import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { ThirdPartyVehicleComponent } from '../third-party-vehicle/third-party-vehicle.component';
import { VehicleComponent } from '../vehicle-master/vehicle.component';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-vehicle-tabs',
  templateUrl: './vehicle-tabs.component.html',
  styleUrls: ['./vehicle-tabs.component.scss']
})
export class VehicleTabsComponent implements OnInit {
  // _component: any;
  // componentsList = [];
  // active = 'top';
  // faTruck = faTruck;
  faTruck = faTruck;
  public userAccessData: UserScreenAccesData;
  constructor(private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private api :DataService) { }

  ngOnInit() {
    // Default loaded Tab
  // this.loadTabComponent("VEHICLES");
  this.userAccessData =this.api.getUserScreenAccessMenu('VEHICLE');
  }

  // loadTabComponent(_selectedTab: string) {
  //
  //     // remove loaded Component
  //     // if (!isNullOrUndefined(this.componentsList)) {
  //     //   this.componentsList.map((cm, i) => {
  //     //     let tmp = this.viewContainerRef;
  //     //     this.viewContainerRef.remove(this.viewContainerRef.indexOf(cm));
  //     //     this.viewContainerRef.remove(i);
  //     //     this.componentsList.splice(i, 1);
  //     //   });
  //     // }

  //     this._component = "";
  //     if (_selectedTab == "VEHICLES")
  //       this._component = VehicleComponent;
  //    else if (_selectedTab == "THIRD-PARTY-VEHICLE")
  //       this._component = ThirdPartyVehicleComponent;

  //     this.viewContainerRef.detach();
  //     this.viewContainerRef.clear();
  //     const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this._component);
  //    // this.viewContainerRef = this.tabHost.viewContainerRef;
  //     let componentRef = this.viewContainerRef.createComponent(componentFactory);
  //     this.componentsList.push(componentRef);
  //   }

}
