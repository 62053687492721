<header id="header" class="banner cement-img position-relative">
  <div class="overlay"></div>
  <div class="container">
      <nav class="navbar">
          <div class="container p-0">
              <a routerLink="/" class="logo"><img src="assets/landing/img/logo.svg" alt=""></a>
              <form class="justify-content-end">
                  <a href="http://www.urgetruck.com/" target="_blank" class="btn-red login"> <i
                          class="bi bi-person-fill"></i> LOGIN </a>
              </form>
          </div>
      </nav>

      <nav class="cd-vertical-nav">
          <ul>
              <li><a routerLink="/" class="active"><span class="label">Home</span></a></li>
              <li><a routerLink="/steel"><span class="label">Steel</span></a></li>
              <li><a routerLink="/automotive"><span class="label">Automotive</span></a></li>
              <li><a routerLink="/cement"><span class="label">Cement</span></a></li>
              <li><a routerLink="/port"><span class="label">Port</span></a></li>
          </ul>
      </nav>
  </div>
</header>

<section class="bg-white">
  <div class="container">
      <div class="row gx-4 gx-lg-5 align-items-center">
          <div class="col-md-4">
              <img class="card-img-top mb-5 mb-md-0" src="../../../assets/landing/img/cement-img.jpg" alt="...">
          </div>
          <div class="col-md-8 text-start">
              <h2>Cement/Mining</h2>
              <p>India is the second-largest producer of cement in the world.</p>
              <p>The challenges associated with the logistic processes of Cement industries and the possible business solutions/strategies are as follows:</p>
              <h3><strong>Process</strong></h3>
              <p>The Logistic processes in cement industries include packing cement into bags and transporting loose cement. The cement bag transportation may involve multiple or single movements like transportation to the zonal warehouse (primary transporation), consignment transfer to the dealer’s warehouse (secondary transporation), and transportation to customers (last-mile delivery or tertiary movement); whereas the loose cement transportation happens via bulk carrier.</p>
              <p>Sales orders get completed by the logistics department by creating delivery orders. The main work of the logistics department is to find a truck, load cement, and dispatch it. There are various types of contracts, such as fixed-price contracts (in which the prices get fixed at the start of the financial year) and market trucks.</p>
              <p>The challenges faced at the time of dispatch are arranging trucks because each transporter wants a committed number of trips for each vehicle and avoids keeping his vehicles idle. Another type of problem is related to plants are located in close vicinity and the companies who pay more for FTL (Full-truck load) compete among themselves to get more and more trucks. There is also competition from FMCG sector companies as they pay higher rates even for transporting LTL (Less than truckload).</p>
              <p>Implementing UrgeTruck solution with the automation setup for the Logistic processes in Cement Industries can overcome the buisness process related challenges mentioned above.</p>
              <h4 class="fs-4">This automation will enable dealers to view their pending order status and book new orders; this in turn reduces field visits by salespersons and helping in focusing on acquiring new customers.</h4>
          </div>
      </div>
  </div>
</section>
<section class="" style="background-color:#f6f6f6;">
  <div class="container">
      <div class="section-header">
          <h2>Cement Industries Logistic Process</h2>
      </div>
      <p>The logistic process starts when a driver arrives at the entry gate of the plant with LR documents containing vehicle capacity and other details. The logistic executive generates [with respect to LR] and prints the DO(Delivery Order). The truck proceeds to the security gate where Registration certificate, driver’s license, and physical checking of vehicle documents happen. After security checks, the vehicle then proceeds to the weighbridge for the tare weight. The truck then moves to the Loading-Bay where the plant team loads the cement bags into the trucks based on the delivery order. The transportation is also done through trains as trains are better choice for long-distance. But there are problems due to delay and demurrages. After loading, the Trucks are covered with tarpaulin and proceed to the weighbridge for the net weight calculation. The logistic department then generates a payment slip, invoice, E-way bill (for other state transport) and LR at the exit gate. Challenges faced is a lack of coordination between the plant team and the production and logistics department. There are challenges in locating the location of the parked truck within the premises. About 60 % of cement transportation happens through truck, rest 40% happens by railways. The Silo is diverted to railway wagons on arrival since they charge high demurrage for the delay in the loading of wagons. Also the loading areas used to be chaotic due to vehicles waiting in line for loading, which sometimes takes as long as 24 hours or more.</p>
      <div class="row">
          <div class="col-md-6 text-start">
              <p>In the Cement industry, the UrgeTruck solution coupled with automation-system covers the following Logistic processes:</p>
              <ol class="list-item">
                  <li>The Outbound Processes [ Finished Products Loading, Weighing, Sales and dispatch data],</li>
                  <li>The In-bound Processes cover the inbound movement of Purchased materials [ including Raw materials] and</li>
                  <li>The In-plant Logistics cover the intra-unit movement for goods (raw materials, finished products, project materials, etc.).</li>
              </ol>
          </div>
          <div class="col-md-6 text-start">
              <img class="card-img-top mb-5 mb-md-0" src="../../../assets/landing/img/cement-img2.jpg" alt="...">
          </div>
      </div>
      <h4 class="fs-4">All the three types of movements involve Vendor collaboration [ Online Transporter registration, Online and On-site Vehicle/Driver/Helper registration, Online assignment/job allocation to Transporters / Vendors, etc.]</h4>

      <div class="mt-2">
          <h2>Mining</h2>
          <img src="../../../assets/landing/img/mining-img.jpg" alt="..."
              style="max-width:400px; float:left; margin-right: 15px;">
          <p>In Mining, the materials which are mined, are loaded into Truck, then weighed, Govt levies are paid, and then proceed for Gate-out. Parking of Vehicles, Driver / Vehicle registration, Gate-IN, Tare weight, Loading, Gross Weight, Security check and Documentation and Gate-Out happen. There may be some processes for In-Bound and In-Plant [ within Mines] activities, GPS-based Vehicle Tracking, etc. All these processes can be covered in the <strong>UrgeTruck</strong> solution thru customizing.</p>
      </div>


  </div>
</section>

<app-lfooter></app-lfooter>
