<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-4" *ngIf="userAccessData[0].canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Application Configuration</h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">
                search
              </span>
              <input class="form-control" type="text" name="search" placeholder="search"
            [(ngModel)]="filter">
            </div>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr. No.</th>

                  <th (click)="sort('name')">Key
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')">Value
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th class="text-center" (click)="sort('name')">Status
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let config of appConfigData | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="left">{{config.key}}</td>
                  <td class="left">{{config.value}}</td>
                  <td class="text-center">
                    <span *ngIf="config.isActive  ==true ">Active</span>
                    <span *ngIf="config.isActive  ==false ">Inactive</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
        </div>
        </div>
    </div>
    </div>
  </section>


<footer class="sticky-footer bg-black text-light mt-auto mb-0">
  <app-footer></app-footer>
</footer>
