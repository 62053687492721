<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
    <span *ngFor="let child of items">
      <!-- Handle branch node menu items -->
      <span *ngIf="child.children && child.children.length > 0">
        <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
          <!-- <mat-icon>{{child.iconName}}</mat-icon> -->
          <span>{{child.displayName}}</span>
        </button>
        <app-menu-item #menu [items]="child.children"></app-menu-item>
      </span>
      <!-- Handle leaf node menu items -->
      <span *ngIf="!child.children || child.children.length === 0">
        <button mat-menu-item [routerLink]="child.routingURL">
          <!-- <mat-icon>{{child.iconName}}</mat-icon> -->
          <span>{{child.displayName}}</span>
        </button>
      </span>
    </span>
  </mat-menu>
  