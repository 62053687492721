import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class UTStatusService {
  constructor() {
  }

  getControlTowerStatuses(): any {
    const ctStatus = [
      {"key":"Un Assigned","status":"Un Assigned"},
      {"key":"In Progress","status":"In Progress"},
      {"key":"Closed","status":"Closed"},
      {"key":"Cancelled","status":"Cancelled"},
      {"key":"On Hold","status":"On Hold"},
      {"key":"Invalid","status":"Invalid"},
      {"key":"Assigned","status":"Assigned"},
      {"key":"Initiated","status":"Initiated"}
    ];
    return ctStatus;
  }

  getControlTowerUserIncidentStatuses(): any {
    const ctStatus = [
      {"key":"Closed","status":"Closed"},
      {"key":"Cancelled","status":"Cancelled"}
    ];
    return ctStatus;
  }

  getRoleGroup(): any {
    const roleGroup = [
      {"Key":"Admin","status":"Admin"},
      {"Key":"Operation","status":"Operation"},
      {"Key":"ControlTower","status":"ControlTower"},
      {"Key":"Security","status":"Security"},
      {"Key":"Management","status":"Management"},
    ];
    return roleGroup;
  }


}
