import { AfterViewInit, Component, Directive, ElementRef, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  UntypedFormControl,
} from '@angular/forms';
import { ViewChild } from '@angular/core';
import {
  faAnchor,
  faEdit,
  faMapPin,
  faObjectGroup,
  faPlus,
  faSort,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { MilestoneMasterResponse } from 'src/app/models/milestonemastermodel';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'app-milestone-master',
  templateUrl: './milestone-master.component.html',
  styleUrls: ['./milestone-master.component.scss'],
})
export class MilestoneMasterComponent implements OnInit {
  milestoneMaster: any[] = [];
  title: any;
  faEdit = faEdit;
  faTimes = faTimes;
  faAnchor = faAnchor;
  faPlus = faPlus;
  faSort = faSort;
  submitted = false;
  form: UntypedFormGroup;
  resetbuttonShow: boolean = true;
  public milestone: MilestoneMasterResponse = new MilestoneMasterResponse();
  public userAccessData: UserScreenAccesData;

  @Input() focus: boolean;
  //@ViewChild("milestoneName") milestoneNameele: ElementRef;
  displayedColumns: string[] = [
    'position',
    'milestoneName',
    'milestoneEvent',
    'desc',
    'status',
    'action',
  ];
  dataSource = new MatTableDataSource<any>(this.milestoneMaster);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize3 = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  activebuttonHide: boolean;
  milestoneName: any;
  focusEvent: any;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  //initializing currentActivePage to one which is active page when page loads
  currentActivePage3: number = 1;
  totalRecords3 = 0;
  tempcurrentActivePage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private element: ElementRef,
    private api :DataService
  ) { }

  ngOnInit(): void {
    this.Reset();
    this.getAllMilestone();
    //this.milestoneNameele.nativeElement.focus();
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  Reset() {
    this.form = this.formBuilder.group({
      milestoneName: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z]*'),
          Validators.minLength(3),
          Validators.maxLength(30),
          this.noWhitespaceValidator,
        ],
      ],
      milestoneCode: ['',
        [Validators.required,
        this.noWhitespaceValidator,
        Validators.pattern('^[ A-Za-z0-9.-]*$'),
        Validators.minLength(3),
        Validators.maxLength(30),],
      ],
      isActive: [''],

      description: [''],
      milestoneEvent: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern('[a-zA-Z]*'),
        ],
      ],
    });
    this.userAccessData =this.api.getUserScreenAccessMenu('MILESTONE');
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }
  // onFocus(){
  //   focus.apply(this.milestoneName);
  // }

  //   setFocus() {
  //     this.focusEvent.emit(true);
  // }

  getAllMilestone() {
    this.spinner.show();
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllMilestone)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.milestoneMaster = response;
          this.totalRecords3 = this.milestoneMaster.length;
          const ELEMENT_DATA: PeriodicElement[] = response;
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  registerMilestone() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService
      .postPatch<any>(ServiceUrl.registerMilestone, this.milestone)
      .subscribe(
        (res) => {

          Swal.fire('', res.responseMessage, 'success');
          this.getAllMilestone();
          this.closeModalPopup();
          this.spinner.hide();
        },
        (r) => {

          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          });
          this.spinner.hide();
        }
      );
  }


  open(content: any) {

    this.onReset();
    //focus.apply(this.milestoneName);
    //this.milestoneNameele.nativeElement.focus();
    if (this.focus) this.element.nativeElement.focus();
    this.title = 'Add Milestone';
    this.milestone.milestoneName = '';
    this.milestone.milestoneCode = '';
    this.milestone.milestoneId = 0;
    this.milestone.isActive = true;
    (this.resetbuttonShow = true),
      this.activebuttonHide = false;
    this.milestone.description = '';
    this.milestone.milestoneEvent = '';
    this.modalService.open(content, this.ngbModalOptions);
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  editMileStone(content: any, milestone) {

    this.onReset();
    //this.milestoneNameele.nativeElement.focus();
    this.title = 'Update Milestone';
    this.milestone.milestoneName = milestone.milestoneName;
    this.milestone.milestoneCode = milestone.milestoneCode;
    //console.log(milestone.mileStoneCode);
    this.resetbuttonShow = false;
    this.activebuttonHide = true;
    this.milestone.milestoneId = milestone.milestoneId;
    this.milestone.isActive = milestone.isActive;
    this.milestone.description = milestone.description;
    this.milestone.milestoneEvent = milestone.milestoneEvent;
    this.modalService.open(content, this.ngbModalOptions);
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage3 = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage3 = 1 : this.currentActivePage3 = this.tempcurrentActivePage
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];
