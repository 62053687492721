import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleDetail } from 'src/app/apiModels/elv-models';
import { JobAllocationData, JobAllocationForInplant, Materials, Products } from 'src/app/apiModels/job-allocation-models';
import { Milestones, VehicleMilestone, elvFromExcel, milestoneJsonData } from 'src/app/apiModels/milestone-model';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inplant-api-simulation',
  templateUrl: './inplant-api-simulation.component.html',
  styleUrls: ['./inplant-api-simulation.component.scss']
})
export class InplantApiSimulationComponent implements OnInit {
  elvForm!: UntypedFormGroup;
  public vehicleData: VehicleDetail = new VehicleDetail();
  public jobAllocationjsonData: JobAllocationData = new JobAllocationData();
  public jobAllocationForInplant : JobAllocationForInplant = new JobAllocationForInplant();
  public Products: Products = new Products();
  public Materials: Materials = new Materials();
  public detailForELV!: any;
  public detailForJobAllocation!: any;
  public detailForMilestone!: any;
  public milestoneWorkFlow!: any;
  submitted = false;
  currentActiveVendor: number = 0;
  elvSuccessfullyDone: boolean = false;
  jobAllocationSuccessfullyDone: boolean = false;
  todateDate!: string;
  productData: any[];
  vehicleMasterData: any[] = [];
  driverMasterData: any[] = [];
  milestoneTemplate1: Array<Milestones> = new Array<Milestones>();
  milestoneTemplate2: Array<Milestones> = new Array<Milestones>();
  milestoneJsonData: VehicleMilestone = new VehicleMilestone();
  milestoneJsonDataFinale: milestoneJsonData = new milestoneJsonData();

  milestoneTemplateDataChooseOption = '';
  milestoneReadyToSend = false;
  templateListData: Array<any> = new Array<any>();
  docTypeIsWorng: boolean = false;
  UOMValue = 'bags';
  excelData: any;
  elvListFromExcel: Array<elvFromExcel> = new Array<elvFromExcel>();
  elvDataFromExcel: elvFromExcel = new elvFromExcel();
  excelErrorMessage: any;
  public newProductList: Array<Products> = new Array<Products>();
  Tareweightfrequncy: any = Tareweightfrequncy;
  documentType ='DO';
  batchSize: number = 100;
  batchNumber:number = 0;  
  searchText : string = null;
  @ViewChild('select') select: NgSelectComponent;
  currentVendorPlaceholder = 'Select a Driver';
  driverSize :number = 100;
  driverBatch: number = 0;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private kemar: KemarClientService,
    private spinner: NgxSpinnerService
  ) {}

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    let result = confirm(
      'Data will be lost if you refresh this page, are you sure?'
    );
    if (result) {
      // Do more processing...
    }
    event.returnValue = false; // stay on same page
  }

  ngOnInit(): void {
    this.resetForELV();
      this.getProductMaster();
    this.getVehicleMaster();
    this.getDriverMaster();
    this.getMilestoneWorkFlow(4);
    this.generateNewDocumentNumber();
  }

  getProductMaster() {
    debugger;
    this.kemar.get<any>(null, ServiceUrl.getallProductMaster).subscribe(
      (response) => {
        this.productData = response;
      },
      (r) => {
        alert(r.error.error);
      }
    );
  }


  onSearch(event : any){
    this.searchText = event.term;
    if(this.searchText)
      this.currentVendorPlaceholder = '';
    else
      this.currentVendorPlaceholder = 'Select Vendor';
    this.currentActiveVendor = 1;
    this.getDriverMaster();
    if (this.select.isOpen) {
      this.select.close();
      this.select.open();
    }
  }


  getVehicleMaster() {
    // Prevent additional API calls if already loading
    // if (this.loading) return;
  
    // this.loading = true;  // Set loading to true to prevent further calls
    const body = { 
      batchNumber: this.batchNumber,
       batchSize: this.batchSize ,
       searchTerm:this.searchText != '' && this.searchText != null? this.searchText:''
      };
  
    this.kemar.get<any>(null, ServiceUrl.GetAllLogisticsVehicleWithpaginationAsync, body)
      .subscribe(
        (response) => {
          if (response && response.length > 0) {
            // Append new vehicles to the existing array
            this.vehicleMasterData = [...this.vehicleMasterData, ...response];
            this.batchNumber++;  // Increment the batch number for the next call
          }
          // this.loading = false;  // Reset loading flag
        },
        (error) => {
          // this.loading = false;  // Reset loading flag on error
          alert(error.error?.message || 'Error loading vehicles');
        }
      );
  }


  loadDriver(){
    debugger
    this.driverBatch++;
    this.getDriverMaster();
  }

  getDriverMaster() {
    debugger
    const body = {
       page: this.driverBatch,
       size: this.driverSize,
       searchTerm:this.searchText != '' && this.searchText != null? this.searchText:''
      };
    this.kemar.get<any>(null, ServiceUrl.GetDriverWithPagination,body).subscribe(
      (response) => {
        this.driverMasterData = [...this.driverMasterData , ...response];
        // var driverCode = this.driverMasterData.filter(
        //   (x) => x.driverCode == '0000'
        // );
        
        // if(driverCode.length>0) {
        //   this.elvForm.controls['DriverCode'].setValue(
        //     driverCode[0].driverCode
        //   );
        // }        
        this.driverBatch++;
      },
      (r) => {
        alert(r.error.error);
      }
    );
  }
  onSearchVendor(event : any){
    this.searchText = event.term;
    if(this.searchText)
      this.currentVendorPlaceholder = '';
    else
      this.currentVendorPlaceholder = 'Select Vendor';
    this.currentActiveVendor = 1;
    this.getVehicleMaster();
    if (this.select.isOpen) {
      this.select.close();
      this.select.open();
    }
  }


  getMilestoneWorkFlow(tranType: any) {
    let query = {
      'TranType' : tranType
    }
    this.kemar.get<any>(null, ServiceUrl.getAllActiveWorkFlowMaster, query).subscribe(
      (response) => {
        this.milestoneWorkFlow = response;
      },
      (r) => {
        alert(r.error.error);
      }
    );
  }

 

  resetForELV() {
    debugger;
    this.elvForm = this.fb.group({

      vrn: ['', [Validators.required]],
      drivercode: ['', [Validators.required]],
      MovementType: ['InPlant'],
      IsActive: ['true'],
      documentType: ['DO', [Validators.required]],
      documentnumber: ['',[Validators.required]],

      MaterialType: [''],
      ProductCode: [' ', [Validators.required]],
      Weight: [0],
      Quantity: [0],
      MRP: [400, [Validators.required]],
      ProductTransactionCode: [''],
      tareweightfrequncy:['Once',[Validators.required]],
      nooftrips:['', [Validators.required]],

    });
    this.elvForm.enable();
  }
  onChangeMovementType(type: number) {

    this.getMilestoneWorkFlow(type);
    if (type === 4) {
      this.elvForm.controls['documentType'].setValue('DO');
    } else {
      this.elvForm.controls['documentType'].setValue('PO');
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.elvForm.controls;
  }
   resetAllForm() {
    this.milestoneTemplateDataChooseOption = '';
    this.resetForELV();
    this.milestoneTemplate1 = new Array<Milestones>();
  }

  generateRandomNumber() {
    var newDate = new Date();
      const random =
        newDate.getDate().toString() +
        newDate.getMonth().toString() +
        newDate.getHours().toString() +
        newDate.getMinutes().toString() +
        newDate.getMilliseconds().toString();

      return random;
    }

  generateRandomNumberForDocumentNumber() {
    var newDate = new Date();
        const random =
          newDate.getDate().toString() +
          newDate.getMonth().toString() +
          newDate.getHours().toString() +
          newDate.getMinutes().toString() +
          newDate.getMilliseconds().toString();

        return random;
      }

  generateNewDocumentNumber() {
    const generatedRandomNumber = this.generateRandomNumberForDocumentNumber();
    this.elvForm.patchValue({
      documentnumber: generatedRandomNumber
    });
  }
  generateRandomNumberForShipmentNumber() {
    var newDate = new Date();
    const random =
      newDate.getDate().toString() +
      newDate.getMonth().toString() +
      newDate.getHours().toString() +
      newDate.getMinutes().toString() +
      newDate.getMilliseconds().toString();
    return random;
  }

  setValueForJobAllocation() {
    debugger;;
    this.elvForm.controls['documentnumber'].setValue(
      this.elvForm.controls['documentType'].value +
        this.generateRandomNumberForDocumentNumber()
    );
    this.elvForm.controls['ProductTransactionCode'].setValue(
      this.generateRandomNumberForShipmentNumber()
    );
    this.elvForm.controls['MovementType'].setValue(
      this.elvForm.controls['MovementType'].value
    );
  }

  setProductWeight() {
    debugger;
    if (this.UOMValue == 'bags') {
      var noBags = this.elvForm.controls['Quantity'].value;
      var productWeight = noBags * 50;
      this.elvForm.controls['Weight'].setValue(productWeight);
    } else {
      var weight = this.elvForm.controls['Weight'].value;
      var productQuantity = 400;
      this.elvForm.controls['Quantity'].setValue(productQuantity);
    }
  }

  setProductName() {
    debugger;
    var productCode = this.elvForm.controls['ProductCode'].value;
    var productName = this.productData.filter(
      (x) => x.productCode == productCode
    );
    this.elvForm.controls['MaterialType'].setValue(
      productName[0].productName
    );
  }

  createJsonDataForJobAllocation() {
    this.setProductWeight();
    if (this.elvForm.invalid) {
      return;
    }
    this.Products = new Products();
    this.jobAllocationForInplant = new JobAllocationForInplant();
    var materialTypeValue = this.elvForm.controls['MaterialType'].value;
    this.Products.MaterialType = materialTypeValue.replace('_', ' ');
    this.jobAllocationForInplant.documentnumber = this.elvForm.controls['documentnumber'].value;
    this.jobAllocationForInplant.documentType = this.elvForm.controls['documentType'].value;
    this.jobAllocationForInplant.tareweightfrequncy = this.elvForm.controls['tareweightfrequncy'].value;
    this.jobAllocationForInplant.nooftrips = this.elvForm.controls['nooftrips'].value;
    this.jobAllocationForInplant.vrn = this.elvForm.controls['vrn'].value;
    this.jobAllocationForInplant.drivercode = this.elvForm.controls['drivercode'].value;
    this.jobAllocationForInplant.isActive = 'true';

    this.Products.ProductCode = this.elvForm.controls['ProductCode'].value;
    this.Products.BatchNo = 'BA' + this.generateRandomNumber();
    this.Products.Weight = this.elvForm.controls['Weight'].value;
    this.Products.Quantity = this.elvForm.controls['Quantity'].value;
    this.Products.MRP = this.elvForm.controls['MRP'].value;
    this.Products.ShippingSequence = '1';
    this.Products.DocumentType = this.elvForm.controls['documentType'].value;
    this.Products.DocumentNumber = this.elvForm.controls['documentnumber'].value;
    this.Products.ProductTransactionCode = this.generateRandomNumber().toString();
    this.Products.IsActive = 'true';


    this.Products.Materials.push(this.Materials);
    this.jobAllocationForInplant.Products.push(this.Products);

    this.jobAllocationjsonData.RequestId = this.generateRandomNumber().toString();
    this.jobAllocationjsonData.JobAllocationForInplant.push(this.jobAllocationForInplant);
  }



  selectMilestoneGroup() {
    ;
    this.milestoneTemplate2 = new Array<Milestones>();
    var milestone = this.milestoneWorkFlow.filter(
      (milestone) =>
        milestone.workFlowCode === this.milestoneTemplateDataChooseOption
    );
    this.milestoneTemplate1 = new Array<Milestones>();
    milestone[0].workFlowDetails.forEach((element) => {
      this.milestoneTemplate2.push(element);
    });
    this.milestoneReadyToSend = true;
    console.log(this.milestoneTemplate2);
  }

  sendMilestoneWithWorkFlowGroupName() {
    debugger;
   this.submitted =true
    if (this.elvForm.invalid)
    return

   this.createJsonDataForJobAllocation();
  //  this.AddNewProduct();
    this.milestoneReadyToSend = false;
    this.jobAllocationForInplant = new JobAllocationForInplant();
    this.jobAllocationForInplant.vrn = this.elvForm.controls['vrn'].value;
    this.jobAllocationForInplant.drivercode = this.elvForm.controls['drivercode'].value;
    this.jobAllocationForInplant.documentnumber = this.elvForm.controls['documentnumber'].value;
    this.jobAllocationForInplant.documentType = this.elvForm.controls['documentType'].value;
     this.jobAllocationForInplant.milestoneGroupCode = this.milestoneTemplateDataChooseOption;
    this.jobAllocationForInplant.isActive = this.elvForm.controls['IsActive'].value;
    this.Products.ProductTransactionCode = this.generateRandomNumber().toString();
    this.jobAllocationForInplant.nooftrips = this.elvForm.controls['nooftrips'].value;
    this.jobAllocationForInplant.tareweightfrequncy = this.elvForm.controls['tareweightfrequncy'].value;
    this.jobAllocationForInplant.materialtype = this.elvForm.controls['MaterialType'].value;
    this.jobAllocationForInplant.Products.push(this.Products)

    this.kemar.postPatch<any>( ServiceUrl.postInPlantTransactionWithGroupName,this.jobAllocationForInplant)
      .subscribe(
        (res) => {
          Swal.fire('', res.responseMessage, 'success');
          this.jobAllocationSuccessfullyDone = false;
          this.milestoneReadyToSend = false;
          this.resetAllForm();
          this.router.navigate(['/inplant-transaction']);
        },
        (r) => {
          this.detailForMilestone=' ';
          this.milestoneReadyToSend = true;
          this.detailForMilestone = r.error.errorMilestoneAllocations[0].errorMilestones[0].errorMessage;

        }
      );
  }

  loadVendors(){
    debugger
    this.currentActiveVendor++;
    this.getVehicleMaster();
  }

}
const Tareweightfrequncy: { key: string; value: string }[] = [
  { key:'Once', value: 'Once' },
  { key: 'Always', value:'Always' },
  { key: 'Once In Shift', value:'OnceInShift' },
];
