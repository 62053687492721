import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";

import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faEdit, faMapPin, faPlus, faSort, faTimes, faWeight } from '@fortawesome/free-solid-svg-icons';
import { WeighBridgeMasterResponse } from 'src/app/models/weighbridgemastermodel';
import { Duplex } from 'stream';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
// import { LocationMasterResponse } from 'src/app/models/locationmastermodel';

@Component({
  selector: 'app-weigh-bridge-master',
  templateUrl: './weigh-bridge-master.component.html',
  styleUrls: ['./weigh-bridge-master.component.scss']
})
export class WeighBridgeMasterComponent implements OnInit {
  WeighBridgeMaster: any;
  locationMaster: any;
  title: any;
  resetbuttonShow: boolean = true;
  faMapPin = faMapPin
  faPlus = faPlus
  faEdit = faEdit
  faSort = faSort
  faTimes = faTimes
  faWeight = faWeight
  submitted = false;
  activebuttonShow: boolean = false;
  public WeighBridge: WeighBridgeMasterResponse = new WeighBridgeMasterResponse();
  public userAccessData: UserScreenAccesData;


  filter = '';
  pageSize = 15;
  key: string = 'name'; //set default
  reverse: boolean = false;
  form: FormGroup;

  Deactivation: any = false;
  isreasonColor: boolean = false;
  tempcurrentActivePage: number = 1;
  ;

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  currentActivePage: number = 1;

  @ViewChild('closebutton') closebutton;
  constructor(private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder,

    private kemarClientService: KemarClientService, private modalService: NgbModal,
    private api :DataService) {
    this.form = this.formBuilder.group({

      WeighBridgeName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
          this.noWhitespaceValidator,
        ]
      ],
      deactivationReason: [[Validators.required,]],
      locationId: [0, [Validators.required, Validators.pattern(/^[1-9]\d*$/)]],

      capacity: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(7),
          Validators.pattern("[0-9].*"),
          //this.noWhitespaceValidator
        ]
      ],
      isActive: [''],
      weighbridgeBoomType: ['', [Validators.required,]],
      isTokenVerificationReq: ['']
    });
    this.userAccessData =this.api.getUserScreenAccessMenu('WB')
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit(): void {

    this.getAllWeighBridge();
    this.getAllLocation();

  }

  getAllWeighBridge() {
    this.spinner.show();
    this.kemarClientService.get<WeighBridgeMasterResponse>(null, ServiceUrl.getAllWeighBridge)
      .subscribe(
        response => {
          this.spinner.hide();
          this.WeighBridgeMaster = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  getAllLocation() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        response => {
          this.locationMaster = response;
        },
        r => {
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  deactivationReason(val: any) {

    if (val.length <= 0 || this.kemarClientService.commonValidation(val)) {
      this.isreasonColor = true
    }
    else {
      this.isreasonColor = false
    }
  }

  registerWeigbridge() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if (this.Deactivation == true) {
      if (this.kemarClientService.commonValidation(this.WeighBridge.deactivationReason) == true) {
        this.isreasonColor = true
        return;
      }
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerWeigbridge, this.WeighBridge)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success')
          this.getAllWeighBridge();
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          if (r.error.status === 409) {
            Swal.fire({
              icon: 'info',
              title: '',
              text: "Can't register duplicate weighbridge name."
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: '',
              text: r.error.errorMessage
            })
          }
          this.spinner.hide();
          // console.log(r.error.error);
          // console.log(r);
        });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  open(content: any) {
    this.onReset();
    this.title = "Add Weighbridge";
    this.WeighBridge.WeighBridgeName = '';
    this.WeighBridge.deactivationReason = '';
    this.activebuttonShow = true;
    this.WeighBridge.WeighBridgeId = 0;
    this.WeighBridge.locationId = 0;
    this.resetbuttonShow = true;
    this.WeighBridge.isActive = true;
    this.WeighBridge.capacity = '';
    this.WeighBridge.weighbridgeBoomType = '';
    this.WeighBridge.isTokenVerificationReq = false;
    this.Deactivation = false;
    this.WeighBridge.editFlag = true;
    this.modalService.open(content, this.ngbModalOptions);

  }

  IsActiveClickEvent(val: any) {
    this.Deactivation = val
    this.isreasonColor = false;
  }

  onReset() {
    this.isreasonColor = false;
    this.submitted = false;
    this.form.reset();
    this.form.get('capacity').setValue('');
    this.form.controls['locationId'].setValue(0);
    this.WeighBridge.isActive=true;
  }

  editWeighBridge(content: any, WeighBridge) {

    this.onReset();
    this.title = "Update Weighbridge";
    this.WeighBridge.WeighBridgeId = WeighBridge.weighBridgeId;
    this.WeighBridge.WeighBridgeName = WeighBridge.weighBridgeName;
    this.WeighBridge.locationId = WeighBridge.locationId;
    this.WeighBridge.capacity = WeighBridge.capacity;
    this.resetbuttonShow = false;
    this.activebuttonShow = false;
    this.WeighBridge.deactivationReason = WeighBridge.deactivationReason;
    this.WeighBridge.isActive = WeighBridge.isActive;
    this.WeighBridge.locationId = WeighBridge.locationId;
    this.WeighBridge.weighbridgeBoomType = WeighBridge.weighbridgeBoomType;
    this.WeighBridge.isTokenVerificationReq = WeighBridge.isTokenVerificationReq;
    WeighBridge.isActive == true ? this.Deactivation = false : this.Deactivation = true;
    this.WeighBridge.editFlag = false;
    this.modalService.open(content, this.ngbModalOptions);
  }

  acceptnumber(event) {
    this.kemarClientService.keyacceptnumberAndDot(event);
  }
  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  tonValidation(event: any) {
    let tempval = this.kemarClientService.keypressInTonsValidation(event, this.form.get('capacity').value);
    if (tempval === 'false') {
      this.form.get('capacity').setValue('');
    } else {
      this.form.get('capacity').setValue(tempval);
    }
  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage

  KeyPressMaxLength(event) {

    let tes: any;
    var charCode = (event.which) ? event.which : event.keyCode;
    tes = this.form.get('capacity').value.toString();
    if (charCode == 46 && tes.includes(".")) {
      return false;
    }
    if (charCode == 46) {
      return true;
    }
    else if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else if (!this.kemarClientService.commonValidation(tes)) {
      if (tes.length >= 1 && tes.includes('.')) {
        return true;
      }
      if (tes.length > 2) {
        if (charCode === 46) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
      else {
        return true
      }
    }
    else {
      return true;
    }
  }
}
