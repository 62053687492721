export class WeighBridgePreferenceResponse {
    
    weighBridgePerferencesId:number;
    tranactionType:string;
    isActive:boolean;
    weighmentType: string;
    priority:number;
    transactionType: string;
    nearByLocationId: number;
    locationId: number;
} 
