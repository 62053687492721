// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-detailed-gate-report',
//   templateUrl: './detailed-gate-report.component.html',
//   styleUrls: ['./detailed-gate-report.component.scss']
// })
// export class DetailedGateReportComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/service/data.service';
import { ExcelService } from 'src/app/service/excel.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { faDownload, faFileAlt, faMapPin, faSort } from '@fortawesome/free-solid-svg-icons';
import { DetailedGateReportModel } from 'src/app/models/reportmodel';
import { ServiceUrl } from 'src/app/common/service-urls';

@Component({
  selector: 'app-detailed-gate-report',
  templateUrl: './detailed-gate-report.component.html',
  styleUrls: ['./detailed-gate-report.component.scss']
})
export class DetailedGateReportComponent implements OnInit {

  DGP: any;
  key: string = 'name';
  reverse: boolean = false;
  currentdate: string;
records: any;
 dateErrorMessage: string = '';
 isValidDates: boolean = true; 
dataForExcel = [];
pageSize = 10;
totalRecords:0;
filter = '';
faSort = faSort;  
faMapPin = faMapPin
faDownload = faDownload
faFileAlt = faFileAlt
currentActivePage: number = 1;
pageSizeForHelper= 15; 
tempcurrentActivePage: number=1;
detailedGateReportModel:DetailedGateReportModel = new DetailedGateReportModel();


  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  constructor(
    private kemarClientService: KemarClientService,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService
    ,private api:DataService
  ) { }

  ngOnInit(): void {
    debugger
    this.currentdate = new Date().toISOString().split('T')[0];
    this.detailedGateReportModel.fromDate = this.currentdate;
    this.detailedGateReportModel.toDate = this.currentdate;
    this.getDetailedGateReport();
  }

  // validateDates() {
  //   const fromDate = new Date(this.detailedGateReportModel.fromDate);
  //   const toDate = new Date(this.detailedGateReportModel.toDate);

  //   if (fromDate > toDate) {
  //     this.dateErrorMessage = '*To date cannot be smaller than From date.';
  //     this.isValidDates = false; // Disable the search button
  //   } else {
  //     this.dateErrorMessage = ''; // Clear error message
  //     this.isValidDates = true; // Enable the search button
  //   }
  // }


// exportToExcel(vehicleType: any) {
//   debugger  
//   this.spinner.show();
//   var query = {
//     vehicleType: vehicleType,
//   };
//   this.kemarClientService
//     .get<any>(null, ServiceUrl.getDetailedGateReport, query)
//     .subscribe(
//       (response) => {
//         console.log('Response:', response);
//         this.exportToExcelDetailReport(response);
//         this.spinner.hide();
//       },
//       (error) => {
//         console.error('Error:', error); // Check the error message
//         this.spinner.hide();
//       }
//     );
//   }
  
  

exportToExcel() {
  debugger
  this.dataForExcel = [];
  this.records.forEach((row:any)=>{
    this.dataForExcel.push(Object.values(row))
  })

  let tatData = {
    title: 'Detailed Gate Report',
    data: this.dataForExcel,
    headers: Object.keys(this.records[0])
  }

  this.excelService.exportExcelForDetailedGateReport(tatData);
}

// exportToExcel() {
//   debugger
//   console.log(this.DGP);  // Add this to see the data

//   if (!this.DGP || this.DGP.length === 0) {
//     console.error('No data to export');
//     return;
//   }

//   this.dataForExcel = [];
//   this.DGP.forEach((row: any) => {
//     this.dataForExcel.push(Object.values(row))
//   })

//   let tatData = {
//     title: 'Day wise TAT Report',
//     data: this.dataForExcel,
//     headers: Object.keys(this.DGP[0])
//   }

//   this.excelService.exportExcelForDetailedGateReport(tatData);
// }





  getDetailedGateReport(){
    debugger
    this.spinner.show();
    // if (!this.isValidDates) return;

    var fromDate;
    var ToDate;
    if(!this.kemarClientService.commonValidation(this.detailedGateReportModel.fromDate))
      fromDate = this.detailedGateReportModel.fromDate + ' ' + '00:00:00';
    if(!this.kemarClientService.commonValidation(this.detailedGateReportModel.toDate))
      ToDate = this.detailedGateReportModel.toDate + ' ' + '23:59:59';
    var transType = this.detailedGateReportModel;
    var query = { 'fromDate': fromDate, 'toDate': ToDate }
    this.kemarClientService.get<DetailedGateReportModel>(null, ServiceUrl.GetDetailReport, query)
      .subscribe( 
        response => {
          this.spinner.hide();
          this.records=response;

          
          this.totalRecords = this.records.length > 0 ? this.records.length : 0;
        },
        r => {
          this.spinner.hide();
          console.error('Error fetching Detailed Gate Report:');  
        });
  }

  
    OnHelperPageSizeChange(arg0: any) {
      throw new Error('Method not implemented.');
      }

      
      OnPageSizeChange(event) {
        this.pageSize = event.target.value;
        this.getDetailedGateReport();
      }
    


  
  

  

 
      
      KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);
  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage

}
