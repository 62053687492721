import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faEdit, faMapPin, faPlus, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CrossCheckMasterResponse, crossCheckEventSchedule } from 'src/app/models/crosscheckmastermodel';
import { WeighBridgeMasterResponse } from 'src/app/models/weighbridgemastermodel';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-cross-check-master',
  templateUrl: './cross-check-master.component.html',
  styleUrls: ['./cross-check-master.component.scss']
})

export class CrossCheckMasterComponent implements OnInit {
  list: any[] = [];
  public crossCheck: CrossCheckMasterResponse = new CrossCheckMasterResponse();
  public crosscheckEventSchedule: crossCheckEventSchedule = new crossCheckEventSchedule();
  public userAccessData: UserScreenAccesData;
  title: any;
  faEdit = faEdit;
  faPlus = faPlus;
  faSort = faSort
  submitted = false;
  form: FormGroup;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 5;
  checked: false;
  eventGroupform: FormGroup;
  scheduleform: FormGroup;
  key: string = 'name'; //set default
  reverse: boolean = false;
  WeighBridgeMaster: any;
  WeighBridgeSelectedValue: any[] = [];
  sequence: number = 0;
  SelectWeighbridgeID: WeighBridge[];
  AllWeighBridge: WeighBridge[];
  flag: boolean;
  Incrementflag: boolean = false;

  AllcrossCheck: any[] = [];
  IsNewEditCase: boolean;
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton') closebutton;





  constructor(private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private kemarClientService: KemarClientService,

    private modalService: NgbModal, private datePipe: DatePipe, public dialog: MatDialog,
    private api:DataService) {   
    this.WeighBridgeMaster;
    this.list;
    this.eventGroupform = this.formBuilder.group({
      WeighBridgeMaster: ['',],
    });
    this.getAllWeighBridge();
}

  ngOnInit(): void {
    this.scheduleform = this.formBuilder.group({
      schedule: ['', [Validators.required]],
      scheduleLifeTime: ['', Validators.required]
    });
    this.form = this.formBuilder.group(
      {
        cceName: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(30),this.noWhitespaceValidator]],
        expectedNoOfVehicles: ['', [Validators.required,Validators.pattern('[1-9][0-9]*')]],
        weighbridge: ['', [Validators.required]],
        isActive: ['',],
      },
    );
    this.userAccessData =this.api.getUserScreenAccessMenu('WBPREF');
    this.getAllWeighBridge();
    this.getAllCrossCheck();
  }
  onReset() {
    this.form.reset();
    this.scheduleform.reset();
    this.WeighBridgeSelectedValue = [];
    this.sequence = 0;
    this.submitted = false;
  }
  getAllCrossCheck() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllCrossCheckEvents)
      .subscribe(
        response => {

          this.spinner.hide();
          this.AllcrossCheck = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
        });
  }
  getAllWeighBridge() {
    this.spinner.show();

    this.kemarClientService.get<any>(null, ServiceUrl.getAllWeighBridge)
      .subscribe(
        response => {

          this.spinner.hide();
          this.list = response;
          this.AllWeighBridge = this.list.map(item => {
            return { cceId: '', ccwbGroupId: '', sequence: '', weighBridgeName: item.weighBridgeName, weighBridgeId: item.weighBridgeId };
          });
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
        });
  }
  acceptnumber(event: any, flag: boolean) {
    if (flag) {
      this.kemarClientService.keyPressNumbers(event);
    }

  }

  registerCrossCheck() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    var ScheduleTime =this.scheduleform.get('schedule').value;
    var ScheduleLifeTime = this.scheduleform.get('scheduleLifeTime').value;
    if (ScheduleTime != undefined && ScheduleTime != '' && ScheduleLifeTime != undefined && ScheduleLifeTime != '')
    {
      if (ScheduleTime == ScheduleLifeTime ) {
      var errorMessage = "Schedule start time and Schedule end time can not be same";
      Swal.fire({
        icon: 'error',
        title: '',
        text: errorMessage,
      })
      return;
    }
  }
    this.spinner.show();
    this.crossCheck.crossCheckWGBroup = this.WeighBridgeSelectedValue;
    let templist: any[] = [];
    templist.push(this.crosscheckEventSchedule);
    this.crossCheck.crossCheckEventSchedule = templist;
    this.kemarClientService.postPatch<any>(ServiceUrl.registerCrossCheck, this.crossCheck)
      .subscribe(
        res => {

          Swal.fire('', res.responseMessage, 'success')
          this.closepop();
          this.getAllCrossCheck();
          this.closeModalPopup();
          this.spinner.hide();
          this.submitted = false;
        },
        r => {
          if (r.status == 409) {
            Swal.fire({
              icon: 'warning',
              title: '',
              text: 'Name is already registered.'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              title: '',
              text: r.error.errorMessage
            })
          }
          this.spinner.hide();
        });
  }

  open(content: any) {

    this.onReset();
    this.crossCheck.isActive = true;
    this.submitted = false;
    this.IsNewEditCase = true;
    this.title = "Add Cross Check Event";
    this.crossCheck.CCEId = 0;
    this.crossCheck.cceName = '';
    this.crossCheck.expectedNoOfVehicles = '';
    this.crosscheckEventSchedule.ScheduleTime = '';
    this.crosscheckEventSchedule.ScheduleLifeTime = '';
    this.crossCheck.isEditMode = false;
    this.form.get('cceName').enable();
    this.modalService.open(content, this.ngbModalOptions);
  }

  editCrossCheck(content: any, crossCheckObj: any) {

    this.onReset();
    this.title = "Update Cross Check Event";
    this.IsNewEditCase =false;
    this.crossCheck.isEditMode = true;
    //this.crossCheck.isEdit=true;
    // this.form.get('cceName').disable();
    this.crossCheck.CCEId = crossCheckObj.cceId;
    this.crossCheck.cceName = crossCheckObj.cceName;
    this.crossCheck.expectedNoOfVehicles = crossCheckObj.expectedNoOfVehicles;
    this.crosscheckEventSchedule.ScheduleTime = this.datePipe.transform(crossCheckObj.crossCheckEventSchedule[0].scheduleTime, 'HH:mm');
    this.crosscheckEventSchedule.ScheduleLifeTime = this.datePipe.transform(crossCheckObj.crossCheckEventSchedule[0].scheduleLifeTime, 'HH:mm');
    this.crossCheck.isActive = crossCheckObj.isActive;
    let obj: any[] = [];
    obj = crossCheckObj.crossCheckWGBroup.filter(x => Object.keys(x.weighBridgeId != "weighBridgeId"));
    this.WeighBridgeSelectedValue = obj
    this.SelectWeighbridgeID = obj
    obj.forEach(((element: { weighBridgeId: any; cceId: any; ccwbGroupId: any; sequence: any; }) => {
      let tes = this.AllWeighBridge.find(x => x.weighBridgeId == element.weighBridgeId);
      if (tes != null) {
        tes.cceId = element.cceId
        tes.ccwbGroupId = element.ccwbGroupId
        tes.sequence = element.sequence
      }
    }));
    this.modalService.open(content, this.ngbModalOptions);
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  public closeModalPopup() {
    this.modalService.dismissAll();
  }
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get scheduleformvaliation(): { [key: string]: AbstractControl } {
    return this.scheduleform.controls;
  }

  closepop() {

    this.modalService.dismissAll();
    this.WeighBridgeSelectedValue = [];
    this.crossCheck.crossCheckWGBroup = [];
    this.form.controls['weighbridge'].setValue('');
    this.submitted = false;
    this.sequence = 0;
  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;

  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage
  setValueTextArea(_event: any) {

    let weighBridgeName = _event.itemValue.weighBridgeName;
    let WBID = _event.itemValue.weighBridgeId;
    if (this.WeighBridgeSelectedValue.length == 0) {
      this.sequence = this.sequence + 1;
      let query = {
        'weighBridgeId': WBID,
        'weighBridgeName': weighBridgeName,
        'sequence': this.sequence,
      }
      this.flag = false;
      this.WeighBridgeSelectedValue.push(query);
    }
    else {
      let index = this.WeighBridgeSelectedValue.findIndex(x => x.weighBridgeName == _event.itemValue.weighBridgeName);
      if (index < 0 && this.flag) {
        this.Incrementflag = true
      }
      else {
        this.Incrementflag = false;
      }
      if (index >= 0) {
        let lastsequence = this.WeighBridgeSelectedValue.find((x, i) => i == index)
        this.WeighBridgeSelectedValue.splice(index, 1);
        if (this.WeighBridgeSelectedValue.length == 0) {
          this.sequence = 0;
        }
        else {
          let templist: any[] = [];
          templist = this.WeighBridgeSelectedValue.filter((item, i) => i >= index);
          if (templist.length == 0 && _event.value.length == 0) {
            this.sequence = 0;
            this.flag = false;
          } else {
            this.sequence = lastsequence.sequence;
            if (templist.length > 0) {
              templist.forEach((element, index) => {
                let filterIndex = this.WeighBridgeSelectedValue.findIndex(x => x.weighBridgeName == element.weighBridgeName);
                if (filterIndex >= 0 && filterIndex != -1) {
                  this.WeighBridgeSelectedValue[filterIndex].sequence = this.sequence;
                  let test = this.AllWeighBridge.find(x => x.weighBridgeId == element.weighBridgeId);
                  test.sequence = this.sequence;
                  this.sequence++;
                  this.flag = true;
                }
              });
            } else {
              this.sequence = this.sequence - 1;
              this.flag = false;
            }
          }
        }
      }
      else {
        if (this.IsNewEditCase) {
          this.sequence = this.Incrementflag ? this.sequence : this.sequence + 1
        }
        else {
          const maxsequence = Math.max(...this.WeighBridgeSelectedValue.map(o => o.sequence), 0);
          this.sequence = maxsequence + 1
        }
        let query = {
          'weighBridgeId': WBID,
          'weighBridgeName': weighBridgeName,
          'sequence': this.sequence,
        }
        this.flag = false;
        this.WeighBridgeSelectedValue.push(query);
      }
    }
  }
}

interface WeighBridge {
  sequence: any;
  ccwbGroupId: any;
  cceId: any;
  weighBridgeId: any;
  weighBridgeName: string;
}

