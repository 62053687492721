<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<div>
    <section class="multi-cards mt-3"*ngIf="userAccessData[0]?.canRead ==true">
        <div class="container-fluid py-2 px-3">
            <div class="row data-filter align-items-center my-2">
                <div class="col-sm-8">
                    <h1 class="mb-0 text-start " style="font-weight: 500;"> <img src="../../assets/images/icons/weighbridge-live.svg" width="44" alt=""> Live Dashboard - All weighbridges </h1>
                </div>
                <div class="col-sm-4 text-end">
                    <p-multiSelect [options]="AllWeighBridge" class="w-100" (onChange)="test($event)" [(ngModel)]="SelectWeighbridgeID" defaultLabel="Select a Weighbridge" optionLabel="name"></p-multiSelect>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4   g-4 pt-4 px-2 mt-0">
                <div class="col mt-0 px-2" *ngFor='let WeightBridge of AllWeightBridge;'>
                    <div class="card  mb-4 " style="min-height: 282px ;box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;">
                        <div class="row " style="    padding: 16px 4px 1px 11px;">
                            <div class="col-sm-5">
                                <button type="button" style="padding: 1px 10px; margin-left: 6px;" [style.color]="getfontColor(WeightBridge.status)" [ngClass]="getbtnColor(WeightBridge.status)" class="btn  btn-sm rounded-pill">
                  {{WeightBridge.status}}
                </button>
                            </div>
                            <div class="col-sm-7" style="    display: flex;
                justify-content: flex-end;">
                                <span style="color: black; font-size: medium;
                  font-weight: 700;     padding-top: 4px;">
                  {{WeightBridge.weightmentName}}</span>
                                <span type="button" class="btn btn-sm more_vert" style="padding-bottom: 4px;" (click)="SetWeighBridgeData(WeightBridge.status)">
                  <div class="btn-group dropstart">
                    <span class="material-icons  dropdown-toggle" style="position: relative;
                                                left: 4px;
                                                font-size: large;bottom: 3px;" data-bs-toggle="dropdown"
                      aria-expanded="false">more_vert</span>
                                <ul class="dropdown-menu" id="myDropdown">
                                    <li class="dropdown-item">
                                        <a [routerLink]="['/weighbridge-transaction', WeightBridge.weighbridgeId]" style="padding-top: 10px;padding-bottom: 10px;padding-right: 40px; color: #212529;">
                                            <span>
                            <fa-icon style="font-size: large; color: #04CF68;" [icon]="faEye">
                            </fa-icon>
                          </span> View History
                                        </a>
                                    </li>

                                    <li class="dropdown-item" (click)="ViewDisabledWeightBridge(Confirmation, WeightBridge)">
                                        <span>
                          <fa-icon style="font-size: large;color: red;" [icon]="faBan">
                          </fa-icon>
                        </span> {{weighbridgeText}} WB
                                    </li>
                                    <li class="dropdown-item" (click)="DivertedWeighBridge(DisabledWeightBridge,WeightBridge)">
                                        <img src="../../assets/images/icons/diversion.svg" style="height: 16px;" alt="" srcset=""> Divert Vehicle
                                    </li>
                                    <li class="dropdown-item" (click)="GetCameraDetail(WeightBridge.locationID, cameraLiveStream)">
                                        <fa-icon style="font-size: large; color: #000000;" [icon]="faCamera">
                                        </fa-icon>
                                        View Camera
                                    </li>
                                </ul>
                            </div>
                            </span>
                        </div>
                    </div>
                    <div class="card-body px-3 pt-2 pb-3" (onDrop)="Drop(DraggablePopup,WeightBridge)" pDroppable="dd">
                        <div *ngIf="WeightBridge.status != '' && WeightBridge.status != null &&  WeightBridge.status  != 'Under Maintenance'">
                            <ul class="list-item list-unstyled">
                                <span *ngIf="WeightBridge.status == 'Weighing' || WeightBridge.status == 'Completed'">
                    <li class="my-1 custom_row">Vehicle No <span class="float-end" style="text-decoration: underline;">
                        <a [routerLink]="['//current-transaction', WeightBridge.vehicleTransactionCode]">{{WeightBridge.vrn}}
                        </a></span>
                                </li>
                                </span>
                                <span *ngIf="WeightBridge.status == 'Weighing' || WeightBridge.status == 'Completed'">
                    <li class="my-1 custom_row">Trip Details <span
                        class="float-end">{{WeightBridge.transactionType}}</span>
                                </li>
                                </span>
                                <span *ngIf="WeightBridge.status == 'Weighing' || WeightBridge.status == 'Completed'">
                    <li class="my-1 custom_row">Weighment Details <span class="float-end">
                        {{WeightBridge.weight}}</span></li>
                                </span>
                                <span>
                    <li class="my-1 custom_row">Live Status <span class="float-end">{{WeightBridge.message}}</span>
                                </li>
                                </span>
                                <span *ngIf="WeightBridge.status == 'Error'">
                    <li class="my-1 custom_row">Error Code <span class="float-end">{{WeightBridge.errorCode}}</span>
                                </li>
                                </span>
                            </ul>
                        </div>
                    </div>
                    <div class=" px-2 pb-1" (onDrop)="Drop(DraggablePopup,WeightBridge)" pDroppable="dd" style=" min-height: 68px;margin-right: 38px;">
                        <h4 class="footer-title" style=" font-weight: 500;font-size: 14px;margin-left: 11px;color: #939393;">
                            Vehicles in queue ({{WeightBridge.vehicleQueueResponse.length}})</h4>
                        <div *ngIf="WeightBridge.vehicleQueueResponse[0] != null ">
                            <div class="row">
                                <div class="col-md-12" style="overflow-x: auto;white-space: nowrap;padding-left: 14px; margin-left: 18px;padding-left: 3px;  ">
                                    <span pDraggable="dd" (onDragStart)="dragStart(Queue,WeightBridge)" style="
                      margin-right: 4px;" *ngFor='let Queue of WeightBridge.vehicleQueueResponse let i=index'>
                      <button type="button" class="btn  btn-sm rounded-pill mb-1" style="font-size: 11px;font-weight: 500 ; border: 1px solid #383e3e;
                          padding: 2px 8px; background-color: #383e3e; color: white; "
                        (dblclick)="OpenTransaction(Queue.vehicleTransactionCode)">
                        {{Queue.vehicleNo}}
                      </button>
                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</section>

<ng-template #QueueData let-modal class="modal">
    <div class="modal-content" style="width: 133% !important;transform: translate3d(-47px, 0px, 0px);">
        <div class="modal-header " style="background-color: #0f75bc;padding: 0px 20px;">
            <h4 class="modal-title mt-3" style="color: white;font-size: large;"> Queue Details : {{weighmentName}} </h4>
            <!-- <i type="button" class="close-btn-icon" data-dismiss="modal"  aria-label="Close" (click)="modal.dismiss('Cross click')">
            </i> -->
            <button type="button" style="background-color: transparent;border: none;font-size: 31px;color: white;" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="modal.dismiss('Cross click');">&times;</span>
        </button>
        </div>
        <div class="modal-body">

            <div class="table-responsive custom-table" style="max-height: 60vh;">
                <table class="table table-striped mr-auto ml-auto table-bordered table-hover table-responsive">
                    <thead class="table-head">
                        <tr>
                            <th class="col-2 sr-no " style="width: 9%;">Sr. No</th>
                            <th (click)="sort('name')" class="col location"> Vehicle Number
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                            </th>
                            <th (click)="sort('name')" class="col location"> Trip Id
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                            </th>
                            <th (click)="sort('name')" class="col location"> Weightment Type
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                            </th>
                            <th (click)="sort('name')" class="col location"> Transaction Type
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let vehicleQueue of VehicleQueueList | orderBy: key : reverse | filter:filter; let i = index">
                            <td class="text-center">{{ i +1 }}</td>
                            <td> <a style="    color: blue;
                                cursor: pointer;
                                text-decoration: underline;" (click)="viewVehicleTransaction(vehicleQueue.vehicleNo)">
                    {{vehicleQueue.vehicleNo}} </a> </td>
                            <td> {{vehicleQueue.vehicleTransactionId}} </td>
                            <td> {{vehicleQueue.weighmentType}} </td>
                            <td> {{vehicleQueue.transactionType}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #DisabledWeightBridge let-modal class="modal">
    <div class="modal-content" style="width: 200% !important;transform: translate3d(-208px, 0px, 10px) !important;">
        <div class="modal-header " style="padding: 2px 20px; color: black;">
            <h3 class="modal-title mt-3" style="font-size: large; font-weight: 500;"> Vehicle Diversion - {{WBName}} </h3>
            <button type="button" style="background-color: transparent;border: none;font-size: 31px;" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="closeButton()">&times;</span>
        </button>
        </div>
        <div class="modal-body" style="padding: 0px !important;">
            <div class="row px-3 py-3 mt-1">
                <div class="col-md-4 ">
                    <p-selectButton [options]="vehicleSelectionType" [(ngModel)]="bulkDefault" optionLabel="label" optionValue="value" (onChange)=" setboolean($event.value)"></p-selectButton>
                </div>
                <div class="col-md-5 align_center">
                    <h3 *ngIf="IsShowWBVehicle" style="margin-bottom: 0px !important; color: #ad2020;font-size: 14px; font-style: italic; ">Please divert these vehicle(s) to different weighbridge(s).</h3>
                </div>
                <div class="col-md-3" *ngIf="BulkSelected == true && SingleSelected == false">
                    <select name="weighbridgeId" autofocus class="form-control form-select custom_Popup_select" (change)="WeighBridgeDiversionChangeOccur($event.target.value,weighBridgeId)" style="width:80%; float: right;" [(ngModel)]="weighBridgeId" [ngClass]="{ 'is-invalid': submitted }">
              <option value="undefined" disabled selected > -- Select WB -- </option>
              <option *ngFor="let wb of weighbridgeMaster" [value]="wb.weighBridgeId">
                {{wb.weighBridgeName}}</option>
            </select>
                </div>
            </div>
            <div class="row px-3 pt-2 pb-1 ">
                <div class="col-md-12">
                    <div class="table-responsive custom-table" style="max-height: 60vh !important;">
                        <table class="table table-striped mr-auto ml-auto table-bordered table-hover table-responsive">
                            <thead class="table-head">
                                <tr>

                                    <th (click)="sort('name')" class="col location"> Vehicle Number
                                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                      </span>
                                    </th>
                                    <th (click)="sort('name')" class="col location"> Trip Id
                                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                      </span>
                                    </th>
                                    <th (click)="sort('name')" class="col location"> Weightment Type
                                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                      </span>
                                    </th>
                                    <th (click)="sort('name')" class="col location"> Trip Type
                                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                      </span>
                                    </th>
                                    <th (click)="sort('name')" class="col location"> Trip Status
                                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                      </span>
                                    </th>
                                    <th (click)="sort('name')" class="col location text-center" *ngIf="!BulkSelected && SingleSelected ">
                                        Select WeighBridge
                                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                      </span>
                                    </th>
                                    <th *ngIf="BulkSelected == true && SingleSelected == false" class="col-2 sr-no text-center " style="width: 9%;"> <span *ngIf="!SingleSelected ">
                        <p-checkbox name="selectAllVehicle" inputId="selectAllVehicle" [binary]="true"
                          (onChange)="SelectAllVehicle($event)">
                        </p-checkbox>
                      </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let vehicleQueue of VehicleQueueList | orderBy: key : reverse | filter:filter; let i = index">

                                    <td class="vertical-center"> <a style="color: blue;cursor: pointer;text-decoration: underline;" (click)="viewVehicleTransaction(vehicleQueue.vehicleNo)">
                        {{vehicleQueue.vehicleNo}} </a> </td>
                                    <td class="vertical-center"> {{vehicleQueue.vehicleTransactionId}} </td>
                                    <td class="vertical-center"> {{vehicleQueue.weighmentType}} </td>
                                    <td class="vertical-center"> {{vehicleQueue.transactionType}} </td>
                                    <td class="vertical-center"> {{vehicleQueue.status}} </td>
                                    <td *ngIf="!BulkSelected && SingleSelected " class="py-1">
                                        <span style="display: flex; justify-content:center">
                        <select name="weighbridgeId" [ngClass]="{ 'is-invalid': submitted }"
                          (change)="SelectWeighbridge($event.target.value,vehicleQueue)"
                          class="form-control form-select  custom_Popup_select" style="width: 68%;">
                          <option selected value="undefine"> --Select Weighbridge --
                          </option>
                          <option *ngFor="let wb of weighbridgeMaster" [value]="wb.weighBridgeId">
                            {{wb.weighBridgeName}}</option>
                        </select>
                      </span>
                                    </td>
                                    <td class="text-center vertical-center" *ngIf="BulkSelected == true && SingleSelected == false">
                                        <p-checkbox [(ngModel)]='vehicleQueue.isChecked' name="selectVehicle" inputId="selectVehicle" (change)="WeighBridgeDiversionChangeOccur($event.target.value,weighBridgeId)" [binary]="true" (onChange)="selectSingleVehicle($event,vehicleQueue)"></p-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr style="margin: 0px;">
            <div class="row py-2 px-3">
                <div class="col-sm-3" style="display: flex;align-items: center;">
                    <p-checkbox *ngIf="IsShowWBVehicle" name="skipDiversion" inputId="skipDiversion" label="I will do this later" [binary]="true" (onChange)="skipDiversion($event)" style="vertical-align: sub;">
                    </p-checkbox>
                </div>
                <div class="col-sm-3 align_center">
                    <p *ngIf="submitted && CommonErrorMessage != null" style="color: red;">{{CommonErrorMessage}}</p>
                </div>
                <div class="offset-sm-3 col-sm-3" style="    display: flex;
        justify-content: flex-end;">
                    <button type="button" class="btn btn-secondary py-1" data-bs-dismiss="modal" (click)="closeButton()" style="background-color: #f1f1f1;border: none;color: black; font-size: small;">CLOSE</button>
                    <button type="button" class="btn btn-primary py-1" style="margin-left:17px; font-size: small;" (click)="UpdateWeighBridge()">SUBMIT </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #Confirmation let-c="close" let-d="dismiss">
    <div class="modal-header " style=" padding: 16px 18px;">
        <h4 class="modal-title" style="margin-bottom: 0px;font-size: x-large; font-weight: 500;" id="modal-basic-title">
            Confirmation</h4>
        <button type="button" class="close  " style="font-size: 31px;
        margin: -8px -5px;   background-color: transparent;
    border: none;" aria-label="Close" (click)="closeButton();">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <p style="font-size: larger;margin-bottom: 0px;" class="py-1">Are you sure, you want to {{ weighbridgeText }} this Weighbridge ?</p>
    </div>
    <div class="modal-footer py-1">
        <button type="button" class="btn btn-secondary py-1" #confirmationCancel (click)="closeButton();" style="background-color: #f1f1f1;border: none;color: black; font-size: small;">Cancel</button>
        <button type="button" class="btn btn-primary py-1" style="font-size: small;" (click)="
        ConfirmationYes(DisabledWeightBridge)">Yes</button>
    </div>
</ng-template>


<ng-template #DraggablePopup let-c="close" let-d="dismiss">
    <div class="modal-header " style=" padding: 16px 18px;">
        <h4 class="modal-title" style="margin-bottom: 0px;font-size: x-large; font-weight: 500;" id="modal-basic-title">
            Confirmation 1</h4>
        <button type="button" class="close  " style="font-size: 31px;
      margin: -8px -5px;   background-color: transparent;
  border: none;" aria-label="Close" (click)="closeButton();">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <p style="font-size: larger;margin-bottom: 0px;" class="py-1"> Are you sure, you want to move {{ this.lastdraggedVehicle?.vehicleNo}} Vehicle to WeighBridge {{this.nextdraggedWeighBridge?.weightmentName}}</p>
    </div>
    <div class="modal-footer py-1">
        <button type="button" class="btn btn-secondary py-1" (click)="closeButton();" style="background-color: #f1f1f1;border: none;color: black; font-size: small;">Cancel</button>
        <button type="button" class="btn btn-primary py-1" style="font-size: small;" (click)="
      DraggableConfirmation()">Yes</button>
    </div>
</ng-template>

<ng-template #cameraLiveStream let-c="close" let-d="dismiss">
    <div class="cameraModel">
        <div class="modal-header " style=" padding: 16px 18px;">
            <h4 class="modal-title" style="margin-bottom: 0px;font-size: x-large; font-weight: 500;" id="modal-basic-title">
                Live Feed</h4>
            <button type="button" class="close  " style="font-size: 31px;
              margin: -8px -5px;   background-color: transparent;
          border: none;" aria-label="Close" (click)="closeButton();">
        <span aria-hidden="true">×</span>
      </button>
        </div>
        <div class="modal-body vrScrollbar">
            <div class="row">
                <div class="col-6 " *ngFor="let WeightBridge of cameraDetailList;">
                    <div class="iframe-container">
                        <iframe [src]="createTructedURL(WeightBridge)" [title]="WeightBridge.accessURL" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowfullscreen allowscriptaccess="always" allow="autoplay"></iframe>
                    </div>

                </div>
            </div>
            <div class="" *ngIf="cameraDetailList?.length == 0">
                No Camera Found
            </div>
        </div>
    </div>
</ng-template>
</div>
