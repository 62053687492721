<ngx-spinner> </ngx-spinner>


        <div class="d-flex data-filter align-items-center my-2">
           <div class="col-sm-12 text-end mx-100">
            <div class="input-append me-0">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search"  placeholder="Search by keyword"
                [(ngModel)]="filter" (ngModelChange)="currentActiveAlertPage = 1">
            </div>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto tbl-formating">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr. No.</th>
                  <th (click)="sort('name')" class="col action-key">Incident No <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th (click)="sort('name')" class="col action-key">VRN <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <!-- <th (click)="sort('name')" class="col action-key">Type <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th> -->
                  <th (click)="sort('name')" class="col action-key">Driver Name <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <!-- <th (click)="sort('name')" class="col action-key">Driver Mobile No.</th> -->

                  <th (click)="sort('name')" class="col action-key">Category <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th (click)="sort('name')" class="col action-key">Sub Category <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th class="col action-key" (click)="sort('name')">Location <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <!-- <th class="col action-key">Subject
                  </th> -->
                  <th class="col action-key" (click)="sort('name')">Description <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th class="col action-key" (click)="sort('name')">Incident Time <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <!-- <th class="col action-key">Status
                  </th> -->
                  <th class="col action-key" (click)="sort('name')"> SLA Status <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let alert of alerts | orderBy: key : reverse | filter:filter | paginate: { id : 'pagination', itemsPerPage: 10, currentPage: currentActiveAlertPage }; let i = index">
                  <td>{{ (currentActiveAlertPage - 1) * pageSize + i +1 }}</td>
                  <td class="left incidentNo"><a class="click"
                      (click)="getAlertDetail(alertdetail, alert.transactionId)">{{alert.incidentNo}}</a></td>
                  <td class="left"> {{alert.vrn}}</td>
                  <!-- <td>
                    <span *ngIf="alert.vehicleType == 1">Outbound</span>
                    <span *ngIf="alert.vehicleType == 2">Inbound</span>
                    <span *ngIf="alert.vehicleType == 4">Inplant</span>
                  </td> -->
                  <td class="left" matTooltip="{{alert.driverNo}}"> {{alert.driverName}}</td>
                  <!-- <td class="left"> {{alert.driverNo}}</td> -->
                  <td class="left category">{{alert.category}}</td>
                  <td class="left"> {{alert.subCategory}}</td>
                  <td class="left"> {{alert.locationName}}</td>
                  <!-- <td class="left" matTooltip="{{alert.subject}}" matTooltipPosition="above">
                    <span class="subject">{{ (alert.subject.length>20)? (alert.subject | slice:0:20)+'..':(alert.subject)
                      }}</span>
                  </td> -->
                  <td style="max-width: 50px;" class="left " matTooltip="{{alert.description}}" matTooltipPosition="above">
                    <span class="desc"> {{ (alert.description.length>23)? (alert.description |
                      slice:0:23)+'..':(alert.description) }}</span>
                  </td>
                  <td class="left">{{alert.transactionTime | date: 'dd-MM-yyyy HH:mm'}}
                  </td>
                  <!-- <td>
                    {{alert.status}}
                  </td> -->
                  <td class="left"> {{alert.slaStatus}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls id="pagination" (pageChange)="pagechange($event)"></pagination-controls>
        </div>






<ng-template #alertdetail let-modal class="modal">
  <div class="modal-content">
    <div class="modal-header mb-0">
      <h4 class="modal-title mt-3">More Details</h4>
      <span type="button" class="close-btn-icon" aria-label="Close" (click)="modal.dismiss('Cross click')">
        X
      </span>
    </div>
    <div class="modal-body">
      <div class="table-list">
        <div class="table-responsive">
          <table class="table table-striped mr-auto ml-auto table-bordered table-hover">
            <thead class="table-head">
              <tr>
                <th class="col location">Title
                </th>
                <th class="col location">Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let alert of alertDetail">
                <td class="left">{{alert.key}}</td>
                <td class="left">
                  {{alert.value}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #history let-modal class="modal">
  <div class="modal-content">
    <div class="modal-header mb-0">
      <h4 class="modal-title mt-3">Alert Trip History</h4>
      <span type="button" class="close-btn-icon" aria-label="Close" (click)="modal.dismiss('Cross click')">
        X
      </span>
    </div>
    <div class="modal-body">

      <div class="table-list">
        <div class="table-responsive">
          <table class="table table-striped mr-auto ml-auto table-bordered table-hover">
            <thead class="table-head">
              <tr>
                <th class="col location">SLA Status</th>
                <th class="col location">Status</th>
                <th class="col location">Trip Time</th>
                <th class="col location">Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let alert of alertHistory">
                <td class="left">{{alert.slaStatus}}</td>
                <td class="left">{{alert.status}}</td>
                <td class="left">{{alert.transactionTime | date: 'dd-MM-yyyy, h:mm a'}}</td>
                <td class="left" matTooltip="{{alert.remarks}}" matTooltipPosition="above">
                  {{ (alert.remarks.length>10)? (alert.remarks | slice:0:10)+'..':(alert.remarks) }}Test
                  Neew......</td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #remark let-modal class="modal">
  <div class="modal-content">
    <div class="modal-header mb-0">
      <h4 class="modal-title mt-3">Remark In Detail</h4>
      <span type="button" class="close-btn-icon" aria-label="Close" (click)="modal.dismiss('Cross click')">
        X
      </span>
    </div>
    <div class="modal-body">
      <div class="table-list">
        <p>Test New Test Model</p>
      </div>
    </div>
    <hr class="hr mb-0">
  </div>
</ng-template>

<footer class="sticky-footer bg-black text-light mt-auto mb-0">
  <app-footer></app-footer>
</footer>
