export class VehicleMasterResponse {


    vehicleId:number;
    transporterId:number;
    rfidTagNumber:string;
    vrn:string;
    vehicleType:string;
    isVerified:boolean;
    isNonLogistic:boolean;
    permitTill:Date;
    vrDate:Date;
    numberOfWheels:string;
    engineCapacityCC:string;
    isActive:boolean;
    capacityInTons:string;
    isBlackListed:boolean;
    tareweightKg:string;
    jobMilestones: string;
    reason: string;
    description: string;
    incidentDateTime: Date;
}


