import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { error } from 'console';
import { AnyARecord } from 'dns';
import { request } from 'https';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-verification',
  templateUrl: './invoice-verification.component.html',
  styleUrls: ['./invoice-verification.component.scss']
})
export class InvoiceVerificationComponent implements OnInit {

  invoiceDetails : any;
  form : any;
  submitted = false;
  verifyNum : any;
  availableInvoices:any=null;
  Invoice:any;
  getInvoiceClick:boolean = false;
  hideInvoiceTable : boolean =false;
  public userAccessData: UserScreenAccesData;
  constructor(private kemarClientService: KemarClientService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private api: DataService) {
                this.form = this.formBuilder.group({
                  requestId:[0],
                  vrn:['', Validators.required],
                });
              this.userAccessData =this.api.getUserScreenAccessMenu('INVOICESIMULATION');
              }

  ngOnInit(): void {
    
  }

  getInvoiceDetails(){
    this.form;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.GetInvoices,this.form.value)
      .subscribe(
        response => {
          debugger
          this.availableInvoices = response;
          this.invoiceDetails = response.invoiceDetail;
          const allVerified =this.invoiceDetails.every( x => x.isVerified === true);
          if(allVerified){
            this.getInvoiceClick = false;
            var error = "Invoice Already Verified";
            Swal.fire({
              icon: 'success',
              title: '',
              text: error
            });
          }
          else{
            this.getInvoiceClick = true;
          }
          this.hideInvoiceTable = true;
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          this.onReset();
          this.getInvoiceClick = false;
          this.hideInvoiceTable = false;
        });
  }

  toggleVerification(invoice: any) {
    debugger
    invoice.isVerified = !invoice.isVerified;
    
    // this.Invoice = this.invoiceDetails.find(inv => inv.invoiceNumber === invoiceNumber)
    // this.Invoice.forEach((x:any)=>{
    //   x.isVerified = true;
    // })
    
  }
  postInvoiceVerification(){
    debugger
    this.invoiceDetails;
    this.availableInvoices.invoiceDetail === this.Invoice;
    
    this.kemarClientService.postPatch<any>(ServiceUrl.UpdateInvoiceDetailOnVehicle,this.availableInvoices)
      .subscribe(
        (response) => 
          {
            debugger
            Swal.fire({
              icon: 'success',
              title: '',
              text: response.statusMessage
            }).then(function(){
              window.location.reload();
            })
            this.spinner.hide();
          },
        (error) => {
          debugger
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.statusMessage
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        }
      )
  }
  isButtonEnabled(){
    if(this.availableInvoices !== null){
      return this.availableInvoices.invoiceDetail.every(obj => obj.isVerified);
    }else{return;}
    
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onReset() {
    this.submitted = false;
    this.form.reset();
  }

}
