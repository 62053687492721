export class WeighBridgeControllerResponse {

    Id:number;
    ipAdress:string = null;
    Actualweight:number;
    vrn: string;


}

