<app-header></app-header>
<section class="my-2" *ngIf="userAccessData[0].canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body custom-table">
        <h4 class="title-one mb-2">Simulation</h4>
        <mat-accordion id="simulation" class="example-headers-align custom-accordion" hideToggle="false" [togglePosition]="'before'">
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                RFID Reader Simulation
              </mat-panel-title>

            </mat-expansion-panel-header>



            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
              <div class="form-group ml-3">
                <input type="hidden" minlength="3" maxlength="30" class="form-control" name="RFIDTagNo"
                  formControlName="RFIDTagNo" [(ngModel)]="RFIDSimulation.RFIDTagNo" />
              </div>
              <div class="col-auto">
                    <label for="">Select Location</label>
                    <select name="deviceLocationId" class="form-control form-select" formControlName="deviceLocationId"
                      [ngClass]="{ 'is-invalid': submitted && f.deviceLocationId.errors }"
                      [(ngModel)]="RFIDSimulation.deviceLocationId">
                      <option hidden [ngValue]=null>--Choose Option--</option>
                      <option *ngFor="let location of deviceMaster" [ngValue]="location.deviceLocationId">
                        {{location.deviceName}}</option>
                    </select>
              </div>
              <div class="col-auto">
                <label for="description">Vehicle Number</label>
                <input type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }" formControlName="vrn"
                  [(ngModel)]="RFIDSimulation.vrn" />
                 </div>
              <div class="col-auto">
                  <label for="description">Remarks</label>
                  <input type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.additionalComment.errors }" formControlName="additionalComment"
                  [(ngModel)]="RFIDSimulation.additionalComment" />
              </div>

              <div class="col-auto">
                <button class="btn btn-success px-4" (click)="PostRFIDTAG()">Send</button>
              </div>
            </form>




            <!-- <form autocomplete="off" [formGroup]="form">
              <div class="form-group">
                <div class="form-group ml-3">
                  <input type="hidden" minlength="3" maxlength="30" class="form-control" name="RFIDTagNo"
                    formControlName="RFIDTagNo" [(ngModel)]="RFIDSimulation.RFIDTagNo" />
                </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-4">
                    <label for="">Select Location</label>
                    <select name="deviceLocationId" class="form-control form-select" formControlName="deviceLocationId"
                      [ngClass]="{ 'is-invalid': submitted && f.deviceLocationId.errors }"
                      [(ngModel)]="RFIDSimulation.deviceLocationId">
                      <option hidden [ngValue]=0>--Choose Option--</option>
                      <option *ngFor="let location of deviceMaster" [ngValue]="location.deviceLocationId">
                        {{location.deviceName}}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-success mt-2" (click)="PostRFIDTAG()">send</button>
                  </div>

                </div>
              </div>
            </form> -->
          </mat-expansion-panel>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Weigment Simulation
              </mat-panel-title>

            </mat-expansion-panel-header>



            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
              <div class="form-group ml-3">
                <!-- <input type="hidden" minlength="3" maxlength="30" class="form-control" name="locationld"
                    value="{{RFIDSimulation.locationGroupId}}" /> -->
              </div>
              <div class="col-auto">
                  <label for="">Select Weighbridge</label>
                  <select name="weighBridgeId" class="form-control form-select"
                    [(ngModel)]="WeighBridgeControllerResponse.ipAdress" formControlName="weighBridgeId"
                    [ngClass]="{ 'is-invalid': submitted && f.weighBridgeId.errors }">
                    <option hidden [ngValue]=0>--Choose Option--</option>
                    <option *ngFor="let location of locMapping" [ngValue]="location.deviceIP">
                      {{location.locationName}}</option>
                  </select>
              </div>
              <div class="col-auto">
                <label for="description">Vehicle Number</label>
                <input type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }" formControlName="vrn"
                  [(ngModel)]="WeighBridgeControllerResponse.vrn" />
                 </div>
                 <div class="col-auto">
                  <label for="description">Weight</label>
                      <input type="text" class="form-control" placeholder="" value=" "
                        [ngClass]="{ 'is-invalid': submitted && f.weight.errors }" formControlName="weight"
                        [(ngModel)]="WeighBridgeControllerResponse.Actualweight" />
                   </div>

              <div class="col-auto">
                <button class="btn btn-success px-4"
                      (click)="PostWeighbridge(WeighBridgeControllerResponse)">send</button>
              </div>
            </form>
          </mat-expansion-panel>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Loading Simulation
              </mat-panel-title>

            </mat-expansion-panel-header>

            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
              <div class="form-group ml-3">
                <!-- <input type="hidden" minlength="3" maxlength="30" class="form-control" name="locationld"
                    value="{{RFIDSimulation.locationGroupId}}" /> -->
              </div>
              <div class="col-auto">
                <label for=""> Status</label>
                <!-- <select name="Status"  class="form-control form-select" [(ngModel)]="PhysicalCheckResponse.Status"
                formControlName="Status" [ngClass]="{ 'is-invalid': submitted && f.Status.errors }" >
                <option hidden [ngValue]=0>--Choose Option--</option>
                <option *ngFor="let location of locMapping" [ngValue]="location.Status">
                  {{location.locationName}}</option>
              </select> -->
                <input type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.Status.errors }" formControlName="Status"
                  [(ngModel)]="InvoicingResponse.Status" />
              </div>
              <div class="col-auto">
                <label for="description">VRN</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.VRN.errors }" formControlName="VRN"
                      [(ngModel)]="InvoicingResponse.VRN" />
                 </div>
                 <div class="col-auto">
                  <label for="description">Milestone Transaction Code</label>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.milestoneTransactionCode.errors }"
                    formControlName="milestoneTransactionCode"
                    [(ngModel)]="InvoicingResponse.milestoneTransactionCode" />
                </div>
              <div class="col-auto">
                <button class="btn btn-success px-4"
                (click)="lodingResponse(InvoicingResponse)">send</button>
              </div>
            </form>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Physical Simulation
              </mat-panel-title>

            </mat-expansion-panel-header>

            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
                <div class="form-group ml-3">
                  <!-- <input type="hidden" minlength="3" maxlength="30" class="form-control" name="locationld"
                    value="{{RFIDSimulation.locationGroupId}}" /> -->
                </div>
                  <div class="col-auto">
                    <label for=""> Status</label>
                    <!-- <select name="Status"  class="form-control form-select" [(ngModel)]="PhysicalCheckResponse.Status"
                    formControlName="Status" [ngClass]="{ 'is-invalid': submitted && f.Status.errors }" >
                    <option hidden [ngValue]=0>--Choose Option--</option>
                    <option *ngFor="let location of locMapping" [ngValue]="location.Status">
                      {{location.locationName}}</option>
                  </select> -->
                    <input type="text" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.Status.errors }" formControlName="Status"
                      [(ngModel)]="PhysicalCheckResponse.Status" />
                  </div>
                  <div class="col-auto">
                    <label for="description">VRN</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.VRN.errors }" formControlName="VRN"
                      [(ngModel)]="PhysicalCheckResponse.VRN" />
                  </div>
                  <div class="col-auto">
                    <label for="description">Comment</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.Comment.errors }" formControlName="Comment"
                      [(ngModel)]="PhysicalCheckResponse.Comment" />
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-success px-4"
                      (click)="PhysicalCheckModel(PhysicalCheckResponse)">send</button>
                  </div>
            </form>
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Invoicing Simulation
              </mat-panel-title>

            </mat-expansion-panel-header>

            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
                <div class="form-group ml-3">
                  <!-- <input type="hidden" minlength="3" maxlength="30" class="form-control" name="locationld"
                    value="{{RFIDSimulation.locationGroupId}}" /> -->
                </div>
                  <div class="col-auto">
                    <label for=""> Status</label>
                    <!-- <select name="Status"  class="form-control form-select" [(ngModel)]="PhysicalCheckResponse.Status"
                    formControlName="Status" [ngClass]="{ 'is-invalid': submitted && f.Status.errors }" >
                    <option hidden [ngValue]=0>--Choose Option--</option>
                    <option *ngFor="let location of locMapping" [ngValue]="location.Status">
                      {{location.locationName}}</option>
                  </select> -->
                    <input type="text" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.Status.errors }" formControlName="Status"
                      [(ngModel)]="InvoicingResponse.Status" />
                  </div>
                  <div class="col-auto">
                    <label for="description">VRN</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.VRN.errors }" formControlName="VRN"
                      [(ngModel)]="InvoicingResponse.VRN" />
                  </div>
                  <div class="col-auto">
                    <label for="description">Milestone Transaction Code</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.InvoicingResponse.errors }"
                      formControlName="milestoneTransactionCode"
                      [(ngModel)]="InvoicingResponse.milestoneTransactionCode" />
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-success px-4" (click)="invoicingResponse(InvoicingResponse)">send</button>
                  </div>
            </form>
          </mat-expansion-panel>


          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                AX4 Notify Retrigger
              </mat-panel-title>

            </mat-expansion-panel-header>

            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
                <div class="form-group ml-3">
                </div>
                  <div class="col-auto">
                    <label for="">Milestone Code</label>
                    <select name="milestoneCode" class="form-control form-select" formControlName="milestoneCode"
                      [ngClass]="{ 'is-invalid': submitted && f.milestoneCode.errors }">
                      <option hidden [ngValue]=0>--Choose Option--</option>
                      <option *ngFor="let milestone of milestoneDetailList" [ngValue]="milestone.milestoneCode">
                        {{milestone.milestoneName}} - {{milestone.milestoneEvent}}</option>
                    </select>
                  </div>

                  <div class="col-auto">
                    <label for="description">VRN</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.VRN.errors }" formControlName="VRN" />
                  </div>

                  <div class="col-auto">
                    <button class="btn btn-success px-4" (click)="retriggerAX4Notify()">send</button>
                  </div>
            </form>
          </mat-expansion-panel>


        </mat-accordion>


      </div>
    </div>
  </div>
</section>

  <app-footer></app-footer>
