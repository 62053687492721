import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { ConfigParms } from '../common/portal-config';
import { NavItem } from '../template/menu-item/NavItemModel';
import { UserScreenAccesData } from '../models/usermodel';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  public isLoading = false;

  constructor(public session: SessionStorageService) {
  }

  //########################### Common methods call###############################
  setUserDetail(userDetails: any) {
    this.session.set('LoginResponse', userDetails);
    // console.log("In setUserDetails");
    // console.log(userDetails);
  }

  setDamageDetail(damageDetail: any) {
    this.session.set('DamageResponse', damageDetail);
  }

  getDamageDetail(): any {
    return this.session.get('DamageResponse');
  }

  getUserDetail(): any {
    return this.session.get('LoginResponse');
  }

  getUserDisplayName(): any {
    if (this.getUserDetail() == null)
      return 'Admin';
    return this.getUserDetail().firstName + " " + this.getUserDetail().lastName;
  }

  getUserRole(): any {
    //return 'admin';
    return this.getUserDetail().roleName;
  }
  getApiUrl(): string {
    return ConfigParms.APIUrl;
  }

  setUserMenu(menuData: any) {
    this.session.set('menu', menuData);
  }

  getUserMenu(): Array<NavItem> {
    return this.session.get('menu');
  }

  getUserScreenAccessMenu(screenCode: string): UserScreenAccesData {
    var userdata = this.session.get('LoginResponse');
    var filteredData = userdata.userAccess.filter(
      (x) => x.screenCode.toUpperCase() == screenCode.toUpperCase()
    );

    return filteredData;
  }

  getUserScreenAccessMenuOnRoutingURL(routingURLData : string): UserScreenAccesData {
    var userdata = this.session.get('LoginResponse');
    var foundDaata = this.findMenuItemByUrl(userdata.menuAccess, routingURLData);
    var userData = this.getUserScreenAccessMenu(foundDaata.screenCode);
    return userData;
  }

  findMenuItemByUrl(menuItems: any[], url: string): any {
    for (let item of menuItems) {
      if (item.routingURL === url) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const found = this.findMenuItemByUrl(item.children, url);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  getUserScreenMenuData(routingUrl: string) {
    var userdata = this.session.get('LoginResponse');
    var screenroutingData = userdata.menuAccess;
    var childDisplayName = '';

      for (let j = 0; j < screenroutingData.length; j++) {
        if (screenroutingData[j].children.length > 0) {
          for (let i = 0; i < screenroutingData[j].children.length; i++) {
            if (screenroutingData[j].children[i].children.length > 0) {
              childDisplayName = this.getChildRouteName(screenroutingData[j].children[i].children, routingUrl)
              if (childDisplayName != '') {
                childDisplayName = screenroutingData[j].displayName + ' > ' + childDisplayName;
                break;
              }
            }
            else {
              if (screenroutingData[j].children[i].routingURL === routingUrl) {
                childDisplayName = screenroutingData[j].displayName + ' > ' + screenroutingData[j].children[i].displayName;
                break;
              }
            }
          }
        }
    }
    return childDisplayName;
  }

  getChildRouteName(childList: any, routingUrl: string) {
    var childData = '';
    for (let i = 0; i < childList.children.length; i++) {
      if (childList.children[i].children.length > 0) {
        childData = childData + ' > ' + this.getChildRouteName(childList.children[i].children, routingUrl)
      }
      else {
        if (childList.children[i].routingURL === routingUrl) {
          childData = ' > ' + childList.children[i].displayName;
        }
      }
    }
    return childData;
  }
}
