import { filter } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import {faCheck,faClosedCaptioning} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ServiceUrl } from 'src/app/common/service-urls';
import { Bank, BANKS } from 'src/app/dashboard/demo-data';
import { MilestoneMasterResponse } from 'src/app/models/milestonemastermodel';
import { VehicleMasterResponse } from 'src/app/models/vehiclemastermodel';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { RouterModule, Routes } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { __values } from 'tslib';
// import { isNull } from '@angular/compiler/src/output/output_ast';
import { MileStoneTrackingRequest } from 'src/app/models/vehicleMilestonetrackingModel';
import { element } from 'protractor';
import { DatePipe } from '@angular/common'
import { DataService } from 'src/app/service/data.service';

const material = [MatExpansionModule];

@Component({
  selector: 'app-current-transaction',
  templateUrl: './current-transaction.component.html',
  styleUrls: ['./current-transaction.component.scss'],
  providers: [DatePipe]
})
export class CurrentTransactionComponent implements OnInit {
  milestoneActionTrackingId: any;
  isChecked: boolean;
  isShown: boolean = false;
  public toggleButton: boolean = true;
  vrn: any;
  public list: VehicleMasterResponse[];
  searchedList: any;
  VehicleMasterResponse: VehicleMasterResponse[] = [];
  vehicleMaster: any;
  vehicle: any;
  vehicle_tran: any;
  jobMilestones: any;
  $allVehicles: Observable<VehicleMasterResponse[]>;
  $filteredVehicles: Observable<VehicleMasterResponse[]>;
  public milestoneTrack: MileStoneTrackingRequest = new MileStoneTrackingRequest();
  bypassjobMileTrackingActionCode:'';
  bypassLocationCode='';
  bypassLocationId=0;
  bypassRemark = '';
  milestoneCompleted = false;

  protected _onDestroy = new Subject<void>();

  panelOpenState = false;
  faCheck = faCheck;
  faClosedCaptioning = faClosedCaptioning;
  step = 0;
  milestoneMaster: any;
  vehicleTransaction: any;
  milestoneActionsTracking: any;
  displayModal: boolean;
  TotalDiffTime: string;
  TranType: string;
  UserRole: string;

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private route: ActivatedRoute, private datePipe: DatePipe,
    public api: DataService
  ) {
    this.UserRole = api.getUserRole();
    this.route.params.subscribe((data) => {
      this.vrn = data;
    });
  }
  open(action) {
    this.displayModal = true;
    this.modalService.open(action, { size: 'sm action' });
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.GetVehilceTransaction(this.vrn);
  }

  onChangecheckbox(MilestoneTrackingId, milestoneActionsTracking, action, jobmileTrackingActionCode, locationCode, locationId) {
    //
    this.milestoneTrack.MilestoneActionTrackingId = MilestoneTrackingId;
    this.bypassjobMileTrackingActionCode = jobmileTrackingActionCode;
    this.bypassLocationCode =  locationCode;
    this.bypassLocationId = locationId;
    this.bypassRemark = jobmileTrackingActionCode.remarks;

    this.isChecked = false;
    milestoneActionsTracking.forEach((val) => {
      if (val.milestoneActionsTrackingId == MilestoneTrackingId) {
        val.isSelected = !val.isSelected;
        val.isShown = !val.isShown;
        val.isRemark = !val.isRemark;
      } else {
        val.isSelected = false;
        val.isShown = false;
        val.isRemark = true;
      }
    });
    this.displayModal = true;
    this.modalService.open(action, { size: 'sm action' });
  }

  GetVehilceTransaction(vrn) {
    var query = { vehicle: vrn.vrn };
    this.spinner.show();
    this.kemarClientService
      .get<VehicleMasterResponse>(null, ServiceUrl.GetVehilceTransaction, query)
      .subscribe(
        (response) => {

          this.spinner.hide();
          if(response == null){
            return;
          }
          this.vehicleTransaction = response;
          if(this.vehicleTransaction?.tranType == '1'){
            this.TranType = 'OutBound';
          }else if(this.vehicleTransaction?.tranType == '2'){
            this.TranType = 'InBound';
          }else if(this.vehicleTransaction?.tranType == '4'){
            this.TranType = 'InPlant';
          }

          if (
            typeof response?.jobMilestones !== 'undefined' &&
            response?.jobMilestones !== null
          ) {
            this.jobMilestones = response?.jobMilestones;
            this.TotalDiffTime = this.jobMilestones[0].totalTimeTakenToCompleteTransaction;
          }

          var pendingMilestone = this.jobMilestones.filter(x => x.status == 'Pending');
          if(this.jobMilestones != null && this.jobMilestones[this.jobMilestones.length-1].status == 'Completed'){
            this.milestoneCompleted = true;
          }
          else if(pendingMilestone.length == 0){
            this.milestoneCompleted = true;
          }
          else{
            this.milestoneCompleted = false;
          }
          // console.log(this.jobMilestones)
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  updateMilestoneTracking(jobmileTracking, locationCode, locationId) {
    //
    this.milestoneTrack.Remark = jobmileTracking.remarks;
    this.milestoneTrack.locationCode = locationCode;
    this.milestoneTrack.locationId = locationId;
    this.milestoneTrack.vrn = this.vehicleTransaction.vrn;
    this.milestoneTrack.actionCode = jobmileTracking.actionCode;

    this.spinner.show();
    this.kemarClientService
      .postPatch<any>(ServiceUrl.updateMilestoneTracking, this.milestoneTrack)
      .subscribe(
        (res) => {
          //
          Swal.fire('', res.responseMessage, 'success').then(function () {
            // window.location.reload();
            // this.GetVehilceTransaction(this.vrn);
          });
          this.GetVehilceTransaction(this.vrn);
        },
        (r) => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          });
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
 }


 updateMilestoneTrackingNew() {
  //
  // console.log(this.bypassRemark);
  this.milestoneTrack.Remark = this.bypassRemark;
  this.milestoneTrack.locationCode = this.bypassLocationCode;
  this.milestoneTrack.locationId = this.bypassLocationId;
  this.milestoneTrack.vrn = this.vehicleTransaction.vrn;
  this.milestoneTrack.actionCode = this.bypassjobMileTrackingActionCode;

  this.spinner.show();
  this.kemarClientService
    .postPatch<any>(ServiceUrl.updateMilestoneTracking, this.milestoneTrack)
    .subscribe(
      (res) => {
        //
        Swal.fire('', res.responseMessage, 'success').then(function () {
          // window.location.reload();
          // this.GetVehilceTransaction(this.vrn);
        });
        this.GetVehilceTransaction(this.vrn);
      },
      (r) => {
        Swal.fire({
          icon: 'error',
          title: '',
          text: r.error.errorMessage,
        });
        this.spinner.hide();
        // console.log(r.error.error);
      }
    );
    this.closeModalPopup();
}

public closeModalPopup() {
  this.modalService.dismissAll();
}

public panelOpen(){
  this.panelOpenState = true;
}

retriggerAX4Notify(jobMilestoneId, MilestoneCode) {
  this.spinner.show();
  var query = {
    vrn: this.vehicleTransaction.vrn,
    milestoneCode: MilestoneCode,
    JobMilestoneId: jobMilestoneId
  }
  this.kemarClientService.get<any>(null, ServiceUrl.retriggerAX4Notify,query)
    .subscribe(
      response => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: response.responseMessage
        }).then(function(){
          window.location.reload();
        })
        this.spinner.hide();
      },
      r => {
        Swal.fire({
          icon: 'error',
          title: '',
          text: r.error.responseMessage
        }).then(function(){
          window.location.reload();
        })
        this.spinner.hide();
      });
}

}
