import { BooleanInput } from "@angular/cdk/coercion";

export class CommonMasterDataResponse {
    id:number;
    dataItemId:number;
    type:string;
    parameter:string; 
    value:string; 
    isActive:boolean;
    createdBy: string;
    createdDate: Date;
    modifiedBy: string;
    modifiedDate: Date;
}
export class LocationListhistoryResponse {
    locationId: number;
    locationHistorId: number;
    locationName: string;
    isActive:boolean;
    createdOn: Date;
    remark: string;
    reason: string;
    createdBy: string;
}