<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-4"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Location Master</h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">
                search
              </span>
              <input class="form-control" type="search" name="search" placeholder="search"
                [(ngModel)]="filter" (ngModelChange)="currentActivePage = 1">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Location"
              matTooltipClass="danger-tooltip"  *ngIf="userAccessData[0]?.canCreate == true">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr. No.</th>

                  <th (click)="sort('name')">Location Name
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')">Location code
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')">Location Type
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Sequence
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Detectable By
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th class="text-center" (click)="sort('name')">Status
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th class="action-key text-center" *ngIf="userAccessData[0].canUpdate ==true"> Action
                  </th>
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let location of locationMapping | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="left">{{location.locationName}}</td>
                  <td class="left">{{location.locationCode}}</td>
                  <td class="left">{{location.locationType}}</td>
                  <td>{{location.sequence}}</td>
                  <td>{{location.detectableBy}}</td>
                  <td class="text-center">
                    <span *ngIf="location.isActive  ==true ">Active</span>
                    <span *ngIf="location.isActive  ==false ">Inactive</span>
                  </td>
                  <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
                    <span class="edit-btn-icon" type="button" matTooltip="Edit Location"
                      matTooltipClass="danger-tooltip"   (click)="editLocationMapping(edit, location)">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>



<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="form-group mb-2">

          <div class="form-group row mb-0">
            <div class="col-sm-6">


              <div class="form-group">
                <input type="hidden" class="form-control" name="locationId" value="{{location.locationId}}" />
              </div>
              <label for="locationName">Location Name <sup>*</sup></label>
              <input type="text" class="form-control" id="locationName" name="locationName" placeholder=" "
                formControlName="locationName" [(ngModel)]="location.locationName"
                [ngClass]="{ 'is-invalid': submitted && f.locationName.errors }">
              <div *ngIf="submitted && f.locationName.errors" class="invalid-feedback">
                <div *ngIf="f.locationName.errors.required">
                  <small>Location Name is required.</small>
                </div>
                <div *ngIf="f.locationName.errors.minlength">
                  <sup>*</sup> Location Name must be at least 4 characters.
                </div>
                <div *ngIf="f.locationName.errors.maxlength">
                  <sup>*</sup>Location Name must not exceed 30 characters.
                </div>
                <div *ngIf="f.locationName.hasError('whitespace')"></div>
                Milestone Name should contain only a-z,A-Z.
              </div>
            </div>
            <div class="col-sm-6">
              <label for="deviceIP">Location Code <sup>*</sup></label>
              <input type="text" class="form-control" id="deviceIP" name="locationCode" placeholder=" "
                formControlName="locationCode" [(ngModel)]="location.locationCode"
                [ngClass]="{ 'is-invalid': submitted && f.locationCode.errors }">
              <div *ngIf="submitted && f.locationCode.errors" class="invalid-feedback">
                <div *ngIf="f.locationCode.errors.required">
                  <small>Location Code is required.</small>
                </div>
                <div *ngIf="f.locationCode.errors.minlength">
                  <sup>*</sup> Location Code must be at least 4 characters.
                </div>
                <div *ngIf="f.locationCode.errors.maxlength">
                  <sup>*</sup>Location Code must not exceed 30 characters.
                </div>
                <div *ngIf="f.locationCode.hasError('whitespace')"></div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-6">
              <label for="parentLocationCode"> Parent Location Code <sup>*</sup></label>
              <select name="parentLocationCode" [(ngModel)]="location.parentLocationCode"
                class="form-control form-select" formControlName="parentLocationCode"
                [ngClass]="{ 'is-invalid': submitted && f.parentLocationCode.errors }">
                <option hidden [ngValue]=0>--Choose Option--</option>
                <option *ngFor="let location of locationMaster" [ngValue]="location.locationCode">
                  {{location.locationName}}</option>
              </select>
              <div *ngIf="submitted && f.parentLocationCode.errors" class="invalid-feedback">
                <div *ngIf="f.parentLocationCode.errors.required">
                  <small>Parent Location Code is required.</small>
                </div>
                <div *ngIf="f.parentLocationCode.hasError('whitespace')">
                  <small>Parent Location Code is required.</small>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="locationType"> Location Type <sup>*</sup></label>
              <!-- <input type="text" class="form-control" id="locationType" name="locationType" placeholder=" "
                formControlName="locationType" [(ngModel)]="location.locationType"
                [ngClass]="{ 'is-invalid': submitted && f.locationType.errors }"> -->
              <select name="locationType" [(ngModel)]="location.locationType" class="form-control form-select"
                formControlName="locationType" [ngClass]="{ 'is-invalid': submitted && f.locationType.errors }">
                <option hidden Value='' disabled>--Choose Option--</option>
                <option *ngFor="let locationType of locationTypeDll" [ngValue]="locationType.key">
                  {{locationType.key}}</option>
              </select>
              <div *ngIf="submitted && f.locationType.errors" class="invalid-feedback">
                <div *ngIf="f.locationType.errors.required">
                  <small>Location Type Code is required.</small>
                </div>
                <div *ngIf="f.locationType.errors.minlength">
                  <sup>*</sup> Location Type Code must be at least 4 characters.
                </div>
                <div *ngIf="f.locationType.errors.maxlength">
                  <sup>*</sup>Location Type Code must not exceed 30 characters.
                </div>
                <div *ngIf="f.locationType.hasError('whitespace')"></div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-6">
              <label for="detectableBy"> Detectable By <sup>*</sup></label>
              <select name="detectableBy" [(ngModel)]="location.detectableBy" class="form-control form-select"
                formControlName="detectableBy" [ngClass]="{ 'is-invalid': submitted && f.detectableBy.errors }">
                <option hidden Value='' disabled>--Choose Option--</option>
                <option *ngFor="let detectableBy of DetectableByDll" [ngValue]="detectableBy.key">
                  {{detectableBy.key}}</option>
              </select>
              <!-- <input type="text" class="form-control" id="detectableBy" name="detectableBy" placeholder=" "
                formControlName="detectableBy" [(ngModel)]="location.detectableBy"
                [ngClass]="{ 'is-invalid': submitted && f.detectableBy.errors }"> -->
              <div *ngIf="submitted && f.detectableBy.errors" class="invalid-feedback">
                <div *ngIf="f.detectableBy.errors.required">
                  <small>Detectable By is required.</small>
                </div>
                <div *ngIf="f.detectableBy.errors.minlength">
                  <sup>*</sup> Detectable By must be at least 4 characters.
                </div>
                <div *ngIf="f.detectableBy.errors.maxlength">
                  <sup>*</sup>Detectable By must not exceed 30 characters.
                </div>
                <div *ngIf="f.detectableBy.hasError('whitespace')"></div>
              </div>
              <!-- <label for="direction"> Direction <sup>*</sup></label>
              <input type="text" class="form-control" id="direction" name="direction" placeholder=" "
                formControlName="direction" [(ngModel)]="location.direction"
                [ngClass]="{ 'is-invalid': submitted && f.direction.errors }">
              <div *ngIf="submitted && f.direction.errors" class="invalid-feedback">
                <div *ngIf="f.direction.errors.required">
                  <small>Direction is required.</small>
                </div>
                <div *ngIf="f.direction.errors.minlength">
                  <sup>*</sup> Direction must be at least 4 characters.
                </div>
                <div *ngIf="f.direction.errors.maxlength">
                  <sup>*</sup>Direction must not exceed 30 characters.
                </div>
                <div *ngIf="f.direction.hasError('whitespace')"></div>
              </div> -->
            </div>
            <div class="col-sm-6">
              <label for="sequence"> Sequence <sup>*</sup></label>
              <input type="text" class="form-control" id="sequence" name="sequence" (keypress)="keyPress($event)"
                minlength=1 maxlength=3 placeholder=" " formControlName="sequence" [(ngModel)]="location.sequence"
                [ngClass]="{ 'is-invalid': submitted && f.sequence.errors }">
              <div *ngIf="submitted && f.sequence.errors" class="invalid-feedback">
                <div *ngIf="f.sequence.errors.required">
                  <small>sequence is required.</small>
                </div>
                <div *ngIf="f.sequence.errors.minlength">
                  <sup>*</sup> sequence must be at least 4 characters.
                </div>
                <div *ngIf="f.sequence.errors.maxlength">
                  <sup>*</sup>sequence must not exceed 30 characters.
                </div>
                <div *ngIf="f.sequence.hasError('whitespace')"></div>
              </div>
            </div>

          </div>

          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-6">
              <label for="maxQueueSize"> Max Queue Size <sup>*</sup></label>
              <input type="text" class="form-control" id="maxQueueSize" name="maxQueueSize"
                (keypress)="keyPress($event)" minlength=1 maxlength=3 placeholder=" " formControlName="maxQueueSize"
                [(ngModel)]="location.maxQueueSize" [ngClass]="{ 'is-invalid': submitted && f.maxQueueSize.errors }">
              <div *ngIf="submitted && f.maxQueueSize.errors" class="invalid-feedback">
                <div *ngIf="f.maxQueueSize.errors.required">
                  <small>Max Queue Size is required.</small>
                </div>

                <div *ngIf="f.maxQueueSize.errors.maxlength">
                  <sup>*</sup> Max Queue Size must be at least 1 No.
                </div>
                <div *ngIf="f.maxQueueSize.errors.maxlength">
                  <sup>*</sup>Max Queue Size must not exceed 3 No.
                </div>
                <div *ngIf="f.maxQueueSize.errors?.pattern">
                  <sup>*</sup>Max Queue Size should contain only Numbers.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="minQueueSize"> Min Queue Size <sup>*</sup></label>
              <input type="text" class="form-control" id="minQueueSize" name="minQueueSize"
                (keypress)="keyPress($event)" minlength=1 maxlength=3 placeholder=" " formControlName="minQueueSize"
                [(ngModel)]="location.minQueueSize" [ngClass]="{ 'is-invalid': submitted && f.minQueueSize.errors }">
              <div *ngIf="submitted && f.minQueueSize.errors" class="invalid-feedback">
                <div *ngIf="f.minQueueSize.errors.required">
                  <small>Min Queue Size is required.</small>
                </div>

                <div *ngIf="f.minQueueSize.errors.minlength">
                  <sup>*</sup> Min Queue Size must be at least 1 No.
                </div>
                <div *ngIf="f.minQueueSize.errors.maxlength">
                  <sup>*</sup>Min Queue Size must not exceed 3 No.
                </div>
                <div *ngIf="f.minQueueSize.errors?.pattern">
                  <sup>*</sup>Min Queue Size should contain only Numbers.
                </div>
                <div *ngIf="f.minQueueSize.hasError('whitespace')"></div>
              </div>
            </div>

          </div>
          <div class="form-group row mb-0 mt-2">
            <!-- <div class="col-sm-6">
              <label for="detectableBy"> Detectable By <sup>*</sup></label>
              <input type="text" class="form-control" id="detectableBy" name="detectableBy" placeholder=" "
                formControlName="detectableBy" [(ngModel)]="location.detectableBy"
                [ngClass]="{ 'is-invalid': submitted && f.detectableBy.errors }">
              <div *ngIf="submitted && f.detectableBy.errors" class="invalid-feedback">
                <div *ngIf="f.detectableBy.errors.required">
                  <small>Detectable By is required.</small>
                </div>
                <div *ngIf="f.detectableBy.errors.minlength">
                  <sup>*</sup> Detectable By must be at least 4 characters.
                </div>
                <div *ngIf="f.detectableBy.errors.maxlength">
                  <sup>*</sup>Detectable By must not exceed 30 characters.
                </div>
                <div *ngIf="f.detectableBy.hasError('whitespace')"></div>
              </div>
            </div> -->
            <div class="col-sm-6">
              <label for="displayName"> Display Name <sup>*</sup></label>
              <input type="text" class="form-control" id="displayName" name="displayName" placeholder=" "
                formControlName="displayName" [(ngModel)]="location.displayName"
                [ngClass]="{ 'is-invalid': submitted && f.displayName.errors }">
              <div *ngIf="submitted && f.displayName.errors" class="invalid-feedback">
                <div *ngIf="f.displayName.errors.required">
                  <small>Display Name is required.</small>
                </div>
                <div *ngIf="f.displayName.errors.minlength">
                  <sup>*</sup> Display Name must be at least 4 characters.
                </div>
                <div *ngIf="f.displayName.errors.maxlength">
                  <sup>*</sup>Display Name must not exceed 30 characters.
                </div>
                <div *ngIf="f.displayName.hasError('whitespace')"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="select-radio" [hidden]="activebuttonShow">
          <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="location.isActive"
            formControlName="isActive">
          <label for="active" class="radio-label">Active</label>

          <input class="radio-input" id="inActive" type="radio" [value]="false" [(ngModel)]="location.isActive"
            formControlName="isActive">
          <label for="inActive" class="radio-label">Inactive</label>
        </div>

        <!-- <div class="form-group mb-0 mt-3 select-radio" *ngIf="activebuttonShow">
          <label for="isActive" class="radio-btn mr-1">Active </label>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" name="isActive" [value]="true"
              [(ngModel)]="locationMapping.isActive" formControlName="isActive">
          </label>
          <label for="isActive" class="radio-btn mr-1">Inactive</label>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" name="isActive" [value]="false"
              [(ngModel)]="locationMapping.isActive" formControlName="isActive">
          </label>
        </div> -->




      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="registerLocationMapping()">Save</button>
    </div>
  </div>
</ng-template>

<footer class="sticky-footer bg-black text-light mt-auto mb-0">
  <app-footer></app-footer>
</footer>
