<header class="header">
  <div class="container-fluid">
    <div class="top-header">
      <div class="row align-items-center h-100">
        <div class="col-6" *ngIf="headerWithName == 'false'">
          <a href="#">
            <img alt="" height="54" id="pia-logo" *ngIf="plant == 'MYCOMPANY'">
            <img alt="" height="54" id="pia-logoStar" *ngIf="plant == 'STAR'">
            <img alt="" height="54" id="pia-logoJSW" *ngIf="plant == 'JSW'">
          </a>
        </div>
        <div class="col-6 text-end" *ngIf="headerWithName == 'false'">
          <a [routerLink]="['/home']">
            <img alt="" id="kemar-logo">
          </a>
        </div>

        <!-- With Plant Name  -->
        <div class="col-4" *ngIf="headerWithName == 'true'">
          <a  [routerLink]="['/dashboard']">
            <img alt="" height="54" id="pia-logo" *ngIf="plant == 'MYCOMPANY'">
            <img alt="" height="54" id="pia-logoStar" *ngIf="plant == 'STAR'">
            <img alt="" height="54" id="pia-logoJSW" *ngIf="plant == 'JSW'">
          </a>
        </div>
        <div class="col-4 mt-2 text-center" *ngIf="headerWithName == 'true'">
          <h2 class="fw-italic"><i class="plantNameHead">{{plantName}}</i></h2>
        </div>
        <div class="col-4 text-end" *ngIf="headerWithName == 'true'">
          <a [routerLink]="['/home']">
            <img alt="" id="kemar-logo">
          </a>
        </div>
    </div>
    </div>
  </div>

  <div class="navigation bg-nav">
    <!-- <button class="navbar-toggler mt-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03"
    aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation"
    (click)="toggleCollapsed()">
    <span class="navbar-toggler-icon"></span>
  </button> -->
    <!-- New Navigation with mobile menu -->
    <mat-toolbar class="header-menu menu-bar mat-elevation-z1 navbar-sub navbar-expand-md navbar-two d-block"
      id="#navbarTogglerDemo03">
      <div fxShow="true" fxHide.gt-sm="true">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <span class="example-spacer"></span>
      <div fxShow="true" fxHide.lt-md="true" class="d-flex align-self-center">
        <ul class="navbar-nav me-auto justify-content-start">
          <li *ngFor="let item of navItems" fxHide.xs>
              <span *ngIf="item.children && item.children.length > 0">
                <button class="dropdown-toggle" mat-button [matMenuTriggerFor]="menu.childMenu" [disabled]="item.disabled">
                  <mat-icon class="material-icons-sharp align-middle">{{item.menuIcon}}</mat-icon>
                  {{item.displayName}}
                </button>
                <app-menu-item #menu [items]="item.children"></app-menu-item>
              </span>
              <span *ngIf="!item.children || item.children.length === 0">
                <button mat-button [routerLink]="item.routingURL">
                  <mat-icon class="material-icons-sharp align-middle">{{item.menuIcon}}</mat-icon>
                  {{item.displayName}}
                </button>
              </span>
          </li>
        </ul>
        <span class="flexExpand"></span>

        <div class="user-profile me-auto justify-content-end align-self-center">
          <button mat-icon-buttonc [matMenuTriggerFor]="menu" class="buttMobile" fxHide fxShow.xs>
            <mat-icon>person</mat-icon>
          </button>
          <ul class="aster userprofile mb-0">
            <div class="dropdown text-white mt-0">
              <li class="nav-item dropdown">
                <a class="dropdown-toggle userDisplayName d-none d-md-block m-0" id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false" [matMenuTriggerFor]="menu" id="username">
                  <span class="profile-pic">
                    <img title="" width="15" class="" src="../../assets/images/icons/user.svg">
                  </span>
                  {{userDisplayName}}
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item [routerLink]="['/my-profile']">My Profile</button>
                      <button mat-menu-item [routerLink]="['/login']">Logout</button>
                    </mat-menu>
                  </div>
                </a>
              </li>
            </div>
          </ul>
        </div>
        </div>
      </mat-toolbar>

    <mat-sidenav-container class="container">
      <mat-sidenav #sidenav mode="over" opened="false" fxHide.gt-sm="true">
        <ul class="navbar-nav">
          <li *ngFor="let item of navItems">
            <span *ngIf="item.children && item.children.length > 0">
              <button class="dropdown-toggle" mat-button [matMenuTriggerFor]="menu.childMenu"
                [disabled]="item.disabled">
                <mat-icon class="material-icons-sharp align-middle">{{item.menuIcon}}</mat-icon>
                {{item.displayName}}
              </button>
              <app-menu-item #menu [items]="item.children"></app-menu-item>
            </span>
            <span *ngIf="!item.children || item.children.length === 0">
              <button mat-button [routerLink]="item.routingURL">
                <mat-icon class="material-icons-sharp align-middle">{{item.menuIcon}}</mat-icon>
                {{item.displayName}}
              </button>
            </span>
          </li>
        </ul>

      </mat-sidenav>
      <!-- <mat-sidenav-content>
          content
      </mat-sidenav-content> -->
    </mat-sidenav-container>


  </div>
  <!-- <router-outlet></router-outlet> -->
</header>
