import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, zip, Observer } from 'rxjs';
import { ConfigParms } from '../common/portal-config';
import { switchMap } from 'rxjs/operators';
import { saveAs } from "file-saver";
import * as JSZip from 'jszip';
import * as fileSaver from 'file-saver';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export interface QueryParams {
  [key: string]: string | number;
}

export interface Post {
  id: number;
  title: string;
  body: string;
  userId: number;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class KemarClientService {
  private readonly END_POINT: string; // usually get this from enviroment !!
  endPoint: string;
  base64Image: any;


  constructor(private http: HttpClient) {
    this.endPoint = "http://localhost:52386/api/Client/GetClientList/term";
    if (ConfigParms.IsProd) {
      this.END_POINT = ConfigParms.ProdAPIUrl;
    } else {
      this.END_POINT = ConfigParms.DevAPIUrl;
    }
  }

  /**
   *
   * * the user here can pass the return type
   *      e.g : this.serviec.getRemove<_TYPE_>(....)
   * * if the user dose not provide an id this will just get all
   * resources for a specific route
   * * this will work on get and delete request with query params filtering
   */
  get<returnType>(
    id: number | null,
    route: string,
    qp: QueryParams = {},
    method: 'get' | 'delete' = 'get'
  ): Observable<returnType> {
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http[method](
      `${this.END_POINT}/${route}${id ? '/' + id : ''}${cfqu}`
    ) as Observable<returnType>;
  }

  getbyId<returnType>(
    id: number | null,
    route: string,
    qp: QueryParams = {},
    method: 'get' | 'delete' = 'get'
  ): Observable<returnType> {
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http[method](
      `${this.END_POINT}/${route}/?id= ${id}`
    ) as Observable<returnType>;
  }

  /**
   * this method will patch or post to any route
   * you choose
   */
  postPatch<returnType>(
    route: string,
    data: any,
    id: number = null,
    method: 'post' | 'patch' = 'post',
    qp: QueryParams = {}
  ): Observable<returnType> {
    const cfqu = this.correctFormatForQueryUrl(qp);
    return this.http[method](
      `${this.END_POINT}/${route}${id ? '/' + id : ''}${cfqu}`,
      data
    ) as Observable<returnType>;
  }

  /** https://medium.com/@mohammedalrowad/creating-a-generic-http-service-in-angular-7c8169abe863
   * In the return we will attach the '?' if the user provides a query params
   * and if the user provides a null we do not need to map the array to
   * anything, we just simply returns ''.
   * if there qp dose has some keys an values
   * e.g
   * const z = {userId: 1, name: 'rowad'} then
   * this method will return ["userId=1", "name=rowad"]
   */
  private correctFormatForQueryUrl(qp: QueryParams): string {
    if (Object.keys(qp).length === 0) {
      return '';
    }
    const qpAsStr = this.mapQueryParamsToUrl(qp);
    return qpAsStr.length === 0 ? '' : `?${qpAsStr.join('&')}`;
  }

  /**
   * e.g :
   * const z = {userId: 1, name: 'rowad'} then
   * this method will return ["userId=1", "name=rowad"]
   */
  private mapQueryParamsToUrl(qp: QueryParams): Array<string> {
    return Object.keys(qp).map((key: string) => {
      return `${key}=${qp[key]}`;
    });
  }
  // getData(url: string): Observable<string> { // Common Method [ To Make blob of Image Url]
  //   return this.http
  //     .get(url, { responseType: "blob" })
  //     .pipe(switchMap(response => this.readFile(response)));
  // }

  // private readFile(blob: Blob): Observable<string> {
  //   return Observable.create(obs => {
  //     const reader = new FileReader();
  //     reader.onerror = err => obs.error(err);
  //     reader.onabort = err => obs.error(err);
  //     reader.onload = () => obs.next(reader.result);
  //     reader.onloadend = () => obs.complete();
  //     return reader.readAsDataURL(blob);
  //   });
  // }

  download(Images: any, Vrn: any) { // Downlaod Multiple Image along with folder Pass List of Image Url as Parameter
    let count = 0;
    const zip = new JSZip();
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Headers': 'Content-Type',
    //   'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    //   'Authorization': 'Bearer szdp79a2kz4wh4frjzuqu4sz6qeth8m3',
    // });
    Images.forEach((item) => {
      this.http
        .get(item.imageUrl, { responseType: "blob" })
        .subscribe((res: any) => {
          const file = new Blob([res], { type: res.type });
          const imgName = item.imageUrl.substr(item.imageUrl.lastIndexOf("/") + 1);
          zip.file(imgName, file, { base64: true });
          count++
          if (count === Images.length) {
            zip.generateAsync({ type: 'blob' }).then((content) => {
              fileSaver.saveAs(content, Vrn);
            });
          }
        });
    });
  }

  downloadImage1(imageUrl: any, imageName) {
    // let imageUrl =
    //   "http://southparkstudios.mtvnimages.com/shared/characters/celebrities/mr-hankey.png?height=165";

    this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
      // console.log(base64data);
      this.base64Image = "data:image/jpg;base64," + base64data;
      // save image to disk
      var link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", this.base64Image);
      link.setAttribute("download", imageName);
      link.click();
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  DownloadPDF(Data: any, Detail: any) {
    html2canvas(Data).then((canvas) => {

      let fileWidth = 190;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      PDF.addImage(FILEURI, 'PNG', 10, 10, fileWidth, fileHeight);
      PDF.save(Detail[0].vrn + "_" + Detail[0].vehicleTransactionCode);
    });
  }

  commonValidation(value: any): boolean {
    let ErrorList = [undefined, '', null]
    if (ErrorList.includes(value)) {
      return true
    } else {
      return false;
    }

  }


  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  keytelephoneNumbers(event: any) {

    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode == 41 || charCode == 40 || charCode == 45) {
      return true;
    }
    else {
      if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    }
  }

  keyacceptnumberAndDot(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode == 46) {
      return true;
    }
    else if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keypressInTonsValidation(event, val: any): any {

    let retValue: any = '';
    if (!this.commonValidation(val)) {
      if (val.includes(".")) {
        var afterDot = val.substr(val.indexOf('.'));
        if (afterDot.length == 1) {
          retValue = val + "000";
        }
        else if (afterDot.length == 2) {
          retValue = val + "00"
        }
        else if (afterDot.length == 3) {
          retValue = val + "0"
        }
        else {
          retValue = val;
        }
      }
      else {
        if (val.length >= 1) {
          retValue = val + ".000";
        }
      }
    }
    else {
      retValue = "false";
    }
    return retValue;
  }

  keyonDate(event: any) {
    event.preventDefault();
    return false;
  }


  yAxisBarchartMaxValue(val: number): number {

    if (val.toString().length == 1 && val > 0) {
      let tes = (val + 10).toString();
      let lastchar = tes.charAt(0);
      return +(lastchar + 0)
    }
    if (val.toString().length == 2 && val > 0) {
      let tes = (val + 20).toString();
      let lastchar = tes.charAt(0);
      return +(lastchar + 0+0)
    }
    if (val.toString().length == 3 && val > 0) {
      let tes = (val + 50).toString();
      let lastchar = tes.charAt(0);
      return +(lastchar + 0+0)
    }
  }

  readTextFile<returnType>(path: string) {
    return this.http.get(path, { responseType: 'text' });
  }
}

