<app-header></app-header>
<section class="my-4">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class=" data-filter align-items-center my-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="">
                            <h4 class="title-one mb-0"> Gate Change </h4>
                        </div>
                        <div class=" d-flex data-filter align-items-center  m_d-flex  ">
                            <div class=" text-end d-flex justify-content-end align-items-center abc">
                                <div class="mt-3">
                                    <div class="input-append">
                                        <span class="material-icons-sharp icon-in">search</span>
                                        <input class="form-control" type="search" name="search" placeholder="Search" (input)="filterchange($event.target.value)" [(ngModel)]="filter">
                                      </div>
                                  </div>
                                <div class="me-4">
                                    <label class="me-4" for="MilestonEvent">Milestone Event<sup>*</sup></label>
                                    <select class="form-control dropdown-select" [(ngModel)]="selectedMilestoneEvent"
                                            (change)="onMilestoneEventChange($event)">
                                        <option value="0">Select</option>
                                        <option value="1003">Plant Gate IN</option>
                                        <option value="1004">Plant Gate OUT</option>
                                    </select>
                                </div>
                                <div class="me-3">
                                    <label for="loc">Select Location<sup>*</sup></label>
                                    <select class="form-control form-select" [(ngModel)]="selectedLocation"
                                            (change)="onLocationChange()" [disabled]="!isMilestoneSelected">
                                        <option value="0">All</option>
                                        <option *ngFor="let loc of locationMaster" [ngValue]="loc.locationCode">{{ loc.displayName }}</option>
                                    </select>
                                </div>
                                <div class="">
                                    <button type="submit" class="modal-button btn btn-success go-btn mt-3"type="submit" (click)="Save()" [disabled]="!canSave">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-list custom-table">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover mx-auto">
                                <thead class="table-head">
                                    <tr>
                                        <th class="sr-no">Sr. No.</th>
                                        <th (click)="sort('name')">
                                            <input type="checkbox" [(ngModel)]="selectAll" (change)="selectAllVRN()">
                                            VRN
                                            <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('name')">Location Name
                                            <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th class="text-center" (click)="sort('name')">Status
                                            <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let res of updateJobMilestone| orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                                        <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                                        <td>
                                            <input type="checkbox" [(ngModel)]="res.selectedOneVrn"
                                                (change)="selectOneVRN()" /> {{res.vrn}}
                                        </td>
                                        <td class="left">{{res.locationName}}</td>
                                        <td class="left">{{res.status}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="pagination justify-content-end">
                    <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>