<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-2"*ngIf="userAccessData[0].canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Weighbridge Crosscheck Events </h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search" placeholder="Search by keyword" [(ngModel)]="filter"
                (input)="filterchange($event.target.value)">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add CrossCheck Event"
              matTooltipClass="danger-tooltip"*ngIf="userAccessData[0].canCreate ==true">
              <fa-icon [icon]="faPlus"></fa-icon>
              <!-- <span class="material-icons-round">add</span> -->
            </button>
          </div>
        </div>

        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="col-2 sr-no">Sr. No.</th>
                  <th (click)="sort('name')" class=""> Name
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">No Of Vehicle
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Schedule Start Time
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Schedule End Time
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th class="action-key text-center">Status</th>
                  <th class="action-key text-center"*ngIf="userAccessData[0].canUpdate ==true">Action</th>
                </tr>
              </thead>
              <tbody>
                <input type="hidden" />
                <tr
                  *ngFor="let crossCheck of AllcrossCheck | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td>{{crossCheck.cceName}}</td>
                  <td>{{crossCheck.expectedNoOfVehicles}}</td>
                  <td>{{crossCheck.crossCheckEventSchedule[0].scheduleTime| date: 'H:mm'}}</td>
                  <td>{{crossCheck.crossCheckEventSchedule[0].scheduleLifeTime | date: 'H:mm'}}</td>
                  <td class="text-center">
                    <span *ngIf="crossCheck.isActive  ==true ">Active</span>
                    <span *ngIf="crossCheck.isActive  ==false ">Inactive</span>
                  </td>
                  <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
                    <span class="edit-btn-icon" type="button" matTooltip="Edit CrossCheck Event" matTooltipClass="danger-tooltip"
                     (click)="editCrossCheck(edit,crossCheck) ">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}} </h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="closepop()">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="form-group">
          <div class="row">
            <div class="col-sm-6">
              <label for="locationTypeName"> Event Name <sup>*</sup></label>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.cceName.errors }" formControlName="cceName"
                [(ngModel)]="crossCheck.cceName" />
              <div *ngIf="submitted && f.cceName.errors" class="invalid-feedback">
                 <!-- <div *ngIf="f.cceName.haserrors.('whitespace')">Event Name is required.
                </div>  -->
                <div *ngIf="f.cceName.errors.minlength">
                  Name must be at least 3 characters.
                </div>
                <div *ngIf="f.cceName.errors.maxlength">
                  Name must not exceed 30 characters.
                </div>
                <div *ngIf="f.cceName.hasError('whitespace')">
                  Event Name is required.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="locationTypeName"> No Of Vehicle <sup>*</sup></label>
              <input type="text" (keypress)="acceptnumber($event, true)"  minlength="1" maxlength="2" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.expectedNoOfVehicles.errors }"
                formControlName="expectedNoOfVehicles" [(ngModel)]="crossCheck.expectedNoOfVehicles" />
              <div *ngIf="submitted && f.expectedNoOfVehicles.errors" class="invalid-feedback">
                <div *ngIf="f.expectedNoOfVehicles.errors.required">
                  Number of vehicle is required.
                </div>
                <div *ngIf="f.expectedNoOfVehicles.errors.pattern">
                  <small>Number of vehicles can not start with Zero.</small>
                </div>
              </div>
              </div>
           
            <div class="row form-group" [formGroup]="scheduleform">
              <div class="col-sm-6">
                <label for="locationId">Schedule Start Time <sup>*</sup></label>
                <input type="time" class="form-control" placeholder="HH:SS" value=""
                  [ngClass]="{ 'is-invalid': submitted && scheduleformvaliation.schedule.errors }"
                  formControlName="schedule" [(ngModel)]="crosscheckEventSchedule.ScheduleTime" style="width: 105%;" />
                <div *ngIf="submitted && scheduleformvaliation.schedule.errors" class="invalid-feedback">
                  <div *ngIf="scheduleformvaliation.schedule.errors.required">Schedule Start Time is required
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <label for="locationId" style="margin-left:12px"> Schedule End Time <sup>*</sup></label>
                <input type="time" class="form-control" placeholder="HH:SS" value=""
                  [ngClass]="{ 'is-invalid': submitted && scheduleformvaliation.scheduleLifeTime.errors }"
                  formControlName="scheduleLifeTime" style="margin-left:12px;width:105%"
                  [(ngModel)]="crosscheckEventSchedule.ScheduleLifeTime" />
                <div *ngIf="submitted && scheduleformvaliation.scheduleLifeTime.errors" class="invalid-feedback">
                  <div *ngIf="scheduleformvaliation.scheduleLifeTime.errors.required" style="margin-left: 14px;">
                    Schedule End Time is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row" > 
              <div class="col-sm-6">
                <label for="locationTypeName"> WeighBridge <sup>*</sup></label>
                <p-multiSelect [options]="AllWeighBridge" (onChange)="setValueTextArea($event)" [showHeader]="false"
                  [(ngModel)]="SelectWeighbridgeID" [ngClass]="{ 'is-invalid': submitted && f.weighbridge.errors }"
                  formControlName="weighbridge" defaultLabel="Select a Weighbridge" optionLabel="weighBridgeName">
                </p-multiSelect>
                <div *ngIf="submitted && f.weighbridge.errors" class="invalid-feedback">
                  <div *ngIf="f.weighbridge.errors.required">WeighBridge is required.
                  </div>
                </div>
                <div class="select-radio" *ngIf="!IsNewEditCase">
                  <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="crossCheck.isActive"
                    formControlName="isActive">
                  <label for="active" class="radio-label">Active</label>
                  <input class="radio-input" id="inactive" type="radio" [value]="false"
                    [(ngModel)]="crossCheck.isActive" formControlName="isActive">
                  <label for="inactive" class="radio-label">Inactive</label>
                </div>
              </div>
              <div class="col-sm-6">
                <label for="locationTypeName" style="margin-left: 13px;"> Selected WeighBridge Sequence
                  <sup>*</sup></label>
                <div class="">
                  <table
                    style="margin-left: 13px;background-color: grey;width: 104%;color: white;font-size: small; margin-top:4px ">
                    <tr>
                      <th style="padding-left:6px">WeighBridge</th>
                      <th>Sequence</th>
                    </tr>
                    <tbody style=" min-height: 50px;">
                      <tr *ngFor="let obj of WeighBridgeSelectedValue">
                        <td style="padding-left:6px">{{obj.weighBridgeName}}</td>
                        <td style="padding-left:6px">{{obj.sequence}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div>
          </div>
        </div>
      </form>

    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close" (click)="closepop()">Close</button>
       <button type="button" *ngIf="IsNewEditCase" class="modal-button btn btn-danger" aria-label="Close"
        (click)="onReset()">Reset</button> 
      <button type="submit" class="modal-button btn btn-success" (click)="registerCrossCheck(crossCheck)">Save</button>
    </div>
  </div>
</ng-template>