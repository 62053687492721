import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plant-in',
  templateUrl: './plant-in.component.html',
  styleUrls: ['./plant-in.component.scss']
})
export class PlantInComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
