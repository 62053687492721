import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LocationMappingResponse } from 'src/app/models/locationmapping';
import { faDesktop, faEdit, faLandmark, faPlus, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { debounce } from 'rxjs/operators';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'app-location-mapping',
  templateUrl: './location-mapping.component.html',
  styleUrls: ['./location-mapping.component.scss']
})
export class LocationMappingComponent implements OnInit {

  public location: LocationMappingResponse = new LocationMappingResponse();
  public userAccessData: UserScreenAccesData;


  locationMapping: any;
  locationMaster: any;
  title: any;
  faEdit = faEdit
  faDesktop = faDesktop
  faSort = faSort
  faTimes = faTimes
  faPlus = faPlus
  faLandmark = faLandmark
  form: FormGroup;
  resetbuttonShow: boolean = true;
  activebuttonShow: boolean = false;
  submitted = false;
  DetectableByDll: any = DetectableByDll;
  locationTypeDll: any = locationTypeDll;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################



  constructor(private kemarClientService: KemarClientService,
    private modalService: NgbModal,

    private formBuilder: FormBuilder,

    private spinner: NgxSpinnerService,
    private api:DataService) {
    this.form = this.formBuilder.group({
      isActive: [''],
      locationName: ['', [Validators.required, this.noWhitespaceValidator]],
      locationCode: ['', [Validators.required, this.noWhitespaceValidator]],
      parentLocationCode: ['', [Validators.required, this.noWhitespaceValidator]],
      locationType: ['', [Validators.required,]],

      sequence: ['', [Validators.required, Validators.minLength(1),
      Validators.maxLength(3), Validators.pattern("[0-9]*")]],
      displayName: ['', [Validators.required, this.noWhitespaceValidator]],
      minQueueSize: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern("[0-9]*"),
      ]],
      maxQueueSize: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern("[0-9]*"),
      ]],
      detectableBy: ['', [Validators.required]]
    });
    this.userAccessData =this.api.getUserScreenAccessMenu('LOCATIONMAPPING');
  }

  public noWhitespaceValidator(control:FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit(): void {
    this.getAllLocationMapping();
    this.getAllLocation();
  }

  getAllLocation() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        response => {
          this.locationMaster = response;
        },
        r => {
          alert(r.error.error);
        });
  }

  isDisplay = false;
  toggleDisplay() {
    this.isDisplay = !this.isDisplay;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
    this.location.isActive = true;
    this.form.controls['parentLocationCode'].setValue(0);
    this.form.controls['locationType'].setValue('');
    this.form.controls['detectableBy'].setValue('');
  }

  getAllLocationMapping() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        response => {
          this.locationMapping = response;
        },
        r => {
          alert(r.error.error);
        });
  }


  registerLocationMapping() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerLocationMapping, this.location)
      .subscribe(
        r => {
          Swal.fire('', r.responseMessage, 'success')
          this.getAllLocationMapping();
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          let msg = "";
          if (r.error.message != undefined) {
            msg = r.error.message;
          }
          else {
            msg = r.error.errorMessage;
          }
          Swal.fire({
            icon: 'error',
            title: '',
            text: msg
          })
          this.spinner.hide();
        });
  }


  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  open(content: any) {
    this.onReset();
    this.location.locationId = 0;
    this.location.locationName = '';
    this.location.locationType = '';
    this.location.locationCode = '';
    this.location.parentLocationCode = 0;
    this.location.isActive = true;
    this.location.sequence = null;
    this.resetbuttonShow = true,
      this.activebuttonShow = true;
    this.location.maxQueueSize = null;
    this.location.minQueueSize = null;
    this.location.displayName = '';
    this.location.detectableBy = '';

    this.title = "Add Location";
    this.ngbModalOptions;
    this.modalService.open(content, this.ngbModalOptions);

  }

  editLocationMapping(content, locationMapping) {
    this.onReset();
    this.location.locationId = locationMapping.locationId;
    this.location.locationName = locationMapping.locationName;
    this.location.locationType = locationMapping.locationType;
    this.location.locationCode = locationMapping.locationCode;
    this.resetbuttonShow = false,
      this.activebuttonShow = false;
    this.location.parentLocationCode = locationMapping.parentLocationCode;
    this.location.maxQueueSize = locationMapping.maxQueueSize;
    this.location.minQueueSize = locationMapping.minQueueSize;
    this.location.displayName = locationMapping.displayName;
    this.location.isActive = locationMapping.isActive;
    this.location.sequence = locationMapping.sequence;
    this.location.detectableBy = locationMapping.detectableBy;

    this.title = "Update Location";
    this.modalService.open(content, this.ngbModalOptions);
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  keyPress(event: any) {
    this.kemarClientService.keyPressNumbers(event);
  }
  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage

}

const DevicTypeDll: { key: string, value: string }[] = [
  { key: 'RFID Reader', value: 'RFID' },
  { key: 'Controller', value: 'Controller' },
  { key: 'CCTV', value: 'CCTV' },
  { key: 'Face Reader', value: 'Face Reader' },
  { key: 'LED Display', value: 'LED' },
  { key: 'PA system', value: 'PA' },
  { key: 'Breath Analysis', value: 'BA' },
  { key: 'Face Reader', value: 'FR' }
];

const DetectableByDll: { key: string, value: number }[] = [
  { key: 'RFID', value: 1 },
  { key: 'GPS', value: 2 },
];
const locationTypeDll: { key: string, value: number }[] = [
  { key: 'OSPP', value: 1 },
  { key: 'Plant Gate', value: 2 },
  { key: 'Weighbridge', value: 3 },
  { key: 'Coil Yard', value: 4 },
  { key: 'TMT Yard', value: 5 },
  { key: 'Loading', value: 6 },
  { key: 'UnLoading', value: 7 },
  { key: 'ISPP', value: 8 },
  { key: 'In Plant Gate', value: 9 },
  { key: 'Packer', value: 10 },
  { key: 'Truck Loader', value: 11 },
  { key: 'Store', value: 12 },
];




