export class VehicleParkingCallInRequest {

    
    vehicleParkingCallInId:number;
    TransactionCode:number;
    ELVCode:string;
    vrn:string;
    action: boolean;
    
} 


