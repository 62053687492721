<app-header></app-header>

<div class="container-fluid justify-content-between">
  <div class="col-md-11 py-4">
    <div class="row col mb-2">
      <div class="col">
        <h2>
          <fa-icon [icon]="faBan" class="faicon"></fa-icon> Weighbridge Status
        </h2>
      </div>


      <div class="pills-bar">


        <div class="container-tab">
          <mat-tab-group mat-align-tabs="end">

            <mat-tab label="Completed">
              <div class="table-responsive sm-table">
                <table class="table table-striped mr-auto ml-auto table-bordered table-hover table-responsive">
                  <thead class="table-head">
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th scope="col" (click)="sort('name')">Weighbridge
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th scope="col" (click)="sort('name')">No Of Vehicle
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                    </tr>
                  </thead>
                  <tbody>

                  </tbody>
                </table>
              </div>

              <div class="pagination">
                <div class="col-md-6 pagination mr-auto ml-auto" aria-label="Page navigation example">
                  <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
                </div>

              </div>
            </mat-tab>
            <mat-tab label="Pending">

              <Div>
                <div class="table-responsive">
                  <table class="table table-striped mr-auto ml-auto table-bordered table-hover table-responsive">
                    <thead class="table-head">
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col" (click)="sort('name')">Weighbridge
                          <span class="glyphicon sort-icon" *ngIf="key =='name'"
                            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th scope="col" (click)="sort('name')">No Of Vehicle
                          <span class="glyphicon sort-icon" *ngIf="key =='name'"
                            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                      </tr>
                    </thead>
                    <tbody *ngFor="let allocateVehicle of allocateVehicleCount | orderBy:'sequence'  let i=index">
                      <tr>
                        <td>{{i + 1}}</td>
                        <td (click)="toggleShow(allocateVehicle.weighBridgeId)">{{allocateVehicle.weighbridgeName}}</td>
                        <td>{{allocateVehicle.allocateCount}}</td>
                      </tr>

                    </tbody>
                  </table>
                  <table *ngIf="isShown"
                    class="table table-striped mr-auto ml-auto table-bordered table-hover table-responsive">
                    <thead class="table-head">
                      <tr>
                        <th>Vehicles</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let allocateVehicle of allocateVehicleCount ">
                      <!-- <tr *ngFor="let wb of allocateVehicleCount.weighbridgeAvailibility">
                      <td>{{wb.vrn}}</td>
                      <span class="edit-btn-icon" type="button" data-toggle="tooltip" data-placement="center" title="Edit"
                      (click)="open(edit)">
                      <fa-icon [icon]="faEye"></fa-icon>
                    </span>
                    </tr> -->
                      <tr *ngFor="let allocateVehicleC of allocateVehicle.weighbridgeAvailibility">
                        <td>{{allocateVehicleC.vrn}}</td>
                        <span class="edit-btn-icon" type="button" data-toggle="tooltip" data-placement="center"
                          title="Edit" (click)="open(edit,allocateVehicleC.weighbridgeId,allocateVehicleC.weighBridgeTransactionId)">
                          <fa-icon [icon]="faEye"></fa-icon>
                        </span>
                      </tr>


                    </tbody>
                  </table>
                </div>
              </Div>


              <div class="pagination">
                <div class="col-md-6 pagination mr-auto ml-auto" aria-label="Page navigation example">
                  <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
                </div>

              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #edit let-modal class="modal">
  <div class="modal-content">
    <div class="modal-header mb-0">
      <h4 class="modal-title mt-3"> {{title}}</h4>
      <span type="button" class="close-btn-icon" aria-label="Close" (click)="modal.dismiss('Cross click')">
        X
      </span>
    </div>
    <div class="modal-body">
      <div class="container py-5">
        <div class="row">
          <div class="col-md-12">
            <form autocomplete="off" [formGroup]="form">
              <div class="form-group">
                <div class="form-group ml-3">

                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <div class="col-sm-12">
                      <label for="WeighBridgeName">WeighBridge Allocation <sup>*</sup></label>
                      <!-- <label for="locationId">Select Location</label> -->
                      <select name="weighBridgeId" [(ngModel)]="WeighBridge.weighBridgeId"
                        class="form-control form-select" formControlName="weighBridgeId"
                        [ngClass]="{ 'is-invalid': submitted && f.weighBridgeId.errors }">
                        <option hidden [ngValue]=0>--Choose Option--</option>
                        <option *ngFor="let WeighBridge of WeighBridgeCapacity" [ngValue]="WeighBridge.weighBridgeId">
                          {{WeighBridge.weighBridgeName}} - {{WeighBridge.capacity}}</option>
                      </select>


                    </div>

                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- <hr class="hr mb-0">
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-secondary" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="registerMilestone(milestone)">Save</button>
    </div> -->
        </div>
      </div>
    </div>
    <hr class="hr mb-0">
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-secondary" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="submit"
        (click)="registerWeigbridgeAssign(WeighBridge)"
        class="modal-button btn btn-primary">Assign</button>
    </div>
  </div>
</ng-template>



<footer class="sticky-footer bg-black text-light mt-auto mb-0">
  <app-footer></app-footer>
</footer>