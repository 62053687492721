<app-header></app-header>
<section class="my-2" *ngIf="userAccessData[0]?.canRead == true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0">
              <!-- <fa-icon [icon]="faTruck" class="faicon"></fa-icon>  -->
              Blacklisting History
            </h4>
          </div>
          <mat-tab-group animationDuration="0ms" mat-align-tabs="end">
            <mat-tab label="Vehicle">
              <app-vehicle-blacklisting></app-vehicle-blacklisting>
            </mat-tab>
            <mat-tab label="Transporter">
              <app-transporter-blacklisting></app-transporter-blacklisting>
            </mat-tab>
            <mat-tab label="Driver">
              <app-driver-blacklisting></app-driver-blacklisting>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>






  <!-- <div id="grid-icon">
  <div class="das-icon"></div>
</div> -->


</section>
<app-footer></app-footer>



<!-- <app-header></app-header>
<div class="container-fluid">
  <div class="d-flex pills-bar d-none d-xl-block mt-4">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills col-12" orientation="horizontal">
      <li ngbNavItem="top">
        <a ngbNavLink (click)="loadTabComponent('VEHICLE')"> <p>Vehicle</p></a>        
      </li>
      <li ngbNavItem="middle">
        <a ngbNavLink (click)="loadTabComponent('TRANSPORTER')">Transporter</a>       
      </li>
      <li ngbNavItem="bottom">
        <a ngbNavLink (click)="loadTabComponent('DRIVER')">Driver</a>
      </li>
    </ul>  
  </div> 

<div class="d-flex mt-4 d-block d-sm-none">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs col-12" orientation="horizontal">
      <li ngbNavItem="top">
        <a ngbNavLink (click)="loadTabComponent('VEHICLE')"> <p>Vehicle</p></a>        
      </li>
      <li ngbNavItem="middle">
        <a ngbNavLink (click)="loadTabComponent('TRANSPORTER')">Transporter</a>       
      </li>
      <li ngbNavItem="bottom">
        <a ngbNavLink (click)="loadTabComponent('DRIVER')">Driver</a>
      </li>
    </ul>  
  </div> 
</div>
<footer class="sticky-footer bg-black text-light mt-auto mb-0">
  <app-footer></app-footer>
</footer>  -->