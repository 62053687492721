export class MilestoneActionMappingResponse {


milestoneId: number
milestoneActionMappingId: number;
milestoneActionId : number;
isActive : boolean;
isDependentOnPreviuos: boolean;
sequenceNumber: number;
milestoneName : string;
milestoneActionName: string;
tranType: number

}

