import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weigh-bridge-control',
  templateUrl: './weigh-bridge-control.component.html',
  styleUrls: ['./weigh-bridge-control.component.scss']
})
export class WeighBridgeControlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
