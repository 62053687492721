import { AfterViewInit, Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faAnchor, faEdit, faMapPin, faObjectGroup, faPlus, faSort, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import { UserRoleAccessMapping, AllUserRoleAccess } from 'src/app/models/userRoleMappingMasterModel';
import { DataService } from 'src/app/service/data.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';

@Component({
  selector: 'app-userRoleMappingMaster',
  templateUrl: './userRoleMappingMaster.component.html',
  styleUrls: ['./userRoleMappingMaster.component.scss']
})

export class UserRoleMappingMasterComponent implements OnInit {
  public userAccessData: UserScreenAccesData;
  userRoleMappingMaster: any;
  
  roleMaster: any;
  faEdit = faEdit;
  faTimes = faTimes
  faAnchor = faAnchor
  faPlus = faPlus
  faSort = faSort
  faInfo = faInfo
  submitted = false;
  form: UntypedFormGroup;
  singleUserAccessMapping: any;
  allUserAccessMapping: any;
  singleUserAccessResponse: any;
  userAccess: boolean[];
  ReadChecked: Boolean= false;
  CreateChecked: Boolean= false;
  UpdateChecked: Boolean= false;
  DeleteChecked: Boolean= false;
  JsonDataToShow: any[];

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 15;
  key: string = 'name'; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  
  //################################# END Pagination ###########################################

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,   
    private api:DataService) { }

  ngOnInit(): void {
    this.getAllUserRoleMapping();
    this.getAllRoles();
    this.userAccessData =this.api.getUserScreenAccessMenu('URMAP');
  }

  onSubmit(): void {
    this.submitted = true;
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }

  onClear() {
    this.singleUserAccessMapping.userAccessManagerResponse.forEach(

    )
  }

  getAllUserRoleMapping() {
    this.spinner.show();
    this.kemarClientService.get<AllUserRoleAccess>(null, ServiceUrl.getAllUserRoleAccessMapping)
      .subscribe(
        userAccessResponse => {
          this.spinner.hide();
          this.allUserAccessMapping = userAccessResponse;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  getSingleUserRoleAccess(roleId: number) {
    this.spinner.show();
    this.kemarClientService.get<UserRoleAccessMapping>(null, ServiceUrl.getSingleUserRoleAccessMapping, { roleId: roleId })
      .subscribe(
        SingleuserAccessResponse => {
          this.spinner.hide();
          //console.log(SingleuserAccessResponse.userAccessManagerResponse.filter(x=>x.userAccessManagerId != 0 ));
          //   SingleuserAccessResponse.userAccessManagerResponse = SingleuserAccessResponse.userAccessManagerResponse.filter(x=>x.userAccessManagerId != 0 );
          //this.singleUserAccessMapping.userAccessManagerResponse
          // console.log(this.singleUserAccessMapping.userAccessManagerResponse.length);
          //console.log(this.singleUserAccessMapping.userAccessManagerResponse[0]);
          // for( let i = 0; i < this.singleUserAccessMapping.userAccessManagerResponse.length; i++)
          // {
          //   console.log(this.singleUserAccessResponse.userAccessManagerResponse[i]);
          // }
          //
          //this.singleUserAccessMapping.userAccessManagerResponse = newarr;
          this.singleUserAccessMapping = SingleuserAccessResponse;
          this.singleUserAccessResponse = SingleuserAccessResponse.userAccessManagerResponse;

        },
        r => {
          this.spinner.hide();
          alert(r.message);
          // console.log("here");
          // console.log(r.message);
        });
  }

  getJsonDetail(content: any, jsonData: any) {

    this.JsonDataToShow = jsonData.userAccess;
    this.modalService.open(content, this.ngbModalOptions);
  }
  assignUserRoleAccessMapping() {

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.assignUserRoleAccessMapping, this.singleUserAccessMapping)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success')
          this.getAllUserRoleMapping();
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
        });
  }

  getAllRoles() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllRoles)
      .subscribe(
        response => {

          this.roleMaster = response;
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  editModalPopup(content: any, roleId: number, accessMapping) {
    this.spinner.show();
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false

    };
    if(accessMapping.isRead==2 ){ this.ReadChecked=true;} else {this.ReadChecked=false;}
    if(accessMapping.isCreate==2 ){ this.CreateChecked=true;} else {this.CreateChecked=false;}
    if(accessMapping.isUpdate==2 ){ this.UpdateChecked=true;} else {this.UpdateChecked=false;}
    if(accessMapping.isDelete==2 ){ this.DeleteChecked=true;} else {this.DeleteChecked=false;}

    this.getSingleUserRoleAccess(roleId);

    // this.singleUserAccessMapping.roleId = accessMapping.roleId;
    // this.singleUserAccessMapping.roleName = accessMapping.roleName;
    this.modalService.open(content, { size: 'xl' });
    this.modalService.open(ngbModalOptions);
  }
  SelectAll() {

    this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
      element.isActive = true;
      element.canCreate = true;
      element.canUpdate = true;
      element.canDeactivate = true;
    });
    this.ReadChecked=true;
    this.CreateChecked=true;
    this.UpdateChecked=true;
    this.DeleteChecked=true;
  }
  SelectReadOnly(flag: any) {
    if (flag){
      this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
        element.isActive = true;
      });
    }else{
      this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
        element.isActive = false;
      });
    }
  }
  SelectCreate(flag: any) {
    if (flag){
      this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
        element.canCreate = true;
      });
    }else{
      this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
        element.canCreate = false;
      });
    }
  }
  SelectUpdate(flag: any) {
    if (flag){
      this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
        element.canUpdate = true;
      });
    }else{
      this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
        element.canUpdate = false;
      });
    }
  }
  SelectDelete(flag: any) {
    if (flag){
      this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
        element.canDeactivate = true;
      });
    }else{
      this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
        element.canDeactivate = false;
      });
    }
  }
  ClearAll() {

    this.singleUserAccessMapping.userAccessManagerResponse.forEach((element: any) => {
      element.isActive = false;
      element.canCreate = false;
      element.canUpdate = false;
      element.canDeactivate = false;
    });
    this.ReadChecked=false;
    this.CreateChecked=false;
    this.UpdateChecked=false;
    this.DeleteChecked=false;
  }
  change(read:any, val:any){

    if(read==="read"){ this.ReadChecked=false;}
    if(read==="create"){ this.CreateChecked=false;}
    if(read==="update"){ this.UpdateChecked=false;}
    if(read==="remove"){ this.DeleteChecked=false;}
  }


  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  public closeModalPopup() {
    this.modalService.dismissAll();
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [

];
