import { Component, OnInit } from '@angular/core';
import { faDownload, faFileAlt, faMapPin, faSort, faInfo, faSearch } from '@fortawesome/free-solid-svg-icons';

import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
// import { GateMasterResponse } from 'src/app/models/gatemastermodel';
// import { LaneMasterResponse } from 'src/app/models/lanemastermodel';
import { ApiTrackingReportModel } from 'src/app/models/reportmodel';
import { ExcelService } from 'src/app/service/excel.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'app-apiTracking-report',
  templateUrl: './apiTracking-report.component.html',
  styleUrls: ['./apiTracking-report.component.scss']
})
export class ApiTrackingReportComponent implements OnInit {

  faMapPin = faMapPin
  faDownload = faDownload
  faFileAlt = faFileAlt
  faSort = faSort
  faInfo = faInfo
  faSearch = faSearch
  apiTrackingReport: any;
  gateMaster: any;
  laneMaster: any;
  dataForExcel = [];
  // lane:LaneMasterResponse=new LaneMasterResponse();
  apiTrackingReportModel: ApiTrackingReportModel = new ApiTrackingReportModel();
  public userAccessData: UserScreenAccesData;
  transTypeDll: any = transTypeDll;
  JsonDataToShow: any[];
  JsonErrorDataToShow: any[];
  JsonDataStatusToShow: any[];
  JsonTypeToShow: any[];

  fromDate: any;
  toDate: any;
  today:Date;


  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################

  constructor(private kemarClientService: KemarClientService,
    private excelService: ExcelService, private modalService: NgbModal,
    private api:DataService,
    private datePipe : DatePipe) {
      this.today = new Date();
     }

  ngOnInit(): void {
    this.fromDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.getApiTrackingReport();
    this.userAccessData =this.api.getUserScreenAccessMenu('APITRACKINGREPORT')
  }

  getApiTrackingReport() {
    var query = {
      fromDate: this.fromDate,
      toDate: this.toDate,
    }
    this.kemarClientService.get<any>(null, ServiceUrl.getApiTrackingReportWithDate, query)
      .subscribe(
        response => {
          this.apiTrackingReport = response;
        },
        r => {
          // console.log(r.error.error);
        });
  }

  exportToExcel() {

    this.dataForExcel = [];
    this.gateMaster.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })
    // console.log(this.gateMaster)

    let gateData = {
      title: 'Vehicle Gate Report',
      data: this.dataForExcel,
      headers: Object.keys(this.gateMaster[0])
    }

    this.excelService.exportExcelForGate(gateData);
  }

  getJsonDetail(content: any, jsonData: any) {
    this.JsonDataToShow = jsonData.requestJson;
    this.JsonErrorDataToShow = jsonData.responseMessage;
    this.JsonDataStatusToShow = jsonData.transactionStatus;
    this.JsonTypeToShow = jsonData.apiType;
    this.modalService.open(content, { size: 'lg' });
    this.modalService.open(this.ngbModalOptions);

  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

}
const transTypeDll: { key: string, value: string }[] = [
  { key: 'Outbound', value: 'Outbound' },
  { key: 'Inbound', value: 'Inbound' }
];
