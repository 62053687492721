<app-header></app-header>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0">
              <!--<fa-icon [icon]="faAnchor" class="faicon"></fa-icon>-->
              {{title}}</h4>
          </div>
          <mat-tab-group animationDuration="10ms" mat-align-tabs="end" (selectedTabChange)="loadTabComponent($event.index)">
            <mat-tab label="Milestone">
              <app-milestone-master></app-milestone-master>
            </mat-tab>
            <mat-tab label="Milestone Action">
              <app-milestone-action></app-milestone-action>
            </mat-tab>
            <mat-tab label="Milestone Action Mapping" >
              <app-milestone-action-mapping></app-milestone-action-mapping>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
