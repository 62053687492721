import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";

import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faBan, faEdit, faMapPin, faPlus, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import { VehicleBlacklistingResponse } from 'src/app/models/vehicleBlacklisting';
@Component({
  selector: 'app-vehicle-blacklisting',
  templateUrl: './vehicle-blacklisting.component.html',
  styleUrls: ['./vehicle-blacklisting.component.scss']
})
export class VehicleBlacklistingComponent implements OnInit {

  vehicleBlacklisting: any[];
  Blacklisting: any;
  BlacklistingReasonForVehicle: any;
  title: any;
  faEdit = faEdit;
  faTimes = faTimes
  faBan = faBan
  faMapPin = faMapPin
  faPlus = faPlus
  faSort =faSort
  submitted = false;
  resetbuttonShow:  boolean = true;
  activebuttonShow:  boolean = false;
  form: UntypedFormGroup;


  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  VehicleBlack: VehicleBlacklistingResponse = new VehicleBlacklistingResponse;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton') closebutton;

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService, private modalService: NgbModal) {

      this.form = this.formBuilder.group({
        vrn: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
            Validators.pattern("[a-zA-Z0-9-]*")
          ]
        ],
        vehicleBlacklistingId: [''],
        description:[''
        ,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30)
        ]],
        isBlackListed: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30)
          ]
        ],
        incidentDate: ['',[Validators.required]],
        isActive: [true],
      });
    }

  ngOnInit(): void {
    this.getAllVehicleBlackListShow();
  }

  getAllVehicleBlackListShow() {

    this.spinner.show();
   this.kemarClientService.get<VehicleBlacklistingResponse>(null, ServiceUrl.getAllVehicleBlackListShow)
      .subscribe(
        response => {
          this.VehicleBlack= response;
          this.spinner.hide();
          // console.log(response);
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

 ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

}
