<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="mt-3">
    <div class="container">
        <div class="card">
            <div class="card-body">
                <div class="row data-filter align-items-center my-2">
                    <div class="col-sm-4">
                        <h4 class="title-one mb-0">Inplant Transaction</h4>
                    </div>
                    <hr>
                    <form autocomplete="off" [formGroup]="elvForm">
                        <div>
                            <div class="row">
                                <div class="col-sm-6 modal-body">
                                    <div class="form-group row mb-1">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="fw-bold customdropdown" for="VRN">Truck Number <span
                                                        class="text-danger">*</span></label><br>
                                                <!-- <p-dropdown [options]="vehicleMasterData"  [(ngModel)]="jobAllocationForInplant.vrn" formControlName="vrn"
                                                    optionLabel="vrn" optionValue="vrn" [filter]="true" filterBy="vrn" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Select a Truck Number"
                                                    [ngClass]="{ 'is-invalid ng-dirty': submitted && f['vrn'].errors }">
                                                    <ng-template let-vehicle pTemplate="item">
                                                        <div class="vehicle-item">
                                                            <div>{{vehicle.vrn}}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown> -->

                                                <ng-select
                                            [items]="vehicleMasterData"
                                            class="form-control form-select"
                                            bindLabel="vrn" 
                                            bindValue="vrn"
                                            [(ngModel)]="jobAllocationForInplant.vrn"  
                                            formControlName="vrn"            
                                            [virtualScroll]="true" 
                                            notFoundText="No vendors found"
                                            appendTo="body"
                                            (scrollToEnd)="loadVendors()"
                                            (search)="onSearchVendor($event)"
                                            #select>
                                            <ng-template let-vehicle pTemplate="item">                      
                                                <span class="d-flex">{{vehicle[i].vrn}}</span>                        
                                            </ng-template>
                                            </ng-select>


                                                <div *ngIf="f['vrn'].errors" class="invalid-feedback">
                                                    <div *ngIf=" f['vrn'].errors">
                                                        <small>VRN is required.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group customdropdown">
                                                <label class="fw-bold" for="DriverCode">Driver Name <span
                                                        class="text-danger">*</span></label><br>
                                                <!-- <p-dropdown [options]="driverMasterData"
                                                [(ngModel)]="jobAllocationForInplant.drivercode" [ngModelOptions]="{standalone: true}"
                                                formControlName="drivercode"
                                                    optionLabel="name" optionValue="driverCode" [filter]="true"
                                                    filterBy="name" placeholder="Select a Driver Name"
                                                    [ngClass]="{ 'is-invalid ng-dirty': submitted && f['drivercode'].errors }">
                                                    <ng-template let-vehicle pTemplate="item">
                                                        <div class="vehicle-item">
                                                            <div>{{vehicle.name}}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown> -->

                                                <ng-select
                                                    [items]="driverMasterData"
                                                    class="form-control form-select"
                                                    bindLabel="name" 
                                                    bindValue="driverCode"
                                                    formControlName="DriverCode"            
                                                    [virtualScroll]="true" 
                                                    notFoundText="No vendors found" 
                                                    appendTo="body"
                                                    (scrollToEnd)="loadDriver()"
                                                    (search)="onSearch($event)"
                                                    #select>
                                                    <ng-template let-driver pTemplate="item">                      
                                                        <span class="d-flex">{{driver[i].name}}</span>                        
                                                    </ng-template>
                                                    </ng-select>
                                                <div *ngIf=" f['drivercode'].errors" class="invalid-feedback">
                                                    <div *ngIf=" f['drivercode'].errors">
                                                        <small>Driver Name is required.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row mb-0 mt-2">
                                        <div class="col-sm-6">
                                            <label class="fw-bold" for="Doctype">Doc Type <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="DO/PO/STO"
                                                  formControlName="documentType"
                                                [ngClass]="{ 'is-invalid': submitted && f['documentType'].errors }"
                                                autocomplete="off">
                                            <span class="text-danger fw-bold" *ngIf="docTypeIsWorng">Doc Type must be
                                                DO,PO,STO only</span>

                                            <div *ngIf="  f['documentType'].errors" class="invalid-feedback">
                                                <div *ngIf=" f['documentType'].errors">
                                                    <small>Doctype is required.</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <label class="fw-bold" for="documentnumber">Document Number <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control" id="DocumentNumber"
                                                  formControlName="documentnumber" placeholder
                                                [ngClass]="{ 'is-invalid': submitted && f['documentnumber'].errors }">
                                            <div *ngIf=" f['documentnumber'].errors" class="invalid-feedback">
                                                <div *ngIf=" f['documentnumber'].errors">
                                                    <small>Document Number is required.</small>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="form-group row mb-0 mt-2">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="fw-bold" for="ProductCode">Product<span
                                                        class="text-danger">*</span></label>
                                                <select class="form-control" id="ProductCode"
                                                    formControlName="ProductCode"
                                                    [ngClass]="{ 'is-invalid': submitted && f['ProductCode'].errors }"
                                                   (change)="setProductName()">
                                                    <option Value=' ' [disabled] [selected]>---Choose Option---</option>
                                                    <option *ngFor="let pro of productData" [ngValue]="pro.productCode">
                                                        {{pro.productName}}
                                                    </option>
                                                </select>
                                                <div *ngIf=" f['ProductCode'].errors" class="invalid-feedback">
                                                    <div *ngIf=" f['ProductCode'].errors">
                                                        <small>Product is required.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label class="fw-bold" for="Nooftrips"> No.of.Trips <sup
                                                    class="text-danger">*</sup></label>
                                            <input type="text" class="form-control" placeholder="no.of trips"
                                                formControlName="nooftrips"
                                                [ngClass]="{ 'is-invalid': submitted && f['nooftrips'].errors }">
                                            <div *ngIf=" f['nooftrips'].errors" class="invalid-feedback">
                                                <div *ngIf=" f['nooftrips'].errors">
                                                    <small>No.Of trips required.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row mb-0 mt-2">
                                        <div class="col-sm-6">
                                            <label class="fw-bold" for="UOM">UOM<span
                                                    class="text-danger">*</span></label>
                                            <select class="form-control" id="ProductCode" [(ngModel)]="UOMValue" [ngModelOptions]="{standalone: true}"
                                                [ngModelOptions]="{standalone: true}">
                                                <option hidden Value='' disabled>--Choose Option--</option>
                                                <option value="bags">Bags</option>
                                                <option value="mt">MT</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group" [hidden]="UOMValue != 'bags'">
                                                <label class="fw-bold" for="Quantity">Number of Bags<span
                                                        class="text-danger">*</span></label>
                                                <input type="number" class="form-control"  max="9999"
                                                    id="Quantity" placeholder="Entry Number of Bags"
                                                    formControlName="Quantity" autocomplete="off"
                                                    [ngClass]="{ 'is-invalid': submitted && f['Quantity'].errors }"
                                                    maxlength="4">
                                                    <div *ngIf=" f['Quantity'].errors"class="invalid-feedback">
                                                        <div *ngIf=" f['Quantity'].errors">
                                                            <small>Number of Bags required.</small>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div class="form-group" [hidden]="UOMValue != 'mt'">
                                                <label class="fw-bold" for="Weight">Weight<span
                                                        class="text-danger">*</span></label>
                                                <input type="number" class="form-control" min="1" max="9999" id="Weight"
                                                    placeholder="Entry Weight" formControlName="Weight" [(ngModel)]="Products.Weight"
                                                    [ngClass]="{ 'is-invalid': submitted && f['Weight'].errors }">
                                            </div>

                                        </div>
                                    </div>
                                    <div class="form-group row mb-0 mt-2">
                                        <div class="col-sm-6">
                                            <label class="fw-bold" for="tareweightfrequesncy"> Tareweightfrequncy <sup
                                                    class="text-danger">*</sup></label>
                                            <select name="tareweightfrequesncy" class="form-control form-select"
                                            formControlName="tareweightfrequncy"

                                                [ngClass]="{ 'is-invalid': submitted && f.tareweightfrequncy.errors }">
                                                <option *ngFor="let weight of Tareweightfrequncy"
                                                    [ngValue]="weight.value">
                                                    {{weight.key}}</option>
                                            </select>
                                            <div *ngIf="f['tareweightfrequncy'].errors" class="invalid-feedback">
                                                <div *ngIf=" f['tareweightfrequncy'].errors">
                                                    <small>Tareweightfrequesncy is required.</small>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-sm-6 mt-3">
                                    <div class="">
                                        <div class="">
                                            <div class="">
                                                <div class="form-group">
                                                    <label class="fw-bold"
                                                        for="milestoneTemplateDataChooseOption">Milestone
                                                        Template</label>
                                                    <select class="form-control" id="milestoneTemplateDataChooseOption"
                                                        [(ngModel)]="milestoneTemplateDataChooseOption"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (change)="selectMilestoneGroup()">
                                                        <option hidden Value='' disabled>--Select Milestone
                                                            Template--</option>
                                                        <option *ngFor="let temp of milestoneWorkFlow"
                                                            [ngValue]="temp.workFlowCode">
                                                            {{temp.workFlowCode}} - {{temp.workFlowName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="fw-bold m-2">
                                            <li *ngFor="let milestone of milestoneTemplate2 let i = index">
                                                <span>{{milestone.sequence}}</span> &nbsp;&nbsp;
                                                <span> {{milestone.milestoneName}}</span> - <span
                                                    *ngIf="milestone.LocationName != ''">{{milestone.locationName}}</span>
                                                <span *ngIf="milestone.LocationName == ''"> No Location Selected</span>
                                            </li>
                                        </ul>
                                        <p class="text-danger fw-bold" >
                                            {{detailForMilestone}}</p>
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-primary my-3"
                                                [hidden]="!milestoneReadyToSend"
                                                (click)="sendMilestoneWithWorkFlowGroupName()">Submit</button>
                                        </div>
                                        <br>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
