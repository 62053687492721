import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder,FormGroup, Validators,FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { CommonMasterDataResponse, LocationListhistoryResponse } from 'src/app/models/commonmasterdatamodel';
import { LocationMappingResponse } from 'src/app/models/locationmapping';
import { LocationStatusHistoryResponse } from 'src/app/models/locationstatusresponsemodel'
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lane-control',
  templateUrl: './lane-control.component.html',
  styleUrls: ['./lane-control.component.scss']
})
export class LaneControlComponent implements OnInit {

  locationMaster: any;
  WeighBridgeMaster: any;
  weighbridgeSimulation: any;
  submitted = false;
  panelOpenState = false;
  form: FormGroup;
  locMapping: any;
  LocationMappingMaster: any;
  LocationStatusMaster: any;
  LocationStatus: any;
  Status: any;
  Reason: any;
  Remark: string;
  IsActive: any;
  filter = '';
  pageSize = 10;
  currentActivePage: number = 1;
  key: string = 'name'; //set default
  reverse: boolean = false;
  public LocationList: LocationListhistoryResponse = new LocationListhistoryResponse();
  public userAccessData: UserScreenAccesData;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  public CommonMasterList: CommonMasterDataResponse = new CommonMasterDataResponse();
  public LocationStatusList: LocationStatusHistoryResponse = new LocationStatusHistoryResponse();
  IsShowActive: boolean;
  IsDe_Active: boolean;
  ReasonDropboxDisplayMessage : string = "Select Reason";
  items: any;
  LocationDeactivationReasonList: any;
  LocationActivationReasonList: any;
  StatusChangeReasonList: any;  //this is going to be a placeholder, will point to activation/deactivation reason  as per IsActive = true or false

  locationStatusReasonsMapping: any;
  LocationHistoryList: LocationListhistoryResponse;

  constructor(private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder,

    private kemarClientService: KemarClientService,   private api:DataService) {

   {

      this.form = this.formBuilder.group({

        locationId:[0, [Validators.required]],
        Reason : ['', [Validators.required]],
        Remark:[
          '',
          [
            Validators.pattern("^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]$")
          ]
        ],

  })
  this.userAccessData =this.api.getUserScreenAccessMenu('LOCCONTROL')
}
  }

  ngOnInit(): void {
    this.getChildLocationList();
    this.getLocationActivationReasonList();
    this.getLocationDeactivationReasonList();
    this.getAllLocationListShow();

    this.enableInputControls(false);
  }
  getChildLocationList() {
    this.kemarClientService.get<LocationMappingResponse>(null, ServiceUrl.getChildLocationList)
      .subscribe(
        response => {
          this.LocationMappingMaster = response;
          this.locMapping = this.LocationMappingMaster.filter(x => x.locationName == 'Controller');
        },
        r => {
          alert(r.error.error);
        });
  }

  getLocationDeactivationReasonList(){
    this.kemarClientService.get<CommonMasterDataResponse>(null,ServiceUrl.getLocationDeactivationReasonList)
    .subscribe(
      response => {
        this.LocationDeactivationReasonList = response;
      },
      r=>{
        alert(r.error.error);
      }
    )
  }

  getLocationActivationReasonList(){
    this.kemarClientService.get<CommonMasterDataResponse>(null,ServiceUrl.getLocationActivationReasonList)
    .subscribe(
      response => {
        this.LocationActivationReasonList = response;
      },
      r=>{
        alert(r.error.error);
      }
    )
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  locationSelection(LocationID: number) {

    let locarr  = this.LocationMappingMaster.find((obj) => obj.locationId == LocationID);
    let isActive = locarr.isActive;
    this.IsActive = isActive;
    if (isActive) {
      this.IsShowActive = false;
      this.IsDe_Active = true;
      this.ReasonDropboxDisplayMessage = "Select Deactivation Reason";
      this.StatusChangeReasonList = this.LocationDeactivationReasonList;
    }
    else {
      this.IsShowActive = true;
      this.IsDe_Active = false;
      this.ReasonDropboxDisplayMessage = "Select Activation Reason";
      this.StatusChangeReasonList = this.LocationActivationReasonList;
    }


    this.LocationStatusList.locationId = LocationID;
    this.enableInputControls(true);
  }

  enableInputControls(enable : boolean)
  {
    if (enable )
    {
    this.form.controls["Reason"].enable();
    this.form.controls["Remark"].enable();
    }
    else
    {
      this.form.controls["Reason"].disable();
      this.form.controls["Remark"].disable();
    }
  }

  getAllLocationListShow() {

    this.spinner.show();
   this.kemarClientService.get<LocationListhistoryResponse>(null, ServiceUrl.locationList)
      .subscribe(
        response => {
          //this.driver=response;
          this.LocationHistoryList= response;
         this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  PostStatus() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.LocationStatusList.isActive = this.IsActive;
    var body = this.LocationStatusList;
    this.kemarClientService.postPatch<any>(ServiceUrl.activateDeactivateLocation,body)
      .subscribe(
        res => {
          this.form.reset();
          this.submitted = false;
          Swal.fire('', res.responseMessage, 'success')
          this.getChildLocationList();
          this.IsShowActive = false;
          this.IsDe_Active = false;
          this.LocationStatusList.Reason = '';
          this.enableInputControls(false);
          this.getAllLocationListShow();

        },
        r => {

          this.spinner.hide();
        });


  }

}
