
export class TransitSLAResponse {

    transitSLAMasterId: number;
    fromEventCode: string;
    toEventCode: string;
    eventName: string;
    alertTimeInMinutes: number;
    exceptionTimeInMinutes: number;
    ticketSLAInMinutes: number;
    ticketPriority:string;
    transitType: String;
    isActive: boolean;
    description: string;
    subject: string;
 
  
}