import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";

import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faBan, faEdit, faMapPin, faPlus, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TransporterMasterResponse } from 'src/app/models/transportermastermodel';
@Component({
  selector: 'app-transporter-blacklisting',
  templateUrl: './transporter-blacklisting.component.html',
  styleUrls: ['./transporter-blacklisting.component.scss']
})
export class TransporterBlacklistingComponent implements OnInit {


  title: any;
  faEdit = faEdit;
  faTimes = faTimes
  faBan = faBan
  faMapPin = faMapPin
  faPlus = faPlus
  faSort =faSort
  submitted = false;
  resetbuttonShow:  boolean = true;
  activebuttonShow:  boolean = false;
  form: UntypedFormGroup;

   //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  public TransporterBlackList:TransporterMasterResponse=new TransporterMasterResponse();

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton') closebutton;

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal) {
      this.form = this.formBuilder.group({
        name:['',Validators.required],
        plantCode:['',Validators.required],
        mobileNo:['',Validators.required],
        reason:['',Validators.required],
        description:['',Validators.required],
        incidentDate:['',Validators.required],


        })
  }

  ngOnInit(): void {
    this.getAllTransporterBlackListShow();
  }

  getAllTransporterBlackListShow() {

    this.spinner.show();
   this.kemarClientService.get<TransporterMasterResponse>(null, ServiceUrl.getAllTransporterBlackListShow)
      .subscribe(
        response => {
          //this.driver=response;
          this.TransporterBlackList= response;
         this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }
  // registertransporterBlacklisting() {
  //
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   this.spinner.show();
  //   this.kemarClientService.postPatch<any>(ServiceUrl.registerTransporterBlacklisting, this.transporterBlacklisting)
  //     .subscribe(
  //       res => {
  //         Swal.fire('', res.message, 'success')
  //         this.getAllTransporters();
  //         this.closeModalPopup();
  //         this.spinner.hide();
  //       },
  //       r => {
  //         Swal.fire({
  //           icon: 'error',
  //           title: '',
  //           text: r.error.errorMessage
  //         })
  //         this.spinner.hide();
  //         console.log(r.error.error);
  //         console.log(r);
  //       });
  // }
  // public closeModalPopup() {
  //   this.modalService.dismissAll();
  // }

  // onSubmit(): void {
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   console.log(JSON.stringify(this.form.value, null, 2));
  // }

  // onReset() {
  //   this.submitted = false;
  // }
  // open(content: any) {
  //   let ngbModalOptions: NgbModalOptions = {
  //     backdrop: 'static',
  //     keyboard: false
  //   };
  //    this.title="Add Transporter Blacklisting";
  //    this.activebuttonShow = false;
  //    this.resetbuttonShow = true;
  //   this.modalService.open(content, ngbModalOptions);

  // }

  // editTransporterBlacklisting(content, transporterBlacklisting){
  //   this.title="Update Transporter Blacklisting";
  //   this.activebuttonShow = true;
  //   this.resetbuttonShow = false;
  //   this.transporterBlacklisting.name = transporterBlacklisting.name;

  // }

}
