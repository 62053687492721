import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";

import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faBan, faEdit, faMapPin, faPlus, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DriverBlackListhistoryResponse } from 'src/app/models/driverblacklisthistoryresponse';
import { DriverMasterResponse } from 'src/app/models/drivermastermodel';
@Component({
  selector: 'app-driver-blacklisting',
  templateUrl: './driver-blacklisting.component.html',
  styleUrls: ['./driver-blacklisting.component.scss']
})
export class DriverBlacklistingComponent implements OnInit {

  //vehicleBlacklisting: any;
  //driverBlacklisting: any[];
  //BlacklistingReason: any;
  title: any;
  driver: any;
  faEdit = faEdit;
  faTimes = faTimes
  faBan = faBan
  faMapPin = faMapPin
  faPlus = faPlus
  faSort =faSort
  submitted = false;
  form: UntypedFormGroup;
  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  public BlackList: DriverBlackListhistoryResponse = new DriverBlackListhistoryResponse();
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton') closebutton;

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService, private modalService: NgbModal) {

      this.form = this.formBuilder.group({
        driverName: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
            Validators.pattern("[a-zA-Z0-9-]*")
          ]
        ],
        driverLiceneseNo: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
            Validators.pattern("[a-zA-Z0-9-]*")
          ]
        ],
        description:[''
        ,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
          Validators.pattern("[a-zA-Z0-9-]*")
        ]],
        isBlackListed: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
            Validators.pattern("[a-zA-Z0-9-]*")
          ]
        ],
       IncidentDate: [
          '',
          [
            Validators.required,

          ]
        ],
        isActive: [
          '',
          [
            Validators.required,
          ]
        ],
      });
    }

  ngOnInit(): void {
   this. getAllDriverBlackListShow();
  }


  // onSubmit(): void {
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   console.log(JSON.stringify(this.form.value, null, 2));
  // }

  // onReset() {
  //   this.submitted = false;
  // }
  // open(content: any) {
  //   this.title = "Add  Driver Blacklisting";
  //   let ngbModalOptions: NgbModalOptions = {
  //     backdrop: 'static',
  //     keyboard: false
  //   };

  //   this.modalService.open(content, ngbModalOptions);

  // }

  // editDriver(content: any, driver) {
  //   this.title = "Edit  Driver Blacklisting";
  //   let ngbModalOptions: NgbModalOptions = {
  //     backdrop: 'static',
  //     keyboard: false
  //   };

  //   this.modalService.open(content, ngbModalOptions);
  // }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  getAllDriverBlackListShow() {

  this.spinner.show();
 this.kemarClientService.get<DriverBlackListhistoryResponse>(null, ServiceUrl.getAllDriverBlackListShow)
    .subscribe(
      response => {

        //this.driver=response;
        this.BlackList= response;
       this.spinner.hide();
      },
      (r) => {
        this.spinner.hide();
        alert(r.error.error);
        // console.log(r.error.error);
      }
    );
}
  // registervehicleBlacklisting() {
  //
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   this.spinner.show();
  //   this.kemarClientService.postPatch<any>(ServiceUrl.registerdriverBlacklisting, this.BlackList)
  //     .subscribe(
  //       res => {
  //         Swal.fire('', res.message, 'success')
  //         this.getAllDriverBlackList();
  //         this.closeModalPopup();
  //         this.spinner.hide();
  //       },
  //       r => {
  //         Swal.fire({
  //           icon: 'error',
  //           title: '',
  //           text: r.error.errorMessage
  //         })
  //         this.spinner.hide();
  //         console.log(r.error.error);
  //         console.log(r);
  //       });
  // }
}
