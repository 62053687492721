import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router} from '@angular/router';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

import { KemarClientService } from '../service/kemar-client.service';
import { ForgotPasswordRequest, ForgotPassword, ResetPasswordRequest } from '../models/usermodel';
import { ServiceUrl } from '../common/service-urls';
import { ConfigParms } from '../common/portal-config';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss', './style.css']
})
export class ForgotPasswordComponent implements OnInit {
  faUser=faUser;
  faKey =faKey;
  form : UntypedFormGroup;
  formModel : ForgotPassword;
  forgotPasswordResponse : any ;
  showPasswordSetDialog = false;
  enableOTPOnUI = ConfigParms.EnableOTPOnUI;
  otpmessage = "";


  constructor(private kemarClientService: KemarClientService , private router: Router) {

  }

  ngOnInit(): void {
    this.formModel = new ForgotPassword();
  }

  getOTP()
  {
    let forgotPasswordRequest = new ForgotPasswordRequest();
    forgotPasswordRequest.userName = this.formModel.userName;

    this.kemarClientService.postPatch<string>(ServiceUrl.forgotPassword, forgotPasswordRequest)
    .subscribe(response => {
      // console.log(response);
      this.forgotPasswordResponse = response;
      this.showPasswordSetDialog = true;
      this.otpmessage =  "(OTP : " + this.forgotPasswordResponse.forgetPasswordOTP + ")" ;
    },
    r => {
      if ( r.status == 0){
        Swal.fire("Connection error, please check your connection and try again" , "" , "error");
      }
      else {
        Swal.fire(r.error.errorMessage, "", "error");
      }

    },);
  }


  setNewPasswordFormSubmit() {
    if ( this.formModel.newPassword != this.formModel.confirmPassword)
    {
      Swal.fire("Password and Confirm Password do not match" , "" , "error");
    }
    else
    {
      this.setNewPassword();
    }
  }

  setNewPassword() {
    let resetPasswordRequest = new ResetPasswordRequest();
    resetPasswordRequest.token = this.formModel.otp;
    resetPasswordRequest.password = this.formModel.newPassword;
    resetPasswordRequest.confirmPassword = this.formModel.confirmPassword;

    this.kemarClientService.postPatch<string>(ServiceUrl.resetPassword, resetPasswordRequest)
    .subscribe(response => {
      // console.log(response);
      Swal.fire("Password reset successfully, please login with new password" , "" , "success");
      this.router.navigate(['/']);
    },
    r => {
      Swal.fire("OTP you have entered is wrong or expired, please try again", "", "error");
      this.router.navigate(['/']);
    });
  }


}
