import { Component, OnInit, ViewChild } from '@angular/core';
import { faSort, faEdit, faPlus, faDoorClosed } from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ServiceUrl } from 'src/app/common/service-urls';
import Swal from 'sweetalert2';
import { DataService } from 'src/app/service/data.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';

@Component({
  selector: 'app-packer-master',
  templateUrl: './packer-master.component.html',
  styleUrls: ['./packer-master.component.scss']
})
export class PackerMasterComponent implements OnInit {
  packerData: any[];
  faSort = faSort;
  faEdit = faEdit;
  faPlus = faPlus;
  faDoorClosed = faDoorClosed;
  filter = '';
  pageSize = 5;
  key: string = 'name'; //set default
  reverse: boolean = false;
  title: string;
  submitted: boolean;
  display: boolean;
  form: any;
  KemarService: any;
  showActiveButton: boolean=false;
  public userAccessData: UserScreenAccesData;
  locationMapping: any;
  ProductMaster: any;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  currentActivePage: number = 1;
  packerForm!: FormGroup;
  resetbuttonShow: boolean = false;
  @ViewChild('closebutton') closebutton;
  tempcurrentActivePage: number = 1;

  constructor(private kemarClientService: KemarClientService,

    private spinner: NgxSpinnerService, private fb: FormBuilder,

    private modalService: NgbModal,private api:DataService) { }
  ngOnInit(): void {
    this.getAllPackerData();
    this.getAllLocationMapping();
    this.getProductMaster();
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  open(content: any) {
    debugger;
    this.submitted = false;
    this.reset();
    this.title = "Add Packer Data";
    this.display = true;
    this.resetbuttonShow = true;
    this.showActiveButton = false;
    this.modalService.open(content, this.ngbModalOptions);
  }
  editPacker(content: any, packer: any) {
    this.title = "Update Packer Data";
    this.reset();
    this.packerForm.patchValue(packer);
    this.showActiveButton = true;
    this.resetbuttonShow = false;
    this.modalService.open(content, this.ngbModalOptions);
  }
  onReset() {
    this.submitted = false;
    this.packerForm.reset();
    this.packerForm.controls['packingLocationId'].setValue('');
    //this.packerForm.controls['category'].setValue('');
  }
  reset() {
    this.packerForm = this.fb.group({
      packingLocationId:[0],
      locationId: ['', Validators.required],
      locationName:[''],
      dispatchPoint: ['', Validators.required],
      runningMaterial: ['', Validators.required],
      tlPoint: ['', [Validators.required]],
      isActive: [true],


    })
    this.userAccessData =this.api.getUserScreenAccessMenu('PackerMaster');
  }

  get f(): { [key: string]: AbstractControl } {
    return this.packerForm.controls;
  }

  
  closeModalPopup() {
    this.modalService.dismissAll();
  }

  getAllPackerData() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAllPackerData)
      .subscribe(
        response => {
          this.packerData = response;
        },
        r => {
          alert(r.error.error);
        }
      );
  }
  pagechange = (event: number) => {
     this.tempcurrentActivePage = this.currentActivePage = event;
    }

  getAllLocationMapping() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllPackerLocation)
      .subscribe(
        (response) => {
          this.locationMapping = response;
        },
        (r) => {
          alert(r.error.error);
        }
      );
  }
  getProductMaster() {
    this.kemarClientService.get<any>(null, ServiceUrl.getallProductCategorylist)
    //this.kemarClientService.get<any>(null, ServiceUrl.getallProductMaster)
      .subscribe(
        response => {
          this.ProductMaster = response;
        },
        r => {
          alert(r.error.error);
        });
  }

  SavePackerData() {
    this.submitted = true;
    debugger;
    if (this.packerForm.invalid) {
      return;
    }
    debugger
    let body = this.packerForm.value;
    this.kemarClientService.postPatch<any>(ServiceUrl.SavePackerData, body)
      .subscribe(
        (res) => {
          debugger;
          Swal.fire('', res.responseMessage, 'success');
          this.getAllPackerData();
          this.closeModalPopup();
          this.spinner.hide();
        },
        (r) => {
          debugger;
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          })
          this.spinner.hide();
          console.log(r.error.error);
        })

    this.display = false;



  }

}
