import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faCalendar,
  faCalendarAlt,
  faTachometerAlt,
  faRecycle,
} from '@fortawesome/free-solid-svg-icons';
import { Chart } from 'angular-highcharts';
import {
  faDoorClosed,
  faEdit,
  faPlus,
  faSort,
  faTimes,
  faTruck,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ReplaySubject, Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Bank, BANKS } from './demo-data';
import { take, takeUntil } from 'rxjs/operators';
import { DashboardFiter } from '../models/dashboardmodel';
import { query } from '@angular/animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  faTachometerAlt = faTachometerAlt;
  faCalendarAlt = faCalendarAlt;
  faRecycle = faRecycle;
  dashboardResponse: any;
  locationMaster: any;
  dashboardData: any;
  locationMasterDATA: any;
  dashboardCardResponse: any;
  successfulInParkingPercent: any;
  OutParkingPercent: any;
  successfulInPlantPercent: any;
  outPlantPercent: any;
  vehicleInParkingStyle: any;
  vehicleInPlantStyle: any;
  inGateChart: Chart;
  outGateChart: Chart;
  OutGateChart: Chart;
  faEdit = faEdit;
  faTimes = faTimes;
  faPlus = faPlus;
  faSort = faSort;
  faTruck = faTruck;
  faUser = faUser;
  today: Date = new Date();
  cardTransactionTimeLine = 'Today';

  data: any;
  dashboard: DashboardFiter = new DashboardFiter();

  displayedColumns: string[] = [
    'position',
    'vrn',
    'location',
    'txnNo',
    'txnType',
    'txnTime',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  public locationCtrl: UntypedFormControl = new UntypedFormControl();
  public locationFilterCtrl: UntypedFormControl = new UntypedFormControl();

  @ViewChild('singleSelect') singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  bChart: any;
  bchartLegend: any;
  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  vehicleMonthlyTran: any;
  vehicleOutboundMonthlyTran: any;
  vehicleInternalMonthlyTran: any;
  vehicleInboundMonthlyTran: any;
  vehicleMonthlyTatStr: any;
  vehicleMonthlyMinTatStr: any;
  vehicleMonthlyMaxTatStr: any;
  intervalMode: any;
  TatDayCount: '1';
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  selectedLocation= 'All';
  TATSelectionData= '1';
  TransactionSelectionData = '1';
  ParkingCapacity=1000;
  PlantCapacity=500;

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    clearInterval(this.intervalMode);
  }

  constructor(private kemarClientService: KemarClientService) {}

  ngOnInit(): void {
    this.getDashboardCardData();
    // this.getMainDashboard('All');
    this.getParkingCapacity();
    this.getPlantCapacity();
    this.getAllLocations();
    let query = { DayWise: this.TatDayCount };
    this.TATSelectionChange(query);
    this.getDashboardCardData();
    this.intervalMode = setInterval(() => {
      let query = { DayWise: this.TatDayCount };
      this.TATSelectionChange(query);
      this.getDashboardCardData();
      this.getMainDashboard(this.selectedLocation);
      this.MonthlyTransactionChange();
    }, 2000);
  }

  getParkingCapacity(){
    var query = {
      "key":"PlantDetail",
      "Parameter":"ParkingCapacity"
    }
    this.kemarClientService
      .get<any>(null, ServiceUrl.getParticularCommonMasterData, query)
      .subscribe(
        (res) => {
          if(res != null){
            this.ParkingCapacity = res.value;
          }
        },
        (r) => {}
      );
  }

  getPlantCapacity(){
    var query = {
      "key":"PlantDetail",
      "Parameter":"PlantCapacity"
    }
    this.kemarClientService
      .get<any>(null, ServiceUrl.getParticularCommonMasterData, query)
      .subscribe(
        (res) => {
          if(res != null){
            this.PlantCapacity = res.value;
          }
        },
        (r) => {}
      );
  }

  one: number = 1;
  getDashboardCardData() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.getDashboardCardData)
      .subscribe(
        (response) => {
          this.dashboardCardResponse = response;
          this.vehicleInParkingStyle =
            'width:' +
            this.dashboardCardResponse[0].vehicleInParkingPercent +
            '%';
          this.vehicleInPlantStyle =
            'width:' +
            this.dashboardCardResponse[0].vehicleInPlantPercent +
            '%';
          this.calculatePieChartPercentages();
          // this.mappedMontlyTransactionData(true);
          this.TATDataMapping(true);
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
  }
  mappedMontlyTransactionData(flag: boolean, res: any = null) {
    if (flag) {
      this.vehicleMonthlyTran =
        this.dashboardCardResponse[0].vehicleMonthlyTran;
      this.vehicleInboundMonthlyTran =
        this.dashboardCardResponse[0].vehicleInboundMonthlyTran;
      this.vehicleOutboundMonthlyTran =
        this.dashboardCardResponse[0].vehicleOutboundMonthlyTran;
      this.vehicleInternalMonthlyTran =
        this.dashboardCardResponse[0].vehicleInternalMonthlyTran;
    } else {
      this.vehicleMonthlyTran = res.monthlyTotal;
      this.vehicleInboundMonthlyTran = res.monthlyInBoard;
      this.vehicleOutboundMonthlyTran = res.monthlyOutBoard;
      this.vehicleInternalMonthlyTran = res.monthlyInternal;
    }
  }

  MonthlyTransactionSelectionChange(event :any){
    this.TransactionSelectionData = event.target.value;
    if (event.target.value == 3) {
      this.cardTransactionTimeLine = 'Monthly';
    } else if (event.target.value == 2) {
      this.cardTransactionTimeLine = 'Weekly';
    } else {
      this.cardTransactionTimeLine = 'Today';
      this.TransactionSelectionData = '1';
    }
    this.MonthlyTransactionChange();
  }

  MonthlyTransactionChange() {
    if(this.TransactionSelectionData == undefined || this.TransactionSelectionData == '' || this.TransactionSelectionData == null){
      this.TransactionSelectionData = '1';
    }
    let query = { DayWise: this.TransactionSelectionData };
    this.kemarClientService
      .get<any>(null, ServiceUrl.getDashBoardMonthlyTransactionData, query)
      .subscribe(
        (res) => {
          this.mappedMontlyTransactionData(false, res);
        },
        (r) => {}
      );
  }

  TATDataMapping(flag: boolean, res: any = null) {
    if (flag) {
      // this.vehicleMonthlyTatStr = this.dashboardCardResponse[0].vehicleMonthlyTatStr;
      // this.vehicleMonthlyMinTatStr = this.dashboardCardResponse[0].vehicleMonthlyMinTatStr;
      // this.vehicleMonthlyMaxTatStr = this.dashboardCardResponse[0].vehicleMonthlyMaxTatStr;
    } else {
      //alert(res.monthlyTatStr + " " + res.monthlyMinTatStr + " " + res.monthlyMaxTatStr );
      this.vehicleMonthlyTatStr = res.monthlyTatStr;
      this.vehicleMonthlyMinTatStr = res.monthlyMinTatStr;
      this.vehicleMonthlyMaxTatStr = res.monthlyMaxTatStr;
    }
  }

  TATSelectionChangeEventHandler(event: any) {
    this.TatDayCount = event.target.value;
    let query = { DayWise: this.TatDayCount };
    this.TATSelectionChange(query);
  }

  TATSelectionChange(query) {
    this.kemarClientService
      .get<any>(null, ServiceUrl.getDashboardTATTransactionData, query)
      .subscribe(
        (res) => {
          this.TATDataMapping(false, res);
        },
        (r) => {}
      );
  }

  getMainDashboard(locationcode) {
    if(locationcode == undefined || locationcode == '' || locationcode == null){
      locationcode = 'All';
    }
    this.dashboard.locationCode = locationcode;
    this.kemarClientService
      .postPatch<any>(ServiceUrl.getMainDashboard, this.dashboard)
      .subscribe(
        (response) => {
          this.dashboardResponse = response;
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
  }

  onLocationCodeClick(LocationCode) {
    this.selectedLocation = LocationCode;
    this.getMainDashboard(LocationCode);
  }
  getMainDashboardReload() {
    this.getAllLocations();
    this.getMainDashboard(this.selectedLocation);
  }

  getAllLocations() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        (response) => {
          this.locationMasterDATA = response;
        },
        (r) => {
          alert(r.error.error);
        }
      );
  }

  calculatePieChartPercentages() {
    let sum =
      this.dashboardCardResponse[0].vehicleInParkingtodays +
      this.dashboardCardResponse[0].vehicleParkingOutTodays +
      this.dashboardCardResponse[0].vehicleInPlantTodays +
      this.dashboardCardResponse[0].vehiclePlantOutTodays;

    this.successfulInParkingPercent = Math.round(
      (this.dashboardCardResponse[0].vehicleInParkingtodays / sum) * 100
    );
    this.OutParkingPercent = Math.round(
      (this.dashboardCardResponse[0].vehicleParkingOutTodays / sum) * 100
    );
    this.successfulInPlantPercent = Math.round(
      (this.dashboardCardResponse[0].vehicleInPlantTodays / sum) * 100
    );
    this.outPlantPercent = Math.round(
      (this.dashboardCardResponse[0].vehiclePlantOutTodays / sum) * 100
    );
  }

  dataBinding() {
    this.bChart = {
      labels: ['Parking', 'Plant'],
      datasets: [
        {
          label: 'Entry',
          backgroundColor: '#38B3B4',
          data: [this.data.parkingEntryCount, this.data.parkingOutCount],
        },
        {
          label: 'Exit',
          backgroundColor: '#FF8956',
          data: [this.data.parkingEntryCount, this.data.parkingOutCount],
        },
      ],
    };
  }
}

export interface PeriodicElement {
  vrn: string;
  position: number;
  location: string;
  txnNo: string;
  txnType: string;
  txnTime: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: 1,
    vrn: 'MH 05 EJ 5664',
    location: 'Plant In',
    txnNo: 'T10067',
    txnType: 'Weighbridge',
    txnTime: 'December 16 2021, 2:30 pm',
  },
  {
    position: 2,
    vrn: 'MH 01 EJ 5004',
    location: 'Tare Weight',
    txnNo: 'T267009',
    txnType: 'Weighbridge',
    txnTime: 'December 16 2021, 2:15 pm',
  },
  {
    position: 3,
    vrn: 'MH 06 EJ 5603',
    location: 'Loading',
    txnNo: 'T387775',
    txnType: 'Weighbridge',
    txnTime: 'December 16 2021, 2:00 pm',
  },
  {
    position: 4,
    vrn: 'MH 03 EJ 6964',
    location: 'Gross Weight',
    txnNo: 'T48347',
    txnType: 'Weighbridge',
    txnTime: 'December 16 2021, 1:30 pm',
  },
  {
    position: 5,
    vrn: 'MH 04 EJ 2364',
    location: 'Plant Out',
    txnNo: 'T7684',
    txnType: 'Weighbridge',
    txnTime: 'December 16 2021, 1:15 pm',
  },
];
