<app-header></app-header>
<section class="my-2" >
  <!-- *ngIf="userAccessData[0].canRead ==true" -->
  <div class="container-fluid" *ngIf="userAccessData[0].canRead ==true">
    <div class="card">
      <div class="card-body custom-table">
        <h4 class="title-one mb-2">Simulation</h4>
        <hr>

        <h3>RFID Reader Simulation</h3>
            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
                <div class="form-group ml-3">
                    <input type="hidden" minlength="3" maxlength="30" class="form-control" name="RFIDTagNo"
                    formControlName="RFIDTagNo" [(ngModel)]="RFIDSimulation.RFIDTagNo" />
                </div>
                <div class="col-md-3">
                    <label for="">Select Location</label>
                    <select name="deviceLocationId" class="form-control form-select" formControlName="deviceLocationId"
                        [ngClass]="{ 'is-invalid': submitted && f.deviceLocationId.errors }"
                        [(ngModel)]="RFIDSimulation.deviceLocationId">
                        <option hidden [ngValue]=0>--Choose Option--</option>
                        <option *ngFor="let location of deviceMaster" [ngValue]="location.deviceLocationId">
                        {{location.deviceName}}</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label for="description">Vehicle Number</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }" formControlName="vrn"
                    [(ngModel)]="RFIDSimulation.vrn" />
                </div>
                <div class="col-md-2">
                    <label for="description">Remarks</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.additionalComment.errors }" formControlName="additionalComment"
                    [(ngModel)]="RFIDSimulation.additionalComment" />
                </div>

                <div class="col-md-2">
                    <button class="btn btn-success px-4" (click)="PostRFIDTAG()">Send</button>
                </div>
            </form>

        <hr>

            <h3>Weigment Simulation</h3>
            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
                <div class="form-group ml-3">
                <!-- <input type="hidden" minlength="3" maxlength="30" class="form-control" name="locationld"
                    value="{{RFIDSimulation.locationGroupId}}" /> -->
                </div>
                <div class="col-md-3">
                    <label for="">Select Weighbridge</label>
                    <select name="weighBridgeId" class="form-control form-select"
                        [(ngModel)]="WeighBridgeControllerResponse.ipAdress" formControlName="weighBridgeId"
                        [ngClass]="{ 'is-invalid': submitted && f.weighBridgeId.errors }">
                        <option hidden [ngValue]=null>--Choose Option--</option>
                        <option *ngFor="let location of locMapping" [ngValue]="location.deviceIP">
                        {{location.locationName}}</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label for="description">Vehicle Number</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }" formControlName="vrn"
                    [(ngModel)]="WeighBridgeControllerResponse.vrn" />
                </div>
                <div class="col-md-2">
                <label for="description">Weight</label>
                    <input type="number" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.weight.errors }" formControlName="weight"
                    [(ngModel)]="WeighBridgeControllerResponse.Actualweight" />
                </div>

                <div class="col-md-2">
                    <button class="btn btn-success px-4"
                        (click)="PostWeighbridge(WeighBridgeControllerResponse)">send</button>
                </div>
            </form>
      </div>
    </div>
  </div>
</section>

  <app-footer></app-footer>
