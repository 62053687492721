export class DeviceLocationMappingResponse {

    deviceLocationMappingId:number;
    locationId:number;
    deviceIP : string;
    direction:string;
    deviceName:string;
    locationName:string;
    remark:string;
    isActive:boolean;
    createdBy:string;
    createdDate:string;
    modifiedBy:string;
    modifiedDate:string;
    antenna: string;
    deviceType:string;
    lane:string;
    accessURL:string;
    snapcaptureURL:string;
    boomUpUrl:string;

}

