import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { DriverMasterResponse } from 'src/app/models/drivermastermodel';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: 'app-frs-enrollment',
  templateUrl: './frs-enrollment.component.html',
  styleUrls: ['./frs-enrollment.component.scss'],
})
export class FrsEnrollmentComponent implements OnInit {
  driverMaster: any[] = [];
  driverForm: UntypedFormGroup;
  selectedDriverName: any;
  FRDRegister: any;
  Please: boolean = false;
  enroll: boolean = false;
  Sync: boolean = false;
  selectedDriver: any;
  value: number = 0;
  progressBar: boolean = false;
  submitted = false;
  visible: boolean = false;
  driverSize :number = 100;
  @ViewChild('video', { static: true }) videoElement: ElementRef;
  video: any;
  stream: MediaStream;
  driverBatch: number = 0;
  searchText : string = null;
  currentActiveVendor: number = 0;
  currentVendorPlaceholder = 'Select a Driver';
  searchTextDriver : string = null;
  tempTextDriver :string;
  isUniqueDriver: boolean = false;
  @ViewChild('select') select: NgSelectComponent;
  constructor(
    private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.driverForm = this.formBuilder.group({
      DriverCode: ['', Validators.required],
      DeviceIP: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.submitted=false;
    this.getDriverMaster();
    this.GetFRDRegister();

    this.video = this.videoElement.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          this.stream = stream;
          this.video.srcObject = stream;
          this.video.play();
        })
        .catch((err) => console.error('Error accessing camera:', err));
    }
  }

  captureImage() {
    const canvas = document.createElement('canvas');
    canvas.width = this.video.videoWidth;
    canvas.height = this.video.videoHeight;
    const context = canvas.getContext('2d');

    if (context) {
      context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
      const imageDataUrl = canvas.toDataURL('image/png');

      // Call a method to send the image to the API
      this.saveImage(imageDataUrl);
    }
  }

  saveImage(imageDataUrl: string) {
    var driverCode = this.driverForm.controls['DriverCode'].value;

    var body ={
      id: driverCode,
      imageData: imageDataUrl
    }
    // Send the captured image data to the API
    this.kemarClientService.postPatch(ServiceUrl.SavePersonImage, body)
      .subscribe(
        response => {
          // console.log('Image saved successfully:', response);
          // Handle success
        },
        error => {
          console.error('Error saving image:', error);
          // Handle error
        }
      );
  }

  ngOnDestroy() {
    this.closeCamera();
  }

  private closeCamera() {
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach(track => track.stop());
    }
  }

  showDialog() {
    this.submitted=true;
    if (this.driverForm.invalid) {
      return;
    }
    this.visible = true;
  }

  get f1(): { [key: string]: AbstractControl } {
    return this.driverForm.controls;
  }

  Enroll() {
    this.submitted=true;
    if (this.driverForm.invalid) {
      return;
    }
    this.visible = false;
    this.Please = true;
    this.enroll = false;
    var body = this.driverForm.value;
    this.kemarClientService
      .postPatch<any>(ServiceUrl.EnrollDriverImageInFRDevice, body)
      .subscribe(
        (res) => {
          if(res.statusCode == 200 || res.statusCode == 201){
            Swal.fire('', res.responseMessage, 'success');
            this.captureImage();
            this.Sync = true;
            this.enroll = false;
            this.Please = false;
            this.submitted=false;
          }
          else{
            Swal.fire('', res.errorMessage, 'error');
            this.Sync = false;
            this.Please = false;
            this.enroll = true;
            this.submitted=false;
          }
        },
        (err) => {
          Swal.fire('', err.errorMessage, 'error');
            this.Sync = false;
            this.Please = false;
            this.enroll = true;
            this.submitted=false;
        }
      );
  }

  SyncDetails() {
    this.progressBar = true;
    var body = this.driverForm.value;
    this.kemarClientService
      .postPatch<any>(ServiceUrl.SyncUserPhotoToAllDevice, body)
      .subscribe(
        (res) => {
          this.progressBar = false;
          if(res.statusCode == 200 || res.statusCode == 201){
            Swal.fire('', res.responseMessage, 'success');
          }
          else{
            Swal.fire('', res.errorMessage, 'error');
          }
        },
        (err) => {
          this.progressBar = false;
          Swal.fire('', err.errorMessage, 'error');
        }
      );
  }

  Delete() {
    var body = this.driverForm.value;
    this.kemarClientService
      .postPatch<any>(ServiceUrl.DeleteEnrollUserFromFR, body)
      .subscribe(
        (res) => {
          if(res.statusCode == 200 || res.statusCode == 201){
            Swal.fire('', res.responseMessage, 'success');
          this.driverForm.reset();
          }
          else{
            Swal.fire('', res.errorMessage, 'error');
          }
        },
        (err) => {
          Swal.fire('', err.errorMessage, 'error');
        }
      );
  }

  getDriverMaster() {
    // this.spinner.show();
    if(this.searchTextDriver!= '' && this.searchTextDriver != null){
      if(this.tempTextDriver != this.searchTextDriver){
         this.tempTextDriver = this.searchTextDriver;
         this.driverBatch = 0;
         this.isUniqueDriver= true;
         this.driverMaster = [];
      }
      else{
        this.isUniqueDriver= false;
      }
    }
    const body = {
      page: this.driverBatch,
      size: this.driverSize,
      searchTerm:this.searchTextDriver != '' && this.searchTextDriver != null? this.searchTextDriver:'' };
    this.kemarClientService.get<any>(null, ServiceUrl.GetDriverWithPagination,body).subscribe(
      (response) => {
        if(this.searchTextDriver!= '' && this.searchTextDriver != null){
          if(this.isUniqueDriver){
            this.driverMaster  = response;
          }
         else{
          this.driverMaster = [...this.driverMaster , ...response];
         }
        }
        else{
          if (response && response.length > 0) {
            this.driverMaster = [...this.driverMaster, ...response];
            this.driverBatch++;
          }
        }
        // this.spinner.hide();
      },
      (r) => {
        alert(r.error.error);
      }
    );
  }

  onSearch(event : any){
    this.searchTextDriver = event.term;
    this.currentActiveVendor = 1;
    this.getDriverMaster();
  }

  GetFRDRegister() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.GetMappedFRDeviceWithRegisterSystem)
      .subscribe(
        (res) => {
          this.FRDRegister = res;
        },
        (err) => {
          Swal.fire('', err.errorMessage, 'error');
        }
      );
  }

  onDriverCodeChange() {
    const selectedDriverCode = this.driverForm.get('DriverCode')?.value;
    this.selectedDriverName = this.driverMaster.find(
      (driver) => driver.driverCode == selectedDriverCode
    );
    this.selectedDriver = this.selectedDriverName.name;
    if (this.selectedDriverName.frStatus == 'Enrolled') {
      this.Sync = true;
      this.enroll = false;
      this.Please = false;
    } else if (
      this.selectedDriverName.frStatus == 'Pending' ||
      this.selectedDriverName.frStatus == null
    ) {
      this.enroll = true;
      this.Sync = false;
    }
  }


  loadDriver(){
    this.driverBatch++;
    this.getDriverMaster();
  }
}
