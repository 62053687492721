<header id="header" class="banner port-img position-relative">
  <div class="overlay"></div>
  <div class="container">
      <nav class="navbar">
          <div class="container p-0">
              <a routerLink="/" class="logo"><img src="assets/landing/img/logo.svg" alt=""></a>
              <form class="justify-content-end">
                  <a href="http://www.urgetruck.com/" target="_blank" class="btn-red login"> <i
                          class="bi bi-person-fill"></i> LOGIN </a>
              </form>
          </div>
      </nav>

      <nav class="cd-vertical-nav">
          <ul>
              <li><a routerLink="/" class="active"><span class="label">Home</span></a></li>
              <li><a routerLink="/steel"><span class="label">Steel</span></a></li>
              <li><a routerLink="/automotive"><span class="label">Automotive</span></a></li>
              <li><a routerLink="/cement"><span class="label">Cement</span></a></li>
              <li><a routerLink="/port"><span class="label">Port</span></a></li>
          </ul>
      </nav>
  </div>
</header>

  <section class="bg-white">
      <div class="container">
          <div class="row gx-4 gx-lg-5 align-items-center">
              <div class="col-md-4"><img class="card-img-top mb-5 mb-md-0"
                      src="../../../assets/landing/img/port-img.jpg" alt="..."></div>
              <div class="col-md-8 text-start">
                  <h2>Port</h2>
                  <p>Successful automated ports show that careful planning and management can surmount many operating difficulties: operating expenses could fall by 25 to 55 percent, and productivity could rise by 10 to 35 percent. And in the long run, these investments will lead the way toward a new paradigm—call it Port 4.0—the shift from asset operator to service orchestrator, part of a Larger transition to Industry 4.0, or digitally enabled efficiency gains throughout the world economy.</p>
                      <p>The UrgeTruck solution deployment is possible in the following areas of Port Operation activities:</p>
                      <ul class="list-item list-unstyled">
                          <li>i. Vendor Collaboration, i.e CFS, Transporter, Vehicle / Driver Registration.</li>
                          <li>ii.	Online Job [Import / Export Cargo] selection by CFS and Transporters</li>
                          <li>iii. Pre-Gate Parking of Vehicles</li>
                          <li>iv.	RFID-based Automated Gate IN / OUT</li>
                          <li>v.	OCR-based Container identification</li>
                          <li>vi.	Interface with Terminal Operating System </li>
                      </ul>
              </div>
          </div>
      </div>
  </section>
  <app-lfooter></app-lfooter>
