import { Component, OnInit } from '@angular/core';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { faDownload, faFileAlt, faMapPin, faSort } from '@fortawesome/free-solid-svg-icons';
import { TatReportModel } from 'src/app/models/reportmodel';
import { ExcelService } from 'src/app/service/excel.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';


@Component({
  selector: 'app-tat-report',
  templateUrl: './tat-report.component.html',
  styleUrls: ['./tat-report.component.scss']
})
export class TatReportComponent implements OnInit {

  faMapPin = faMapPin
  faDownload = faDownload
  faFileAlt = faFileAlt
  faSort = faSort
  tatReport: any;
  gateMaster: any;
  laneMaster: any;
  tatReportModel: TatReportModel = new TatReportModel();
  public userAccessData: UserScreenAccesData;
  dataForExcel = [];
  VehicleTat: any;
  transTypeDll: any = transTypeDll;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  currentdate: string;
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################

  constructor(private kemarClientService: KemarClientService,
    private excelService: ExcelService, private spinner: NgxSpinnerService,
    private api:DataService
  ) { }

  ngOnInit(): void {
    this.currentdate = new Date().toISOString().split('T')[0];
      this.tatReportModel.fromDate = this.currentdate;
      this.tatReportModel.toDate = this.currentdate;
    this.getTatReport();
    // this.getallgate();
    this.userAccessData =this.api.getUserScreenAccessMenu('TATREPORT');
  }

  // getTatReport()  {
  //   this.kemarClientService.get<any>(null,ServiceUrl.getTatReport)
  //   .subscribe(
  //     response => {
  //       this.tatReport = response;
  //       console.log(this.tatReport);
  //     },
  //     r => {
  //       console.log(r.error.error);
  //     });
  // }
  getTatReport() {
    this.spinner.show();

    //var fromDate = this.tatReportModel.fromDate;
    //var ToDate = this.tatReportModel.toDate;
    var fromDate;
    var ToDate;
    if (!this.kemarClientService.commonValidation(this.tatReportModel.fromDate))
      fromDate = this.tatReportModel.fromDate + " " + "00:00:00";
    if (!this.kemarClientService.commonValidation(this.tatReportModel.toDate))
      ToDate = this.tatReportModel.toDate + " " + "23:59:59";
    var transType = this.tatReportModel.tranType;
    // var default = false;
    // console.log(this.tatReportModel.fromDate);
    // const ToDate = new Date(this.reportFilter.toDate);
    // let todateS = ToDate.toISOString().slice(0,10);
    var query = { 'fromDate': fromDate, 'toDate': ToDate, 'defaultAll': '', 'tranType': transType }
    this.kemarClientService.get<TatReportModel>(null, ServiceUrl.getTatReport, query)
      .subscribe(
        response => {
          this.spinner.hide();

          this.tatReport = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
    // window.location.reload();
  }
  exportToExcel() {

    this.dataForExcel = [];
    this.tatReport.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })
    // console.log(this.tatReport)

    let tatData = {
      title: 'TAT Report',
      data: this.dataForExcel,
      headers: Object.keys(this.tatReport[0])
    }

    this.excelService.exportExcelForTat(tatData);
  }
  getallgate() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getGateReportData)
      .subscribe(
        response => {
          this.spinner.hide();
          this.gateMaster = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);
  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage

  // getLaneDeatailBasedOnGateId(e) {
  //

  //  var gateId  : number = +e.target.value;
  //  var query = { 'gateId': gateId}
  //   this.kemarClientService.get<LaneMasterResponse>(null,ServiceUrl.GetLanebasedOnGateId, query)
  //   .subscribe(
  //     response => {
  //       this.laneMaster = response;
  //       console.log(this.laneMaster);
  //     },
  //     r => {
  //       console.log(r.error.error);
  //     });
  //   }

}
const transTypeDll: { key: string, value: string }[] = [
  { key: 'All', value: 'undefined' },
  { key: 'Outbound', value: '1' },
  { key: 'Inbound', value: '2' },
  { key: 'Inplant', value: '4' }
];
