<app-header></app-header>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0">
              <!--<fa-icon [icon]="faAnchor" class="faicon"></fa-icon>-->
              API Communication Errors
            </h4>
          </div>
          <mat-tab-group animationDuration="10ms" ngbNav #nav="ngbNav" (selectedIndexChange)="loadTabComponent($event)"
            mat-align-tabs="end">
            <mat-tab label="Active" ngbNavItem="top" (click)="CurrentPendingPage = 1">
              <div class="d-flex data-filter align-items-center my-2 m_d-flex">
                <div class="col-sm-8 col-10">
                  <div class="input-append">
                    <span class="material-icons-sharp icon-in">search</span>
                    <input class="form-control" type="search" name="search" placeholder="search"
                      (ngModelChange)="CurrentPendingPage = 1" [(ngModel)]="pendingFilter" />
                  </div>
                </div>
                <div class="col-md-4 text-end" id="input-filter">

                  <button class="btn btn-primary go-btn" (click)="getPendingAPIErrorsList()" matTooltip="Search"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faSearch"></fa-icon>
                  </button>
                </div>
              </div>
              <div class="table-list custom-table">
                <div class="table-responsive">
                  <table class="table table-striped MX-auto table-bordered table-hover">
                    <thead class="table-head">
                      <tr>
                        <th class="col-2" [width]="40">Sr#</th>
                        <th class="col location">
                          VRN
                        </th>
                        <th class="col location">
                          Milestone
                        </th>

                        <th class="col location">
                          M.T. Code
                        </th>

                        <th class="col location">
                          Error Message
                        </th>

                        <th (click)="sort('errorTime')" class="col location">
                          Occured At
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key == 'name'"
                            [ngClass]="glyphicon-chevron-up">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <!-- <th (click)="sort('name')" class="col location">Doc No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <th class="col location">
                          Retry Count
                        </th>
                        <th class="col location">
                          Last Retry At
                        </th>
                        <th class="col location">
                          Triggered By
                        </th>
                        <th class="col location">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let activeElv of pendingErrorsList
                            | filter : pendingFilter
                            | paginate
                              : {
                                  id: 'PendingPagination',
                                  itemsPerPage: pendingPageSize,
                                  currentPage: CurrentPendingPage
                                };
                          let i = index
                        ">
                        <td>
                          {{
                          (CurrentPendingPage - 1) * pendingPageSize + i + 1
                          }}
                        </td>
                        <td class="containerFirst"> <a
                            [routerLink]="['/current-transaction', activeElv.vehicleTransactionCode]">
                            {{activeElv.vrn}}</a>
                        </td>
                        <td>{{ activeElv.milestoneName }} - {{activeElv.milestoneEvent}}</td>
                        <td>{{ activeElv.milestoneTransactionCode }}</td>

                        <td class="left " matTooltip="{{activeElv.errorMessage}}" matTooltipPosition="above">
                          <span class="desc"> {{ (activeElv.errorMessage.length>75)? (activeElv.errorMessage |
                            slice:0:75)+'...':(activeElv.errorMessage) }}</span> &nbsp;
                          <span class="material-icons text-primary fs-5 InfoIcon"
                            (click)="ShowModelDetails(activeElv, jsonData)"> info </span>
                        </td>

                        <!-- <td matTooltip="{{activeElv.errorMessage}}" matTooltipPosition="above">
                          <span class="desc" *ngIf="activeElv.errorMessage != null && activeElv.errorMessage.length > 50">
                          {{ (activeElv.errorMessage.length>50)? (activeElv.errorMessage |
                            slice:0:50)+'..':(activeElv.errorMessage) }}</span> &nbsp;
                            <span class="material-icons text-primary" (click)="ShowModelDetails(activeElv, jsonData)"> info </span>
                        </td> -->
                        <td>{{ activeElv.errorTime | date : "dd-MM-yyyy HH:mm" }}</td>
                        <td class="text-center">
                          {{ activeElv.retriggerCount }}
                        </td>
                        <td>
                          {{ activeElv.lastRetriggerTime | date : "dd-MM-yyyy HH:mm" }}
                        </td>
                        <td>
                          {{ activeElv.reTriggerBy }}
                        </td>
                        <td>
                          <button class="btn go-btn "
                            (click)="retriggerAPICalls(activeElv.vrn, activeElv.jobMilestoneId ,activeElv.milestoneCode)"
                            matTooltip="Retrigger API" matTooltipClass="danger-tooltip">
                            <fa-icon [icon]="faRedo"></fa-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  Item per page
                  <select (change)="pendingPageSize = $event.target.value" class="paginationcount">
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                  <span> Total Records : {{totalActiveRecords}} </span>
                </div>
                <div class="col-md-6 text-end">
                  <div class="pagination justify-content-end">
                    <pagination-controls (pageChange)="pagechange($event) " id="PendingPagination">
                    </pagination-controls>
                  </div>
                </div>
              </div>

            </mat-tab>
            <mat-tab label="Resolved" ngbNavItem="middle" (click)="CurrentCompletedPage = 1">
              <div class="d-flex data-filter align-items-center my-2 m_d-flex">
                <div class="col-sm-4 col-10">
                  <div class="input-append">
                    <span class="material-icons-sharp icon-in">search</span>
                    <input class="form-control" type="search" name="search" placeholder="search"
                      [(ngModel)]="completedFilter" (ngModelChange)="CurrentCompletedPage = 1" />
                  </div>
                </div>
                <div class="col-md-8 text-end" id="input-filter">
                  <label for="fromDate" class="mr-2">From:</label>
                  <input class="form-control date mr-2" name="fromDate" (keypress)="KeyPressDate($event)" type="date"
                    [(ngModel)]="fromDate" id="fromDate" max="{{ today | date : 'yyyy-MM-dd' }}" />
                  <label for="toDate" class="mr-2">To:</label>
                  <input class="form-control date mr-2" name="toDate" (keypress)="KeyPressDate($event)"
                    [(ngModel)]="toDate" type="date" id="toDate" max="{{ today | date : 'yyyy-MM-dd' }}" />
                  <button class="btn btn-primary go-btn" (click)="getCompletedAPIErrorList()" matTooltip="Search"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faSearch"></fa-icon>
                  </button>
                </div>
              </div>
              <div class="table-list custom-table">
                <div class="table-responsive">
                  <table class="table table-striped MX-auto table-bordered table-hover">
                    <thead class="table-head">
                      <tr>
                        <th class="col-2" [width]="40">Sr#</th>
                        <th class="col location">
                          VRN
                        </th>
                        <th class="col location">
                          Milestone
                        </th>

                        <th class="col location">
                          M.T. Code
                        </th>

                        <th class="col location">
                          Error Message
                        </th>

                        <th (click)="sort('errorTime')" class="col location">
                          Occured At
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key == 'name'"
                            [ngClass]="glyphicon-chevron-up">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th class="col location">
                          Triggered By
                        </th>
                        <th class="col location">
                          Retry count
                        </th>

                        <th class="col location">
                          Error Resolved At
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let activeElv of CompletedErrorList
                            | filter : completedFilter
                            | paginate
                              : {
                                  id: 'completedPagination',
                                  itemsPerPage: completedPageSize,
                                  currentPage: CurrentCompletedPage
                                };
                          let i = index
                        ">
                        <td>{{(CurrentCompletedPage - 1) * completedPageSize + i + 1}}</td>
                        <td class="containerFirst"> <a
                            [routerLink]="['/current-transaction', activeElv.vehicleTransactionCode]">
                            {{activeElv.vrn}}</a>
                        </td>

                        <td>{{ activeElv.milestoneName }} - {{activeElv.milestoneEvent}}</td>
                        <td>{{ activeElv.milestoneTransactionCode }}</td>
                        <td class="left " matTooltip="{{activeElv.errorMessage}}" matTooltipPosition="above">
                          <span class="desc"> {{ (activeElv.errorMessage.length>75)? (activeElv.errorMessage |
                            slice:0:75)+'...':(activeElv.errorMessage) }}</span> &nbsp;
                          <span class="material-icons text-primary fs-5 InfoIcon"
                            (click)="ShowModelDetails(activeElv, jsonData)"> info </span>
                        </td>
                        <td>
                          {{
                          activeElv.errorTime | date : "dd-MM-yyyy HH:mm"
                          }}
                        </td>
                        <td>
                          {{ activeElv.reTriggerBy }}
                        </td>
                        <td class="text-center">
                          {{ activeElv.retriggerCount}}
                        </td>
                        <td>{{ activeElv.errorResolvedTime | date : "dd-MM-yyyy HH:mm" }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  Item per page
                  <select (change)="completedPageSize = $event.target.value" class="paginationcount">
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                  <span> Total Records : {{totalCompletedRecord}} </span>
                </div>
                <div class="col-md-6 text-end">
                  <div class="pagination justify-content-end">
                    <pagination-controls (pageChange)="pagechange1($event) " id="completedPagination">
                    </pagination-controls>
                  </div>
                </div>
              </div>

            </mat-tab>
          </mat-tab-group>
        </div>
        <div [ngbNavOutlet]="nav" class="ml-4"></div>
      </div>
    </div>
  </div>
</section>

<!-- Footer -->

<ng-template #jsonData let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">Error Details</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body vrScrollbar">
      <div class="table-list custom-table">
        <div class="table-responsive">
          <div class="row">
            <div class="col-4"><span>Vehicle No.: <b>{{ModelShowValue.vrn}}</b> </span></div>
            <div class="col-4"><span>Milestone: <b>{{ ModelShowValue.milestoneName }} -
                  {{ModelShowValue.milestoneEvent}}</b> </span></div>
            <div class="col-4"><span>Milestone Transaction Code: <b>{{ ModelShowValue.milestoneTransactionCode }}</b>
              </span></div>
          </div>
          <hr>
          <!-- <span>Milestone: {{ ModelShowValue.milestoneName }} - {{ModelShowValue.milestoneEvent}}</span> <br>
          <span>Milestone Transaction Code: {{ ModelShowValue.milestoneTransactionCode }}</span> <br> -->
          <P>Error: {{ModelShowValue.errorMessage}}</P>
          <span>Occured At: {{ ModelShowValue.errorTime | date : "dd-MM-yyyy HH:mm"}}</span>
          <hr>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-footer></app-footer>