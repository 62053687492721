<ngx-spinner> </ngx-spinner>

<app-header></app-header>
<section class="my-4"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Weighbridge </h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">
                search
              </span>
              <input class="form-control" type="search" name="search" placeholder="search"
               [(ngModel)]="filter" (ngModelChange)="currentActivePage = 1">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Weighbridge"
              matTooltipClass="danger-tooltip" *ngIf="userAccessData[0]?.canCreate == true">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <div class="table-list custom-table" >
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr. No.</th>
                  <th (click)="sort('name')">Weighbridge Name
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Location Name
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Capacity
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <!-- <th (click)="sort('name')" class="col location">Description
                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th> -->
                <th (click)="sort('name')">Boom Type
                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th>
                  <th (click)="sort('name')">Status
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Token Verification
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th class="action-key text-center" *ngIf="userAccessData[0].canUpdate ==true">Action</th>
                </tr>
              </thead>
              <tbody>
                <!-- <input type="hidden" name="WeighBridgeId" /*value={{WeighBridge.WeighBridgeId}}*/ /> -->
                <tr
                  *ngFor="let WeighBridge of WeighBridgeMaster | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="left">{{WeighBridge.weighBridgeName}}</td>
                  <td class="left">{{WeighBridge.locationName}}</td>
                  <td>{{WeighBridge.capacity}}</td>
                  <td>
                    <span *ngIf="WeighBridge.weighbridgeBoomType  =='NU'">Always Boom Up</span>
                    <span *ngIf="WeighBridge.weighbridgeBoomType  =='ND'">Always Boom Down</span>
                  </td>
                  <td>
                    <span *ngIf="WeighBridge.isActive  ==true ">Active</span>
                    <span *ngIf="WeighBridge.isActive  ==false ">Inactive</span>
                  </td>
                  <td>
                    <span *ngIf="WeighBridge.isTokenVerificationReq  ==true ">Yes</span>
                    <span *ngIf="WeighBridge.isTokenVerificationReq  ==false ">No</span>
                  </td>
                  <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
                    <span class="edit-btn-icon" type="button" matTooltip="Edit Weighbridge"
                      matTooltipClass="danger-tooltip" (click)="editWeighBridge(edit, WeighBridge) "
                      >
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="form-group mb-2">
          <div class="form-group ml-3">
            <input type="hidden" minlength="3" maxlength="30" class="form-control" name="WeighBridgeId"
              value="{{WeighBridge.WeighBridgeId}}" />
          </div>

          <div class="form-group mb-0 mt-1">
            <label for="WeighBridgeName">Weighbridge Name <sup>*</sup></label>
            <input type="text" class="form-control" placeholder="" value=" " minlength="3" maxlength="30"
              [ngClass]="{ 'is-invalid': submitted && f.WeighBridgeName.errors }" formControlName="WeighBridgeName"
              [(ngModel)]="WeighBridge.WeighBridgeName" />
            <div *ngIf="submitted && f.WeighBridgeName.errors" class="invalid-feedback">
              <div *ngIf="f.WeighBridgeName.errors.required">Weighbridge Name is required.
              </div>
              <div *ngIf="f.WeighBridgeName.errors.minlength">
                Weighbridge Name must be at least 3 characters.
              </div>
              <div *ngIf="f.WeighBridgeName.errors.maxlength">
                Weighbridge Name must not exceed 30 characters.
              </div>
              <div *ngIf="f.WeighBridgeName.errors?.pattern">
                <sup>*</sup>Weighbridge Name should contain only a-z,A-Z,0-9,-.
              </div>
              <div *ngIf="f.WeighBridgeName.hasError('whitespace')"></div>
            </div>
          </div>

          <div class="form-group mb-0 mt-1">
            <label for="WeighBridgeName">Select Location <sup>*</sup></label>
            <!-- <label for="locationId">Select Location</label> -->
            <select name="locationId" [(ngModel)]="WeighBridge.locationId" class="form-control form-select"
              formControlName="locationId" [ngClass]="{ 'is-invalid': submitted && f.locationId.errors }">
              <option hidden [ngValue]=0>--Choose Option--</option>
              <option *ngFor="let location of locationMaster" [ngValue]="location.locationId">
                {{location.locationName}}</option>
            </select>

            <div *ngIf=" submitted && f.locationId.errors" class="text-danger">
              <div *ngIf="f.locationId.errors?.pattern">
                <small> Location is required.</small>
              </div>
            </div>
          </div>

          <div class="form-group mb-0 mt-1">
            <label for="capacity">Capacity (In Tons) <sup>*</sup></label>
            <input type="text" class="form-control" (focusout)="tonValidation($event)"
              (keypress)="KeyPressMaxLength($event)" minlength="3" maxlength="7" placeholder="" value=" "
              [ngClass]="{ 'is-invalid': submitted && f.capacity.errors }" formControlName="capacity"
              [(ngModel)]="WeighBridge.capacity" />
            <div *ngIf="submitted && f.capacity.errors" class="invalid-feedback">
              <div *ngIf="f.capacity.errors.required">
                <small>Capacity(In Tons) is required.</small>
              </div>
              <div *ngIf="f.capacity.errors.minlength">
                <small>
                  <sup>*</sup> capacityInTons must be at least 3 digit.
                </small>
              </div>
              <div *ngIf="f.capacity.errors.maxlength">
                <sup>*</sup>capacityInTons must not exceed 7 digits.
              </div>
              <div *ngIf="f.capacity.errors?.pattern">
                <small> Capacity should contain only Numbers</small>
              </div>
            </div>
          </div>

          <div class="form-group mb-0 mt-1">
            <label for="weighbridgeBoomType">Select weighbridge Boom Type <sup>*</sup></label>
            <!-- <label for="locationId">Select Location</label> -->
            <select name="weighbridgeBoomType" [(ngModel)]="WeighBridge.weighbridgeBoomType" class="form-control form-select"
              formControlName="weighbridgeBoomType" [ngClass]="{ 'is-invalid': submitted && f.weighbridgeBoomType.errors }">
              <option hidden ngValue=''>--Choose Option--</option>
              <option ngValue='NU'>Always Boom Up</option>
              <option ngValue='ND'>Always Boom Down</option>
            </select>

            <div *ngIf=" submitted && f.weighbridgeBoomType.errors" class="text-danger">
              <div *ngIf="f.weighbridgeBoomType.errors?.pattern">
                <small> Boom Type is required.</small>
              </div>
            </div>
          </div>

          <div class="form-group mb-0 mt-1">
            <label for="isTokenVerificationReq">Token Verification Req. <sup>*</sup></label>
            <!-- <label for="locationId">Select Location</label> -->
            <select name="isTokenVerificationReq" [(ngModel)]="WeighBridge.isTokenVerificationReq" class="form-control form-select"
              formControlName="isTokenVerificationReq" [ngClass]="{ 'is-invalid': submitted && f.isTokenVerificationReq.errors }">
              <option hidden ngValue=''>--Choose Option--</option>
              <option [ngValue]='true'>Yes</option>
              <option [ngValue]='false'>No</option>
            </select>

            <div *ngIf=" submitted && f.isTokenVerificationReq.errors" class="text-danger">
              <div *ngIf="f.isTokenVerificationReq.errors?.pattern">
                <small> Is Token Verification Required</small>
              </div>
            </div>
          </div>

          <!-- <div class="form-group mb-0 mt-1">
            <label for="maxQueueSize">Max Queue Size</label>
            <input type="text" class="form-control" placeholder="" value=" "
              [ngClass]="{ 'is-invalid': submitted && f.maxQueueSize.errors }" formControlName="maxQueueSize"
              [(ngModel)]="WeighBridge.maxQueueSize" />
            <div *ngIf="submitted && f.maxQueueSize.errors" class="invalid-feedback">
              <div *ngIf="f.maxQueueSize.errors.required">maxQueueSize is required.
              </div>
              <div *ngIf="f.maxQueueSize.errors.minlength">
                Max Queue Size must be at least 3 characters.
              </div>
              <div *ngIf="f.maxQueueSize.errors.maxlength">
                Max Queue Size must not exceed 30 characters.
              </div>
              <div *ngIf="f.maxQueueSize.errors?.pattern">
                <sup>*</sup>Max Queue Size should contain only a-z,A-Z,-.
              </div>
            </div> -->
          <!-- </div> -->

          <div class="form-group mb-0 mt-1" [hidden]="activebuttonShow">
            <div class="select-radio">
              <input class="radio-input" type="radio" (change)="IsActiveClickEvent(false)" id="active" [value]="true"
                [(ngModel)]="WeighBridge.isActive" formControlName="isActive">
              <label for="active" class="radio-label">Active</label>
              <input class="radio-input" id="inActive" (change)="IsActiveClickEvent(true)" type="radio" [value]="false"
                [(ngModel)]="WeighBridge.isActive" formControlName="isActive">
              <label for="inActive" class="radio-label">Inactive</label>
            </div>
          </div>
          <div class="form-group mb-0 mt-1" [hidden]="activebuttonShow">
            <span *ngIf="Deactivation">
              <label for="deactivationReason">Deactivation Reason<sup>*</sup> </label>
              <textarea type="text" class="form-control" placeholder="" value=" " minlength="1" maxlength="150"
                [ngClass]="{ 'is-invalid': submitted && isreasonColor  }"
                (keyup)="deactivationReason($event.target.value.trim())" formControlName="deactivationReason"
                [(ngModel)]="WeighBridge.deactivationReason"> </textarea>
              <div *ngIf="submitted && isreasonColor" class="invalid-feedback">
                <div *ngIf="isreasonColor==true">
                  <small>Deactivation Reason required.</small>
                </div>
              </div>
            </span>
          </div>


          <!-- <div class="form-group mb-0 mt-4 select-radio" *ngIf="activebuttonShow">
            <label for="isActive" class="radio-btn">Active</label>
            <label class="form-check-inline">
              <input class="form-check-input" type="radio" name="isActive" [value]="true" formControlName="isActive"
                [(ngModel)]="WeighBridge.isActive">
            </label>
            <label for="isActive" class="radio-btn ">Inactive</label>
            <label class="form-check-inline">
              <input class="form-check-input" type="radio" name="isActive" [value]="false" formControlName="isActive"
                [(ngModel)]="WeighBridge.isActive">
            </label>
          </div> -->
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="registerWeigbridge(WeighBridge)">Save</button>
    </div>
  </div>
</ng-template>

<footer class="sticky-footer bg-black text-light">
  <app-footer></app-footer>
</footer>
