import { getLocaleDateTimeFormat } from "@angular/common";

export class CrossCheckMasterResponse {
    CCEId: number;
    cceName: string;
    expectedNoOfVehicles: string; 
    isActive: boolean;
    isEditMode: boolean;
    //isEdit:boolean;
    crossCheckEventSchedule: any[];
    crossCheckWGBroup: any[];
}
export class crossCheckEventSchedule {
    CCESheduleId: number;
    CCEId: number;
    ScheduleTime: string;
    ScheduleLifeTime: string;
    isEnabled: boolean;

}
export class crossCheckWGBroup {
    CCWBGroupId: number
    CCEId: number
    WeighBridgeId: number
    WeighBridgeName: string
    Sequence: number

}