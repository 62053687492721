<header class="header">
    <div class="container-fluid">
        <div class="top-header">
            <div class="row align-items-center h-100">
                <div class="col-6">
                    <a href="#">
                        <img title="JSW-logo" height="40" class="" src="../../assets/images/jsw-logo.png">
                    </a>
                </div>
                <div class="col-6 text-end">
                    <a href="#">
                        <img title="" height="50" class="" src="../../assets/images/FINAL-NEW.png">
                    </a>
                </div>
                </div>
            </div>
    </div>
  

    <nav class="navigation navbar navbar-expand-lg bg-nav">
        <div class="container-fluid p-0">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                    <img title="" width="12" class="" src="../../assets/images/icons/dashboard.svg"> Dashboard</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle"  id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                    <img title="" width="12" class="" src="../../assets/images/icons/report.svg"> 
                    Reports
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                    <li><a class="dropdown-item" href="#">Get Report</a></li>
                    <li><a class="dropdown-item" href="#">TAT Report</a></li>
                  </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img title="" width="12" class="" src="../../assets/images/icons/user.svg"> 
                    Admin
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                    <li><a class="dropdown-item" href="#">Get Report</a></li>
                    <li><a class="dropdown-item" href="#">TAT Report</a></li>
                  </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img title="" width="12" class="" src="../../assets/images/icons/master.svg"> 
                    Masters
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                    <li><a class="dropdown-item" href="#">Get Report</a></li>
                    <li><a class="dropdown-item" href="#">TAT Report</a></li>
                  </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img title="" width="12" class="" src="../../assets/images/icons/configuration.svg">  
                    Configuration
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                    <li><a class="dropdown-item" href="#">Get Report</a></li>
                    <li><a class="dropdown-item" href="#">TAT Report</a></li>
                  </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img title="" width="12" class="" src="../../assets/images/icons/center.svg"> 
                    Control Center
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                    <li><a class="dropdown-item" href="#">Get Report</a></li>
                    <li><a class="dropdown-item" href="#">TAT Report</a></li>
                  </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img title="" width="12" class="" src="../../assets/images/icons/transaction.svg"> 
                    Transaction
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                    <li><a class="dropdown-item" href="#">Get Report</a></li>
                    <li><a class="dropdown-item" href="#">TAT Report</a></li>
                  </ul>
              </li>
            </ul>
            <div class="user-profile d-flex">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="profile-pic">
                            <img title="" width="12" class="" src="../../assets/images/icons/user.svg">
                        </span>
                        
                          Super Admin
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><hr class="dropdown-divider"></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </li>
            </div>
          </div>
        </div>
      </nav>
</header>

<section class="card-section mt-2">
    <div class="container-fluid">
        <h4 class="title-one">Dashboard</h4>
        <div class="row">
            <div class="col-sm-3">
              <div class="card card-one">
                <div class="card-body">
                    <div class="bg-white progress">
                        <div class="progress-bar card-one" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small class="text-white">Parking Capacity</small>
                    <div class="row">
                        <div class="d-flex align-items-center mb-2">
                            <div class="col-6">
                                <h1 class="card-count text-center">999</h1>
                            </div>
                            <div class="col-6">
                                <ul class="w-75 float-end list-item list-unstyled text-center mb-0">
                                    <li><a href="/">Unallocated: 3</a></li>
                                    <li><a href="/">Allocated: 5</a></li>
                                    <li><a href="/">Called in: 3</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-end">
                            <div class="flex-grow-2">
                              <img title="" width="36" class="" src="../../assets/images/icons/parking.png">
                            </div>
                            <div class="flex-shrink-1">
                              <a href="/" class="card-title">Vehicles in Parking</a>
                            </div>
                    </div>

                        <!-- <div class="col-12">
                            <div class="d-flex">
                                <div class="flex-grow-1 align-self-center">
                                    <h1 class="card-count">999</h1>
                                </div>
                                <div class="flex-shrink-0">
                                    <ul class="list-item list-unstyled text-center">
                                        <li>Unallocated: 3</li>
                                        <li>Allocated: 5</li>
                                        <li>Called in: 3</li>
                                    </ul>
                                </div>
                        </div>
                        </div> -->
                        
                        <!-- <p class="text-end text-white">16th June </p> -->
                    </div>
                    
                    
                </div>
              </div>
            </div>
            <div class="col-sm-3">
                <div class="card card-two">
                  <div class="card-body">
                    <div class="bg-white progress">
                        <div class="progress-bar card-two" role="progressbar" style="width: 45%" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <small class="text-white">Plant Capacity</small>
                      <div class="row">
                        <div class="d-flex align-items-center mb-2">
                            <div class="col-6">
                                <h1 class="card-count text-center">500</h1>
                            </div>
                            <div class="col-6">
                                <ul class="w-75 float-end list-item list-unstyled text-center mb-0">
                                    <li><a href="/">Inbound: 4</a></li>
                                    <li><a href="/">Outbound: 6</a></li>
                                    <li><a href="/">Internal: 4</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-end">
                            <div class="flex-grow-2">
                              <img title="" width="36" class="" src="../../assets/images/icons/plant.png">
                            </div>
                            <div class="flex-shrink-1">
                                <a href="/" class="card-title">Vehicles in Plant</a>
                        </div>
                    </div>
                        <!-- <div class="col-7">
                            <div class="d-flex">
                                <div class="flex-grow-1 align-self-center">
                                <img title="" width="50" class="" src="../../assets/images/icons/plant.png">
                                </div>
                                <div class="flex-shrink-0">
                                <h1 class="card-count">500</h1>
                                <h2 class="card-title">Vehicles in <br> Plant</h2>
                                </div>
                        </div>
                        </div>
                        <div class="col-5">
                            <ul class="list-item list-unstyled text-center">
                                <li>Inbound: 4</li>
                                <li>Outbound: 6</li>
                                <li>Internal: 4</li>
                            </ul>
                        </div> -->
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card card-three">
                  <div class="card-body">
                    <div class="card-filter">
                        <label class="custom-select my-0">
                            <select>
                                <option selected>Daily</option>
                                <option value="Option 1">Weekly</option>
                                <option value="Option 2">Monthly</option>
                            </select>
                        </label>
                    </div>
                    <!-- <div class="card-filter text-end">
                        <mat-form-field appearance="fill">
                            <mat-label>Select an option</mat-label>
                            <mat-select [(value)]="selected">
                              <mat-option>None</mat-option>
                              <mat-option value="option1">Today</mat-option>
                              <mat-option value="option2">Weekly</mat-option>
                              <mat-option value="option3">Monthly</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div> -->

                      <div class="row">
                        <div class="d-flex align-items-center mt-2 mb-2">
                            <div class="col-6">
                                <h1 class="card-count text-center">09:30</h1>
                            </div>
                            <div class="col-6">
                                <ul class="w-75 float-end list-item list-unstyled text-center mb-0">
                                    <li><a href="/">Min TAT: 00:00</a></li>
                                    <li><a href="/">Max TAT: 00:00</a></li>
                                    <li class="invisible">&nbsp;</li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-end">
                            <div class="flex-grow-2">
                              <img title="" width="36" class="" src="../../assets/images/icons/tat.png">
                            </div>
                            <div class="flex-shrink-1">
                                <a href="/" class="card-title">TAT Summary</a>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card card-four">
                  <div class="card-body">
                    <div class="card-filter">
                        <label class="custom-select mt-0">
                            <select>
                                <option selected>Daily</option>
                                <option value="Option 1">Weekly</option>
                                <option value="Option 2">Monthly</option>
                            </select>
                        </label>
                        <!-- <mat-form-field class="dd-select" appearance="fill">
                            <mat-label>Select an option</mat-label>
                            <mat-select [(value)]="selected">
                              <mat-option>None</mat-option>
                              <mat-option value="option1">Today</mat-option>
                              <mat-option value="option2">Weekly</mat-option>
                              <mat-option value="option3">Monthly</mat-option>
                            </mat-select>
                          </mat-form-field> -->
                    </div>
                      <div class="row">
                        <div class="d-flex align-items-center mt-2 mb-2">
                            <div class="col-6">
                                <h1 class="card-count text-center">600</h1>
                            </div>
                            <div class="col-6">
                                <ul class="w-75 float-end list-item list-unstyled text-center mb-0">
                                    <li><a href="/">Inboard: 8</a></li>
                                    <li><a href="/">Outboard: 5</a></li>
                                    <li><a href="/">Internal: 3</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-end">
                            <div class="flex-grow-2">
                              <img title="" width="32" class="" src="../../assets/images/icons/transaction.svg">
                            </div>
                            <div class="flex-shrink-1">
                                <a href="/" class="card-title">Monthly Transaction</a>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
    </div>
</section>

<section class="data-table">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-8">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-center align-items-center mb-3">
                            <div class="flex-grow-1">
                                <h4 class="title-one mb-0">Recent Transactions &nbsp; <a href="/"><img title="" width="22" class="" src="../../assets/images/icons/refresh.svg"></a></h4>
                            </div>
                            <div class="search-filter flex-grow-1">
                                <div class="form-group has-search">
                                    <span class="fa fa-search form-control-feedback"></span>
                                    <input type="text" class="form-control" placeholder="Search by keyword">
                                  </div>
                            </div>
                            <div class="flex-grow-1">
                                <label class="custom-select w-100">
                                    <select>
                                        <option disabled selected>All Location</option>
                                        <option value="Option 1">Option 1</option>
                                        <option value="Option 2">Option 2</option>
                                        <option value="Option 3">Option 3</option>
                                        <option value="Option 4">Option 4</option>
                                        <option value="Option 5">Option 5</option>
                                    </select>
                                </label>
                            </div>
                    </div>

                        <!-- <h4 class="title-one">Live Transaction</h4>
                        <div class="d-flex">
                            <div class="col-6">
                                <h4 class="title-one">Live Transaction</h4>
                            </div>
                            <div class="col-6">
                                
                            </div>
                        </div> -->
                        <div class="table-responsive">
                            <table class="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>VRN</th>
                                        <th>Milestone</th>
                                        <th>Location</th>
                                        <th>Txn No</th>
                                        <th>Txn Type</th>
                                        <th>Txn Date & Time</th>
                                        <th width="5%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/true.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/pending.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/cancel.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">4</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/true.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">5</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/pending.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">6</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/pending.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">7</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/cancel.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">8</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/cancel.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">9</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/cancel.svg"></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">10</th>
                                        <td><a href="/">MH12DE1414</a></td>
                                        <td> PLANT GATE</td>
                                        <td>Goa Gate - OUT</td>
                                        <td>O220045</td>
                                        <td> OutBound </td>
                                        <td>10-06-2022 11:50</td>
                                        <td><img title="" width="18" class="" src="../../assets/images/icons/cancel.svg"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pages py-2">
                            <div class="row">
                                <div class="col-sm-6">
                                    <p class="my-2">Showing 1 to of 30 entries</p>
                                </div>
                                <div class="col-sm-6">
                                    <ul class="pagination justify-content-end mb-0">
                                        <li class="page-item"><a class="page-link" href="#" aria-label="Previous">&laquo;</a></li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item active"><a class="page-link " href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">4</a></li>
                                        <li class="page-item"><a class="page-link" href="#" aria-label="Next">&raquo;</a></li>
                                      </ul>
                                </div>
                            </div>
                        </div>
        
                        
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card">
    
                    <div class="card-body">
                      <h4 class="title-one">Today's Vehicle Traffic</h4>
                    </div>
              
                    <div class="card-body">
                      <canvas id="barChart"></canvas>
                    </div>
              
                  </div>
            </div>
        </div>
    </div>
</section>


<footer class="mt-4">
    <p>Copyright © Kemar Port Automation Pvt. Ltd.</p>
</footer>