<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="row data-filter align-items-center my-2">
                    <div class="col-sm-4">
                        <h4 class="title-one mb-0"> My Incidents </h4>
                    </div>
                    <div class="col-sm-8 text-end">
                        <div class="input-append">
                            <span class="material-icons-sharp icon-in">search</span>
                            <input class="form-control" type="search" name="search" placeholder="Search"
                                [(ngModel)]="filter" (ngModelChange)="currentActivePage = 1">
                        </div>
                         <button class="btn btn-primary go-btn" (click)="open(AddEdit)" matTooltip="Add Incidents"
                            matTooltipClass="danger-tooltip"   *ngIf="userAccessData[0].canCreate ==true ">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="table-list custom-table" >
                    <!-- <div class="row mb-2">
                    <div class="col-md-4 col-sm-6 filter-first-col">
                        <input class="form-control rounded-pill " style="width:60%" type="text" name="search"
                            placeholder="search" [(ngModel)]="filter">
                    </div>
                    <div class="col-md-8 col-sm-6 text-end filter-second-col ">
                        <button class="btn btn-outline-primary add-btn" (click)="open(AddEdit)" data-toggle="tooltip"
                            data-placement="top" title="Add Mapping">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </button>
                    </div>
                </div> -->

                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover mx-auto tbl-formating">
                            <thead class="table-head">
                                <tr>
                                    <th>Sr. No.</th>
                                    <th (click)="sort('name')" class="col action-key">Ticket No
                                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                            [ngClass]='glyphicon-chevron-up'>
                                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                        </span>
                                    </th>
                                    <th class="col action-key">Priority </th>
                                    <th class="col action-key">Category </th>
                                    <th class="col action-key">Sub Category </th>
                                    <th (click)="sort('name')" class="col action-key">Assigned User
                                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                            [ngClass]='glyphicon-chevron-up'>
                                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                        </span>
                                    </th>
                                    <th style="max-width: 80px;" class="col">Raised User</th>
                                    <th style="max-width: 80px;" class="col">Status </th>
                                    <th class="col action-key">Location Name </th>
                                    <th class="col action-key">Ticket Subject</th>
                                    <th class="col action-key">Ticket Description</th>
                                    <th class="col action-key">Remark </th>
                                    <th class="col action-key">SLA</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let ticket of tickets| orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: 10, currentPage: currentActivePage }; let i = index">
                                    <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                                    <td class="left"><a class="click"
                                            (click)="getTicketDetailHistory(ticketHistory,ticket.ticketId)">{{ticket.ticketNo}}</a>
                                    </td>
                                    <td class="left"> {{ticket.priority}} </td>
                                    <td class="left"> {{ticket.category}} </td>
                                    <td class="left"> {{ticket.subCategory}} </td>
                                    <td style="max-width: 40px;" class="left"> {{ticket.assignedUser}}</td>
                                    <td style="max-width: 80px;" class="left">{{ticket.raisedUser}}</td>
                                    <td style="max-width: 80px;" class="left"> {{ticket.status}}</td>
                                    <td class="left"> {{ticket.locationName}} </td>
                                    <td class="left " matTooltip="{{ticket.ticketSubject}}" matTooltipPosition="above">
                                        <span class="subject"> {{ (ticket.ticketSubject.length>20)?
                                            (ticket.ticketSubject |
                                            slice:0:20)+'..':(ticket.ticketSubject) }}</span>
                                    </td>
                                    <td class="left " matTooltip="{{ticket.ticketDescription}}"
                                        matTooltipPosition="above">
                                        <span class="desc"> {{ (ticket.ticketDescription.length>20)?
                                            (ticket.ticketDescription |
                                            slice:0:20)+'..':(ticket.ticketDescription) }}</span>
                                    </td>
                                    <td style="max-width: 50px;" class="left " matTooltip="{{ticket.remarks}}"
                                        matTooltipPosition="above">
                                        {{ (ticket.remarks.length>23)? (ticket.remarks |
                                        slice:0:23)+'..':(ticket.remarks) }}
                                    </td>
                                    <td class="left"> {{ticket.serviceSLATime | date: 'dd-MM-yyyy, H:mm '}} </td>

                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
                <div class="pagination justify-content-end">
                    <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
                </div>

            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>



 <ng-template #AddEdit let-modal class="modal">
    <div class="modal-content custom-modal">
        <div class="modal-header">
            <h4 class="modal-title mb-0">{{header}} </h4>
            <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span class="material-icons-sharp">close</span>
            </button>
        </div>
        <div class="modal-body">
            <form autocomplete="off" [formGroup]="form">
                <div class="form-group">
                    <div class="form-group row mb-0 mt-1">
                        <div class="col-sm-6">
                            <label for="locationName">Location Name <sup>*</sup></label>
                            <select type="text" class="form-control form-select my-1" placeholder="" value=" "
                                formControlName="locationName" [(ngModel)]="myIncident.locationName"
                                [ngClass]="{ 'is-invalid': submitted && f.locationName.errors }">
                                <option [ngValue]=null hidden>--Choose Option--</option>
                                <option *ngFor="let loc of location" [ngValue]="loc.locationName">
                                    {{loc.locationName}}</option>
                            </select>
                            <div *ngIf="submitted && f.locationName.errors" class="invalid-feedback">
                                <div *ngIf="f.locationName.errors.required">
                                    <small>Location Name is required.</small>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <label for="ticketUrgency">Urgency <sup>*</sup></label>
                            <select type="text" class="form-control form-select my-1" placeholder="" value=" "
                                formControlName="ticketUrgencyId" [(ngModel)]="myIncident.priority"
                                [ngClass]="{ 'is-invalid': submitted && f.ticketUrgencyId.errors }">
                                <option [ngValue]=null hidden>--Choose Option--</option>
                                <option *ngFor="let urgency of ticketUrgency" [ngValue]="urgency.urgencyId">
                                    {{urgency.urgency}}</option>
                            </select>
                            <div *ngIf="submitted && f.ticketUrgencyId.errors" class="invalid-feedback">
                                <div *ngIf="f.ticketUrgencyId.errors.required">
                                    <small>Urgency is required.</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row mb-0 mt-2">
                        <div class="col-sm-6">
                            <label for="status">Category<sup>*</sup></label>
                            <select type="text" class="form-control form-select my-1" placeholder="" value=" "
                                formControlName="categoryId" [(ngModel)]="myIncident.categoryId"
                                (ngModelChange)="getSubCategoryByCategory($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.categoryId.errors }">
                                <option [ngValue]=null hidden>--Choose Option--</option>
                                <option *ngFor="let cat of category" [ngValue]="cat.categoryId">
                                    {{cat.categoryName}}</option>
                            </select>
                            <div *ngIf="submitted && f.categoryId.errors" class="invalid-feedback">
                                <div *ngIf="f.categoryId.errors.required">
                                    <small>Category is required.</small>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <label for="subCategory">Sub Category<sup>*</sup></label>
                            <select type="text" class="form-control form-select my-1" placeholder="" value=""
                                formControlName="subcategoryId" [(ngModel)]="myIncident.subCategoryId"
                                [ngClass]="{ 'is-invalid': submitted && f.subcategoryId.errors }">
                                <option [ngValue]=null hidden>--Choose Option--</option>
                                <option *ngFor="let subcat of subcategory" [ngValue]="subcat.subCategoryId">
                                    {{subcat.subCategoryName}}</option>
                            </select>
                            <div *ngIf="submitted && f.subcategoryId.errors" class="invalid-feedback">
                                <div *ngIf="f.subcategoryId.errors.required">
                                    <small>Sub Category is required.</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group  mb-0 mt-2">
                        <label for="ticketSubject">Ticket Subject<sup>*</sup></label>
                        <input type="text" class="form-control my-1" [(ngModel)]="myIncident.ticketSubject"
                            placeholder="" value=" " formControlName="ticketSubject"
                            [ngClass]="{ 'is-invalid': submitted && f.ticketSubject.errors }" />
                        <div *ngIf="submitted && f.ticketSubject.errors" class="invalid-feedback">
                            <div *ngIf="f.ticketSubject.errors.required">
                                <small> Ticket Subject is required.</small>
                            </div>
                            <div *ngIf="f.ticketSubject.errors.minlength">
                                <small> Minimum 5 characters is required.</small>
                            </div>
                            <div *ngIf="f.ticketSubject.errors.maxlength">
                                <small> Maximum 200 characters.</small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group  mb-0 mt-2">
                        <label for="remark">Ticket Descirption<sup>*</sup></label>
                        <textarea type="text" class="form-control my-1" placeholder="" value=" "
                            [(ngModel)]="myIncident.TicketDescription" formControlName="ticketDescription"
                            [ngClass]="{ 'is-invalid': submitted && f.ticketDescription.errors }"></textarea>
                        <div *ngIf="submitted && f.ticketDescription.errors" class="invalid-feedback">
                            <div *ngIf="f.ticketDescription.errors.required">
                                <small>Ticket Descirption is required.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="modal-button btn btn-default" aria-label="Close"
                (click)="modal.dismiss('Cross click')">Close</button>
            <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
                (click)="onReset()">Reset</button>
            <button type="submit" class="modal-button btn btn-success"
                (click)="registerOrUpdateIncident(EditTicket,myIncident)">{{Save}}</button>
        </div>
    </div>
</ng-template>

<ng-template #ticketHistory let-modal class="modal">
    <div class="modal-content custom-modal">
        <div class="modal-header">
            <h4 class="modal-title mb-0">Ticket History </h4>
            <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span class="material-icons-sharp">close</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="table-list custom-table">
                <div class="table-responsive">
                    <table class="table table-striped mx-auto table-bordered table-hover">
                        <thead class="table-head">
                            <tr>
                                <th (click)="sort('name')" class="col location">Changed By </th>
                                <th (click)="sort('name')" class="col location">Status</th>
                                <th (click)="sort('name')" class="col location">Remarks</th>
                                <th (click)="sort('name')" class="col location">Transaction Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ticket of ticketHistoryData">
                                <td class="left">{{ticket.userName}}</td>
                                <td class="left">{{ticket.status}}</td>
                                <td class="left">{{ticket.remarks}}</td>
                                <td class="left">{{ticket.actionedAt | date: 'dd-MM-yyyy HH:mm'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>
