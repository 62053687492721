<app-header></app-header>
<section class="my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body custom-table">
        <h4 class="title-one mb-2">Invoice Verification</h4>
            <!-- <h3>Invoice Verification</h3>    -->
            <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
                <div class="col-md-2">
                    <label for="description">Vehicle Number</label>
                    <input type="text" class="form-control" placeholder="" formControlName="vrn" value="" name="vrn"
                    [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }"
                    />
                </div>
                <div class="col-md-2">
                    <button class="btn btn-success px-4" (click)="getInvoiceDetails()">Send</button>
                </div>
            </form>

            <!-- <div *ngFor="let invoice of invoiceDetails; let i = index">
                <span>
                    {{invoice.invoiceNumber}}
                    <input type="text" [(ngModel)]="verifyNum" (click)="postInvoiceVerification()">
                </span>
            </div> -->
            <!-- <form class="flex align-items-center gap-1  mt-3" [formGroup]="form2"v>

                <div *ngFor="let invoice of invoiceDetails" class="field-checkbox" class="d-flex row">

                    <div class="col-md-2">
                        <h3 for="description">Invoice Number : <span class="lb-md">{{invoice.invoiceNumber}}</span></h3>
                        <label for="description" class="lb-sm"></label>
                    </div>
                    <div class="col-md-2">
                        <input type="checkbox" [(ngModel)]="invoice.isVerified" (change)="toggleVerification(invoice)" [checked]="invoice.isVerified">
                    </div>  
                </div>
                <div class="col-md-2 align-content-end">
                    <button class="btn btn-success px-1" [hidden]="!getInvoiceClick"  (click)="postInvoiceVerification()" [disabled]="!isButtonEnabled()">Submit</button>
                </div>
            </form> -->

            <div [hidden]="!hideInvoiceTable">
            <div class="col-md-6">
                <p-table [value]="invoiceDetails" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-uppercase text-center">Invoice Number</th>
                            <th class="text-uppercase text-center">Verify</th> 
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-invoice>
                        <tr>
                            <td class="text-uppercase text-center">{{ invoice.invoiceNumber }}</td>
                            <td class="text-uppercase text-center">
                                <p-checkbox 
                                    [(ngModel)]="invoice.isVerified" 
                                    [binary]="true" 
                                    (onChange)="toggleVerification($invoice)"
                                    [checked]="invoice.isVerified"
                                    inputId="binary">
                                </p-checkbox>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="d-flex justify-content-end col-md-6 mt-3">
                <button class="btn btn-success px-1" [hidden]="!getInvoiceClick"  (click)="postInvoiceVerification()" [disabled]="!isButtonEnabled()">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>

  <app-footer></app-footer>