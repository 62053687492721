import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';

import { ChangePassword, UserRegistration, UserScreenAccesData } from 'src/app/models/usermodel';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {
  faEdit,
  faKey,
  faPlus,
  faRegistered,
  faTimes,
  faSort,
  faUser,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { DataService } from 'src/app/service/data.service';
import { AnySoaRecord } from 'dns';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
})
export class UserRegistrationComponent implements OnInit {
  public userRegistration: UserRegistration = new UserRegistration();
  public user: UserRegistration = new UserRegistration();
  public userAccessData: UserScreenAccesData;
  model = new ChangePassword();
  faEdit = faEdit;
  faTimes = faTimes;
  faKey = faKey;
  faRegistered = faRegistered;
  faUserAlt = faUserAlt;
  faPlus = faPlus;
  faSort = faSort;
  showMsg: boolean = false;
  form: UntypedFormGroup;
  changePasswordForm: UntypedFormGroup;
  editUserForm: UntypedFormGroup;
  errMsg = [];
  submitted = false;
  editUserFormSubmitted = false;
  userManager: any;
  displayChangePassword = false;
  userDetails: any;
  roleMaster: any;
  public roles = [
    { roleId: 1, roleName: 'Admin' },
    { roleId: 2, roleName: 'Management' },
  ];
  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  key: string = 'name'; //set default
  reverse: boolean = false;
  location: any;
  tempSelectedUsername: any = '';
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  //initializing p to one
  p: number = 1;
  rowCount: number = 15
  //############################################################################

  constructor(
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public api: DataService,
    private spinner: NgxSpinnerService,
    
  ) {
    this.editUserForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(12),
          Validators.pattern('[a-zA-Z]*'),
        ],
      ],

      lastName: [
        '',

        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(12),
          Validators.pattern('[a-zA-Z]*'),
        ],
      ],
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('[0-9]*'),
        ],
      ],

      email: [
        '',

        [
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(50),
        ],
      ],

      roleId: ['', Validators.required],
      userName: ['', Validators.required],
      isActive: [''],
    });
  }
  ngOnInit(): void {
    this.userDetails = this.api.getUserDetail();
    this.getAllActiveRoles();
    this.form = this.formBuilder.group(
      {
        firstName: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(20),
            Validators.pattern('[a-zA-Z]*'),
          ],
        ],

        lastName: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(20),
            Validators.pattern('[a-zA-Z]*'),
          ],
        ],

        mobileNumber: [
          '',
          [

            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('[0-9]*'),
          ],
        ],

        email: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.minLength(5),
            Validators.maxLength(50),
          ],
        ],

        password: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(15),
            Validators.pattern(
              '^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[#$^+=!*()@%&]).{5,}$'
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
        role: ['', [Validators.required, Validators.pattern(/^[1-9]\d*$/)]],

        userName: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(20),
          ],
        ],


      },
      {
        validator: this.MustMatch('password', 'confirmPassword'),
      }
    );
    this.userAccessData =this.api.getUserScreenAccessMenu('USERMGMT');

    this.changePasswordForm = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(15),
            Validators.pattern(
              '^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[#$^+=!*()@%&]).{5,}$'
            ),
          ],
        ],
        // oldPassword: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.minLength(5),
        //     Validators.maxLength(250)
        //   ]
        // ],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: this.MustMatch('password', 'confirmPassword'),
      }
    );

    this.getAllUser();
  }
  onSubmit() {

    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if (this.model.password != this.model.confirmPassword) {
      Swal.fire('Password and Confirm Password do not match', '', 'error');
    } else {
      this.submitted = false;
      this.changeUserPassword();
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get f1(): { [key: string]: AbstractControl } {
    return this.editUserForm.controls;
  }
  get f2(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }
  // onSubmit(): void {
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   }

  // }

  // custom validator to check that two fields match
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  open(content: any) {
    this.onReset();
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };

    this.userRegistration.firstName = '';
    this.userRegistration.lastName = '';
    this.userRegistration.mobileNumber = '';
    this.userRegistration.email = '';
    this.userRegistration.password = '';
    this.userRegistration.confirmPassword = '';
    this.userRegistration.roleId = 0;
    this.userRegistration.userName = '';
    this.modalService.open(content, ngbModalOptions);
  }

  getAllActiveRoles() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllActiveRoles)
      .subscribe(
        (response) => {
          this.roleMaster = response;
        },
        (r) => {
          alert(r.error.error);
        }
      );
  }
  getAllUser() {
    this.kemarClientService
      .get<UserRegistration>(null, ServiceUrl.getUsersData)
      .subscribe(
        (response) => {
          this.userManager = response;
        },
        (r) => {
          alert(r.error.error);
        }
      );
  }

  registerUser(userRegistration) {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();

    // console.log(JSON.stringify(this.form.value, null, 2));
    this.kemarClientService
      .postPatch<UserRegistration>(
        ServiceUrl.registerUserManager,
        userRegistration
      )
      .subscribe(
        (data) => {
          this.getAllUser();
          Swal.fire('', 'User Registered Successfully', 'success');
          this.closeModalPopup();
        },
        (r) => {

          let msg = '';
          if (r.error != null)
            msg = r.error;
          if (r.error != null && r.error.errors != null) {
            if (r.error.errors.Password != null)
              msg = msg + r.error.errors.Password[0];
            if (r.error.errors.Email != null)
              msg = msg + r.error.errors.Email[0];
            if (msg == '') msg = 'Error in registering user';
          }

          Swal.fire({
            icon: 'error',
            title: '',
            text: msg,
          });

          this.spinner.hide();
        }
      );
  }

  updateUser(user) {

    this.editUserFormSubmitted = true;
    if (this.editUserForm.invalid) {
      return;
    }
    this.spinner.show();

    this.kemarClientService
      .postPatch<UserRegistration>(ServiceUrl.updateUserDeatils, user)
      .subscribe(
        (data) => {
          this.getAllUser();
          Swal.fire('', 'User Detail Updated Successfully', 'success');
          this.closeModalPopup();
        },
        (r) => {

          let msg = '';
          if (r.error != null)
            msg = r.error;
          if (r.error == null)
            msg = 'Something Went Wrong'
          Swal.fire({
            icon: 'error',
            title: '',
            text: msg,
          });
          this.spinner.hide();
        }
      );
  }

  onReset():void{
    this.submitted = false;
    this.form.reset();
    this.form.controls['role'].setValue(0);

  }

  onEditUserFormReset(): void {
    this.editUserFormSubmitted = false;
    this.editUserForm.reset();
  }
  editUser(content, user) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.user.firstName = user.firstName;
    this.user.id = user.id;
    this.user.lastName = user.lastName;
    this.user.mobileNumber = user.mobileNumber;
    this.user.email = user.email;
    this.user.userName = user.userName;
    this.user.roleId = user.roleId;
    this.user.mobileNumber = user.mobileNumber;
    this.displayChangePassword = false;
    this.user.isActive = user.isActive;
    this.user.password = 'Dummy@1234';
    this.user.confirmPassword = 'Dummy@1234';
    this.editUserFormSubmitted = false;
    this.modalService.open(content, ngbModalOptions);
  }

  public closeModalPopup() {
    this.form.reset();
    this.modalService.dismissAll();
  }

  getUserRoleDisplayName(rolename) {
    let roleDisplayName = this.roles.find((x) => x.roleName == rolename);
    return roleDisplayName;
  }

  togglePasswordChange() {
    this.displayChangePassword = !this.displayChangePassword;
    if (this.displayChangePassword == false) {
      this.user.password = 'Dummy@1';
      this.user.confirmPassword = 'Dummy@1';
    } else {
      this.user.password = '';
      this.user.confirmPassword = '';
    }
  }
  changeUserPassword() {

    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    let changePassword = new ChangePassword();
    changePassword.userName = this.tempSelectedUsername;
    changePassword.password = this.model.password;
    //tbd : need a new api for this
    changePassword.oldPassword = 'Pass@123';
    changePassword.confirmPassword = this.model.confirmPassword;

    this.kemarClientService
      .postPatch<string>(ServiceUrl.resetPasswordByAdmin, changePassword)
      .subscribe(
        (data) => {
          Swal.fire('Password changed succesfully', '', 'success');
        },
        (error) => {
          this.errMsg = error;
          if (error.errors != null && error.errors != undefined)
            this.errMsg = error.errors[0];
          Swal.fire('Error :' + this.errMsg, '', 'error');
        }
      );
    this.modalService.dismissAll();
    this.model = new ChangePassword();
  }
  openChangePassword(content: any, edituser: any) {

    this.changePasswordForm.reset();
    this.model.confirmPassword = this.model.password = '';
    this.submitted = false;
    this.tempSelectedUsername = '';
    this.tempSelectedUsername = edituser.userName;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalService.open(content, { size: 'sm' });
    this.modalService.open(ngbModalOptions);

  }

  pagechange = (event) => this.tempcurrentActivePage = this.p = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.p = 1 : this.p = this.tempcurrentActivePage


  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
