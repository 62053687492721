import { Component, OnInit } from '@angular/core';
import { ConfigParms } from '../../common/portal-config';

@Component({
  selector: 'app-ut-header-common',
  templateUrl: './ut-header-common.component.html',
  styleUrls: ['./ut-header-common.component.scss']
})
export class UtHeaderCommonComponent implements OnInit {
  disabled = true;
  plantName='';
  plant='';
  headerWithName='false';


  constructor() {
    this.plantName = ConfigParms.PlantName;
    this.plant = ConfigParms.plant;
    this.headerWithName = ConfigParms.headerWithName;
  }

  ngOnInit(): void {
  }

}
