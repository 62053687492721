import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faDoorClosed, faEdit, faPlus, faSort, faTimes, faTruck ,faSearch} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { JobScheduleMasterResponse } from 'src/app/models/JobScheduleMasterResponse';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-job-schedule-master',
  templateUrl: './job-schedule-master.component.html',
  styleUrls: ['./job-schedule-master.component.scss']
})
export class JobScheduleMasterComponent implements OnInit {
  filter = '';
  faPlus = faPlus;
  faDoorClosed = faDoorClosed;
  faEdit = faEdit;
  faSort = faSort;
  form: UntypedFormGroup;
  submitted: boolean;
  title: string;
  public jobschedule: JobScheduleMasterResponse = new JobScheduleMasterResponse();
  activeButtonShow: any;
  //deepButtonShow:any;
  resetbuttonShow: boolean;
  key: any;
  reverse: boolean;
  pageSize = 10;
  currentPage:any;
  currentActivePage: number = 1;
  jobschedulemaster: any;
  deepButtonShow: boolean;

  constructor(private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal
    ) {
      this.form = this.formBuilder.group
      ({

        jobScheduleId:[''],
        jobName: ['', [Validators.required]],//string
        cronExpression: ['', [Validators.required]],//string
        jobDescription: ['', [Validators.required]],//string
        hourlyLogFrequency:['', [Validators.required]],//number
        isActive: [''],//bit
        enableLogging:[''],//boolen
        enableDeepLogging:[false],//bit
        isEveryDayReset:[''],//bit
        EnableActive:[''],
        DeepActive: [''],
        IsEveryDayActive:[''],


});
    }

  ngOnInit(): void {
    this.getAllJobSchedules();
  }

  ngbModalOptions: NgbModalOptions =
    {
      backdrop: 'static',
      keyboard: false
    };
  onReset() {
    this.submitted = false;
    this.form.reset();
  }
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  public closeModalPopup() {
    this.modalService.dismissAll();
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  open(content:any){
    this.onReset();
    this.title="Add JobSchedule";
    this.jobschedule.jobScheduleId=0;
    this.jobschedule.jobName='';
    this.jobschedule.cronExpression='';
    this.jobschedule.jobDescription='';
    this.jobschedule.isActive;
    this.jobschedule.enableLogging=true;
    this.jobschedule.enableDeepLogging=false;
    this.jobschedule.hourlyLogFrequency=null;
    this.jobschedule.isEveryDayReset=true;
    this.activeButtonShow = false;
    this.resetbuttonShow = true;
    this.modalService.open(content, this.ngbModalOptions);
}

editJobSchedule(content:any,jobschedule){
  this.onReset();
  this.title="Update JobSchedule";
  this.jobschedule.jobScheduleId = jobschedule.jobScheduleId;
  this.jobschedule.jobName = jobschedule.jobName;
  this.jobschedule.cronExpression = jobschedule.cronExpression;
  this.jobschedule.jobDescription = jobschedule.jobDescription;
  this.jobschedule.isActive = jobschedule.isActive;
  this.jobschedule.enableLogging = jobschedule.enableLogging;
  this.jobschedule.enableDeepLogging = jobschedule.enableDeepLogging;
  this.jobschedule.hourlyLogFrequency = jobschedule.hourlyLogFrequency;
  this.jobschedule.isEveryDayReset = jobschedule.isEveryDayReset;
  this.activeButtonShow = false,
  this.resetbuttonShow = false,
  this.modalService.open(content, this.ngbModalOptions);

}

getAllJobSchedules(){
  this.spinner.show();
  this.kemarClientService.get<any>(null, ServiceUrl.getAllJobSchedules)
    .subscribe(
      response => {
        this.jobschedulemaster = response;
        this.spinner.hide();
      },
      (r) => {
        this.spinner.hide();
        alert(r.error.error);
        // console.log(r.error.error);
      }
    );

}
registerJobSchedule() {

  this.submitted = true;
  if (this.form.invalid) {
    return;
  }
  this.spinner.show();
  this.kemarClientService.postPatch<any>(ServiceUrl.registerJobSchedule,this.jobschedule)
    .subscribe(
      (res) => {

        Swal.fire('', res.responseMessage, 'success');
        this.getAllJobSchedules();
        this.closeModalPopup();
        this.spinner.hide();
      },
      (r) => {

        Swal.fire({
          icon: 'error',
          title: '',
          text: r.error.errorMessage,
        })
        this.spinner.hide();
        // console.log(r.error.error);
      }
    );
}

}
