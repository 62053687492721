import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../service/data.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { NgbNavbar } from '@ng-bootstrap/ng-bootstrap';
import { NavItem } from './menu-item/NavItemModel';
import { ConfigParms } from '../common/portal-config';
// To be removed : //https://stackblitz.com/edit/dynamic-nested-topnav-menu?file=app%2Fapp.module

@Component({
  selector: 'app-header',
  templateUrl: './app-header.html',
  styleUrls: ['./app-header.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppHeaderComponent implements OnInit {

  navItems: Array<NavItem>;
  disabled = true;
  plantName='';
  plant='';
  headerWithName='false';

  constructor(public api: DataService, private router: Router) {
    this.navItems = api.getUserMenu();
    this.plantName = ConfigParms.PlantName;
    this.plant = ConfigParms.plant;
    this.headerWithName = ConfigParms.headerWithName;
    // console.log( this.navItems);
  }

  userDisplayName: string;
  userRole: string;
  faUser = faUser;
  NgbNavbar = NgbNavbar;
  rolewiseDefaultPage = "/dashboard";

  readonly ROLE_ADMIN = "SuperAdmin";
  readonly ROLE_MANAGEMENT = "SuperAdmin";
  readonly ROLE_OPERATOR = "GateOperator";
  collapsed = true;
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  ngOnInit(): void {
    this.userDisplayName = this.api.getUserDisplayName();
    this.userRole = this.api.getUserRole();

    if (this.userRole == this.ROLE_ADMIN || this.userRole == this.ROLE_MANAGEMENT) {
      this.rolewiseDefaultPage = "/dashboard";
    }
    else
      this.rolewiseDefaultPage = "/dashboard";
  }

  tryLogout() {
    this.router.navigateByUrl('/login');
  }

  goToLocationMaster() {
    this.router.navigate([`location-master`]);
  }

  goToGateMaster() {
    this.router.navigate([`gate-master`]);
  }

  goToDashboard() {
    this.router.navigate([`dashboard`])
  }
  goToInGate() {
    this.router.navigate([`in-gate`])
  }

  goToOutGate() {
    this.router.navigate([`out-gate`])
  }

}
