import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { KemarClientService } from '../service/kemar-client.service';
import { FormBuilder } from '@angular/forms';
import { NgbModal,  } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../service/data.service';
import { ServiceUrl } from '../common/service-urls';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gate-change',
  templateUrl: './gate-change.component.html',
  styleUrls: ['./gate-change.component.scss']
})
export class GateChangeComponent implements OnInit {
  jobMilestoneRes:any;
  locationMaster: any;
  updateJobMilestone:any;
  key: string = 'name'; //set default
  reverse: boolean = false;
  GateIn: any;
  GateOut: any;
  submitted: boolean;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  filter: string = '';
  pageSize = 10;
  currentActivePage: number = 1;
  tempcurrentActivePage: number = 1;
  selectAll: boolean = false;
  selectedMilestoneEvent: string = '0';
  selectedLocation: string = '0';
  isMilestoneSelected: boolean = false;
  canSave: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private api:DataService
  ) {

   }

  ngOnInit(): void {
  //  this.getAllPendingVehiclesList();
  this.getPlantGateList(this.selectedMilestoneEvent); 
   this.getAllLocation();
  }
  
  getAllPendingVehiclesList()
  {
    debugger;
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllPendingVehiclesList)
      .subscribe(
        (response) => {
          this.jobMilestoneRes = response;
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  getPlantGateList(milestoneEvent: string) {
    debugger;
    this.spinner.show();
    let serviceUrl = '';
    if (milestoneEvent === '1003') {
      serviceUrl = ServiceUrl.getPlantGateInList;
    } else if (milestoneEvent === '1004') {
      serviceUrl = ServiceUrl.getPlantGateOutList;
    }
    else if (milestoneEvent ==="0"){
      serviceUrl = ServiceUrl.getAllPendingVehiclesList;
    }
  
    this.kemarClientService.get<any>(null, serviceUrl)
      .subscribe(
        (response) => {
        debugger;
          this.updateJobMilestone = response;  
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.error(error);
        }
      );
  }

  getAllLocation() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        response => {
          this.locationMaster = response.filter(x => x.parentLocationCode === 'PLANTGATE');
        },
        r => {
          alert(r.error.error);
        });
  }

  onMilestoneEventChange(event: any) {
    debugger;
    this.selectedMilestoneEvent = event.target.value;
    this.isMilestoneSelected = this.selectedMilestoneEvent !== '0';
    this.selectedLocation = '0';
  
    if (this.isMilestoneSelected) {
      this.getPlantGateList(this.selectedMilestoneEvent);
    }
    this. updateSaveButtonState();
  }
  
  onLocationChange() {
    this.updateSaveButtonState();
  }

  updateSaveButtonState() {
    debugger;
    this.canSave = this.selectedMilestoneEvent !== '0' && this.selectedLocation !== '0';
  }
  
  selectAllVRN() {
    debugger;
    this.updateJobMilestone.forEach(item => {
      item.selectedOneVrn = this.selectAll;
      
    });
  }
  selectOneVRN() {
    debugger;
    this.selectAll = this.updateJobMilestone.every(item => item.selectedOneVrn);
   
  }
  Save()
  {
    debugger;
    this.submitted = true;
    this.spinner.show();
    const selectedItems = this.updateJobMilestone
    .filter(item => item.selectedOneVrn)  
    .map(item => {
      return {
        jobMilestoneId :item.jobMilestoneId,
        // vrn: item.vrn, 
        // status: item.status
      };
    });
    if (selectedItems.length === 0) {
      Swal.fire({icon: 'error',title: 'Selection Error',text: 'Please select at least one vehicle.'});
      this.spinner.hide();
      return;
    }
    var query = {
   
    milestoneEvent: this.selectedMilestoneEvent,
    locationCode: this.selectedLocation,         
    selectedItems: selectedItems  
    }
    this.kemarClientService.postPatch<any>(ServiceUrl.saveUpdatedLocations, query)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success')
          this.getPlantGateList(this.selectedMilestoneEvent); 
        },
        r => {
            Swal.fire({icon: 'error',title: '',text: r.error.errorMessage})
           this.spinner.hide();
        
        });
  }


  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage


}
