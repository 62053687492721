export class VehicleMilestone {
    RequestId: string;
    VRN: string;
    ELVCode: string;
    Milestones: Array<Milestones> = new Array<Milestones>();
  }

  export class Milestones {
    MilestoneTransactionCode: string;
    MilestoneCode: string;
    milesteoneName: string;
    LocationCode: string;
    LocationName: string;
    ExpectedLane: string = '';
    ActualLane: string = '';
    MileStoneSequence: string;
    IsActive: string = 'true';
    IsOptional: string = 'false';
  }

  export class milestoneJsonData {
    RequestId: string;
    vehiclemilestones: Array<VehicleMilestone> = new Array<VehicleMilestone>();
  }

  export class MilestoneWorkFlow{
    ELVCode: string;
    VRN: string;
    WorkFlowGroupName: string;
    WorkFlowGroupCode: string;
  }

  export class elvFromExcel{
    VRN: string;
    DriverCode: string;
    Product: string;
    UOM: string;
    Bag: string;
    Weight: string;
    MilestoneGroupName: string;
    Type: string;
  }

