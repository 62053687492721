<ngx-spinner> </ngx-spinner>
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-sm-4">
    <div class="input-append ">
      <span class="material-icons-sharp icon-in">search</span>
      <input class="form-control" type="search" name="search" placeholder="Search by keyword" [(ngModel)]="filter"
      (ngModelChange)="currentActivePage = 1">
    </div>
  </div>
  <div class="col-sm-8 text-end">
    <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Ticket" 
    *ngIf="userAccessData[0].canCreate ==true"
      matTooltipClass="danger-tooltip">
      <fa-icon [icon]="faPlus"></fa-icon>
      <!-- <span class="material-icons-round">add</span> -->
    </button>
  </div>
</div>
<div class="table-list custom-table">
  <!-- <div class="row mb-3">
            <div class="col-sm-3 filter-first-col">
              <input class="form-control rounded-pill " style="width:60%" type="text" name="search" placeholder="search"
                [(ngModel)]="filter">
            </div>
            <div class="col-sm-2 filter-second-col">
              <button class="btn btn-outline-primary add-btn" (click)="open(edit)" data-toggle="tooltip"
                data-placement="top" title="Add Mapping">
                <fa-icon [icon]="faPlus"></fa-icon>
              </button>
            </div>
          </div> -->

  <div class="table-responsive">
    <table class="table table-striped mx-auto table-bordered table-hover mx-auto tbl-formating">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>
          <th (click)="sort('name')">Ticket No <span class="glyphicon sort-icon" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')">VRN <span class="glyphicon sort-icon" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th style="max-width: 120px;" (click)="sort('name')" class="col action-key">Driver Name <span
              class="glyphicon sort-icon" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th (click)="sort('name')" class="col action-key">Priority <span class="glyphicon sort-icon"
              *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th (click)="sort('name')" class="col action-key">Category <span class="glyphicon sort-icon"
              *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th (click)="sort('name')" class="col action-key">Transaction Time <span class="glyphicon sort-icon"
              *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th (click)="sort('name')" (click)="sort('name')" class="col action-key">Assigned User <span
              class="glyphicon sort-icon" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th (click)="sort('name')" style="max-width: 80px;" class="col">Raised User<span class="glyphicon sort-icon"
              *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th (click)="sort('name')" class="col">Status <span class="glyphicon sort-icon" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span></th>
          <th (click)="sort('name')" class="col action-key">Location Name <span class="glyphicon sort-icon"
              *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th (click)="sort('name')" class="col action-key">Ticket Descirption <span class="glyphicon sort-icon"
              *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <th (click)="sort('name')" class="col action-key">Remarks <span class="glyphicon sort-icon"
              *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> </th>
          <!-- <th class="col action-key">SLA</th> -->
          <th class="col action-key text-center" *ngIf="userAccessData[0].canUpdate ==true">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let ticket of tickets| orderBy: key : reverse | filter:filter | paginate: { id : 'pagination1', itemsPerPage: 10, currentPage: currentActivePage }; let i = index">
          <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
          <td class="left"><a class="click"
              (click)="getTicketDetailHistory(ticketHistory,ticket.ticketId)">{{ticket.ticketNo}}</a></td>
          <td class="left"> {{ticket.vrn}} </td>
          <td class="left" matTooltip="{{ticket.driverNo}}"> {{ticket.driverName}} </td>
          <td class="left"> {{ticket.priority}} </td>
          <td class="left"> {{ticket.category}} </td>
          <td class="left"> {{ticket.raisedAt | date:'dd-MM-yyyy HH:mm'}} </td>
          <td style="max-width: 60px;" class="left"> {{ticket.assignedUser}}</td>
          <td style="max-width: 60px;" class="left">{{ticket.raisedUser}}</td>
          <td style="max-width: 80px;" class="left"> {{ticket.status}}</td>
          <td class="left"> {{ticket.locationName}} </td>
          <td class="left " matTooltip="{{ticket.ticketDescription}}" matTooltipPosition="above">
            <span class="desc"> {{ (ticket.ticketDescription.length>20)? (ticket.ticketDescription |
              slice:0:20)+'..':(ticket.ticketDescription) }}</span>
          </td>
          <td style="max-width: 50px;" class="left " matTooltip="{{ticket.remarks}}" matTooltipPosition="above">
            {{ (ticket.remarks.length>23)? (ticket.remarks | slice:0:23)+'..':(ticket.remarks) }}
          </td>
          <!-- <td class="left"> {{ticket.serviceSLATime | date: 'dd-MM-yyyy, h:mm a'}} </td> -->
          <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
            <fa-icon [icon]="faInfo" class="faicon text-primary" matTooltip="Ticket info" style="cursor: pointer;"
              (click)="getTicketDetails(ticketdetail,ticket.ticketId)"></fa-icon> &nbsp;&nbsp;&nbsp;
            <fa-icon [icon]="faEdit" 
              *ngIf="ticket.status != 'Closed' && ticket.status != 'Completed' && ticket.status != 'Cancelled' && ticket.status != 'Auto Closed'  && ticket.status != 'Canceled'"
              class="faicon text-primary" style="cursor: pointer;" matTooltip="Edit Ticket" 
              (click)="editTicketDetails(edit,ticket)"></fa-icon>
            <!-- <img (click)="getTicketDetailHistory(ticketHistory,ticket.ticketId)" title="" width="18" class=""
                      src="../../assets/images/icons/clock-history.svg"> -->
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</div>
<div class="pagination justify-content-end">
  <pagination-controls id="pagination1" (pageChange)="pagechange($event)"></pagination-controls>
</div>








<ng-template #ticketdetail let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-list custom-table">
        <div class="table-responsive sm-table">
          <table class="table table-striped table-bordered table-hover mx-auto">
            <thead class="table-head">
              <tr>
                <th class="col location">Title
                </th>
                <th class="col location">Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ticket of ticketDetails">
                <td class="left">{{ticket.key}}</td>
                <td class="left">{{ticket.value}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{header}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="row vrScrollbar">
          <div class="col-sm-6">
            <label for="locationName">Location Name<sup>*</sup></label>
            <select type="text" class="form-control form-select my-1" placeholder="" value=" "
              formControlName="locationName" [(ngModel)]="ticketModel.locationName"
              [ngClass]="{ 'is-invalid': submitted && f.locationName.errors }">
              <option [ngValue]="null" hidden>--Choose Option--</option>
              <option *ngFor="let loc of location" [ngValue]="loc.locationName">
                {{loc.locationName}}</option>
            </select>
            <div *ngIf=" submitted && f.locationName.errors" class="text-danger">
              <div *ngIf="f.locationName.errors.required">
                <small> Location Name is required.</small>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <label for="ticketUrgency">Urgency<sup>*</sup></label>
            <select type="text" class="form-control form-select my-1" placeholder="" value=" "
              formControlName="priority" [(ngModel)]="ticketModel.priority"
              [ngClass]="{ 'is-invalid': submitted && f.priority.errors }">
              <option [ngValue]='null' hidden>--Choose Option--</option>
              <option *ngFor="let urgency of ticketUrgency" [ngValue]="urgency.urgencyId">
                {{urgency.urgency}}</option>
            </select>
            <div *ngIf=" submitted && f.priority.errors" class="text-danger">
              <div *ngIf="f.priority.errors.required">
                <small> Urgency is required.</small>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="status">Category<sup>*</sup></label>
            <select type="text" class="form-control form-select my-1" placeholder="" value=" "
              formControlName="categoryId" [(ngModel)]="ticketModel.categoryId"
              (ngModelChange)="getSubCategoryByCategory($event)"
              [ngClass]="{ 'is-invalid': submitted && f.categoryId.errors }">
              <option [ngValue]='null' hidden>--Choose Option--</option>
              <option *ngFor="let cat of category" [ngValue]="cat.categoryId">
                {{cat.categoryName}}</option>
            </select>
            <div *ngIf=" submitted && f.categoryId.errors" class="text-danger">
              <div *ngIf="f.categoryId.errors.required">
                <small> Category is required.</small>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <label for="subCategory">Sub Category<sup>*</sup></label>
            <select type="text" class="form-control form-select my-1" placeholder="" value=""
              formControlName="subcategoryId" [(ngModel)]="ticketModel.subCategoryId"
              [ngClass]="{ 'is-invalid': submitted && f.subcategoryId.errors }">
              <option [ngValue]='null' hidden>--Choose Option--</option>
              <option *ngFor="let subcat of subcategory" [ngValue]="subcat.subCategoryId">
                {{subcat.subCategoryName}}</option>
            </select>
            <div *ngIf=" submitted && f.subcategoryId.errors" class="text-danger">
              <div *ngIf="f.subcategoryId.errors.required">
                <small>Sub Category is required.</small>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="ticketSubject">Ticket Subject<sup>*</sup></label>
            <input type="text" class="form-control my-1" [(ngModel)]="ticketModel.ticketSubject" placeholder=""
              value=" " formControlName="ticketSubject"
              [ngClass]="{ 'is-invalid': submitted && f.ticketSubject.errors }" />
            <div *ngIf="submitted && f.ticketSubject.errors" class="text-danger">
              <div *ngIf="f.ticketSubject.errors.required">
                <small>Ticket Subject is required.</small>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="remark">Ticket Descirption<sup>*</sup></label>
            <textarea type="text" class="form-control my-1" placeholder="" value=" "
              [(ngModel)]="ticketModel.ticketDescription" formControlName="ticketDescription"
              [ngClass]="{ 'is-invalid': submitted && f.ticketDescription.errors }"></textarea>
            <div *ngIf="submitted && f.ticketDescription.errors" class="text-danger">
              <div *ngIf="f.ticketDescription.errors.required">
                <small>Ticket Subject is required.</small>
              </div>
            </div>
          </div>


          <div class="col-sm-6">
            <label for="status">Status<sup>*</sup></label>
            <select type="text" class="form-control form-select my-1" formControlName="status"
              (change)="Assigned($event.target.value)" [(ngModel)]="ticketModel.status"
              [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
              <option [ngValue]='null' hidden>--Choose Option--</option>
              <option *ngFor="let status of utStatus" [ngValue]="status.key">
                {{status.status}}</option>
            </select>
            <div *ngIf=" submitted && f.status.errors" class="text-danger">
              <div *ngIf="f.status.errors.required">
                <small>Status is required.</small>
              </div>
            </div>
          </div>

          <div class="col-sm-6" *ngIf="assign">
            <label for="assignedUserId">Assign To<sup>*</sup></label>
            <select type="text" class="form-control form-select my-1" placeholder="" value=""
              formControlName="assignedUserId" [(ngModel)]="ticketModel.assignedUserId"
              [ngClass]="{ 'is-invalid': submitted && f.assignedUserId.errors }">
              <option *ngIf="resetbuttonShow " [ngValue]="null" hidden>--Choose Option--</option>
              <option *ngIf="!resetbuttonShow && AssignedUserOnEditcase "  [ngValue]="null" hidden>
                {{currentAssignUser}}</option>
              <option *ngFor="let user of ticketAssignUser" [ngValue]="user.id">
                {{user.userName}}</option>
            </select>
            <div *ngIf="submitted && f.assignedUserId.errors" class="text-danger">
              <div *ngIf="f.assignedUserId.errors.required">
                <small> Assign is required.</small>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="remarks">Remark</label>
            <textarea type="text" class="form-control my-1" placeholder="" value=" " [(ngModel)]="ticketModel.remarks"
              formControlName="remarks"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-success" (click)="registerException()">{{Save}}</button>
    </div>
  </div>
</ng-template>

<ng-template #ticketHistory let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">Ticket History</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-list custom-table">
        <div class="table-responsive sm-list">
          <table class="table table-striped table-bordered table-hover mx-auto">
            <thead class="table-head">
              <tr>
                <th (click)="sort('name')" class="col location">Changed By </th>
                <th (click)="sort('name')" class="col location">Status</th>
                <th (click)="sort('name')" class="col location">Remarks</th>
                <th (click)="sort('name')" class="col location">Transaction Time</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ticket of ticketHistoryData">
                <td class="left">{{ticket.userName}}</td>
                <td class="left">{{ticket.status}}</td>
                <td class="left">{{ticket.remarks}}</td>
                <td class="left">{{ticket.actionedAt | date: 'dd-MM-yyyy HH:mm'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-footer></app-footer>
