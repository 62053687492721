<app-header></app-header>
<section class="my-2"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0">
              <!-- <fa-icon [icon]="faTruck" class="faicon"></fa-icon>  -->
              Vehicle Masters
            </h4>
          </div>
          <mat-tab-group animationDuration="0ms" mat-align-tabs="end">
            <mat-tab label="Vehicles">
              <app-vehicle></app-vehicle>
            </mat-tab>
            <mat-tab label="Non-Logistic Vehicles">
              <app-third-party-vehicle></app-third-party-vehicle>
            </mat-tab>
            <mat-tab label="InPlant Vehicles">
              <app-inplant-master></app-inplant-master>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>