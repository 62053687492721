<app-header></app-header>
<section class="my-2"*ngIf="userAccessData[0].canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0"> 
              {{title}} Masters</h4>
          </div>
          <mat-tab-group animationDuration="0ms" mat-align-tabs="end"  (selectedTabChange)="tab($event)" >
            <mat-tab label="Alert" >
              <app-alert-master></app-alert-master>
            </mat-tab>
            <mat-tab label="Exception">
              <app-exception-master></app-exception-master>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>


<!-- <div class="container-fluid">
  <div class="d-none d-xl-block mt-4">
    <ul ngbNav #nav="ngbNav"  [(activeId)]="active" class="nav-pills justify-content-end" orientation="horizontal">
      <li ngbNavItem="top">
        <a ngbNavLink (click)="loadTabComponent('ALERTS')"> <p>Alerts ({{alertCount}})</p></a>
      </li>
      <li ngbNavItem="middle">
        <a ngbNavLink (click)="loadTabComponent('EXCEPTION')">Tickets ({{ticketCount}})</a>
      </li>

    </ul>
  </div>

<div class="d-flex mt-4 d-block d-sm-none">
    <ul ngbNav #nav="ngbNav"  [(activeId)]="active" class="nav-tabs justify-content-end" orientation="horizontal">
        <li ngbNavItem="top" >
            <a ngbNavLink (click)="loadTabComponent('ALERTS')"> <p>Alerts ({{alertCount}})</p></a>
          </li>
          <li ngbNavItem="middle">
            <a ngbNavLink (click)="loadTabComponent('EXCEPTION')">Tickets ({{ticketCount}})</a>
          </li>
    </ul>
  </div>
</div> -->

<!-- <footer class="sticky-footer bg-black text-light mt-auto mb-0">
  <app-footer></app-footer>
</footer> -->
