import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// import { LocationMasterResponse } from 'src/app/models/locationmastermodel';
import { DeviceLaneMappingResponse } from 'src/app/models/lanedevicemappingmodel';
import { DeviceLocationMappingResponse } from 'src/app/models/devicelocationmappingmodel';
import { DeviceMasterResponse } from 'src/app/models/devicemastermodel';
import { faDesktop, faEdit, faLandmark, faPlus, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { debounce } from 'rxjs/operators';
import { AnyRecord } from 'dns';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'app-device-location-mapping',
  templateUrl: './device-location-mapping.component.html',
  styleUrls: ['./device-location-mapping.component.scss']
})
export class DeviceLocationMappingComponent implements OnInit {

  // public location: LocationMasterResponse = new LocationMasterResponse();
  public deviceLocationMapping: DeviceLocationMappingResponse = new DeviceLocationMappingResponse();
  public userAccessData: UserScreenAccesData;

  locationMaster: any;
  title: any;

  faEdit = faEdit
  faDesktop = faDesktop
  faSort = faSort
  faTimes = faTimes
  faPlus = faPlus
  faLandmark = faLandmark
  laneMaster: any;
  deviceTypeDDL: any = DevicTypeDll;
  antennaDDL: any = AntennaTypeDll;
  LedLineDll: any = LedLineDll;
  GPONumber :any = GPONumber
  TriggerType :any = TriggerType;
  deviceLocationMappingMaster: any;
  resetbuttonShow:  boolean = true;
  activebuttonShow:  boolean = false;
  form: FormGroup;
  GpoForm: FormGroup;
  submitted = false;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  tempcurrentActivePage: number = 1;
  DeviceLocMappingId: any;
  DeviceLocMapping: any;
  DeviceNameToShow: any;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################



  constructor(private kemarClientService: KemarClientService,
    private modalService: NgbModal,

    private formBuilder: FormBuilder,

    private spinner: NgxSpinnerService, private api:DataService)
    {

    this.form = this.formBuilder.group({
      isActive: [1],
      locationId: [0, [Validators.required, Validators.pattern(/^[1-9]\d*$/)]],
      deviceName: [0, [Validators.required, this.noWhitespaceValidator]],
      direction: ['', [Validators.required,]],
      deviceIP: ['', [Validators.required,   Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)')]],
      deviceType: ['', [Validators.required, this.noWhitespaceValidator]],
      remark: [''],
      antenna: [''],
      ledline: [''],
      lane: [0, [Validators.required, this.noWhitespaceValidator]],
      accessURL: [''],
      snapcaptureURL:[''],
      boomUpUrl: ['']
    });
    this.userAccessData =this.api.getUserScreenAccessMenu('DEVICEMAP');

    this.GpoForm = this.formBuilder.group({
      GPOManagerId:[0],
      DeviceLocMappingId:[0],
      GPONumber:['',[Validators.required]],
      TriggerType:['',[Validators.required]],
      Status:['',[Validators.required]],
      DelayInSec:['',[Validators.required]],
      IsActive:[1],
      RetryCount:[0]
    })
  }
  public noWhitespaceValidator(control: FormControl) {

    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  ngOnInit(): void {
    this.getAllDeviceLocationMapping();
    this.getAllLocationMapping();
  }

  isDisplay = false;
  toggleDisplay() {
    this.isDisplay = !this.isDisplay;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get f2(): { [key: string]: AbstractControl } {
    return this.GpoForm.controls;
  }
  onSubmit(): void {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
    this.form.controls['locationId'].setValue(0);
    this.form.controls['direction'].setValue('');
    this.form.controls['deviceType'].setValue('');
  }

  getAllDeviceLocationMapping() {
    this.kemarClientService.get<DeviceLocationMappingResponse>(null, ServiceUrl.getAllDeviceLocationMappings)
      .subscribe(
        response => {
          this.deviceLocationMappingMaster = response;
          // this.currentActivePage = this.deviceLocationMappingMaster.length;
        },
        r => {
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  getAllLocationMapping() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        response => {
          this.locationMaster = response;
        },
        r => {
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }
  acceptnumber(event: any, flag: boolean) {

    if (flag) {
      this.kemarClientService.keyacceptnumberAndDot(event);
    }
    else {
      this.kemarClientService.keyPressNumbers(event);
    }
  }

  registerLocationDeviceMapping() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerDeviceLocationMapping, this.deviceLocationMapping)
      .subscribe(
        r => {
          // console.log(r);
          Swal.fire('', r.responseMessage, 'success')
          this.getAllDeviceLocationMapping();
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  ngbModal: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size:'lg'
  };
  open(content: any) {
    this.onReset();
    this.title = "Add Device Mapping";
    this.deviceLocationMapping.locationId = 0;
    this.deviceLocationMapping.deviceLocationMappingId = 0
    this.deviceLocationMapping.isActive = true;
    this.deviceLocationMapping.deviceIP = '';
    this.deviceLocationMapping.direction = '';
    this.deviceLocationMapping.antenna = '';
    this.deviceLocationMapping.remark = '';
    this.deviceLocationMapping.deviceName = '';
    this.deviceLocationMapping.deviceType = '';
    this.deviceLocationMapping.lane='';
    this.deviceLocationMapping.accessURL = '';
    this.deviceLocationMapping.snapcaptureURL = '';
    this.deviceLocationMapping.boomUpUrl = '';
    this.activebuttonShow = false;
    this.resetbuttonShow = true;
    this.modalService.open(content, this.ngbModalOptions);

  }

  editDeviceLocationMapping(content, deviceLocationMapping) {

    this.onReset();
    this.title = "Update Device Mapping";
    this.deviceLocationMapping.locationId = deviceLocationMapping.locationId;
    this.deviceLocationMapping.deviceLocationMappingId = deviceLocationMapping.deviceLocationMappingId
    this.deviceLocationMapping.isActive = deviceLocationMapping.isActive;
    this.deviceLocationMapping.deviceIP = deviceLocationMapping.deviceIP;
    this.deviceLocationMapping.direction = deviceLocationMapping.direction;
    this.deviceLocationMapping.remark = deviceLocationMapping.remark;
    this.deviceLocationMapping.deviceName = deviceLocationMapping.deviceName;
    this.deviceLocationMapping.antenna = deviceLocationMapping.antenna;
    this.deviceLocationMapping.deviceType = deviceLocationMapping.deviceType;
    this.deviceLocationMapping.accessURL = deviceLocationMapping.accessURL;
    this.deviceLocationMapping.snapcaptureURL = deviceLocationMapping.snapCaptureURL;
    this.deviceLocationMapping.boomUpUrl = deviceLocationMapping.boomUpUrl;
    this.activebuttonShow = true;
    this.resetbuttonShow = false;
    this.deviceLocationMapping.lane = deviceLocationMapping.lane
    this.modalService.open(content, this.ngbModalOptions);

  }

  Gpo(content ,value){

    this.GpoForm.get('DeviceLocMappingId').setValue(value.deviceLocationMappingId);
    this.DeviceLocMappingId = value.deviceLocationMappingId;
    this.DeviceNameToShow = value.deviceName + ' Lane : ' + value.lane;
    this.GetAllGPOOnDeviceMapping();
    this.modalService.open(content, this.ngbModal);
  }

  Add(content){
    this.submitted =false
    this.activebuttonShow =false;
    this.modalService.open(content, this.ngbModalOptions);
    this.GpoForm.patchValue({
      GPOManagerId:0,
      GPONumber:'',
      TriggerType:'',
      DelayInSec:'',
      Status:'',
      RetryCount:0,
    })
  }

  GpoEdit(content,value){
    this.activebuttonShow =true
    this.GpoForm.patchValue({
      GPOManagerId:value.gpoManagerId,
      DeviceLocMappingId:value.deviceLocMappingId,
      GPONumber:value.gpoNumber,
      TriggerType: value.triggerType,
      Status: value.status,
      DelayInSec:value.delayInSec,
      IsActive:value.isActive,
      RetryCount: value.retryCount
    })
    this.modalService.open(content, this.ngbModalOptions);

  }

  GetAllGPOOnDeviceMapping(){

    this.DeviceLocMapping = null;
    let query = {
      DeviceLocationMappingId:this.DeviceLocMappingId
    }
    this.kemarClientService.get<any>(null,ServiceUrl.GetAllGPOOnDeviceMapping,query)
    .subscribe(
      (res)=>{
       this.DeviceLocMapping = res;
      },
      (err)=>
      {

      }
    )
  }

  SaveGPO(){

    this.submitted =true;
    if(this.GpoForm.invalid)
       return
    var body =this.GpoForm.value
    this.kemarClientService.postPatch<any>(ServiceUrl.RegisterGPOManager,body)
    .subscribe(
      (res)=>{
        Swal.fire('', res.responseMessage, 'success');
        this.closeModalPopup();
        // this.GetAllGPOOnDeviceMapping();
      },
      (err)=>{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err.errorMessage
        })
        this.closeModalPopup();
      }
    )
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) =>  (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 :  this.currentActivePage = this.tempcurrentActivePage

}

const DevicTypeDll: { key: string, value: string }[] = [
  { key: 'RFID Reader', value: 'RFID' },
  { key: 'Controller', value: 'Controller' },
  { key: 'CCTV', value: 'CCTV' },
  { key: 'LED Display', value: 'LED' },
  { key: 'PA system', value: 'PA' },
  { key: 'Breath Analysis', value: 'BA' },
  { key: 'Face Reader', value: 'FR' },
  { key: 'IPC', value: 'IPC' },
  { key:  'LOCATIONPC' , value:'LOCATIONPC'},
  { key:  'LED_MULTILINE' , value:'LED_MULTILINE'}
];
const AntennaTypeDll: { key: string, value: number }[] = [
  { key: 'Antenna 1', value: 1 },
  { key: 'Antenna 2', value: 2 },
  { key: 'Antenna 3', value: 3 },
  { key: 'Antenna 4', value: 4 },
  { key: 'Antenna 5', value: 5 },
  { key: 'Antenna 6', value: 6 },
  { key: 'Antenna 7', value: 7 },
  { key: 'Antenna 8', value: 8 },

];
const LedLineDll: { key: string, value: number }[] = [
  { key: 'Line 1', value: 1 },
  { key: 'Line 2', value: 2 },
  { key: 'Line 3', value: 3 },
  { key: 'Line 4', value: 4 },
  { key: 'Line 5', value: 5 },
  { key: 'Line 6', value: 6 },
  { key: 'Line 7', value: 7 },
  { key: 'Line 8', value: 8 },
  { key: 'Line 9', value: 9 },
  { key: 'Line 10', value: 10 },

];
const GPONumber: { key: string, value: number }[] = [
  { key: '1', value: 1 },
  { key: '2', value: 2 },
  { key: '3', value: 3 },
  { key: '4', value: 4 },
  { key: '5', value: 5 },
  { key: '6', value: 6 },
  { key: '7', value: 7 },
  { key: '8', value: 8 },
];
const TriggerType: { key: string, value: string}[] = [
  { key: 'Boom', value: 'Boom' },
  { key: 'Traffic Light', value:'TrafficLight'}
];
