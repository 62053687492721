<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex data-filter my-2 align-items-center">
          <div class="col-sm-3">
            <h4 class="title-one mb-0"> Device Status </h4>
          </div>

          <div class="col-sm-9 text-end" id="input-filter">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">
                search
              </span>
              <input class="form-control" type="search" name="search" placeholder="Search"
                [(ngModel)]="filter">
            </div>
            <select name="deviceTypeSelect" class="form-control form-select" [(ngModel)]="deviceTypeSelect"
              (change)="onDeviceTypeSelectionChange()">

              <option *ngFor="let devtype of deviceTypeMasterList" [ngValue]="devtype.id">
                {{devtype.deviceType}}</option>
            </select>
            <select name="deviceStatusSelect" class="form-control form-select" [(ngModel)]="deviceStatusSelect"
              (change)="onStatusSelectionChange()">

              <option *ngFor="let devstat of statusMasterList" [ngValue]="devstat.id">
                {{devstat.status}}</option>
            </select>

            <div class="d-inline current-status" style="margin-left: 10px;" *ngIf="deviceStatusSelect == 1 && deviceTypeSelect == 1">
              <mat-icon class="align-middle" [ngStyle]="{'color':'green'}">check_circle</mat-icon>:
              <b>{{availableDeviceCount}}</b> &nbsp; &nbsp;

              <mat-icon class="align-middle" [ngStyle]="{'color':'red'}">cancel</mat-icon>
              <b>{{unavailableDeviceCount}}</b> &nbsp; &nbsp;
            </div>

            <div class="d-inline current-status" *ngIf="deviceStatusSelect != 1 || deviceTypeSelect != 1">

              <span *ngIf="deviceStatusSelect == 2 || deviceStatusSelect == 1">

                <mat-icon class="align-middle" [ngStyle]="{'color':'green'}">check_circle</mat-icon>
                <b>{{availableDeviceCount}}</b> &nbsp; &nbsp;
              </span>
              <span *ngIf="deviceStatusSelect == 3 ||deviceStatusSelect == 1">

                <mat-icon class="align-middle" [ngStyle]="{'color':'red'}">cancel</mat-icon> &nbsp; :
                <b>{{unavailableDeviceCount}}</b> &nbsp; &nbsp;
              </span>

            </div>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-hover mx-auto">
              <thead class="table-head">
                <tr>

                  <th (click)="sort('name')" class="" style="text-align:left">Location

                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="" style="text-align:left">Device Type

                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">IP
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Last Poll Time
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th class="action-key text-center">Refresh
                  </th>
                  <th class="action-key text-center">History
                  </th>
                  <th (click)="sort('name')" class="text-center">Status
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let deviceStatus of deviceStatusList | orderBy: key : reverse | filter: filter">

                  <td class="col-2" style="text-align:left"> {{deviceStatus.location}}</td>
                  <td class="col-2" style="text-align:left"> {{deviceStatus.type}}</td>
                  <td class="col-2" style="text-align:left"> {{deviceStatus.ip}}</td>
                  <td class="col-3" style="text-align:left"> {{deviceStatus.timestamp | date: 'dd-MM-yyyy HH:mm'}}
                  </td>

                  <!-- <td class="col-4">
                <span> {{jobmileTracking.completionTime | date: 'dd-MM-yyyy hh:mm'}}</span>
              </td> -->

                  <td class="col-3 text-center">
                    <mat-icon (click)="refreshSingleDevice(deviceStatus)" style="cursor:pointer">autorenew</mat-icon>

                    <!-- <button type="submit" mat-button color="primary"
                  (click)="refreshSingleDevice(deviceStatus)">Refresh</button> -->
                  </td>

                  <td class="col-1 text-center">
                    <span class="edit-btn-icon" type="button" data-toggle="tooltip" data-placement="center" title="Edit"
                      (click)="displayDeviceStatusHistory(edit, deviceStatus.deviceStatusId) ">
                      <fa-icon [icon]="faHistory"></fa-icon>
                    </span>
                  </td>
                  <td class="col-4 text-center">
                    <span *ngIf="deviceStatus.status  == 'Available'">
                      <mat-icon [ngStyle]="{'color':'green'}">check_circle</mat-icon>
                    </span>

                    <span *ngIf="deviceStatus.status  == 'Not Available'">
                      <mat-icon [ngStyle]="{'color':'red'}">cancel</mat-icon>
                    </span>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>


<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">Device History</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>

    <div
      *ngIf="deviceStatusHistoryList == null || deviceStatusHistoryList == undefined || deviceStatusHistoryList.length <=  0">
      <div class="modal-header">
        <h3 class="modal-title pt-3">There are no records to display</h3>
      </div>
    </div>

    <div
      *ngIf="deviceStatusHistoryList != null && deviceStatusHistoryList != undefined && deviceStatusHistoryList.length > 0">
      <div class="modal-body">
        <div class="table-responsive sm-table vrScrollbar">
          <table class="table table-striped table-hover mx-auto">

            <thead class="table-head">

              <tr>

                <th (click)="sort('name')" class="col-8 location">Status

                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th>
                <th (click)="sort('name')" class="col-4 location">Time

                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th>

              </tr>
            </thead>

            <tbody>

              <tr *ngFor="let device of deviceStatusHistoryList  | filter: filter">
                <td class="col-1" style="text-align:left"> {{device.status}}</td>
                <td class="col-2" style="text-align:left"> {{device.timestamp | date: 'dd-MM-yyyy HH:mm:ss'}}</td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
