export class elv {
    RequestId: string;
    LocationCode: string;
    VehicleList: Array<VehicleDetail> = new Array<VehicleDetail>();
  }

  export class VehicleDetail {
    ELVCode: string;
    VRN: string;
    DriverCode: string;
    MovementType: string;
    ValidTill: string;
    IsActive: string = 'true';
    Doctype: string;
    HelperCode: string;
  }
