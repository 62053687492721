<header id="header" class="banner automotive-img position-relative">
  <div class="overlay"></div>
  <div class="container">
      <nav class="navbar">
          <div class="container p-0">
              <a routerLink="/" class="logo"><img src="assets/landing/img/logo.svg" alt=""></a>
              <form class="justify-content-end">
                  <a href="http://www.urgetruck.com/" target="_blank" class="btn-red login"> <i
                          class="bi bi-person-fill"></i> LOGIN </a>
              </form>
          </div>
      </nav>

      <nav class="cd-vertical-nav">
          <ul>
              <li><a routerLink="/" class="active"><span class="label">Home</span></a></li>
              <li><a routerLink="/steel"><span class="label">Steel</span></a></li>
              <li><a routerLink="/automotive"><span class="label">Automotive</span></a></li>
              <li><a routerLink="/cement"><span class="label">Cement</span></a></li>
              <li><a routerLink="/port"><span class="label">Port</span></a></li>
          </ul>
      </nav>
  </div>
</header>

<section class="bg-white">
  <div class="container">
      <div class="row gx-4 gx-lg-5 align-items-center">
          <div class="col-md-4"><img class="card-img-top mb-5 mb-md-0"
                  src="../../../assets/landing/img/automotive.jpg" alt="..."></div>
          <div class="col-md-8 text-start">
              <h2>Automotive</h2>
              <p>The automobile is the epitome of a high-priced, individually customized product. Hence customers
                  expect a high delivery service quality. Distribution logistics provides the link between the
                  manufacturer and the customer and is therefore responsible for providing these services. In
                  automobile logistics, the distribution chain involves several stakeholders, such as manufacturers,
                  transport, and technical services providers, as well as dealers. Seamless coordination between them
                  is crucial. Digitalization is an inevitable means for gaining a high level of transparency among the
                  stakeholders. In addition, it provides technologies for developing effective and tailor-made
                  assistance and control systems that support processes along the distribution chain.</p>
              <p>The Gate-IN/Out processes, Vehicle Yard Management, BarCode / RFID based Manufactured Vehicle
                  Identification and Yard Parking, Reallocation of Yard parking, Exact yard location identification of
                  new vehicles using DGPS locations by using Hand Held Computers, and Sales and dispatch processes,
                  can be taken care by customising UrgeTruck Solution for Automotive Industries,</p>
          </div>
      </div>
  </div>
</section>
<app-lfooter></app-lfooter>
