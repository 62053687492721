import { Component, OnInit, ViewChild } from '@angular/core';

import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { DriverMasterResponse } from 'src/app/models/drivermastermodel';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { faDoorClosed, faEdit, faPlus, faSort, faTimes, faTruck, faUser, faSearch, faDownload } from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { dateFormat } from 'highcharts';
import { Pagination } from 'src/app/models/pagination';
import { MatPaginator } from '@angular/material/paginator';
import { iif } from 'rxjs';
import { Duplex } from 'stream';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { ExcelService } from 'src/app/service/excel.service';



@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit {
  BlacklistingReason: any;
  vehicleBlacklisting: [];
  Blacklisting: any;
  public driver: DriverMasterResponse = new DriverMasterResponse();
  public userAccessData: UserScreenAccesData;

  [x: string]: any;

  driverMaster: any;
  helperMaster: any;
  faEdit = faEdit;
  faTimes = faTimes
  faPlus = faPlus
  faSort = faSort
  faTruck = faTruck
  faUser = faUser;
  faSearch = faSearch;
  faDownload = faDownload;
  title: any;
  form: FormGroup;
  activebuttonShow: boolean = false;

  submitted = false;
  blacklistedDll: any = blacklistedDll;
  blacklistShow: boolean = false;
  resetbuttonShow: boolean = false;
  blaclistingDescription: boolean;
  blaclistingIncidentDate: boolean = false;
  Blacklistingreason: boolean = false;
  PreviewsBlackListed: boolean;
  lastActivePage: number;
  driverSelectedColumn: any = "1";
  helperSelectedColumn: any = "1";
  editType = 'Driver';
  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 15;
  currentPage = 1;
  totalRecords: any;

  filterHelper = '';
  pageSizeForHelper = 15;
  currentPageForHelper = 1;
  totalHelperRecords: any;

  key: string = 'driverCode'; //set default
  reverse: boolean = false;
  currentdate: string;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  blaclistingChange = true;
  ReasonRequired = true;
  DescriptionRequired = true;
  //IncidentRequired = true;
  DateRequired = true;
  public pagination: Pagination = new Pagination(1, 0, 15, 0, [10, 20, 30, 40]);

  IsEnableSearchBox: boolean = true;
  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('closebutton') closebutton;
  //################################# END Pagination ###########################################

  constructor(private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,

    private formBuilder: FormBuilder,
    private excelService: ExcelService,
    private modalService: NgbModal,private api:DataService) {


    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.pattern("^[a-zA-Z\\s ]*$"), Validators.maxLength(30),this.noWhitespaceValidator]],
      isActive: [1],
      address: [''],
      // address: ['', [Validators.required,this.noWhitespaceValidator]],
      driverCode: ['', [Validators.required,this.noWhitespaceValidator , Validators.minLength(2), Validators.maxLength(20)]],
      mobileNo: [''],
      // mobileNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(11),this.noWhitespaceValidator]],
      email: [''],
      // email: ['', [Validators.required, Validators.email]],
      aadharNo: ['', [Validators.required, Validators.pattern(/^[1-9]\d*$/), Validators.minLength(12), Validators.maxLength(12), this.noWhitespaceValidator]],
      dlNo: ['', [Validators.required, this.noWhitespaceValidator, Validators.minLength(15), Validators.maxLength(16)]],
      isBlackListed: [''],
      dlValidaty: ['', [Validators.required]],
      reason: [''],
      description: [''],
      incidentDateTime: ['']

    });
    this.userAccessData =this.api.getUserScreenAccessMenu('DRIVER');
  }

  public emailValidationOnChange(){
    var rfidValue = this.form.controls['email'].value;
    if(rfidValue == '' || rfidValue == undefined || rfidValue == null){
      this.form.controls['email'].clearValidators();
    }
    else{
      this.form.controls['email'].setValidators(
        [Validators.email]
      );
    }
    this.form.controls['email'].updateValueAndValidity();
  }

  public noWhitespaceValidator(control: FormControl) {

    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit(): void {
    // this.getAllDrivers();
    this.GetDriverItemPerPage(this.currentPage)
    this.GetHelperItemPerPage(this.currentPage)
    this.getVehiclesBlacklistingReason();
    this.currentdate = new Date().toISOString().split('T')[0];
  }
  // OnPageChange(event) {
  //   this.pagination.page = event;
  //   this.GetDriverItemPerPage();
  // }

  SearchFilter() {
    this.currentPage = 1;
    this.GetDriverItemPerPage(this.currentPage);
  }

  OnPageSizeChange(pageSize: any) {
    this.pageSize = pageSize;
    this.GetDriverItemPerPage(this.currentPage);
  }

  SearchHelperFilter() {
    this.currentPageForHelper = 1;
    this.GetHelperItemPerPage(this.currentPage);
  }

  OnHelperPageSizeChange(pageSize: any) {
    this.pageSizeForHelper = pageSize;
    this.GetHelperItemPerPage(this.currentPage);
  }

  // driverchange(val: any) {
  //   this.currentPage = 1;
  //   this.GetDriverItemPerPage(this.currentPage);
  // }
  EnterBtn(event: any) {
    if (event.keyCode === 13) {
      this.currentPage = 1;
      this.GetDriverItemPerPage(this.currentPage);
    }
  }

  EnterBtnForHelper(event: any) {
    if (event.keyCode === 13) {
      this.currentPage = 1;
      this.GetHelperItemPerPage(this.currentPage);
    }
  }

  GetDriverItemPerPage(currentPage: any) {

    if (!this.kemarClientService.commonValidation(this.driverMaster) && this.driverMaster.length < this.pageSize) {
      this.currentPage = 1
      this.getData(1)
    }
    else
      this.getData(currentPage)
  }

  GetHelperItemPerPage(currentPage: any) {

    if (!this.kemarClientService.commonValidation(this.helperMaster) && this.helperMaster.length < this.pageSizeForHelper) {
      this.currentPage = 1
      this.getHelperData(1)
    }
    else
      this.getHelperData(currentPage)
  }

  getData(currentPage: any, downloadExcel = 'false'){
    this.spinner.show();
    var query = {
      currentPage: currentPage,
      rowSize: this.pageSize,
      searchtext: this.filter.trim(),
      searchColumn: this.driverSelectedColumn,
      download: downloadExcel
    };
    this.kemarClientService
      .get<any>(
        null,
        ServiceUrl.getDriverItemPerPage,
        query
      )
      .subscribe(
        (response) => {
          if(downloadExcel == 'true'){
            this.exportToExcelDriver(response);
            this.spinner.hide();
          }
          else{
            this.spinner.hide();
            this.driverMaster = response;
            this.driverMaster.length > 0 ? this.totalRecords = response[0].totalRecord : this.totalRecords = 0;
            this.currentPage = currentPage;
          }
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }

  getHelperData(currentPageForHelper: any, downloadExcel = 'false'){
    this.spinner.show();
    var query = {
      currentPage: currentPageForHelper,
      rowSize: this.pageSizeForHelper,
      searchtext: this.filterHelper.trim(),
      searchColumn: this.helperSelectedColumn,
      download: downloadExcel
    };
    this.kemarClientService
      .get<any>(
        null,
        ServiceUrl.getHelperItemPerPage,
        query
      )
      .subscribe(
        (response) => {
          if(downloadExcel == 'true'){
            this.exportToExcelHelper(response);
            this.spinner.hide();
          }
          else{
            this.spinner.hide();
            this.helperMaster = response;
            this.helperMaster.length > 0 ? this.totalHelperRecords = response[0].totalRecord : this.totalHelperRecords = 0;
            this.currentPageForHelper = currentPageForHelper;
          }
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  exportToExcelDriver(driverMasterData: any) {
    this.dataForExcel = [];
    driverMasterData.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row));
    });

    let gateData = {
      title: 'Driver Master',
      data: this.dataForExcel,
      headers: Object.keys(driverMasterData[0]),
    };
    this.excelService.exportExcelForDriverMaster(gateData, 'Driver Master');
  }

  exportToExcelHelper(driverMasterData: any) {
    this.dataForExcel = [];
    driverMasterData.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row));
    });

    let gateData = {
      title: 'Helper Master',
      data: this.dataForExcel,
      headers: Object.keys(driverMasterData[0]),
    };
    this.excelService.exportExcelForDriverMaster(gateData, 'Helper Master');
  }

  // GetDriverItemPerPage() {
  //   if (this.pagination.searchtext === undefined) {
  //     this.pagination.searchtext == null;
  //   }
  //   var query = { 'page': this.pagination.page, 'pagesize': this.pagination.pageSize, 'searchtext': this.pagination.searchtext }
  //   this.kemarClientService.get<any>(null, ServiceUrl.getDriverItemPerPage,query)
  //     .subscribe(
  //       response => {
  //         this.driverMaster = response.items;
  //         this.pagination.count = response.count;
  //         this.pagination.filterRecordCount = response.filterRecordCount;
  //         this.pagination.totalTransactionCount = response.totalTransactionCount;
  //       },
  //       r => {
  //         console.log(r.error.error);
  //       });
  // }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  getAllDrivers() {

    this.spinner.show();
    this.kemarClientService.get<DriverMasterResponse>(null, ServiceUrl.getAllDrivers)
      .subscribe(
        response => {
          this.driverMaster = response;
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  open(content: any, personType) {
    this.addValidationForDL(personType);
    this.editType = personType;
    this.onReset();
    this.title = "Add "+ personType;
    this.form.get('driverCode').enable();
    this.driver.driverId = 0;
    this.driver.name = '';
    this.driver.address = '';
    this.driver.driverCode = '';
    this.driver.isActive = true;
    this.driver.mobileNo = '';
    this.driver.email = '';
    this.driver.aadharNo = null;
    this.driver.dlValidaty = null;
    this.activebuttonShow = false;
    this.driver.dlNo = '';
    this.driver.isBlackListed = false;
    this.blaclistingChange = true;
    this.blacklistShow = false;
    this.resetbuttonShow = true;
    this.driver.personType = personType;
    if(personType == 'Helper'){
      this.driver.dlValidaty = new Date();
    }
    this.modalService.open(content, this.ngbModalOptions);
  }

  addValidationForDL(personType){
if(personType == 'Driver'){
  this.form.controls['dlNo'].setValidators([Validators.required, this.noWhitespaceValidator, Validators.minLength(15), Validators.maxLength(16)]);
  this.form.controls['dlValidaty'].setValidators([Validators.required]);
}
else{
  this.form.controls['dlNo'].clearValidators();
  this.form.controls['dlValidaty'].clearValidators();

}
this.form.controls['dlNo'].updateValueAndValidity();
this.form.controls['dlValidaty'].updateValueAndValidity();

  }

  editdriver(content: any, driver, personType) {
    this.addValidationForDL(personType);
    this.editType = personType;
    this.title = "Update "+ personType;

    this.submitted = false;
    this.PreviewsBlackListed = driver.isBlackListed;
    this.driver.driverId = driver.driverId;
    this.form.get('driverCode').disable();
    this.form.patchValue(driver);
    this.driver.name = driver.name;
    this.driver.address = driver.address;
    this.driver.driverCode = driver.driverCode;
    this.driver.isActive = driver.isActive;
    this.driver.mobileNo = driver.mobileNo;
    this.driver.email = driver.email;
    this.driver.aadharNo = driver.aadharNo;
    this.driver.dlNo = driver.dlNo;
    this.activebuttonShow = true;
    this.blacklistShow = true;
    this.blaclistingChange = true;
    this.resetbuttonShow = false;
    this.driver.isBlackListed = driver.isBlackListed;
    this.driver.reason = '';
    this.driver.description = '';
    this.driver.incidentDateTime = null;
    this.driver.personType = personType;
    if(personType == 'Helper'){
      this.driver.dlValidaty = new Date();
    }
    this.driver.dlValidaty = driver.dlValidaty.split('T')[0];
    // this.driver.dlValidaty = driver.dlValidaty;
    this.blaclistingChange = this.blaclistingIncidentDate = this.blaclistingDescription = this.Blacklistingreason = false;
    // DateTime(driver.dlValidaty);

    this.modalService.open(content, this.ngbModalOptions);
  }

  registerDriver() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if (this.blaclistingChange == false) {
      // this.Reasonrequired();
      // if (this.ReasonRequired || this.DescriptionRequired || this.IncidentRequired) {
      //   return;
      // }
      if (this.Blacklistingreason && this.blaclistingIncidentDate && this.blaclistingDescription) {
        this.ReasonRequired = this.driver.reason == '' ? true : false;
        this.ReasonRequired = this.kemarClientService.commonValidation(this.driver.reason);
        this.DateRequired = this.kemarClientService.commonValidation(this.driver.incidentDateTime);
        this.DescriptionRequired = this.kemarClientService.commonValidation(this.driver.description);
        if (this.ReasonRequired || this.DateRequired || this.DescriptionRequired)
          return
      }
      else if (this.blaclistingDescription) {
        this.DescriptionRequired = this.kemarClientService.commonValidation(this.driver.description);
        if (this.DescriptionRequired)
          return
      }
    }

    this.driver.editFlag = this.activebuttonShow

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerDriver, this.driver)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success')
          //this.getAllDrivers();
          if(this.editType == 'Helper'){
            this.GetHelperItemPerPage(this.currentPage);
          }else{
            this.GetDriverItemPerPage(this.currentPage);
          }
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          if (r.error.status === 409) {
            Swal.fire({
              icon: 'info',
              title: '',
              text: "Can't register duplicate driver Code."
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: '',
              text: r.error.errorMessage
            })
          }
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  getVehiclesBlacklistingReason() {
    this.spinner.show();
    this.kemarClientService
      .get<DriverMasterResponse>(null, ServiceUrl.GetDriverBlacklistingReason)
      .subscribe(
        (response) => {
          this.BlacklistingReason = response;
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }



  keyPressnum(event: any) {
    this.kemarClientService.keyPressNumbers(event)
  }
  // ChangedBlacklist() {
  //   this.blaclistingChange = !this.blaclistingChange;

  // }

  ChangedBlacklist(value: any) {

    if (value === "true") {
      if (this.PreviewsBlackListed === true)
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = false;
      else
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = true;
    }
    else {
      if (this.PreviewsBlackListed === true) {
        this.blaclistingIncidentDate = this.Blacklistingreason = false
        this.blaclistingDescription = true;
      }
      else {
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = false;
      }
    }
  }

  // Reasonrequired() {
  //
  //   this.submitted = true;
  //   if ( this.driver.reason == '') {
  //     this.ReasonRequired = true;
  //   }
  //   else{
  //     this.ReasonRequired = false;
  //   }
  //   if( this.driver.incidentDateTime==null){
  //     this.IncidentRequired= true;
  //   }else{
  //     this.IncidentRequired = false;
  //   }
  //   if( this.driver.description==''){
  //     this.DescriptionRequired= true;
  //   }else{
  //     this.DescriptionRequired = false;
  //   }
  // }

  DropDownChnange(val: any) {

    if (val === "undefined") {
      this.IsEnableSearchBox = true;
      this.filter = '';
      this.GetDriverItemPerPage(1);
      return;
    }
    else if (!this.kemarClientService.commonValidation(val)) {
      this.IsEnableSearchBox = false
    }
    else {
      this.IsEnableSearchBox = true
    }
  }

  DropDownChnangeForHelper(val: any) {

    if (val === "undefined") {
      this.IsEnableSearchBox = true;
      this.filterHelper = '';
      this.GetDriverItemPerPage(1);
      return;
    }
    else if (!this.kemarClientService.commonValidation(val)) {
      this.IsEnableSearchBox = false
    }
    else {
      this.IsEnableSearchBox = true
    }
  }

  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);
  keyPressBlackListing = (val: any) => this.ReasonRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  keyPressIncidentDate = (val: any) => this.DateRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  keyPressDescition = (val: any) => this.DescriptionRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
}


const blacklistedDll: { key: string, value: string }[] = [
  { key: 'Yes', value: 'true' },
  { key: 'No', value: 'false' }
];

function DateTime(dlValidaty: any) {
  dlValidaty: new Date();
}
