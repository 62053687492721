<ngx-spinner> </ngx-spinner>
        <div class="d-flex data-filter align-items-center my-2">
          <div class="col-sm-4">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search" placeholder="Search"
                [(ngModel)]="filter" (keyup)="currentActivePage=1">
            </div>
          </div>
          
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="col-2 sr-no">Sr. No.</th>
                  <th (click)="sort('name')" class="">Vehicle Registration No.
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Vehicle Type
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')" class="">Blacklisted
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Incident Date
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Reason
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Created by
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="w-25">Description
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>

                <!-- <input type="hidden" name="vehicleId" value={{vehicleBlacklisting.vehicleId}} /> -->
                <tr
                  *ngFor="let vehicleBlacklisting of VehicleBlack  | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage, id: 'VehicleBlack' }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td>{{vehicleBlacklisting.vrn}}</td>
                  <td>{{vehicleBlacklisting.vehicleType}}</td>
                  <td>
                    <span *ngIf="vehicleBlacklisting.status.toString().toUpperCase() == 'TRUE'">YES</span>
                    <span *ngIf="vehicleBlacklisting.status.toString().toUpperCase() == 'FALSE'">NO</span>
                  </td>
                  <td>{{vehicleBlacklisting.incidentDate| date: 'dd-MM-yyyy'}}</td>
                  <td>{{vehicleBlacklisting.reason}}</td>
                  <td>{{vehicleBlacklisting.created}}</td>
                  <td>{{vehicleBlacklisting.description}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
<div class="pagination justify-content-end">
   <pagination-controls id="VehicleBlack" (pageChange)="currentActivePage = $event"></pagination-controls>
</div>

<!-- <ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
            <form autocomplete="off" [formGroup]="form">
              <div class="form-group">
                <div class="form-group ml-3">
                  <input type="hidden" minlength="3" maxlength="30" class="form-control" name="vehicleBlacklistingId"
              value="{{vehicleBlacklist.vehicleBlacklistingId}}" />
                </div>
                <div class="form-group row  mb-0 mt-2 ">
                    <div class="col-sm-12">
                      <label for="vrn">Vehicle Registration No. <sup>*</sup></label>
                      <input type="text" class="form-control" placeholder="" value=" "
                        [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }"
                        formControlName="vrn" [(ngModel)]="vehicleBlacklist.vrn" />
                      <div *ngIf="submitted && f.vrn.errors" class="invalid-feedback">
                        <div *ngIf="f.vrn.errors.required">Vehicle Registration Id is required.
                        </div>
                        <div *ngIf="f.vrn.errors.minlength">
                          Vehicle Registration No must be at least 3 characters.
                        </div>
                        <div *ngIf="f.vrn.errors.maxlength">
                          Vehicle Registration No must not exceed 30 characters.
                        </div>
                        <div *ngIf="f.vrn.errors?.pattern">
                          <sup>*</sup>Vehicle Registration No should contain only a-z,A-Z,0-9,-.
                        </div>
                      </div>
                    </div>

                </div>
                <div class="form-group row  mb-0 mt-2">
                  <div class="col-sm-12">
                    <label for="locationName">Reason</label>
                    <select name="isBlackListed" [(ngModel)]="vehicleBlacklist.isBlackListed" class="form-control form-select"
                      formControlName="isBlackListed" [ngClass]="{ 'is-invalid': submitted && f.isBlackListed.errors }">
                      <option hidden Value='' disabled>--Choose Option--</option>
                      <option *ngFor="let vehicleBlacklist of BlacklistingReasonForVehicle" [ngValue]="vehicleBlacklist.reason">
                        {{vehicleBlacklist.reason}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row  mb-0 mt-2">
                  <div class="col-sm-12">
                    <label for="dateAndTime">Incident Date <sup>*</sup></label>
                    <input type="date" class="form-control" placeholder="" value=" "
                      [ngClass]="{ 'is-invalid': submitted && f.dateAndTime.errors }" formControlName="dateAndTime"
                      [(ngModel)]="vehicleBlacklist.dateAndTime" />
                    <div *ngIf="submitted && f.dateAndTime.errors" class="invalid-feedback">
                      <div *ngIf="f.dateAndTime.errors.required">Location name is required.
                      </div>
                      <div *ngIf="f.dateAndTime.errors.minlength">
                        Location name must be at least 3 characters.
                      </div>
                      <div *ngIf="f.dateAndTime.errors.maxlength">
                        Location name must not exceed 30 characters.
                      </div>
                      <div *ngIf="f.dateAndTime.errors?.pattern">
                        <sup>*</sup>Location name should contain only a-z,A-Z,0-9,-.
                      </div>
                    </div>
                    </div>
                    </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-12">
                  <label for="description">Description <sup>*</sup></label>
                  <textarea type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.description.errors }" formControlName="description"
                    [(ngModel)]="vehicleBlacklist.description"></textarea>
                  <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">Description is required.
                    </div>
                    <div *ngIf="f.description.errors.minlength">
                      Description must be at least 3 characters.
                    </div>
                    <div *ngIf="f.description.errors.maxlength">
                      Description must not exceed 30 characters.
                    </div>
                    <div *ngIf="f.description.errors?.pattern">
                      <sup>*</sup>Description should contain only a-z,A-Z,0-9,-.
                    </div>
                  </div>
                </div>
                </div>

                <!-- <div class="form-group mb-0 mt-3 select-radio" *ngIf="activebuttonShow">
                  <label for="isActive" class="radio-btn">Active</label>
            <label class="form-check-inline">
              <input class="form-check-input" type="radio" name="isActive" [value]="true" formControlName="isActive"
                [(ngModel)]="vehicleBlacklist.isActive">
            </label>
            <label for="isActive" class="radio-btn ">Inactive</label>
            <label class="form-check-inline">
              <input class="form-check-input" type="radio" name="isActive" [value]="false" formControlName="isActive"
                [(ngModel)]="vehicleBlacklist.isActive">
            </label>
                </div> -->

                <!-- <div class="select-radio" *ngIf="activebuttonShow">
                  <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="vehicleBlacklist.isActive" formControlName="isActive">
                  <label for="active" class="radio-label">Active</label>

                  <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="vehicleBlacklist.isActive" formControlName="isActive">
                  <label for="inactive" class="radio-label">Inactive</label>
                </div>


                </div>
            </form>
        </div> -->
        <!-- <hr class="hr mb-0">
<div class="modal-footer">
  <button type="button" class="modal-button btn btn-secondary" aria-label="Close"
    (click)="modal.dismiss('Cross click')">Close</button>
  <button type="submit" class="modal-button btn btn-primary" (click)="registerMilestone(milestone)">Save</button>
</div> -->
    <!-- </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
        <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow" (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-success"
        (click)="registervehicleBlacklisting(vehicleBlacklist)">Save</button>
    </div>
  </div>
</ng-template> --> 
