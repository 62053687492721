import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { VehicleMasterResponse } from 'src/app/models/vehiclemastermodel';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  faDoorClosed,
  faEdit,
  faPlus,
  faSort,
  faTimes,
  faTruck,
  faSearch,
  faLeaf
} from '@fortawesome/free-solid-svg-icons';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  MaxLengthValidator,
  Validators,
} from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-cement-vehicle',
  templateUrl: './cement-vehicle.component.html',
  styleUrls: ['./cement-vehicle.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class CementVehicleComponent implements OnInit {
  BlacklistingReason: any;
  vehicleBlacklisting: [];
  Blacklisting: any;
  public vehicle: VehicleMasterResponse = new VehicleMasterResponse();
  vehicleMaster: any[];
  faEdit = faEdit;
  faTimes = faTimes;
  faPlus = faPlus;
  faSort = faSort;
  faTruck = faTruck;
  faDoorClosed = faDoorClosed;
  form: UntypedFormGroup;
  dateFormat = 'dd-mm-yyyy'

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 15;
  title: any;
  currentdate: string;
  key: string = 'vrDate'; //set default
  reverse: boolean = false;
  ActiveShow: boolean = false;
  resetbuttonShow: boolean = true;
  submitted = false;
  blacklistedDll: any = blacklistedDll;
  vehicleTypeDll: any = vehicleTypeDll;
  tempcurrentActivePage: number = 1;
  selectedColumn: any;
  totalRecords: number = 10;
  faSearch = faSearch;
  IsEnableSearchBox: boolean = true;

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  blaclistingChange = true;
  ReasonRequired = true;
  DescriptionRequired = true;
  IncidentRequired = true;
  DateRequired = true;
  blaclistingDescription: boolean;
  blaclistingIncidentDate: boolean = false;
  Blacklistingreason: boolean = false;
  PreviewsBlackListed: boolean;
  public userAccessData: UserScreenAccesData;
  vehicleDetailtoCheck: any;

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;

  @ViewChild('closebutton') closebutton;

  constructor(
    private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private dataService: DataService,
    private confirmationService: ConfirmationService, private messageService: MessageService
  ) {
    this.userAccessData =
      this.dataService.getUserScreenAccessMenu('CEMENTVEHICLE');
    this.form = this.formBuilder.group({
      rfidTagNumber: [
        ''
      ],
      isActive: [''],
      vrn: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(11),
          Validators.pattern('[a-zA-Z0-9]*'),
          this.noWhitespaceValidator,
        ],
      ],

      tareweightKg: [
        0
      ],
      engineCapacityCC: [
        0
      ],
      isBlackListed: [''],
      isVerified: [''],
      isNonLogistic: [''],
      permitTill: ['', [Validators.required]],
      vehicleType: [''],
      vrDate: ['', [Validators.required]],
      // permitTill: ['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
      // vrDate: ['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
      numberOfWheels: [
        0,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(2),
          Validators.pattern('[0-9.]*'),
          Validators.min(2),
        ],
      ],
      capacityInTons: [
        0
      ],
      reason: [''],
      description: [''],
      incidentDateTime: [''],
    });
  }

  public rfidValidationOnChange(){
    var rfidValue = this.form.controls['rfidTagNumber'].value;
    if(rfidValue == '' || rfidValue == undefined || rfidValue == null){
      this.form.controls['rfidTagNumber'].clearValidators();
    }
    else{
      this.form.controls['rfidTagNumber'].setValidators(
        [Validators.minLength(20),
          Validators.maxLength(28),
          Validators.pattern('[a-zA-F0-9]*'),
          this.noWhitespaceValidator]
      );
    }
    this.form.controls['rfidTagNumber'].updateValueAndValidity();
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  ngOnInit(): void {
    this.GetVehicleItemPerPage(this.currentActivePage);
    this.getVehiclesBlacklistingReason();
    this.currentdate = new Date().toISOString().split('T')[0];
  }

  SearchFilter() {
    this.currentActivePage = 1;
    this.GetVehicleItemPerPage(this.currentActivePage);
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }

  GetVehicleItemPerPage(currentPage: any) {

    if (!this.kemarClientService.commonValidation(this.vehicleMaster) && this.vehicleMaster.length < this.pageSize) {
      this.currentActivePage = 1
      this.getData(1)
    }
    else
      this.getData(currentPage)

  }

  getData(currentPage: any) {

    this.spinner.show();
    var query = {
      vehicleType:'CEMENT',
      currentPage: currentPage,
      rowSize: this.pageSize,
      searchtext: this.filter.trim(),
      searchColumn: this.selectedColumn
    };
    this.kemarClientService
      .get<any>(
        null,
        ServiceUrl.getAllLogisticsVehicleAsync,
        query
      )
      .subscribe(
        (response) => {

          this.spinner.hide();
          this.vehicleMaster = response;
          this.vehicleMaster.length > 0 ? this.totalRecords = response[0].totalRecord : this.totalRecords = 0;
          this.currentActivePage = currentPage;
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }

  getDatabyVRN() {
var vehicleNUmber = this.form.get('vrn').value;
if(vehicleNUmber == '' || vehicleNUmber == undefined || vehicleNUmber == null){
  return;
}
    this.spinner.show();
    var query = {
      VRN:vehicleNUmber
    };
    this.kemarClientService
      .get<any>(
        null,
        ServiceUrl.getVehiclebyVRN,
        query
      )
      .subscribe(
        (response) => {

          this.spinner.hide();
          this.vehicleDetailtoCheck = response;
          if(this.vehicleDetailtoCheck != null){
            if(this.vehicleDetailtoCheck.cementVehicle == true &&
              (this.vehicleDetailtoCheck.vehicleType == 'LOGISTIC' || this.vehicleDetailtoCheck.vehicleType == 'CEMENT')){
                let message = 'This '+ this.vehicleDetailtoCheck.vrn +' is already register in cement category.';
                Swal.fire('', message, 'error');
                this.closeModal();
            }
            else if(this.vehicleDetailtoCheck.steelVehicle == true && this.vehicleDetailtoCheck.cementVehicle == false &&
              (this.vehicleDetailtoCheck.vehicleType == 'LOGISTIC' || this.vehicleDetailtoCheck.vehicleType == 'CEMENT')){
              this.confirm1();
            }
            else if(this.vehicleDetailtoCheck.vehicleType == 'NONLOGISTIC'){
              let message = 'This '+ this.vehicleDetailtoCheck.vrn +' is already register in ' + this.vehicleDetailtoCheck.vehicleType.toLowerCase() + ' category.';
              Swal.fire('', message, 'error');
              this.closeModal();
            }
            else if(this.vehicleDetailtoCheck.vehicleType == 'INPLANT'){
              let message = 'This '+ this.vehicleDetailtoCheck.vrn +' is already register in ' + this.vehicleDetailtoCheck.vehicleType.toLowerCase() + ' category.';
                Swal.fire('', message, 'error');
                this.closeModal();
            }
            // this.updateVehicleDetail(this.vehicleDetailtoCheck)
          }
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  updateVehicleDetail( vehicle) {
    this.onReset();
    this.form.patchValue(vehicle);
    this.title = 'Update Vehicle';
    this.PreviewsBlackListed = vehicle.isBlackListed;
    this.vehicle.isActive = vehicle.isActive;
    this.vehicle.vehicleId = vehicle.vehicleId;
    this.vehicle.permitTill = vehicle.permitTill.split('T')[0];
    this.vehicle.vrDate = vehicle.vrDate.split('T')[0];
    this.vehicle.vehicleType = 'CEMENT'
    this.blaclistingChange = this.blaclistingIncidentDate = this.blaclistingDescription = this.Blacklistingreason = false;
    this.vehicle.reason = '';
    this.vehicle.description = '';
    this.vehicle.incidentDateTime = null;
    this.ActiveShow = true;
  }
  confirm1() {
    this.confirmationService.confirm({
        message: 'This '+ this.vehicleDetailtoCheck.vrn +' is already register in ' + this.vehicleDetailtoCheck.vehicleType.toLowerCase() + ' category. '
          + '</br>' + ' Do you want to add this vehicle in cement category ?',
        header: 'Alert',
        key: 'alert',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.updateVehicleDetail(this.vehicleDetailtoCheck);
        },
        reject: (type) => {
          this.closeModal();
        }
    });
}

  getVehiclesBlacklistingReason() {
    this.spinner.show();
    this.kemarClientService
      .get<VehicleMasterResponse>(null, ServiceUrl.GetVehicleBlacklistingReason)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.BlacklistingReason = response;
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  registerVehicle() {
    this.submitted = true;
    if(this.vehicle.rfidTagNumber != '' && this.vehicle.rfidTagNumber != null && this.vehicle.rfidTagNumber != undefined ){
      this.vehicle.rfidTagNumber = this.vehicle.rfidTagNumber.toUpperCase();
    }
    this.vehicle.vrn = this.vehicle.vrn.toUpperCase();
    if (this.form.invalid) {
      return;
    }
    if (this.blaclistingChange == false) {
      if (this.Blacklistingreason && this.blaclistingIncidentDate && this.blaclistingDescription) {
        this.ReasonRequired = this.kemarClientService.commonValidation(this.vehicle.reason);
        this.DateRequired = this.kemarClientService.commonValidation(this.vehicle.incidentDateTime);
        this.DescriptionRequired = this.kemarClientService.commonValidation(this.vehicle.description);
        if (this.ReasonRequired || this.DateRequired || this.DescriptionRequired)
          return
      }
      else if (this.blaclistingDescription) {
        this.DescriptionRequired = this.kemarClientService.commonValidation(this.vehicle.description);
        if (this.DescriptionRequired)
          return
      }
    }
    this.vehicle.transporterId = 1;
    this.spinner.show();
    this.kemarClientService
      .postPatch<any>(ServiceUrl.registerVehicle, this.vehicle)
      .subscribe(
        (res) => {
          Swal.fire('', res.responseMessage, 'success');
          this.GetVehicleItemPerPage(this.currentActivePage);
          this.closeModalPopup();
          this.spinner.hide();
        },
        (r) => {

          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          });
          this.spinner.hide();
        }
      );
  }


  closeModal() {
    this.blaclistingChange = this.blaclistingIncidentDate = this.blaclistingDescription = this.Blacklistingreason = false;
    this.modalService.dismissAll();
  }
  keyPressAlphaNumeric(event) {
    this.kemarClientService.keyPressAlphaNumeric(event);
  }


  open(content: any) {
    this.onReset();
    this.title = 'Add Vehicle';
    this.vehicle.vehicleId = 0;
    this.vehicle.capacityInTons = '';
    this.vehicle.engineCapacityCC = '';
    this.vehicle.vrn = '';
    this.ActiveShow = false
    //this.vehicle.isActive = true;
    this.vehicle.rfidTagNumber = '';
    this.vehicle.tareweightKg = '';
    this.vehicle.isBlackListed = false;
    (this.resetbuttonShow = true), (this.vehicle.vehicleType = 'CEMENT');
    this.blaclistingChange = true;
    this.vehicle.numberOfWheels = '';
    this.vehicle.permitTill = null;
    this.vehicle.vrDate = null;
    this.vehicle.description = null;
    this.modalService.open(content, this.ngbModalOptions);
  }

  editVehicle(content: any, vehicle) {
    this.onReset();
    this.title = 'Update Vehicle';
    this.PreviewsBlackListed = vehicle.isBlackListed;
    this.vehicle.vehicleId = vehicle.vehicleId;
    this.vehicle.capacityInTons = vehicle.capacityInTons;
    this.vehicle.engineCapacityCC = vehicle.engineCapacityCC;
    this.vehicle.vrn = vehicle.vrn;
    this.vehicle.isActive = vehicle.isActive;
    //(this.ActiveShow = false), (this.blaclistingChange = true);
    this.vehicle.rfidTagNumber = vehicle.rfidTagNumber;
    this.vehicle.vehicleType = vehicle.vehicleType;
    this.vehicle.tareweightKg = vehicle.tareweightKg;
    this.vehicle.permitTill = vehicle.permitTill.split('T')[0];
    this.vehicle.numberOfWheels = vehicle.numberOfWheels;
    this.vehicle.vrDate = vehicle.vrDate.split('T')[0];
    // this.vehicle.isVerified = vehicle.isVerified;
    this.vehicle.isBlackListed = vehicle.isBlackListed;
    this.blaclistingChange = this.blaclistingIncidentDate = this.blaclistingDescription = this.Blacklistingreason = false;
    this.vehicle.reason = '';
    this.vehicle.description = '';
    this.vehicle.incidentDateTime = null;
    this.ActiveShow = true;
    // this.vehicle.isNonLogistic = vehicle.isNonLogistic;
    (this.resetbuttonShow = false),
      this.modalService.open(content, this.ngbModalOptions);
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }


  ChangedBlacklist(value: any) {
    if (value === "true") {
      if (this.PreviewsBlackListed === true)
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = false;
      else
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = true;
    }
    else {
      if (this.PreviewsBlackListed === true) {
        this.blaclistingIncidentDate = this.Blacklistingreason = false
        this.blaclistingDescription = true;
      }
      else {
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = false;
      }
    }
  }

  acceptnumber(event: any, flag: boolean) {
    if (flag) {
      this.kemarClientService.keyPressNumbers(event);
    }
    else {
      this.kemarClientService.keyacceptnumberAndDot(event);
    }
  }

  tonValidation(event: any) {
    let tempval = this.kemarClientService.keypressInTonsValidation(event, this.form.get('capacityInTons').value);
    if (tempval === 'false') {
      this.form.get('capacityInTons').setValue('');
    } else {
      this.form.get('capacityInTons').setValue(tempval);
    }
  }

  KeyPressMaxLength(event, flag: boolean) {

    let tes: any;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (flag) {
      tes = this.form.get('capacityInTons').value.toString();
    } else {
      tes = this.form.get('tareweightKg').value.toString();
    }
    if (charCode == 46 && tes.includes(".")) {
      return false;
    }
    if (charCode == 46) {
      return true;
    }
    else if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else if (!this.kemarClientService.commonValidation(tes)) {
      var afterDot = tes.substr(tes.indexOf('.'));
      if (afterDot.length > 3 && tes.includes('.')) {
        return false;
      }
      if (tes.length >= 1 && tes.includes('.')) {
        return true;
      }
      if (tes.length > 2) {
        if (charCode === 46) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
      else {
        return true
      }
    }
    else {
      return true;
    }
  }

  keyPressBlackListing = (val: any) => this.ReasonRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  keyPressIncidentDate = (val: any) => this.DateRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  keyPressDescition = (val: any) => this.DescriptionRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  tareWeightValidation(event: any) {
    let tempval = this.kemarClientService.keypressInTonsValidation(event, this.form.get('tareweightKg').value);

    if (tempval === 'false') {
      this.form.get('tareweightKg').setValue('');
    } else {
      this.form.get('tareweightKg').setValue(tempval);
    }
  }

  OnPageSizeChange(pageSize: any) {

    this.pageSize = pageSize;
    this.GetVehicleItemPerPage(this.currentActivePage);
  }

  EnterBtn(event: any) {
    if (event.keyCode === 13) {
      this.currentActivePage = 1;
      this.GetVehicleItemPerPage(this.currentActivePage);
    }
  }

  DropDownChnange(val: any) {

    if (val === "undefined") {
      this.IsEnableSearchBox = true;
      this.filter = '';
      this.GetVehicleItemPerPage(1);
      return;
    }
    else if (!this.kemarClientService.commonValidation(val)) {
      this.IsEnableSearchBox = false
    }
    else {
      this.IsEnableSearchBox = true
    }
  }

  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);

  public KeyLimit($event: any, limit: number) {
    if ($event.value.length == limit && $event.keyCode > 47 && $event.keyCode < 58) {
      return false;
    }
    // this.value.length==2 && event.keyCode>47 && event.keyCode < 58)return false;
  }

}

const blacklistedDll: { key: string; value: string }[] = [
  { key: 'Yes', value: 'true' },
  { key: 'No', value: 'false' },
];

const vehicleTypeDll: { key: string; value: string }[] = [
  { key: 'LOGISTICS', value: 'logistics' },
  { key: 'NON LOGISTICS', value: 'Non logistics' },
  { key: 'CEMENT', value: 'CEMENTl' },
];
