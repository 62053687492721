<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<div class=" main gate-dashboard ">
    <section class="card-section my-1">
        <div class="container-fluid">
            <h2 class="">Gate Dashboard</h2>
            <div class="row">
                <div class="col-sm-6">
                <div class="card py-1" style="background-color:rgba(1, 107, 190, .06);">
                        <div class="card-body">
                            <div class="row mb-2">
                                <h4 class="title-one"> Today Traffic </h4>
                            </div>
                            <p-chart type="bar" [data]="barchartDailyMapping" [options]="basicOptions" [ngStyle]="{backgroundColor:'#333'}" [legend.itemStyle]="{width: 13, height: 13,
                                border: { color: '#FF0000', width: 1 } }"></p-chart>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card py-1" style="background-color:rgba(190, 155, 86, 0.06);">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h4 class="title-one">Last {{days}} days Traffic </h4>
                                </div>
                                <div class="col-sm-6 text-end">
                                    <div class="custom-select">
                                        <select name="weighbridgeId" (change)="changeDrop($event.target.value)"
                                        class="form-control dropdown-select">
                                        <option value="8" selected> Last 7 Days </option>
                                        <option value="16"> Last 15 Days </option>
                                    </select>
                                    </div>

                                </div>
                            </div>
                            <p-chart type="line" [data]="weekThroughput" [options]="WeekybasicOptions"></p-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="charts">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-4" *ngFor="let obj of piechartDataList">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h4 class="title-one">Today's Traffic</h4>
                            <p-chart type="pie" [data]="obj" [options]="chartOptions" [style]="{'width': '40%'}">
                            </p-chart>
                            <h4 class="mb-0 mt-2 text-center">{{obj.GateName}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="data-table ">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-center align-items-center mb-3">
                                <div class="flex-grow-1">
                                    <h4 class="title-one mb-0">Today's Gate Movements - {{CurrentDate | date:
                                        'dd/MM/yyyy'}} </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <label class="custom-select w-100">
                                        <select class="form-control" (change)="ChangeMethod($event.target.value)">
                                            <option [value]="undefined" selected>All Location</option>
                                            <option *ngFor="let obj of location" [value]="obj.locationCode">
                                                {{obj.locationName}}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>VRN</th>
                                            <th>Milestone</th>
                                            <th>Location</th>
                                            <th>Txn No</th>
                                            <th>Txn Type</th>
                                            <th>Txn Date & Time</th>
                                            <th width="5%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let obj of gateDashboardSPdto  let i = index">
                                            <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                                            <td>{{obj.vrn}}</td>
                                            <td>{{obj.milestone}}</td>
                                            <td>{{obj.locationName}}</td>
                                            <td>{{obj.vehicleTransactionCode}}</td>
                                            <td>{{obj.vehicleType}}</td>
                                            <td>{{obj.transactionDate | date: 'dd-MM-yyyy HH:mm'}}</td>
                                            <td>
                                                <span *ngIf="obj.tranStatus == 'Pending'">
                                                    <img title="" width="18" class=""
                                                        src="../../assets/images/icons/pending.svg">
                                                </span>
                                                <span *ngIf="obj.tranStatus == 'Completed'">
                                                    <img title="" width="18" class=""
                                                        src="../../assets/images/icons/true.svg">
                                                </span>
                                                <span *ngIf="obj.tranStatus == 'Cancelled'">
                                                    <img title="" width="18" class=""
                                                        src="../../assets/images/icons/cancel.svg">
                                                </span>
                                                <span *ngIf="obj.tranStatus == 'Transit'">
                                                    <img title="" width="18" class=""
                                                        src="../../assets/images/icons/true.svg">
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>
