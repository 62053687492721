<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="mt-3">
  <div class="container">
    <div class="text-end">

        <label for="inputTag" class="btn btn-primary mx-1" matTooltip="Upload Vehicle ELV List">
          <fa-icon [icon]="faUpload"></fa-icon>
          <input id="inputTag" type="file" (change)="uploadExcelFile($event)" multiple="false"
        accept=".xlsx, .xls, .csv" style="display: none; "/>
        </label>
        <span class="text-end mx-1" matTooltip="Download Vehicle ELV Format">
          <a href="../../../assets/elxList.xlsx" class="btn btn-success" download target="_blank">
            <fa-icon [icon]="faDownload"></fa-icon>
          </a>
        </span>
        <div class="text-danger fw-bold" style="white-space: pre;">
          {{excelErrorMessage}}
        </div>

    </div>

    <div class="stepper">
      <ul class="form-stepper form-stepper-horizontal text-center mx-auto pl-0">
        <!-- Step 1 -->
        <li class="form-stepper-unfinished text-center form-stepper-list" step="1">
            <a class="mx-2">
                <span class="form-stepper-circle" [ngClass]="{'completed': elvSuccessfullyDone == true, 'openForm': elvSuccessfullyDone == false}">
                    <span class="boldFont">1</span>
                </span>
                <div class="label boldFont" >Truck Details</div>
            </a>
        </li>
        <!-- Step 2 -->
        <li class="form-stepper-unfinished text-center form-stepper-list" step="2">
            <a class="mx-2">
                <span class="form-stepper-circle label" [ngClass]="{'completed': jobAllocationSuccessfullyDone == true, 'openForm': jobAllocationSuccessfullyDone == false && elvSuccessfullyDone == true }">
                    <span class="" [ngClass]="{ 'boldFont': jobAllocationSuccessfullyDone == false && elvSuccessfullyDone == true }">2</span>
                </span>
                <div class="label" [ngClass]="{ 'boldFont': jobAllocationSuccessfullyDone == false && elvSuccessfullyDone == true }">Job Details</div>
            </a>
        </li>
        <!-- Step 3 -->
        <li class="form-stepper-unfinished text-center form-stepper-list" step="3">
            <a class="mx-2">
                <span class="form-stepper-circle label " [ngClass]="{'openForm': jobAllocationSuccessfullyDone == true}">
                    <span class="" [ngClass]="{'boldFont': jobAllocationSuccessfullyDone == true}">3</span>
                </span>
                <div class="label" [ngClass]="{'boldFont': jobAllocationSuccessfullyDone == true}">Milestone Details</div>
            </a>
        </li>
    </ul>
    </div>


    <div class="row justify-content-center">
      <!-- ELV Start Here -->
      <div class="col-lg-4 col-md-8 col-sm-12">
          <form [formGroup]="elvForm" class="">
                <div class="form-group ">
                  <label  class="fw-bold" for="exampleInputEmail1">Type : &nbsp;</label>
                  <input [disabled]="elvSuccessfullyDone" type="radio" class="radio-input" id="outbondId" formControlName="MovementType" value="outbound" (click)="onChangeMovementType(1)">
                  <label class="mx-1" for="outbondId">Outbound</label>&nbsp;
                  <input [disabled]="elvSuccessfullyDone" type="radio" class="radio-input" id="inbondId" formControlName="MovementType" value="inbound" (click)="onChangeMovementType(2)">
                  <label class="mx-1" for="inbondId">Inbound</label>&nbsp;
                </div>

                <div class="form-group mt-2 ">

                    <label class="fw-bold" for="vrn">Truck Number <span class="text-danger">*</span></label><br>
                    <ng-select
                    [items]="vehicleMasterData"
                    bindLabel="vrn" 
                    bindValue="vrn"
                    formControlName="VRN"                    
                    [virtualScroll]="true"
                    [clearable]="true"
                    placeholder="Select VRN"
                    notFoundText="No Vehicle found"
                    [markFirst]="true"
                    appendTo="body"
                    (scrollToEnd)="loadVehicles()"
                    (search)="onSearchVRN($event)"
                    #select
                    >
                    <ng-template let-vehicle pTemplate="item">                      
                        <span class="d-flex">{{vehicle[i].vrn}}</span>                        
                    </ng-template>
                    </ng-select>
                </div>

                <div class="form-group mt-2 ">
                  <label class="fw-bold" for="DriverCode">Driver Name  <span class="text-danger">*</span></label><br>
                  <ng-select
                    [items]="driverMasterData"
                    bindLabel="name" 
                    bindValue="driverCode"
                    formControlName="DriverCode"            
                    [virtualScroll]="true"
                    [clearable]="true"
                    placeholder="Select Driver"
                    notFoundText="No Driver Found"
                    [markFirst]="true"
                    appendTo="body"
                    (scrollToEnd)="loadDriver()"
                    (search)="onSearchDriver($event)"
                    #select>
                    <ng-template let-driver pTemplate="item">                      
                        <span class="d-flex">{{driver[i].name}}</span>                        
                    </ng-template>
                    </ng-select>

                  <!-- <select class="form-control" id="DriverCode" formControlName="DriverCode" [ngClass]="{ 'is-invalid': submitted && f['DriverCode'].errors }">
                    <option hidden Value='' disabled>--Select Driver--</option>
                    <option *ngFor="let driver of driverMasterData" [ngValue]="driver.driverCode">
                      {{driver.name}}</option>
                  </select> -->
                </div>
                <div class="form-group mt-2">
                  <label class="fw-bold" for="exampleInputPassword1">Valid Till Date</label>
                  <input [disabled]="elvSuccessfullyDone" type="datetime-local" class="form-control" id="exampleInputPassword1"
                    placeholder="Enter ELV Validity Date" formControlName="ValidTill"
                    [ngClass]="{ 'is-invalid': submitted && f['ValidTill'].errors }" [min]="todateDate" onkeydown="return false">
                </div>
                <div class="form-group mt-2">
                  <label class="fw-bold" for="exampleInputPassword1">Doc Type <span class="text-danger">*</span></label>
                  <input [disabled]="elvSuccessfullyDone" type="text" class="form-control" id="exampleInputPassword1" placeholder="DO/PO/STO"
                    formControlName="Doctype" [ngClass]="{ 'is-invalid': submitted && f['Doctype'].errors }" autocomplete="off">
                    <span class="text-danger fw-bold" *ngIf="docTypeIsWorng">Doc Type must be DO,PO,STO only</span>
                </div>
                <!-- <div class="form-group">
                  <label for="exampleFormControlSelect1">MovementType</label>
                  <select class="form-control" id="exampleFormControlSelect1" formControlName="MovementType">
                    <option value="outbond" selected>Outbond</option>
                    <option value="inbond">Inbond</option>
                    <option value="inplant">Inplant</option>
                  </select>
                </div> -->
                <div class="text-center">
                  <button type="submit" class="btn btn-primary my-3 float-end" (click)="CreateJsonForELV()" [disabled]="elvSuccessfullyDone || elvForm.invalid || submitted" [ngClass]="{'greyBackground': elvSuccessfullyDone == true}">Next</button>
                </div>

          </form>
          <p class="text-danger fw-bold" style="white-space: pre;">{{detailForELV}}</p>

      </div>
      <!-- ELV End Here -->

      <!-- Job Allocation Start Here -->
      <div class="col-lg-4 col-md-6 col-sm-12 mt-4" [hidden]="!elvSuccessfullyDone">
        <div class=" ">
          <form [formGroup]="jobAllocationForm">
            <div class="row">
              <!-- <div class=""> -->
                <!-- <div class="form-group">
                  <input type="radio" class="radio-input" id="outbondId" formControlName="MovementType" value="outbond">
                  <label class="mx-1" for="outbondId">Outbond</label>&nbsp;&nbsp;
                  <input type="radio" class="radio-input" id="inbondId" formControlName="MovementType" value="inbond">
                <label class="mx-1" for="inbondId">Inbond</label>&nbsp;&nbsp;
                <input type="radio" class="radio-input" id="inplantId" formControlName="MovementType" value="inplant">
                <label class="mx-1" for="inplantId">Inplant</label>
              </div> -->
                <div class="form-group">
                  <label class="fw-bold" for="DocumentNumber">Document Number <span class="text-danger">*</span></label>
                  <input [disabled]="jobAllocationSuccessfullyDone" type="text" class="form-control" id="DocumentNumber" formControlName="DocumentNumber">
                </div>
                <div class="form-group">
                  <label class="fw-bold" for="ShipmentNumber">Shipment Number <span class="text-danger">*</span></label>
                  <input [disabled]="jobAllocationSuccessfullyDone" type="text" class="form-control" id="ShipmentNumber" formControlName="ShipmentNumber">
                </div>
                <!-- <div class="form-group">
                  <label for="JobAllocationCode">JobAllocation Code</label>
                  <input type="text" class="form-control" id="JobAllocationCode" formControlName="JobAllocationCode">
                </div> -->
                <div class="form-group">
                  <label class="fw-bold" for="ProductCode">Product<span class="text-danger">*</span></label>
                  <select [disabled]="jobAllocationSuccessfullyDone" class="form-control" id="ProductCode" formControlName="ProductCode"
                    [ngClass]="{ 'is-invalid': submitted1 && f1['ProductCode'].errors }" (change)="setProductName()">
                    <option selected>--Choose Option--</option>
                    <option *ngFor="let pro of productData" [ngValue]="pro.productCode">
                      {{pro.productName}}
                    </option>
                  </select>
                  <div *ngIf="submitted1 &&  f1['ProductCode'].errors" class="invalid-feedback">
                    <div *ngIf=" f1['ProductCode'].errors">
                        <small>Product is required.</small>
                    </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label class="fw-bold" for="ProductCode">UOM<span class="text-danger">*</span></label>
                      <select [disabled]="jobAllocationSuccessfullyDone" class="form-control" id="ProductCode"[(ngModel)]="UOMValue" [ngModelOptions]="{standalone: true}">
                        <option hidden Value='' disabled>--Choose Option--</option>
                        <option value="bags" >Bags</option>
                        <option value="mt" >MT</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" [hidden]="UOMValue != 'bags'">
                      <label class="fw-bold" for="Quantity">Number of Bags<span class="text-danger">*</span></label>
                      <input [disabled]="jobAllocationSuccessfullyDone" type="number" class="form-control" min="1" max="9999" id="Quantity"
                        placeholder="Entry Number of Bags" formControlName="Quantity"
                        [ngClass]="{ 'is-invalid': submitted1 && f1['Quantity'].errors }" maxlength="4">
                    </div>
                    <div class="form-group" [hidden]="UOMValue != 'mt'">
                      <label class="fw-bold" for="Weight">Weight<span class="text-danger">*</span></label>
                      <input [disabled]="jobAllocationSuccessfullyDone" type="number" class="form-control" min="1" max="9999000" id="Weight" placeholder="Entry Weight"
                        formControlName="Weight" [ngClass]="{ 'is-invalid': submitted1 && f1['Weight'].errors }">
                    </div>
                  </div>
              </div>
              <!-- <div class="form-group my-2">
                <label class="fw-bold" for="uam">UOM<span class="text-danger">*</span></label>
                <select class="form-control" id="uam" formControlName="uam">
                  <option value="" selected >Bag </option>
                  <option>Empty</option>
                </select>
            </div> -->
                <!-- <div class="form-group">
                  <label class="fw-bold" for="ProductCode">UOM<span class="text-danger">*</span></label>
                  <select class="form-control" id="ProductCode"[(ngModel)]="UOMValue" [ngModelOptions]="{standalone: true}">
                    <option hidden Value='' disabled>--Choose Option--</option>
                    <option value="bags" >Bags</option>
                    <option value="empty" >Empty</option>
                  </select>
                </div> -->
                <!-- <div class="form-group" [hidden]="UOMValue != 'bags'">
                  <label class="fw-bold" for="Quantity">Number of Bags<span class="text-danger">*</span></label>
                  <input type="number" class="form-control" min="1" max="9999" id="Quantity"
                    placeholder="Entry Number of Bags" formControlName="Quantity"
                    [ngClass]="{ 'is-invalid': submitted1 && f1['Quantity'].errors }" pattern="^[1-9][0-9]*$" required oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">

                    <div *ngIf="submitted1 && f1['Quantity'].errors" class="invalid-feedback">
                      <div *ngIf="f1['Quantity'].errors">
                          <small>Number of Bags is required.</small>
                      </div>
                  </div>
                </div> -->
                <div class="form-group" [hidden]="UOMValue != 'empty'">
                  <label class="fw-bold" for="Weight">Weight<span class="text-danger">*</span></label>
                  <input [disabled]="jobAllocationSuccessfullyDone" type="number" class="form-control" min="1" max="9999000" id="Weight" placeholder="Entry Weight"
                    formControlName="Weight" [ngClass]="{ 'is-invalid': submitted1 && f1['Weight'].errors }">
                </div>
                <div class="form-group" hidden>
                  <label class="fw-bold" for="MRP">MRP<span class="text-danger">*</span></label>
                  <input [disabled]="jobAllocationSuccessfullyDone" type="number" class="form-control" min="1" max="9999" id="MRP" placeholder="Entry MRP"
                    formControlName="MRP" [ngClass]="{ 'is-invalid': submitted1 && f1['MRP'].errors }">
                </div>
                <div class="text-center">
                  <!-- <a type="submit" class="btn btn-primary my-3 mx-3"
                  (click)="AddNewProduct()" [hidden]="jobAllocationSuccessfullyDone" [ngClass]="{'greyBackground': jobAllocationSuccessfullyDone == true}">Add New Product</a> -->

                  <button type="submit" class="btn btn-primary my-3 mx-3"
                  (click)="createJsonDataForJobAllocation()" [disabled]="jobAllocationSuccessfullyDone" [ngClass]="{'greyBackground': jobAllocationSuccessfullyDone == true}">Next</button>
                </div>
                <!-- <div *ngIf="newProductList.length > 0">
                    <div class="" *ngFor="let product of newProductList let i = index" >
                        <ol>
                          <li>
                            <h3>
                              {{product.ProductName}} - <span *ngIf="product.UOMValue != 'mt'">{{product.Quantity}}</span>
                            <span *ngIf="product.UOMValue != 'bags'">{{product.Weight}}</span> <span class="text-uppercase"> {{product.UOMValue}}</span>
                            </h3>
                          </li>
                        </ol>
                    </div>
                </div> -->
              </div>
            </div>
          </form>
          <p class="text-danger fw-bold" style="white-space: pre;">{{detailForJobAllocation}}</p>
        </div>
      </div>
      <!-- Job Allocation End Here -->

      <!-- Milestones Start Here -->
      <div class="col-lg-4 col-md-12 col-sm-12 mt-4" [hidden]="!jobAllocationSuccessfullyDone">
        <div class="">
            <div class="">
              <div class="">
                <!-- <div class="form-group">
                  <label  for="exampleInputEmail1">ELV Code</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter ELV Code" formControlName="ELVCode"
                    [ngClass]="{ 'is-invalid': submitted && f['ELVCode'].errors }">
                </div> -->
                <div class="form-group">
                  <label class="fw-bold" for="milestoneTemplateDataChooseOption">Milestone Template</label>
                  <!-- <select class="form-control" id="VRN" [(ngModel)]="milestoneTemplateDataChooseOption" (change)="selectMilestoneTemplate()">
                    <option hidden value='0' disabled>--Select Milestone Template--</option>
                    <option value='1'>Outbound Packer 1 template</option>
                    <option value='2'>Outbound Packer 2 template</option>
                  </select> -->

                  <select class="form-control" id="milestoneTemplateDataChooseOption" [(ngModel)]="milestoneTemplateDataChooseOption" (change)="selectMilestoneGroup()">
                    <option hidden Value='' disabled>--Select Milestone Template--</option>
                    <option *ngFor="let temp of milestoneWorkFlow" [ngValue]="temp.workFlowCode">
                      {{temp.workFlowCode}} - {{temp.workFlowName}}</option>
                  </select>
                </div>

              </div>
            </div>
            <ul class="fw-bold m-2">
              <li *ngFor="let milestone of milestoneTemplate2 let i = index">
                <span>{{milestone.sequence}}</span> &nbsp;&nbsp;
                <span> {{milestone.milestoneName}}</span> -
                <span *ngIf="milestone.LocationName != ''"> {{milestone.locationName}}</span>
                <span *ngIf="milestone.LocationName == ''"> No Location Selected</span>
              </li>
            </ul>
            <div class="text-center">
              <button type="submit" class="btn btn-primary my-3" [hidden]="!milestoneReadyToSend" (click)="sendMilestoneWithWorkFlowGroupName()">Submit</button>
            </div>
            <br>
            <p class="text-danger fw-bold" style="white-space: pre;">{{detailForMilestone}}</p>
        </div>
      </div>
      <!-- Milestones End Here -->
    </div>
  </div>
  <!-- <div class="btn btn-dark" (click)="getTextFileTemplate()">Test</div>
  <div class="btn btn-dark" (click)="getFileListFromFolder()">Test</div> -->
</section>


