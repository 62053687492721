<app-header></app-header>
<section  class="mt-5" id="lane-control">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="title-one mb-2">Location Control </h4>
            <form action="/" [formGroup]="form">
              <div class="row">
                <div class="select-lane col-sm-12 my-1">
                  <label for="">Location<sup style="color: red;" id="Star">*</sup></label>
                  <select name="locationId" class="form-control form-select" formControlName="locationId"
                    [ngClass]="{ 'is-invalid': submitted && f.locationId.errors}" [(ngModel)]="LocationList.locationId"
                    (change)="locationSelection($event.target.value)">
                    <option class="dropdown-item" hidden selected value="undefined">Select Location</option>
                    <option *ngFor="let location of LocationMappingMaster" [value]="location.locationId">
                      {{location.locationName}}</option>
                  </select>
                </div>
                <div class="select-reason col-sm-12 my-1">
                  <label for="Reason">Reason<sup style="color: red;" id="Star">*</sup></label>
                  <select class="form-control form-select" name="Reason" formControlName="Reason"
                    [ngClass]="{ 'is-invalid': submitted && f.Reason.errors}" [(ngModel)]="LocationStatusList.Reason">
                    <option class="dropdown-item" hidden selected value="">{{ ReasonDropboxDisplayMessage }}</option>
                    <option *ngFor="let reason of StatusChangeReasonList" [Value]="reason.value">
                      {{reason.value}}</option> 
                  </select>
                  <div *ngIf="submitted && f.Reason.errors" class="invalid-feedback">
                    <div *ngIf="f.Reason.errors.required">
                      <small>Reason is required.</small>
                    </div>
                  </div>
                </div>
                  <div class="specify-reason col-sm-12 my-1">
                    <label for="Remark">Remark</label>
                    <textarea type="text" class="form-control" name="Remark" id="Remark" formControlName="Remark" 
                    [ngClass]="{ 'is-invalid': submitted && f.Remark.errors }" [(ngModel)]="LocationStatusList.Remark"></textarea>
                      <div *ngIf="submitted && f.Remark.errors" class="text-danger"></div>
                  </div>
                </div> 
                <div class="col-sm-12 text-end mt-2">
                  <button class="btn btn-success" *ngIf="IsShowActive && !IsDe_Active"
                    (click)="PostStatus()">Activate</button>
                  <button class="btn btn-danger" *ngIf="!IsShowActive && IsDe_Active"
                    (click)="PostStatus()">Deactivate</button>
                </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-9">
          <div class="card h-100">
            <div class="card-body">
              <div class="table-list custom-table">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered table-hover mx-auto">
                    <thead class="table-head">
                      <tr>
                        <th class="sr-no">Sr. No.</th>
                        <th (click)="sort('name')" class="">Location Name
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        <th (click)="sort('name')" class="">Created By
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="">Created On
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="">Reason
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="">Remark
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="">IsActive
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <input type="hidden" />
                      <tr
                        *ngFor="let list of LocationHistoryList  | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                        <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                        <td>{{list.locationName}}</td>
                        <td>{{list.createdBy }}</td>
                        <td>{{list.createdOn | date: 'dd-MM-yyyy' }}</td>
                        <td>{{list.reason}}</td>
                        <td>{{list.remark}}</td>
                        <td>
                          <span *ngIf="list.isActive.toString().toUpperCase() == 'TRUE'">YES</span>
                          <span *ngIf="list.isActive.toString().toUpperCase() == 'FALSE'">NO</span>
                        </td> 
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pagination justify-content-end">
                <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>


<!-- <section class="mt-5" id="lane-control">
  <div class="container-fluid">
    <div class="d-flex justify-content-center">
      <div class="col-sm-4">
        <div class="card">
          <div class="card-body">
            <h4 class="title-one mb-0"> Location Control </h4>
            <form action="/" [formGroup]="form">
              <div class="row">
                <h4 class="text-center pt-2"> </h4>
                <div class="select-lane col-sm-6">
                  <label for="">Location<sup style="color: red;" id="Star">*</sup></label>
                  <select name="locationId" class="form-control form-select" formControlName="locationId"
                    [ngClass]="{ 'is-invalid': submitted && f.locationId.errors}" [(ngModel)]="LocationList.locationId"
                    (change)="locationSelection($event.target.value)">
                    <option class="dropdown-item" hidden selected value="undefined">Select Location</option>
                    <option *ngFor="let location of LocationMappingMaster" [value]="location.locationId">
                      {{location.locationName}}</option>
                  </select>
                </div>
                <div class="select-reason col-sm-6">
                  <label for="Reason">Reason<sup style="color: red;" id="Star">*</sup></label>
                  <select class="form-control form-select" name="Reason" formControlName="Reason"
                    [ngClass]="{ 'is-invalid': submitted && f.Reason.errors}" [(ngModel)]="LocationStatusList.Reason">
                    <option class="dropdown-item" hidden selected value="">{{ ReasonDropboxDisplayMessage }}</option>
                    <option *ngFor="let reason of StatusChangeReasonList" [Value]="reason.value">
                      {{reason.value}}</option> 
                  </select>
                  <div *ngIf="submitted && f.Reason.errors" class="invalid-feedback">
                    <div *ngIf="f.Reason.errors.required">
                      <small>Reason is required.</small>
                    </div>
                  </div>
                </div>
                  <div class="specify-reason col-sm-12" style="margin-top: 15px; margin-bottom: 15px;">
                    <label for="Remark">Remark</label>
                    <textarea type="text" class="form-control" name="Remark" id="Remark" formControlName="Remark" 
                    [ngClass]="{ 'is-invalid': submitted && f.Remark.errors }" [(ngModel)]="LocationStatusList.Remark"></textarea>
                      <div *ngIf="submitted && f.Remark.errors" class="text-danger"></div>
                  </div>
                </div> 
                <div class="col-sm-12 text-end">
                  <button class="btn btn-success me-2" *ngIf="IsShowActive && !IsDe_Active"
                    (click)="PostStatus()">Activate</button>
                  <button class="btn btn-danger" *ngIf="!IsShowActive && IsDe_Active"
                    (click)="PostStatus()">Deactivate</button>
                </div>
            </form>
          </div>
        </div>
          </div>
        </div>
      </div>
      <div class="table-list custom-table">
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover mx-auto">
            <thead class="table-head">
              <tr>
                <th class="sr-no">Sr. No.</th>
                <th (click)="sort('name')" class="">Location Name
                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                <th (click)="sort('name')" class="">Created By
                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th>
                <th (click)="sort('name')" class="">Created On
                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th>
                <th (click)="sort('name')" class="">Reason
                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th>
                <th (click)="sort('name')" class="">Remark
                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th>
                <th (click)="sort('name')" class="">IsActive
                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <input type="hidden" />
              <tr
                *ngFor="let list of LocationHistoryList  | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                <td>{{list.locationName}}</td>
                <td>{{list.createdBy }}</td>
                <td>{{list.createdOn | date: 'dd-MM-yyyy' }}</td>
                <td>{{list.reason}}</td>
                <td>{{list.remark}}</td>
                <td>
                  <span *ngIf="list.isActive.toString().toUpperCase() == 'TRUE'">YES</span>
                  <span *ngIf="list.isActive.toString().toUpperCase() == 'FALSE'">NO</span>
                </td> 
                </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="pagination justify-content-end">
        <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
      </div>
</section> -->
<app-footer></app-footer>