<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0">
              <!-- <fa-icon [icon]="faTruck" class="faicon"></fa-icon>  -->
              Cement Vehicle Masters
            </h4>
          </div>
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-md-4">
    <!-- <h4 class="title-one mb-0"> Vehicle Master </h4> -->
  </div>
  <div class="col-md-8 text-end" >
    <label class="me-2" *ngIf="userAccessData[0]?.canRead == true">
      <select [(ngModel)]="selectedColumn" (change)="DropDownChnange($event.target.value)" class="form-control dropdown-select">
        <option selected value="undefined"> -- All -- </option>
        <option value="1">VRN</option>
        <option value="2">RFID Tag</option>
      </select>
    </label>
    <div class="input-append me-0" *ngIf="userAccessData[0]?.canRead == true">
      <!-- <span class="material-icons-sharp icon-in">
        search
      </span> -->
      <input class="form-control ps-2" type="text" name="search" placeholder="Search"
        [(ngModel)]="filter" (keydown)="EnterBtn($event)" [disabled]="IsEnableSearchBox">
    </div>
    <button class="btn btn-primary go-btn mx-2" (click)="SearchFilter()" matTooltip="Search" *ngIf="userAccessData[0]?.canRead == true"
      matTooltipClass="danger-tooltip" [disabled]="IsEnableSearchBox">
      <fa-icon [icon]="faSearch"></fa-icon>
    </button>
    <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Vehicle"
      matTooltipClass="danger-tooltip" *ngIf="userAccessData[0]?.canCreate == true">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
  </div>
</div>
<div class="table-list custom-table">
  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover mx-auto tbl-formating" *ngIf="userAccessData[0]?.canRead == true">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>
          <th (click)="sort('vrn')" class="col location">Reg. No.
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('rfidTagNumber')" class="col location">RFID Tag
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('vrDate')" class="col location">Reg. Date
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('permitTill')" class="col">Permit Validity
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('numberOfWheels')" class="col">Wheels
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('engineCapacityCC')" class="col">Engine CC
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('capacityInTons')" class="col">Load Capacity (Ton)
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('tareweightKg')" class="col">Tare Weight (Kgs)
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('isBlackListed')" class="col">Blacklisted
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th (click)="sort('isActive')" class="col">Status
            <!-- <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> -->
          </th>
          <th *ngIf="userAccessData[0]?.canUpdate == true" class="col action-key text-center">Action </th>
        </tr>
      </thead>
      <tbody>
        <input type="hidden" />
        <tr
          *ngFor="let vehicle of vehicleMaster | orderBy: key : !reverse  | paginate: { id: 'vehiclePagination', itemsPerPage: pageSize, currentPage: currentActivePage, totalItems: totalRecords  }; let i = index">
          <td class="left">{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
          <td class="left">{{vehicle.vrn}}</td>
          <td class="left">{{vehicle.rfidTagNumber}}</td>
          <td class="left">{{vehicle.vrDate | date: 'dd-MM-yyyy'}}</td>
          <td class="left">{{vehicle.permitTill |date: 'dd-MM-yyyy'}}</td>
          <td class="left">{{vehicle.numberOfWheels}}</td>
          <td class="left">{{vehicle.engineCapacityCC}}</td>
          <td class="left">{{vehicle.capacityInTons}}</td>
          <td class="left">{{vehicle.tareweightKg}}</td>
          <td class="left">
            <span *ngIf="vehicle.isBlackListed  ==true ">YES</span>
            <span *ngIf="vehicle.isBlackListed  ==false ">NO</span>
          </td>
          <td class="text-center">
            <span *ngIf="vehicle.isActive  ==true ">Active</span>
            <span *ngIf="vehicle.isActive  ==false ">Inactive</span>
          </td>
          <td class="text-center" *ngIf="userAccessData[0]?.canUpdate == true">
            <span class="edit-btn-icon" type="button" matTooltip="Edit Vehicle" matTooltipClass="danger-tooltip"
              (click)="editVehicle(edit, vehicle) ">
              <fa-icon [icon]="faEdit"></fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex">
    <div class="col-md-6" *ngIf="userAccessData[0]?.canRead == true">
      Item per page
      <select (change)="OnPageSizeChange(pageSize = $event.target.value)" class="paginationcount">
        <option value="15">15</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </select>
      <span> Total Records : {{totalRecords}} </span>
    </div>
    <div class="col-md-6 text-end" *ngIf="userAccessData[0]?.canRead == true">
      <div class="pagination justify-content-end">
        <pagination-controls id="vehiclePagination" (pageChange)="GetVehicleItemPerPage(currentActivePage = $event)">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>
<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="closeModal()">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <form autocomplete="off" [formGroup]="form">
            <div class="form-group">
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="vrn">Registration No. <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    (keypress)="keyPressAlphaNumeric($event)" minlength="6" maxlength="11"
                    style="text-transform:uppercase" [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }"
                    formControlName="vrn" [(ngModel)]="vehicle.vrn" (focusout)="getDatabyVRN()" />
                  <div *ngIf="submitted && f.vrn.errors" class="text-danger">
                    <div *ngIf="f.vrn.errors.required">
                      <small>Registration No is required.</small>
                    </div>
                    <div *ngIf="f.vrn.errors.minlength">
                      <small>
                        <sup>*</sup>Registration No is invalid.
                      </small>
                    </div>
                    <div *ngIf="f.vrn.hasError('whitespace')"></div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="rFIDTagNumber">RFID Tag </label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" " minlength="20" maxlength="28"
                    style="text-transform:uppercase" [ngClass]="{ 'is-invalid': submitted && f.rfidTagNumber.errors }"
                    formControlName="rfidTagNumber" [(ngModel)]="vehicle.rfidTagNumber" (keyup)="rfidValidationOnChange()" />
                  <div *ngIf="submitted && f.rfidTagNumber.errors" class="text-danger">
                    <div *ngIf="f.rfidTagNumber.errors.required">
                      <small>RFID Tag number is required.</small>
                    </div>
                    <div *ngIf="f.rfidTagNumber.errors.minlength">
                      <small>
                        <sup>*</sup> RFID Tag number is too short.
                      </small>
                    </div>
                    <div *ngIf="f.rfidTagNumber.errors?.pattern">
                      <small> <sup>*</sup>RFID Tag field should contain 'A-F,0-9' only.</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label>Vehicle Registration Date <sup>*</sup></label>
                  <input type="date" formControlName="vrDate" (keypress)="keyonDate($event)"
                    class="form-control datetime-local" [max]="currentdate" min="2000-04-30"
                    [ngClass]="{ 'is-invalid': submitted && f.vrDate.errors }" [(ngModel)]="vehicle.vrDate" />
                  <div *ngIf="submitted && f.vrDate.errors" class="invalid-feedback">
                    <div *ngIf="f.vrDate.errors.required">Date is required</div>
                    <!-- <div *ngIf="f.vrDate.errors.pattern">Date must be a valid date in the format
                           </div> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <label>Permit Validity <sup>*</sup></label>
                  <input type="date" formControlName="permitTill" (keypress)="keyonDate($event)" #keyress
                    class="form-control datetime-local" [min]="currentdate" max="2060-04-30"
                    [ngClass]="{ 'is-invalid': submitted && f.permitTill.errors }" [(ngModel)]="vehicle.permitTill" />
                  <div *ngIf="submitted && f.permitTill.errors" class="invalid-feedback">
                    <div *ngIf="f.permitTill.errors.required">Permit Validity is required</div>
                    <!-- <div *ngIf="f.permitTill.errors.pattern">Permit Validity must be a valid date in the format
                             </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="numberOfWheels">Number of Wheels <sup>*</sup></label>

                  <input type="text" (keypress)="acceptnumber($event, true)" minlength="1" maxlength="2"
                    class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.numberOfWheels.errors }" formControlName="numberOfWheels"
                    [(ngModel)]="vehicle.numberOfWheels" />
                  <div *ngIf="submitted && f.numberOfWheels.errors" class="text-danger">
                    <div *ngIf="f.numberOfWheels.errors.required">
                      <small>No of Wheels is required.</small>
                    </div>
                    <div *ngIf="f.numberOfWheels.errors.min">
                      <small>
                        <sup>*</sup>Invalid input.
                      </small>
                    </div>
                    <!-- <div *ngIf="f.numberOfWheels.errors.maxlength">
                      <sup>*</sup>Number Of Wheels must not exceed 4 digits.
                    </div> -->
                    <!-- <div *ngIf="f.numberOfWheels.errors?.pattern">
                          <small> <sup>*</sup>Number Of Wheels should contain only Numbers</small>
                        </div> -->
                    <!-- <div *ngIf="f.numberOfWheels.hasError('whitespace')"></div> -->
                  </div>
                  <!-- <input type="text" class="form-control" placeholder="" value=" "
                          [ngClass]="{ 'is-invalid': submitted && f.numberOfWheels.errors }"
                          formControlName="numberOfWheels" [(ngModel)]="vehicle.numberOfWheels" /> -->
                  <!-- <div *ngIf="submitted && f.numberOfWheels.errors" class="text-danger">
                          <div *ngIf="f.numberOfWheels.errors.required">
                            <small><sup>*</sup>Number Of Wheels is required.</small>
                          </div>
                          <div *ngIf="f.numberOfWheels.errors.minlength">
                            <small>
                              <sup>*</sup> Number Of Wheelsmust be at least 4 characters.
                            </small>
                          </div>
                          <div *ngIf="f.numberOfWheels.errors.maxlength">
                            <sup>*</sup>Number Of Wheels must not exceed 30 characters.
                          </div>
                          <div *ngIf="f.numberOfWheels.hasError('whitespace')">Blank space Not Allowed,enter valid data</div>
                      </div> -->
                </div>
                <div class="col-sm-6">
                  <label for="tareweightKg">Tare Weight (In Tons) <sup>*</sup></label>
                  <input type="text" (keypress)="KeyPressMaxLength($event, false)"
                    (focusout)="tareWeightValidation($event)" minlength="3" maxlength="7" class="form-control"
                    class="form-control" placeholder="" value=" " name="tareweightKg"
                    [ngClass]="{ 'is-invalid': submitted && f.tareweightKg.errors }" formControlName="tareweightKg"
                    [(ngModel)]="vehicle.tareweightKg" />
                  <div *ngIf="submitted && f.tareweightKg.errors" class="text-danger">
                    <div *ngIf="f.tareweightKg.errors.required">
                      <small>Tare Weight is required.</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="engineCapacityCC">Capacity (in CC) <sup>*</sup></label>
                  <input type="text" (keypress)="acceptnumber($event, true)" minlength="3" maxlength="7"
                    class="form-control" class="form-control" placeholder="" value=" " name="engineCapacityCC"
                    [ngClass]="{ 'is-invalid': submitted && f.engineCapacityCC.errors }"
                    formControlName="engineCapacityCC" [(ngModel)]="vehicle.engineCapacityCC" />
                  <div *ngIf="submitted && f.engineCapacityCC.errors" class="text-danger">
                    <div *ngIf="f.engineCapacityCC.errors.required">
                      <small>Capacity(In CC) is required.</small>
                    </div>
                    <div *ngIf="f.engineCapacityCC.errors.minlength">
                      <small>
                        Capacity(In CC) should be betwween 3 to 7 digits.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="capacityInTons">Load Carrying Capacity (In Tons) <sup>*</sup></label>

                  <input type="text" (focusout)="tonValidation($event)" (keypress)="KeyPressMaxLength($event, true)"
                    minlength="3" maxlength="7" class="form-control" class="form-control" placeholder="" value=" "
                    name="capacityInTons" [ngClass]="{ 'is-invalid': submitted && f.capacityInTons.errors }"
                    formControlName="capacityInTons" [(ngModel)]="vehicle.capacityInTons" />
                  <div *ngIf="submitted && f.capacityInTons.errors" class="text-danger">
                    <div *ngIf="f.capacityInTons.errors.required">
                      <small>Capacity(In Tons) is required.</small>
                    </div>
                    <!-- <div *ngIf="f.capacityInTons.errors.minlength">
                      <small>
                        <sup>*</sup> capacityInTons must be at least 3 digit.
                      </small>
                    </div> -->
                    <div *ngIf="f.capacityInTons.errors.maxlength">
                      <sup>*</sup>capacity(InTon) must not exceed 7 digits.
                    </div>
                    <div *ngIf="f.capacityInTons.errors?.pattern">
                      <small> Capacity should contain only Numbers</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6 d-none">
                  <label for="vehicleType">Vehicle Type </label>
                  <select name="vehicleType" [(ngModel)]="vehicle.vehicleType" class="form-control form-select"
                    formControlName="vehicleType" [ngClass]="{ 'is-invalid': submitted && f.vehicleType.errors }">
                    <option hidden Value='' disabled>--Choose Option--</option>
                    <option *ngFor="let vehicleType of vehicleTypeDll" [ngValue]="vehicleType.value">
                      {{vehicleType.key}}</option>
                  </select>
                </div>

                <div class="col-sm-6" *ngIf="ActiveShow">
                  <div class="select-radio" *ngIf="ActiveShow">
                    <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="vehicle.isActive"
                      formControlName="isActive">
                    <label for="active" class="radio-label">Active</label>

                    <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="vehicle.isActive"
                      formControlName="isActive">
                    <label for="inactive" class="radio-label">Inactive</label>
                  </div>
                  <label for="isBlackListed">BlackListed</label>
                  <select name="isBlackListed" (change)="ChangedBlacklist($event.target.value)"
                    [(ngModel)]="vehicle.isBlackListed" class="form-control form-select" formControlName="isBlackListed"
                    [ngClass]="{ 'is-invalid': submitted && f.isBlackListed.errors }">
                    <option hidden [ngValue]='' disabled>--Choose Option--</option>
                    <option [value]="true">Yes</option>
                    <option [value]="false">No</option>
                  </select>
                </div>
              </div>

              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6" *ngIf="Blacklistingreason">
                  <label for="locationName">Reason<sup>*</sup></label>
                  <select name="ReasonRequired"  (change)="keyPressBlackListing($event.target.value)" [(ngModel)]="vehicle.reason" class="form-control form-select"
                    formControlName="reason" [ngClass]="{ 'is-invalid': submitted && ReasonRequired }">
                    <option hidden Value='' disabled>--Choose Option--</option>
                    <option *ngFor="let vehicle of BlacklistingReason" [ngValue]="vehicle.reason">
                      {{vehicle.reason}}</option>
                  </select>
                  <div *ngIf="submitted && ReasonRequired" class="text-danger">
                    <small> Reason for blacklisting is required.</small>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="blaclistingIncidentDate">
                  <label for="incidentDateTime">Incident Date <sup>*</sup></label>
                  <input type="date" class="form-control" placeholder="" (keypress)="KeyPressDate($event)"
                    (change)="keyPressIncidentDate($event.target.value)" value=" " name="incidentDateTime"
                    [ngClass]="{ 'is-invalid': submitted && DateRequired }" formControlName="incidentDateTime"
                    [(ngModel)]="vehicle.incidentDateTime" />
                  <div *ngIf="submitted && DateRequired" class="invalid-feedback">
                    <div *ngIf="DateRequired">Date is required.
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" *ngIf="blaclistingDescription">
                  <label for="DescriptionRequired">Description<sup>*</sup></label>
                  <textarea style="font-size: 0.75rem;" type="text"
                    (keyup)="keyPressDescition($event.target.value.trim())" class="form-control" placeholder=""
                    value=" " name="DescriptionRequired" [ngClass]="{ 'is-invalid': submitted && DescriptionRequired }"
                    formControlName="description" [(ngModel)]="vehicle.description"></textarea>
                  <div *ngIf="submitted && DescriptionRequired" class="text-danger">
                    <div *ngIf="DescriptionRequired">
                      <small>Description is required.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="closeModal()">Close</button>
      <button type="button" class="modal-button btn btn-danger" *ngIf="resetbuttonShow" aria-label="Close"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="registerVehicle(vehicle)">Save</button>
    </div>
  </div>
</ng-template>
</div>
</div>
</div>
</div>
</section>
<p-confirmDialog #cd [style]="{ width: '50vw' }" [baseZIndex]="10000" key="alert">
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>
<app-footer></app-footer>
