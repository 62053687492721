<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<div>
    <section class="my-2"*ngIf="userAccessData[0]?.canRead ==true">
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex data-filter align-items-center my-2">
                        <div class="col-sm-4">
                            <h4 class="title-one mb-0"> Inplant Trips </h4>
                        </div>
                        <div class="col-sm-8 text-end mx-100">
                            <div class="input-append me-0">
                                <span class="material-icons-sharp icon-in">search</span>
                                <input class="form-control" type="search" name="searchtext" placeholder="search VRN"
                                    [(ngModel)]="pagination.searchtext" (keyup.enter)="GetTransactionByVrn($event)">
                            </div>
                            <a routerLink="/inplantapiSimulator" matTooltip="Add" *ngIf="userAccessData[0]?.canCreate == true"
                                  class=" btn btn-primary go-btn mx-2"><fa-icon [icon]="faPlus"></fa-icon></a>
                        </div>
                    </div>
                    <div class="table-list custom-table" >
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover mx-auto">
                                <thead class="table-head">
                                    <tr>
                                        <th (click)="sort('vrn')" class="col-1">VRN
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('vrn')" class="col-1">Trip No
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('vehicleTransactionCode')" class="col-2">Number of trips
                                            <span class="glyphicon glyphicon-chevron-up"
                                                *ngIf="key =='vehicleTransactionCode'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('exitDateTime')" class="col-4">Number of trips completed
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='exitDateTime'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('driverName')" class="col-4">Trip start time
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='driverName'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('entryDateTime')" class="col-4">Trip end time
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='entryDateTime'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('tranType')" class="col-4"> Job Completed
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='tranType'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th class="col-1 text-center"> History</th>
                                        <!-- <th class="col action-key">History
                                    </th>  -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let inplant of transactionResponse | orderBy: key : reverse  | paginate: { itemsPerPage: pagination.pageSize, currentPage: pagination.page,totalItems:pagination.count }; let i = index">

                                        <td class="col-1"><a
                                                [routerLink]="['/current-transaction', inplant.vehicleTrancode]">{{inplant.vrn}}</a>
                                            <!-- {{inplant.vrn}} -->
                                        </td>
                                        <td class="col-2">{{inplant.vehicleTrancode}}</td>
                                        <td class="col-2">{{inplant.noOfTrips}}</td>
                                        <td class="col-1">{{inplant.noOfTripsCompleted }}</td>
                                        <td class="col-2">{{inplant.transactionStarted |date: 'dd-MM-yyyy HH:mm'}}</td>
                                        <td class="col-2">{{inplant.transactionCompleted |date: 'dd-MM-yyyy HH:mm a'}}
                                        </td>
                                        <td class="col-1">
                                            <span *ngIf="inplant.isJobCompleted  ==true ">Yes</span>
                                            <span *ngIf="inplant.isJobCompleted  ==false ">No</span>
                                        </td>
                                        <td class="col-1 text-center" style="cursor: pointer;">
                                            <fa-icon [icon]="faHistory" class="faicon"
                                                (click)="GetInPlantTransactionHistory(history, inplant.inPlantTransactionId)">
                                            </fa-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            Item per page
                            <select (change)="OnPageSizeChange($event)" class="paginationcount">
                                <option *ngFor="let size of pagination.pageSizes">
                                    {{size}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4 text-end" *ngIf="hidefoundrecord==true">
                            <span> Found {{pagination.count}} </span>
                        </div>
                        <div class="col-md-4 text-end">
                            <span> Total Records:{{pagination.totalTransactionCount}} </span>
                        </div>
                        <div class="col-md-4 text-end">
                            <div class="pagination justify-content-end">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next" maxSize="9"
                                    screenReaderCurrentLabel="You're on page" (pageChange)="OnPageChange($event)">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
    <ng-template #history let-modal class="modal">
        <div class="modal-content custom-modal"
            style="width: 200% !important;transform: translate3d(-208px, 0px, 10px) !important;">
            <div class="modal-header">
                <h4 class="modal-title mb-0">Inplant Trip History</h4>
                <!-- <i class="fa fa-history" style="font-size:24px"></i> -->
                <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span class="material-icons-sharp">close</span>
                </button>
            </div>

            <div
                *ngIf="inPlantTransactionhistory == null || inPlantTransactionhistory == undefined || inPlantTransactionhistory.length <=  0">
                <div class="modal-header">
                    <h3 class="modal-title pt-3">There are no records to display</h3>
                </div>
            </div>

            <div
                *ngIf="inPlantTransactionhistory != null && inPlantTransactionhistory != undefined && inPlantTransactionhistory.length > 0">
                <div class="modal-body">
                    <div class="table-list custom-table">
                        <div class="table-responsive ">
                            <table class="table table-striped MX-auto table-bordered table-hover">
                                <thead class="table-head">
                                    <tr>
                                        <!-- <th (click)="sort('name')" class="col-2 location">Location</th>
                                        <th (click)="sort('name')" class="col-4 location">Start Time</th>
                                        <th (click)="sort('name')" class="col-4 location">End Time</th>
                                        <th (click)="sort('name')" class="col-2 location">Milestone</th>
                                        <th (click)="sort('name')" class="col-2 location">Status </th>
                                        <th (click)="sort('name')" class="col-1 location">No. of Trip</th> -->

                                        <th (click)="sort('name')" class="col location">Sr .No
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('name')" class="col location"> Location
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('name')" class="col location">Start Time
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('name')" class="col location">End Time
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('name')" class="col location">Milestone
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('name')" class="col location"> Value
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('name')" class="col location">Status
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('name')" class="col location">No. of Round
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let history of inPlantTransactionhistory  | filter: filter let i = index">
                                        <td> {{ i + 1}} </td>
                                        <td class="col-2" style="text-align:left"> {{history.vrnDetectionLocCode}}</td>
                                        <td class="col-2" style="text-align:left"> {{history.inDateTime
                                            |date:'dd-MM-yyyy
                                            H:mm'}}</td>
                                        <td class="col-2" style="text-align:left"> {{history.outDateTime
                                            |date:'dd-MM-yyyy
                                            H:mm'}}</td>
                                        <td class="col-2" style="text-align:left"> {{history.milestonename}}</td>
                                        <td class="col-1" style="text-align:left"> {{history.value}}</td>
                                        <td class="col-1" style="text-align:left"> {{history.status}}</td>
                                        <td class="col-3" style="text-align:center"> {{history.tripNo}}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
