import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import {
  faSearch,
  faBan,
  faCheck,
  faEye,
  faSort,
  faClosedCaptioning,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  filter,
} from 'rxjs/operators';
import { ServiceUrl } from 'src/app/common/service-urls';
import { Bank, BANKS } from 'src/app/dashboard/demo-data';
import { MilestoneMasterResponse } from 'src/app/models/milestonemastermodel';
import { VehicleMasterResponse } from 'src/app/models/vehiclemastermodel';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { Router, RouterModule, Routes } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { __values } from 'tslib';
import { MileStoneTrackingRequest } from 'src/app/models/vehicleMilestonetrackingModel';
import { Pagination } from 'src/app/models/pagination';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/service/excel.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

const material = [MatExpansionModule];
@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent implements OnInit {
  milestoneActionTrackingId: any;
  isChecked: boolean;
  faEye = faEye;
  faBan = faBan;
  faSort = faSort;
  faSearch = faSearch;
  active = 'top';
  public localStorage = localStorage;

  isShown: boolean = false;
  title: any;
  key: string = 'vrn'; //set default
  reverse: boolean = false;
  public toggleButton: boolean = true;
  vrn: any;
  search: boolean = false;
  //protected banks: Bank[] = BANKS;
  public list: VehicleMasterResponse[];
  searchedList: any;
  VehicleMasterResponse: VehicleMasterResponse[] = [];
  vehicleMaster: any;
  vehicle: any;
  txn: any;
  vehicle_tran: any;
  jobMilestones: any;
  public userAccessData: UserScreenAccesData;
  $allVehicles: Observable<VehicleMasterResponse[]>;
  $filteredVehicles: Observable<VehicleMasterResponse[]>;
  public milestoneTrack: MileStoneTrackingRequest =
    new MileStoneTrackingRequest();
  public pagination: Pagination = new Pagination(1, 0, 15, 0, [15, 20, 25, 30]);
  public searchText: string = '';
  hidefoundrecord: boolean = false;
  dataForExcel = [];

  public vehicleCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public vehicleFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredVehicles: ReplaySubject<VehicleMasterResponse[]> =
    new ReplaySubject<VehicleMasterResponse[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @Input() placeholderLabel = 'Search';
  @Input() noEntriesFoundLabel = 'No Entries Found';
  @Input() clearSearchInput = false;

  /** Disables initial focusing of the input field */
  @Input() disableInitialFocus = false;
  vehicleTransactionCode: any;
  selectedColumn: any;
  cancelledResponse: any;
  filterCancelled= '';
  currentPageCancelled:1;

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  currentCompletedPage = 1;
  currentCancelledPage = 1;
  panelOpenState = false;
  faCheck = faCheck;
  faClosedCaptioning = faClosedCaptioning;
  step = 0;
  milestoneMaster: any;
  vehicleTransaction: any;
  milestoneActionsTracking: any;
  transaction: any;
  transactionResponse: any;
  complatedTransactionResponse: any;
  keyvalue: any;

  //sorting
  public filter: string = '';
  pageSize = 15;
  currentPage = 1;
  totalRecords = 10;
  CompletedTotalRecord = 0;
  CancelledTotalRecord = 0;
  today: Date;
  FromDate: any;
  ToDate: any;
  currentPageCompleted = 1;
  pageSizeCompleted = 15;
  pageSizeCancelled = 15;
  filterCompleted = '';
  currentActivePageCompleted: number = 1;

  TodayComplete: any;
  FromDateComplete: any;

  tranTypeActive=0;
  tranTypeCompleted=0;
  tranTypeCancelled=0;

  getAllVehicles() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllVehicle).subscribe(
      (response) => {
        this.vehicleMaster = response;
        this.searchedList = response;
        this.spinner.hide();
      },
      (r) => {
        this.spinner.hide();
        // console.log(r.error.error);
      }
    );
  }

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private api:DataService
  ) {
    this.today = new Date();
    this.route.params.subscribe((data) => {
      this.vehicleTransactionCode = data;
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    let vrn = this.route.snapshot.params.vrn;
    this.FromDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.ToDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.TodayComplete = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    this.FromDateComplete = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    if (vrn == '0') {
      this.pagination.searchtext = '';
    } else {
      this.pagination.searchtext = vrn;
    }
    this.GetAllVehilceTransaction(this.currentPage);
    this.getComplatedData(this.currentPage);
    this.getCancelledData(this.currentPage);
    this.userAccessData =this.api.getUserScreenAccessMenu('VTRAN')
  }

  OnPageChange(pageSize: any) {
    this.pageSize = pageSize;
    this.GetAllVehilceTransaction(this.currentPage);
  }
  OnPageChangeCompleted(pageSize: any) {
    this.pageSizeCompleted = pageSize;
    this.GetAllVehilceTransactionCompleted(this.currentPage);
  }
  OnPageChangeCancelled(pageSize: any) {
    this.pageSizeCancelled = pageSize;
    this.GetAllVehilceTransactionCancelled(this.currentPage);
  }
   GetAllVehilceTransaction(currentPage: any) {
    if (!this.kemarClientService.commonValidation(this.transactionResponse) &&this.transactionResponse.length < this.pageSize)
    {
      this.currentActivePageCompleted = 1;
      this.getData(1);
    } else this.getData(currentPage);
  }
  getData(currentPage: any) {
    debugger;
    this.spinner.show();
    var query = {
      currentPage: currentPage,
      rowSize: this.pageSize,
      searchtext: this.filter,
      searchColumn: this.selectedColumn,
      fromDate: '',
      toDate: '',
      index: 0,
      isExcelDownload: "false",
      tranType: this.tranTypeActive
    };
    this.kemarClientService.get<any>(null, ServiceUrl.getAllVehilceTransaction, query)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.transactionResponse = response;
          this.transactionResponse.length > 0 ? this.totalRecords = response[0].totalRecord : this.totalRecords = 0;
          this.currentPage = currentPage;
          console.log(response);
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }




  GetAllVehilceTransactionCompleted(currentPage: any)
  {
    if (!this.kemarClientService.commonValidation(this.complatedTransactionResponse) && this.complatedTransactionResponse.length < this.pageSizeCompleted ) {
      this.getComplatedData(1)
    }
    else
      this.getComplatedData(currentPage)
  }

  GetAllVehilceTransactionCancelled(currentPage: any)
  {
    if (!this.kemarClientService.commonValidation(this.cancelledResponse) && this.cancelledResponse.length < this.pageSizeCancelled ) {
      this.getCancelledData(1)
    }
    else
      this.getCancelledData(currentPage)
  }


  getComplatedData(currentPage: any) {
    debugger;
    this.spinner.show();
    var query = {
      currentPage: currentPage,
      rowSize: this.pageSizeCompleted,
      searchtext: this.filterCompleted,
      searchColumn: this.selectedColumn,
      fromDate: this.FromDate ,
      toDate: this.ToDate,
      index: 1,
      isExcelDownload: "false",
      tranType: this.tranTypeCompleted
    };
    this.kemarClientService
      .get<any>(
        null,
        ServiceUrl.getAllVehilceTransaction,
        query
      )
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.complatedTransactionResponse = response;
          this.complatedTransactionResponse.length > 0 ? this.CompletedTotalRecord = response[0].totalRecord : this.CompletedTotalRecord = 0;
          this.currentPageCompleted = currentPage;
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }
  getCancelledData(currentPage: any) {
    debugger;
    this.spinner.show();
    var query = {
      currentPage: currentPage,
      rowSize: this.pageSizeCancelled,
      searchtext: this.filterCancelled,
      searchColumn: this.selectedColumn,
      fromDate: this.FromDateComplete ,
      toDate: this.TodayComplete,
      index: 2,
      isExcelDownload: "false",
      tranType: this.tranTypeCancelled
    };
    this.kemarClientService.get<any>(null,ServiceUrl.getAllVehilceTransaction,query)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.cancelledResponse = response;
          this.cancelledResponse.length > 0 ? this.CancelledTotalRecord = response[0].totalRecord : this.CancelledTotalRecord = 0;
          this.currentPageCancelled = currentPage;
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }


  GetTransactionByVrn(searchtext) {
    if (
      typeof searchtext != 'undefined' &&
      searchtext != null &&
      searchtext != ''
    ) {
      this.hidefoundrecord = true;
      this.pagination.page = 1;
      this.GetAllVehilceTransaction(this.currentPage);
    } else {
      this.hidefoundrecord = false;
    }
  }

  searchvehicle(keyvalue) {
    this.search = true;
    this.searchedList = this.vehicleMaster.filter((val) =>
      val['keyvalue'].includes(keyvalue)
    );
  }
  searchtxn(txn) {
    this.search = true;
    this.searchedList = this.vehicleMaster.filter((val) =>
      val['txn'].includes(txn)
    );
  }

  EnterBtn(event: any) {
    if (event.keyCode == 13) {
      this.currentPage = this.currentActivePage = 1;
      this.GetAllVehilceTransaction(this.currentPage);
    }
  }

  EnterCompletedBtn(event: any) {
    if (event.keyCode == 13) {
      this.currentPage = this.currentCompletedPage = 1;
      this.GetAllVehilceTransactionCompleted(this.currentPage);
    }
  }
  EnterCancelledBtn(event: any) {
    if (event.keyCode == 13) {
      this.currentPage = this.currentCancelledPage = 1;
      this.GetAllVehilceTransactionCancelled(this.currentPage);
    }
  }

  SearchFilter() {
    this.currentPage = this.currentActivePage = 1
    this.GetAllVehilceTransaction(this.currentPage);
  }
  SearchCompletedFilter() {
    this.currentPage = this.currentActivePage = 1
    this.GetAllVehilceTransactionCompleted(this.currentPage);
  }
  SearchCancelledFilter() {
    this.currentPage = this.currentActivePage = 1
    this.GetAllVehilceTransactionCancelled(this.currentPage);
  }

  viewVehicleTransaction(vehicle) {
    this.router.navigateByUrl('/current-transaction/{{vehicle}}');
  }

  getCurrentActiveData() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllActiveVehilceTransactiontoDownload)
      .subscribe(
        (response) => {
          this.exportToExcelNonLogisticVehicle(response, 'Current');
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  exportToExcelNonLogisticVehicle(currentTransDetails: any, TransactionType: any) {

    this.dataForExcel = [];

    currentTransDetails.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row));
    });

    let gateData = {
      title: TransactionType + ' Transaction',
      data: this.dataForExcel,
      headers: Object.keys(currentTransDetails[0]),
    };
    this.excelService.exportExcelForCurrentTransaction(gateData);
  }

  getActiveDataForExcel() {
    debugger;
    this.spinner.show();
    var query = {
      currentPage: 1,
      rowSize: this.pageSize,
      searchtext: this.filter,
      searchColumn: this.selectedColumn,
      fromDate: '',
      toDate: '',
      index: 0,
      isExcelDownload: "true",
      tranType: this.tranTypeActive
    };
    this.kemarClientService.get<any>(null, ServiceUrl.getAllVehilceTransaction, query)
      .subscribe(
        (response) => {
          this.exportToExcelNonLogisticVehicle(response, 'Current Active');
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }

  getComplatedDataForExcel() {
    debugger;
    this.spinner.show();
    var query = {
      currentPage: 1,
      rowSize: this.pageSizeCompleted,
      searchtext: this.filterCompleted,
      searchColumn: this.selectedColumn,
      fromDate: this.FromDate ,
      toDate: this.ToDate,
      index: 1,
      isExcelDownload: "true",
      tranType: this.tranTypeCompleted
    };
    this.kemarClientService
      .get<any>(
        null,
        ServiceUrl.getAllVehilceTransaction,
        query
      )
      .subscribe(
        (response) => {
          this.exportToExcelNonLogisticVehicle(response, 'Completed');
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }
  getCancelledDataForExcel() {
    debugger;
    this.spinner.show();
    var query = {
      currentPage: 1,
      rowSize: this.pageSizeCancelled,
      searchtext: this.filterCancelled,
      searchColumn: this.selectedColumn,
      fromDate: this.FromDateComplete ,
      toDate: this.TodayComplete,
      index: 2,
      isExcelDownload: "true",
      tranType: this.tranTypeCancelled
    };
    this.kemarClientService.get<any>(null,ServiceUrl.getAllVehilceTransaction,query)
      .subscribe(
        (response) => {
          this.exportToExcelNonLogisticVehicle(response, 'Cancelled');
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }
}
