export class DriverMasterResponse {
    driverId:number;
    name:string;
    driverCode:string;
    address:string;
    telNo:string;
    mobileNo:string;
    email:string;
    aadharNo:number;
    dlNo:string;
    dlValidaty:Date;
    isBlackListed:boolean;
    isActive:boolean;
    reason: string;
    description : string;
    dateAndTime: Date;
    incidentDateTime: Date;
    editFlag : boolean;
    personType: string;

}
