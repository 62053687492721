<header class="header">
  <div class="container-fluid">
    <div class="top-header">
      <div class="row align-items-center h-100">
        <div class="col-6" *ngIf="headerWithName == 'false'">
          <a href="#">
            <img alt="" height="54" id="pia-logo" *ngIf="plant == 'MYCOMPANY'">
            <img alt="" height="54" id="pia-logoStar" *ngIf="plant == 'STAR'">
            <img alt="" height="54" id="pia-logoJSW" *ngIf="plant == 'JSW'">
          </a>
        </div>
        <div class="col-6 text-end" *ngIf="headerWithName == 'false'">
          <a [routerLink]="['/home']">
            <img alt="" id="kemar-logo">
          </a>
        </div>

        <!-- With Plant Name  -->
        <div class="col-4" *ngIf="headerWithName == 'true'">
          <a  [routerLink]="['/dashboard']">
            <img alt="" height="54" id="pia-logo" *ngIf="plant == 'MYCOMPANY'">
            <img alt="" height="54" id="pia-logoStar" *ngIf="plant == 'STAR'">
            <img alt="" height="54" id="pia-logoJSW" *ngIf="plant == 'JSW'">
          </a>
        </div>
        <div class="col-4 mt-2 text-center" *ngIf="headerWithName == 'true'">
          <h2 class="fw-italic"><i class="plantNameHead">{{plantName}}</i></h2>
        </div>
        <div class="col-4 text-end" *ngIf="headerWithName == 'true'">
          <a [routerLink]="['/home']">
            <img alt="" id="kemar-logo">
          </a>
        </div>
    </div>
    </div>
  </div>
  <!-- <router-outlet></router-outlet> -->
</header>
