<app-header></app-header>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0">
              <!--<fa-icon [icon]="faAnchor" class="faicon"></fa-icon>-->
              Expected List of Vehicles
            </h4>
          </div>
          <mat-tab-group animationDuration="10ms" ngbNav #nav="ngbNav" [(activeId)]="active" (selectedIndexChange)="loadTabComponent($event)" mat-align-tabs="end">
            <mat-tab label="Active" ngbNavItem="top" >
              <div class="d-flex data-filter align-items-center my-2 m_d-flex">
                <div class="col-sm-4 col-10">
                  <div class="input-append">
                    <span class="material-icons-sharp icon-in">search</span>
                    <input class="form-control " type="search" name="search" placeholder="search" (ngModelChange)="currentActivePage = 1"
                  [(ngModel)]="filter">
                  </div>
                </div>
                <div class="col-sm-4"></div>
                <div class="col-sm-4 text-end"  >
                  <div class="row">
                    <div class="col-10 text-end">
                      <select name="tranType" class="form-control activeOption" (change)="loadTabComponent(0)" name="tranType" id="tranType" autocomplete="" [(ngModel)]="tranTypeActive">
                        <option value='0'>-- All Tran Type --</option>
                        <option value ='1' >Outbound</option>
                        <option value ='2' >Inbound</option>
                        <option value ='4' >InPlant</option>
                      </select>
                    </div>
                    <div class="col-1" *ngIf="userAccessData[0].canCreate ==true ">
                      <a routerLink="/apiSimulator" matTooltip="Add New ELV"
                  class=" btn btn-primary go-btn"><fa-icon [icon]="faPlus"></fa-icon></a>
                    </div>
                  </div>

                <!-- <div *ngIf="userAccessData[0].canCreate ==true ">
                  <a routerLink="/apiSimulator" matTooltip="Add New ELV"
                  class=" btn btn-primary go-btn"><fa-icon [icon]="faPlus"></fa-icon></a>
                </div> -->

                </div>
              </div>
              <div class="table-list custom-table" >
                <div class="table-responsive">
                  <table class="table table-striped MX-auto table-bordered table-hover">
                    <thead class="table-head">
                      <tr>
                        <th class="col-2 sr-no">Sr. No.</th>
                        <th (click)="sort('elvCode')" class="col location">ELV Code
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('driverName')" class="col location">Driver Name
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('vrn')" class="col location">VRN
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('tranType')" class="col location">Trip Type
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('shipmentNo')" class="col location">Shipment No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <!-- <th (click)="sort('name')" class="col location">Doc No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <th (click)="sort('entryDateTime')" class="col location">Parking InTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('exitDateTime')" class="col location">Parking OutTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('validTill')" class="col location">Valid till
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('status')" class="col location">Trip Status

                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <!-- <th (click)="sort('name')" class="col">Status
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <th (click)="sort('name')" class="col" *ngIf="plantInfo == 'STAR'">Delete
                        </th>
                        <th (click)="sort('name')" class="col" *ngIf="plantInfo == 'STAR'">Skip
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let activeElv of activeElvTransactionActive | orderBy: key : reverse | filter:filter | paginate: { id: 'ActivePagination' ,itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                        <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                        <td>{{activeElv.elvCode}}</td>

                        <td>{{activeElv.driverName}}</td>
                        <td>{{activeElv.vrn}}</td>
                        <td>
                          <span *ngIf="activeElv.tranType  ==1 ">Outbound</span>
                          <span *ngIf="activeElv.tranType  ==2 ">Inbound</span>
                          <span *ngIf="activeElv.tranType  ==4 ">InPlant</span>
                        </td>
                        <td>{{activeElv.shipmentNo}}</td>
                        <!-- <td>{{activeElv.docNumber}}</td> -->
                        <td>{{activeElv.entryDateTime |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <td>{{activeElv.exitDateTime |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <td>{{activeElv.validTill |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <td>{{activeElv.status}}</td>
                        <!-- <td>
                          <span *ngIf="activeElv.isActive  ==true ">Active</span>
                          <span *ngIf="activeElv.isActive  ==false ">Inactive</span>
                        </td> -->
                        <!-- <td class="text-center">
                          <span *ngIf="activeElv.status == 'Pending'" >
                            <fa-icon [icon]="faDelete" class="sorticon"></fa-icon>
                          </span>
                        </td> -->
                        <td *ngIf="plantInfo == 'STAR'">
                          <button class="btn go-btn" (click)="openConfirmationModel(Confirmation, activeElv.elvId)" matTooltip="Delete Transaction"
                            matTooltipClass="danger-tooltip">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </td>
                        <td *ngIf="plantInfo == 'STAR'">
                          <button class="btn go-btn" (click)="openConfirmationToCompleteModel(ConfirmationToComplete, activeElv.elvId)" matTooltip="Skip Transaction"
                            matTooltipClass="danger-tooltip">
                            <fa-icon [icon]="faForward"></fa-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pagination justify-content-end">
                <pagination-controls id="ActivePagination" (pageChange)="pagechange($event)">
                </pagination-controls>
              </div>
            </mat-tab>
            <mat-tab label="Completed" ngbNavItem="middle" (click)=" currentActivePage1 =1 " >
              <div class="d-flex data-filter align-items-center my-2 m_d-flex">
                <div class="col-sm-4 col-10">
                  <div class="input-append">
                    <span class="material-icons-sharp icon-in">search</span>
                    <input class="form-control " type="search" name="search" placeholder="search" [(ngModel)]="filter1"
                    (ngModelChange)="currentActivePage1 = 1">
                  </div>
                </div>
                <div class="col-md-8 text-end" id="input-filter">
                  <label for="fromDate" class="mr-2">Tran Type:</label>
                  <select name="tranType"
                  class="form-control form-select" name="tranType" id="tranType" autocomplete="" [(ngModel)]="tranTypeComlpeted">
                      <option value='0'>-- All Tran Type --</option>
                      <option value ='1' >Outbound</option>
                      <option value ='2' >Inbound</option>
                      <option value ='4' >InPlant</option>
                </select>
                  <label for="fromDate" class="mr-2">From:</label>
                  <input class="form-control date mr-2" name="fromDate" (keypress)="KeyPressDate($event)"
                     type="date" [(ngModel)]="fromDate" id="fromDate" max="{{ today | date:'yyyy-MM-dd' }}">
                  <label for="toDate" class="mr-2">To:</label>
                  <input class="form-control date mr-2" name="toDate" (keypress)="KeyPressDate($event)"
                    [(ngModel)]="toDate" type="date" id="toDate" max="{{ today | date:'yyyy-MM-dd' }}">
                  <button class="btn btn-primary go-btn" (click)="loadTabComponent(1)" matTooltip="Search"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faSearch"></fa-icon>
                  </button>
                </div>
              </div>
              <div class="table-list custom-table">
                <div class="table-responsive">
                  <table class="table table-striped MX-auto table-bordered table-hover">
                    <thead class="table-head">
                      <tr>
                        <th class="col-2 sr-no">Sr. No.</th>
                        <th (click)="sort('name')" class="col location">ELV Code
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="col location">Driver Name
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('name')" class="col location">VRN
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <!-- <th (click)="sort('name')" class="col location">Doc No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <th (click)="sort('shipmentNo')" class="col location">Shipment No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="col location">Parkig InTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="col location">Parkig OutTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="col location">Valid till
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('name')" class="col location">Trip Status

                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <!-- <th (click)="sort('name')" class="col">Status
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let activeElv of activeElvTransactionCompleted | orderBy: key : reverse | filter:filter1 | paginate: {id: 'completedPagination' , itemsPerPage: pageSize, currentPage: currentActivePage1 }; let i = index">
                        <td>{{ (currentActivePage1 - 1) * pageSize + i +1 }}</td>
                        <td>{{activeElv.elvCode}}</td>
                        <td>{{activeElv.driverName}}</td>
                        <td>{{activeElv.vrn}}</td>
                        <td>{{activeElv.shipmentNo}}</td>
                        <!-- <td>{{activeElv.docNumber}}</td> -->
                        <td>{{activeElv.entryDateTime |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <td>{{activeElv.exitDateTime |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <td>{{activeElv.validTill |date: 'dd-MM-yyyy HH:mm:' }}</td>
                        <td>{{activeElv.status}}</td>
                        <!-- <td>
                          <span *ngIf="activeElv.isActive  ==true ">Active</span>
                          <span *ngIf="activeElv.isActive  ==false ">Inactive</span>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pagination justify-content-end">
                <pagination-controls id="completedPagination" (pageChange)="pagechange1($event)">
                </pagination-controls>
              </div>
            </mat-tab>
            <mat-tab label="Expired" ngbNavItem="bottom" (click)=" currentActivePage2 =1 ">
              <div class="d-flex data-filter align-items-center my-2 m_d-flex">
                <div class="col-sm-4 col-10">
                  <div class="input-append">
                    <span class="material-icons-sharp icon-in">search</span>
                    <input class="form-control " type="search" name="search" placeholder="search" [(ngModel)]="filter2"
                    (ngModelChange)="currentActivePage2 = 1">
                  </div>
                </div>
                <div class="col-md-8 text-end" id="input-filter">
                  <label for="fromDate" class="mr-2">Tran Type:</label>
                  <select name="tranType"
                  class="form-control form-select" name="tranType" id="tranType"  autocomplete="" [(ngModel)]="tranTypeExpired">
                      <option value='0'>-- All Tran Type --</option>
                      <option value ='1' >Outbound</option>
                      <option value ='2' >Inbound</option>
                      <option value ='4' >InPlant</option>
                  </select>
                  <label for="fromDate" class="mr-2">From:</label>
                  <input class="form-control date mr-2" name="fromDate" (keypress)="KeyPressDate($event)"
                     type="date" [(ngModel)]="fromExpireDate" id="fromDate" max="{{ today | date:'yyyy-MM-dd' }}">
                  <label for="toDate" class="mr-2">To:</label>
                  <input class="form-control date mr-2" name="toDate" (keypress)="KeyPressDate($event)"
                    [(ngModel)]="toExpireDate" type="date" id="toDate" max="{{ today | date:'yyyy-MM-dd' }}">
                  <button class="btn btn-primary go-btn" (click)="loadTabComponent(2)" matTooltip="Search"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faSearch"></fa-icon>
                  </button>
                </div>

              </div>
              <div class="table-list custom-table">
                <div class="table-responsive">
                  <table class="table table-striped MX-auto table-bordered table-hover">
                    <thead class="table-head">
                      <tr>
                        <th class="col-2 sr-no">Sr. No.</th>
                        <th (click)="sort('name')" class="col location">ELV Code
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="col location">Driver Name
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('name')" class="col location">VRN
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('shipmentNo')" class="col location">Shipment No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <!-- <th (click)="sort('name')" class="col location">Doc No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <!-- <th (click)="sort('name')" class="col location">Parkig InTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <!-- <th (click)="sort('name')" class="col location">Parkig OutTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <th (click)="sort('name')" class="col location">Valid till
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <!-- <th (click)="sort('name')" class="col location">Trip Status

                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->

                        <!-- <th (click)="sort('name')" class="col">Status
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let activeElv of activeElvTransactionExpired | orderBy: key : reverse | filter:filter2 | paginate: { id: 'expiredPagination',itemsPerPage: pageSize, currentPage: currentActivePage2 }; let i = index">
                        <td>{{ (currentActivePage2 - 1) * pageSize + i +1 }}</td>
                        <td>{{activeElv.elvCode}}</td>

                        <td>{{activeElv.driverName}}</td>
                        <td>{{activeElv.vrn}}</td>
                        <td>{{activeElv.shipmentNo}}</td>
                        <!-- <td>{{activeElv.docNumber}}</td> -->
                        <!-- <td>{{activeElv.entryDateTime |date: 'dd-MM-yyyy HH:mm' }}</td> -->
                        <!-- <td>{{activeElv.exitDateTime |date: 'dd-MM-yyyy HH:mm' }}</td> -->
                        <td>{{activeElv.validTill |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <!-- <td>{{activeElv.status}}</td> -->
                        <!-- <td>
                          <span *ngIf="activeElv.isActive  ==true ">Active</span>
                          <span *ngIf="activeElv.isActive  ==false ">Inactive</span>
                        </td> -->

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pagination justify-content-end">
                <pagination-controls id="expiredPagination" (pageChange)="pagechange2($event)">
                </pagination-controls>
              </div>
            </mat-tab>
            <mat-tab label="Cancelled" ngbNavItem="bottom" (click)=" currentActivePage2 =1 ">
              <div class="d-flex data-filter align-items-center my-2 m_d-flex">
                <div class="col-sm-4 col-10">
                  <div class="input-append">
                    <span class="material-icons-sharp icon-in">search</span>
                    <input class="form-control " type="search" name="search" placeholder="search" [(ngModel)]="filter3"
                    (ngModelChange)="currentActivePage2 = 1">
                  </div>
                </div>
                <div class="col-md-8 text-end" id="input-filter">
                  <label for="fromDate" class="mr-2">Tran Type:</label>
                  <select name="tranType"
                  class="form-control form-select"  name="tranType" id="tranType" autocomplete="" [(ngModel)]="tranTypeCancelled">
                      <option value='0'>-- All Tran Type --</option>
                      <option value ='1' >Outbound</option>
                      <option value ='2' >Inbound</option>
                      <option value ='4' >InPlant</option>
                </select>
                  <label for="fromDate" class="mr-2">From:</label>
                  <input class="form-control date mr-2" name="fromDate" (keypress)="KeyPressDate($event)"
                     type="date" [(ngModel)]="fromCancelledDate" id="fromDate" max="{{ today | date:'yyyy-MM-dd' }}">
                  <label for="toDate" class="mr-2">To:</label>
                  <input class="form-control date mr-2" name="toDate" (keypress)="KeyPressDate($event)"
                    [(ngModel)]="toCancelledDate" type="date" id="toDate" min="fromdate">
                  <button class="btn btn-primary go-btn" (click)="loadTabComponent(3)" matTooltip="Search"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faSearch"></fa-icon>
                  </button>
                </div>

              </div>
              <div class="table-list custom-table">
                <div class="table-responsive">
                  <table class="table table-striped MX-auto table-bordered table-hover">
                    <thead class="table-head">
                      <tr>
                        <th class="col-2 sr-no">Sr. No.</th>
                        <th (click)="sort('name')" class="col location">ELV Code
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="col location">Driver Name
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('name')" class="col location">VRN
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('shipmentNo')" class="col location">Shipment No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <!-- <th (click)="sort('name')" class="col location">Doc No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <th (click)="sort('name')" class="col location">Parkig InTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="col location">Parkig OutTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('name')" class="col location">Valid till
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <!-- <th (click)="sort('name')" class="col location">Trip Status

                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->

                        <!-- <th (click)="sort('name')" class="col">Status
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->

                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let activeElv of activeElvTransactionCancelled | orderBy: key : reverse | filter:filter3 | paginate: { id: 'expiredPagination',itemsPerPage: pageSize, currentPage: currentActivePage2 }; let i = index">
                        <td>{{ (currentActivePage2 - 1) * pageSize + i +1 }}</td>
                        <td>{{activeElv.elvCode}}</td>

                        <td>{{activeElv.driverName}}</td>
                        <td>{{activeElv.vrn}}</td>
                        <td>{{activeElv.shipmentNo}}</td>
                        <!-- <td>{{activeElv.docNumber}}</td> -->
                        <td>{{activeElv.entryDateTime |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <td>{{activeElv.exitDateTime |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <td>{{activeElv.validTill |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <!-- <td>{{activeElv.status}}</td> -->
                        <!-- <td>
                          <span *ngIf="activeElv.isActive  ==true ">Active</span>
                          <span *ngIf="activeElv.isActive  ==false ">Inactive</span>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pagination justify-content-end">
                <pagination-controls id="expiredPagination" (pageChange)="pagechange2($event)">
                </pagination-controls>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div [ngbNavOutlet]="nav" class="ml-4"></div>
      </div>
    </div>
  </div>

</section>
<app-footer></app-footer>

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">Add or Update ELV</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="form-group">
          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-6">
              <label for="name">Name</label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name"
                [(ngModel)]="transporter.name" />
              <div *ngIf="submitted && f.name.errors" class="text-danger">
                <div *ngIf="f.name.errors.required">
                  <small><sup>*</sup>Name is required.</small>
                </div>
                <div *ngIf="f.name.errors.minlength">
                  <small>
                    <sup>*</sup> Name must be at least 4 characters.
                  </small>
                </div>
                <div *ngIf="f.name.errors.maxlength">
                  <small> <sup>*</sup>Name must not exceed 30 characters.</small>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="vendorCode">Vendor Code</label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.vendorCode.errors }" formControlName="vendorCode"
                [(ngModel)]="transporter.vendorCode" />
              <div *ngIf="submitted && f.vendorCode.errors" class="text-danger">
                <div *ngIf="f.vendorCode.errors.required">
                  <small><sup>*</sup>Vendor Code is required.</small>
                </div>
                <div *ngIf="f.vendorCode.errors.minlength">
                  <small>
                    <sup>*</sup> Vendor Code must be at least 4 characters.
                  </small>
                </div>
                <div *ngIf="f.vendorCode.errors.maxlength">
                  <small> <sup>*</sup>Vendor Code must not exceed 30 characters.</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-6">
              <label for="plantCode">Plant Code</label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.plantCode.errors }" formControlName="plantCode"
                [(ngModel)]="transporter.plantCode" />
              <div *ngIf="submitted && f.plantCode.errors" class="text-danger">
                <div *ngIf="f.plantCode.errors.required">
                  <small><sup>*</sup>Plant Code is required.</small>
                </div>
                <div *ngIf="f.plantCode.errors.minlength">
                  <small>
                    <sup>*</sup> Plant Code must be at least 4 characters.
                  </small>
                </div>
                <div *ngIf="f.plantCode.errors.maxlength">
                  <small> <sup>*</sup>Plant Code must not exceed 30 characters.</small>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="address">Address</label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address"
                [(ngModel)]="transporter.address" />
              <div *ngIf="submitted && f.address.errors" class="text-danger">
                <div *ngIf="f.address.errors.required">
                  <small><sup>*</sup>Address is required.</small>
                </div>
                <div *ngIf="f.address.errors.minlength">
                  <small>
                    <sup>*</sup> Address must be at least 4 characters.
                  </small>
                </div>
                <div *ngIf="f.address.errors.maxlength">
                  <small> <sup>*</sup>Address must not exceed 30 characters.</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-6">
              <label for="mobileNo">Mobile No. <sup>*</sup></label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }" formControlName="mobileNo"
                [(ngModel)]="transporter.mobileNo" />
              <div *ngIf="submitted && f.mobileNo.errors" class="text-danger">
                <div *ngIf="f.mobileNo.errors.required">
                  <small><sup>*</sup>Mobile No. is required.</small>
                </div>
                <div *ngIf="f.mobileNo.errors.minlength">
                  <small>
                    <sup>*</sup> Mobile No. must be at least 10 No.
                  </small>
                </div>
                <div *ngIf="f.mobileNo.errors.maxlength">
                  <small> <sup>*</sup>Mobile No. must not exceed 11 No.</small>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="telNo">Telephone No. <sup>*</sup></label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.telNo.errors }" formControlName="telNo"
                [(ngModel)]="transporter.telNo" />
              <div *ngIf="submitted && f.telNo.errors" class="text-danger">
                <div *ngIf="f.telNo.errors.required">
                  <small><sup>*</sup>Telephone No. is required.</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-6">
              <label for="email">Email <sup>*</sup></label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
                [(ngModel)]="transporter.email" />
              <div *ngIf="submitted && f.email.errors" class="text-danger">
                <div *ngIf="f.email.errors.required">
                  <small><sup>*</sup>Email is required.</small>
                </div>
                <div *ngIf="f1.email.errors?.email">
                  <small class="text-danger">Email address is invalid</small>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="contactPersonName">Contact Person Name <sup>*</sup></label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.contactPersonName.errors }"
                formControlName="contactPersonName" [(ngModel)]="transporter.contactPersonName" />
              <div *ngIf="submitted && f.contactPersonName.errors" class="text-danger">
                <div *ngIf="f.contactPersonName.errors.required">
                  <small><sup>*</sup>Contact Person Name is required.</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-6">
              <label for="contactPersonNumber">Contact Person No. <sup>*</sup></label>
              <br>
              <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.contactPersonNumber.errors }"
                formControlName="contactPersonNumber" [(ngModel)]="transporter.contactPersonNumber" />
              <div *ngIf="submitted && f.contactPersonNumber.errors" class="text-danger">
                <div *ngIf="f.contactPersonNumber.errors.required">
                  <small><sup>*</sup>Contact Person No. is required.</small>
                </div>
                <div *ngIf="f.contactPersonNumber.errors.minlength">
                  <small>
                    <sup>*</sup> Contact Person No. must be at least 10 characters.
                  </small>
                </div>
                <div *ngIf="f.contactPersonNumber.errors.maxlength">
                  <small> <sup>*</sup>Contact Person No. must not exceed 11 characters.</small>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="isBlackListed">BlackListed</label>
              <select name="isBlackListed" [(ngModel)]="transporter.isBlackListed" class="form-control form-select"
                formControlName="isBlackListed" [ngClass]="{ 'is-invalid': submitted && f.isBlackListed.errors }">
                <option hidden Value='' disabled>--blacklisted status--</option>
                <option *ngFor="let blacklisted of blacklistedDll" [ngValue]="blacklisted.value">
                  {{blacklisted.key}}</option>
              </select>
            </div>
          </div>
          <div class="select-radio" [hidden]="ShowIsActive">
            <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="transporter.isActive"
              formControlName="isActive">
            <label for="active" class="radio-label">Active</label>

            <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="transporter.isActive"
              formControlName="isActive">
            <label for="inactive" class="radio-label">Inactive</label>
          </div>

        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="submit" class="modal-button btn btn-success"
        (click)="registerTransporter(transporter)">Save</button>
    </div>
  </div>
</ng-template>

<ng-template #Confirmation let-c="close" let-d="dismiss">
  <div class="modal-header " style=" padding: 16px 18px;">
    <h4 class="modal-title" style="margin-bottom: 0px;font-size: x-large; font-weight: 500;" id="modal-basic-title">
      Confirmation</h4>
    <button type="button" class="close  " style="font-size: 31px;
      margin: -8px -5px;   background-color: transparent;
  border: none;" aria-label="Close" (click)="closeButton();">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p style="font-size: larger;margin-bottom: 0px;" class="py-1">This will delete the ELV reccord permanently, Are you sure ?</p>
  </div>
  <div class="modal-footer py-1">
    <button type="button" class="btn btn-secondary py-1" #confirmationCancel (click)="closeButton();"
      style="background-color: #f1f1f1;border: none;color: black; font-size: small;">Cancel</button>
    <button type="button" class="btn btn-primary py-1" style="font-size: small;" (click)="
    deleteTransaction()">Yes</button>
  </div>
</ng-template>

<ng-template #ConfirmationToComplete let-c="close" let-d="dismiss">
  <div class="modal-header " style=" padding: 16px 18px;">
    <h4 class="modal-title" style="margin-bottom: 0px;font-size: x-large; font-weight: 500;" id="modal-basic-title">
      Confirmation</h4>
    <button type="button" class="close  " style="font-size: 31px;
      margin: -8px -5px;   background-color: transparent;
  border: none;" aria-label="Close" (click)="closeButton();">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p style="font-size: larger;margin-bottom: 0px;" class="py-1"> Are you sure ? You want to skip all Milestone of this vehicle till Gate Out ?</p>
  </div>
  <div class="modal-footer py-1">
    <button type="button" class="btn btn-secondary py-1" #confirmationCancel (click)="closeButton();"
      style="background-color: #f1f1f1;border: none;color: black; font-size: small;">Cancel</button>
    <button type="button" class="btn btn-primary py-1" style="font-size: small;" (click)="
    completeTransaction()">Yes</button>
  </div>
</ng-template>
