export class VehicleBlacklistingResponse {
      
    vehicleBlacklistingId: number;
    registrationNumber:string;
    vrn:string;
    reason:string;
    dateAndTime: Date;
    isActive: boolean;
    description:string;
    incidentDate:Date;
    type: string;
    vehicleType:string;
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;

} 
