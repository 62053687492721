export class LocationMappingResponse {

    locationId:number;
    locationName:string;
    locationCode:string;
    parentLocationCode:number;
    locationType:string;
    createdBy:string;
    createdDate:string;
    modifiedBy:string;
    modifiedDate:string;
    isActive:boolean;
sequence:number;
    detectableBy:string;
    maxQueueSize:number;
    minQueueSize: number;
    displayName:string;
    direction: string;

}




