import {NavItem} from '../template/menu-item/NavItemModel';

export class UserLogin {
    userName: string;
    password: string;
}

export class UserRegistration {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    acceptTerms: boolean = true;
    roleId: number;
    name: string;
    Created: string;
    mobileNumber: string;
    isActive : boolean;
}

export class ChangePassword {
    userName: string;
    password: string;
    oldPassword: string;
    confirmPassword: string;
}

export class LoginResponse {
    firstName: string;
    lastName: string;
    Email: string;
    role: string;
    jwtToken: string;
    refreshToken: string;
    mobNo: string;
    userName:string;
    menuAccess: Array<NavItem>;
    userAccess: Array<NavItem>;
}

export class ForgotPasswordRequest {
    userName: string;
    email: string;
}

export class ForgotPassword {
    userName: string;
    otp: string;
    newPassword: string;
    confirmPassword: string;

}
export class ResetPasswordRequest {
    token: string;
    userName :string;
    password: string;
    confirmPassword: string;
}

export class TokenModel {
    token: string;
}
export class UserScreenAccesData {
    screenCode: string;
    canRead: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    canDeactivate: boolean;
  }