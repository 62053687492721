import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { clearLine } from 'readline';
import { WSA_E_CANCELLED } from 'constants';
//import * as logoFile from './carlogo.js';
//import { DatePipe } from '../../node_modules/@angular/common';
@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  //  vehicleParkingResponse: any;
  //  data: any = [];
  //  y : any = [];

  // x  = {"vrn ": "MH04KL12656","employee": "Trisha","EntryDate": "2022-03-12T04:30:00","parkInLocation": "Parking Level 1"};
  // arr1= []
  constructor() {}
  exportExcelForTat(tatData) {
    //Title, Header & Data
    const title = tatData.title;
    const header = [
      'Trip No',
      'VRN',
      'Trip Type',
      'Driver Name',
      'Entry DateTime',
      'Exit DateTime',
      'Duration',
    ];
    const data = tatData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('TAT Report');

    // Add Row and formatting
    worksheet.mergeCells('A1', 'F3');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('G1', 'G3');
    let d = new Date();
    let date = d;
    let dateCell = worksheet.getCell('G1');

    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting

    data.forEach((d) => {
      //let row = worksheet.addRow(d);
      var dd = new Date(d[4]);
      var datestring =
        ('0' + dd.getDate()).slice(-2) +
        '-' +
        ('0' + (dd.getMonth() + 1)).slice(-2) +
        '-' +
        dd.getFullYear() +
        ' ' +
        ('0' + dd.getHours()).slice(-2) +
        ':' +
        ('0' + dd.getMinutes()).slice(-2);
        if(datestring == '01-01-1970 05:30'){
          datestring = '';
        }
      var dd1 = new Date(d[5]);
      var datestring1 =
        ('0' + dd1.getDate()).slice(-2) +
        '-' +
        ('0' + (dd1.getMonth() + 1)).slice(-2) +
        '-' +
        dd1.getFullYear() +
        ' ' +
        ('0' + dd1.getHours()).slice(-2) +
        ':' +
        ('0' + dd1.getMinutes()).slice(-2);
        if(datestring1 == '01-01-1970 05:30'){
          datestring1 = '';
        }
      let val = null;
      if (d[2] === 1) val = 'OutBound';
      else if (d[2] === 2) val = 'InBound';
      else if (d[2] === 4) val = 'InPlant';

      // let formattedRowData = [d[0], datestring,d[4], d[3], d[2],d[1]];
      let formattedRowData = [
        d[0],
        d[1],
        val,
        d[3],
        datestring,
        datestring1,
        d[6],
      ];
      let row = worksheet.addRow(formattedRowData);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

  exportExcelForGate(gateData) {
    //Title, Header & Data
    const title = gateData.title;
    const header = [
      'Trip No',
      'Gate No',
      'VRN',
      'Trip Type',
      'Direction',
      'Entry/Exit Time',
    ];
    const data = gateData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Gate Report');

    // Add Row and formatting
    worksheet.mergeCells('A1', 'E3');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('F1', 'F3');
    let d = new Date();
    let date = d;
    let dateCell = worksheet.getCell('F1');

    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      //let row = worksheet.addRow(d);
      var dd = new Date(d[4]);
      var datestring =
        ('0' + dd.getDate()).slice(-2) +
        '-' +
        ('0' + (dd.getMonth() + 1)).slice(-2) +
        '-' +
        dd.getFullYear() +
        ' ' +
        ('0' + dd.getHours()).slice(-2) +
        ':' +
        ('0' + dd.getMinutes()).slice(-2);
        if(datestring == '01-01-1970 05:30'){
          datestring = '';
        }
      // var dd1 = new Date(d[6]);
      // var datestring1 = ("0" + dd.getDate()).slice(-2) + "-" + ("0" + (dd.getMonth() + 1)).slice(-2) + "-" +
      //   dd.getFullYear() + " " + ("0" + dd.getHours()).slice(-2) + ":" + ("0" + dd.getMinutes()).slice(-2);
      let val = null;
      if (d[3] === 1) val = 'OutBound';
      else if (d[3] === 2) val = 'InBound';
      else if (d[3] === 4) val = 'InPlant';

      // let formattedRowData = [d[0], datestring,d[4], d[3], d[2],d[1]];
      let formattedRowData = [d[0], d[1], d[2], val, d[5], datestring];
      let row = worksheet.addRow(formattedRowData);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

  exportExcelForNonLogisticGate(gateData) {
    //Title, Header & Data
    const title = gateData.title;
    const header = ['Gate No', 'VRN', 'Direction', 'Entry/Exit Time'];
    const data = gateData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Non Logistic vehicle Gate Report');

    // Add Row and formatting
    worksheet.mergeCells('A1', 'C3');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('D1', 'D3');
    let d = new Date();
    let date = d;
    let dateCell = worksheet.getCell('D1');

    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      //let row = worksheet.addRow(d);
      var dd = new Date(d[2]);
      var datestring =
        ('0' + dd.getDate()).slice(-2) +
        '-' +
        ('0' + (dd.getMonth() + 1)).slice(-2) +
        '-' +
        dd.getFullYear() +
        ' ' +
        ('0' + dd.getHours()).slice(-2) +
        ':' +
        ('0' + dd.getMinutes()).slice(-2);
        if(datestring == '01-01-1970 05:30'){
          datestring = '';
        }
      // let formattedRowData = [d[0], datestring,d[4], d[3], d[2],d[1]];
      let formattedRowData = [d[0], d[1], d[3], datestring];
      let row = worksheet.addRow(formattedRowData);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

  exportExcelForVehicleMaster(vehicleMaster) {
    //Title, Header & Data
    const title = vehicleMaster.title;
    const header = ['Vehicle No.', 'RFID Tag', 'Permit Valid Till', 'Number of Wheels'];
    const data = vehicleMaster.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Gate Report');

    // Add Row and formatting
    worksheet.mergeCells('A1', 'C3');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('D1', 'D3');
    let d = new Date();
    let date = d;
    let dateCell = worksheet.getCell('D1');

    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      //let row = worksheet.addRow(d);
      var dd = new Date(d[5]);
      var datestring =
        ('0' + dd.getDate()).slice(-2) +
        '-' +
        ('0' + (dd.getMonth() + 1)).slice(-2) +
        '-' +
        dd.getFullYear();
        if(datestring == '01-01-1970 05:30'){
          datestring = '';
        }

      // var dd1 = new Date(d[6]);
      // var datestring1 = ("0" + dd.getDate()).slice(-2) + "-" + ("0" + (dd.getMonth() + 1)).slice(-2) + "-" +
      //   dd.getFullYear() + " " + ("0" + dd.getHours()).slice(-2) + ":" + ("0" + dd.getMinutes()).slice(-2);

      // let formattedRowData = [d[0], datestring,d[4], d[3], d[2],d[1]];
      let formattedRowData = [d[1], d[2], datestring, d[6]];
      let row = worksheet.addRow(formattedRowData);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 25;
    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

  exportExcelForWeighbridgeTransaction(weightDetail) {
    //Title, Header & Data
    const title = weightDetail.title;
    const header = ['Vehicle No.', 'Vehicle Tran. Code', 'Weighbridge Name', 'Weighment Type','Excepted Weight (KG)', 'Actual Weight (KG)','Net Weight', 'Transaction Time',
        'Weighment TAT','Image Capture', 'Status','Tare Weighment (KG)', 'Product Type','Product Weight', 'Shipment Number', 'Transaction Type', 'Remark'];
    const data = weightDetail.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Weighment Transaction Report');

    // Add Row and formatting
    worksheet.mergeCells('A1', 'P3');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('Q1', 'Q3');
    let d = new Date();
    let date = d;
    let dateCell = worksheet.getCell('Q1');

    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      //let row = worksheet.addRow(d);
      var dd = new Date(d[9]);
      var datestring = ("0" + dd.getDate()).slice(-2) + "-" + ("0" + (dd.getMonth() + 1)).slice(-2) + "-" +
        dd.getFullYear() + " " + ("0" + dd.getHours()).slice(-2) + ":" + ("0" + dd.getMinutes()).slice(-2);
        if(datestring == '01-01-1970 05:30'){
          datestring = '';
        }
      var ImageCapture = 'No';
      if(d[10] == true){
        ImageCapture = 'Yes';
      }
      var TransactionType = '';
      if(d[22] == '1'){
        TransactionType = 'Outbound';
      }
      else if(d[22] == '2'){
        TransactionType = 'Inbound';
      }
      else if(d[22] == '4'){
        TransactionType = 'Inplant';
      }

      var netWeight = d[8] - d[17]

      let formattedRowData = [d[0], d[1], d[2], d[6], d[7], d[8],netWeight, datestring, d[27], ImageCapture,
       d[11], d[17], d[16], d[18], d[21], TransactionType, d[26]];
      let row = worksheet.addRow(formattedRowData);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 25;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 30;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 40;
    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

  exportExcelForCurrentTransaction(currentTran) {
    //Title, Header & Data
    const title = currentTran.title;
    const header = ['Vehicle No', 'Driver Name', 'Vehicle Tran Code', 'RFID Tag', 'Transaction Type','Parking Entry Time',
     'Parking Exit Time','Plant Entry Time','Plant Exit Time', 'Transaction Status', 'Previous Milestone', 'Current Milestone',];
    const data = currentTran.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Current Transaction');

    // Add Row and formatting
    worksheet.mergeCells('A1', 'K3');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('L1', 'L3');
    let d = new Date();
    let date = d;
    let dateCell = worksheet.getCell('L1');

    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      //let row = worksheet.addRow(d);
      var dd = new Date(d[9]);
      var datestring = ("0" + dd.getDate()).slice(-2) + "-" + ("0" + (dd.getMonth() + 1)).slice(-2) + "-" +
        dd.getFullYear() + " " + ("0" + dd.getHours()).slice(-2) + ":" + ("0" + dd.getMinutes()).slice(-2);
      if(datestring == '01-01-1970 05:30'){
        datestring = '';
      }
        var dd1 = new Date(d[10]);
      var datestring1 = ("0" + dd1.getDate()).slice(-2) + "-" + ("0" + (dd1.getMonth() + 1)).slice(-2) + "-" +
        dd1.getFullYear() + " " + ("0" + dd1.getHours()).slice(-2) + ":" + ("0" + dd1.getMinutes()).slice(-2);
        if(datestring1 == '01-01-1970 05:30'){
          datestring1 = '';
        }
        var dd2 = new Date(d[5]);
      var datestring2 = ("0" + dd2.getDate()).slice(-2) + "-" + ("0" + (dd2.getMonth() + 1)).slice(-2) + "-" +
        dd2.getFullYear() + " " + ("0" + dd2.getHours()).slice(-2) + ":" + ("0" + dd2.getMinutes()).slice(-2);
        if(datestring2 == '01-01-1970 05:30'){
          datestring2 = '';
        }
        var dd3 = new Date(d[6]);
        var datestring3 = ("0" + dd3.getDate()).slice(-2) + "-" + ("0" + (dd3.getMonth() + 1)).slice(-2) + "-" +
          dd3.getFullYear() + " " + ("0" + dd3.getHours()).slice(-2) + ":" + ("0" + dd3.getMinutes()).slice(-2);
          if(datestring3 == '01-01-1970 05:30'){
            datestring3 = '';
          }
      var TransactionType = '';
      if(d[4] == '1'){
        TransactionType = 'Outbound';
      }
      else if(d[4] == '2'){
        TransactionType = 'Inbound';
      }
      else if(d[4] == '4'){
        TransactionType = 'Inplant';
      }
      let formattedRowData = [d[2], d[8], d[1], d[3], TransactionType, datestring,datestring1,datestring2, datestring3,
       d[7], d[11], d[12]];
      let row = worksheet.addRow(formattedRowData);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 30;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

  exportExcelForDailyTATReport(currentTran) {
    //Title, Header & Data
    const title = currentTran.title;
    const header = ['Date','OB Max TAT', 'OB Min TAT', 'OB Average TAT', 'No. of Trans', 'IB Max TAT', 'IB Min TAT', 'IB Average TAT', 'No. of IB Trans',
                    'Max TAT', 'Min TAT', 'Average TAT', 'No. of Trans'];
    const data = currentTran.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Day wise TAT Report');

    // Add Row and formatting
    worksheet.mergeCells('A1', 'L3');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('M1', 'M3');
    let d = new Date();
    let date = d;
    let dateCell = worksheet.getCell('M1');

    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      //let row = worksheet.addRow(d);

      let formattedRowData = [ d[0], d[1], d[2], d[3],d[10], d[4], d[5], d[6], d[11], d[7], d[8], d[9], d[12]];
      let row = worksheet.addRow(formattedRowData);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 20;
    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }
  exportExcelForDetailedGateReport(DetailReport) {
    // Title, Header & Data
    const title = DetailReport.title;
    const header = [
        'VRN', 'AX4 Txn ID', 'Driver Code', 'Driver Name', 'Driver Mobile No',
        'Transporter', 'Transaction Type', 'Location', 'Milestone',
        'Txn Initiated by', 'Vehicle Detection', 'Transaction Date & Time',
        'RFID', 'FRS', 'BA', 'Boom Up', 'Notify Ax4', 'TAT (Minutes)', 'Remarks'
    ];
    const data = DetailReport.data;

    // Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Detail Gate Report');

    // Add Title
    worksheet.mergeCells('A1', 'R3'); // Adjust the range based on your header length
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
        name: 'Calibri',
        size: 16,
        underline: 'single',
        bold: true,
        color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Add Date
    worksheet.mergeCells('S1', 'S3'); // Adjust the column index if necessary
    let dateCell = worksheet.getCell('S1');
    dateCell.value = new Date();
    dateCell.font = {
        name: 'Calibri',
        size: 12,
        bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    // Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
        };
        cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12,
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Adding Data
    data.forEach((rowData) => {
        let row = worksheet.addRow(rowData);
        // You can apply conditional formatting or other styles here if needed
    });

    // Set column widths
    worksheet.getColumn(1).width = 20; // Adjust widths according to your needs
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 25;
    worksheet.getColumn(11).width = 25;
    worksheet.getColumn(12).width = 25;
    worksheet.getColumn(13).width = 20;
    worksheet.getColumn(14).width = 20;
    worksheet.getColumn(15).width = 20;
    worksheet.getColumn(16).width = 20;
    worksheet.getColumn(17).width = 20;
    worksheet.getColumn(18).width = 30;

    worksheet.addRow([]); // Adding an empty row for spacing

    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fs.saveAs(blob, title + '.xlsx');
    });
}

  exportExcelForDriverMaster(driverMaster, reportHeader) {
    //Title, Header & Data
    const title = driverMaster.title;
    const header = ['Driver Name', 'Driver Code', 'Contact No.', 'Aadhar No.', 'DL No.', 'DL Validity', 'Is BlackListed', 'FR\'s Status', 'Active Status', 'Registered Date'];
    const data = driverMaster.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(reportHeader);

    // Add Row and formatting
    worksheet.mergeCells('A1', 'I3');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('J1', 'J3');
    let d = new Date();
    let date = d;
    let dateCell = worksheet.getCell('J1');

    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      //let row = worksheet.addRow(d);
      var dd = new Date(d[9]);
      var dd1 = new Date(d[17]);
      var datestring =
        ('0' + dd.getDate()).slice(-2) +
        '-' +
        ('0' + (dd.getMonth() + 1)).slice(-2) +
        '-' +
        dd.getFullYear();
        if(datestring == '01-01-0001'){
          datestring = '';
        }

        var datestring1 =
        ('0' + dd1.getDate()).slice(-2) +
        '-' +
        ('0' + (dd1.getMonth() + 1)).slice(-2) +
        '-' +
        dd1.getFullYear();
        if(datestring1 == '01-01-0001'){
          datestring1 = '';
        }

        var activeStatus = 'Active';
        if(d[16] == false){
          activeStatus = 'Inactive';
        }

        var blackListStatus = 'No';
        if(d[11] == true){
          blackListStatus = 'Yes';
        }
      // var dd1 = new Date(d[6]);
      // var datestring1 = ("0" + dd.getDate()).slice(-2) + "-" + ("0" + (dd.getMonth() + 1)).slice(-2) + "-" +
      //   dd.getFullYear() + " " + ("0" + dd.getHours()).slice(-2) + ":" + ("0" + dd.getMinutes()).slice(-2);

      // let formattedRowData = [d[0], datestring,d[4], d[3], d[2],d[1]];
      let formattedRowData = [d[2], d[1], d[5], d[7], d[8], datestring, blackListStatus, d[13], activeStatus, datestring1];
      let row = worksheet.addRow(formattedRowData);
    });

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 20;
    worksheet.addRow([]);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

}
