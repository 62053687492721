import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { RFIDResponse } from 'src/app/models/rfidmodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';
import { SkipFrs } from 'src/app/models/skip-frs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parkingsimulation',
  templateUrl: './parkingsimulation.component.html',
  styleUrls: ['./parkingsimulation.component.scss']
})
export class ParkingSimulationComponent implements OnInit {

  public userAccessData: UserScreenAccesData;
  deviceMaster: any;
  submitted = false;
  form: any;

  driverMasterData:any;
  selectedDriver:any;
  public RFIDSimulation: RFIDResponse = new RFIDResponse();
  public FRSSimulation: SkipFrs = new SkipFrs();

  constructor(private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder,

    private kemarClientService: KemarClientService, private modalService: NgbModal,
    private api: DataService, private router: Router) {
      this.form = this.formBuilder.group({

        deviceLocationId: [0],
        RFIDTagNo: [''],

        vrn: [
          '',

        ],
        weight: [''],
        weighBridgeId: [0],
        deviceId: [0],
        Status:[''],
        VRN:[''],
        Comment:[''],
        additionalComment:[''],
        milestoneTransactionCode: [''],
        milestoneCode: [''],
      });
      var route = this.router.url;
    this.userAccessData =this.api.getUserScreenAccessMenuOnRoutingURL(route);
    }

  ngOnInit(): void {
    this.getAllDevice();
    this.getDriverMaster();
  }

  getAllDevice() {
    debugger
    this.spinner.show();
    var query = { 'requestId': "S1637", 'LocationType':"OSPP" }
    this.kemarClientService.get<any>(null, ServiceUrl.getdeviceMapping,query)
      .subscribe(
        response => {
          debugger
          this.deviceMaster = response.locations.slice().sort((a,b)=> a.deviceName.localeCompare(b.deviceName));
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
        });
  }

  PostRFIDTAG() {
    debugger
    this.form;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if(this.RFIDSimulation?.deviceLocationId == undefined || this.RFIDSimulation?.deviceLocationId == 0){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Select Location"
      });
      return;
    }
    if(this.RFIDSimulation?.vrn == undefined || this.RFIDSimulation?.vrn == null){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Enter Vehicle Number"
      });
      return;
    }
  this.RFIDSimulation.requestId="s4526"
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.postRFIDTAG, this.RFIDSimulation)
      .subscribe(
        res => {

          Swal.fire({
            icon: 'success',
            title: '',
            text: res.statusMessage
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        },
        r => {
          var errmsg = "";

          if (r.error.title != undefined && r.error.title != null){
              errmsg = r.error.title;
          }
          else
          {
            if (r.error.errorMessage != undefined && r.error.errorMessage != null)
            {
              errmsg = r.error.errorMessage;
            }
            else if (r.error.statusMessage != undefined && r.error.statusMessage != null)
            {
              errmsg = r.error.statusMessage;
            }
            else if ( r.error != null && r.error != null)
            {
              errmsg = r.error;
            }
          }

          Swal.fire({
            icon: 'error',
            title: '',
            text: errmsg
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        });
  }
  getDriverMaster() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAllDrivers).subscribe(
      (response) => {
        debugger
        this.driverMasterData = response.map(driver => ({
          name: driver.name,
          driverCode: driver.driverCode
        }));
        // this.driverMasterData = response;
        // var driverCode = this.driverMasterData.filter(
        //   (x) => x.driverCode == '0000'
        // );
        // if(driverCode.length>0) {
        //   this.elvForm.controls['DriverCode'].setValue(
        //     driverCode[0].driverCode
        //   );
        // }
      },
      (r) => {
        alert(r.error.error);
      }
    );
  }

  postFRSDetails(){
    debugger
    this.FRSSimulation.id = '1';
    if(this.FRSSimulation?.vrn == undefined || this.FRSSimulation?.vrn == null || this.FRSSimulation?.vrn == ''){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Enter Vehicle Number"
      });
      return;
    }
    if(this.FRSSimulation?.driverCode == undefined || this.FRSSimulation?.driverCode == null || this.FRSSimulation?.driverCode == ''){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Select Driver"
      });
      return;
    }
    if(this.FRSSimulation?.remarks == undefined || this.FRSSimulation?.remarks == null || this.FRSSimulation?.remarks == ''){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Add remark to bypass FR's"
      });
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.skipFRS, this.FRSSimulation)
    .subscribe(
      (response) =>
        {
          Swal.fire({
            icon: 'success',
            title: '',
            text: response.statusMessage
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.statusMessage
        }).then(function(){
          window.location.reload();
        })
        this.spinner.hide();
      }
    )

  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
}
