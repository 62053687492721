import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { DatePipe } from '@angular/common';
import { authInterceptorProviders } from './common/auth.interceptor';
import { AppHeaderComponent } from './template/app-header';
import { AppFooterComponent } from './template/app-footer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserRegistrationComponent } from './admin/user/user-registration/user-registration.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { GateInReportComponent } from './reports/gate-report/gate-report.component';
import { AuthguardService } from './service/authguard-service.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ParkingInComponent } from './gates/parking-in/parking-in.component';
import { ParkingOutComponent } from './gates/parking-out/parking-out.component';
import { PlantInComponent } from './gates/plant-in/plant-in.component';
import { PlantOutComponent } from './gates/plant-out/plant-out.component';
import { VehicleComponent } from './master/vehicle-master/vehicle.component';
import { DriverComponent } from './master/driver/driver.component';
import { TransporterMasterComponent } from './master/transporter-master/transporter-master.component';
import { TatReportComponent } from './reports/tat-report/tat-report.component';
import { LaneControlComponent } from './controls/lane-control/lane-control.component';
import { WeighBridgeControlComponent } from './controls/weigh-bridge-control/weigh-bridge-control.component';
import { WeighBridgeMasterComponent } from './master/weigh-bridge-master/weigh-bridge-master.component';
import { MilestoneMasterComponent } from './master/milestone-master/milestone-master.component';
import { VehicleBlacklistingComponent } from './controls/vehicle-blacklisting/vehicle-blacklisting.component';
import { DriverBlacklistingComponent } from './controls/driver-blacklisting/driver-blacklisting.component';
import { ThirdPartyVehicleComponent } from './master/third-party-vehicle/third-party-vehicle.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TransporterBlacklistingComponent } from './controls/transporter-blacklisting/transporter-blacklisting.component';
import { VehicleTabsComponent } from './master/vehicle-tabs/vehicle-tabs.component';
import { DeviceLocationMappingComponent } from './master/device-location-mapping/device-location-mapping.component';
import { BlacklistingComponent } from './controls/blacklisting/blacklisting.component';
import { MilestoneActionComponent } from './controls/milestone-action/milestone-action.component';
import { WeighbridgePreferenceComponent } from './controls/weighbridge-preference/weighbridge-preference.component';
import { ElvComponent } from './transaction/elv/elv.component';
import { MilestoneActionMappingComponent } from './master/milestone-action-mapping/milestone-action-mapping.component';
import { MilestoneTabsComponent } from './master/milestone-tabs/milestone-tabs.component';
import { CurrentTransactionComponent } from './transaction/current-transaction/current-transaction.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RoleComponent } from './master/roleMaster/role.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { UserRoleMappingMasterComponent } from './master/userRoleMappingMaster/userRoleMappingMaster.component';
import { TransactionComponent } from './transaction/transaction/transaction.component';
import { RfidRederSimulationComponent } from './controls/rfidredersimulation/rfidredersimulation.component';
import { Ax4SimulationComponent } from './controls/ax4simulation/ax4simulation.component';
import { CrossCheckMasterComponent } from './master/cross-check-master/cross-check-master.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WeighbridgestatusComponent } from './transaction/weighbridge-status/weighbridge-status.component';
import { VehicleParkingComponent } from './transaction/vehicle-parking/vehicle-parking.component';
import { DeviceControlComponent } from './controls/device-control/device-control.component';
import { MenuItemComponent } from './template/menu-item/menu-item.component';
import { LocationMappingComponent } from './master/location-mapping/location-mapping.component';
import { InplantTransactionComponent } from './transaction/inplant-transaction/inplant-transaction.component';
import { WeighbridgeTransactionComponent } from './transaction/weighbridge-transaction/weighbridge-transaction.component';
import { ApiTrackingReportComponent } from './reports/apiTracking-report/apiTracking-report.component';
import { AlertTabsComponent } from './controls/alert-tabs/alert-tabs.component';
import { AlertMasterComponent } from './controls/alert-master/alert-master.component';
import { ExceptionMasterComponent } from './controls/exception-master/exception-master.component';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';
import { BchartComponent } from './dashboard/bchart/bchart.component';
import { ChartModule } from 'primeng/chart';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MyIncidentComponent } from './controls/myincident/myincident.component';
import { CtDashboardComponent } from './ct-dashboard/ct-dashboard.component';
import { CTUsersComponent } from './controls/ctusers/ctusers.component';
import { WbDasboardComponent } from './wb-dasboard/wb-dasboard.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api'
import { GalleriaModule } from 'primeng/galleria';
import { DragDropModule } from 'primeng/dragdrop';
import { CheckboxModule } from 'primeng/checkbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import { ServiceTicketSLAComponent } from './master/service-ticket-sla/service-ticket-sla.component';
import { CommonMasterDataComponent } from './master/common-master-data/common-master-data.component';
import { GateTransactionComponent } from './transaction/gate-transaction/gate-transaction.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { InplantMasterComponent } from './master/inplant-master/inplant-master.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppConfigComponent } from './master/app-config/app-config.component'
import { TransitSLAComponent } from './master/transit-sla/transit-sla.component';
import { SLAMasterTabsComponent } from './master/slamaster/slamaster-tabs.component';
import {CarouselModule} from 'primeng/carousel';
import { JobScheduleMasterComponent } from './master/job-schedule-master/job-schedule-master.component';
import { GateDashboardComponent } from './transaction/gate-dashboard/gate-dashboard.component';
import { MyAssignedincidentComponent } from './my-assignedincident/my-assignedincident.component';
import {NgxPrintModule} from 'ngx-print';
import { NonlogisticVehicleComponent } from './nonlogistic-vehicle/nonlogistic-vehicle.component';
import { WorkFlowMasterComponent } from './master/work-flow-master/work-flow-master.component';
import { FrsEnrollmentComponent } from './frs-enrollment/frs-enrollment.component';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { ApiSimulationComponent } from './controls/api-simulation/api-simulation.component';
import { DropdownModule } from 'primeng/dropdown';
import { CementVehicleComponent } from './master/cement-vehicle/cement-vehicle.component';
import { DeviceConfigurationComponent } from './controls/device-configuration/device-configuration.component';
import { InplantApiSimulationComponent } from './controls/inplant-api-simulation/inplant-api-simulation.component';
import { PackerMasterComponent } from './master/packer-master/packer-master.component';
import { WeightToleranceActionComponent } from './transaction/weight-tolerance-action/weight-tolerance-action.component';
import { WeighmentApprovalComponent } from './transaction/weighment-approval/weighment-approval.component';
import { TableModule } from 'primeng/table';
import { DailyTatReportComponent } from './reports/daily-tat-report/daily-tat-report.component';
import { APICommunicationErrorsComponent } from './controls/apicommunication-errors/apicommunication-errors.component';
import { UpComingVehicleReportComponent } from './reports/up-coming-vehicle-report/up-coming-vehicle-report.component';
import { AutomotiveComponent } from './landingPage/automotive/automotive.component';
import { CementComponent } from './landingPage/cement/cement.component';
import { SteelComponent } from './landingPage/steel/steel.component';
import { PortComponent } from './landingPage/port/port.component';
import { LfooterComponent } from './landingPage/lfooter/lfooter.component';
import { LandingHomeComponent } from './landingPage/landing-home/landing-home.component';
import { ParkingSimulationComponent } from './controls/parkingsimulation/parkingsimulation.component';
import { PlantGateSimulationComponent } from './controls/plantgatesimulation/plantgatesimulation.component';
import { WeighmentsimulationComponent } from './controls/weighmentsimulation/weighmentsimulation.component';
import { LoadingsimulationComponent } from './controls/loadingsimulation/loadingsimulation.component';
import { InvoiceVerificationComponent } from './controls/invoice-verification/invoice-verification.component';
import { GateChangeComponent } from './gate-change/gate-change.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DetailedGateReportComponent } from './reports/detailed-gate-report/detailed-gate-report.component';


import { UTDashboardCommonComponent } from './CommonDashboard/ut-dashboard-common/ut-dashboard-common.component';
import { UTWeighbridgeDashboardCommonComponent } from './CommonDashboard/ut-weighbridge-dashboard-common/ut-weighbridge-dashboard-common.component';
import { UTCTDashboardCommonComponent } from './CommonDashboard/ut-ct-dashboard-common/ut-ct-dashboard-common.component';
import { UTDeviceStatusCommonComponent } from './CommonDashboard/ut-device-status-common/ut-device-status-common.component';
import { UtHeaderCommonComponent } from './CommonDashboard/ut-header-common/ut-header-common.component';
import { VehicleTripSupportComponent } from './support/vehicle-trip-support/vehicle-trip-support.component';
import { ElvSupportComponent } from './support/elv-support/elv-support.component';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    MenuItemComponent,
    LoginComponent,
    NotFoundComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    UserRegistrationComponent,
    MyProfileComponent,
    GateInReportComponent,
    ApiTrackingReportComponent,
    ParkingInComponent,
    ParkingOutComponent,
    PlantInComponent,
    PlantOutComponent,
    VehicleComponent,
    DriverComponent,
    TransporterMasterComponent,
    TatReportComponent,
    LaneControlComponent,
    WeighBridgeControlComponent,
    WeighBridgeMasterComponent,
    MilestoneMasterComponent,
    VehicleBlacklistingComponent,
    RoleComponent,
    UserRoleMappingMasterComponent,
    DriverBlacklistingComponent,
    ThirdPartyVehicleComponent,
    TransporterBlacklistingComponent,
    VehicleTabsComponent,
    DeviceLocationMappingComponent,
    BlacklistingComponent,
    MilestoneActionComponent,
    WeighbridgePreferenceComponent,
    ElvComponent,
    MilestoneActionMappingComponent,
    MilestoneTabsComponent,
    AlertTabsComponent,
    AlertMasterComponent,
    ExceptionMasterComponent,
    RfidRederSimulationComponent,
    Ax4SimulationComponent,
    TransactionComponent,
    CrossCheckMasterComponent,
    CurrentTransactionComponent,
    WeighbridgestatusComponent,
    VehicleParkingComponent,
    DeviceControlComponent,
    LocationMappingComponent,
    InplantTransactionComponent,
    WeighbridgeTransactionComponent,
    NewDashboardComponent,
    BchartComponent,
    MyIncidentComponent,
    CtDashboardComponent,
    CTUsersComponent,
    WbDasboardComponent,
    ServiceTicketSLAComponent,
    CommonMasterDataComponent,
    GateTransactionComponent,
    InplantMasterComponent,
    AppConfigComponent,
    TransitSLAComponent,
    SLAMasterTabsComponent,
    JobScheduleMasterComponent,
    GateDashboardComponent,
    MyAssignedincidentComponent,
    NonlogisticVehicleComponent,
    WorkFlowMasterComponent,
    FrsEnrollmentComponent,
    ApiSimulationComponent,
    CementVehicleComponent,
    DeviceConfigurationComponent,
    InplantApiSimulationComponent,
    PackerMasterComponent,
    WeightToleranceActionComponent,
    WeighmentApprovalComponent,
    DailyTatReportComponent,
    APICommunicationErrorsComponent,
    UpComingVehicleReportComponent,
    AutomotiveComponent,
    PortComponent,
    SteelComponent,
    CementComponent,
    LfooterComponent,
    LandingHomeComponent,
    ParkingSimulationComponent,
    PlantGateSimulationComponent,
    WeighmentsimulationComponent,
    LoadingsimulationComponent,
    InvoiceVerificationComponent,
    GateChangeComponent,
    DetailedGateReportComponent,
    UTDashboardCommonComponent,
    UTWeighbridgeDashboardCommonComponent,
    UTCTDashboardCommonComponent,
    UTDeviceStatusCommonComponent,
    UtHeaderCommonComponent,
    VehicleTripSupportComponent,
    ElvSupportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatOptionModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    RouterModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    ChartModule,
    NgbModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTooltipModule,
    ConfirmDialogModule,
    GalleriaModule,
    DragDropModule,
    CheckboxModule,
    SelectButtonModule,
    MultiSelectModule,
    MatSidenavModule,
    CarouselModule,
    NgxPrintModule,
    DialogModule,
    ProgressBarModule,
    DropdownModule,
    TableModule,
    NgSelectModule
  ],
  providers: [authInterceptorProviders, AuthguardService, DatePipe, ConfirmationService],
  bootstrap: [AppComponent],
  exports: [
    AppComponent,
    AppHeaderComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule { }
