<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-4"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter align-items-center my-2">
          <div class="col-sm-6">
            <h4 class="title-one mb-0"> Packer Master</h4>
          </div>
          <div class="col-sm-6 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">
                search
              </span>
              <input class="form-control" type="text" name="search" placeholder="search" [(ngModel)]="filter">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Packer Data" matTooltipClass="danger-tooltip"*ngIf="userAccessData[0]?.canCreate ==true">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr. No.</th>

                  <th  class="text-center" (click)="sort('name')">Packer Location
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th  class="text-center" (click)="sort('name')">Dispatched Point
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th class="text-center" (click)="sort('name')">Running Material
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th class="text-center" (click)="sort('name')">TL Point
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                 <th class="text-center" (click)="sort('name')">Status
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                  </th>
                   <th class="text-center" (click)="sort('name')"*ngIf="userAccessData[0]?.canUpdate ==true">Action</th>

                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let packer of packerData | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="text-center">{{packer.location?.locationName}}</td>
                  <td class="text-center">{{packer.dispatchPoint}}</td>
                  <td class="text-center">{{packer.runningMaterial}}</td>
                  <td class="text-center">{{packer.tlPoint}}</td>
                  <td class="text-center">
                    <span *ngIf="packer.isActive  ==true ">Active</span>
                    <span *ngIf="packer.isActive  ==false ">Inactive</span>
                  </td>
                  <td class="text-center" *ngIf="userAccessData[0]?.canUpdate ==true">
                    <span class="edit-btn-icon" type="button" matTooltip="Edit Packer"
                      matTooltipClass="danger-tooltip" (click)="editPacker(edit, packer)">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
        </div>
        </div>
    </div>
    </div>
  </section>
  <ng-template #edit let-modal class="modal">
    <div class="modal-content custom-modal">
        <div class="modal-header">
          <h4 class="modal-title mb-0">{{title}}</h4>
          <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span class="material-icons-sharp">close</span>
          </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
        <div class="col-md-12">
            <form autocomplete="off" [formGroup]="packerForm">
                <div class="form-group">
                    <div class="form-group row mb-0 mt-2">
                        <div class="col-6">
                          <label for="locationId" style="padding-right: 54px;">Packer Name
                          </label>

                          <select name="locationId" class="form-control form-select"
                          formControlName="locationId" [ngClass]="{ 'is-invalid': submitted && f['locationId'].errors }">
                          <option hidden Value='' disabled>--Choose Option--</option>
                                  <option *ngFor="let packerName of locationMapping"
                                      [ngValue]="packerName.locationId">
                                      {{packerName.locationName}}</option>
                                    </select>
                            <div *ngIf="submitted && f['locationId'].errors" class="text-danger">
                            <div *ngIf="f['locationId'].errors['required']">
                              <small class="requiredMessage">Packer Name is required</small>
                            </div>

                          </div>
                        </div>
                        <div class="col-6">
                            <label for="dispatchPoint" style="padding-right: 54px;">Dispatch Point
                            </label>
                            <input type="text" class="form-control" placeholder="" value=""
                                [ngClass]="{ 'is-invalid': submitted && f['dispatchPoint'].errors }"
                                name="dispatchPoint" formControlName="dispatchPoint" />
                            <div *ngIf="submitted && f['dispatchPoint'].errors" class="text-danger">
                                <div *ngIf="f['dispatchPoint'].errors['required']">
                                    <small class="requiredMessage">Dispatched Point is required</small>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="form-group row mb-0 mt-2">
                        <div  class="col-6">
                            <label for="runningMaterial" style="padding-right: 54px;"> Running Material
                            </label>
                            <select name="runningMaterial" class="form-control form-select"
                            formControlName="runningMaterial" [ngClass]="{ 'is-invalid': submitted && f['runningMaterial'].errors }">
                            <option hidden Value='' disabled>--Choose Option--</option>
                                    <option *ngFor="let runningMaterial of ProductMaster"
                                        [ngValue]="runningMaterial.category">
                                        {{runningMaterial.category}}</option>

                            </select>
                            <div *ngIf="submitted && f['runningMaterial'].errors" class="text-danger">
                              <div *ngIf="f['runningMaterial'].errors['required']">
                                  <small class="requiredMessage">Running Material is required</small>
                              </div>

                          </div>
                        </div>
                        <div class="col-6">
                            <label for="tlPoint" style="padding-right: 54px;">TL Point
                            </label>
                            <input type="text" class="form-control" placeholder="" value=""
                                [ngClass]="{ 'is-invalid': submitted && f['tlPoint'].errors }"
                                name="tlPoint" formControlName="tlPoint" />
                            <div *ngIf="submitted && f['tlPoint'].errors" class="text-danger">
                                <div *ngIf="f['tlPoint'].errors['required']">
                                    <small class="requiredMessage"> TLPoint Count is required</small>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <br>
                <div class="select-radio" *ngIf="showActiveButton">
                  <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="packerData.isActive"
                    formControlName="isActive">
                  <label for="active" class="radio-label">Active</label>

                  <input class="radio-input" id="inActive" type="radio" [value]="false" [(ngModel)]="packerData.isActive"
                    formControlName="isActive">
                  <label for="inActive" class="radio-label">Inactive</label>
                </div>

            </form>
        </div>
        </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
      (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
      (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-success"
          (click)="SavePackerData()">Save</button>
  </div>
</ng-template>
