export class TransactionSLAMasterResponse {
    transactionSLAMasterId: number;
    eventCode: string;
    eventName: string;
    alertTimeInMinutes: number;
    exceptionTimeInMinutes: number;
    ticketSLAInMinutes: number;
    subject: string;
    description: string;
    ticketPriority: string;
    isActive: boolean;
}