import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plant-out',
  templateUrl: './plant-out.component.html',
  styleUrls: ['./plant-out.component.scss']
})
export class PlantOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
