import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ELVResponseModel } from 'src/app/models/elvmodel';

import { faBan, faPlus, faSort,faSearch, faTrash, faForward } from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { ConfigParms } from 'src/app/common/portal-config';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-elv',
  templateUrl: './elv.component.html',
  styleUrls: ['./elv.component.scss']
})
export class ElvComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  public userAccessData: UserScreenAccesData;
  active = 'top';
  _component: any;
  componentsList = [];
  faBan = faBan
  faPlus = faPlus
  faSearch = faSearch
  faSort = faSort
  faDelete = faTrash;
  faForward = faForward;
  elvList: any;
  activeElvTransaction: any;
  activeElvTransactionExpired: any;
  activeElvTransactionCompleted: any;
  activeElvTransactionCancelled: any;
  activeElvTransactionActive: any;
  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  filter1 = '';
  filter2 = '';
  filter3 = '';
  pageSize = 15;
  key: string = 'name'; //set default
  reverse: boolean = false;
  tempcurrentActivePage: number = 1;
  tempcurrentActivePage1: number = 1;
  tempcurrentActivePage2: number = 1;
  status: any;
  validtil: any;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  currentActivePage1: number = 1;
  currentActivePage2: number = 1;
  Elv: UntypedFormGroup;
  fromDate: any;
  toDate: any;
  TotalRecords: any;
  public _selectedTab: number = 0;
  today:Date;
  public searchText: string = '';
  // Expire
  fromExpireDate: any;
  toExpireDate: any;

  // Cancelled
  fromCancelledDate: any;
  toCancelledDate: any;
  elvIdToDelete: number;
  elvIdToComplete: number;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;

  plantInfo = '';
  tranTypeActive = 0;
  tranTypeComlpeted = 0;
  tranTypeExpired = 0;
  tranTypeCancelled = 0;

  constructor(private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private api:DataService,
    private datePipe: DatePipe) {
      this.plantInfo = ConfigParms.plant;
      this.today = new Date();

     }

  ngOnInit(): void {
    this.resetform();
    const today = new Date();
    const formattedDate = this.datePipe.transform(today,'yyyy-MM-dd');
    this.fromDate = formattedDate;
    this.toDate = formattedDate;
    this.fromExpireDate =formattedDate;
    this.toExpireDate =formattedDate;
    this.fromCancelledDate =formattedDate;
    this.toCancelledDate =formattedDate;
      this.loadTabComponent(0)
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  open(content: any) {
    this.modalService.open(content, this.ngbModalOptions);
  }

  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);
  SearchFilter() {
    this.tempcurrentActivePage = this.currentActivePage = 1;
    this.getallELVlist(this.tranTypeActive);
  }

  resetform() {
    this.Elv = this.formBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      selectedTab: [''],
    });
    this.userAccessData =this.api.getUserScreenAccessMenu('ELV');
  }

  loadTabComponent(tab: number) {
    if (tab == 0){
      this.status = ["Active"]
      this.getallELVlist(this.tranTypeActive);
    }
    else if (tab == 1)
    {this.status = ["Completed"]
    this.getallELVlist(this.tranTypeComlpeted)
    }
    else if (tab == 2)
    {
    this.status = "Expired"
      this.getallELVlist(this.tranTypeExpired);
    }
    else if (tab == 3)
    {
    this.status = "Cancelled"
      this.getallELVlist(this.tranTypeCancelled);
    }
  }

  getallELVlist(tranType) {
    debugger;
    this.spinner.show();
    var fromDateValue = this.fromDate;
    var toDateValue = this.toDate;
    if(this.status == 'Expired'){
      fromDateValue = this.fromExpireDate;
      toDateValue = this.toExpireDate;
    }
    else if(this.status == 'Cancelled'){
      fromDateValue = this.fromCancelledDate;
      toDateValue = this.toCancelledDate;
    }
    var query ={
      status:this.status,
      fromDate : fromDateValue,
      toDate : toDateValue,
      tranType: tranType
    }
    this.kemarClientService.get<ELVResponseModel>(null, ServiceUrl.getAllElvlist,query)
      .subscribe(
        response => {
          this.spinner.hide();
          this.elvList = response;
          if(this.status == 'Active'){
            this.activeElvTransactionActive = response;
          }
          if(this.status == 'Completed'){
            this.activeElvTransactionCompleted = response;
          }
          if(this.status == 'Expired'){
            this.activeElvTransactionExpired = response;
          }
          if(this.status == 'Cancelled'){
            this.activeElvTransactionCancelled = response;
          }
          // this.activeElvTransactionActive = this.elvList.filter(x => x.status == 'Pending' || x.status == 'InTransit' || x.status == 'InParking' || x.status == 'CalledIn' || x.status == null);
          // this.activeElvTransactionCompleted = this.elvList.filter(x => x.status == 'Completed' || x.Status == 'InPlant');
          // this.activeElvTransactionExpired = this.elvList.filter(x => x.status == 'Expired');
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  // loadTabComponent(_selectedTab: string) {
  //
  //   this._component = "";
  //   if (_selectedTab == "ACTIVE")
  //     this.activeElvTransactionActive = this.elvList.filter(x => x.status == 'Pending' || x.status == 'InTransit' || x.status == 'InParking' || x.status == 'CalledIn' || x.status == null);
  //   else if (_selectedTab == "COMPLETED")
  //     this.activeElvTransactionCompleted = this.elvList.filter(x => x.status == 'Completed' || x.Status == 'InPlant');
  //   else if (_selectedTab == "EXPIRED")
  //     this.activeElvTransactionExpired = this.elvList.filter(x => x.status == 'Expired');
  // }

  pagechange = (event: number) => {  this.tempcurrentActivePage = this.currentActivePage = event; }
  filterchange = (val: any) => {  (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage }


  pagechange1 = (event) => {  this.tempcurrentActivePage1 = this.currentActivePage1 = event; }
  filterchange1 = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage1 = 1 : this.currentActivePage1 = this.tempcurrentActivePage1

  pagechange2 = (event) => this.tempcurrentActivePage2 = this.currentActivePage2 = event;
  filterchange2 = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage2 = 1 : this.currentActivePage2 = this.tempcurrentActivePage2
  // test = (event: any) => this.currentActivePage = this.currentActivePage1 = this.currentActivePage2 = 1;


  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  public closeButton(){
    this.modalService.dismissAll();
  }

  openConfirmationModel(model: any, elvId: number){
    this.elvIdToDelete = elvId;
    this.modalService.open(model, this.ngbModalOptions);
  }

  openConfirmationToCompleteModel(model: any, elvId: number){
    this.elvIdToComplete = elvId;
    this.modalService.open(model, this.ngbModalOptions);
  }

  deleteTransaction(){
    var query = {
      elvId: this.elvIdToDelete
    }
    this.kemarClientService.get<any>(null, ServiceUrl.deleteTransactionOnElvId, query)
    .subscribe(
      res => {
        Swal.fire('',res.responseMessage,'success');
        this.getallELVlist(this.tranTypeActive);
        this.modalService.dismissAll();
      },
      r => {
        debugger;
        Swal.fire('',r.error.errorMessage,'error');
        this.modalService.dismissAll();
      }
    )
  }

  completeTransaction(){
    var query = {
      elvId: this.elvIdToComplete
    }
    this.kemarClientService.get<any>(null, ServiceUrl.completeAllTransactionOnEldId, query)
    .subscribe(
      res => {
        Swal.fire('',res.responseMessage,'success');
        this.getallELVlist(this.tranTypeActive);
        this.modalService.dismissAll();
      },
      r => {
        debugger;
        Swal.fire('',r.error.errorMessage,'error');
        this.modalService.dismissAll();
      }
    )
  }

}
