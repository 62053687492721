<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<div>
    <section class="weighBridge my-2"*ngIf="userAccessData[0]?.canRead ==true">
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="row data-filter my-2 align-items-center">
                        <div class="col-sm-2">
                            <h4 class="title-one mb-0"> Weighbridge Transaction </h4>
                        </div>
                        </div>
                        <div class="filters">
                        <div class="d-flex filter justify-content-end" id="input-filter">
                            <div class="row ">

                            <div class="col">
                            <input class="form-control" name="vrn"
                                [(ngModel)]="wbsearchParameter.vrn" type="text" id="start" value="" placeholder="vehicle"
                                (keyup.enter)="GetAllVehilceTransaction()">
                            </div>
                               <!-- &nbsp; -->
                            <div class="col">
                                <!-- <select name="status" class="form-control form-select" autocomplete="" id="status"
                                    [(ngModel)]="wbSearchForm.status">
                                    <option *ngFor="let wstatus of wbstatus" [ngValue]="wstatus.value">
                                        {{wstatus.key}}
                                    </option>
                                </select> -->
                                <p-multiSelect
                                    [showHeader]="false" [options]="wbstatus"
                                    optionLabel="key" defaultLabel="--Select Status--" [maxSelectedLabels]="2"
                                    [selectedItemsLabel]="'{0} Status selected'"
                                    [(ngModel)]="wbSearchForm.status">
                                </p-multiSelect>


                            </div>
                            <div class="col">
                                <select name="wbtype" class="form-control form-select" autocomplete="" id="wbtype"
                                    [(ngModel)]="wbsearchParameter.wbtype">
                                    <option *ngFor="let wbtype of wbtype" [ngValue]="wbtype.value">
                                        {{wbtype.key}}</option>
                                </select>
                            </div>
                            <div class="col">
                                <!-- <select name="weighbridgeId" class="form-control form-select"
                                    [(ngModel)]="wbsearchParameter.weighbridgeId" >
                                    <option value=0 >--All Weighbridge </option>
                                    <option *ngFor="let wb of weighbridgeMaster" [value]="wb.weighBridgeId">
                                        {{wb.weighBridgeName}}</option>
                                </select> -->
                                <p-multiSelect
                                    [showHeader]="false" [options]="weighbridgeMaster"
                                    optionLabel="weighBridgeName" defaultLabel="--Select Weighbridge--" [maxSelectedLabels]="2"
                                    [selectedItemsLabel]="'{0} Weighbridge selected'"
                                    [(ngModel)]="wbSearchForm.weighbridgeId">
                                </p-multiSelect>
                            </div>
                            <div class="col">
                                <p-multiSelect
                                    [showHeader]="false" [options]="transType"
                                    optionLabel="key" defaultLabel="--Transaction Type--" [maxSelectedLabels]="1"
                                    [selectedItemsLabel]="'{0} Transaction selected'"
                                    [(ngModel)]="wbSearchForm.tranType">
                                </p-multiSelect>
                            </div>

                            <!-- <div class="col d-flex justify-content-around radioButtons">
                                <div class="mr-1">
                                    <input class="mr-05" type="radio" name="regularEvent" [value]="true" (click)="onClick('regularEvent')"
                                        [(ngModel)]="wbsearchParameter.regularEvent">
                                    <label for="active" class="mr-05 radio-label">Regular</label>
                                </div>
                                <div>
                                    <input class="mr-05" type="radio" name="crossCheckEvent" [value]="true"
                                        (click)="onClick('crossCheckEvent')"
                                        [(ngModel)]="wbsearchParameter.crossCheckEvent">
                                    <label for="inactive" class="mr-05 radio-label">Cross Check</label>
                                </div>
                            </div> -->
                            <!-- &nbsp; -->
                            <div class="col d-flex align-items-center justify-content-around">
                                <div>
                                    <label for="start">From:</label>
                                </div>
                                <div>
                                    <input class="form-control date" name="fromdate" [max]="currentdate" (keypress)="KeyPressDate($event)"
                                        [(ngModel)]="wbsearchParameter.fromDateFilter" type="date" id="start" value="Date">
                                </div>
                            </div>
                            <div class="col d-flex align-items-center justify-content-around">
                                <div>
                                    <label for="start" class="to-label">To:</label>
                                </div>
                                <div>
                                    <input class="form-control date" name="todate" [max]="currentdate" (keypress)="KeyPressDate($event)"
                                    [(ngModel)]="wbsearchParameter.toDateFilter" type="date" id="start" value="Date">
                                </div>
                            </div>
                            <div class="col-md-1 d-flex justify-content-evenly">
                                <button class="btn btn-primary go-btn" (click)="GetTransactionByVrn(pagination.searchtext)">
                                    <span class="material-icons-round">search</span>
                                </button>
                                <button class="btn btn-success go-btn mx-1" (click)="GetAllVehilceTransactionforExcel()">
                                    <span class="material-icons-round">download</span>
                                </button>
                            </div>

                        </div>
                    </div>
                    </div>
                    <div class="table-list custom-table">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover mx-auto transaction">
                                <thead class="table-head">
                                    <tr>
                                        <th (click)="sort('vrn')">VRN
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('type')">Trip Type
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='type'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('vehicleTransactionCode')">Trip No
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vehicleTransactionCode'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('weighbridgeName')">Weighbridge
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='weighbridgeName'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('actualWeight')">Actual Wt(Kg)
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='actualWeight'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('expectedWeight')">Expected Wt(Kg)
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='expectedWeight'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('expectedWeight')">Net Wt(Kg)
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='expectedWeight'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('status')">Status
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='status'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('weighmentType')">Weighment Type
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='weighmentType'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('isCrossCheckEnabled')">Cross Check
                                            <span class="glyphicon glyphicon-chevron-up"
                                                *ngIf="key =='isCrossCheckEnabled'" [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('transactionDateTime')"> Weighment Date
                                            <span class="glyphicon glyphicon-chevron-up"
                                                *ngIf="key =='transactionDateTime'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th > Weighment TAT </th>
                                        <th (click)="sort('transactionDateTime')" class="text-center"> Images
                                            <span class="glyphicon glyphicon-chevron-up"
                                                *ngIf="key =='transactionDateTime'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>

                                        </th>
                                        <th (click)="sort('transactionDateTime')" class="text-center"> Weighment Slip
                                            <span class="glyphicon glyphicon-chevron-up"
                                                *ngIf="key =='transactionDateTime'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let wb of transactionResponse  | paginate: { itemsPerPage: pagination.pageSize, currentPage: pagination.page,totalItems:pagination.count }; let i = index">
                                        <td><a [routerLink]="['/current-transaction', wb.vehicleTransactionCode]">
                                                {{wb.vrn}} </a></td>
                                        <td>
                                            <span *ngIf="wb.type == 1">Outbound</span>
                                            <span *ngIf="wb.type == 2">Inbound</span>
                                            <span *ngIf="wb.type == 4">Inplant</span>
                                        </td>
                                        <td>{{wb.vehicleTransactionCode}}</td>
                                        <td>{{wb.weighbridgeName}}</td>
                                        <td>{{wb.actualWeight}}</td>
                                        <td>{{wb.expectedWeight}}</td>
                                        <td>
                                            <span *ngIf="wb.weighmentType  == 'GROSS' && wb.status == 'Completed'">{{wb.actualWeight - wb.tareWeight}}</span>
                                        </td>
                                        <td>{{wb.status}}</td>
                                        <td>{{wb.weighmentType }}</td>
                                        <td>
                                            <span *ngIf="wb.isCrossCheckEnabled  ==true ">Yes</span>
                                            <span *ngIf="wb.isCrossCheckEnabled  ==false ">No</span>
                                        </td>
                                        <td>{{wb.transactionDateTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                                        <td>{{wb.weighmentTAT}}</td>
                                        <td style="text-align: center;">
                                            <span class="material-icons collections" (click)="ViewImage(ImagePopup,wb)">
                                                collections </span>
                                        </td>
                                        <td class="text-center">
                                            <span class="material-icons visibility" (click)="OpenPopup(wb,pdfPopup)">
                                                visibility </span>
                                            <span class="material-icons download" (click)="downloadAsPDF(wb, true)">
                                                download_for_offline </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="pagination d-flex align-items-center">
                            <div class="col-md-6">
                                Item per page
                                <select (change)="OnPageSizeChange($event)" class="paginationcount">
                                    <option *ngFor="let size of pagination.pageSizes">
                                        {{size}}
                                    </option>
                                </select>
                                <!-- <span> showing {{pagination.filterRecordCount}} record out of {{pagination.count}} </span> -->
                                <span> Total Records:{{pagination.count}} </span>
                            </div>
                            <div class="col-md-6 text-end">
                                <div class="justify-content-end">
                                    <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
                                        maxSize="9" screenReaderCurrentLabel="You're on page"
                                        (pageChange)="OnPageChange($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <ng-template #ImagePopup let-modal class="modal">
        <div class="modal-content custom-modal">
            <div class="modal-header">
                <h4 class="modal-title mb-0">Vehicle Image</h4>
                <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span class="material-icons-sharp">close</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <div *ngIf="ImageList == null || ImageList.length == 0 ">
                <h3 class="text-center">No Image Found</h3>
            </div>
            <div *ngIf="ImageList.length > 0 ">
                <div
                    class=" row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 g-4  px-4 pt-3 mt-0">
                    <div class="col mt-0 px-2 pb-3" *ngFor="let test of ImageList">
                        <div class="card w-100 ">
                            <img class="card-img-top" src={{test.imageUrl}} alt="Card image cap">
                        </div>
                        <div class="card-footer text-center">
                            <small class="  py-2" style="font-size: larger;"> {{test.imageName}} </small>
                        </div>
                    </div>
                </div>
            </div> -->
                <div id="print-section"  style="content-visibility: hidden;"
                    class=" row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 g-4  px-4 pt-3 mt-0">
                    <!-- <div *ngFor="let info of imageInfo; let index = index;"  class=" col mt-0 px-2 pb-3"> -->
                        <table class="table table-striped">
                            <thead>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="customfontSize">VRN :</td>
                                    <td class="customfontSize">{{imageInfo.vrn}}</td>
                                    <td class=" customfontSize">ActualWeight : </td>
                                    <td class="customfontSize">{{imageInfo.actualWeight}} :</td>
                                    <td class=" customfontSize">Transaction Time :</td>
                                    <td class="customfontSize">{{imageInfo.transactionDateTime}}</td>
                                    <td class=" customfontSize">WeighBridge Name :</td>
                                    <td class="customfontSize">{{imageInfo.weighBridgeName}}</td>
                                    <td class=" customfontSize">Weighment Type :</td>
                                    <td class="customfontSize">{{imageInfo.weighmentType}}</td>
                                    <td class=" customfontSize">Product Name :</td>
                                    <td class="customfontSize">{{imageInfo.productName}}</td>
                                    <td class=" customfontSize">Product Type :</td>
                                    <td class="customfontSize">{{imageInfo.productType}}</td>
                                </tr>
                            </tbody>
                        </table>
                    <!-- </div> -->
                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 g-4  px-4 pt-3 mt-0">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div *ngFor="let image of ImageList; let index = index;" class=" col mt-0 px-2 pb-3" key="index">
                                            <div class="card" style="width:40%" >
                                                <img [src]="image.imageUrl" [alt]="image.alt" style="cursor: pointer; "
                                                    (click)="imageClick(index)" />
                                            </div>
                                            <div class="card-footer text-center mt-2">
                                                <small class="py-2">
                                                    {{image.imageName}} </small>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="ImageList.length > 0">
                    <div
                        class=" row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 g-4  px-4 pt-3 mt-0">
                        <div *ngFor="let image of ImageList; let index = index;" class=" col mt-0 px-2 pb-3"
                            key="index">
                            <div class="card w-100 ">
                                <img [src]="image.imageUrl" [alt]="image.alt" style="cursor: pointer; "
                                    (click)="imageClick(index)" />
                            </div>
                            <div class="card-footer text-center mt-2">
                                <small class="py-2">
                                    {{image.imageName}} </small>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ImageList.length > 0">
                        <div class="row">
                            <div class="offset-md-8 col-md-4">
                                <button class="btn btn-primary mx-3" style="float: right;" (click)="downloadAllImage()">
                                    Download
                                </button>
                                <button printTitle="Weighbridge Details" class="btn btn-primary mx-3"
                                    style="float: right;"  printSectionId="print-section"  [useExistingCss]="true"
                                    ngxPrint>print</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="ImageList.length <= 0">
                    <h3 class="text-center" style="margin-bottom: 0px;color: red;"> No Image found this trip.
                    </h3>
                </div>
            </div>
        </div>
    </ng-template>

    <p-galleria [(value)]="ImageList" [(visible)]="displayCustom" [(activeIndex)]="activeIndex"
        [responsiveOptions]="responsiveOptions" [containerStyle]="{'width': '75vw'}" [circular]="true"
        [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
        <ng-template pTemplate="item" let-item>
            <img [src]="item.imageUrl" style="width: 100%; display: block;" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="grid grid-nogutter justify-content-center">
                <img [src]="item.imageUrl" style="display: block;" />
            </div>
        </ng-template>
    </p-galleria>


    <div class="container my-3 px-3" style="content-visibility: hidden;">
        <div id="htmlData" *ngIf=" PDFWeighBridge.length > 0 ">
            <div class=" px-5" style="border: 1px solid;">
                <div class="row align-self-center py-4">
                    <div class="col-sm-4">
                        <img alt="" style="margin-left: 15px;" height="30" src="../../../assets/images/my-logo.png" *ngIf="plantInfo == 'MYCOMPANY'">
                        <img alt="" style="margin-left: 15px;" height="30" src="../../../assets/images/jsw-logo.png" *ngIf="plantInfo == 'JSW'">
                        <img alt="" style="margin-left: 15px;" height="30" src="../../../assets/images/starNewLogo.PNG" *ngIf="plantInfo == 'STAR'">
                    </div>
                    <div class="col-sm-4"
                        style="text-align: center;  font-size: x-large;margin-bottom: 0px;text-transform: uppercase;">
                        <h2 style="margin-bottom: 0px;font-size: x-large;" *ngIf="plantInfo == 'MYCOMPANY'">My Company</h2>
                        <h2 style="margin-bottom: 0px;font-size: x-large;" *ngIf="plantInfo == 'JSW'">JSW Steel Limited</h2>
                        <h2 style="margin-bottom: 0px;font-size: x-large;" *ngIf="plantInfo == 'STAR'">Star Cement</h2>
                        <h4 style="margin-bottom: 0px;">{{PopViewData[0].plantName}}</h4>
                    </div>
                    <div class="col-sm-4 text-end">
                        <!-- <label for="" style="    margin-right: 19px;
                        font-size: small;
                        font-weight: 700;"> Plant Name: {{PDFWeighBridge[0].plantName}} </label> -->
                        <img alt="" style="margin-left: 15px;" height="30" src="../../../assets/images/urge-truck.png">
                    </div>
                </div>
                <div class="row" *ngIf=" PDFWeighBridge.length > 0 ">
                    <div class="col-sm-12 justify-content-center">
                        <div class="table-responsive " style=" overflow: hidden;">
                            <table class="table table-striped">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>D:\Kemar Repo\UTVijaynagar
                                        <td class=" customfontSize">VRN </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].vrn}}</td>
                                    </tr>
                                    <tr *ngIf="PDFWeighBridge[0].type == 1">
                                        <td class="customfontSize">Vehicle Type </td>
                                        <td class="customfontSize">Outbound
                                        </td>
                                    </tr>
                                    <tr *ngIf="PDFWeighBridge[0].type == 2">
                                        <td class="customfontSize">Vehicle Type </td>
                                        <td class="customfontSize">Inbound
                                        </td>
                                    </tr>
                                    <tr *ngIf="PDFWeighBridge[0].type == 4">
                                        <td class="customfontSize">Vehicle Type </td>
                                        <td class="customfontSize">Inplant
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="customfontSize"> Weighbridge Name </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].weighbridgeName}}</td>
                                    </tr>
                                    <tr *ngIf="PDFWeighBridge[0].weighmentType == 'TARE' && PDFWeighBridge[0].type != 2 ">
                                        <td class="customfontSize">Expected Weight (Kg) </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].expectedWeight}}</td>
                                    </tr>
                                    <tr>
                                        <td *ngIf="PDFWeighBridge[0].type == 1 || PDFWeighBridge[0].type == 4 " class="customfontSize">Tare Weight (Kg) </td>
                                        <td *ngIf="PDFWeighBridge[0].type == 2 && PDFWeighBridge[0].weighmentType == 'GROSS' " class="customfontSize">Gross Weight (Kg) </td>
                                        <td *ngIf="PDFWeighBridge[0].type == 2 && PDFWeighBridge[0].weighmentType == 'TARE' " class="customfontSize">Tare Weight (Kg) </td>
                                        <td *ngIf="PDFWeighBridge[0].weighmentType == 'TARE' &&  (PDFWeighBridge[0].type == 1 || PDFWeighBridge[0].type == 4)" class="customfontSize">{{PDFWeighBridge[0].actualWeight}}</td>
                                        <td *ngIf="PDFWeighBridge[0].weighmentType == 'GROSS' && (PDFWeighBridge[0].type == 1 || PDFWeighBridge[0].type == 4)" class="customfontSize">{{PDFWeighBridge[0].tareWeight}}</td>
                                        <td *ngIf="PDFWeighBridge[0].weighmentType == 'GROSS' && PDFWeighBridge[0].type == 2" class="customfontSize">{{PDFWeighBridge[0].actualWeight}}</td>
                                        <td *ngIf="PDFWeighBridge[0].weighmentType == 'TARE' && PDFWeighBridge[0].type == 2" class="customfontSize">{{PDFWeighBridge[0].tareWeight}}</td>
                                    </tr>

                                    <!-- <tr *ngIf="PDFWeighBridge[0].weighmentType == 'GROSS' || (PDFWeighBridge[0].weighmentType == 'TARE' && PDFWeighBridge[0].type == 2 )">
                                        <td class="customfontSize">Product Weight (Kg) </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].productWeight}}</td>
                                    </tr> -->
                                    <tr *ngIf=" (PDFWeighBridge[0].weighmentType == 'GROSS' &&  PDFWeighBridge[0].type != 2 ) || (PDFWeighBridge[0].weighmentType == 'TARE' && PDFWeighBridge[0].type == 2 ) ">
                                        <td class="customfontSize">Gross Weight (Kg) </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].grossWeight}}</td>
                                    </tr>
                                    <!-- <tr *ngIf="PDFWeighBridge[0].weighmentType == 'TARE'">
                                        <td class="customfontSize">Expected Weight (Kg) </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].expectedWeight}}</td>
                                    </tr>
                                    <tr>
                                        <td class="customfontSize">Tare Weight (Kg) </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].tareWeight}}</td>
                                    </tr>

                                    <tr *ngIf="PDFWeighBridge[0].weighmentType == 'GROSS'">
                                        <td class="customfontSize">Product Weight (Kg) </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].productWeight}}</td>
                                    </tr>
                                    <tr *ngIf="PDFWeighBridge[0].weighmentType == 'GROSS'">
                                        <td class="customfontSize">Gross Weight (Kg) </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].grossWeight}}</td>
                                    </tr> -->
                                    <tr>
                                        <td class="customfontSize"> Weighment Date </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].transactionDateTime | date:
                                            'dd-MM-yyyy HH:mm'}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="PDFWeighBridge[0].type == 4">
                                        <td class="customfontSize"> Trip Rouds </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].of }}/{{PDFWeighBridge[0].outOf
                                            }}
                                        </td>
                                    </tr>
                                    <!-- <tr *ngIf="PDFWeighBridge[0].weighmentType == 'GROSS'">
                                        <td class="customfontSize"> Product Name </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].productName }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="PDFWeighBridge[0].weighmentType == 'GROSS'">
                                        <td class="customfontSize"> Product Type </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].productType }}
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td class="customfontSize"> Document No </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].shipmentNo}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="customfontSize"> Vehicle Trip No </td>
                                        <td class="customfontSize">{{PDFWeighBridge[0].vehicleTransactionCode}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div *ngIf="ImageList.length > 0">
                            <div
                                class=" row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 g-4  px-4 pt-3 mt-0">
                                <div *ngFor="let image of ImageList; let index = index;" class=" col mt-0  pb-3"
                                    key="index">
                                    <div class="card w-100 ">
                                        <img [src]="image.imageUrl" [alt]="image.alt" style="cursor: pointer; " />
                                    </div>
                                    <div class="card-footer text-center ">
                                        <small class="  py-2" style="font-size: larger;">
                                            {{image.imageName}} </small>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="offset-sm-4 col-sm-4 text-center" style="font-size: x-large;">
                                <p class="text-secondary-d1 text-105">Thank You !</p>
                            </div>
                            <div class="col-sm-4 action-buttons d-block mt-1">
                                <span style="float: right;">
                                    <span style="font-size: small;
                                    font-style: italic;"> Date : {{todayDate |date:
                                        'dd-MM-yyyy HH:mm'}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <ng-template #pdfPopup let-modal class="modal">
        <div class="modal-content custom-modal">
            <div class="modal-header">
                <h4 class="modal-title mb-0"></h4>
                <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span class="material-icons-sharp">close</span>
                </button>
            </div>
            <div class="modal-body">
                <span>
                    <div *ngIf=" PopViewData.length > 0 " style="border: 1px solid Black ; padding:19px ; ">
                        <div class="d-flex align-self-center py-3">
                            <div class="col-sm-4">
                                <img alt="" style="margin-left: 15px;" height="30" src="../../../assets/images/my-logo.png" *ngIf="plantInfo == 'MYCOMPANY'">
                        <img alt="" style="margin-left: 15px;" height="30" src="../../../assets/images/jsw-logo.png" *ngIf="plantInfo == 'JSW'">
                        <img alt="" style="margin-left: 15px;" height="30" src="../../../assets/images/starNewLogo.PNG" *ngIf="plantInfo == 'STAR'">
                            </div>
                            <div class="col-sm-4"
                                style="text-align: center;  font-size: x-large;margin-bottom: 0px;text-transform: uppercase;">
                                <!-- <h1 style="margin-bottom: 0px;    font-size: x-large;
                                font-weight: 500;">Weighment Slip</h1> -->
                                <h2 style="margin-bottom: 0px;font-size: x-large;" *ngIf="plantInfo == 'MYCOMPANY'">My Company</h2>
                                <h2 style="margin-bottom: 0px;font-size: x-large;" *ngIf="plantInfo == 'JSW'">JSW Steel Limited</h2>
                                <h2 style="margin-bottom: 0px;font-size: x-large;" *ngIf="plantInfo == 'STAR'">Star Cement</h2>
                        <h4 style="margin-bottom: 0px;">{{PopViewData[0].plantName}}</h4>
                            </div>
                            <div class="col-sm-4 text-end">
                                <!-- <label for="" style="    margin-right: 19px;
                                font-size: small;
                                font-weight: 700;"> Plant Name: {{PopViewData[0].plantName}} </label> -->

                                <img alt="" style="margin-left: 15px;" height="30" src="../../../assets/images/urge-truck.png">
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-sm-12 justify-content-center" *ngIf=" PopViewData.length > 0 ">
                                <div class="table-responsive sm-table vrScrollbar">
                                    <table class="table table-striped">
                                        <thead>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="customfontSize">VRN </td>
                                                <td class="customfontSize">{{PopViewData[0].vrn}}</td>
                                            </tr>
                                            <tr *ngIf="PopViewData[0].type == 1">
                                                <td class="customfontSize">Vehicle Type </td>
                                                <td class="customfontSize">Outbound
                                                </td>
                                            </tr>
                                            <tr *ngIf="PopViewData[0].type == 2">
                                                <td class="customfontSize">Vehicle Type </td>
                                                <td class="customfontSize">Inbound
                                                </td>
                                            </tr>
                                            <tr *ngIf="PopViewData[0].type == 4">
                                                <td class="customfontSize">Vehicle Type </td>
                                                <td class="customfontSize">Inplant
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="customfontSize"> Weighbridge Name </td>
                                                <td class="customfontSize">{{PopViewData[0].weighbridgeName}}</td>
                                            </tr>
                                            <tr *ngIf="PopViewData[0].weighmentType == 'TARE' && PopViewData[0].type != 2 ">
                                                <td class="customfontSize">Expected Weight (Kg) </td>
                                                <td class="customfontSize">{{PopViewData[0].expectedWeight}}</td>
                                            </tr>
                                            <tr>
                                                <td *ngIf="PopViewData[0].type == 1 || PopViewData[0].type == 4 " class="customfontSize">Tare Weight (Kg) </td>
                                                <td *ngIf="PopViewData[0].type == 2 && PopViewData[0].weighmentType == 'GROSS' " class="customfontSize">Gross Weight (Kg) </td>
                                                <td *ngIf="PopViewData[0].type == 2 && PopViewData[0].weighmentType == 'TARE' " class="customfontSize">Tare Weight (Kg) </td>
                                                <td *ngIf="PopViewData[0].weighmentType == 'TARE' &&  (PopViewData[0].type == 1 || PopViewData[0].type == 4)" class="customfontSize">{{PopViewData[0].actualWeight}}</td>
                                                <td *ngIf="PopViewData[0].weighmentType == 'GROSS' && (PopViewData[0].type == 1 || PopViewData[0].type == 4)" class="customfontSize">{{PopViewData[0].tareWeight}}</td>
                                                <td *ngIf="PopViewData[0].weighmentType == 'GROSS' && PopViewData[0].type == 2" class="customfontSize">{{PopViewData[0].actualWeight}}</td>
                                                <td *ngIf="PopViewData[0].weighmentType == 'TARE' && PopViewData[0].type == 2" class="customfontSize">{{PopViewData[0].tareWeight}}</td>
                                            </tr>

                                            <!-- <tr *ngIf="PopViewData[0].weighmentType == 'GROSS' || (PopViewData[0].weighmentType == 'TARE' && PopViewData[0].type == 2 )">
                                                <td class="customfontSize">Product Weight (Kg) </td>
                                                <td class="customfontSize">{{PopViewData[0].productWeight}}</td>
                                            </tr> -->
                                            <tr *ngIf=" (PopViewData[0].weighmentType == 'GROSS' &&  PopViewData[0].type != 2 ) || (PopViewData[0].weighmentType == 'TARE' && PopViewData[0].type == 2 ) ">
                                                <td class="customfontSize">Gross Weight (Kg) </td>
                                                <td class="customfontSize">{{PopViewData[0].grossWeight}}</td>
                                            </tr>
                                            <tr>
                                                <td class="customfontSize"> Weighment Date </td>
                                                <td class="customfontSize">{{PopViewData[0].transactionDateTime | date:
                                                    'dd-MM-yyyy HH:mm'}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="PopViewData[0].type == 4">
                                                <td class="customfontSize"> Trip Rounds </td>
                                                <td class="customfontSize">{{PopViewData[0].of }}/{{PopViewData[0].outOf}}
                                                </td>
                                            </tr>
                                            <!-- <tr *ngIf="PopViewData[0].weighmentType == 'GROSS'">
                                                <td class="customfontSize"> Product Name </td>
                                                <td class="customfontSize">{{PopViewData[0].productName }}
                                                </td>
                                            </tr>
                                            <tr *ngIf="PopViewData[0].weighmentType == 'GROSS'">
                                                <td class="customfontSize"> Product Type </td>
                                                <td class="customfontSize">{{PopViewData[0].productType }}
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td class="customfontSize"> Document No </td>
                                                <td class="customfontSize">{{PopViewData[0].shipmentNo}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="customfontSize"> Vehicle Trip No </td>
                                                <td class="customfontSize">{{PopViewData[0].vehicleTransactionCode}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- <div *ngIf="ImageList.length > 0">
                            <div
                                class=" row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 g-4  px-4 pt-3 mt-0">
                                <div *ngFor="let image of ImageList; let index = index;" class=" col mt-0  pb-3"
                                    key="index">
                                    <div class="card w-100 ">
                                        <img [src]="image.imageUrl" [alt]="image.alt" style="cursor: pointer; " />
                                    </div>
                                    <div class="card-footer text-center ">
                                        <small class="  py-2" style="font-size: larger;">
                                            {{image.imageName}} </small>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                                <div class="row">
                                    <div class="offset-sm-4 col-md-4" style="display: flex; justify-content: center;">
                                        <h1 class="text-secondary-d1 text-105">Thank You !</h1>
                                    </div>
                                    <div class="col-sm-4 text-end ">
                                        <div class="action-buttons d-block mt-1">
                                            <span>
                                                <span style="font-size: x-small;
                                                font-style: italic;"> Printed On: {{todayDate
                                                    |date:
                                                    'dd-MM-yyyy HH:mm'}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="PopViewData.length > 0 ">
                        <div class="text-center mt-3">
                            <button class="btn btn-primary "
                                (click)="downloadAsPDF(PopViewData, false)">Download</button>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </ng-template>
