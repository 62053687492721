<app-header></app-header>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Transporter </h4>
          </div>
          <div class="col-sm-8 text-end">
            <label class="me-2">
              <select [(ngModel)]="selectedColumn" (change)="DropDownChnange($event.target.value)" class="form-control dropdown-select">
                <option selected value="undefined"> -- All -- </option>
                <option value="1">Name</option>
                <option value="2">VenderCode</option>
                <option value="3">Mobile No</option>
              </select>
            </label>
            <div class="input-append me-0">
              <input class="form-control" type="search" style="padding-left:10px" name="search"
                placeholder="Search by Name" (keydown)="EnterBtn($event)" [(ngModel)]="filter"
                [disabled]="IsEnableSearchBox">
            </div>
            <button class="btn btn-primary go-btn mx-2" (click)="SearchFilter()" matTooltip="Search"
              matTooltipClass="danger-tooltip" [disabled]="IsEnableSearchBox">
              <fa-icon [icon]="faSearch"></fa-icon>
            </button>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Transporter"
              matTooltipClass="danger-tooltip" *ngIf="userAccessData[0]?.canCreate == true">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>

        <div class="table-list custom-table"  >
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto tbl-formating">
              <thead class="table-head">
                <tr>
                  <th class=""> Sr No.</th>
                  <th (click)="sort('name')">Name
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <th (click)="sort('vendorCode')">Vendor Code
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <!-- <th (click)="sort('plantCode')">Plant Code
                    <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th> -->

                  <th (click)="sort('address')">Address
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <th (click)="sort('mobileNo')">Mobile No.
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <th (click)="sort('telNo')">Telephone No.
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <th (click)="sort('email')">Email
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <th (click)="sort('contactPersonName')">Con Per Name
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <th (click)="sort('contactPersonNumber')">Con Per No.
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <th (click)="sort('isBlackListed')">Blacklisted
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>

                  <th (click)="sort('isActive')">Status
                    <!-- <span class="glyphicon glyphicon-chevron-up" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span> -->
                  </th>
                  <th *ngIf="userAccessData[0]?.canUpdate ==true"  class="action-key text-center " style="width: 4%!important;">Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <input type="hidden" />
                <tr
                  *ngFor="let transporter of transporterMaster | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecords }; let i = index">
                  <td class="text-center">{{ i +1 + pageSize*(currentPage-1) }}</td>
                  <td class="left">{{transporter.name}}</td>
                  <td class="left">{{transporter.vendorCode}}</td>
                  <!-- <td class="left">{{transporter.plantCode}}</td> -->
                  <td class="left">{{transporter.address}}</td>
                  <td>{{transporter.mobileNo}}</td>
                  <td>{{transporter.telNo}}</td>
                  <td class="left">{{transporter.email}}</td>
                  <td class="left">{{transporter.contactPersonName}}</td>
                  <td>{{transporter.contactPersonNumber}}</td>
                  <td>
                    <span *ngIf="transporter.isBlackListed  ==true ">YES</span>
                    <span *ngIf="transporter.isBlackListed  ==false ">NO</span>
                  </td>
                  <td>
                    <span *ngIf="transporter.isActive  ==true ">Active</span>
                    <span *ngIf="transporter.isActive  ==false ">Inactive</span>
                  </td>
                  <td class="text-center" *ngIf="userAccessData[0]?.canUpdate ==true">
                    <span class="edit-btn-icon" type="button" matTooltip="Edit Transporter"
                      matTooltipClass="danger-tooltip" (click)="editTransporter(edit, transporter) ">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <div class="row">
            <div class="col-md-6">
              Item per page
              <select (change)="OnPageSizeChange(pageSize = $event.target.value)" class="paginationcount">
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
              <span> Total Records : {{totalRecords}} </span>
            </div>
            <div class="col-md-6 text-end">
              <div class="pagination justify-content-end">
                <pagination-controls (pageChange)="getAllTransportersWithPagination(currentPage = $event)">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="closePopUp()">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <form autocomplete="off" [formGroup]="form">
            <div class="form-group">
              <!-- <div class="form-group ml-3">
                    <input type="hidden" minlength="3" maxlength="30" class="form-control" name="mileStoneId"
                      value="{{milestone.mileStoneId}}" />
                  </div> -->
              <div class="form-group row mb-0">
                <div class="col-sm-6">
                  <label for="name">Name <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name"
                    [(ngModel)]="transporter.name" />
                  <div *ngIf="submitted && f.name.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.name.errors.required">
                      <small>Name is required.</small>
                    </div>
                    <div *ngIf="f.name.errors.minlength">
                      <small>
                        <sup>*</sup> Name must be at least 4 characters.
                      </small>
                    </div>
                    <div *ngIf="f.name.errors.maxlength">
                      <small> <sup>*</sup>Name must not exceed 30 characters.</small>
                    </div>
                    <div *ngIf="f.name.hasError('whitespace')"></div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="vendorCode">Vendor Code <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.vendorCode.errors }" formControlName="vendorCode"
                    [(ngModel)]="transporter.vendorCode" />
                  <div *ngIf="submitted && f.vendorCode.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.vendorCode.errors.required">
                      <small>Vendor Code is required.</small>
                    </div>
                    <div *ngIf="f.vendorCode.hasError('whitespace')"></div>
                    <!-- <div *ngIf="f.vendorCode.errors.minlength">
                            <small>
                              <sup>*</sup> Vendor Code must be at least 4 characters.
                            </small>
                          </div>
                          <div *ngIf="f.vendorCode.errors.maxlength">
                           <small> <sup>*</sup>Vendor Code must not exceed 30 characters.</small>
                          </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="col-sm-6">
                  <label for="plantCode">Plant Code <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.plantCode.errors }" formControlName="plantCode"
                    [(ngModel)]="transporter.plantCode" />
                  <div *ngIf="submitted && f.plantCode.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.plantCode.errors.required">
                      <small>Plant Code is required.</small>
                    </div>
                    <div *ngIf="f.plantCode.hasError('whitespace')"></div>
                    <!-- <div *ngIf="f.plantCode.errors.minlength">
                          <small>
                            <sup>*</sup> Plant Code must be at least 4 characters.
                          </small>
                        </div>
                        <div *ngIf="f.plantCode.errors.maxlength">
                         <small> <sup>*</sup>Plant Code must not exceed 30 characters.</small>
                        </div> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="address">Address <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address"
                    [(ngModel)]="transporter.address" />
                  <div *ngIf="submitted && f.address.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.address.errors.required">
                      <small>Address is required.</small>
                    </div>
                    <!-- <div *ngIf="f.address.errors.minlength">
                            <small>
                              <sup>*</sup> Address must be at least 4 characters.
                            </small>
                          </div>
                          <div *ngIf="f.address.errors.maxlength">
                          <small> <sup>*</sup>Address must not exceed 30 characters.</small>
                          </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="mobileNo">Mobile No. <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" " (keypress)="keyPress($event)"
                    minlength=10 maxlength=10 [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }"
                    formControlName="mobileNo" [(ngModel)]="transporter.mobileNo" />
                  <div *ngIf="submitted && f.mobileNo.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.mobileNo.errors.required">
                      <small>Mobile No. is required.</small>
                    </div>
                    <div *ngIf="f.mobileNo.errors.minlength">
                      <small>
                        <sup>*</sup> Mobile No. must be at least 10 No.
                      </small>
                    </div>
                    <div *ngIf="f.mobileNo.errors.maxlength">
                      <small> <sup>*</sup>Mobile No. must not exceed 10 No.</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="telNo">Telephone No. <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    (keypress)="keyPresstelephone($event)" minlength=10 maxlength=10
                    [ngClass]="{ 'is-invalid': submitted && f.telNo.errors }" formControlName="telNo"
                    [(ngModel)]="transporter.telNo" />
                  <div *ngIf="submitted && f.telNo.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.telNo.errors.required">
                      <small>Telephone No. is required.</small>
                    </div>
                    <div *ngIf="f.telNo.errors.minlength">
                      <small>
                        <sup>*</sup> Telephone No. must be at least 10 No.
                      </small>
                    </div>
                    <div *ngIf="f.telNo.errors.maxlength">
                      <small> <sup>*</sup>Telephone No. must not exceed 11 No.</small>
                    </div>
                    <!-- <div *ngIf="f.telNo.errors.minlength">
                          <small>
                            <sup>*</sup> Telephone No. must be at least 4 characters.
                          </small>
                        </div>
                        <div *ngIf="f.telNo.errors.maxlength">
                        <small> <sup>*</sup>Telephone No. must not exceed 30 characters.</small>
                        </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="email">Email <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
                    [(ngModel)]="transporter.email" />
                  <div *ngIf="submitted && f.email.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.email.errors.required">
                      <small>Email is required.</small>
                    </div>
                    <div *ngIf="f.email.errors?.email">
                      <small class="text-danger">Email address is invalid</small>
                    </div>
                    <!-- <div *ngIf="f.email.errors.minlength">
                          <small>
                            <sup>*</sup> Email must be at least 4 characters.
                          </small>
                        </div>
                        <div *ngIf="f.email.errors.maxlength">
                         <small> <sup>*</sup>Email must not exceed 30 characters.</small>
                        </div> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="contactPersonName">Contact Person Name <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.contactPersonName.errors }"
                    formControlName="contactPersonName" [(ngModel)]="transporter.contactPersonName" />
                  <div *ngIf="submitted && f.contactPersonName.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.contactPersonName.errors.required">
                      <small>Contact Person Name is required.</small>
                    </div>
                    <div *ngIf="f.contactPersonName.errors.pattern">
                      <small>Contact Person Name should contain only characters</small>
                    </div>
                    <!-- <div *ngIf="f.contactPersonName.errors.minlength">
                            <small>
                              <sup>*</sup> Contact Person Name must be at least 4 characters.
                            </small>
                          </div>
                          <div *ngIf="f.contactPersonName.errors.maxlength">
                          <small> <sup>*</sup>Contact Person Name must not exceed 30 characters.</small>
                          </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="contactPersonNumber">Contact Person No. <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" " (keypress)="keyPress($event)"
                    minlength=10 maxlength=10 [ngClass]="{ 'is-invalid': submitted && f.contactPersonNumber.errors }"
                    formControlName="contactPersonNumber" [(ngModel)]="transporter.contactPersonNumber" />
                  <div *ngIf="submitted && f.contactPersonNumber.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.contactPersonNumber.errors.required">
                      <small>Contact Person No. is required.</small>
                    </div>
                    <div *ngIf="f.contactPersonNumber.errors.minlength">
                      <small>
                        <sup>*</sup> Contact Person No. must be at least 10 Numbers.
                      </small>
                    </div>
                    <div *ngIf="f.contactPersonNumber.errors.maxlength">
                      <small> <sup>*</sup>Contact Person No. must not exceed 11 Numbers.</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="blacklistShow">
                  <label for="isBlackListed">BlackListed</label>
                  <select name="isBlackListed" (change)="ChangedBlacklist($event.target.value)"
                    [(ngModel)]="transporter.isBlackListed" class="form-control form-select"
                    formControlName="isBlackListed" [ngClass]="{ 'is-invalid': submitted && f.isBlackListed.errors }">
                    <!-- <option hidden Value='' disabled>--Blacklisted status--</option> -->
                    <option hidden Value='' disabled>--Choose Option--</option>
                    <option [value]="true">Yes</option>
                    <option [value]="false">No</option>

                  </select>
                </div>
              </div>
              <div class="select-radio" [hidden]="activebuttonShow">
                <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="transporter.isActive"
                  formControlName="isActive">
                <label for="active" class="radio-label">Active</label>

                <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="transporter.isActive"
                  formControlName="isActive">
                <label for="inactive" class="radio-label">Inactive</label>
              </div>
              <div class="form-group row mb-0 mt-2" [hidden]="blaclistingChange">
                <div class="col-sm-6" *ngIf="Blacklistingreason">
                  <label for="ReasonRequired">Reason<sup>*</sup></label>
                  <select name="ReasonRequired" [(ngModel)]="transporter.reason" class="form-control form-select"
                    (change)="keyPressBlackListing($event.target.value)" formControlName="reason"
                    [ngClass]="{ 'is-invalid': submitted && ReasonRequired }">
                    <option hidden Value='' disabled>--Choose Option--</option>
                    <option *ngFor="let transporter of BlacklistingReason" [ngValue]="transporter.reason">
                      {{transporter.reason}}</option>
                  </select>
                  <div *ngIf="submitted && ReasonRequired" class="text-danger">
                    <small> Reason for blacklisting is required.</small>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="blaclistingIncidentDate">
                  <label for="incidentDateTime">Incident Date <sup>*</sup></label>
                  <input type="date" class="form-control" placeholder="" value=" " name="incidentDateTime"
                    [ngClass]="{ 'is-invalid': submitted && IncidentRequired }" formControlName="incidentDateTime"
                    [(ngModel)]="transporter.incidentDateTime" (change)="keyPressIncidentDate($event.target.value)"
                    (keypress)="keyonDate($event)" />
                  <div *ngIf="submitted && IncidentRequired" class="invalid-feedback">
                    <div *ngIf="IncidentRequired">Date is required.
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" *ngIf="blaclistingDescription">
                  <label for="DescriptionRequired">Description<sup>*</sup></label>
                  <textarea type="text" class="form-control" (keyup)="keyPressDescition($event.target.value.trim())"
                    placeholder="" value=" " name="DescriptionRequired"
                    [ngClass]="{ 'is-invalid': submitted && DescriptionRequired }" formControlName="description"
                    [(ngModel)]="transporter.description"></textarea>
                  <div *ngIf="submitted && DescriptionRequired" class="text-danger">
                    <div *ngIf="DescriptionRequired">
                      <small>Description is required.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="closePopUp()">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-success"
        (click)="registerTransporter(transporter)">Save</button>
    </div>
  </div>
</ng-template>