import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LocationMappingResponse } from 'src/app/models/locationmapping';
import {
  faEdit,
  faPlus,
  faSort,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  MaxLengthValidator,
  Validators,
} from '@angular/forms';
import { validateBasis } from '@angular/flex-layout';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-ctusers',
  templateUrl: './ctusers.component.html',
  styleUrls: ['./ctusers.component.scss'],
})
export class CTUsersComponent implements OnInit {
  CTUserData: any[];
  public userAccessData: UserScreenAccesData;
  title: string = 'Add New User';
  Save: string = 'Save';
  faEdit = faEdit;
  faUser = faUser;
  faPlus = faPlus;
  faSort = faSort;
  submitted = false;
  resetbuttonShow: boolean = true;
  activebuttonShow: boolean = false;
  ctUserForm: FormGroup;
  allCTUserList: any[];
  AllUsers: any[];
  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################

  constructor(
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,

    private spinner: NgxSpinnerService,
    private api:DataService
  ) {}

  ngOnInit(): void {
    this.Reset();
    this.getAllCTUser();
    this.getAllUser();
  }

  Reset() {
    this.ctUserForm = this.formBuilder.group({
      ctUserAvailabilityId: [0],
      userId: [0, [Validators.required , Validators.pattern(/^[1-9]\d*$/)]],
      shiftStartTime: ['', [Validators.required]],
      ShiftEndTime: ['', [Validators.required]],
      isAutoAllocationEnabled: [true],
    });
    this.userAccessData =this.api.getUserScreenAccessMenu('CT Users');
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getAllCTUser() {
    this.kemarClientService.get<any>(null, ServiceUrl.GetCTUasers).subscribe(
      (response) => {
        this.allCTUserList = response;
      },
      (r) => {
        alert(r.error.error);
        // console.log(r.error.error);
      }
    );
  }

  getAllUser() {
    this.spinner.show();
    var userType = {"RoleGroup":"ControlTower"}
    this.kemarClientService.get<any>(null, ServiceUrl.getUserbyRoll, userType).subscribe(
      (response) => {
        this.spinner.hide();
        this.AllUsers = response;
      },
      (r) => {
        this.spinner.hide();
        // console.log(r.error.error);
      }
    );
  }

  onReset() {
    this.submitted = false;
    this.ctUserForm.reset();
    this.ctUserForm.controls['ctUserAvailabilityId'].setValue(0);
    this.ctUserForm.controls['userId'].setValue(0);
    this.ctUserForm.controls['isAutoAllocationEnabled'].setValue(1);
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  open(content: any) {
    this.onReset();
    this.title = 'Add CT User';
    this.Save = 'Save';
    this.ctUserForm.controls['ctUserAvailabilityId'].setValue(0);
    this.ctUserForm.controls['userId'].setValue(0);
    this.ctUserForm.controls['shiftStartTime'].setValue('');
    this.ctUserForm.controls['ShiftEndTime'].setValue('');
    this.ctUserForm.controls['isAutoAllocationEnabled'].setValue(1);
    this.ctUserForm.controls['userId'].enable();
    this.resetbuttonShow = true;
    this.modalService.open(content, this.ngbModalOptions);
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  editUserDetail(content: any, user: any) {
    this.ctUserForm.reset();
    this.title = 'Update CT User';
    this.Save = 'Update';
    this.ctUserForm.controls['ctUserAvailabilityId'].setValue(user.ctUserAvailabilityId);
    this.ctUserForm.controls['userId'].setValue(user.userId);
    this.ctUserForm.controls['shiftStartTime'].setValue(user.shiftStartTime);
    this.ctUserForm.controls['ShiftEndTime'].setValue(user.shiftEndTime);
    this.ctUserForm.controls['isAutoAllocationEnabled'].setValue(user.isAutoAllocationEnabled);
    this.ctUserForm.controls['userId'].disable();
    this.resetbuttonShow = false;
    this.modalService.open(content, this.ngbModalOptions);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.ctUserForm.controls;
  }

  registeruser() {
    this.submitted = true;
    if (this.ctUserForm.invalid) {
       return;
    }
    this.spinner.show();
    var body = this.ctUserForm.value;
    this.kemarClientService
      .postPatch<any>(ServiceUrl.registerCTUser, body)
      .subscribe(
        (res) => {
          Swal.fire('', res.responseMessage, 'success');
          this.getAllCTUser();
          this.closeModalPopup();
        },
        (r) => {
          this.spinner.hide();
          let message = r.error?.errorMessage; //message;
          if(message == null){
            message = r.error.message;
          }
          Swal.fire('', message, 'error');
        }
      );

    this.spinner.hide();
  }
}
