
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { faBan, faEye, faSort,faHistory, faInfo } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { __values } from 'tslib';
import { Pagination } from 'src/app/models/pagination';


@Component({
  selector: 'app-nonlogistic-vehicle',
  templateUrl: './nonlogistic-vehicle.component.html',
  styleUrls: ['./nonlogistic-vehicle.component.scss']
})
export class NonlogisticVehicleComponent implements OnInit {
  faEye = faEye;
  faBan = faBan;
  faSort = faSort;
  faHistory = faHistory;
  faInfo = faInfo;
  public localStorage = localStorage;
  pageSize = 20;
  title: any;
  key: string = 'nonLogVehicleTranId'; //set default
  reverse: boolean = false;
  public toggleButton: boolean = true;
  vrn: any;
  search: boolean = false;
  filter = '';
  protected _onDestroy = new Subject<void>();
  searchedList: any;
  public pagination: Pagination = new Pagination(1, 0, 15, 0, [15, 20, 25, 30]);
  public searchText: string = '';
  hidefoundrecord: boolean = false;
  vehicle: any;
  inPlantTransactionhistory: any[];
  //deviceStatusHistoryList : InplantTransactionHistory[];

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads

  transaction: any;
  transactionResponse: any;

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute) {
    this.route.params.subscribe(data => {
      this.vrn = data;
    })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.GetAllNonLogisticVehicle();
  }

  OnPageChange(event) {
    this.pagination.page = event;
    this.GetAllNonLogisticVehicle();
  }

  OnPageSizeChange(event) {
    this.pagination.pageSize = event.target.value;
    this.GetAllNonLogisticVehicle();
  }

  GetAllNonLogisticVehicle() {
    this.spinner.show();
    if (this.pagination.searchtext === undefined) {
      this.pagination.searchtext == null;
    }

    var query = { 'page': this.pagination.page, 'pagesize': this.pagination.pageSize, 'searchtext': this.pagination.searchtext }
    this.kemarClientService.get<any>(null, ServiceUrl.getAllNonLogisticVehicle, query)
      .subscribe(
        response => {
          this.spinner.hide();
          this.transactionResponse = response.items;
          this.pagination.count = response.count;
          this.pagination.filterRecordCount = response.filterRecordCount;
          this.pagination.totalTransactionCount = response.totalTransactionCount;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }


  GetTransactionByVrn(searchtext) {

    if(typeof searchtext != 'undefined' && searchtext!=null && searchtext!= "")
    {
      this.hidefoundrecord =true;
      this.GetAllNonLogisticVehicle();
    }
    else{
      this.hidefoundrecord =false;
      this.GetAllNonLogisticVehicle();
    }

  }

  GetInPlantTransactionHistory(content, Id : number){
    // inPlantTransactionhistory
    this.spinner.show();
    this.kemarClientService.getbyId<any>(Id, ServiceUrl.getInplantTransactionHistory)
      .subscribe(
        response => {
          this.spinner.hide();
          this.inPlantTransactionhistory = response;
          // console.log(this.inPlantTransactionhistory)
          let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
          };
          this.modalService.open(content, ngbModalOptions);
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }



}
