<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter my-2 align-items-center">
          <div class="col-sm-3">
            <h4 class="title-one mb-0"> TAT Report</h4>
          </div>
          <div class="col-sm-9 text-end" id="input-filter">
            <div class="input-append">
              <!-- <span class="material-icons-sharp icon-in">
                search
                </span> -->
              <input class="form-control ps-2" type="search" name="search" placeholder="Search by keyword"
                [(ngModel)]="filter" (input)="filterchange($event.target.value)">
            </div>
            <select name="gateId" [(ngModel)]="tatReportModel.tranType" class="form-control form-select">
              <option hidden selected [ngValue]="undefined">All Trip Type</option>
              <option *ngFor="let transType of transTypeDll" [ngValue]="transType.value">
                {{transType.key}}</option>
            </select>
            &nbsp;
            <label for="start">From:</label>
            <input class="form-control date" name="fromdate" (keypress)="KeyPressDate($event)" [(ngModel)]="tatReportModel.fromDate" type="date"
              id="start" value="Date" [max]="currentdate">
            <label for="start" class="to-label">To:</label>
            <input class="form-control date" name="todate" (keypress)="KeyPressDate($event)" [(ngModel)]="tatReportModel.toDate" type="date" id="start"
              value="Date" [max]="currentdate">
            <button class="btn btn-primary go-btn" (click)="getTatReport()" matTooltip="Search"
              matTooltipClass="primary-tooltip">
              <span class="material-icons-round">search</span>
            </button>
            <button class="btn btn-success go-btn ms-2" matTooltip="Download Report" matTooltipClass="primary-tooltip"
              (click)="exportToExcel()">
              <span class="material-icons-round">file_download</span>
            </button>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Trip No.</th>
                  <th (click)="sort('name')">VRN
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Trip Type
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Driver Name
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Entry Date Time
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Exit Date Time
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Duration
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                    (DAY:HH:MM)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let gate of tatReport | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">

                  <td>{{gate.vehicleTransactionCode}}</td>
                  <td>{{gate.vrn}}</td>
                  <td><span *ngIf="gate.tranType  ==1 ">OutBound</span>
                    <span *ngIf="gate.tranType ==2">InBound</span>
                  </td>
                  <td>{{gate.driverId}}</td>
                  <td>{{gate.transactionStartTime | date: 'dd-MM-yyyy HH:mm '}}</td>
                  <td>{{gate.transactionEndTime | date: 'dd-MM-yyyy HH:mm '}}</td>
                  <td>{{gate.duration}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>
<footer class="sticky-footer bg-black text-light">
  <app-footer></app-footer>
</footer>
