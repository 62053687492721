<app-header></app-header>
<section class="vehicleTransaction my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter mb-2 align-items-center"
        *ngIf="vehicleTransaction!=null && vehicleTransaction!='undefined'">
        <div class="col-md-3 col-12">
          <h4 class="title-one mb-0"> Vehicle Trip </h4>
        </div>

        <div class="col-md-2 col-3" >
          <input class="form-control" type="text" name="vrn" value="{{ vehicleTransaction.vrn }}" disabled>
        </div>
        <div class="col-md-2 col-3">
          <input class="form-control" type="text" name="txn"
            value="{{ TranType}}" disabled>
        </div>
        <div class="col-md-2 col-3">
          <input class="form-control" type="text" name="txn"
            value="Current TXN: {{ vehicleTransaction.vehicleTransactionCode }}" disabled>
        </div>
        <div class="col-md-2 col-3">
          <div class="input-append">
            <span class="material-icons-sharp icon-in">
              person
            </span>
            <input class="form-control" type="text" name="name" value="{{ vehicleTransaction.driverName }}" disabled>
          </div>
        </div>
        <!-- <div class="col-md-1">
          <div class="input-append">
            <span class="material-icons-sharp icon-in">
              sort
              </span>
              <select name="gateId" class="form-control form-select" style="padding-left: 36px;">
                <option hidden [ngValue]="0">Short By</option>
                <option *ngFor="let transType of transTypeDll" [ngValue]="transType.value">
                  {{transType.key}}</option>
              </select>
          </div>
          </div> -->
      </div>
        <div class="row justify-content-start my-4 ml-4">
          <div class="col-md-11 col-12">
            <div class="custom-table">
              <mat-accordion class="custom-accordion  vertical-line" [togglePosition]="'before'">
                <mat-expansion-panel (onclick)="selectedJobmilestone(jobmile.jobMilestoneId)"
                  *ngFor="let jobmile of jobMilestones" hideToggle="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ jobmile.milestone }}
                      <span *ngIf="jobmile.milestone  != 'PHYSICAL CHECK' && jobmile.milestone  != 'INVOICING' && jobmile.milestone  != 'EXIT CLEARANCE'" >
                        - {{jobmile.milestioneEvent | titlecase}}
                      </span>
                    </mat-panel-title>
                    <mat-panel-description>
                      <div>
                        {{jobmile.locationName}}
                      </div>
                    </mat-panel-description>

                    <mat-panel-description>
                      <div>
                        {{jobmile.lane}}
                      </div>
                    </mat-panel-description>
                    <mat-panel-description>
                      <div class="align-center">
                        {{jobmile.milestoneCompletionTime | date: 'dd-MM-yyyy HH:mm'}}
                      </div>
                    </mat-panel-description>
                    <mat-panel-description>
                      <div class="align-center " *ngIf="jobmile.status == 'Completed'">
                        {{jobmile.timeTakenToCompleteMilestoneTransaction}}
                      </div>
                    </mat-panel-description>
                    <!-- <mat-panel-description class="justify-content-end">
                    <div class="btn-status">
                      <button mat-raised-button color="progress" *ngIf="jobmile.status  == 'Open'">
                        <mat-icon>check</mat-icon>
                        Completed
                      </button>
                      <button mat-raised-button color="pending" *ngIf="jobmile.status  == 'Pending'">
                        <mat-icon>pending</mat-icon>
                        Pending
                      </button>
                      <button mat-raised-button color="success" *ngIf="jobmile.status  == 'Completed'">
                        <mat-icon>check</mat-icon>
                        Completed
                      </button>
                    </div>
                  </mat-panel-description> -->
                    <!-- <mat-icon *ngIf="!panelOpenState">add</mat-icon>
                  <mat-icon *ngIf="panelOpenState">remove</mat-icon> -->
                    <div class="vt_status">
                      <span *ngIf="jobmile.status == 'Open'">
                        <mat-icon color="primary" style="transform: scaleX(-1);" class="material-icons blink">local_shipping</mat-icon>
                      </span>
                      <span *ngIf="jobmile.status == 'Pending'">
                        <mat-icon color="pending" class="material-icons">pending</mat-icon>
                      </span>
                      <span class="completed" *ngIf="jobmile.status == 'Completed'">
                        <mat-icon color="completed" class="material-icons">check_circle</mat-icon>
                      </span>
                      <span class="Cancelled" *ngIf="jobmile.status == 'Cancelled'">
                        <mat-icon color="danger">cancel</mat-icon>
                      </span>
                      <span class="completed" *ngIf="jobmile.status == 'Skip'">
                        <mat-icon color="primary" class="material-icons">fast_forward</mat-icon>
                      </span>
                      <div class="" *ngIf="jobmile.status == 'Completed'">
                        <!-- <mat-icon color="success" class="material-icons">check_circle</mat-icon> -->
                        <small class="reach-time">{{jobmile.timeTakenToCompleteMilestone}}</small>
                      </div>
                    </div>



                    <!-- <mat-icon color="completed" class="material-icons">check_circle</mat-icon>
                    <mat-icon color="open" class="material-icons">next_plan</mat-icon>
                    <mat-icon color="pending" class="material-icons">pending</mat-icon>
                    <mat-icon color="running" class="material-icons">replay_circle_filled</mat-icon>
                    <mat-icon color="failed" class="material-icons">error</mat-icon> -->



                  </mat-expansion-panel-header>
                  <div class="running-status">
                    <!-- <span *ngIf="jobmile.status == 'Open'">
                      <mat-icon color="success" class="material-icons">local_shipping</mat-icon>
                    </span> -->
                  </div>
                  <div class="row my-3 justify-content-start align-items-center">
                    <div class="col-md-12">
                      <table class="table table-bordered sm-table text-center">
                        <thead>
                          <tr>
                            <th scope="col" class="sr-no">#</th>
                            <th scope="col">Activities</th>
                            <th scope="col">TimeStamp</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            <th scope="col" width="24%">Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let jobmileTracking of jobmile.milestoneActionsTracking   | orderBy:['sequence']  let i=index">
                            <td>{{i + 1}}</td>
                            <td> {{jobmileTracking.milestoneAction }}</td>
                            <td>
                              <span> {{jobmileTracking.completionTime | date: 'dd-MM-yyyy HH:mm:ss'}}</span>
                            </td>
                            <td>
                              <span *ngIf="jobmileTracking.status  == 'Open'">
                                <mat-icon color="success">check</mat-icon> Completed
                              </span>
                              <span *ngIf="jobmileTracking.status  == 'Completed'">
                                <strong class="text-success">Completed</strong>
                              </span>
                              <span *ngIf="jobmileTracking.status  == 'Failed'">
                                <mat-icon color="danger">cancel</mat-icon>
                              </span>
                              <span *ngIf="jobmileTracking.status  == 'ReOpen'">
                                <mat-icon color="#FFFFFF">cancel</mat-icon>
                              </span>
                            </td>
                            <td>
                              <span
                                *ngIf="jobmileTracking.status  != 'Completed' && jobmile.status  == 'Open'  && jobmileTracking.canByPassFromUI !=false && jobmileTracking.isActiveAction==true "
                                >
                                <button
                                  (click)="onChangecheckbox(jobmileTracking.milestoneActionsTrackingId,jobmile.milestoneActionsTracking, action,jobmileTracking.actionCode,jobmile.locationCode,jobmile.locationId )"
                                  class="btn btn-danger btn-sm align-items-center">
                                  <mat-icon class="material-icons-outlined">task_alt</mat-icon>
                                </button>
                              </span>

                              <span
                                *ngIf="jobmileTracking.status  == 'Completed' && jobmile.status  == 'Completed' && jobmileTracking.actionCode == 'AX4NOTIFY' && (UserRole == 'SuperAdmin' || UserRole == 'Admin')">
                                <button
                                  (click)="retriggerAX4Notify(jobmile.jobMilestoneId,jobmile.milestoneCode )"
                                  class="btn btn-danger btn-sm align-items-center">
                                  <mat-icon class="material-icons-outlined">Retrigger</mat-icon>
                                </button>
                              </span>
                            </td>
                            <td style="max-width: 10vw;">
                              <p>{{jobmileTracking.remarks}}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>


              <div class="completed-timeline d-flex align-self-center justify-content-end my-2" *ngIf="milestoneCompleted">
                <mat-icon style="transform: scaleX(-1);" class="material-icons">local_shipping</mat-icon>
                  <span class="total-time">Trip TAT : {{TotalDiffTime}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<footer class="sticky-footer bg-black text-light mt-auto mb-0">
  <app-footer></app-footer>
</footer>

<ng-template #action let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">Bypassed</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="comments">
        <!-- <ul>
          <li>Lorem ipsum, or lipsum as it is sometimes known..</li>
          <li>Lorem ipsum, or lipsum as it is sometimes known...</li>
          <li>Lorem ipsum, or lipsum as it is sometimes known...</li>
        </ul> -->
        <!-- <input type="text" class="form-control mb-2" value="" name="" placeholder="Enter your name"> -->
        <textarea rows="2" type="text" placeholder="Write your comment" class="form-control input"
          [(ngModel)]="bypassRemark"></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="modal.dismiss('Cross click')"
        data-dismiss="modal">Close</button>
      <button type="button" class="btn btn-primary btn-sm" (click)="updateMilestoneTrackingNew()" >Submit</button>

    </div>
  </div>
</ng-template>
