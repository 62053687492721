<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-2"*ngIf="userAccessData[0].canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body custom-table">
        <div class="d-flex data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Weighbridge Preference </h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search" placeholder="Search"
                [(ngModel)]="filter" (ngModelChange)="currentActivePage = 1">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add WeighBridge"
             matTooltipClass="danger-tooltip" *ngIf="userAccessData[0].canCreate ==true">
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
          </div>
        </div>	
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr.No.</th>
                  <th (click)="sort('name')" class="">Weighbridge Name
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Near by Location
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Transaction Type
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Weightment Type
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Priority
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="">Status
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
    
                  <th class="text-center action-key"*ngIf="userAccessData[0].canUpdate ==true">Action
                  </th>
                </tr>
              </thead>
              <tbody>
    
                <tr
                  *ngFor="let WeighBridgePreference of weighbridgePreference | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="left">{{WeighBridgePreference.locationName}}</td>
                  <td class="left">{{WeighBridgePreference.nearByLocation}}</td>
                  <td class="left">{{WeighBridgePreference.transactionType}}</td>
                  <td class="left">{{WeighBridgePreference.weighmentType}}</td>
                  <td>{{WeighBridgePreference.priority}}</td>
                  <td>
                    <span *ngIf="WeighBridgePreference.isActive  ==true ">Active</span>
                    <span *ngIf="WeighBridgePreference.isActive  ==false ">Inactive</span>
                  </td>
                  <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
                    <span class="edit-btn-icon" type="button" matTooltip="Edit WeighBridge" matTooltipClass="danger-tooltip"  (click)="editWeighBridge(edit, WeighBridgePreference) ">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end"  *ngIf="weighbridgePreference != null">
          <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
        </div>
  </div>
  </div>	
</div>
</section>

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <form autocomplete="off" [formGroup]="form">
              <div class="form-group">
                <div class="form-group ml-3">
                  <input type="hidden" minlength="3" maxlength="30" class="form-control" name="mileStoneId"
                    value="{{WeighBridgePreference.WeighBridgePerferencesId}}" />
                </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-6">
                    <label for="locationId">Select Weighbridge <sup>*</sup></label>
                    <select name="locationId" [(ngModel)]="WeighBridgePreference.locationId"
                      class="form-control form-select" formControlName="locationId"
                      [ngClass]="{ 'is-invalid': submitted && f.locationId.errors }">
                      <option [ngValue]="0" hidden>--Choose Option--</option>
                      <option *ngFor="let location of locationWBMaster" [ngValue]="location.locationId">
                        {{location.locationName}}</option>
                    </select>
                    <div *ngIf=" submitted && f.locationId.errors" class="text-danger">
                      <div *ngIf="f.locationId.errors?.pattern">
                        <small> Weighbridge is required.</small>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <label for="nearByLocationId">Select Near by Location <sup>*</sup></label>
                    <select name="nearByLocationId" [(ngModel)]="WeighBridgePreference.nearByLocationId"
                      class="form-control form-select" formControlName="nearByLocationId"
                      [ngClass]="{ 'is-invalid': submitted && f.nearByLocationId.errors }">
                      <option [ngValue]="0" hidden>--Choose Option--</option>
                      <option *ngFor="let group of locationGroupName" [ngValue]="group.locationId">
                        {{group.locationName}}</option>
                    </select>
                    <div *ngIf=" submitted && f.nearByLocationId.errors" class="text-danger">
                      <div *ngIf="f.nearByLocationId.errors?.pattern">
                        <small> Near by location is required.</small>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-6">
                    <label for="transactionType">Select Type <sup>*</sup></label>
                    <select name="transactionType" [(ngModel)]="WeighBridgePreference.transactionType"
                      class="form-control form-select" formControlName="transactionType"
                      [ngClass]="{ 'is-invalid': submitted && f.transactionType.errors }">
                      <option hidden [ngValue]="null">--Choose Option--</option>
                      <option *ngFor="let dmapping of transactionTypeDDL" [ngValue]="dmapping.value">
                        {{dmapping.key}}</option>
                    </select>
                    <div *ngIf="submitted && f.transactionType.errors" (click)="toggleDisplay()" class="text-danger">
                      <div *ngIf="f.transactionType.errors.required">
                        <small> Trip Type is required.</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label for="weighmentType">Weightment Type <sup>*</sup></label>
                    <br>
                    <select name="weighmentType" [(ngModel)]="WeighBridgePreference.weighmentType"
                      class="form-control form-select" placeholder="--Choose Option--" formControlName="weighmentType"
                      [ngClass]="{ 'is-invalid': submitted && f.weighmentType.errors }">
                      <option hidden [ngValue]="null">--Choose Option--</option>

                      <option *ngFor="let dmapping of weighmentTypeDll" [ngValue]="dmapping.value">
                        {{dmapping.key}}</option>
                    </select>

                    <div *ngIf="submitted && f.weighmentType.errors" class="text-danger">
                      <div *ngIf="f.weighmentType.errors.required">
                        <small>Weightment Type is required.</small>
                      </div>
                      <div *ngIf="f.weighmentType.errors.minlength">
                        <small>
                          <sup>*</sup>Weightment Type must be at least 4 characters.
                        </small>
                      </div>
                      <div *ngIf="f.weighmentType.errors.maxlength">
                        <small> <sup>*</sup>Weightment Type must not exceed 30 characters.</small>
                      </div>
                      <div *ngIf="f.weighmentType.hasError('whitespace')"></div>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-6">
                    <label for="priority">Priority<sup>*</sup></label>
                    <br>
                    <select name="priority" [(ngModel)]="WeighBridgePreference.priority"
                      class="form-control form-select" placeholder="--Choose Option--" formControlName="priority"
                      [ngClass]="{ 'is-invalid': submitted && f.priority.errors }">
                      <option hidden [ngValue]="null">--Choose Option--</option>
                      <option *ngFor="let dmapping of priorityDll" [ngValue]="dmapping.value">
                        {{dmapping.key}}</option>
                    </select>
                    <div *ngIf="submitted && f.priority.errors" (click)="toggleDisplay()" class="text-danger">
                      <div *ngIf="f.priority.errors.required">
                        <small> Priority is required.</small>
                      </div> 
                    </div>
                  </div>
                  <div class="col-sm-6">
                  </div>
                </div>
                <!-- <div class="form-group mb-0 mt-3 select-radio" *ngIf="activebuttonShow">
                  <label for="isActive" class="radio-btn mr-1">Active </label>
                  <label class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isActive" [value]="true"
                      [(ngModel)]="WeighBridgePreference.isActive" formControlName="isActive">
                  </label>
                  <label for="isActive" class="radio-btn mr-1">Inactive</label>
                  <label class="form-check-inline">
                    <input class="form-check-input" type="radio" name="isActive" [value]="false"
                      [(ngModel)]="WeighBridgePreference.isActive" formControlName="isActive">
                  </label>
                </div> -->
                <div class="select-radio" *ngIf="activebuttonShow">
                  <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="WeighBridgePreference.isActive" formControlName="isActive">
                  <label for="active" class="radio-label">Active</label>
                 
                  <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="WeighBridgePreference.isActive" formControlName="isActive">
                  <label for="inactive" class="radio-label">Inactive</label>
                </div>

              </div>
            </form>
          </div>

        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
        <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow" (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-success"
        (click)="registerWeigbridgePreference(WeighBridgePreference)">Save</button>
    </div>
  </div>

</ng-template>
<app-footer></app-footer>