import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { faSort,faSearch, faRedo, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, timer } from 'rxjs';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-apicommunication-errors',
  templateUrl: './apicommunication-errors.component.html',
  styleUrls: ['./apicommunication-errors.component.scss']
})
export class APICommunicationErrorsComponent implements OnInit {

pendingErrorsList:any[];
CompletedErrorList: any[];
ModelShowValue: any;
fromDate: any;
toDate: any;
pendingFilter: string;
completedFilter: string;
public userAccessData: UserScreenAccesData;

faSearch = faSearch
faRedo = faArrowRight
faSort = faSort
key: string = 'name'; //set default
reverse: boolean = false;
today:Date;
timer$: Observable<number> = timer(1000, 120000);
private subscription: Subscription;

sort(key) {
  this.key = key;
  this.reverse = !this.reverse;
}

// Pagination for Pending
CurrentPendingPage = 1;
pendingPageSize = 15;
tempcurrentPendingPage: number = 1;
totalActiveRecords=0;

//  Pagination for Completed
CurrentCompletedPage = 1;
completedPageSize = 15;
tempcurrentCompletedPage1: number = 1;
totalCompletedRecord = 0;

  constructor(private kemarClientService: KemarClientService,private modalService: NgbModal,
    private datePipe: DatePipe, private spinner: NgxSpinnerService,
    private api:DataService) { }

  ngOnInit(): void {
    const today = new Date();
    const formattedDate = this.datePipe.transform(today,'yyyy-MM-dd');
    this.fromDate = formattedDate;
    this.toDate = formattedDate;
    this.loadTabComponent(0);
    this.userAccessData =this.api.getUserScreenAccessMenu('APICommunicationErrors');

  }

  loadTabComponent(tab: number) {
    if (tab == 0){
      this.Timer();
    }
    else if (tab == 1)
    {
     this.getCompletedAPIErrorList();
     this.subscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  Timer = () => { this.subscription = this.timer$.subscribe(_second => this.getPendingAPIErrorsList()) }

  getPendingAPIErrorsList(){

    this.spinner.show();
    var query ={
      status: 'Pending',
    }
    this.kemarClientService.get<any>(null, ServiceUrl.getAllAPICommunicationList,query)
      .subscribe(
        response => {
          this.spinner.hide();
          this.pendingErrorsList = response;
          if(response != null){
            this.totalActiveRecords = response.length;
          }
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });

  }

  getCompletedAPIErrorList(){
    this.spinner.show();

    var fromDateValue = this.fromDate;
    var toDateValue = this.toDate;
    var query ={
      status: 'Resolved',
      fromDate : fromDateValue,
      toDate : toDateValue
    }
    this.kemarClientService.get<any>(null, ServiceUrl.getAllAPICommunicationList,query)
      .subscribe(
        response => {
          this.spinner.hide();
          this.CompletedErrorList = response;
          if(response != null){
            this.totalCompletedRecord = response.length;
          }
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  retriggerAPICalls(vrn, jobMilestoneId, MilestoneCode) {
    this.spinner.show();
    var query = {
      vrn: vrn,
      milestoneCode: MilestoneCode,
      JobMilestoneId: jobMilestoneId
    }
    this.kemarClientService.get<any>(null, ServiceUrl.retriggerAX4Notify,query)
      .subscribe(
        response => {
          Swal.fire({
            icon: 'success',
            title: '',
            text: response.responseMessage
          });
          this.getPendingAPIErrorsList();
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.responseMessage
          });
          this.getPendingAPIErrorsList();
          this.spinner.hide();
        });
  }

  ShowModelDetails(data: any, content: any){
    this.ModelShowValue = data;
    this.modalService.open(content, { size: 'lg' });
    this.modalService.open(this.ngbModalOptions);
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);

  pagechange = (event: number) => {  this.tempcurrentPendingPage = this.CurrentPendingPage = event; }
  filterchange = (val: any) => {  (!this.kemarClientService.commonValidation(val)) ? this.CurrentPendingPage = 1 : this.CurrentPendingPage = this.tempcurrentPendingPage }


  pagechange1 = (event) => {  this.tempcurrentCompletedPage1 = this.CurrentCompletedPage = event; }
  filterchange1 = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.CurrentCompletedPage = 1 : this.CurrentCompletedPage = this.tempcurrentCompletedPage1
}
