import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { faAnchor, faEdit,faBan, faCheck, faMapPin, faObjectGroup, faPlus,faEye, faSort, faTimes, faClosedCaptioning } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil, filter } from 'rxjs/operators';
import { ServiceUrl } from 'src/app/common/service-urls';
import { Bank, BANKS } from 'src/app/dashboard/demo-data';
import { MilestoneMasterResponse } from 'src/app/models/milestonemastermodel';
import { VehicleMasterResponse } from 'src/app/models/vehiclemastermodel';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { Router, RouterModule, Routes } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { __values } from 'tslib';
import { MileStoneTrackingRequest } from 'src/app/models/vehicleMilestonetrackingModel';
import { WeighBridgeMasterResponse } from 'src/app/models/weighbridgemastermodel';

const material = [MatExpansionModule]

@Component({
  selector: 'app-weighbridge-status',
  templateUrl: './weighbridge-status.component.html',
  styleUrls: ['./weighbridge-status.component.scss']
})

export class WeighbridgestatusComponent implements OnInit {

  failurewb:number;

  failurewbtansactionId:number;


  milestoneActionTrackingId:any;
  WeighBridgeMaster: any;
  isChecked:boolean;
  faEye = faEye;
  faBan = faBan;
  faSort = faSort;
  active = 'top';

  isShown: boolean =false;
  pageSize = 10;
  title: any;
  key: string = 'name'; //set default
  reverse: boolean = false;
  public toggleButton: boolean = true;
  vrn: any;
  search: boolean= false;
  filter = '';
  //protected banks: Bank[] = BANKS;
  public list: VehicleMasterResponse[];
  searchedList: any;
  VehicleMasterResponse: VehicleMasterResponse[] = [];
  vehicleMaster: any;
  vehicle: any;
  txn: any;
  vehicle_tran: any;
  jobMilestones: any;
  $allVehicles: Observable<VehicleMasterResponse[]>;
  $filteredVehicles: Observable<VehicleMasterResponse[]>;
 public milestoneTrack :MileStoneTrackingRequest = new MileStoneTrackingRequest();


  public vehicleCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public vehicleFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredVehicles: ReplaySubject<VehicleMasterResponse[]> = new ReplaySubject<VehicleMasterResponse[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @Input() placeholderLabel = 'Search';

  @Input() noEntriesFoundLabel = 'No Entries Found';


  @Input() clearSearchInput = false;

  /** Disables initial focusing of the input field */
  @Input() disableInitialFocus = false;
  allocateVehicleCount: any;
  allocateCount: any;
  submitted: boolean;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;

  form: UntypedFormGroup;
  panelOpenState = false;
  faCheck = faCheck;
  faClosedCaptioning = faClosedCaptioning;
  step = 0;
  milestoneMaster: any;
  vehicleTransaction: any;
  milestoneActionsTracking: any;
  transaction: any;
  WeighBridgeCapacity:any;
  transactionResponse: any;
  keyvalue: any;
  public WeighBridge: WeighBridgeMasterResponse = new WeighBridgeMasterResponse();
  //VehicleMasterResponse: any;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  getAllVehicles() {

    this.spinner.show();
    this.kemarClientService.get<VehicleMasterResponse>(null, ServiceUrl.getAllVehicle)
      .subscribe(
        response => {
          // this.vehicleMaster = response;
          this.vehicleMaster = response
          this.searchedList = response;
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }


  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute) {
      this.route.params.subscribe(data => {
        this.vrn = data;
      })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {

        weighBridgeName: [
          '',

        ],
        weighBridgeId: [''],
        isActive: [
          '',
          [
            Validators.required,
          ]
        ],


      },
    );



    this.getAllWeighBridgeStatus();


  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }
  registerWeigbridgeAssign(ReallocatedWb:any) {

    var query = { 'reallocatedWb': ReallocatedWb.weighBridgeId,'failureWb': this.failurewb,'weighBridgeTranId': this.failurewbtansactionId }
    this.submitted = true;
    this.spinner.show();

    this.kemarClientService.postPatch<any>(ServiceUrl.assignWeigbridge,query)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success')
         // this.getAllWeighBridge();
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
          // console.log(r);
        });
  }
  getAllWeighBridgeCapacity(weighbridgeId) {

    this.spinner.show();
    var query = { 'weighbridgeId': weighbridgeId }
    this.kemarClientService.get<any>(null, ServiceUrl.getAllWeighBridgeCapacity,query)
      .subscribe(
        response => {
          this.spinner.hide();
          this.WeighBridgeCapacity = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }
  getAllWeighBridgeStatus() {

    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllWeighBridgeStatus)
      .subscribe(
        response => {

          this.spinner.hide();
          this.allocateVehicleCount = response.allocateVehicleCount;
          this.allocateCount = response. weighbridgeAvailibility;
          // console.log(this.allocateCount);
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }
  open(content: any,weighbridgeId,failurewbtansactionId) {

    this.failurewb=weighbridgeId;
    this.failurewbtansactionId=failurewbtansactionId;
    this.title="Add Weighbridge Status";
    this.getAllWeighBridgeCapacity(weighbridgeId);

    this.modalService.open(content, this.ngbModalOptions);
  }
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
toggleShow(weighBridgeId) {

// console.log(weighBridgeId);
this.isShown = ! this.isShown;
}

  GetAllVehilceTransaction() {



    this.kemarClientService.get<any>(null,ServiceUrl.getAllVehilceTransaction)
      .subscribe(
        response => {

          this.transactionResponse = response;
        },
        r => {
          // console.log(r.error.error);
        });
  }


  GetTransactionByVrn(vehicle) {

    var query = { 'keyvalue': vehicle }

    this.kemarClientService.get<any>(null,ServiceUrl.GetTransactionByVrn,query)
      .subscribe(
        response => {
          this.transactionResponse = response;
          // console.log(this.transactionResponse);
        },
        r => {
          // console.log(r.error.error);
        });
  }

  searchvehicle(keyvalue) {

    this.search = true;

    this.searchedList = this.vehicleMaster.filter(

      (val) => val['keyvalue'].includes(keyvalue))
    // console.log("HII");
    //Searched Data
    // console.log(this.searchedList)
  }

  searchtxn(txn) {

    this.search = true;

    this.searchedList = this.vehicleMaster.filter(

      (val) => val['txn'].includes(txn))
    // console.log("HII");
    //Searched Data
    // console.log(this.searchedList)
  }
  viewVehicleTransaction(vehicle){
    this.router.navigateByUrl('/current-transaction/{{vehicle}}');

  }

}
