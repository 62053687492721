import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { DeviceLocationMappingResponse } from 'src/app/models/devicelocationmappingmodel';
import { DeviceMasterResponse } from 'src/app/models/devicemastermodel';
import { InvoicingResponse } from 'src/app/models/invoicingresponsemodel';
// import { LocationMasterResponse } from 'src/app/models/locationmastermodel';
import { PhysicalCheckResponse } from 'src/app/models/physicalcheckresponsemodel';
import { RFIDResponse } from 'src/app/models/rfidmodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { WeighBridgeControllerResponse } from 'src/app/models/weighbridgecontrollerresponsemodel';
import { WeighBridgeMasterResponse } from 'src/app/models/weighbridgemastermodel';
import { DataService } from 'src/app/service/data.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rfidredersimulation',
  templateUrl: './rfidredersimulation.component.html',
  styleUrls: ['./rfidredersimulation.component.scss']
})
export class RfidRederSimulationComponent implements OnInit {
  public userAccessData: UserScreenAccesData;
  locationMaster:any;
  WeighBridgeMaster:any;
  weighbridgeSimulation: any;
  submitted = false;
  panelOpenState = false;
  form: any;
  deviceMaster: any;
  locMapping:any;
  public RFIDSimulation: RFIDResponse = new RFIDResponse();
  public WeighBridgeControllerResponse: WeighBridgeControllerResponse = new WeighBridgeControllerResponse();
  public PhysicalCheckResponse: PhysicalCheckResponse = new PhysicalCheckResponse();
  public InvoicingResponse: InvoicingResponse = new InvoicingResponse();
  deviceLocationMappingMaster:any;
  milestoneDetailList:any[];

  constructor(private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder,
    private router: Router,
    private kemarClientService: KemarClientService, private modalService: NgbModal,
    private api: DataService) {
      this.form = this.formBuilder.group({

        deviceLocationId: [0],
        RFIDTagNo: [''],

        vrn: [
          '',

        ],
        weight: [''],
        weighBridgeId: [0],
        deviceId: [0],
        Status:[''],
        VRN:[''],
        Comment:[''],
        additionalComment:[''],
        milestoneTransactionCode: [''],
        milestoneCode: [''],


      });
      var route = this.router.url;
    // this.userAccessData =this.api.getUserScreenAccessMenu('DEVICEMAP');
    this.userAccessData =this.api.getUserScreenAccessMenuOnRoutingURL(route);

    }

  ngOnInit(): void {
 //   this.getAllLocation();
    this.getAllWeighBridge();
    this.getAllDevice();
    this.getAllDeviceLocationMapping();
    this.getAllMilestone();
  }

  getAllMilestone() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAllMilestone)
      .subscribe(
        response => {

          this.milestoneDetailList = response;
        },
        r => {
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  getAllDeviceLocationMapping() {
    this.kemarClientService.get<DeviceLocationMappingResponse>(null, ServiceUrl.getAllActiveDeviceLocationMappings)
      .subscribe(
        response => {
          this.deviceLocationMappingMaster = response;
        this.locMapping=  this.deviceLocationMappingMaster.filter(x => x.deviceType == 'Controller');
        },
        r => {
          alert(r.error.error);
        });
  }
  getAllDevice() {
    this.spinner.show();
    var query = { 'requestId': "S1637" }
    this.kemarClientService.get<any>(null, ServiceUrl.getdeviceMapping,query)
      .subscribe(
        response => {
          this.deviceMaster = response.locations.slice().sort((a,b)=> a.deviceName.localeCompare(b.deviceName));
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
        });
  }

  getAllWeighBridge() {
    this.spinner.show();
    this.kemarClientService.get<WeighBridgeMasterResponse>(null, ServiceUrl.getAllWeighBridge)
      .subscribe(
        response => {
          this.spinner.hide();
          this.WeighBridgeMaster = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
        });
  }

  PostRFIDTAG() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
  this.RFIDSimulation.requestId="s4526"
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.postRFIDTAG, this.RFIDSimulation)
      .subscribe(
        res => {

          Swal.fire({
            icon: 'success',
            title: '',
            text: res.statusMessage
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        },
        r => {
          var errmsg = "";

          if (r.error.title != undefined && r.error.title != null){
              errmsg = r.error.title;
          }
          else
          {
            if (r.error.errorMessage != undefined && r.error.errorMessage != null)
            {
              errmsg = r.error.errorMessage;
            }
            else if (r.error.statusMessage != undefined && r.error.statusMessage != null)
            {
              errmsg = r.error.statusMessage;
            }
            else if ( r.error != null && r.error != null)
            {
              errmsg = r.error;
            }
          }

          Swal.fire({
            icon: 'error',
            title: '',
            text: errmsg
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        });

  }

  PostWeighbridge(WeighBridgeControllerResponse) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.postWeighbridgeTransaction,WeighBridgeControllerResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success').then(function () {
            window.location.reload();
          })
          this.spinner.hide();
        },
        r => {
          var errmsg = "";

          if (r.error.title != undefined && r.error.title != null){
              errmsg = r.error.title;
          }
          else
          {
            if (r.error.errorMessage != undefined && r.error.errorMessage != null)
            {
              errmsg = r.error.errorMessage;
            }
            else if ( r.error != null && r.error != null)
            {
              errmsg = r.error;
            }
          }
          //errmsg = r.error.errorMessage;
          Swal.fire({
            icon: 'error',
            title: '',
            text: errmsg
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();

        });

  }

  PhysicalCheckModel(PhysicalCheckResponse) {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.PhysicalCheckModel,PhysicalCheckResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success').then(function () {
            window.location.reload();
          })
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
        });
        window.location.reload();
  }

  invoicingResponse(InvoicingResponse) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.InvoicingModel,InvoicingResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success').then(function () {
            window.location.reload();
          })
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
        });
        window.location.reload();
  }

  lodingResponse(InvoicingResponse) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.LodingModel,InvoicingResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success')
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
        });
        window.location.reload();
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  retriggerAX4Notify() {
    this.spinner.show();
    var vehicleNumber = this.form.controls['VRN'].value;
    var milestoneCode = this.form.controls['milestoneCode'].value;
    if(vehicleNumber == '' || milestoneCode == ''){
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Provide required data'
      })
      return;
    }
    var query = {
      vrn: vehicleNumber,
      milestoneCode: milestoneCode,
      JobMilestoneId: 0
    }
    this.kemarClientService.get<any>(null, ServiceUrl.retriggerAX4Notify,query)
      .subscribe(
        response => {
          Swal.fire({
            icon: 'success',
            title: '',
            text: response.responseMessage
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.responseMessage
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        });
  }

}
