import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserRegistrationComponent } from './admin/user/user-registration/user-registration.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { GateInReportComponent } from './reports/gate-report/gate-report.component';
import { ParkingInComponent } from './gates/parking-in/parking-in.component';
import { ParkingOutComponent } from './gates/parking-out/parking-out.component';
import { AuthGuard } from './service/auth.guard';
import { PlantInComponent } from './gates/plant-in/plant-in.component';
import { PlantOutComponent } from './gates/plant-out/plant-out.component';
import { VehicleComponent } from './master/vehicle-master/vehicle.component';
import { TransporterMasterComponent } from './master/transporter-master/transporter-master.component';
import { DriverComponent } from './master/driver/driver.component';
import { TatReportComponent } from './reports/tat-report/tat-report.component';
import { LaneControlComponent } from './controls/lane-control/lane-control.component';
import { WeighBridgeControlComponent } from './controls/weigh-bridge-control/weigh-bridge-control.component';
import { WeighBridgeMasterComponent } from './master/weigh-bridge-master/weigh-bridge-master.component';
import { MilestoneMasterComponent } from './master/milestone-master/milestone-master.component';
import { VehicleBlacklistingComponent } from './controls/vehicle-blacklisting/vehicle-blacklisting.component';
import { DriverBlacklistingComponent } from './controls/driver-blacklisting/driver-blacklisting.component';
import { ThirdPartyVehicleComponent } from './master/third-party-vehicle/third-party-vehicle.component';
import { TransporterBlacklistingComponent } from './controls/transporter-blacklisting/transporter-blacklisting.component';
import { VehicleTabsComponent } from './master/vehicle-tabs/vehicle-tabs.component';
import { DeviceLocationMappingComponent } from './master/device-location-mapping/device-location-mapping.component';
import { BlacklistingComponent } from './controls/blacklisting/blacklisting.component';
import { MilestoneActionComponent } from './controls/milestone-action/milestone-action.component';
import { WeighbridgePreferenceComponent } from './controls/weighbridge-preference/weighbridge-preference.component';
import { ElvComponent } from './transaction/elv/elv.component';
import { MilestoneActionMappingComponent } from './master/milestone-action-mapping/milestone-action-mapping.component';
import { MilestoneTabsComponent } from './master/milestone-tabs/milestone-tabs.component';
import { CurrentTransactionComponent } from './transaction/current-transaction/current-transaction.component';
import { RoleComponent } from './master/roleMaster/role.component';
import { UserRoleMappingMasterComponent } from './master/userRoleMappingMaster/userRoleMappingMaster.component';
import { TransactionComponent } from './transaction/transaction/transaction.component';
import { RfidRederSimulationComponent } from './controls/rfidredersimulation/rfidredersimulation.component';
import { ParkingSimulationComponent } from './controls/parkingsimulation/parkingsimulation.component';
import { PlantGateSimulationComponent } from './controls/plantgatesimulation/plantgatesimulation.component';
import { WeighmentsimulationComponent } from './controls/weighmentsimulation/weighmentsimulation.component';
import { LoadingsimulationComponent } from './controls/loadingsimulation/loadingsimulation.component';
import { Ax4SimulationComponent } from './controls/ax4simulation/ax4simulation.component';
import { CrossCheckMasterComponent } from './master/cross-check-master/cross-check-master.component';
import { WeighbridgestatusComponent } from './transaction/weighbridge-status/weighbridge-status.component';
import { VehicleParkingComponent } from './transaction/vehicle-parking/vehicle-parking.component';
import { DeviceControlComponent } from './controls/device-control/device-control.component';
import { LocationMappingComponent } from './master/location-mapping/location-mapping.component';
import { InplantTransactionComponent } from './transaction/inplant-transaction/inplant-transaction.component';
import { WeighbridgeTransactionComponent } from './transaction/weighbridge-transaction/weighbridge-transaction.component';
import { ApiTrackingReportComponent } from './reports/apiTracking-report/apiTracking-report.component';
import { AlertTabsComponent } from './controls/alert-tabs/alert-tabs.component';
import { AlertMasterComponent } from './controls/alert-master/alert-master.component';
import { MyIncidentComponent } from './controls/myincident/myincident.component';
import { CtDashboardComponent } from './ct-dashboard/ct-dashboard.component';
import { CTUsersComponent } from './controls/ctusers/ctusers.component';
import { WbDasboardComponent } from './wb-dasboard/wb-dasboard.component';
import { CommonMasterDataComponent } from './master/common-master-data/common-master-data.component';
import { GateTransactionComponent } from './transaction/gate-transaction/gate-transaction.component';
import { SLAMasterTabsComponent } from './master/slamaster/slamaster-tabs.component';
import { JobScheduleMasterComponent } from './master/job-schedule-master/job-schedule-master.component';
import { AppConfigComponent } from './master/app-config/app-config.component';
import { GateDashboardComponent } from './transaction/gate-dashboard/gate-dashboard.component';
import { MyAssignedincidentComponent } from './my-assignedincident/my-assignedincident.component';
import{ NonlogisticVehicleComponent } from './nonlogistic-vehicle/nonlogistic-vehicle.component';
import { WorkFlowMasterComponent } from './master/work-flow-master/work-flow-master.component';
import { CementVehicleComponent } from './master/cement-vehicle/cement-vehicle.component';

import { FrsEnrollmentComponent } from './frs-enrollment/frs-enrollment.component';
import { ApiSimulationComponent } from './controls/api-simulation/api-simulation.component';
import { DeviceConfigurationComponent } from './controls/device-configuration/device-configuration.component';
import { InplantApiSimulationComponent } from './controls/inplant-api-simulation/inplant-api-simulation.component';
import { PackerMasterComponent } from './master/packer-master/packer-master.component';
import { WeightToleranceActionComponent } from './transaction/weight-tolerance-action/weight-tolerance-action.component';
import { WeighmentApprovalComponent } from './transaction/weighment-approval/weighment-approval.component';
import { DailyTatReportComponent } from './reports/daily-tat-report/daily-tat-report.component';
import { APICommunicationErrorsComponent } from './controls/apicommunication-errors/apicommunication-errors.component';
import { UpComingVehicleReportComponent } from './reports/up-coming-vehicle-report/up-coming-vehicle-report.component';
import { GateChangeComponent } from './gate-change/gate-change.component';

import { PortComponent } from './landingPage/port/port.component';
import { SteelComponent } from './landingPage/steel/steel.component';
import { LandingHomeComponent } from './landingPage/landing-home/landing-home.component';
import { CementComponent } from './landingPage/cement/cement.component';
import { AutomotiveComponent } from './landingPage/automotive/automotive.component';
import { InvoiceVerificationComponent } from './controls/invoice-verification/invoice-verification.component';
import { DetailedGateReportComponent } from './reports/detailed-gate-report/detailed-gate-report.component';

// Common Dahsboard
import { UTDashboardCommonComponent } from './CommonDashboard/ut-dashboard-common/ut-dashboard-common.component';
import { UTWeighbridgeDashboardCommonComponent } from './CommonDashboard/ut-weighbridge-dashboard-common/ut-weighbridge-dashboard-common.component';
import { UTCTDashboardCommonComponent } from './CommonDashboard/ut-ct-dashboard-common/ut-ct-dashboard-common.component';
import { UTDeviceStatusCommonComponent } from './CommonDashboard/ut-device-status-common/ut-device-status-common.component';
import { VehicleTripSupportComponent } from './support/vehicle-trip-support/vehicle-trip-support.component';
import { ElvSupportComponent } from './support/elv-support/elv-support.component';

const routes: Routes = [

  { path: '', redirectTo: "/home", pathMatch: 'full' },
  // { path: '', redirectTo: "/login", pathMatch: 'full' },

  { path: 'steel', component: SteelComponent },
  { path: 'automotive', component:  AutomotiveComponent},
  { path: 'cement', component: CementComponent },
  { path: 'port', component: PortComponent },
  { path: 'home', component: LandingHomeComponent },

  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  // { path: 'user-registration', component: UserRegistrationComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'user-registration', component: UserRegistrationComponent, canActivate: [AuthGuard] },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
  { path: 'my-assignedincident', component: MyIncidentComponent, canActivate: [AuthGuard] },
  { path: 'nonlogistic-vehicle', component: NonlogisticVehicleComponent, canActivate: [AuthGuard] },
  { path: 'my-incident', component: MyAssignedincidentComponent, canActivate: [AuthGuard] },
  { path: 'gate-report', component: GateInReportComponent, canActivate: [AuthGuard] },
  { path: 'parking-in', component: ParkingInComponent, canActivate: [AuthGuard] },
  { path: 'parking-out', component: ParkingOutComponent, canActivate: [AuthGuard] },
  { path: 'plant-in', component: PlantInComponent, canActivate: [AuthGuard] },
  { path: 'plant-out', component: PlantOutComponent, canActivate: [AuthGuard] },
 // { path: 'vehicle', component: VehicleComponent, canActivate: [AuthGuard] },
  { path: 'transporter-master', component: TransporterMasterComponent, canActivate: [AuthGuard] },
  { path: 'driver', component: DriverComponent, canActivate: [AuthGuard] },
  { path: 'tat-report', component: TatReportComponent, canActivate: [AuthGuard] },
  { path: 'lane-control', component: LaneControlComponent, canActivate: [AuthGuard] },
  { path: 'weigh-bridge-control', component: WeighBridgeControlComponent, canActivate: [AuthGuard] },
  { path: 'weigh-bridge-master', component: WeighBridgeMasterComponent, canActivate: [AuthGuard] },
  // { path: 'milestone-master', component: MilestoneMasterComponent, canActivate: [AuthGuard] },
  //{ path: 'vehicle-blacklisting', component: VehicleBlacklistingComponent, canActivate: [AuthGuard] },
  //{ path: 'driver-blacklisting', component: DriverBlacklistingComponent, canActivate: [AuthGuard] },
  //{ path: 'third-party-vehicle', component: ThirdPartyVehicleComponent, canActivate: [AuthGuard] },
  //{ path: 'transporter-blacklisting', component: TransporterBlacklistingComponent, canActivate: [AuthGuard] },
  { path: 'vehicle-tabs', component: VehicleTabsComponent, canActivate: [AuthGuard] },
  { path: 'device-location-mapping', component: DeviceLocationMappingComponent, canActivate: [AuthGuard] },
  { path: 'blacklisting', component: BlacklistingComponent, canActivate: [AuthGuard] },
  // { path: 'milestone-action', component: MilestoneActionComponent, canActivate: [AuthGuard] },
  { path: 'weighbridge-preference', component: WeighbridgePreferenceComponent, canActivate: [AuthGuard] },
  { path: 'elv', component: ElvComponent, canActivate: [AuthGuard] },
  // { path: 'milestone-action-mapping', component: MilestoneActionMappingComponent, canActivate: [AuthGuard] },
  { path: 'milestone-tabs', component: MilestoneTabsComponent, canActivate: [AuthGuard] },
  { path: 'current-transaction/:vrn', component: CurrentTransactionComponent, canActivate: [AuthGuard] },
  { path: 'transaction/:vrn', component: TransactionComponent, canActivate: [AuthGuard] },
  { path: 'role', component: RoleComponent, canActivate: [AuthGuard] },
  { path: 'userRoleMapping', component: UserRoleMappingMasterComponent, canActivate: [AuthGuard] },
  { path: 'rfidRederSimulation', component: RfidRederSimulationComponent, canActivate: [AuthGuard] },
  { path: 'parkingSimulation', component: ParkingSimulationComponent, canActivate:[AuthGuard]},
  { path: 'plantGateSimulation', component: PlantGateSimulationComponent, canActivate:[AuthGuard]},
  { path: 'weighmentSimulation', component: WeighmentsimulationComponent, canActivate:[AuthGuard]},
  { path: 'loadingSimulation', component: LoadingsimulationComponent, canActivate:[AuthGuard]},
  { path: 'ax4Simulation', component: Ax4SimulationComponent, canActivate: [AuthGuard] },
  { path: 'crossCheck', component: CrossCheckMasterComponent, canActivate: [AuthGuard] },
  { path: 'weighbridge-status', component: WeighbridgestatusComponent, canActivate: [AuthGuard] },
  { path: 'vehicle-parking', component: VehicleParkingComponent },
  { path: 'device-control', component: DeviceControlComponent },
  { path: 'location-mapping', component: LocationMappingComponent },
  { path: 'inplant-transaction', component: InplantTransactionComponent },
  { path: 'weighbridge-transaction/:vrn', component: WeighbridgeTransactionComponent },
  { path: 'api-tracking-report', component: ApiTrackingReportComponent, canActivate: [AuthGuard] },
  { path: 'ct-alertticket', component: AlertTabsComponent, canActivate: [AuthGuard] },
  { path: 'alert-master', component: AlertMasterComponent, canActivate: [AuthGuard] },
  { path: 'CTDashboard', component: CtDashboardComponent, canActivate: [AuthGuard] },
  { path: 'CTusers', component: CTUsersComponent, canActivate: [AuthGuard] },
  { path: 'WBDashboard', component: WbDasboardComponent, canActivate: [AuthGuard] },
  { path: 'CommonMasterData', component: CommonMasterDataComponent, canActivate: [AuthGuard] },
  { path: 'gateTransaction', component: GateTransactionComponent, canActivate: [AuthGuard] },
  { path: 'SLAMaster', component: SLAMasterTabsComponent, canActivate: [AuthGuard] },
  { path: 'JobScheduleMaster', component: JobScheduleMasterComponent, canActivate: [AuthGuard] },
  { path: 'AppConfigMaster', component: AppConfigComponent, canActivate: [AuthGuard] },
  { path: 'gateDashboard', component: GateDashboardComponent, canActivate: [AuthGuard] },
  { path: 'workFlowMaster', component: WorkFlowMasterComponent,canActivate: [AuthGuard]},
  { path: 'FRSEnrollment', component: FrsEnrollmentComponent,canActivate: [AuthGuard]},
  { path: 'apiSimulator', component: ApiSimulationComponent,canActivate: [AuthGuard]},
  { path: 'cementVehicle', component: CementVehicleComponent,canActivate: [AuthGuard]},
  { path: 'deviceconfiguration', component: DeviceConfigurationComponent,canActivate: [AuthGuard]},
  { path: 'inplantapiSimulator', component: InplantApiSimulationComponent, canActivate: [AuthGuard]},
  { path: 'PackerMaster', component: PackerMasterComponent, canActivate: [AuthGuard]},
  { path: 'toleranceAction', component: WeightToleranceActionComponent, canActivate: [AuthGuard]},
  { path: 'weighmentApproval', component: WeighmentApprovalComponent, canActivate: [AuthGuard]},
  { path: 'dailyTATReport', component: DailyTatReportComponent, canActivate: [AuthGuard]},
  { path: 'APICommunicationErrors', component: APICommunicationErrorsComponent, canActivate: [AuthGuard]},
  { path:'upcomingvehiclecount',component: UpComingVehicleReportComponent, canActivate: [AuthGuard ]},
  { path:'invoiceVerification', component:InvoiceVerificationComponent, canActivate: [AuthGuard]},
  {path:'gateChange',component:GateChangeComponent,canActivate:[AuthGuard]},
  {path:'detailgatereport',component:DetailedGateReportComponent,canActivate:[AuthGuard]},
  // { path:'invoiceVerification', component:InvoiceVerificationComponent, canActivate: [AuthGuard]},

  // Common Dashboard
  { path:'CTD1', component:UTDashboardCommonComponent},
  { path:'CTD2', component:UTWeighbridgeDashboardCommonComponent},
  { path:'CTD3', component:UTCTDashboardCommonComponent},
  { path:'CTD4', component:UTDeviceStatusCommonComponent },

  // Support Page Transactions
  {path:'vehicleTripSupport', component:VehicleTripSupportComponent,canActivate:[AuthGuard]},
  {path:'elvSupport', component:ElvSupportComponent,canActivate:[AuthGuard]}

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
