import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { faAnchor } from '@fortawesome/free-solid-svg-icons';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-milestone-tabs',
  templateUrl: './milestone-tabs.component.html',
  styleUrls: ['./milestone-tabs.component.scss']
})
export class MilestoneTabsComponent implements OnInit {
  
  active = 'top';
  _component: any;
  componentsList = [];
  faBan = faBan;
  faAnchor = faAnchor;
  title = 'Milestone Masters';
  public userAccessData: UserScreenAccesData;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private api:DataService
  ) {}

  ngOnInit(): void {
    this.loadTabComponent(0);
    this.userAccessData =this.api.getUserScreenAccessMenu('MILESTONE');
  }

  loadTabComponent(tab: number) {
    if (tab == 0)
      this.title = 'Milestone Masters';
    else if (tab == 1)
      this.title = 'Milestone Action';
    else if (tab == 2)
      this.title = 'Milestone Action Mapping';
  }
}
