import { Component, OnInit } from '@angular/core';
import {
  faDownload,
  faFileAlt,
  faMapPin,
  faSort,
} from '@fortawesome/free-solid-svg-icons';

import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
// import { GateMasterResponse } from 'src/app/models/gatemastermodel';
// import { LaneMasterResponse } from 'src/app/models/lanemastermodel';
import { GateReportModel } from 'src/app/models/reportmodel';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'src/app/service/excel.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-gate-report',
  templateUrl: './gate-report.component.html',
  styleUrls: ['./gate-report.component.scss'],
})
export class GateInReportComponent implements OnInit {
  faMapPin = faMapPin;
  faDownload = faDownload;
  faFileAlt = faFileAlt;
  faSort = faSort;
  gateReport: any;
  gateMaster: any;
  laneMaster: any;
  dataForExcel = [];
  // lane:LaneMasterResponse=new LaneMasterResponse();
  gateReportModel: GateReportModel = new GateReportModel();
  public userAccessData: UserScreenAccesData;


  nonLogisticReport: any;
  nonLogisticMaster: any;
  nonLogisticGateReportModel: GateReportModel = new GateReportModel();
  transTypeDll: any = transTypeDll;
  filter1 = '';
  tempcurrentActivePage1: number = 1;
  currentActivePage1: number = 1;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  currentdate: string;
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################

  constructor(
    private kemarClientService: KemarClientService,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService
    ,private api:DataService
  ) {}

  ngOnInit(): void {
    this.currentdate = new Date().toISOString().split('T')[0];
    this.gateReportModel.fromDate = this.currentdate;
    this.gateReportModel.toDate = this.currentdate;
    this.nonLogisticGateReportModel.fromDate = this.currentdate;
    this.nonLogisticGateReportModel.toDate = this.currentdate;

    this.getGateReport();
    // this.getallgate();
    this.getNonLogisticGateReport();
    this.userAccessData =this.api.getUserScreenAccessMenu('GATEREPORT');
  }

  // getGateReport()  {
  //   this.kemarClientService.postPatch<any>(ServiceUrl.getGateReport,this.gateReportModel)
  //   .subscribe(
  //     response => {
  //       this.gateReport = response;
  //       console.log(this.gateReport);
  //     },
  //     r => {
  //       console.log(r.error.error);
  //     });
  // }
  getGateReport() {

    this.spinner.show();
    var fromDate;
    var ToDate;
    if (
      !this.kemarClientService.commonValidation(this.gateReportModel.fromDate)
    )
      fromDate = this.gateReportModel.fromDate + ' ' + '00:00:00';
    if (!this.kemarClientService.commonValidation(this.gateReportModel.toDate))
      ToDate = this.gateReportModel.toDate + ' ' + '23:59:59';
    var TransType = this.gateReportModel.TransType;
    // console.log(this.gateReportModel.fromDate);
    var query = {
      fromDate: fromDate,
      toDate: ToDate,
      defaultAll: '',
      transType: TransType,
    };
    this.kemarClientService
      .get<GateReportModel>(null, ServiceUrl.getGateReportData, query)
      .subscribe(
        (response) => {

          this.spinner.hide();
          this.gateMaster = response;
          this.gateReport = response;
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }

  getNonLogisticGateReport() {
    this.spinner.show();
    var fromDate;
    var ToDate;
    if (
      !this.kemarClientService.commonValidation(
        this.nonLogisticGateReportModel.fromDate
      )
    )
      fromDate = this.nonLogisticGateReportModel.fromDate + ' ' + '00:00:00';
    if (
      !this.kemarClientService.commonValidation(
        this.nonLogisticGateReportModel.toDate
      )
    )
      ToDate = this.nonLogisticGateReportModel.toDate + ' ' + '23:59:59';
    var query = {
      fromDate: fromDate,
      toDate: ToDate,
    };
    this.kemarClientService
      .get<GateReportModel>(null, ServiceUrl.getNonLogisticGateReport, query)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.nonLogisticReport = response;
          this.nonLogisticMaster = response;
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
    // window.location.reload();
  }

  getallgate() {
    this.spinner.show();
    this.kemarClientService
      .get<any>(null, ServiceUrl.getGateReportData)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.gateMaster = response;
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }
  exportToExcel() {

    this.dataForExcel = [];

    this.gateMaster.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row));
    });
    // console.log(this.gateMaster);

    let gateData = {
      title: 'Gate Report',
      data: this.dataForExcel,
      headers: Object.keys(this.gateMaster[0]),
    };

    this.excelService.exportExcelForGate(gateData);
  }

  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);
  pagechange = (event) =>
    (this.tempcurrentActivePage = this.currentActivePage = event);
  filterchange = (val: any) =>
    !this.kemarClientService.commonValidation(val)
      ? (this.currentActivePage = 1)
      : (this.currentActivePage = this.tempcurrentActivePage);

  pagechange1 = (event) =>
    (this.tempcurrentActivePage1 = this.currentActivePage1 = event);
  filterchange1 = (val: any) =>
    !this.kemarClientService.commonValidation(val)
      ? (this.currentActivePage1 = 1)
      : (this.currentActivePage1 = this.tempcurrentActivePage1);

  exportToExcelNonLogisticVehicle() {

    this.dataForExcel = [];

    this.nonLogisticMaster.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row));
    });
    // console.log(this.nonLogisticMaster);

    let gateData = {
      title: 'Non Logistic Gate Report',
      data: this.dataForExcel,
      headers: Object.keys(this.nonLogisticMaster[0]),
    };

    this.excelService.exportExcelForVehicleMaster(gateData);
  }

}

const transTypeDll: { key: string; value: string }[] = [
  { key: 'All', value: 'undefined' },
  { key: 'Outbound', value: '1' },
  { key: 'Inbound', value: '2' },
  { key: 'Inplant', value: '4' },
  { key: 'NonLogistic', value: '5' },
];
