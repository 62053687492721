import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { DeviceLocationMappingResponse } from 'src/app/models/devicelocationmappingmodel';
import { RFIDResponse } from 'src/app/models/rfidmodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { WeighBridgeControllerResponse } from 'src/app/models/weighbridgecontrollerresponsemodel';
import { DataService } from 'src/app/service/data.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-weighmentsimulation',
  templateUrl: './weighmentsimulation.component.html',
  styleUrls: ['./weighmentsimulation.component.scss']
})
export class WeighmentsimulationComponent implements OnInit {

  public userAccessData: UserScreenAccesData;
  deviceMaster: any;
  submitted = false;
  form: any;
  public RFIDSimulation: RFIDResponse = new RFIDResponse();
  public WeighBridgeControllerResponse: WeighBridgeControllerResponse = new WeighBridgeControllerResponse();
  deviceLocationMappingMaster:any;
  locMapping:any;

  constructor(private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder,

    private kemarClientService: KemarClientService, private modalService: NgbModal,
    private router: Router, private api: DataService) {
      this.form = this.formBuilder.group({

        deviceLocationId: [0],
        RFIDTagNo: [''],

        vrn: [
          '',

        ],
        weight: [''],
        weighBridgeId: [0],
        deviceId: [0],
        Status:[''],
        VRN:[''],
        Comment:[''],
        additionalComment:[''],
        milestoneTransactionCode: [''],
        milestoneCode: [''],
      });
      var route = this.router.url;
    this.userAccessData =this.api.getUserScreenAccessMenuOnRoutingURL(route);
    }

  ngOnInit(): void {
    this.getAllDevice();
    this.getAllDevice();
    this.getAllDeviceLocationMapping();
  }

    getAllDevice() {
      debugger
      this.spinner.show();
      var query = { 'requestId': "S1637", 'LocationType':"Weighbridge"}
       this.kemarClientService.get<any>(null, ServiceUrl.getdeviceMapping,query)
      //this.kemarClientService.get<any>(null, ServiceUrl.GetLocationList,query)
        .subscribe(
          response => {
            debugger
            this.deviceMaster = response.locations.slice().sort((a,b)=> a.deviceName.localeCompare(b.deviceName));
            this.spinner.hide();
          },
          r => {
            this.spinner.hide();
          });
    }

  getAllDeviceLocationMapping() {
    this.kemarClientService.get<DeviceLocationMappingResponse>(null, ServiceUrl.getAllActiveDeviceLocationMappings)
      .subscribe(
        response => {
          debugger
          this.deviceLocationMappingMaster = response;
        this.locMapping=  this.deviceLocationMappingMaster.filter(x => x.deviceType == 'Controller');
        },
        r => {
          alert(r.error.error);
        });
  }

  PostRFIDTAG() {
    debugger
    this.form;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if(this.RFIDSimulation?.deviceLocationId == undefined || this.RFIDSimulation?.deviceLocationId == 0){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Select Location"
      });
      return;
    }
    if(this.RFIDSimulation?.vrn == undefined || this.RFIDSimulation?.vrn == null){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Enter Vehicle Number"
      });
      return;
    }
  this.RFIDSimulation.requestId="s4526"
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.postRFIDTAG, this.RFIDSimulation)
      .subscribe(
        res => {

          Swal.fire({
            icon: 'success',
            title: '',
            text: res.statusMessage
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        },
        r => {
          var errmsg = "";

          if (r.error.title != undefined && r.error.title != null){
              errmsg = r.error.title;
          }
          else
          {
            if (r.error.errorMessage != undefined && r.error.errorMessage != null)
            {
              errmsg = r.error.errorMessage;
            }
            else if (r.error.statusMessage != undefined && r.error.statusMessage != null)
            {
              errmsg = r.error.statusMessage;
            }
            else if ( r.error != null && r.error != null)
            {
              errmsg = r.error;
            }
          }

          Swal.fire({
            icon: 'error',
            title: '',
            text: errmsg
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        });
  }
  PostWeighbridge(WeighBridgeControllerResponse) {
    debugger
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if(this.WeighBridgeControllerResponse?.ipAdress == undefined || this.WeighBridgeControllerResponse?.ipAdress == null || this.WeighBridgeControllerResponse?.ipAdress == ''){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Select Weighbridge"
      });
      return;
    }
    if(this.WeighBridgeControllerResponse?.vrn == undefined || this.WeighBridgeControllerResponse?.vrn == null || this.WeighBridgeControllerResponse?.vrn == ''){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Enter Vehicle Number"
      });
      return;
    }
    if(this.WeighBridgeControllerResponse?.Actualweight == undefined || this.WeighBridgeControllerResponse?.Actualweight == null || this.WeighBridgeControllerResponse?.Actualweight == 0){
      Swal.fire({
        icon: 'error',
        title: '',
        text: "Enter Proper Weight"
      });
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.postWeighbridgeTransaction,WeighBridgeControllerResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success').then(function () {
            window.location.reload();
          })
          this.spinner.hide();
        },
        r => {
          var errmsg = "";

          if (r.error.title != undefined && r.error.title != null){
              errmsg = r.error.title;
          }
          else
          {
            if (r.error.errorMessage != undefined && r.error.errorMessage != null)
            {
              errmsg = r.error.errorMessage;
            }
            else if ( r.error != null && r.error != null)
            {
              errmsg = r.error;
            }
          }
          //errmsg = r.error.errorMessage;
          Swal.fire({
            icon: 'error',
            title: '',
            text: errmsg
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();

        });

  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
}

