import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-device-configuration',
  templateUrl: './device-configuration.component.html',
  styleUrls: ['./device-configuration.component.scss']
})
export class DeviceConfigurationComponent implements OnInit {

  panelOpenState = false;
  form: any;
  submitted = false;
  constructor(private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private spinner: NgxSpinnerService,){ 
    this.form = this.formBuilder.group({
      FRDeviceDetailId:[0],
      deviceIP: [''],

    });

  }

  ngOnInit(): void {
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  AddFRS() {
    debugger;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    let body=this.form.value;
    this.kemarClientService.postPatch<any>(ServiceUrl.addfrs,body)
      .subscribe(
        res => {
          Swal.fire({icon: 'success',title: '',text: res.statusMessage
          })
          this.spinner.hide();
        },
        r => {
          var errmsg = "";
          Swal.fire({icon: 'error', title: '',text: errmsg})
          this.spinner.hide();
        });

  }

}
