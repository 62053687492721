<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <mat-tab-group animationDuration="0ms" mat-align-tabs="end">
    <mat-tab label="Logistic Gate Report">
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <div class="row data-filter my-2 align-items-center">
              <div class="col-sm-3">
                <h4 class="title-one mb-0">Logistic Gate Report </h4>
              </div>

              <div class="col-sm-9 text-end" id="input-filter">
                <div class="input-append">
                  <!-- <span class="material-icons-sharp icon-in">search</span> -->
                  <input class="form-control ps-2" type="search" name="search" placeholder="Search by keyword"
                    [(ngModel)]="filter" (input)="filterchange($event.target.value)">
                </div>
                <select name="gateId" [(ngModel)]="gateReportModel.TransType" class="form-control form-select">
                  <option hidden selected [ngValue]="undefined">All Trip Type</option>
                  <option *ngFor="let transType of transTypeDll" [ngValue]="transType.value">
                    {{transType.key}}</option>
                </select>
                &nbsp;
                <label for="start">From:</label>
                <input class="form-control date" name="fromdate" (keypress)="KeyPressDate($event)"
                  [(ngModel)]="gateReportModel.fromDate" type="date" id="start" value="Date" [max]="currentdate">
                <label for="start" class="to-label">To:</label>
                <input class="form-control date" name="todate" (keypress)="KeyPressDate($event)"
                  [(ngModel)]="gateReportModel.toDate" type="date" id="start" value="Date" [max]="currentdate">
                <button class="btn btn-primary go-btn" (click)="getGateReport()" matTooltip="Search"
                  matTooltipClass="primary-tooltip">
                  <span class="material-icons-round">search</span>
                  <!-- <span class="material-icons-outlined">search</span> -->
                </button>
                <button class="btn btn-success go-btn ms-2" matTooltip="Download Report" matTooltipClass="primary-tooltip"
                  (click)="exportToExcel()">
                  <span class="material-icons-round">file_download</span>
                </button>
              </div>
            </div>
            <div class="table-list custom-table">
              <div class="table-responsive">
                <table class="table table-striped table-hover mx-auto">
                  <thead class="table-head">
                    <tr>
                      <th>Trip No.</th>
                      <th (click)="sort('name')">VRN
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <!--
                        <th (click)="sort('name')">Gate No
                          <span class="glyphicon sort-icon" *ngIf="key =='name'"
                            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                      <th (click)="sort('name')">Trip Type
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('name')"> Entry/Exit Time
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('name')">Direction
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let gate of gateMaster | orderBy: key : !reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">

                      <td>{{gate.transCode}}</td>
                      <td>{{gate.vrn}}</td>
                      <!-- <td>{{gate.gateEntry}}</td> -->
                      <td><span *ngIf="gate.tranType  ==1 ">Outbound</span>
                        <span *ngIf="gate.tranType ==2">Inbound</span>
                        <span *ngIf="gate.tranType ==4">In-Plant</span>
                      </td>
                      <td>
                        {{ gate.transactionDate | date: 'dd-MM-yyyy HH:mm '}}
                      </td>
                      <td> {{gate.direction}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="pagination justify-content-end">
              <!-- <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls> -->
              <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Non-Logistic Gate Report">
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <div class="row data-filter my-2 align-items-center">
              <div class="col-sm-3">
                <h4 class="title-one mb-0"> Non Logistic Gate Report </h4>
              </div>

              <div class="col-sm-9 text-end" id="input-filter">
                <div class="input-append">
                  <!-- <span class="material-icons-sharp icon-in">search</span> -->
                  <input class="form-control ps-2" type="search" name="search" placeholder="Search by keyword"
                    [(ngModel)]="filter1" (input)="filterchange1($event.target.value)">
                </div>
                &nbsp;
                <label for="start">From:</label>
                <input class="form-control date" name="fromdate" (keypress)="KeyPressDate($event)"
                  [(ngModel)]="nonLogisticGateReportModel.fromDate" type="date" id="start" value="Date" [max]="currentdate">
                <label for="start" class="to-label">To:</label>
                <input class="form-control date" name="todate" (keypress)="KeyPressDate($event)"
                  [(ngModel)]="nonLogisticGateReportModel.toDate" type="date" id="start" value="Date" [max]="currentdate">
                <button class="btn btn-primary go-btn" (click)="getNonLogisticGateReport()" matTooltip="Search"
                  matTooltipClass="primary-tooltip">
                  <span class="material-icons-round">search</span>
                  <!-- <span class="material-icons-outlined">search</span> -->
                </button>
                <button class="btn btn-success go-btn ms-2" matTooltip="Download Report" matTooltipClass="primary-tooltip"
                  (click)="exportToExcelNonLogisticVehicle()">
                  <span class="material-icons-round">file_download</span>
                </button>
              </div>
            </div>
            <div class="table-list custom-table">
              <div class="table-responsive">
                <table class="table table-striped table-hover mx-auto">
                  <thead class="table-head">
                    <tr>
                      <th (click)="sort('name')">VRN
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('name')">Gate Name
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('name')"> Entry/Exit Time
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('name')">Direction
                        <span class="glyphicon sort-icon" *ngIf="key =='name'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let gate of nonLogisticReport | orderBy: key : !reverse | filter:filter1 | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage1 }; let i = index">
                      <td>{{gate.vrn}}</td>
                      <td>{{gate.gateName}}</td>
                      <td>
                        {{ gate.transactionDate | date: 'dd-MM-yyyy HH:mm '}}
                      </td>
                      <td> {{gate.direction}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="pagination justify-content-end">
              <pagination-controls (pageChange)="pagechange1($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</section>
<app-footer></app-footer>
