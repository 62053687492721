<ngx-spinner> </ngx-spinner>
<section class="login">
    <div class="container h-100">
        <div class="d-flex align-items-center justify-content-center h-100">
            <div class="card">
                <div class="card-body">
                    <img src="" class="main-logo text-center" alt="">
                    <form class="mt-3" autocomplete="off" [formGroup]="form" (keyup.enter)="login()" (ngSubmit)="onSubmit()">
                        <div class="input-group form-group mt-2 ">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon [icon]="faUser"></fa-icon>
                                </span>
                            </div>
                            <input type="text" class="form-control form-control-user mt-0" id="Username" name="Username"
                                [(ngModel)]="userLogin.userName" placeholder="User Name" formControlName="username"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autofocus>
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">User Name is required
                                </div>
                                <div *ngIf="f.username.errors.minlength">
                                    User Name must be at least 3 characters
                                </div>
                                <div *ngIf="f.username.errors.maxlength">
                                    User name must not exceed 20 characters
                                </div>
                            </div>

                        </div>
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                        <fa-icon [icon]="faKey"></fa-icon>
                                   </span>
                                    <!-- <fa-icon [icon]="faKey"></fa-icon> -->
                            </div>
                            <input [type]="isPasswordVisible ? 'text' : 'password'" class="form-control form-control-user" id="UserPassword"
                                name="UserPassword" [(ngModel)]="userLogin.password" placeholder="Password"
                                formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required
                                </div>
                                <div *ngIf="f.password.errors.minlength">
                                    Password must be at least 5 characters
                                </div>
                                <div *ngIf="f.password.errors.maxlength">
                                    Password must not exceed 20 characters
                                </div>
                            </div>
                            <span class="input-group-text">
                              <i
                                class="fa"
                                [ngClass]="{'fa-eye-slash': !isPasswordVisible,'fa-eye': isPasswordVisible}"
                                (click)="isPasswordVisible = !isPasswordVisible"
                              ></i>
                            </span>
                        </div>
                      </form>
                        <div class="form-check align-items-center">
                            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="rememberMe" id="rememberMe">
                            <label class="form-check-label" for="rememberMe">
                                Remember Me
                            </label>
                        </div>

                        <button (click)="login()" id="login-btn" type="submit" value="Login"
                            class="btn btn-primary login-btn" [disabled]="submitted || !form.valid "> Login </button>
                        <a routerLink="/forgot-password" class="forgot-pass text-end mt-2">Forgot Password?</a>

                    <p class="copyright text-center"> Powered by <a href="https://www.kemarautomation.in/"> Kemar Port
                            Automation Pvt. Ltd.</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
