<ngx-spinner> </ngx-spinner>
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-md-6">
    <!-- <div class="input-append">
      <span class="material-icons-sharp icon-in">
        search
      </span>
      <input class="form-control" type="text" name="search" (input)="filterchange($event.target.value)"
        placeholder="Search" [(ngModel)]="filter">
    </div> -->
    <div class="input-append">
      <span class="material-icons-sharp icon-in">
        search
      </span>
      <input class="form-control" type="search" name="search" placeholder="search" [(ngModel)]="filter"
      (ngModelChange)="currentActivePage3 = 1">
    </div>
  </div>
  <div class="col-md-6 text-end">
    <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Milestone"
      matTooltipClass="danger-tooltip"  *ngIf="userAccessData[0]?.canCreate == true">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
  </div>
</div>

<div class="table-list custom-table">
  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover mx-auto">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>
          <th (click)="sort('name')" class="col location">Milestone Name
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="col location">Milestone Code
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="col location">Milestone Event
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <!-- <th (click)="sort('name')" class="col location">Description
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th> -->
          <th (click)="sort('name')" class="col">Status
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th class="action-key text-center"  *ngIf="userAccessData[0]?.canUpdate ==true">Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let milestone of milestoneMaster | orderBy: key : reverse | filter:filter | paginate: { id : 'pagination1', itemsPerPage: pageSize3, currentPage: currentActivePage3}; let i = index">
          <td>{{ i +1 + pageSize3*(currentActivePage3-1) }}</td>
          <td class="left">{{milestone.milestoneName}}</td>
          <td>{{milestone.milestoneCode}}</td>
          <td class="left">{{milestone.milestoneEvent}}</td>
          <!-- <td>{{milestone.description}}</td> -->
          <td>
            <span *ngIf="milestone.isActive  ==true ">Active</span>
            <span *ngIf="milestone.isActive  ==false ">Inactive</span>
          </td>
          <td class="text-center" *ngIf="userAccessData[0]?.canUpdate ==true">
            <span class="edit-btn-icon" type="button" matTooltip="Edit Milestone" matTooltipClass="danger-tooltip"
              (click)="editMileStone(edit, milestone) " >
              <fa-icon [icon]="faEdit"></fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination justify-content-end">
  <!-- <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls> -->
  <!-- <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls> -->
  <pagination-controls id="pagination1" (pageChange)="pagechange($event)"></pagination-controls>
</div>

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <form autocomplete="off" [formGroup]="form">
            <div class="row">
              <div class="form-group ml-3">
                <input type="hidden" minlength="3" maxlength="30" class="form-control" name="milestoneId"
                  value="{{milestone.milestoneId}}" />
              </div>
              <div class="form-group mb-2 col-sm-4">
                <label for="mileStoneName">Milestone Name <sup>*</sup></label>
                <input type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.milestoneName.errors }" formControlName="milestoneName"
                  [(ngModel)]="milestone.milestoneName" />
                <div *ngIf="submitted && f.milestoneName.errors" class="invalid-feedback">
                  <div *ngIf="f.milestoneName.errors.required">Milestone Name is required.
                  </div>
                  <div *ngIf="f.milestoneName.errors.minlength">
                    Milestone name must be at least 3 characters.
                  </div>
                  <div *ngIf="f.milestoneName.errors.maxlength">
                    Milestone name must not exceed 30 characters.
                  </div>
                  <div *ngIf="f.milestoneName.errors?.pattern">
                    <sup>*</sup>Milestone Name should contain only a-z,A-Z.
                  </div>
                  <!-- <div *ngIf="f.milestoneName.hasError('whitespace')">Blank space Not Allowed</div> -->
                </div>
              </div>
              <div class="form-group mb-2 col-sm-4">
                <label for="milestoneEvent">Milestone Event <sup>*</sup></label>
                <input type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.milestoneEvent.errors }" formControlName="milestoneEvent"
                  [(ngModel)]="milestone.milestoneEvent" />
                <div *ngIf="submitted && f.milestoneEvent.errors" class="invalid-feedback">
                  <div *ngIf="f.milestoneEvent.errors.required">Milestone Event is required.
                  </div>
                  <div *ngIf="f.milestoneEvent.errors.minlength">
                    Milestone Event must be at least 2 characters.
                  </div>
                  <div *ngIf="f.milestoneEvent.errors.maxLength">
                    Milestone Event must not exceed 30 characters.
                  </div>
                  <div *ngIf="f.milestoneEvent.errors?.pattern">
                    <sup>*</sup>Milestone Event should contain only a-z,A-Z,-.
                  </div>
                  <!-- <div *ngIf="f.milestoneEvent.hasError('whitespace')">Blank space Not Allowed</div> -->
                </div>
              </div>
              <div class="form-group mb-2 col-sm-4">
                <label for="mileStoneCode">Milestone Code <sup>*</sup></label>
                <input type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.milestoneCode.errors }" formControlName="milestoneCode"
                  [(ngModel)]="milestone.milestoneCode" />
                <div *ngIf="submitted && f.milestoneCode.errors" class="invalid-feedback">
                  <div *ngIf="f.milestoneCode.errors.required">Milestone Code is required.
                  </div>
                  <div *ngIf="f.milestoneCode.errors.minlength">
                    Milestone Code must be at least 3 characters.
                  </div>
                  <div *ngIf="f.milestoneCode.errors.maxLength">
                    Milestone Code must not exceed 30 characters.
                  </div>
                  <div *ngIf="f.milestoneCode.errors.pattern">Milestone Code should contain only a-z,A-Z,-,.
                  </div>
                  <div *ngIf="f.milestoneCode.hasError('whitespace')"></div>
                </div>
              </div>
              <div class="form-group mb-2">
                <label for="description">Description</label>
                <textarea type="text" class="form-control" placeholder="" value=" " formControlName="description"
                  [(ngModel)]="milestone.description"></textarea>
              </div>

              <span *ngIf="activebuttonHide">
                <div class="select-radio">
                  <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="milestone.isActive"
                    formControlName="isActive">
                  <label for="active" class="radio-label">Active</label>

                  <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="milestone.isActive"
                    formControlName="isActive">
                  <label for="inactive" class="radio-label">Inactive</label>
                </div>
              </span>
              <!-- <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="1" [value]="true" [(ngModel)]="milestone.isActive" formControlName="isActive">Active</mat-radio-button>
                    <mat-radio-button class="mx-4" value="2" [value]="false" [(ngModel)]="milestone.isActive" formControlName="isActive">Inactive</mat-radio-button>
                  </mat-radio-group> -->
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="registerMilestone(milestone)">Save</button>
    </div>
  </div>
</ng-template>
