import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { TokenStorageService } from '../service/token-storage.service';
import { AuthguardService } from '../service/authguard-service.service';
import { KemarClientService } from '../service/kemar-client.service';
import { ServiceUrl } from '../common/service-urls';
import { TokenModel } from '../models/usermodel';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  public tokenModel: TokenModel = new TokenModel();
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private tokenService: TokenStorageService,
    private authService: AuthguardService,
    private kemarClientService: KemarClientService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    var userName = this.tokenService.getUserName();
    const token = this.tokenService.getToken(userName);
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    // return next.handle(authReq);
    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes(ServiceUrl.authenticate) && error.status === 401) {
        if (authReq.url.includes("refreshtoken")) {
          this.tokenService.signOut();
      }

        return this.handle401Error(authReq, next);
      }
      return throwError(error);
    }));

  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.tokenService.getRefreshToken();
      this.tokenModel.token = refreshToken;
      if (refreshToken) {
        this.kemarClientService.postPatch<any>(ServiceUrl.refreshToken, this.tokenModel)
          .subscribe(response => {
            if (response.jwtToken !== null) {
              this.isRefreshing = false;
              this.tokenService.saveToken(response.jwtToken, response.userName);
              this.tokenService.setRefreshToken(response.refreshToken);
              this.refreshTokenSubject.next(response.jwtToken);

              return next.handle(this.addTokenHeader(request, response.jwtToken));
            }
          },
            r => {
              // console.log(r);
              this.isRefreshing = false;
              this.tokenService.signOut();
              return throwError(r);
            });

      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
