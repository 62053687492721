<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-4"*ngIf="userAccessData[0].canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Common Master Data</h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">
                search
              </span>
              <input class="form-control" type="search" name="search" placeholder="search" [(ngModel)]="filter"
              (ngModelChange)="currentActivePage = 1">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add CommonMaster"
              matTooltipClass="danger-tooltip" *ngIf="userAccessData[0].canCreate ==true">
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr. No.
                  </th>
                  <th (click)="sort('name')">Type
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Parameter
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Value
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Status
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th class="action-key text-center"*ngIf="userAccessData[0].canUpdate ==true">Action</th>
                </tr>
              </thead>
              <tbody>
                <!-- <input type="hidden" name="WeighBridgeId" /*value={{WeighBridge.WeighBridgeId}}*/ /> -->
                <tr
                  *ngFor="let CommonMaster of commonmasterdata | orderBy: key : reverse | filter: filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="left">{{CommonMaster.type}}</td>
                  <td class="left">{{CommonMaster.parameter}}</td>
                  <td>{{CommonMaster.value}}</td>
                  <td>
                    <span *ngIf="CommonMaster.isActive  ==true ">Active</span>
                    <span *ngIf="CommonMaster.isActive  ==false ">Inactive</span>
                  </td>
                  <td class="text-center"*ngIf="userAccessData[0].canUpdate ==true">
                    <span class="edit-btn-icon" type="button" matTooltip="Edit CommonMasterData"
                      matTooltipClass="danger-tooltip" (click)="editCommonMaster(edit, CommonMaster) ">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')" (click)="onReset()">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="formGroup mb-2">
          <div class="form-group row mb-2">
            <div class="col-sm-12">
              <div class="form-group">
                <input type="hidden" class="form-control" name="id" formControlName="id" [(ngModel)]="CommonMaster.id" [Value]="id" />
              </div>
              <label for="type"> Type <sup>*</sup></label>
              <select name="type" class="form-control form-select" formControlName="type" [(ngModel)]="CommonMaster.type"
                [ngClass]="{ 'is-invalid': submitted && f.type.errors}">
                <option class="dropdown-item" hidden selected value="">Select Type</option>
                <option *ngFor="let commonMaster of typeList" [ngValue]="commonMaster">
                  {{commonMaster}}</option>
              </select>
              <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                <div *ngIf="f.type.errors.required">
                  <small>Type is required.</small>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <label for="parameter">Parameter <sup>*</sup></label>
              <select name="parameter" class="form-control form-select" formControlName="parameter" [(ngModel)]="CommonMaster.parameter"
                [ngClass]="{ 'is-invalid': submitted && f.parameter.errors}">
                <option class="dropdown-item" hidden selected value="">Select Parameter</option>
                <option *ngFor="let commonMaster of parameterList" [ngValue]="commonMaster">
                  {{commonMaster}}</option>
              </select>
              <div *ngIf="submitted && f.parameter.errors" class="invalid-feedback">
                <div *ngIf="f.parameter.errors.required">
                  <small>Parameter is required.</small>
                </div>
              </div>
              <!-- <input type="text" class="form-control" id="parameter" name="parameter" placeholder=""
                formControlName="parameter" [(ngModel)]="CommonMaster.parameter"
                [ngClass]="{'is-invalid': submitted && f.parameter.errors}">
              <div *ngIf="submitted && f.parameter.errors" class="invalid-feedback">
                <div *ngIf="f.parameter.errors.required">
                  <small>Parameter is required</small>
                </div>
              </div> -->
            </div>
          </div>
          <div class="form-group row mb-2">
            <div class="col-sm-12">
              <label for="value">Value <sup>*</sup></label>
              <input type="text" class="form-control" id="value" name="value" placeholder="" formControlName="value"
                [(ngModel)]="CommonMaster.value" [ngClass]="{'is-invalid': submitted && f.value.errors}">
              <div *ngIf="submitted && f.value.errors" class="invalid-feedback">
                <div *ngIf="f.value.errors.required">
                  <small>Value is required</small>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-6" hidden>
              <label for="dataItemId">DataItemId</label>
              <input type="text" class="form-control" id="dataItemId" name="dataItemId" placeholder=""
              formControlName="dataItemId" [(ngModel)]="CommonMasterRequest.dataItemId" [ngValue]="CommonMasterRequest.dataItemId" [ngClass]="{'is-invalid': submitted && f.dataItemId.errors}"> 
              <div *ngIf="submitted && f.dataItemId.errors" class="invalid-feedback">
              </div>
            </div> -->
            <div class="select-radio" [hidden]="activebuttonShow">
              <!-- [hidden]="activebuttonShow" -->
              <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="CommonMaster.isActive"
                formControlName="isActive">
              <label for="active" class="radio-label">Active</label>

              <input class="radio-input" type="radio" id="inActive" [value]="false" [(ngModel)]="CommonMaster.isActive"
                formControlName="isActive">
              <label for="inActive" class="radio-label">Inactive</label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')" (click)="onReset()">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="addCommonMasterData()">Save</button>
    </div>
  </div>
</ng-template>

<footer class="sticky-footer bg-black text-light">
  <app-footer></app-footer>
</footer>