import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DataService } from '../service/data.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ChangePassword } from '../models/usermodel';
import { ServiceUrl } from 'src/app/common/service-urls';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  errMsg: string = "";
  userDetails: any;
  model: ChangePassword = new ChangePassword();
  submitted = false;
  changePasswordForm: UntypedFormGroup;
  editUserFormSubmitted = false;
  spinner: any;
  Passss: string;

  constructor(
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    public api: DataService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    // console.log("oninit");
    // this.model.confirmPassword = "";
    // this.model.oldPassword = "";
    // this.model.password = "";
    // console.log(this.model);
    this.userDetails = this.api.getUserDetail()
    this.changePasswordForm = this.formBuilder.group(
      {
        // firstName: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.minLength(3),
        //     Validators.maxLength(20)
        //   ]
        // ],

        // lastName: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.minLength(3),
        //     Validators.maxLength(20)
        //   ]
        // ],

        // email: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.email,
        //     Validators.minLength(5),
        //     Validators.maxLength(50)
        //   ]
        // ],

        password: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*[#$^+=!*()@%&]).{5,}$")
          ]
        ],
        confirmPassword: ['',
          [
            Validators.required,
            Validators.minLength(5)
          ]
        ],
        oldPassword: ['', [
          Validators.required,
          Validators.minLength(5)
        ]],
        // acceptTerms: [true, Validators.requiredTrue],
        // userName: [
        //   '',
        //   [
        //     Validators.required,
        //     Validators.minLength(3),
        //     Validators.maxLength(20)
        //   ]
        // ],

      },

    );
  }

  onSubmit() {
    this.editUserFormSubmitted = true;
    if (this.changePasswordForm.invalid) {

      return;
    }


    if (this.model.password != this.model.confirmPassword) {
      Swal.fire("Password and Confirm Password do not match", "", "error");
    }
    else {
      this.changeUserPassword();
    }

  }

  get f1(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }

  changeUserPassword() {
    this.submitted = true;
    let changePassword = new ChangePassword();
    changePassword.userName = this.userDetails.userName;
    changePassword.password = this.model.password;
    changePassword.oldPassword = this.model.oldPassword;
    changePassword.confirmPassword = this.model.confirmPassword;

    let err = false;
    this.kemarClientService.postPatch<string>(ServiceUrl.changePassword, changePassword)
      .subscribe(
        (res) => {
          Swal.fire("Password changed succesfully", "", "success");
          this.modalService.dismissAll();
          this.model = new ChangePassword();
        },
        (r) => {

          err = true;
          this.errMsg = r;
          if(r.error==null)
          {
            Swal.fire("Password changed succesfully", "", "success");
            this.modalService.dismissAll();
            this.model = new ChangePassword();
            this.router.navigateByUrl('/login');
          }
          else
          {
            Swal.fire(r.error, "", "error");
            // this.modalService.dismissAll();
            // this.model = new ChangePassword();
          }
        }
      );
    // .subscribe(
    //   data => {
    //     Swal.fire("Password changed succesfully", "" , "info");
    //
    //   },
    //   error => {
    //
    //     err = true;
    //     this.errMsg = error;
    //     Swal.fire(error.error , "" , "error");
    //   });
    //   if ( err == false)
    //   {
    //    Swal.fire("Password changed succesfully", "" , "info");
    //    this.router.navigateByUrl('/login');
    //   }

    //   this.modalService.dismissAll();
    //   this.model = new ChangePassword();
  }
  open(content: any) {
    this.editUserFormSubmitted = false;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };

    this.modalService.open(content, ngbModalOptions);
    this.model.confirmPassword = null;
    this.model.password = null;
    this.model.oldPassword = null;
  }
} //class MyProfilecontent

