<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-2 gate-transaction" *ngIf="IsShowflag == true && userAccessData[0]?.canRead ==true">
  <h1>System Not Configured !!!</h1>
</section>
<section class="my-2 gate-transaction" *ngIf="IsShowflag == false && userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-8">
        <div class="card" style="background-color:#DADEE7; min-height: 440px;">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <ul class="list-unstyled list-inline">
                  <li class="list-inline-item me-4"> {{currentVehicleDetails?.weighbridgeName}} - {{currentVehicleDetails?.wbStatus}}</li><br>
                  <li class="list-inline-item"> <strong>Weighbridge Message: </strong> ({{currentVehicleDetails?.wbMessage}})</li>
                </ul>
              </div>
              <div class="col text-end ">
                <ul class="list-unstyled list-inline">
                  <!-- <li class="list-inline-item " ><span class="material-icons "> check_box </span>{{currentVehicleDetails?.tripType}}</li> -->
                  <li class="list-inline-item ">
                    {{currentVehicleDetails?.tripType}}</li>
                </ul>
              </div>
            </div>
            <span *ngIf="milestoneList != null && milestoneList.length > 0 ">
              <div class="p-4 d-flex  justify-content-center">
                <div class="col-sm-4">
                  <div class="xcvvxc">
                    <ul class="list-unstyled mb-0">
                        <li class="list-item align-items-center" *ngFor=" let obj of milestoneList ">
                          <span class="material-icons text-success" *ngIf="obj.status == 'Completed'"> check_box </span>
                          <span class="material-icons  text-success" *ngIf="obj.status == 'Pending'">
                          </span>
                          <span class="material-icons  text-danger" *ngIf="obj.status == 'Failed'"> disabled_by_default
                          </span>
                          <label for=""> {{obj.milestoneAction }}
                            <small class="date d-block">Date: {{obj.completionTime |date:'dd-MM-yyyy HH:mm'}}
                            </small>
                          </label>
                        </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-4">
                  <h4 class="title-one">Vehicle Details</h4>
                  <ul class="vehicle-details list-unstyled">
                    <li class="list-item"> <strong>Vehicle No:</strong> <span>{{currentVehicleDetails?.vrn}}</span>
                    </li>
                    <li class="list-item"> <strong>Weighment Type:</strong>
                      <span>{{currentVehicleDetails?.weighmentType}}</span>
                    </li>
                    <li class="list-item"> <strong>Shipment No:</strong>
                      <span>{{currentVehicleDetails?.shipmentNo}}</span>
                    </li>
                    <li class="list-item" *ngIf="currentVehicleDetails?.weighmentType == 'GROSS'"> <strong>Tare Weight:</strong>
                      <span>{{currentVehicleDetails?.tareWeight}} KG</span>
                    </li>
                    <li class="list-item"> <strong>Excepted Weight:</strong>
                      <span>{{currentVehicleDetails?.expectedWeight}} KG</span>
                    </li>
                    <li class="list-item" *ngIf="currentVehicleDetails?.weighmentStatus != 'Pending'"> <strong>Actual Weight:</strong>
                      <span>{{currentVehicleDetails?.actualWeight}} KG</span>
                    </li>
                    <li class="list-item" *ngIf="currentVehicleDetails?.weighmentStatus != 'Pending'"> <strong>Weight Difference:</strong>
                      <span>{{currentVehicleDetails?.weightDifference}} KG</span>
                    </li>
                    <li class="list-item" *ngIf="currentVehicleDetails?.weighmentStatus != 'Pending' &&
                    currentVehicleDetails?.weighmentType == 'GROSS'"> <strong>Net Loaded:</strong>
                      <span>{{currentVehicleDetails?.netWeight}}</span>
                    </li>
                    <li class="list-item" *ngIf="currentVehicleDetails?.weighmentStatus != 'Pending'
                     && currentVehicleDetails?.weighmentType == 'GROSS'"> <strong>Vehicle Loaded:</strong>
                      <span [ngClass]="{ 'text-danger fw-bold': vehicleLoadedPer > 100 }">{{currentVehicleDetails?.vehicleLoaded}}%</span>
                    </li>
                    <li class="list-item"> <strong>Weighment Status:</strong> <span>{{currentVehicleDetails?.weighmentStatus}}</span></li>
                    <!-- <span *ngIf=" currentVehicleDetails?.currentMilestone == 'EIMG'">
                      <div class="comments">
                        <textarea  class="form-control" type="text" rows="1"  placeholder="comment" [(ngModel)]="remarks" ></textarea>
                    </div>
                      <br>
                      <button (click)="onChangecheckbox()" type="button" class="btn btn-success me-2"> Allow </button>
                      <button (click)="onChangecheckbox('flag')" type="button" class="btn btn-danger"> Reject </button>
                    </span> -->
                  </ul>
                </div>

                <div class="col-sm-4">
                  <h4 class="title-one">Driver Detail</h4>
                  <ul class="vehicle-details list-unstyled">
                    <img class="driverImage" id="driverImageImg" [src]="currentVehicleDetails?.driverImageURL"
                     onerror="this.onerror=null; this.src='../../../assets/images/ImageNotFound.png'" [alt]="currentVehicleDetails?.driverName">
                    <!-- <img class="driverImage" src="https://urgetruckvjnr.jsw.in/WBImages/DriverImages/355954909541.png" alt="{{currentVehicleDetails?.driverName}}"> -->
                    <br>
                    <li class="list-item text-center mt-2"> <strong>{{currentVehicleDetails?.driverName}}</strong>
                    </li>
                    <span *ngIf=" currentVehicleDetails?.currentAction == 'WEIGHMENTAPPROVAL'">
                      <div class="comments">
                        <textarea  class="form-control" type="text" rows="1"  placeholder="comment" [(ngModel)]="remarks" ></textarea>
                    </div>
                      <br>
                      <button (click)="updateWeightApprovalAction('APPROVE')" type="button" class="btn btn-success me-2"> Allow </button>
                      <button (click)="updateWeightApprovalAction('REJECT')" type="button" class="btn btn-danger"> Reject </button>
                    </span>
                  </ul>
                </div>
              </div>
            </span>
            <span *ngIf=" milestoneList == null || milestoneList.length == 0  ">
              <div class="d-flex align-items-center justify-content-center">
                <div class="col-md-12">
                  <div class="text-center no-detected">
                      <span class="material-icons text-primary fs-1">local_shipping</span>
                      <h1 class="fs-3 text-primary"> Waiting for vehicle</h1>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card" style="height: 525px;">
          <div class="card-body">
            <h4 class="title-one mb-2"> Product details </h4>
            <!-- <p-chart type="bar" [data]="basicData" width="auto" height="380px" [options]="basicOptions"></p-chart>
            <p-chart type="bar" [data]="basicData" width="auto" height="380" [options]="basicOptions"></p-chart> -->
            <div class="table-responsive">

            <table class="table table-responsive table-striped table-bordered table-hover mx-auto"
            mdbTable mdbTableScroll scrollY="true" maxHeight="200" bordered="true" >
              <thead class="table-head">
                <tr>
                  <th class="">Product</th>
                  <th class="">Batch No.</th>
                  <th class="">Qty.</th>
                  <th class="">Weight</th>
                  <th class="">Load Status</th>

                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let product of productDetails; let i = index">
                  <td class="left">{{product.productCode}}</td>
                  <td class="left">{{product.batchNo}}</td>
                  <td class="left">{{product.quantity}}</td>
                  <td class="left">{{product.weight}} KG</td>
                  <td class="left">
                    <span *ngIf="product.isLoaded == true">Loaded</span>
                    <span *ngIf="product.isLoaded == false && currentVehicleDetails?.tripType == 'Inbound'">Unloaded</span>
                    <span *ngIf="product.isLoaded == false && currentVehicleDetails?.tripType != 'Inbound'">Not loaded</span>
                  </td>

                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row border-bottom">
              <div class="col">
                <h4 class="title-one">Vehicle Queue</h4>
              </div>
            </div>
            <div class="vehicle-queues row pt-2">
              <p-carousel [value]="upcomingVehicle" [numVisible]="6" [numScroll]="6" [circular]="false"
                [responsiveOptions]="responsiveOptions" id="queues">
                <ng-template let-upcoming pTemplate="item">
                  <div class="upcoming-item">
                    <div class="upcoming-item-content">
                      <div>
                        <h4 class="outbound text-center" *ngIf="upcoming.tranType == 1 "> OutBound </h4>
                        <h4 class="inbound text-center" *ngIf="upcoming.tranType == 2 "> InBound </h4>
                        <h4 class="inbound text-center" *ngIf="upcoming.tranType == 4 "> Inplant </h4>
                        <h4 class="number text-center">{{upcoming.vrn}}</h4>
                        <h4 class="loading text-center">{{upcoming.driverName}}</h4>
                        <h4 class="flyash text-center">{{upcoming.driverMobile}}</h4>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<footer></footer>
