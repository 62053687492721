export class TicketModel {
    ticketId: number
    raisedUserId: number;
    assignedUserId: number;
    locationName: string;
    locationId: number;
    priority: number;
    categoryId: number;
    subCategoryId: number;
    ticketSubject: string;
    ticketDescription: string;

    status: string;
    remarks: string;
    vrn: string;
    driverName: string;
    driverNo: string;
    serviceSLATime: string;
}

export class ticketTransactionHistory {
    ticketHistoryId: number;
    ticketId: number;
    userId: number;
    status: string;
    remarks: string;
}

export class MyIncidentModel {
    ticketId: number = 0;
    locationName: string ;
    locationId: number;
    priority: number = 0;
    categoryId: number = 0;
    subCategoryId: number = 0;
    ticketSubject: string;
    TicketDescription: string;
    status: string;
    remarks: string;
    assignedUserId: number;

    // ticketTransactionHistory: ticketTransactionHistory = new ticketTransactionHistory();
}

