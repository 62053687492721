import { Component, OnInit } from '@angular/core';
import { faDownload, faFileAlt, faMapPin, faSort } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { TatReportModel } from 'src/app/models/reportmodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { ExcelService } from 'src/app/service/excel.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';

@Component({
  selector: 'app-daily-tat-report',
  templateUrl: './daily-tat-report.component.html',
  styleUrls: ['./daily-tat-report.component.scss']
})
export class DailyTatReportComponent implements OnInit {

  faMapPin = faMapPin
  faDownload = faDownload
  faFileAlt = faFileAlt
  faSort = faSort
  tatReport: any;
  gateMaster: any;
  laneMaster: any;
  tatReportModel:TatReportModel = new TatReportModel();
  dataForExcel = [];
  VehicleTat: any;
  transTypeDll: any[];
  public userAccessData: UserScreenAccesData;
  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  currentdate: string;
  tempcurrentActivePage: number = 1;
  currentPage = 1;
  totalRecords = 0;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################

  constructor(private kemarClientService: KemarClientService,
    private excelService: ExcelService, private spinner: NgxSpinnerService,
    private api:DataService
  ) { }

  ngOnInit(): void {
    this.currentdate = new Date().toISOString().split('T')[0];
    this.tatReportModel.fromDate = this.currentdate;
    this.tatReportModel.toDate = this.currentdate;
    this.getTatReport();
    this.userAccessData =this.api.getUserScreenAccessMenu('dailyTATReport');
  }

  getTatReport() {
    this.spinner.show();
    var fromDate;
    var ToDate;
    if (!this.kemarClientService.commonValidation(this.tatReportModel.fromDate))
      fromDate = this.tatReportModel.fromDate + " " + "00:00:00";
    if (!this.kemarClientService.commonValidation(this.tatReportModel.toDate))
      ToDate = this.tatReportModel.toDate + " " + "23:59:59";
    var transType = this.tatReportModel.tranType;
    var query = { 'fromDate': fromDate, 'toDate': ToDate }
    this.kemarClientService.get<TatReportModel>(null, ServiceUrl.getDailyTatReport, query)
      .subscribe(
        response => {
          this.spinner.hide();
          this.tatReport = response;
          this.totalRecords = this.tatReport.length > 0 ? this.tatReport.length : 0;
        },
        r => {
          this.spinner.hide();
        });
  }

  exportToExcel() {

    this.dataForExcel = [];
    this.tatReport.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row))
    })

    let tatData = {
      title: 'Day wise TAT Report',
      data: this.dataForExcel,
      headers: Object.keys(this.tatReport[0])
    }

    this.excelService.exportExcelForDailyTATReport(tatData);
  }


  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);
  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage
}
