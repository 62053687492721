import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cement',
  templateUrl: './cement.component.html',
  styleUrls: ['./cement.component.scss']
})
export class CementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
