<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2"*ngIf="userAccessData[0]?.canRead == true">
    <div class="container-fluid" >
        <div class="card">
            <div class="card-body">
                <div class="customize-mattab">
                    <div class="position-absolute mt-2">
                        <h4 class="title-one mb-0">
                            <!--<fa-icon [icon]="faAnchor" class="faicon"></fa-icon>-->
                            {{title}}
                        </h4>
                    </div>
                    <mat-tab-group animationDuration="10ms" mat-align-tabs="end"
                        (selectedTabChange)="loadTabComponent($event.index)" >
                        <mat-tab label="Work Flow" >
                            <div class="d-flex data-filter align-items-center my-2">
                                <div class="col-md-6">
                                    <div class="input-append">
                                        <span class="material-icons-sharp icon-in">
                                            search
                                        </span>
                                        <input class="form-control" type="search" name="search" placeholder="search"
                                            (ngModelChange)="currentActivePage1 = 1" [(ngModel)]="filter">
                                    </div>
                                </div>
                                <div class="col-md-6 text-end">
                                    <button class="btn btn-primary go-btn"
                                        (click)="openWorkFlowMaster(editWorkFlowMaster)" matTooltip="Add WorkFlow"
                                        matTooltipClass="danger-tooltip" *ngIf="userAccessData[0]?.canCreate == true">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </div>
                            </div>

                            <div class="table-list custom-table" >
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered table-hover mx-auto">
                                        <thead class="table-head">
                                            <tr>
                                                <th class="sr-no">Sr. No.</th>
                                                <th (click)="sort('name')" class="col location">WorkFlow Code
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]='glyphicon-chevron-up'>
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')" class="col location">WorkFlow Name
                                                  <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                      [ngClass]='glyphicon-chevron-up'>
                                                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                  </span>
                                              </th>
                                                <th (click)="sort('name')" class="col location">Work Flow Type
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]='glyphicon-chevron-up'>
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')" class="col location">Description
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]='glyphicon-chevron-up'>
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')" class="col">Status
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th class="action-key text-center"
                                                    *ngIf="userAccessData[0]?.canUpdate == true">Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let workFlow of workFlowMaster  | filter:filter | paginate: { id : 'pagination1', itemsPerPage: pageSize1, currentPage: currentActivePage1}; let i = index">
                                                <td>{{ i +1 + pageSize1*(currentActivePage1-1) }}</td>
                                                <td class="left">{{workFlow.workFlowCode}}</td>
                                                <td class="left">{{workFlow.workFlowName}}</td>
                                               <td>{{workFlow.workFlowType}}</td>
                                                <td class="left">{{workFlow.description}}</td>
                                                <!-- <td>{{milestone.description}}</td> -->
                                                <td>
                                                    <span *ngIf="workFlow.isActive  ==true ">Active</span>
                                                    <span *ngIf="workFlow.isActive  ==false ">Inactive</span>
                                                </td>
                                                <td class="text-center" *ngIf="userAccessData[0]?.canUpdate == true">
                                                    <span class="edit-btn-icon" type="button"
                                                        matTooltip="Edit Work Flow" matTooltipClass="danger-tooltip"
                                                        (click)="editworkFlow(editWorkFlowMaster, workFlow) ">
                                                        <fa-icon [icon]="faEdit"></fa-icon>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="pagination justify-content-end">
                                <!-- <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls> -->
                                <!-- <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls> -->
                                <pagination-controls id="pagination1"
                                    (pageChange)="pagechange($event)"></pagination-controls>
                            </div>
                        </mat-tab>
                        <mat-tab label="Work Flow Detail">
                            <div class="d-flex data-filter align-items-center my-2">
                                <div class="col-md-6">
                                    <div class="input-append">
                                        <span class="material-icons-sharp icon-in">
                                            search
                                        </span>
                                        <input class="form-control" type="search" name="search" placeholder="search"
                                            (ngModelChange)="currentActivePage2 = 1" [(ngModel)]="filter1">
                                    </div>
                                    <div class="input-append">
                                      <select name="workFlowName"class="form-control form-select me-2 "
                                        (change)="SearchFilter()" [(ngModel)]="groupName">
                                        <option value='' enabled selected>All</option>
                                        <option *ngFor="let workFlow of workFlowMaster" [value]="workFlow.workFlowName">
                                            {{workFlow.workFlowCode}} - {{workFlow.workFlowName}}
                                        </option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-md-6 text-end">

                                    <!-- <button class="btn btn-primary go-btn me-2" (click)="SearchFilter()" matTooltip="Search"
                                        matTooltipClass="danger-tooltip">
                                        <fa-icon [icon]="faSearch"></fa-icon>
                                    </button> -->
                                    <button class="btn btn-primary go-btn text-end" (click)="openWorkFlowDetail(editWorkFlowDetails)"
                                        matTooltip="Add WorkFlow Details" matTooltipClass="danger-tooltip"
                                        *ngIf="userAccessData[0]?.canCreate == true">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </div>
                            </div>

                            <div class="table-list custom-table" *ngIf="userAccessData[0]?.canRead == true">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered table-hover mx-auto">
                                        <thead class="table-head">
                                            <tr>
                                                <th class="sr-no">Sr. No.</th>
                                                <th (click)="sort('name')" class="col location">WorkFlow Group
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]='glyphicon-chevron-up'>
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')" class="col location">Milestone
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]='glyphicon-chevron-up'>
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')" class="col location">Location
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]='glyphicon-chevron-up'>
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')" class="col">Sequence
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')" class="col">Optional
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')" class="col">Status
                                                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th class="action-key text-center"
                                                    *ngIf="userAccessData[0]?.canUpdate == true">Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let workFlow of workFlowDetails | orderBy: key : reverse | filter:filter1 | paginate: { id : 'pagination2', itemsPerPage: pageSize2, currentPage: currentActivePage2}; let i = index">
                                                <td>{{ i +1 + pageSize1*(currentActivePage1-1) }}</td>
                                                <td class="left">{{workFlow.workFlowMaster?.workFlowCode}} - {{workFlow.workFlowMaster?.workFlowName}}</td>
                                                <td class="left">{{workFlow.milestoneName}}</td>
                                                <td class="left">{{workFlow.locationName}}</td>
                                                <td class="left">{{workFlow.sequence}}</td>
                                                <td class="end">
                                                    <span *ngIf="workFlow.isOptional  ==true ">True</span>
                                                    <span *ngIf="workFlow.isOptional  ==false ">False</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="workFlow.isActive  ==true ">Active</span>
                                                    <span *ngIf="workFlow.isActive  ==false ">Inactive</span>
                                                </td>
                                                <!-- <td class="center">{{workFlow.isOptional}}</td> -->
                                                <td class="text-center" *ngIf="userAccessData[0]?.canUpdate == true">
                                                    <span class="edit-btn-icon" type="button"
                                                        matTooltip="Edit Work Flow Details" matTooltipClass="danger-tooltip"
                                                        (click)="editworkFlowDetail(editWorkFlowDetails, workFlow) ">
                                                        <fa-icon [icon]="faEdit"></fa-icon>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="pagination justify-content-end">
                                <!-- <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls> -->
                                <!-- <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls> -->
                                <pagination-controls id="pagination2"
                                    (pageChange)="pagechange1($event)"></pagination-controls>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>

<ng-template #editWorkFlowMaster let-modal class="modal">
    <div class="modal-content custom-modal">
        <div class="modal-header">
            <h4 class="modal-title mb-0">{{model1Title}}</h4>
            <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span class="material-icons-sharp">close</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <form autocomplete="off" [formGroup]="workFlowMasterForm">
                        <div class="row">

                          <div class="form-group mb-2 col-md-6">
                            <label for="workFlowCode">Workflow Code <sup>*</sup></label>
                            <input type="text" class="form-control" placeholder="" value=" "
                                [ngClass]="{ 'is-invalid': submitted1 && f.workFlowCode.errors }"
                                formControlName="workFlowCode" [disabled]="!resetbuttonShow" />
                            <div *ngIf="submitted1 && f.workFlowCode.errors" class="invalid-feedback">
                                <div *ngIf="f.workFlowCode.errors.required">Workflow Code is required.
                                </div>
                                <div
                                    *ngIf="f.workFlowCode.hasError('whitespace')  && !f.workFlowCode.errors.required ">
                                    Blank space Not Allowed</div>
                                <div
                                    *ngIf="f.workFlowCode.errors.minlength && !f.workFlowCode.errors?.pattern && !f.workFlowCode.hasError('whitespace')">
                                    Workflow Code must be at least 2 characters.
                                </div>
                                <div
                                    *ngIf="f.workFlowCode.errors.maxlength && !f.workFlowCode.errors?.pattern && !f.workFlowCode.hasError('whitespace')">
                                    Workflow Code must not exceed 30 characters.
                                </div>
                                <div
                                    *ngIf="f.workFlowCode.errors?.pattern && !f.workFlowCode.hasError('whitespace')">
                                    Workflow Code should contain only a-z,A-Z.
                                </div>
                            </div>
                        </div>

                            <div class="form-group mb-2 col-md-6">
                                <label for="workFlowName">Workflow Name <sup>*</sup></label>
                                <input type="text" class="form-control" placeholder="" value=" "
                                    [ngClass]="{ 'is-invalid': submitted1 && f.workFlowName.errors }"
                                    formControlName="workFlowName" />
                                <div *ngIf="submitted1 && f.workFlowName.errors" class="invalid-feedback">
                                    <div *ngIf="f.workFlowName.errors.required">Workflow Name is required.
                                    </div>
                                    <div
                                        *ngIf="f.workFlowName.hasError('whitespace')  && !f.workFlowName.errors.required ">
                                        Blank space Not Allowed</div>
                                    <div
                                        *ngIf="f.workFlowName.errors.minlength && !f.workFlowName.errors?.pattern && !f.workFlowName.hasError('whitespace')">
                                        Workflow name must be at least 2 characters.
                                    </div>
                                    <div
                                        *ngIf="f.workFlowName.errors.maxlength && !f.workFlowName.errors?.pattern && !f.workFlowName.hasError('whitespace')">
                                        Workflow name must not exceed 30 characters.
                                    </div>
                                    <div
                                        *ngIf="f.workFlowName.errors?.pattern && !f.workFlowName.hasError('whitespace')">
                                        Workflow Name should contain only a-z,A-Z.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-2 col-md-6">
                                <label for="workFlowType">WorkFlow Type <sup>*</sup></label>
                                <select type="text" class="form-control form-select" placeholder="" value=" "
                                    [ngClass]="{ 'is-invalid': submitted1 && f.workFlowType.errors }"
                                    formControlName="workFlowType">
                                    <option value='' hidden>--Choose Option--</option>
                                    <option value="Outbound">Outbound</option>
                                    <option value="Inbound">Inbound</option>
                                    <option value="InPlant">InPlant</option>
                                </select>
                                    <div *ngIf="submitted1 && f.workFlowType.errors" class="invalid-feedback">
                                        <div *ngIf="f.workFlowType.errors.required">Workflow Type is required.
                                        </div>
                                    </div>
                            </div>

                            <div class="form-group mb-2">
                                <label for="description">Description</label>
                                <textarea type="text" class="form-control" placeholder="" value=" "
                                    formControlName="description"></textarea>
                            </div>
                            <span *ngIf="!resetbuttonShow">
                                <div class="select-radio">
                                    <input class="radio-input" type="radio" id="active" [value]="true"
                                        formControlName="isActive">
                                    <label for="active" class="radio-label">Active</label>

                                    <input class="radio-input" id="inactive" type="radio" [value]="false"
                                        formControlName="isActive">
                                    <label for="inactive" class="radio-label">Inactive</label>
                                </div>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="modal-button btn btn-default" aria-label="Close"
                (click)="modal.dismiss('Cross click')">Close</button>
            <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
                (click)="OnReset()">Reset</button>
            <button type="submit" class="modal-button btn btn-primary" (click)="registerWorkFlowMaster()">Save</button>
        </div>
    </div>
</ng-template>


<ng-template #editWorkFlowDetails let-modal class="modal">
    <div class="modal-content custom-modal">
        <div class="modal-header">
            <h4 class="modal-title mb-0">{{model2Title}}</h4>
            <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span class="material-icons-sharp">close</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <form autocomplete="off" [formGroup]="workFlowDetailForm">
                        <div class="row">


                            <div class="form-group mb-2 col-md-6">
                                <label for="workFlowId">WorkFlow Group <sup>*</sup></label>
                                <select type="text" class="form-control form-select" placeholder="" value=" "
                                    [ngClass]="{ 'is-invalid': submitted2 && f1.workFlowId.errors }"
                                    formControlName="workFlowId">
                                    <option value='' hidden>--Choose Option--</option>
                                    <option *ngFor="let work of workFlowMaster" [ngValue]="work.workFlowId">
                                      {{work.workFlowCode}} - {{work.workFlowName}}</option>
                                </select>
                                    <div *ngIf="submitted2 && f1.workFlowId.errors" class="invalid-feedback">
                                        <div *ngIf="f1.workFlowId.errors.required">Workflow Group is required.
                                        </div>
                                    </div>
                            </div>

                            <div class="form-group mb-2 col-md-6">
                                <label for="milestoneCode">Milestone <sup>*</sup></label>
                                <select type="text" class="form-control form-select" placeholder="" value=" "
                                    [ngClass]="{ 'is-invalid': submitted2 && f1.milestoneCode.errors }"
                                    formControlName="milestoneCode" (change)="milestoneSelection()">
                                    <option value='' hidden>--Choose Option--</option>
                                    <option *ngFor="let milestone of milestoneMasterData" [ngValue]="milestone.milestoneCode">
                                        {{milestone.milestoneName}}-{{milestone.milestoneEvent}}</option>
                                </select>
                                    <div *ngIf="submitted2 && f1.milestoneCode.errors" class="invalid-feedback">
                                        <div *ngIf="f1.milestoneCode.errors.required">Milestone is required.
                                        </div>
                                    </div>
                            </div>

                            <div class="form-group mb-2 col-md-6">
                                <label for="locationCode">Location</label>
                                <select type="text" class="form-control form-select" placeholder="" value=" "
                                    [ngClass]="{ 'is-invalid': submitted2 && f1.locationCode.errors }"
                                    formControlName="locationCode" (change)="locationSelection()">
                                    <option value=''>No Location Selected</option>
                                    <option *ngFor="let workFlow of locationMasterData" [ngValue]="workFlow.locationCode">
                                        {{workFlow.locationName}}</option>
                                </select>
                                    <div *ngIf="submitted2 && f1.locationCode.errors" class="invalid-feedback">
                                        <div *ngIf="f1.locationCode.errors.required">Location is required.
                                        </div>
                                    </div>
                            </div>

                            <div class="form-group mb-2 col-md-6">
                                <label for="sequence">Sequence <sup>*</sup></label>
                                <input type="number" class="form-control" placeholder="" value=" "
                                    [ngClass]="{ 'is-invalid': submitted2 && f1.sequence.errors }"
                                    formControlName="sequence" minlength="1"/>
                                <div *ngIf="submitted2 && f1.sequence.errors" class="invalid-feedback">
                                    <div *ngIf="f1.sequence.errors.required">Sequence is required.
                                    </div>
                                    <div *ngIf="f1.sequence.errors.pattern">Negative values not allowed.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-2 col-md-6">
                                <input type="checkbox" id="IsOptional" class="" placeholder=""
                                    formControlName="isOptional" /> &nbsp;
                                <label for="IsOptional">Is Optional</label>
                            </div>

                            <span *ngIf="!resetbuttonShow">
                                <div class="select-radio">
                                    <input class="radio-input" type="radio" id="active" [value]="true"
                                        formControlName="isActive">
                                    <label for="active" class="radio-label">Active</label>

                                    <input class="radio-input" id="inactive" type="radio" [value]="false"
                                        formControlName="isActive">
                                    <label for="inactive" class="radio-label">Inactive</label>
                                </div>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="modal-button btn btn-default" aria-label="Close"
                (click)="modal.dismiss('Cross click')">Close</button>
            <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
                (click)="OnReset()">Reset</button>
            <button type="submit" class="modal-button btn btn-primary" (click)="registerWorkFlowDetail()">Save</button>
        </div>
    </div>
</ng-template>

