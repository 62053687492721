import { Component, ElementRef, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {faAnchor,faBan,faPlus,faSort,faEdit,faSearch} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-work-flow-master',
  templateUrl: './work-flow-master.component.html',
  styleUrls: ['./work-flow-master.component.scss']
})
export class WorkFlowMasterComponent implements OnInit {

  faBan = faBan;
  faAnchor = faAnchor;
  faPlus = faPlus;
  faSort = faSort;
  faEdit = faEdit;
  faSearch = faSearch;
  title = 'Workflow Masters';
  public userAccessData: UserScreenAccesData;
  workFlowMaster: any;
  workFlowDetails: any;
  model1Title = 'Add WorkFlok';
  model2Title = 'Add WorkFlok Detail';
  resetbuttonShow: boolean = true;
  submitted1 = false;
  submitted2 = false;
  milestoneMasterData: any;
  locationMasterData: any;
  activeWorkFlowMasterData: any;

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage1: number = 1;
  totalRecords1 = 0;
  tempcurrentActivePage: number = 1;

  currentActivePage2: number = 1;
  totalRecords2 = 0;
  tempcurrentActivePage2: number = 1;

  //sorting
  filter = '';
  pageSize1 = 10;
  filter1 = '';
  pageSize2 = 10;
  key: string = 'name'; //set default

  workFlowMasterForm: UntypedFormGroup;
  workFlowDetailForm: UntypedFormGroup;
  workFlowName: any;
  groupName: string = '';

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private element: ElementRef,
    private dataService: DataService
  ) {
    this.userAccessData =
      this.dataService.getUserScreenAccessMenu('WORKFLOWMASTER');
  }

  ngOnInit(): void {

    this.getAllWorkFlowMaster();
    this.getAllWorkFlowDetailsByGroup();
    this.ResetWorkFlowMaster();
    this.ResetWorkFlowDetail();
    this.getAllLocation();
    this.getAllMilestoneMaster();
    this.getAllActiveWorkFlowMaster();
  }

  getAllMilestoneMaster() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllMilestone)
      .subscribe(
        (response) => {
          this.milestoneMasterData = response;
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
  }

  getAllLocation() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        (response) => {
          this.locationMasterData = response;
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
  }

  getAllActiveWorkFlowMaster() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllActiveWorkFlowMaster)
      .subscribe(
        (response) => {
          this.activeWorkFlowMasterData = response;
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
  }

  loadTabComponent(tab: number) {

    if (tab == 0) this.title = 'Work Flow Masters';
    else if (tab == 1) this.title = 'Work Flow Detail';
  }

  ResetWorkFlowMaster() {
    this.workFlowMasterForm = this.formBuilder.group({
      workFlowId: [0],
      workFlowName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          this.noWhitespaceValidator,
        ],
      ],
      workFlowType: ['', [Validators.required]],
      isActive: [1],
      description: [''],
      workFlowCode: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          this.noWhitespaceValidator,
        ],
      ],
    });
  }

  ResetWorkFlowDetail() {
    this.workFlowDetailForm = this.formBuilder.group({
      workFlowDetailId: [0],
      workFlowId: ['', [Validators.required]],
      milestoneCode: ['', Validators.required],
      milestoneName: ['', Validators.required],
      locationCode: [''],
      locationName: [''],
      sequence: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      isOptional: [false],
      isActive: [1],
      workFlowName: [],
      groupName: [''],
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.workFlowMasterForm.controls;
  }

  get f1(): { [key: string]: AbstractControl } {
    return this.workFlowDetailForm.controls;
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  getAllWorkFlowMaster() {
    this.spinner.show();

    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllWorkFlowmaster)
      .subscribe(
        (response) => {

          this.spinner.hide();
          this.workFlowMaster = response;
          this.totalRecords1 = this.workFlowMaster.length;
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  getAllWorkFlowDetails() {
    this.spinner.show();
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllWorkFlowDetail)
      .subscribe(
        (response) => {

          this.spinner.hide();
          this.workFlowDetails = response;
          this.totalRecords2 = this.workFlowDetails.length;
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }
  getAllWorkFlowDetailsByGroup() {
    this.spinner.show();

    var query = {
      groupName: this.groupName,
    };

    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllWorkFlowDetailByGroup, query)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.workFlowDetails = response;
          this.totalRecords2 = this.workFlowDetails.length;
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  cng(val) {

    this.groupName = val;
  }
  pagechange = (event) => {

    this.tempcurrentActivePage = this.currentActivePage1 = event;
  };
  filterchange = (val: any) =>
    !this.kemarClientService.commonValidation(val)
      ? (this.currentActivePage1 = 1)
      : (this.currentActivePage1 = this.tempcurrentActivePage);

  pagechange1 = (event) =>
    (this.tempcurrentActivePage2 = this.currentActivePage2 = event);
  filterchange1 = (val: any) =>
    !this.kemarClientService.commonValidation(val)
      ? (this.currentActivePage2 = 1)
      : (this.currentActivePage2 = this.tempcurrentActivePage2);

  openWorkFlowMaster(content: any) {
    this.OnReset();
    this.submitted1 = false;
    this.ResetWorkFlowMaster();
    this.model1Title = 'Add Workflow';
    this.workFlowMasterForm.controls['workFlowId'].setValue(0);
    this.resetbuttonShow = true;
    this.modalService.open(content, this.ngbModalOptions);
    this.workFlowMasterForm.controls['isActive']?.setValue(true);
  }

  openWorkFlowDetail(content: any) {

    this.OnReset();
    this.submitted2 = false;
    this.ResetWorkFlowDetail();
    this.model2Title = 'Add Workflow Details';
    this.workFlowDetailForm.controls['workFlowDetailId'].setValue(0);
    this.resetbuttonShow = true;
    this.modalService.open(content, this.ngbModalOptions);
  }

  editworkFlow(content: any, workFlowMaster: any) {
    this.submitted1 = false;
    this.ResetWorkFlowDetail();
    this.model1Title = 'Update Workflow Master';
    this.workFlowMasterForm.patchValue(workFlowMaster);
    this.resetbuttonShow = false;
    this.modalService.open(content, this.ngbModalOptions);
  }

  editworkFlowDetail(content: any, worlFlowDetail: any) {
    this.submitted2 = false;
    this.ResetWorkFlowDetail();
    this.model2Title = 'Update Workflow Details';
    this.workFlowDetailForm.patchValue(worlFlowDetail);
    this.resetbuttonShow = false;
    this.modalService.open(content, this.ngbModalOptions);
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  registerWorkFlowMaster() {
    this.submitted1 = true;
    if (this.workFlowMasterForm.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService
      .postPatch<any>(
        ServiceUrl.updateWorkFlowMaster,
        this.workFlowMasterForm.value
      )
      .subscribe(
        (res) => {

          Swal.fire('', res.responseMessage, 'success');
          this.getAllWorkFlowMaster();
          this.getAllActiveWorkFlowMaster();
          this.closeModalPopup();
          this.spinner.hide();
        },
        (r) => {

          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          });
          this.spinner.hide();
        }
      );
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }
  SearchFilter() {

    this.currentActivePage2 = 1;
    this.getAllWorkFlowDetailsByGroup();
  }
  OnReset() {
    this.submitted1 = false;
    this.ResetWorkFlowMaster();

    this.submitted2 = false;
    this.ResetWorkFlowDetail();
  }

  milestoneSelection() {
    var MilestoneCode = this.workFlowDetailForm.controls['milestoneCode'].value;
    var milestoneDetail = this.milestoneMasterData.filter(
      (x) => x.milestoneCode == MilestoneCode
    );
    this.workFlowDetailForm.controls['milestoneName'].setValue(
      milestoneDetail[0].milestoneName +
        ' - ' +
        milestoneDetail[0].milestoneEvent
    );
  }

  locationSelection() {

    var LocationCode = this.workFlowDetailForm.controls['locationCode'].value;
    if(LocationCode == ''){
      this.workFlowDetailForm.controls['locationName'].setValue('');
    }
    else{
      var LocationDetail = this.locationMasterData.filter(
        (x) => x.locationCode == LocationCode
      );
      this.workFlowDetailForm.controls['locationName'].setValue(
        LocationDetail[0].locationName
      );
    }
  }

  registerWorkFlowDetail() {
    this.submitted2 = true;

    if (this.workFlowDetailForm.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService
      .postPatch<any>(
        ServiceUrl.updateWorkFlowDetail,
        this.workFlowDetailForm.value
      )
      .subscribe(
        (res) => {

          Swal.fire('', res.responseMessage, 'success');
          this.getAllWorkFlowDetailsByGroup();
          this.closeModalPopup();
          this.spinner.hide();
        },
        (r) => {

          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          });
          this.spinner.hide();
        }
      );
  }
}
