import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const USER_KEY = 'auth-user';
const Refresh_Token = 'refresh-token';
const USER_NAME = 'user-name';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor(private router: Router) { }

  signOut() {
    window.sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

  public saveToken(token: string, userName: string) {
    window.sessionStorage.removeItem(userName);
    window.sessionStorage.setItem(userName, token);
  }

  public getToken(userName: string): string {
    return sessionStorage.getItem(userName);
  }

  public setUserName(userName: string) {
    window.sessionStorage.removeItem(USER_NAME);
    window.sessionStorage.setItem(USER_NAME, userName);
  }

  public getUserName(): string {
    return sessionStorage.getItem(USER_NAME);
  }

  public setRefreshToken(refreshToken: string) {
    window.sessionStorage.removeItem(Refresh_Token);
    window.sessionStorage.setItem(Refresh_Token, refreshToken);
  }

  public getRefreshToken() {
    return sessionStorage.getItem(Refresh_Token);
  }

  public saveUser(user) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser() {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
}
