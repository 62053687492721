import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-app-config',
  templateUrl: './app-config.component.html',
  styleUrls: ['./app-config.component.scss']
})
export class AppConfigComponent implements OnInit {
appConfigData : any[];
public userAccessData: UserScreenAccesData;
faSort = faSort;


 //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################

  constructor(private kemarClientService: KemarClientService,
    private spinner: NgxSpinnerService,
    private api:DataService) { }

  ngOnInit(): void {
    this.getAllApplicationConfig();
    this.userAccessData =this.api.getUserScreenAccessMenu('AppConfigMaster');
  }

  getAllApplicationConfig() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAllApplicationConfiguration)
       .subscribe(
         response => {
           this.appConfigData = response;
         },
         r => {
           alert(r.error.error);
         });
   }


}
