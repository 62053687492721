import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { CommonMasterDataResponse } from 'src/app/models/commonmasterdatamodel';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { faAnchor, faEdit, faMapPin, faObjectGroup, faPlus, faSort, faTimes, faHistory } from '@fortawesome/free-solid-svg-icons';
import { RouterModule } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Key } from 'protractor';
import Swal from 'sweetalert2';
import { max } from 'rxjs/operators';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
//import { CommonMasterDataRequest } from 'src/app/models/commonmasterdatarequestmodel';

@Component({
  selector: 'app-common-master-data',
  templateUrl: './common-master-data.component.html',
  styleUrls: ['./common-master-data.component.scss']
})
export class CommonMasterDataComponent implements OnInit {
  faEdit = faEdit;
  faTimes = faTimes;
  faAnchor = faAnchor;
  faPlus = faPlus;
  faSort = faSort;
  faHistory = faHistory;
  form: FormGroup;
  resetbuttonShow:  boolean = true;
  activebuttonShow:  boolean = false;
  currentActivePage: number = 1;
  commonmasterdata: any;
  public CommonMaster: CommonMasterDataResponse = new CommonMasterDataResponse();
  //public commonMasterRequest: CommonMasterDataRequest = new CommonMasterDataRequest();
  public userAccessData: UserScreenAccesData;

  title: string;
  submitted: boolean;
  type: string;
  parameter: string;
  value: string;
  dataItemId: number;
  typeListMapping: any;
  typeList: any;
  parameterList: CommonMasterDataResponse;
  filter = '';
  pageSize = 10;
  key: string = 'name';
  reverse: boolean = false;
  tempcurrentActivePage: number = 1;
  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) =>  (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 :  this.currentActivePage = this.tempcurrentActivePage
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }


  constructor(private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,


    private formBuilder: FormBuilder,
    private api:DataService

  ) {
    this.form = this.formBuilder.group({
      isActive: [''],
      id:[0, [Validators.required]],
      type: ['',[Validators.required]],
      parameter: ['',[Validators.required]],
      value: ['',[Validators.required]]
    });
    this.userAccessData =this.api.getUserScreenAccessMenu('Common Master Data');
   }

  ngOnInit(): void {
  this.getAllCommonMasterData();
  this.getTypeList();
  this.getParameterList();
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
    this.form.get('parameter').setValue('');
    this.form.get('type').setValue('');
    this.form.get('value').setValue('');
    this.form.get('id').setValue(0);
    this.form.get('isActive').setValue(true);
  }


  getAllCommonMasterData() {

    this.kemarClientService.get<CommonMasterDataResponse>(null, ServiceUrl.getAllCommonMasterData)
      .subscribe(
        response => {
          this.spinner.hide();
          this.commonmasterdata = response;
          // console.log("CommonMasterGET")
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  getTypeList() {

    this.kemarClientService.get<CommonMasterDataResponse>(null, ServiceUrl.getTypeList)
       .subscribe(
         response => {

           this.typeList = response;
           //this.typeList = this.commonmasterdata.filter(x => x.type == 'controller')
          //  console.log("TypeListGet")
         },
         r => {
           alert(r.error.error);
         });
   }

   getParameterList(){

    this.kemarClientService.get<CommonMasterDataResponse>(null, ServiceUrl.getParameterList)
       .subscribe(
         response => {

           this.parameterList = response;
           //this.typeList = this.commonmasterdata.filter(x => x.type == 'controller')
          //  console.log("TypeListGet")
         },
         r => {
           alert(r.error.error);
         });
   }



  addCommonMasterData(){

    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.spinner.show();
    var body = this.CommonMaster;
    this.kemarClientService.postPatch<any>(ServiceUrl.addCommonMasterData,body)
    .subscribe(
      r=>{
        // console.log(r);
        this.form.reset();
        this.submitted = false;
        Swal.fire('',r.responseMessage,'success')
        this.getAllCommonMasterData();
        this.closeModalPopup();
        this.spinner.hide();
      },
      r=>{
        Swal.fire({
          icon:"error",
          title:'',
          text:r.error.errorMessage
        })
        this.spinner.hide();
        // console.log(r.error.error);
      });
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
    this.form.reset();
  }

  get f():{[Key:string]:AbstractControl}{
    return this.form.controls;
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  open(content: any) {

    this.onReset();
    this.CommonMaster.id = 0;
    this.CommonMaster.type='';
    this.CommonMaster.parameter='';
    this.CommonMaster.value='';
    this.CommonMaster.dataItemId= 0;
    this.CommonMaster.isActive= true;
    this.activebuttonShow = true;
    this.resetbuttonShow = true;
    this.title = "Add Common Master Data";
    this.ngbModalOptions;
    this.modalService.open(content, { size: 'sm' });
    this.modalService.open(this.ngbModalOptions);

  }

  editCommonMaster(content: any, commonmasterdata) {

    this.onReset();
    this.title = 'Update Common Master Data';
    this.CommonMaster.id = commonmasterdata.id;
    this.CommonMaster.dataItemId = commonmasterdata.dataItemId;
    this.CommonMaster.type = commonmasterdata.type;
    this.CommonMaster.parameter = commonmasterdata.parameter;
    this.CommonMaster.value = commonmasterdata.value;
    this.CommonMaster.isActive = commonmasterdata.isActive;
    this.resetbuttonShow = false,
    this.activebuttonShow = false;
    this.modalService.open(content, { size: 'sm' });
    this.modalService.open(this.ngbModalOptions);

  }

}
