<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter my-2 align-items-center">
          <div class="col-sm-3">
            <h4 class="title-one mb-0">Daily TAT Report</h4>
          </div>
          <div class="col-sm-9 text-end" id="input-filter">
            <div class="input-append">
              <!-- <span class="material-icons-sharp icon-in">
                search
                </span> -->
            </div>
            <label for="start">From:</label>
            <input
              class="form-control date"
              name="fromdate"
              (keypress)="KeyPressDate($event)"
              [(ngModel)]="tatReportModel.fromDate"
              type="date"
              id="start"
              value="Date"
              [max]="currentdate"
            />
            <label for="start" class="to-label">To:</label>
            <input
              class="form-control date"
              name="todate"
              (keypress)="KeyPressDate($event)"
              [(ngModel)]="tatReportModel.toDate"
              type="date"
              id="start"
              value="Date"
              [max]="currentdate"
            />
            <button
              class="btn btn-primary go-btn"
              (click)="getTatReport()"
              matTooltip="Search"
              matTooltipClass="primary-tooltip"
            >
              <span class="material-icons-round">search</span>
            </button>
            <button
              class="btn btn-success go-btn ms-2"
              matTooltip="Download Report"
              matTooltipClass="primary-tooltip"
              (click)="exportToExcel()"
            >
              <span class="material-icons-round">file_download</span>
            </button>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover mx-auto"
            >
              <thead class="table-head">
                <tr>
                  <th class="sr-no text-center" style="width: 15px;">Sr No.</th>
                  <th (click)="sort('date')">
                    Date
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    OB Max TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    OB Min TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    OB Average TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    No. of OB Tran
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    IB Max TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    IB Min TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    IB Average TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    No. of IB Tran
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    Max TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    Min TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    Average TAT
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">
                    No. of Tran
                    <span
                      class="glyphicon sort-icon"
                      [ngClass]="{
                        'glyphicon-chevron-up': reverse,
                        'glyphicon-chevron-down': !reverse
                      }"
                    >
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let gate of tatReport
                      | orderBy : key : reverse
                      | filter : filter
                      | paginate
                        : {
                            itemsPerPage: pageSize,
                            currentPage: currentActivePage
                          };
                    let i = index
                  "
                >
                  <td class="text-center">
                    {{ i + 1 + pageSize * (currentActivePage - 1) }}
                  </td>
                  <td>{{ gate.date }}</td>
                  <td class="text-end">{{ gate.obMaxTAT }}</td>
                  <td class="text-end">{{ gate.obMinTAT }}</td>
                  <td class="text-end">{{ gate.obAverageTAT }}</td>
                  <td class="text-end">{{ gate.obTotalTranCount }}</td>
                  <td class="text-end">{{ gate.ibMaxTAT }}</td>
                  <td class="text-end">{{ gate.ibMinTAT }}</td>
                  <td class="text-end">{{ gate.ibAverageTAT }}</td>
                  <td class="text-end">{{ gate.ibTotalTranCount }}</td>
                  <td class="text-end">{{ gate.maxTAT }}</td>
                  <td class="text-end">{{ gate.minTAT }}</td>
                  <td class="text-end">{{ gate.averageTAT }}</td>
                  <td class="text-end">{{ gate.totalTranCount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <span> Total Records : {{ totalRecords }} </span>
          </div>
          <div class="col-md-4 text-center"></div>
          <div class="col-md-4 text-end">
            <div class="pagination justify-content-end">
              <pagination-controls
                (pageChange)="pagechange($event)"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<footer class="sticky-footer bg-black text-light">
  <app-footer></app-footer>
</footer>
