import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faAnchor, faEdit, faMapPin, faObjectGroup, faPlus, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MilestoneMasterResponse } from 'src/app/models/milestonemastermodel';
import { MilestoneActionMappingResponse } from 'src/app/models/milestoneActionMappingModel';
import { MilestoneActionResponse } from 'src/app/models/milestoneActionModel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-milestone-action-mapping',
  templateUrl: './milestone-action-mapping.component.html',
  styleUrls: ['./milestone-action-mapping.component.scss']
})
export class MilestoneActionMappingComponent implements OnInit {


  milestoneMaster: any;
  milestoneaction: any;
  milestoneMappingMaster:any[];
  title: any;
  faEdit = faEdit;
  faTimes = faTimes
  faAnchor = faAnchor
  faPlus = faPlus
  faSort = faSort
  dependentTypeDll: any = dependentTypeDll;
  submitted = false;
  form: UntypedFormGroup;
  public milestone: MilestoneMasterResponse = new MilestoneMasterResponse();
  public milestoneAction: MilestoneActionResponse = new MilestoneActionResponse();
  public milestoneMapping: MilestoneActionMappingResponse = new MilestoneActionMappingResponse();
  public userAccessData: UserScreenAccesData;

  FilterMilestone:any = 0;
  FilterTranType: any = 1;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize2 = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  ReasetShow:boolean=false;
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  activebuttonHide:  boolean;

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage2: number = 1;
  totalRecords2 = 0;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private api :DataService) { }

  // ngOnInit(): void {
  //   this.getAllMilestoneMapping();
  // }
  ngOnInit(): void {
    this.userAccessData =this.api.getUserScreenAccessMenu('MILESTONE');
    this.FilterMilestone = 0;
    this.FilterTranType = 0;
    this.Reset();
    this.getAllMilestoneMapping();
    this.getAllMilestone();
    this.getAllMilestoneAction();
  }

  Reset(){
    this.form = this.formBuilder.group(
      {
        milestoneId: [ 0, [ Validators.required , Validators.pattern(/^[1-9]\d*$/)]],
        // milestone: ['',  Validators.required ],
        // milestoneAction: ['',  Validators.required ],
        isActive: ['',  Validators.required ],
        milestoneActionId: [ 0, [ Validators.required, Validators.pattern(/^[1-9]\d*$/)]],
        sequenceNumber: [0,[Validators.required,Validators.minLength(1),Validators.required,Validators.pattern(/^[1-9][0-9]*$/)]],
        isDependentOnPreviuos: [''],
        tranType: [0,  Validators.required ]
      },
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  registerMilestoneActionMapping() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerMilestoneActionMapping, this.milestoneMapping)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success')
          this.getAllMilestoneMapping();
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
        });
  }
  getAllMilestoneMapping() {
    this.spinner.show();
    var query = {
      MilestoneMaster: this.FilterMilestone,
      TranType: this.FilterTranType
    }
    this.kemarClientService.get<any>(null, ServiceUrl.getAllMilestoneActionMapping, query)
      .subscribe(
        response => {
          this.spinner.hide();
          this.milestoneMappingMaster = response;
          this.totalRecords2 = this.milestoneMappingMaster.length;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }
  getAllMilestoneAction() {
    this.spinner.show();
    this.kemarClientService.get<MilestoneActionResponse>(null, ServiceUrl.getAllMilestoneAction)
      .subscribe(
        response => {

          this.spinner.hide();
          this.milestoneAction = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }
  getAllMilestone() {
    this.spinner.show();
    this.kemarClientService.get<MilestoneMasterResponse>(null, ServiceUrl.getAllMilestone)
      .subscribe(
        response => {

          this.spinner.hide();
          this.milestoneMaster = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
    this.form.controls['milestoneId'].setValue(0);
    this.form.controls['milestoneActionId'].setValue(0);
    this.form.controls['isDependentOnPreviuos'].setValue(true);
  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage2 = event;
  filterchange = (val: any) =>  (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage2 = 1 :  this.currentActivePage2 = this.tempcurrentActivePage
  open(content: any) {
    this.onReset();
    this.title = "Add Milestone Action Mapping";
    this.milestoneMapping.milestoneName = '';
    this.milestoneMapping.milestoneId = 0;
    this.milestoneMapping.milestoneActionName = '';
    this.milestoneMapping.milestoneActionId = 0;
   this.milestoneMapping. milestoneActionMappingId = 0;
   this.milestoneMapping.sequenceNumber= null;
   this.milestoneMapping.tranType = 0;
   this.activebuttonHide = true;
   this.ReasetShow=true;
   this.milestoneMapping.isDependentOnPreviuos= true;
    this.milestoneMapping.isActive = true;

    this.modalService.open(content, this.ngbModalOptions);
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  editMileStoneActionMapping(content: any, milestoneMapping) {

    // console.log(milestoneMapping);
    this.onReset();
    this.title = "Update Milestone Action Mapping"
    this.milestoneMapping.milestoneActionMappingId= milestoneMapping.milestoneActionMappingId;
    this.milestoneMapping.milestoneName = milestoneMapping.milestoneName;
    this.milestoneMapping.milestoneActionName = milestoneMapping.milestoneActionName;
    this.milestoneMapping.milestoneId = milestoneMapping.milestoneId;
    this.milestoneMapping.milestoneActionId = milestoneMapping.milestoneActionId;
    this.milestoneMapping.sequenceNumber = milestoneMapping.sequenceNumber;
    this.milestoneMapping.isDependentOnPreviuos = milestoneMapping.isDependentOnPreviuos;
    this.milestoneMapping.isActive = milestoneMapping.isActive;
    this.milestoneMapping.tranType = milestoneMapping.tranType;
    this.activebuttonHide = false;
    this.ReasetShow=false;
    this.modalService.open(content, this.ngbModalOptions);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

}
const dependentTypeDll: { key: string, value: string }[] = [
  { key: 'false', value: 'No' },
  { key: 'true', value: 'yes' }
];
