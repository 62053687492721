<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<div>
    <section class="my-2">
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex data-filter align-items-center my-2">
                        <div class="col-sm-4">
                            <h4 class="title-one mb-0"> Non Logistic Movement </h4>
                        </div>
                        <div class="col-sm-8 text-end mx-100">
                            <div class="input-append me-0">
                                <span class="material-icons-sharp icon-in">search</span>
                                <input class="form-control" type="search" name="searchtext" placeholder="search"
                                    [(ngModel)]="pagination.searchtext" (keyup)="GetTransactionByVrn($event.target.value)"
                                    (ngModelChange)=" pagination.page = 1">
                            </div>
                        </div>
                    </div>
                    <div class="table-list custom-table">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover mx-auto">
                                <thead class="table-head">
                                    <tr>
                                        <th (click)="sort('vrn')" class="col-1">VRN
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('vrn')" class="col-1">Transaction Time
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('vehicleTransactionCode')" class="col-2">Location Name
                                            <span class="glyphicon glyphicon-chevron-up"
                                                *ngIf="key =='vehicleTransactionCode'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th (click)="sort('vrn')" class="col-1">Direction
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('vehicleTransactionCode')" class="col-2">Lane
                                            <span class="glyphicon glyphicon-chevron-up"
                                                *ngIf="key =='vehicleTransactionCode'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <!-- <th (click)="sort('exitDateTime')" class="col-4">IsActive
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='exitDateTime'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th> -->

                                        <!-- <th (click)="sort('entryDateTime')" class="col-4">Trip end time
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='entryDateTime'"
                                                [ngClass]='glyphicon-chevron-up'>
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>

                                        <th (click)="sort('tranType')" class="col-4"> Job Completed
                                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='tranType'"
                                                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                            </span>
                                        </th>
                                        <th class="col-1 text-center"> History</th> -->
                                        <!-- <th class="col action-key">History
                                    </th>  -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let nonlogistic of transactionResponse   | paginate: { itemsPerPage: pagination.pageSize, currentPage: pagination.page,totalItems:pagination.count }; let i = index">

                                        <td class="col-1">
                                            <!-- <a
                                                [routerLink]="['/current-transaction', inplant.vehicleTrancode]">{{inplant.vrn}}</a> -->
                                             {{nonlogistic.vrn}}
                                        </td>
                                        <td class="col-2">{{nonlogistic.tranTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                                        <td class="col-2">{{nonlogistic.locationName}}</td>
                                        <td class="col-2">{{nonlogistic.direction}}</td>
                                        <td class="col-2">{{nonlogistic.lane}}</td>
                                        <!-- <td class="col-2">
                                            <span *ngIf="nonlogistic.isActive==1">Yes</span>
                                            <span *ngIf="nonlogistic.isActive==0">No</span>
                                        </td> -->
                                        <!-- <td class="col-2">{{inplant.transactionCompleted |date: 'dd-MM-yyyy hh:mm'}}
                                        </td>
                                        <td class="col-1">
                                            <span *ngIf="inplant.isJobCompleted  ==true ">Yes</span>
                                            <span *ngIf="inplant.isJobCompleted  ==false ">No</span>
                                        </td>
                                        <td class="col-1 text-center" style="cursor: pointer;">
                                            <fa-icon [icon]="faHistory" class="faicon"
                                                (click)="GetInPlantTransactionHistory(history, inplant.inPlantTransactionId)">
                                            </fa-icon>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-4">
                            Item per page
                            <select (change)="OnPageSizeChange($event)" class="paginationcount">
                                <option *ngFor="let size of pagination.pageSizes">
                                    {{size}}
                                </option>
                            </select>
                        </div> -->
                        <!-- <div class="col-md-4 text-end" *ngIf="hidefoundrecord==true">
                            <span> Found {{pagination.count}} </span>
                        </div>
                        <div class="col-md-4 text-end">
                            <span> Total Records:{{pagination.totalTransactionCount}} </span>
                        </div> -->
                        <div class="col-md-12 text-end">
                            <div class="pagination justify-content-end">
                                <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next" maxSize="9"
                                    screenReaderCurrentLabel="You're on page" (pageChange)="OnPageChange($event)">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
<app-footer></app-footer>
