import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parking-out',
  templateUrl: './parking-out.component.html',
  styleUrls: ['./parking-out.component.scss']
})
export class ParkingOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
