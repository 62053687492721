<ngx-spinner> </ngx-spinner>
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-md-6">
    <div class="input-append">
      <span class="material-icons-sharp icon-in">
        search
      </span>
      <input class="form-control" type="search" name="search" placeholder="Search"
        [(ngModel)]="filter" (ngModelChange)="currentActivePage2 = 1">
    </div>
    <div class="input-append">
    <select type="text" class="form-control form-select" placeholder="" value="All" [(ngModel)]="FilterMilestone" (change)="getAllMilestoneMapping()">
              <option Value=0>All</option>
              <option *ngFor="let milestone of milestoneMaster" [ngValue]="milestone.milestoneId">
                {{milestone.milestoneName}}-{{milestone.milestoneEvent}}</option>
              </select>
    </div>
    <div class="input-append">
      <select type="text" class="form-control form-select" placeholder="" value="All" [(ngModel)]="FilterTranType" (change)="getAllMilestoneMapping()">
                <option Value=0>All</option>
                <option value =1 >Outbound</option>
                <option value =2 >Inbound</option>
                <option value =4 >InPlant</option>
                </select>
      </div>
  </div>
  <div class="col-md-6 text-end">

    <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Milestone Action Mapping"
    matTooltipClass="danger-tooltip"  *ngIf="userAccessData[0]?.canCreate == true">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
  </div>
</div>
<div class="table-list custom-table">
  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover mx-auto">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>
          <th (click)="sort('name')">Milestone Name
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')">Milestone Action Name
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')">isDependent
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')">Sequence No.
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')">Tran. Type
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')">Status
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th class="action-key text-center"*ngIf="userAccessData[0].canUpdate ==true">Action </th>
        </tr>
      </thead>
      <tbody>
        <input type="hidden" />
        <tr
          *ngFor="let milestoneMapping of milestoneMappingMaster | orderBy: key : reverse | filter:filter | paginate: { id: 'pagination3', itemsPerPage: pageSize2, currentPage: currentActivePage2}; let i = index">
          <td>{{ i +1 + pageSize2*(currentActivePage2-1) }}</td>
          <td class="left">{{milestoneMapping.milestoneName}}-{{milestoneMapping.milestoneEvent}}</td>
          <td class="left">{{milestoneMapping.milestoneAction}}</td>
          <td><span *ngIf="milestoneMapping.isDependentOnPreviuos  ==true ">Yes</span>
            <span *ngIf="milestoneMapping.isDependentOnPreviuos  ==false ">No</span>
           </td>
           <!-- {{milestone.isDependentOnPreviuos}} -->
          <td>{{milestoneMapping.sequenceNumber}}</td>
          <td>
            <span *ngIf="milestoneMapping.tranType  ==1 ">Outbound</span>
            <span *ngIf="milestoneMapping.tranType  ==2 ">Inbound</span>
            <span *ngIf="milestoneMapping.tranType  ==4 ">InPlant</span>
          </td>
          <td>
            <span *ngIf="milestoneMapping.isActive  ==true ">Active</span>
            <span *ngIf="milestoneMapping.isActive  ==false ">Inactive</span>
          </td>
          <td class="text-center"*ngIf="userAccessData[0].canUpdate ==true">
            <span class="edit-btn-icon" type="button" matTooltip="Edit Milestone Action Mapping" matTooltipClass="danger-tooltip" (click)="editMileStoneActionMapping(edit, milestoneMapping) ">
              <fa-icon [icon]="faEdit"></fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination justify-content-end">
  <pagination-controls id="pagination3" (pageChange)="pagechange($event)"></pagination-controls>
</div>

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="row">


          <div class="form-group ml-3">
            <input type="hidden" minlength="3" maxlength="30" class="form-control" name="milestoneActionMappingId"
              value="{{milestoneMapping.milestoneActionMappingId}}" />
          </div>
          <div class="form-group mb-2 col-md-6">
            <label for="milestoneId">Milestone Name <sup>*</sup></label>
            <select type="text" class="form-control form-select" placeholder="" value=" "
              [ngClass]="{ 'is-invalid': submitted && f.milestoneId.errors }" formControlName="milestoneId"
              [(ngModel)]="milestoneMapping.milestoneId">
              <option [ngValue]=0 hidden>--Choose Option--</option>
              <option *ngFor="let milestone of milestoneMaster" [ngValue]="milestone.milestoneId">
                {{milestone.milestoneName}}-{{milestone.milestoneEvent}}</option>
              </select>
            <div *ngIf="submitted && f.milestoneId.errors"  (click) ="toggleDisplay()"  class="invalid-feedback">
              <div *ngIf="f.milestoneId.errors.required">Milestone Name is required.
              </div>
              <div *ngIf="f.milestoneId.errors?.pattern">
                Milestone Name is required.
              </div>
            </div>
          </div>
          <div class="form-group mb-2 col-md-6">
            <label for="milestoneActionId">Milestone Action Name <sup>*</sup></label>
            <select type="text" class="form-control form-select" placeholder="" value=" "
            [ngClass]="{ 'is-invalid': submitted && f.milestoneActionId.errors }" formControlName="milestoneActionId" [(ngModel)]="milestoneMapping.milestoneActionId">
            <option [ngValue]=0 >--Choose Option--</option>
          <option *ngFor="let milestoneAction of milestoneAction" [ngValue]="milestoneAction.milestoneActionId">
            {{milestoneAction.milestoneAction}}</option>
          </select>
          <div *ngIf="submitted && f.milestoneActionId.errors"  (click) ="toggleDisplay()"  class="invalid-feedback">
            <div *ngIf="f.milestoneActionId.errors.required">Milestone Action is required.
            </div>
            <div *ngIf="f.milestoneActionId.errors?.pattern">
             Milestone Action is required.
            </div>

          </div>
          </div>

           <div class="form-group mb-2 col-md-6">
            <label for="sequenceNumber">Sequence No <sup>*</sup></label>
            <input type="number" min="1" max ="99"
            onkeydown="if(this.value.length==2 && event.keyCode!=8) return false;"
            class="form-control" placeholder=""
            [ngClass]="{ 'is-invalid': submitted && f.sequenceNumber.errors }" formControlName="sequenceNumber"
            [(ngModel)]="milestoneMapping.sequenceNumber" />
            <div *ngIf="submitted && f.sequenceNumber.errors" class="invalid-feedback">
              <div *ngIf="f.sequenceNumber.errors.required">Sequence Number is required.
              </div>
              <div *ngIf="f.sequenceNumber.errors?.pattern">
              Sequence Number must be greater than Zero.
              </div>

            </div>
          </div>
          <div class="form-group mb-2 col-md-6">
          <label for="isDependentOnPreviuos" >Is Dependent on Previous<sup>*</sup></label>
          <select name="isDependentOnPreviuos" [(ngModel)]="milestoneMapping.isDependentOnPreviuos"
            class="form-control form-select" autocomplete=""  formControlName="isDependentOnPreviuos"
            [ngClass]="{ 'is-invalid': submitted && f.isDependentOnPreviuos.errors }">
            <option hidden Value='' disabled>--Choose Option--</option>
            <!-- <option (click)=toggleDisplayDiv() id="divshow"  value="RFID">RFID Reader</option> -->
            <option [value]=false>No</option>
            <option [value]=true>Yes</option>
          </select>
          <!-- <div *ngIf="submitted && f.isDependentOnPreviuos.errors" (click) ="toggleDisplay()" class="text-danger">
            <div *ngIf="f.isDependentOnPreviuos.errors.required">
               *Type is required.
            </div>
          </div> -->
        </div>

        <div class="form-group mb-2 col-md-6">
          <label for="tranType" >Transaction Type<sup>*</sup></label>
          <select name="tranType" [(ngModel)]="milestoneMapping.tranType"
            class="form-control form-select" autocomplete=""  formControlName="tranType"
            [ngClass]="{ 'is-invalid': submitted && f.tranType.errors }">
            <option hidden Value='' disabled>--Choose Option--</option>
            <!-- <option (click)=toggleDisplayDiv() id="divshow"  value="RFID">RFID Reader</option> -->
                <option value =1 >Outbound</option>
                <option value =2 >Inbound</option>
                <option value =4 >InPlant</option>
          </select>
          <!-- <div *ngIf="submitted && f.isDependentOnPreviuos.errors" (click) ="toggleDisplay()" class="text-danger">
            <div *ngIf="f.isDependentOnPreviuos.errors.required">
               *Type is required.
            </div>
          </div> -->
        </div>

        <div class="mb-2 col-md-6 select-radio" [hidden]="activebuttonHide">
          <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="milestoneMapping.isActive" formControlName="isActive">
          <label for="active" class="radio-label">Active</label>
          <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="milestoneMapping.isActive" formControlName="isActive">
          <label for="inactive" class="radio-label">Inactive</label>
        </div>

          <!-- <div class="form-group mb-3 select-radio" *ngIf="activebuttonShow">
            <label for="isActive" class="radio-btn">Active</label>
            <label class="form-check-inline">
              <input class="form-check-input" type="radio" name="isActive" [value]="true" formControlName="isActive"
                [(ngModel)]="milestoneMapping.isActive">
            </label>
            <label for="isActive" class="radio-btn ">Inactive</label>
            <label class="form-check-inline">
              <input class="form-check-input" type="radio" name="isActive" [value]="false" formControlName="isActive"
                [(ngModel)]="milestoneMapping.isActive">
            </label>
          </div> -->
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
        <button type="button" *ngIf="ReasetShow" class="modal-button btn btn-danger" aria-label="Close" (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="registerMilestoneActionMapping(milestoneMapping)">Save</button>
    </div>
  </div>
</ng-template>
