import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { faBan, faEye, faSort, faCamera, faDownload, faImages } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Pagination } from 'src/app/models/pagination';
import { WeighBridgeMasterResponse } from 'src/app/models/weighbridgemastermodel';
import { WbSerachTransactionFunctionality } from 'src/app/models/wbtransearchfunctionality';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { ConfigParms } from '../../common/portal-config';
import { ExcelService } from 'src/app/service/excel.service';
import { DataService } from 'src/app/service/data.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';



@Component({
  selector: 'app-weighbridge-transaction',
  templateUrl: './weighbridge-transaction.component.html',
  styleUrls: ['./weighbridge-transaction.component.scss']
})
export class WeighbridgeTransactionComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable: ElementRef;
  faEye = faEye;
  faBan = faBan;
  faSort = faSort;
  faCamera = faCamera;
  faFileDownload = faDownload;
  faImages = faImages;
  public localStorage = localStorage;
  public toggleButton: boolean = true;
  vrn: any;
  search: boolean = false;
  filter = '';
  protected _onDestroy = new Subject<void>();
  wbstatus: any = weighbridegestatus;
  wbtype: any = weighmentType;
  testList: any[] = []
  //&****************************pagination*******************
  pageSize = 20;
  searchedList: any;
  public pagination: Pagination = new Pagination(1, 0, 15, 0, [15, 20, 25, 30]);
  // public wbsearchParameter: WbSerachTransactionFunctionality = new WbSerachTransactionFunctionality(null, null, null, 0, false, true, null, null);
  public wbsearchParameter: WbSerachTransactionFunctionality = new WbSerachTransactionFunctionality([], null, null,[], false, true, null, null,[]);
  public wbSearchForm: WbSerachTransactionFunctionality = new WbSerachTransactionFunctionality([], null, null,[], false, true, null, null, []);

  public wbsearchParameterForExcel: WbSerachTransactionFunctionality = new WbSerachTransactionFunctionality([], null, null,[], false, true, null, null, []);
  public searchText: string = '';
  title: any;
  key: string = 'vrn'; //set default
  reverse: boolean = false;
  //*********************************************
  hidefoundrecord: boolean = false;
  weighbridgeMaster: any;
  public userAccessData: UserScreenAccesData;
  ImageList: any[];
  ImageVrn: any;
  PDFWeighBridge: any[] = [];
  todayDate: Date;
  PopViewData: any[] = [];
  currentdate: string;
  currentPage: number;
  totalRecords: any;
  selectedColumn: any;
  imageInfo: any;
  plantInfo = '';
  dataForExcel = [];
  transType : any = transactionType;

  sort(key) {

    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads

  transaction: any;
  transactionResponse: any[] = [];
  displayBasic: boolean;
  displayBasic2: boolean;
  displayCustom: boolean = false;
  activeIndex: number = 0;
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];


  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe : DatePipe,
    private excelService: ExcelService,private api:DataService) {
      this.plantInfo = ConfigParms.plant;
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  downloadAllImage() {

    //this.kemarClientService.download(this.ImageList, this.ImageVrn);
    this.ImageList.forEach(element => {
      this.kemarClientService.downloadImage1(element.imageUrl, element.imageName + '.jpg')
    });
  }

  ngOnInit(): void {
    this.PDFWeighBridge;
    this.todayDate = new Date();
    this.wbsearchParameter.fromDateFilter = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    this.wbsearchParameter.toDateFilter = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    //this.wbsearchParameter.weighbridgeId = this.route.snapshot.params.vrn == undefined ? 0 : this.route.snapshot.params.vrn;
    this.getAllWeighBridge();
    this.GetAllVehilceTransaction();
   
    this.currentdate = new Date().toISOString().split('T')[0];
    this.userAccessData =this.api.getUserScreenAccessMenu('WBTRAN')

  }

  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);

  OnPageChange(event) {
    this.pagination.page = event
    this.GetAllVehilceTransaction();
    //this.getAllWeighBridge();
  }

  onClick(item) {
    if (item == 'crossCheckEvent') {
      this.wbsearchParameter.crossCheckEvent = true;
      this.wbsearchParameter.regularEvent = false
    }
    else if (item == 'regularEvent')
      this.wbsearchParameter.regularEvent = true;
    this.wbsearchParameter.crossCheckEvent = false;
  }

  OnPageSizeChange(event) {
    this.pagination.page = 1;
    this.pagination.pageSize = event.target.value;
    this.GetAllVehilceTransaction();
  }

//   GetAllVehilceTransaction() {
// debugger
//     this.spinner.show();
//     this.wbsearchParameter.page = this.pagination.page;
//     this.wbsearchParameter.pagesize = this.pagination.pageSize;
//     this.wbsearchParameter.isExcelDownload = false;
//     this.wbsearchParameter.status = this.wbsearchParameter.status
    
//     this.kemarClientService.postPatch<any>(ServiceUrl.getweighbridgeVehilceTransaction, this.wbsearchParameter)
//       .subscribe(
//         response => {

//           this.spinner.hide();
//           this.transactionResponse = response.items;
//           this.pagination.count = response.count;
//           this.pagination.filterRecordCount = response.filterRecordCount;

//         },
//         r => {
//            this.spinner.hide();
//           //  console.log(r.error.error);
//          });
//         }
GetAllVehilceTransaction() {
  debugger;
  this.spinner.show();

  const mapValues = (sourceArray: any[], key: string) => sourceArray?.length > 0 ? sourceArray.map(item => item[key]) : [];

  this.wbsearchParameter.status = mapValues(this.wbSearchForm.status, 'value');
  this.wbsearchParameter.weighbridgeId = mapValues(this.wbSearchForm.weighbridgeId, 'weighBridgeId');
  this.wbsearchParameter.tranType = mapValues(this.wbSearchForm.tranType, 'value');

  this.wbsearchParameter.page = this.pagination.page;
  this.wbsearchParameter.pagesize = this.pagination.pageSize;
  this.wbsearchParameter.isExcelDownload = false;



  this.kemarClientService.postPatch<any>(ServiceUrl.getweighbridgeVehilceTransaction, this.wbsearchParameter)
    .subscribe(
      response => {
        this.spinner.hide();
        this.transactionResponse = response.items;
        this.pagination.count = response.count;
        this.pagination.filterRecordCount = response.filterRecordCount;
      },
      r => {
        this.spinner.hide();
        // Handle error here
      }
    );
}

    getData(currentPage: any){
    this.spinner.show();
    var query = {
      currentPage: currentPage,
      rowSize: this.pageSize,
      searchtext: this.filter.trim(),
      searchColumn: this.selectedColumn
    };
    this.kemarClientService
      .get<any>(
        null,
        ServiceUrl.getAllVehilceTransaction,
        query
      )
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.transactionResponse = response;
          this.transactionResponse.length > 0 ? this.totalRecords = response[0].totalRecord : this.totalRecords = 0;
          this.currentPage = currentPage;
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }

GetTransactionByVrn(searchtext) {
    this.pagination.page = 1
    this.GetAllVehilceTransaction();
    if (typeof searchtext != 'undefined' && searchtext != null && searchtext != "") {

      this.hidefoundrecord = true;
      this.GetAllVehilceTransaction();
    }
    else {
      this.hidefoundrecord = false;
    }

  }


  getAllWeighBridge() {

    this.spinner.show();
    this.kemarClientService.get<WeighBridgeMasterResponse>(null, ServiceUrl.getAllWeighBridge)
      .subscribe(
        response => {

          this.spinner.hide();
          this.weighbridgeMaster = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });

    //window.location.reload();
  }

  ViewImage(content: any, weighbriddgeDetail: any, flag: boolean) {

    let query;
    if (weighbriddgeDetail == null) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Something went wrong"
      })
      return;
    }
    // if (flag)
    //   query = { "weighbridgeID": weighbriddgeDetail.weighBridgeTransactionId }
    // else
    //   query = { "weighbridgeID": weighbriddgeDetail[0].weighBridgeTransactionId }
    query = { "weighbridgeID": weighbriddgeDetail.weighbridgeTranId }
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getweighbridgeImage, query)
      .subscribe(
        response => {

          this.spinner.hide();
          this.ImageList = response;
          this.ImageVrn = weighbriddgeDetail.vrn;
          this.imageInfo =weighbriddgeDetail;
          if (content != null) {
            this.modalService.open(content, { size: 'lg' });
            this.modalService.open(this.ngbModalOptions);
          }
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });


  }
  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  downloadAsPDF(wb: any, flag: boolean) {
    this.spinner.show();
    this.PDFWeighBridge = [];
    if (flag)
      this.PDFWeighBridge.push(wb);
    else
      this.PDFWeighBridge.push(wb[0]);
    setTimeout(() => {
      let DATA: any = document.getElementById('htmlData');
      this.kemarClientService.DownloadPDF(DATA, this.PDFWeighBridge);
      this.spinner.hide();
    }, 3000);
  }

  print(): void {

    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>WeighBridge Details</title>
          <style>

          </style>
        </head>
    <body onload="window.print();window.close()">
    <div class=" col mt-0 px-2 pb-3>
    ${printContents}
    </div>
    </body>
      </html>`
    );
    popupWin.document.close();
}

  OpenPopup(rowDetails, content) {
    this.PopViewData = [];
    this.PopViewData.push(rowDetails);
    this.modalService.open(content, { size: 'lg' });
    this.modalService.open(this.ngbModalOptions);
  }

  closeButton() {
    this.modalService.dismissAll();
  }

  exportToExcelNonLogisticVehicle(weightTransDetails: any) {

    this.dataForExcel = [];

    weightTransDetails.forEach((row: any) => {
      this.dataForExcel.push(Object.values(row));
    });

    let gateData = {
      title: 'Weighment Transaction',
      data: this.dataForExcel,
      headers: Object.keys(weightTransDetails[0]),
    };
    this.excelService.exportExcelForWeighbridgeTransaction(gateData);
  }

  GetAllVehilceTransactionforExcel() {

    this.spinner.show();
    this.wbsearchParameterForExcel = this.wbsearchParameter;
    this.wbsearchParameterForExcel.page = this.pagination.page;
    this.wbsearchParameterForExcel.pagesize = this.pagination.pageSize;
    this.wbsearchParameterForExcel.isExcelDownload = true;
    //this.wbsearchParameterForExcel.status = true;
    this.kemarClientService.postPatch<any>(ServiceUrl.getweighbridgeVehilceTransaction, this.wbsearchParameterForExcel)
      .subscribe(
        response => {
          this.spinner.hide();
          this.exportToExcelNonLogisticVehicle(response.items);
        },
        r => {
           this.spinner.hide();
          Swal.fire('error','');
         });
        }


}
const weighbridegestatus: { key: string, value: string }[] = [
  // { key: '-All Status', value: null },
  { key: 'Cancelled', value: 'Cancelled' },
  { key: 'Pending', value: 'Pending' },
  { key: 'Completed', value: 'Completed' },
  { key: 'Overweight', value: 'OVERWEIGHT' },
  { key: 'Underweight', value: 'UNDERWEIGHT' },

];
const weighmentType: { key: string, value: string }[] = [
  { key: '-All Type', value: null },
  { key: 'Tare', value: 'TARE' },
  { key: 'Gross', value: 'GROSS' }
];
const transactionType: { key: string, value: string }[] = [
  //{ key: '-All Type', value: null },
  { key: 'Inbound', value: '2' },
  { key: 'OutBound', value: '1' },
  { key: 'InPlant', value: '3' }
];
