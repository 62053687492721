import { AfterViewInit, Component,ElementRef,HostListener, OnInit, ViewChild } from '@angular/core';
import {AbstractControl,UntypedFormBuilder,UntypedFormGroup, Validators,} from '@angular/forms';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { elv, VehicleDetail } from 'src/app/apiModels/elv-models';
import {JobAllocationData,jobAllocation,Products, Materials,} from 'src/app/apiModels/job-allocation-models';
import {milestoneJsonData,VehicleMilestone,Milestones,MilestoneWorkFlow,elvFromExcel,} from 'src/app/apiModels/milestone-model';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import {  faUpload,  faDownload} from '@fortawesome/free-solid-svg-icons';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-api-simulation',
  templateUrl: './api-simulation.component.html',
  styleUrls: ['./api-simulation.component.scss']
})
export class ApiSimulationComponent implements OnInit {
  elvForm!: UntypedFormGroup;
  jobAllocationForm!: UntypedFormGroup;
  milestoneForm!: UntypedFormGroup;
  public elvData: elv = new elv();
  public vehicleData: VehicleDetail = new VehicleDetail();
  public jobAllocationjsonData: JobAllocationData = new JobAllocationData();
  public jobAllocationjson: jobAllocation = new jobAllocation();
  public Products: Products = new Products();
  public Materials: Materials = new Materials();
  public detailForELV!: any;
  public detailForJobAllocation!: any;
  public detailForMilestone!: any;
  public milestoneWorkFlow!: any;
  faDownload = faDownload;
  faUpload = faUpload;
  submitted = false;
  submitted1 = false;
  submitted2 = false;
  elvSuccessfullyDone: boolean = false;
  jobAllocationSuccessfullyDone: boolean = false;
  todateDate!: string;
  productData: any[];
  driverMasterData: any[] = [];
  milestoneTemplate1: Array<Milestones> = new Array<Milestones>();
  milestoneTemplate2: Array<Milestones> = new Array<Milestones>();
  milestoneJsonData: VehicleMilestone = new VehicleMilestone();
  milestoneJsonDataFinale: milestoneJsonData = new milestoneJsonData();
  milestoneWorkFlowModel: MilestoneWorkFlow = new MilestoneWorkFlow();
  milestoneTemplateDataChooseOption = '';
  milestoneReadyToSend = false;
  templateListData: Array<any> = new Array<any>();
  docTypeIsWorng: boolean = false;
  UOMValue = 'mt';
  excelData: any;
  elvListFromExcel: Array<elvFromExcel> = new Array<elvFromExcel>();
  elvDataFromExcel: elvFromExcel = new elvFromExcel();
  excelErrorMessage: any;
  public newProductList: Array<Products> = new Array<Products>();
  vehicleMasterData: any[] = [];
  batchSize: number = 100;
  currentBatchIndex: number = 0;
  batchNumber:number = 0;  
  searchTextVRN : string = null;
  searchTextDriver : string = null;
  loading: boolean = false;
  currentVendorPlaceholder = 'Select a Driver';
  driverBatch: number = 0;
  driverSize :number = 100;
  tempTextVRN :string;
  tempTextDriver :string;
  isUniqueVRN: boolean = false;
  isUniqueDriver: boolean = false;
  @ViewChild('select') select: NgSelectComponent;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private kemar: KemarClientService,
    private spinner: NgxSpinnerService,
    private datePipe : DatePipe
  ) { }


  ngOnInit(): void {
    var todateDateValue = new Date();
    this.todateDate = new Date(todateDateValue).toISOString().split('T')[0];
    this.resetForELV();
    this.setDefaulfValuesForElv();
    this.resetForJobAllocation();
    this.getProductMaster();
    this.getVehicleMaster();
    this.getDriverMaster();
    this.getMilestoneWorkFlow(1);
  }

 
  getProductMaster() {

    this.kemar.get<any>(null, ServiceUrl.getallProductMaster).subscribe(
      (response) => {
        this.productData = response;
      },
      (r) => {
        alert(r.error.error);
      }
    );
  }

  loadVehicles(){
    this.getVehicleMaster();
  }
  onSearchVRN(event : any){
    this.searchTextVRN = event.term;
    this.getVehicleMaster();
  }

  // Fetch all data
  getVehicleMaster() {
    this.spinner.show();
    if(this.searchTextVRN!= '' && this.searchTextVRN != null){
      if(this.tempTextVRN != this.searchTextVRN){ 
         this.tempTextVRN = this.searchTextVRN;
         this.batchNumber = 0;
         this.isUniqueVRN= true;
         this.vehicleMasterData = [];
      }
      else{
        this.isUniqueVRN= false;
      }
    }
    const body = { 
      batchNumber: this.batchNumber,
       batchSize: this.batchSize ,
       searchTerm:this.searchTextVRN != '' && this.searchTextVRN != null? this.searchTextVRN:''
      };
  
    this.kemar.get<any>(null, ServiceUrl.GetAllLogisticsVehicleWithpaginationAsync, body)
      .subscribe(
        (response) => {
          if(this.searchTextVRN!= '' && this.searchTextVRN != null){
            if(this.isUniqueVRN){
              this.vehicleMasterData =response;
            }
           else{
            // this.vehicleMasterData =response;
            this.vehicleMasterData = [...this.vehicleMasterData, ...response];
           }
          }
          else{
            if (response && response.length > 0) {
              this.vehicleMasterData = [...this.vehicleMasterData, ...response];
              this.batchNumber++;  
            }
          }
        
        this.spinner.hide();
        },
        (error) => {
          this.loading = false;  // Reset loading flag on error
          alert(error.error?.message || 'Error loading vehicles');
        }
      );
  }
  
  
  getMilestoneWorkFlow(tranType: any) {
    var query = {
      'TranType': tranType
    }
    this.kemar.get<any>(null, ServiceUrl.getAllActiveWorkFlowMaster,query).subscribe(
      (response) => {
        this.milestoneWorkFlow = response;
      },
      (r) => {
        alert(r.error.error);
      }
    );
  }
  loadDriver(){
    this.driverBatch++;
    this.getDriverMaster();
  }
  onSearchDriver(event : any){
    this.searchTextDriver = event.term;
    this.getDriverMaster();
  }
  getDriverMaster() {
    debugger
    this.spinner.show();
    if(this.searchTextDriver!= '' && this.searchTextDriver != null){
      if(this.tempTextDriver != this.searchTextDriver){ 
         this.tempTextDriver = this.searchTextDriver;
         this.driverBatch = 0;
         this.isUniqueDriver= true;
         this.driverMasterData = [];
      }
      else{
        this.isUniqueDriver= false;
      }
    }
    const body = {
       page: this.driverBatch,
       size: this.driverSize,
       searchTerm:this.searchTextDriver != '' && this.searchTextDriver != null? this.searchTextDriver:''
      };
    this.kemar.get<any>(null, ServiceUrl.GetDriverWithPagination,body).subscribe(
      (response) => {
        if(this.searchTextDriver!= '' && this.searchTextDriver != null){
          if(this.isUniqueDriver){
            this.driverMasterData  = response;
          }
         else{
          this.driverMasterData = [...this.driverMasterData , ...response];
         }
        }
        else{
          if (response && response.length > 0) {
            this.driverMasterData = [...this.driverMasterData, ...response];
            this.driverBatch++;  
          }
        }
        this.spinner.hide();
      },
      (r) => {
        alert(r.error.error);
      }
    );
  }

  setDefaulfValuesForElv() {
    // var driverCode = this.driverMasterData.filter((x) => x.driverCode == '0000');
    var newDate = new Date();
    newDate.setDate(newDate.getDate() + 2);
    this.elvForm.controls['ValidTill'].setValue(
      (newDate.toISOString().split('T')[0])
    + ' ' + newDate.toTimeString().slice(0,5)
    );
  }

  resetForELV() {
    this.elvForm = this.fb.group({
      ELVCode: [''],
      VRN: ['', [Validators.required]],
      DriverCode: ['', [Validators.required]],
      MovementType: ['outbound'],
      ValidTill: ['', [Validators.required]],
      IsActive: [true],
      Doctype: ['DO', [Validators.required]],
    });
  }

  onChangeMovementType(type: number) {
    this.getMilestoneWorkFlow(type);
    if (type === 1) {
      this.elvForm.controls['Doctype'].setValue('DO');
    } else {
      this.elvForm.controls['Doctype'].setValue('PO');
    }
  }

  resetForJobAllocation() {
    this.jobAllocationForm = this.fb.group({
      DocumentNumber: [''],
      ShipmentNumber: [''],
      JobAllocationCode: [''],
      MovementType: [''],
      MaterialType: [''],
      ProductCode: ['', [Validators.required]],
      Weight: [0],
      Quantity: [0, Validators.min(1)],
      MRP: [400, [Validators.required]],
      ProductTransactionCode: [''],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.elvForm.controls;
  }
  get f1(): { [key: string]: AbstractControl } {
    return this.jobAllocationForm.controls;
  }
  get f2(): { [key: string]: AbstractControl } {
    return this.milestoneForm.controls;
  }

  resetAllForm() {
    this.milestoneTemplateDataChooseOption = '';
    this.elvSuccessfullyDone = false;
    this.jobAllocationSuccessfullyDone = false;
    this.resetForELV();
    this.setDefaulfValuesForElv();
    this.resetForJobAllocation();
    this.milestoneTemplate1 = new Array<Milestones>();
  }

  CreateJsonForELV() {
    this.detailForELV = '';
    this.elvForm.controls['ELVCode'].setValue(
      'E' + this.generaterandonELVCode()
    );
    this.submitted = true;
    if (this.elvForm.invalid) {
      return;
    }
    if (
      this.elvForm.controls['Doctype'].value == 'DO' ||
      this.elvForm.controls['Doctype'].value == 'PO' ||
      this.elvForm.controls['Doctype'].value == 'STO'
    ) {
      this.docTypeIsWorng = false;
    } else {
      this.docTypeIsWorng = true;
      this.submitted = false;
      this.elvForm.controls['Doctype'].setValue('');
      return;
    }
    this.vehicleData = new VehicleDetail();
    this.elvData = new elv();
    this.vehicleData.ELVCode = this.elvForm.controls['ELVCode'].value;
    this.vehicleData.VRN = this.elvForm.controls['VRN'].value;
    this.vehicleData.DriverCode = this.elvForm.controls['DriverCode'].value;
    this.vehicleData.MovementType = this.elvForm.controls['MovementType'].value;
    this.vehicleData.ValidTill = this.elvForm.controls['ValidTill'].value + ":00";
    this.vehicleData.IsActive = this.elvForm.controls['IsActive'].value;
    this.vehicleData.Doctype = this.elvForm.controls['Doctype'].value;
    this.elvData.RequestId = this.generateRandomNumber().toString();
    this.elvData.VehicleList.push(this.vehicleData);
    this.elvData.LocationCode = '';
    if(this.vehicleData.MovementType == 'outbound'){
      this.UOMValue = 'bags';
    }
    else{
      this.UOMValue = 'mt';
    }
    // var currentdate = new Date();
    // this.vehicleData.ValidTill =   this.vehicleData.ValidTill + " " +  this.getFormattedCurrentTime();
    // console.log(this.elvData);
    this.sendJsonToSystemForELV(this.elvData);
  }



   getFormattedCurrentTime()
  {
    var dt = new Date();
    return  this.padWithLeadingZeros(dt.getHours(),2 )  + ":"
          + this.padWithLeadingZeros(dt.getMinutes(),2 )  + ":"
          + this.padWithLeadingZeros(dt.getSeconds(),2 )
      ;


  }

   padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }



  generateRandomNumber() {
    var newDate = new Date();
    const random =
      newDate.getDate().toString() +
      newDate.getMonth().toString() +
      newDate.getHours().toString() +
      newDate.getMinutes().toString() +
      newDate.getMilliseconds().toString();
    // const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
    return random;
  }
  generaterandonELVCode() {
    var newDate = new Date();
    const random =
      newDate.getDate().toString() +
      newDate.getMonth().toString() +
      newDate.getHours().toString() +
      newDate.getMinutes().toString() +
      newDate.getMilliseconds().toString();
    // const random = Math.floor(Math.random() * (9999 - 1000)) + 1000;
    return random;
  }

  generateRandomNumberForDocumentNumber() {
    var newDate = new Date();
    const random =
      newDate.getDate().toString() +
      newDate.getMonth().toString() +
      newDate.getHours().toString() +
      newDate.getMinutes().toString() +
      newDate.getMilliseconds().toString();
    // const random = Math.floor(Math.random() * (9999999 - 1000000)) + 1000000;
    return random;
  }

  generateRandomNumberForShipmentNumber() {
    var newDate = new Date();
    const random =
      newDate.getDate().toString() +
      newDate.getMonth().toString() +
      newDate.getHours().toString() +
      newDate.getMinutes().toString() +
      newDate.getMilliseconds().toString();
    // const random = Math.floor(Math.random() * (99999999 - 10000000)) + 10000000;
    return random;
  }

  sendJsonToSystemForELV(data: any) {
    this.kemar.postPatch<any>(ServiceUrl.sendELVApi, data).subscribe(
      (res) => {
         ;
        this.setValueForJobAllocation();
        this.elvSuccessfullyDone = true;
        this.submitted = false;
        this.detailForELV = '';
      },
      (r) => {
        this.detailForELV = '';
         ;
        r.error.errorVehicles.forEach((element: any) => {
          element.errorMessages.forEach((value: any) => {
            this.detailForELV = this.detailForELV + '\n ' + value;
          });
        });
        this.setDefaulfValuesForElv();
        this.submitted = false;
        // this.detailForELV = r.error.errorVehicles[0].errorMessages[0];
      }
    );
  }

  setValueForJobAllocation() {
     ;
    this.jobAllocationForm.controls['DocumentNumber'].setValue(
      this.elvForm.controls['Doctype'].value +
        this.generateRandomNumberForDocumentNumber()
    );

    this.jobAllocationForm.controls['ShipmentNumber'].setValue(
      'SN' + this.generateRandomNumberForShipmentNumber()
    );

    this.jobAllocationForm.controls['JobAllocationCode'].setValue(
      this.generateRandomNumberForShipmentNumber()
    );

    this.jobAllocationForm.controls['ProductTransactionCode'].setValue(
      this.generateRandomNumberForShipmentNumber()
    );

    this.jobAllocationForm.controls['MovementType'].setValue(
      this.elvForm.controls['MovementType'].value
    );
  }

  setProductWeight() {
    if (this.UOMValue == 'bags') {
      var noBags = this.jobAllocationForm.controls['Quantity'].value;
      var productWeight = noBags * 50;
      this.jobAllocationForm.controls['Weight'].setValue(productWeight);
    } else {
      var weight = this.jobAllocationForm.controls['Weight'].value * 1000;
      var productQuantity = 400;
      this.jobAllocationForm.controls['Weight'].setValue(weight);
      this.jobAllocationForm.controls['Quantity'].setValue(productQuantity);
    }
  }

  setProductName() {
    var productCode = this.jobAllocationForm.controls['ProductCode'].value;
    var productName = this.productData.filter(
      (x) => x.productCode == productCode
    );
    this.jobAllocationForm.controls['MaterialType'].setValue(
      productName[0].category
    );
  }

  AddNewProduct() {
    this.setProductWeight();
    this.submitted1 = true;
    if (this.jobAllocationForm.invalid) {
      return;
    }
    var Productslatest = new Products();
    Productslatest.MaterialType =
      this.jobAllocationForm.controls['MaterialType'].value;
    Productslatest.ProductCode =
      this.jobAllocationForm.controls['ProductCode'].value;
    Productslatest.Weight = this.jobAllocationForm.controls['Weight'].value;
    Productslatest.Quantity = this.jobAllocationForm.controls['Quantity'].value;
    Productslatest.MRP = this.jobAllocationForm.controls['MRP'].value;
    Productslatest.BatchNo = 'BA' + this.generateRandomNumber();
    Productslatest.ShippingSequence = (
      this.newProductList.length + 1
    ).toString();
    Productslatest.IsActive = 'true';
    Productslatest.ProductTransactionCode =
      this.generateRandomNumber().toString();

    var productName = this.productData.filter(
      (x) => x.productCode == Productslatest.ProductCode
    );
    Productslatest.ProductName = productName[0].productName;
    Productslatest.UOMValue = this.UOMValue;
    Productslatest.Materials.push(this.Materials);
    this.resetForJobAllocation();
    this.newProductList.push(Productslatest);
    this.submitted1=false;
  }

  createJsonDataForJobAllocation() {

    // if(this.newProductList.length = 0){
    //   this.AddNewProduct();
    // }
    this.setProductWeight();
    this.Products = new Products();
    this.jobAllocationjsonData = new JobAllocationData();
    this.jobAllocationjson = new jobAllocation();
    this.submitted1 = true;
    if (this.jobAllocationForm.invalid) {
      return;
    }
    this.Products.MaterialType =
      this.jobAllocationForm.controls['MaterialType'].value;
    this.Products.ProductCode =
      this.jobAllocationForm.controls['ProductCode'].value;
    this.Products.BatchNo = 'BA' + this.generateRandomNumber();
    this.Products.Weight = this.jobAllocationForm.controls['Weight'].value;
    this.Products.Quantity = this.jobAllocationForm.controls['Quantity'].value;
    this.Products.MRP = this.jobAllocationForm.controls['MRP'].value;
    this.Products.ShippingSequence = '1';
    this.Products.IsActive = 'true';
    this.Products.ProductTransactionCode =
      this.generateRandomNumber().toString();
      this.Products.DocumentType =
      this.elvForm.controls['Doctype'].value;
    this.Products.Materials.push(this.Materials);
    this.Products.DocumentNumber =
      this.jobAllocationForm.controls['DocumentNumber'].value;

    this.jobAllocationjson.ELVCode = this.elvForm.controls['ELVCode'].value;
    this.jobAllocationjson.VRN = this.elvForm.controls['VRN'].value;
    this.jobAllocationjson.DriverCode =
      this.elvForm.controls['DriverCode'].value;
    this.jobAllocationjson.JobAllocationCode =
      this.jobAllocationForm.controls['JobAllocationCode'].value;
    this.jobAllocationjson.IsActive = 'true';
    this.jobAllocationjson.MovementType =
      this.elvForm.controls['MovementType'].value;
    this.jobAllocationjson.ShipmentNumber =
      this.jobAllocationForm.controls['ShipmentNumber'].value;
    this.jobAllocationjson.Products.push(this.Products);

    this.jobAllocationjsonData.RequestId =
      this.generateRandomNumber().toString();
    this.jobAllocationjsonData.JobAllocations.push(this.jobAllocationjson);

    this.sendJsonToSystemForJobAllocation();
  }

  sendJsonToSystemForJobAllocation() {
    this.kemar
      .postPatch<any>(ServiceUrl.sendJobAllocation, this.jobAllocationjsonData)
      .subscribe(
        (res) => {
           ;
          // this.setValueForJobAllocation();
          this.jobAllocationSuccessfullyDone = true;
          this.submitted1 = false;
        },
        (r) => {
          this.detailForJobAllocation = '';
           ;
          r.error.errorJobAllocations.forEach((element: any) => {
            element.errorMessages.forEach((value: any) => {
              this.detailForJobAllocation =
                this.detailForJobAllocation + '\n ' + value;
            });
          });
        }
      );
  }

  createJsonDataForMilestone() {
    this.milestoneJsonData = new VehicleMilestone();
    this.milestoneJsonDataFinale = new milestoneJsonData();
    this.milestoneJsonData.RequestId = this.generateRandomNumber().toString();
    this.milestoneJsonData.VRN = this.elvForm.controls['VRN'].value;
    this.milestoneJsonData.ELVCode = this.elvForm.controls['ELVCode'].value;
    this.milestoneTemplate1.forEach((element) => {
      this.milestoneJsonData.Milestones.push(element);
    });
    this.milestoneJsonDataFinale.RequestId = this.generateRandomNumber();
    this.milestoneJsonDataFinale.vehiclemilestones.push(this.milestoneJsonData);
    this.sendJsonToSystemFormilestone();
  }

  sendJsonToSystemFormilestone() {
    this.kemar
      .postPatch<any>(ServiceUrl.sendMilestone, this.milestoneJsonDataFinale)
      .subscribe(
        (res) => {
           ;
          Swal.fire('', res.responseMessage, 'success');
          // this.setValueForJobAllocation();
          this.jobAllocationSuccessfullyDone = false;
          this.submitted2 = false;
          this.milestoneReadyToSend = false;
          this.resetAllForm();
          this.router.navigate(['/elv']);
        },
        (r) => {
          this.detailForMilestone = '';
           ;
          r.error.errors.forEach((element: any) => {
            this.detailForMilestone = this.detailForMilestone + '\n ' + element;
          });
        }
      );
  }

  selectMilestoneTemplate() {
    switch (this.milestoneTemplateDataChooseOption) {
      case '1':
        // this.milestoneTemplateOne('3001', 'Packer 1');
        this.milestoneReadyToSend = true;
        break;
      case '2':
        // this.milestoneTemplateOne('3002', 'Packer 2');
        this.milestoneReadyToSend = true;
        break;
    }
  }

  selectMilestoneGroup() {
    this.milestoneTemplate2 = new Array<Milestones>();
    var milestone = this.milestoneWorkFlow.filter(
      (milestone) =>
        milestone.workFlowCode === this.milestoneTemplateDataChooseOption
    );
    this.milestoneTemplate1 = new Array<Milestones>();
    milestone[0].workFlowDetails.forEach((element) => {
      this.milestoneTemplate2.push(element);
    });
    this.milestoneReadyToSend = true;
    // console.log(this.milestoneTemplate2);
  }

  sendMilestoneWithWorkFlowGroupName() {
    this.milestoneWorkFlowModel = new MilestoneWorkFlow();
    this.milestoneWorkFlowModel.ELVCode =
      this.elvForm.controls['ELVCode'].value;
    this.milestoneWorkFlowModel.VRN = this.elvForm.controls['VRN'].value;
    this.milestoneWorkFlowModel.WorkFlowGroupCode =
      this.milestoneTemplateDataChooseOption;
      this.milestoneReadyToSend = false;
    this.kemar
      .postPatch<any>(
        ServiceUrl.updateMilestoneWithGroupName,
        this.milestoneWorkFlowModel
      )
      .subscribe(
        (res) => {
           ;
          Swal.fire('', res.responseMessage, 'success');
          this.jobAllocationSuccessfullyDone = false;
          this.submitted2 = false;
          this.milestoneReadyToSend = false;
          this.resetAllForm();
          this.router.navigate(['/elv']);
        },
        (r) => {
          this.detailForMilestone = '';
           ;
          r.error.errors.forEach((element: any) => {
            this.detailForMilestone = this.detailForMilestone + '\n ' + element;
          });
        }
      );
  }

  getTextFileTemplate() {
    this.kemar
      .readTextFile<any>(
        '/assets/milestonetemplates/' +
          this.milestoneTemplateDataChooseOption +
          '.txt'
      )
      .subscribe(
        (response: any) => {
          this.milestoneTemplate1 = new Array<Milestones>();
          const lines = response.split('},');
          let data = {};
          lines.forEach((line) => {
            var lineData = line.split(',');
            if (lineData != '') {
              lineData.forEach((element) => {
                element = element.replace('{', '');
                element = element.replace('"', '');
                element = element.trim();
                const [key, value] = element.split('=');
                data[key] = value;
              });
              this.createJsonDataForMIlestoneTemplate(data);
              // console.log(data);
            }
          });
          this.milestoneReadyToSend = true;
        },
        (r) => {
          alert(r.error.error);
        }
      );
  }


  createJsonDataForMIlestoneTemplate(data: any) {
    var milestone1 = new Milestones();
    milestone1.MilestoneTransactionCode =
      this.generateRandomNumber().toString() + data.MilestoneCode;
    milestone1.MilestoneCode = data.MilestoneCode;
    milestone1.milesteoneName = data.milesteoneName;
    milestone1.LocationCode = data.LocationCode;
    milestone1.LocationName = data.LocationName;
    milestone1.MileStoneSequence = data.MileStoneSequence;
    milestone1.IsOptional = data.IsOptional;

    this.milestoneTemplate1.push(milestone1);
  }

  uploadExcelFile(evt: any) {
    this.elvListFromExcel = new Array<elvFromExcel>();
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      this.excelData = null;
      this.excelData = <any>XLSX.utils.sheet_to_json(ws, { header: 1 });
      // console.log('data:', this.excelData);
      this.excelData.map((res) => {
        if (res.length > 0) {
          if(res[0] != '' && res[0] != undefined){
            this.elvDataFromExcel = new elvFromExcel();
            this.elvDataFromExcel.VRN = res[0];
            this.elvDataFromExcel.DriverCode = res[1];
            this.elvDataFromExcel.Product = res[2];
            this.elvDataFromExcel.UOM = res[3];
            this.elvDataFromExcel.Bag = res[4];
            this.elvDataFromExcel.Weight = res[5];
            this.elvDataFromExcel.MilestoneGroupName = res[6];
            this.elvDataFromExcel.Type = res[7];
            this.elvListFromExcel.push(this.elvDataFromExcel);
          }
        }
      });
      this.elvListFromExcel.shift();
      // console.log('List Data', this.elvListFromExcel);
      this.sendMilestoneWitheExcelList();
    };
    reader.readAsBinaryString(target.files[0]);
    // this.CreateJsonList();
  }

  sendMilestoneWitheExcelList() {
    // var body = JSON.stringify(this.excelData);
    this.spinner.show();
    this.excelErrorMessage = '';
    this.kemar
      .postPatch<any>(
        ServiceUrl.postVehicleMilestonesFromExcelAsync,
        this.elvListFromExcel
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          Swal.fire('', res.responseMessage, 'success');
          this.router.navigate(['/elv']);
        },
        (r) => {
          var error = r.error.errorMilestoneAllocations;
          error.forEach((element) => {
            this.excelErrorMessage = this.excelErrorMessage + element.vrn + ' ';
            element.errorMilestones.forEach((ele) => {
              this.excelErrorMessage =
                this.excelErrorMessage + ele.errorMessage + ' ';
            });
            this.excelErrorMessage = this.excelErrorMessage + '\n ';
          });
          this.spinner.hide();
        }
      );
  }

}
