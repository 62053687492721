import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { DeviceLocationMappingResponse } from 'src/app/models/devicelocationmappingmodel';
import { DeviceMasterResponse } from 'src/app/models/devicemastermodel';
import { InvoicingResponse } from 'src/app/models/invoicingresponsemodel';
import { PhysicalCheckResponse } from 'src/app/models/physicalcheckresponsemodel';
import { RFIDResponse } from 'src/app/models/rfidmodel';
import { WeighBridgeControllerResponse } from 'src/app/models/weighbridgecontrollerresponsemodel';
import { WeighBridgeMasterResponse } from 'src/app/models/weighbridgemastermodel';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ax4simulation',
  templateUrl: './ax4simulation.component.html',
  styleUrls: ['./ax4simulation.component.scss']
})
export class Ax4SimulationComponent implements OnInit {

  locationMaster:any;
  WeighBridgeMaster:any;
  weighbridgeSimulation: any;
  submitted = false;
  panelOpenState = false;
  form: any;
  deviceMaster: any;
  locMapping:any;
  public RFIDSimulation: RFIDResponse = new RFIDResponse();
  public WeighBridgeControllerResponse: WeighBridgeControllerResponse = new WeighBridgeControllerResponse();
  public PhysicalCheckResponse: PhysicalCheckResponse = new PhysicalCheckResponse();
  public InvoicingResponse: InvoicingResponse = new InvoicingResponse();
  deviceLocationMappingMaster:any;

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService, private modalService: NgbModal) {

      this.form = this.formBuilder.group({

        deviceLocationId: [0],
        RFIDTagNo: [''],

        vrn: [
          '',

        ],
        weight: [''],
        weighBridgeId: [0],
        deviceId: [0],
        Status:[''],
        VRN:[''],
        Comment:[''],
        milestoneTransactionCode: [''],


      });

    }

  ngOnInit(): void {
  //  this.getAllLocation();
    this.getAllWeighBridge();
    this.getAllDevice();
    this.getAllDeviceLocationMapping();
  }
  // getAllLocation() {
  //   this.kemarClientService.get<LocationMasterResponse>(null, ServiceUrl.getAllLocations)
  //     .subscribe(
  //       response => {
  //         this.locationMaster = response;
  //       },
  //       r => {
  //         alert(r.error.error);
  //         console.log(r.error.error);
  //       });
  // }
  getAllDeviceLocationMapping() {
    this.kemarClientService.get<DeviceLocationMappingResponse>(null, ServiceUrl.getAllDeviceLocationMappings)
      .subscribe(
        response => {

          this.deviceLocationMappingMaster = response;
        this.locMapping=  this.deviceLocationMappingMaster.filter(x => x.deviceType == 'Controller');
        },
        r => {
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }
  getAllDevice() {
    this.spinner.show();
    this.kemarClientService.get<DeviceMasterResponse>(null, ServiceUrl.getdeviceMapping)
      .subscribe(
        response => {
          this.deviceMaster = response;
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  getAllWeighBridge() {
    this.spinner.show();
    this.kemarClientService.get<WeighBridgeMasterResponse>(null, ServiceUrl.getAllWeighBridge)
      .subscribe(
        response => {
          this.spinner.hide();
          this.WeighBridgeMaster = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }
  PostRFIDTAG() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.postRFIDTAG, this.RFIDSimulation)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success')


          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
          // console.log(r);
        });
        window.location.reload();
  }
  PostWeighbridge(WeighBridgeControllerResponse) {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.postWeighbridgeTransaction,WeighBridgeControllerResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success')


          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
          // console.log(r);
        });
        window.location.reload();
  }
  PhysicalCheckModel(PhysicalCheckResponse) {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.PhysicalCheckModel,PhysicalCheckResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success')


          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
          // console.log(r);
        });
        window.location.reload();
  }
  invoicingResponse(InvoicingResponse) {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.InvoicingModel,InvoicingResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success')


          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
          // console.log(r);
        });
        window.location.reload();
  }
  lodingResponse(InvoicingResponse) {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.LodingModel,InvoicingResponse)
      .subscribe(
        res => {
          Swal.fire('', res.message, 'success')


          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
          // console.log(r);
        });
        window.location.reload();
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

}
