import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faCar, faEdit, faMapPin, faPlus, faSort, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { NonLogisticVehicleResponse } from 'src/app/models/nonlogisticvehiclemodel';
import { VehicleMasterResponse } from 'src/app/models/vehiclemastermodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
@Component({
  selector: 'app-third-party-vehicle',
  templateUrl: './third-party-vehicle.component.html',
  styleUrls: ['./third-party-vehicle.component.scss']
})


export class ThirdPartyVehicleComponent implements OnInit {

  BlacklistingReason: any;
  vehicleBlacklisting: [];
  Blacklisting: any;
  nonLogisticVehicle: any[];
  title: any;
  faEdit = faEdit;
  faTimes = faTimes
  faMapPin = faMapPin
  faCar = faCar
  faPlus = faPlus
  faSort = faSort
  submitted = false;
  form: FormGroup;
  blacklistedDll: any = blacklistedDll;
  IsactiveShow: boolean = false;
  public vehicle: VehicleMasterResponse = new VehicleMasterResponse();
  public userAccessData: UserScreenAccesData;

  // public nonLogistic: NonLogisticVehicleResponse = new NonLogisticVehicleResponse();

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'vrDate'; //set default
  reverse: boolean = false;
  resetbuttonShow: boolean = false;
  tempcurrentActivePage: number = 1;
  selectedColumn: any = '1';
  totalRecords: any;
  faSearch = faSearch;
  IsEnableSearchBox: boolean = true;

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  blaclistingChange = true;
  ReasonRequired = true;
  DescriptionRequired = true;
  IncidentRequired = true;
  currentdate: string;
  blaclistingDescription: boolean;
  blaclistingIncidentDate: boolean = false;
  Blacklistingreason: boolean = false;
  PreviewsBlackListed: boolean;
  DateRequired = true;


  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton') closebutton;


  constructor(private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder,

    private kemarClientService: KemarClientService, private modalService: NgbModal,
    private api :DataService) { }


  ngOnInit(): void {
    this.getVehiclesBlacklistingReason();
    this.form = this.formBuilder.group({
      rfidTagNumber: [''],
      isActive: [''],
      vrn: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(11), Validators.pattern("[a-zA-Z0-9]*"), this.noWhitespaceValidator]],
      tareweightKg: [0,],
      engineCapacityCC: [0],
      isBlackListed: [''],
      isVerified: [''],
      permitTill: ['', [Validators.required]],
      vehicleType: [''],
      vrDate: ['', [Validators.required]],
      numberOfWheels: [0,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(2),
          Validators.pattern('[0-9-]*'),
          Validators.min(2),
        ],],
      capacityInTons: [0],
      reason: [''],
      description: [''],
      incidentDateTime: [''],

    });
    this.getAllNonLogisticVehicle(this.currentActivePage);
    this.currentdate = new Date().toISOString().split('T')[0];
    this.userAccessData =this.api.getUserScreenAccessMenu('VEHICLE');
  }

  public rfidValidationOnChange(){
    var rfidValue = this.form.controls['rfidTagNumber'].value;
    if(rfidValue == '' || rfidValue == undefined || rfidValue == null){
      this.form.controls['rfidTagNumber'].clearValidators();
    }
    else{
      this.form.controls['rfidTagNumber'].setValidators(
        [Validators.minLength(20),
          Validators.maxLength(28),
          Validators.pattern('[a-zA-F0-9]*'),
          this.noWhitespaceValidator]
      );
    }
    this.form.controls['rfidTagNumber'].updateValueAndValidity();
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  OnPageSizeChange(pageSize: any) {
    this.pageSize = pageSize;
    this.getAllNonLogisticVehicle(this.currentActivePage);
  }
  getVehiclesBlacklistingReason() {
    this.spinner.show();
    this.kemarClientService
      .get<VehicleMasterResponse>(null, ServiceUrl.GetVehicleBlacklistingReason)
      .subscribe(
        (response) => {

          this.BlacklistingReason = response;
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  keyPressAlphaNumeric(event) {
    this.kemarClientService.keyPressAlphaNumeric(event);
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset() {
    this.submitted = false;
  }

  // getAllNonLogisticVehicle() {
  //   this.spinner.show();
  //   this.kemarClientService.get<any>(null, ServiceUrl.getAllNonLogisticsVehicleAsync)
  //     .subscribe(
  //       response => {
  //         this.spinner.hide();
  //         this.nonLogisticVehicle = response;
  //       },
  //       r => {
  //         this.spinner.hide();
  //         alert(r.error.error);
  //         console.log(r.error.error);
  //       });
  // }

  SearchFilter() {
    this.currentActivePage = 1;
    this.getAllNonLogisticVehicle(this.currentActivePage);
  }

  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);
  getAllNonLogisticVehicle(currentPage: any) {
    if (!this.kemarClientService.commonValidation(this.nonLogisticVehicle) && this.nonLogisticVehicle.length < this.pageSize) {
      this.currentActivePage = 1
      this.getData(1)
    }
    else
      this.getData(currentPage)
  }

  getData(currentPage: any) {

    this.spinner.show();
    var query = {
      vehicleType:'NONLOGISTIC',
      currentPage: currentPage,
      rowSize: this.pageSize,
      searchtext: this.filter.trim(),
      searchColumn: this.selectedColumn
    };
    this.kemarClientService
      .get<any>(
        null,
        ServiceUrl.getAllNonLogisticsVehicleAsync,
        query
      )
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.nonLogisticVehicle = response;
          this.nonLogisticVehicle.length > 0 ? this.totalRecords = response[0].totalRecord : this.totalRecords = 0;
          this.currentActivePage = currentPage;
        },
        (r) => {
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }

  EnterBtn(event: any) {
    if (event.keyCode === 13) {
      this.currentActivePage = 1;
      this.getAllNonLogisticVehicle(this.currentActivePage);
    }
  }

  registerVehicle() {
    this.submitted = true;
    this.vehicle.rfidTagNumber = this.vehicle.rfidTagNumber.toUpperCase();
    this.vehicle.vrn = this.vehicle.vrn.toUpperCase();
    if (this.form.invalid) {
      return;
    }
    if (this.blaclistingChange == false) {
      // this.Reasonrequired();
      // if (this.ReasonRequired || this.DescriptionRequired || this.IncidentRequired) {
      //   return;
      // }
      if (this.Blacklistingreason && this.blaclistingIncidentDate && this.blaclistingDescription) {
        this.ReasonRequired = this.kemarClientService.commonValidation(this.vehicle.reason);
        this.DateRequired = this.kemarClientService.commonValidation(this.vehicle.incidentDateTime);
        this.DescriptionRequired = this.kemarClientService.commonValidation(this.vehicle.description);
        if (this.ReasonRequired || this.DateRequired || this.DescriptionRequired)
          return
      }
      else if (this.blaclistingDescription) {
        this.DescriptionRequired = this.kemarClientService.commonValidation(this.vehicle.description);
        if (this.DescriptionRequired)
          return
      }
    }
    this.vehicle.transporterId = 1;
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerVehicle, this.vehicle)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success')
          this.getAllNonLogisticVehicle(this.currentActivePage);
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          // console.log(r);

          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  acceptnumber(event: any, flag: boolean) {
    if (flag) {
      this.kemarClientService.keyPressNumbers(event);
    }
    else {
      this.kemarClientService.keyacceptnumberAndDot(event);
    }
  }

  open(content: any) {
    this.onReset();
    this.title = "Add Vehicle";
    this.vehicle.vehicleId = 0;
    this.vehicle.capacityInTons = ''
    this.vehicle.engineCapacityCC = ''
    this.vehicle.vrn = '';
    this.IsactiveShow = true,
      this.vehicle.isActive = true;
    this.vehicle.rfidTagNumber = ''
    this.vehicle.tareweightKg = '';
    this.vehicle.isBlackListed = false;
    this.resetbuttonShow = true,
      this.vehicle.vehicleType = 'NONLOGISTIC';
    this.vehicle.numberOfWheels = '';
    this.vehicle.permitTill = null;
    this.vehicle.vrDate = null;
    this.blaclistingChange = true;
    this.modalService.open(content, this.ngbModalOptions);
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  editNonLogisticVehicle(content: any, vehicle) {
    this.title = 'Update Vehicle';
    this.onReset();
    this.PreviewsBlackListed = vehicle.isBlackListed;
    this.vehicle.vehicleId = vehicle.vehicleId;
    this.vehicle.capacityInTons = vehicle.capacityInTons;
    this.vehicle.engineCapacityCC = vehicle.engineCapacityCC;
    this.vehicle.vrn = vehicle.vrn;
    this.vehicle.isActive = vehicle.isActive;
    this.IsactiveShow = false,
      this.vehicle.rfidTagNumber = vehicle.rfidTagNumber;
    this.vehicle.vehicleType = vehicle.vehicleType;
    this.vehicle.tareweightKg = vehicle.tareweightKg;
    this.vehicle.permitTill = vehicle.permitTill.split('T')[0];
    this.vehicle.numberOfWheels = vehicle.numberOfWheels;
    this.vehicle.vrDate = vehicle.vrDate.split('T')[0];
    this.vehicle.isBlackListed = vehicle.isBlackListed;
    this.vehicle.reason = '';
    this.vehicle.description = '';
    this.resetbuttonShow = false,
      this.blaclistingChange = this.blaclistingIncidentDate = this.blaclistingDescription = this.Blacklistingreason = false;
    this.vehicle.incidentDateTime = null;
    this.modalService.open(content, this.ngbModalOptions);
  }


  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage

  public closeModalPopup() {
    this.modalService.dismissAll();
  }
  ChangedBlacklist(value: any) {

    if (value === "true") {
      if (this.PreviewsBlackListed === true)
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = false;
      else
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = true;
    }
    else {
      if (this.PreviewsBlackListed === true) {
        this.blaclistingIncidentDate = this.Blacklistingreason = false
        this.blaclistingDescription = true;
      }
      else {
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = false;
      }
    }

  }
  Reasonrequired() {
    this.submitted = true;
    if (this.vehicle.reason == '') {
      this.ReasonRequired = true;
    }
    else {
      this.ReasonRequired = false;
    }
    if (this.vehicle.incidentDateTime == null) {
      this.IncidentRequired = true;
    } else {
      this.IncidentRequired = false;
    }
    if (this.vehicle.description == '') {
      this.DescriptionRequired = true;
    } else {
      this.DescriptionRequired = false;
    }
  }

  KeyPressMaxLength(event, flag: boolean) {

    let tes: any;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (flag) {
      tes = this.form.get('capacityInTons').value.toString();
    } else {
      tes = this.form.get('tareweightKg').value.toString();
    }
    if (charCode == 46 && tes.includes(".")) {
      return false;
    }
    if (charCode == 46) {
      return true;
    }
    else if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else if (!this.kemarClientService.commonValidation(tes)) {
      if (tes.length >= 1 && tes.includes('.')) {
        return true;
      }
      if (tes.length > 2) {
        if (charCode === 46) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
      else {
        return true
      }
    }
    else {
      return true;
    }
  }

  DropDownChnange(val: any) {

    if (val === "undefined") {
      this.IsEnableSearchBox = true;
      this.filter = '';
      this.getAllNonLogisticVehicle(1);
      return;
    }
    else if (!this.kemarClientService.commonValidation(val)) {
      this.IsEnableSearchBox = false
    }
    else {
      this.IsEnableSearchBox = true
    }
  }



  tonValidation(event: any) {
    let tempval = this.kemarClientService.keypressInTonsValidation(event, this.form.get('capacityInTons').value);
    if (tempval === 'false') {
      this.form.get('capacityInTons').setValue('');
    } else {
      this.form.get('capacityInTons').setValue(tempval);
    }
  }

  tareWeightValidation(event: any) {
    let tempval = this.kemarClientService.keypressInTonsValidation(event, this.form.get('tareweightKg').value);
    if (tempval === 'false') {
      this.form.get('tareweightKg').setValue('');
    } else {
      this.form.get('tareweightKg').setValue(tempval);
    }
  }
  closeModal() {
    this.blaclistingChange = this.blaclistingIncidentDate = this.blaclistingDescription = this.Blacklistingreason = false;
    this.modalService.dismissAll();
  }
  keyPressBlackListing = (val: any) => this.ReasonRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  keyPressIncidentDate = (val: any) => this.DateRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  keyPressDescition = (val: any) => this.DescriptionRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
}
const blacklistedDll: { key: string, value: string }[] = [
  { key: 'Yes', value: 'true' },
  { key: 'No', value: 'false' }
];
const vehicleTypeDll: { key: string, value: string }[] = [
  { key: 'LOGISTICS', value: 'logistics' },
  { key: 'NON LOGISTICS', value: 'Non logistics' }
];
