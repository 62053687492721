import { Component, OnInit } from '@angular/core';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { Observable, Subscription, timer } from 'rxjs';
import { faAnchor, faEdit, faMapPin, faObjectGroup, faPlus, faSort, faTimes, faHistory } from '@fortawesome/free-solid-svg-icons';
import { AlertPromise } from 'selenium-webdriver';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-ut-device-status-common',
  templateUrl: './ut-device-status-common.component.html',
  styleUrls: ['./ut-device-status-common.component.scss']
})
export class UTDeviceStatusCommonComponent implements OnInit {
  faEdit = faEdit;
  faTimes = faTimes;
  faAnchor = faAnchor;
  faPlus = faPlus;
  faSort = faSort;
  faHistory = faHistory;
  key: string = 'status'; //set default
  reverse: boolean = true;
  milestone: any;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  private subscription: Subscription;
  timer$: Observable<number> = timer(1000, 30000);
  filter = '';
  deviceStatusList : DeviceStatus[] = [];
  deviceStatusHistoryList : DeviceStatusHistory[];
  deviceStatusUnfilteredList : DeviceStatus[] = [];
  public userAccessData: UserScreenAccesData;
  deviceTypeMasterList: any;
  statusMasterList : any;
  deviceTypeSelect : number = 1;
  deviceStatusSelect : number  = 1 ;
  availableDeviceCount = 0;
  unavailableDeviceCount = 0;

  constructor(private spinner: NgxSpinnerService,private kemarClientService: KemarClientService
    , private router: Router,private modalService: NgbModal,
    private api:DataService) { }

    ngOnInit(): void {
      this.getAllBlankDeviceStatus();
      this.getDeviceTypeList();
      this.getStatusLit();
      this.subscription = this.timer$.subscribe(second => this.getAllDeviceStatus())

    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
    onDeviceTypeSelectionChange()
    {
      this.filterDeviceStatusList();

    }
    onStatusSelectionChange()
    {
      this.filterDeviceStatusList();

    }


    refreshSingleDevice(deviceStatus) {
      this.spinner.show();

      var testlist = this.deviceStatusList;
            var index = this.deviceStatusList.indexOf(this.deviceStatusList.filter(x=>x.deviceLocationMappingId == deviceStatus.deviceLocationMappingId)[0]);

            testlist[index] =  {
              deviceName : this.deviceStatusList[index].deviceName,
              location  : this.deviceStatusList[index].location ,
              locationType : this.deviceStatusList[index].locationType,
              type  : this.deviceStatusList[index].type,
              ip  : this.deviceStatusList[index].ip,
              antenna : this.deviceStatusList[index].antenna,
              transactionType : this.deviceStatusList[index].transactionType,
              status  : "",
              timestamp : this.deviceStatusList[index].timestamp,
              deviceLocationMappingId : this.deviceStatusList[index].deviceLocationMappingId,
              deviceStatusId : this.deviceStatusList[index].deviceStatusId
            };
            this.deviceStatusList = testlist;
            this.availableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Available").length;
            this.unavailableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Not Available").length;

      var query = { 'deviceLocationMappingId': deviceStatus.deviceLocationMappingId }
      this.kemarClientService.get<any>(null, ServiceUrl.getCommonDeviceStatus, query)
        .subscribe(
          response => {
            this.spinner.hide();
            var index = this.deviceStatusList.indexOf(this.deviceStatusList.filter(x=>x.deviceLocationMappingId == response.deviceLocationMappingId)[0]);
            var testlist = this.deviceStatusList;
            testlist[index] =  {
              deviceName : response.deviceName,
              location  : response.location ,
              locationType : response.locationType,
              type  : response.type,
              ip  : response.ip,
              antenna : response.antenna,
              transactionType : response.transactionType,
              status  : response.status,
              timestamp : response.timestamp,
              deviceLocationMappingId : deviceStatus.deviceLocationMappingId,
              deviceStatusId : deviceStatus.deviceStatusId
            };
            this.deviceStatusList = testlist;
            this.availableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Available").length;
            this.unavailableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Not Available").length;

          },
          r => {
            this.spinner.hide();
          });
    }

    getAllDeviceStatus()
    {
      var query = {}
      // this.spinner.show();

      this.kemarClientService.get<any>(null, ServiceUrl.getCommonAllDeviceStatus, query)
        .subscribe(
          response => {
            // this.spinner.hide();
            this.deviceStatusList = response;
            this.deviceStatusUnfilteredList = response;
            this.availableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Available").length;
            this.unavailableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Not Available").length;
            this.filterDeviceStatusList();
          },
          r => {
            // this.spinner.hide();
          });

    }

    displayDeviceStatusHistory(content, deviceStatus)
    {
      let ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };
      this.getDeviceStatusHistory(deviceStatus);
      this.modalService.open(content, ngbModalOptions);
    }

    getDeviceStatusHistory(statusId)
    {
      var query = {  devicestatusid : statusId}
      this.spinner.show();

      this.kemarClientService.get<any>(null, ServiceUrl.getCommonDeviceStatusHistory, query)
        .subscribe(
          response => {
            this.spinner.hide();
            this.deviceStatusHistoryList = response;
          },
          r => {
            this.spinner.hide();
          });
    }

    getAllBlankDeviceStatus()
    {
      var query = {}
      this.spinner.show();
      this.deviceStatusList = [];
      this.kemarClientService.get<any>(null, ServiceUrl.getCommonAllBlankDeviceStatus, query)
        .subscribe(
          response => {
            this.spinner.hide();

            response.forEach(element => {
              this.deviceStatusList.push(
                {
                  location  : element.location,
                  locationType : element.locationType,
                  type  : element.type,
                  ip  : element.ip,
                  antenna : element.antenna,
                  transactionType : element.transactionType,
                  status  : element.status,
                  timestamp : element.timestamp,
                  deviceLocationMappingId : element.deviceLocationMappingId,
                  deviceName : element.deviceName,
                  deviceStatusId : element.deviceStatusId

                }
              );
            });

            this.availableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Available").length;
            this.unavailableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Not Available").length;
          },
          r => {
            this.spinner.hide();
          });
    }

    getDeviceTypeList() {
      this.deviceTypeMasterList = [
        {id: 1 , deviceType : "All"},
        { id: 2 , deviceType : "LED" },
        { id: 3 , deviceType : "Controller" },
        { id: 4 , deviceType : "RFID" },
        { id: 5 , deviceType : "BA" },
        { id: 6 , deviceType : "FR" },
        { id: 7 , deviceType : "CCTV" },
      ];
    }

    getStatusLit() {
      this.statusMasterList = [
        { id: 1 , status : "All" },
        { id: 2 , status : "Connnected" },
        { id: 3 , status : "Disconnected" }

      ];
    }

    /****Filtering Logic */
    filterDeviceStatusList()
    {
      var selectedStatusString = "All";
          if ( this.deviceStatusSelect == 1)
      {
        this.deviceStatusList = this.deviceStatusUnfilteredList;
      }
      else
      {
        if (this.deviceStatusSelect == 2) selectedStatusString = "Available";
        if (this.deviceStatusSelect == 3) selectedStatusString = "Not Available";

        this.deviceStatusList = this.deviceStatusUnfilteredList.filter(x=>x.status ==selectedStatusString );
      }
      var selectedDeviceType = this.deviceTypeMasterList.filter(x=>x.id == this.deviceTypeSelect)[0].deviceType;

      if ( selectedDeviceType == "All" && selectedStatusString == "All")
      {
        this.deviceStatusList = this.deviceStatusUnfilteredList;
      }
      else if ( selectedDeviceType == "All")
      {
        this.deviceStatusList = this.deviceStatusUnfilteredList.filter(x=>x.status == selectedStatusString );
      }
      else if ( selectedStatusString == "All")
      {
        this.deviceStatusList = this.deviceStatusUnfilteredList.filter(x=>x.type ===selectedDeviceType );
      }
      else
      {
        this.deviceStatusList = this.deviceStatusUnfilteredList.filter(
          x=>x.type ==selectedDeviceType  &&  x.status == selectedStatusString );
      }

      this.availableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Available").length;
      this.unavailableDeviceCount = this.deviceStatusList.filter(x=>x.status=="Not Available").length;
    }

}

export class DeviceStatus {
  deviceLocationMappingId : number;
  deviceName : string;
  location : string;
  locationType : string;
  type : string;
  ip : string;
  antenna : number;
  transactionType : string;
  status : string;
  timestamp : Date;
  deviceStatusId : number;
}

export class DeviceStatusHistory {
  deviceStatusId : number;
  status : string;
  timestamp : Date;
}

