import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debug } from 'console';
import { ServiceUrl } from '../common/service-urls';
import { DataService } from '../service/data.service';
import { KemarClientService } from '../service/kemar-client.service';
import { TokenStorageService } from '../service/token-storage.service';
import { DatePipe } from '@angular/common';
import { UserScreenAccesData } from '../models/usermodel';

@Component({
  selector: 'app-ct-dashboard',
  templateUrl: './ct-dashboard.component.html',
  styleUrls: ['./ct-dashboard.component.scss'],
})
export class CtDashboardComponent implements OnInit {
  bChart: any;
  bchartLegend: any;
  transactionTime: number = 0;
  timeLapse: number = 0;
  assignedTicket: number = 0;
  unAssignedTicket: number = 0;
  deviceError: number = 0;
  userQueries: number = 0;
  todayClosedTicket: number = 0;
  weeklyClosedTicket: number = 0;
  monthlyClosedTicket: number = 0;
  plantGateTicketTickets: number = 0;
  weighbridgeTickets: number = 0;
  loadingStationTickets: number = 0;
  unloadingStationTickets: number = 0;
  timeLapseAtGateIn: number = 0;
  timeLapseAtGateOut: number = 0;
  timeLapseAtWeighment: number = 0;
  timeLapseAtLoading: number = 0;
  timeLapseAtUnloading: number = 0;
  transactionAtGateIn: number = 0;
  transactionAtGateOut: number = 0;
  transactionAtWeighment: number = 0;
  transactionAtLoading: number = 0;
  transactionAtUnloading: number = 0;
  userQueriesAtOnPlantGate: number = 0;
  userQueriesAtWeighment: number = 0;
  userQueriesAtLoading: number = 0;
  userQueriesAtUnloading: number = 0;
  plantGateOfTimeLapse: number = 0;
  plantGateOfTransaction: number = 0;
  plantGateOfUserQuesries: number = 0;
  weighbridgeOfTimeLapse: number = 0;
  weighbridgeOfTransaction: number = 0;
  weighbridgeOfUserQuesries: number = 0;
  loadingOfTimeLapse: number = 0;
  loadingOfTransaction: number = 0;
  loadingOfUserQuesries: number = 0;
  unloadingOfTimeLapse: number = 0;
  unloadingOfTransaction: number = 0;
  unloadingOfUserQuesries: number = 0;
  cctvDeviceNotAvilable: number = 0;
  rfidDeviceNotAvilable: number = 0;
  ledDeviceNotAvilable: number = 0;
  othersDeviceNotAvilable: number = 0;
  deviceErrorOnPlantgate: number = 0;
  deviceErrorOnWeighbridge: number = 0;
  deviceErrorOnLoading: number = 0;
  deviceErrorOnUnLoading: number = 0;
  dashboardBarGraphData: any[];
  alertTableData: any[];
  exceptionTabledata: any[];
  highPriorityTicketData: any[];
  currentActiveAlertPage = 1;
  userRole: string;
  userWiseMyIncident: any[];
  getCTUserDetails: any[];
  allActiveTickets: any[];
  public userAccessData: UserScreenAccesData;
  filter = '';
  key: string = 'name'; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  constructor(
    private kemarClientService: KemarClientService,
    private router: Router,
    private data: DataService,
    public datepipe: DatePipe
  ) {
    this.userRole = this.data.getUserDetail().roleName;
    // console.log(this.userRole)
    this.userAccessData =this.data.getUserScreenAccessMenu('CT Dashboard')
  }

  ngOnInit(): void {
    this.GetMyIncident();
    this.GetCTUserDetails();
    this.getDashboardCardData();
    this.getAllTickets();
    setInterval(() => {
      this.getDashboardCardData();
    }, 30000);
  }

  getDashboardCardData() {
    this.kemarClientService
      .get<any>(null, ServiceUrl.GetControlTowerDashboard)
      .subscribe(
        (response) => {
          if (response.dashboardTotalData.length > 0) {
            this.transactionTime =
              response.dashboardTotalData[0].transactionTime;
            this.timeLapse = response.dashboardTotalData[0].timeLapse;
            this.assignedTicket = response.dashboardTotalData[0].assignedTicket;
            this.unAssignedTicket =
              response.dashboardTotalData[0].unAssignedTicket;
            this.deviceError = response.dashboardTotalData[0].deviceError;
            this.userQueries = response.dashboardTotalData[0].userQueries;
            this.todayClosedTicket =
              response.dashboardTotalData[0].todayClosedTicket;
            this.weeklyClosedTicket =
              response.dashboardTotalData[0].weeklyClosedTicket;
            this.monthlyClosedTicket =
              response.dashboardTotalData[0].monthlyClosedTicket;
            this.plantGateTicketTickets =
              response.dashboardTotalData[0].plantGateTicketTickets;
            this.weighbridgeTickets =
              response.dashboardTotalData[0].weighbridgeTickets;
            this.loadingStationTickets =
              response.dashboardTotalData[0].loadingStationTickets;
            this.unloadingStationTickets =
              response.dashboardTotalData[0].unloadingStationTickets;
            this.timeLapseAtGateIn =
              response.dashboardTotalData[0].timeLapseAtGateIn;
            this.timeLapseAtGateOut =
              response.dashboardTotalData[0].timeLapseAtGateOut;
            this.timeLapseAtWeighment =
              response.dashboardTotalData[0].timeLapseAtWeighment;
            this.timeLapseAtLoading =
              response.dashboardTotalData[0].timeLapseAtLoading;
            this.timeLapseAtUnloading =
              response.dashboardTotalData[0].timeLapseAtUnloading;
            this.transactionAtGateIn =
              response.dashboardTotalData[0].transactionAtGateIn;
            this.transactionAtGateOut =
              response.dashboardTotalData[0].transactionAtGateOut;
            this.transactionAtWeighment =
              response.dashboardTotalData[0].transactionAtWeighment;
            this.transactionAtLoading =
              response.dashboardTotalData[0].transactionAtLoading;
            this.transactionAtUnloading =
              response.dashboardTotalData[0].transactionAtUnloading;
            this.userQueriesAtWeighment =
              response.dashboardTotalData[0].userQueriesAtWeighment;
            this.userQueriesAtLoading =
              response.dashboardTotalData[0].userQueriesAtLoading;
            this.userQueriesAtUnloading =
              response.dashboardTotalData[0].userQueriesAtUnloading;
            this.userQueriesAtOnPlantGate =
              response.dashboardTotalData[0].userQueriesAtOnPlantGate;
            this.plantGateOfTimeLapse =
              response.dashboardTotalData[0].plantGateOfTimeLapse;
            this.plantGateOfTransaction =
              response.dashboardTotalData[0].plantGateOfTransaction;
            this.plantGateOfUserQuesries =
              response.dashboardTotalData[0].plantGateOfUserQuesries;
            this.weighbridgeOfTimeLapse =
              response.dashboardTotalData[0].weighbridgeOfTimeLapse;
            this.weighbridgeOfTransaction =
              response.dashboardTotalData[0].weighbridgeOfTransaction;
            this.weighbridgeOfUserQuesries =
              response.dashboardTotalData[0].weighbridgeOfUserQuesries;
            this.loadingOfTimeLapse =
              response.dashboardTotalData[0].loadingOfTimeLapse;
            this.loadingOfTransaction =
              response.dashboardTotalData[0].loadingOfTransaction;
            this.unloadingOfUserQuesries =
              response.dashboardTotalData[0].unloadingOfUserQuesries;
            this.loadingOfUserQuesries =
              response.dashboardTotalData[0].loadingOfUserQuesries;
            this.unloadingOfTimeLapse =
              response.dashboardTotalData[0].unloadingOfTimeLapse;
            this.unloadingOfTransaction =
              response.dashboardTotalData[0].unloadingOfTransaction;
            this.cctvDeviceNotAvilable =
              response.dashboardTotalData[0].cctvDeviceNotAvilable;
            this.rfidDeviceNotAvilable =
              response.dashboardTotalData[0].rfidDeviceNotAvilable;
            this.ledDeviceNotAvilable =
              response.dashboardTotalData[0].ledDeviceNotAvilable;
            this.othersDeviceNotAvilable =
              response.dashboardTotalData[0].othersDeviceNotAvilable;
            this.deviceErrorOnPlantgate =
              response.dashboardTotalData[0].deviceErrorOnPlantgate;
            this.deviceErrorOnWeighbridge =
              response.dashboardTotalData[0].deviceErrorOnWeighbridge;
            this.deviceErrorOnLoading =
              response.dashboardTotalData[0].deviceErrorOnLoading;
            this.deviceErrorOnUnLoading =
              response.dashboardTotalData[0].deviceErrorOnUnLoading;
          }
          this.dashboardBarGraphData = response.dashboardBarGraphData;
          this.ResetGraphData();
          this.alertTableData = response.alerttableData;
          this.exceptionTabledata = response.ticketTableData;
          this.highPriorityTicketData = response.highPriorityTickets;
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
  }

  ResetGraphData() {
    if(this.userRole != 'CT Supervisor' && this.userRole != 'CT Operator'){}
    this.bChart = {
      labels: this.dashboardBarGraphData.map((proj) => proj.date),
      datasets: [
        {
          label: 'Generated Ticket',
          backgroundColor: '#FF8956',
          data: this.dashboardBarGraphData.map((proj) => proj.generatedTicket),
        },
        {
          label: 'Solved Ticket',
          backgroundColor: '#38B3B4',
          data: this.dashboardBarGraphData.map((proj) => proj.completedTicket),
        },
      ],
    };
    this.bchartLegend = {
      legend: {
        position: 'bottom',
      },
    };
  }

  CardForwardonAlertPage() {
    this.router.navigate(['/ct-alertticket']);
  }

  CardForwardondeviceStatusPage() {
    this.router.navigate(['/device-control']);
  }

  GetMyIncident() {
    if(this.userRole == 'CT Operator'){
    this.kemarClientService
      .get<any>(null, ServiceUrl.getMyIncidentTicket)
      .subscribe(
        (response) => {
          this.userWiseMyIncident = response;
          // console.log(response);
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
    }
  }

  GetCTUserDetails() {
    if(this.userRole == 'CT Supervisor'){
    this.kemarClientService
      .get<any>(null, ServiceUrl.GetCTUserDetails)
      .subscribe(
        (response) => {

          this.getCTUserDetails = response;
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
    }
  }

  getAllTickets() {
    if(this.userRole == 'CT Supervisor'){
    this.kemarClientService
      .get<any>(null, ServiceUrl.getActiveTickets)
      .subscribe(
        (response) => {
          this.allActiveTickets = response.ticket;
          // console.log(this.allActiveTickets)
        },
        (r) => {
          // console.log(r.error.error);
        }
      );
    }
  }
}
