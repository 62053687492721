<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="my-2"*ngIf="userAccessData[0].canRead ==true">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body custom-table">
                <div class="d-flex data-filter align-items-center my-2">
                    <div class="col-sm-4">
                        <h4 class="title-one mb-0"> Device Mapping </h4>
                    </div>
                    <div class="col-sm-8 text-end">
                        <div class="input-append">
                            <span class="material-icons-sharp icon-in">search</span>
                            <input class="form-control" type="search" name="search" placeholder="Search" [(ngModel)]="filter" (input)="filterchange($event.target.value)">
                        </div>
                        <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Device"
                        matTooltipClass="danger-tooltip" *ngIf="userAccessData[0].canCreate ==true">
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
                    </div>
                </div>
                <div class="table-list custom-table">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover mx-auto">
                            <thead class="table-head">
                                <tr>
                                    <th class="sr-no">Sr. No.</th>

                                    <th (click)="sort('name')">Location Name
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>

                                    <th (click)="sort('name')">Device Name
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th (click)="sort('name')">Device IP
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th (click)="sort('name')">Device Type
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th (click)="sort('name')">Antenna
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th (click)="sort('name')"> Lane
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th (click)="sort('name')"> Direction
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th (click)="sort('name')"> Remark
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th (click)="sort('name')" class="text-center"> Gpo
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th (click)="sort('name')"> Status
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                                    </th>
                                    <th class="action-key text-center"*ngIf="userAccessData[0].canUpdate ==true"> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let deviceLocationMapping of deviceLocationMappingMaster | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                                    <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                                    <td class="left">{{deviceLocationMapping.locationName}}</td>
                                    <td class="left">{{deviceLocationMapping.deviceName}}</td>
                                    <td class="left">{{deviceLocationMapping.deviceIP}}</td>
                                    <td class="left">{{deviceLocationMapping.deviceType}}</td>
                                    <td>
                                        <span *ngIf="deviceLocationMapping.deviceType=='RFID'">{{deviceLocationMapping.antenna}}</span>
                                    </td>
                                    <td>{{deviceLocationMapping.lane}}</td>
                                    <td>{{deviceLocationMapping.direction}}</td>
                                    <td class="left">{{deviceLocationMapping.remark}}</td>
                                    <td class="text-center">
                                        <span *ngIf="deviceLocationMapping.deviceType=='RFID'" (click)="Gpo(GPOModel,deviceLocationMapping)" matTooltip="Add Gpo"><button class="customBtn">Gpo</button></span>
                                    </td>
                                    <td>
                                        <span *ngIf="deviceLocationMapping.isActive  ==true ">Active</span>
                                        <span *ngIf="deviceLocationMapping.isActive  ==false ">Inactive</span>
                                    </td>
                                    <td class="text-center"*ngIf="userAccessData[0].canUpdate ==true">
                                        <span class="edit-btn-icon" type="button" matTooltip="Edit Device" matTooltipClass="danger-tooltip" (click)="editDeviceLocationMapping(edit, deviceLocationMapping)">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>


                <!-- <div class="pagination" *ngIf="deviceLocationMappingMaster.length > 0">
          <div class="col-md-6 pagination mr-auto ml-auto" aria-label="Page navigation example">
            <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
          </div>
        </div> -->
                <div class="pagination justify-content-end" *ngIf="deviceLocationMappingMaster.length > 0">
                    <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>


<ng-template #edit let-modal class="modal">
    <div class="modal-content custom-modal">
        <div class="modal-header">
            <h4 class="modal-title mb-0">{{title}}</h4>
            <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
        </div>
        <div class="modal-body">
            <form autocomplete="off" [formGroup]="form">
                <div class="form-group">

                    <div class="form-group row mb-0 mt-2">
                        <div class="col-sm-6">
                            <label for="deviceName">Device Name <sup>*</sup></label>
                            <input type="text" class="form-control" id="deviceName" name="deviceName" placeholder=" " formControlName="deviceName" [(ngModel)]="deviceLocationMapping.deviceName" [ngClass]="{ 'is-invalid': submitted && f.deviceName.errors }">
                            <div *ngIf="submitted && f.deviceName.errors" class="text-danger">
                                <div *ngIf="f.deviceName.errors.required">
                                    <small>Device Name is required.</small>
                                </div>
                                <div *ngIf="f.deviceName.errors.minlength">
                                    <sup>*</sup> Device Name must be at least 4 characters.
                                </div>
                                <div *ngIf="f.deviceName.errors.maxlength">
                                    <sup>*</sup>Device Name must not exceed 30 characters.
                                </div>
                                <div *ngIf="f.deviceName.hasError('whitespace')"></div>

                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label for="deviceIP">Device IP <sup>*</sup></label>
                            <input type="text" (keypress)="acceptnumber($event, true)" class="form-control" id="deviceIP" name="deviceIP" placeholder=" " formControlName="deviceIP" [(ngModel)]="deviceLocationMapping.deviceIP" [ngClass]="{ 'is-invalid': submitted && f.deviceIP.errors }">
                            <div *ngIf="submitted && f.deviceIP.errors" class="text-danger">
                                <div *ngIf="f.deviceIP.errors.required">
                                    <small>Device IP is required.</small>
                                </div>
                                <div *ngIf="f.deviceIP.errors.minlength">
                                    <sup>*</sup> Device IP must be at least 4 characters.
                                </div>
                                <div *ngIf="f.deviceIP.errors.maxlength">
                                    <sup>*</sup>Device IP must not exceed 30 characters.
                                </div>

                                <div *ngIf="f.deviceIP.hasError('whitespace')"></div>
                                <div *ngIf="f.deviceIP.errors.pattern">
                                    <sup>*</sup>Invalid IP Address format
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="form-group row mb-0 mt-2">
                        <div class="col-sm-6">

                            <label for="locationId">Select Location <sup>*</sup></label>
                            <select name="locationId" [(ngModel)]="deviceLocationMapping.locationId" class="form-control form-select" formControlName="locationId" [ngClass]="{ 'is-invalid': submitted && f.locationId.errors }">
                  <option hidden [ngValue]=0 style="font-weight: light;">--Choose Option--</option>
                  <option *ngFor="let location of locationMaster" [ngValue]="location.locationId">
                    {{location.locationName}}</option>
                </select>
                            <div *ngIf=" submitted && f.locationId.errors" class="text-danger">
                                <div *ngIf="f.locationId.errors?.pattern">
                                    <small> Location Name is required.</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label for="direction">Select Trip type <sup>*</sup></label>
                            <select name="direction" [(ngModel)]="deviceLocationMapping.direction" formControlName="direction" class="form-control form-select" autocomplete="" [ngClass]="{ 'is-invalid': submitted && f.direction.errors }">
                  <option Value='' hidden>--Choose Option--</option>
                  <option value='In'>In</option>
                  <option value='Out'>Out</option>
                </select>
                            <div *ngIf=" submitted && f.direction.errors" class="text-danger">
                                <div *ngIf="f.direction.errors.required">
                                    <small> Trip Type required.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-0 mt-2">
                        <div class="col-sm-6">
                            <label for="deviceType">Select Type <sup>*</sup></label>
                            <select name="deviceType" [(ngModel)]="deviceLocationMapping.deviceType" class="form-control form-select" formControlName="deviceType" [ngClass]="{ 'is-invalid': submitted && f.deviceType.errors }">
                                <option hidden Value='' disabled>--Choose Option--</option>

                                <option *ngFor="let dmapping of deviceTypeDDL" [ngValue]="dmapping.value">
                                    {{dmapping.key}}</option>
                </select>
                            <div *ngIf="submitted && f.deviceType.errors" (click)="toggleDisplay()" class="text-danger">
                                <div *ngIf="f.deviceType.errors.required">
                                    <small> Device Type is required.</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label for="lane">Lane Name <sup>*</sup></label>
                            <input type="text" class="form-control" id="lane" name="lane" placeholder=" " formControlName="lane" [(ngModel)]="deviceLocationMapping.lane" [ngClass]="{ 'is-invalid': submitted && f.lane.errors }">
                            <div *ngIf="submitted && f.lane.errors" class="text-danger">
                                <div *ngIf="f.lane.errors.required">
                                    <small>Lane Name is required.</small>
                                </div>
                                <div *ngIf="f.lane.errors.minlength">
                                    <sup>*</sup> Lane Name must be at least 4 characters.
                                </div>
                                <div *ngIf="f.lane.errors.maxlength">
                                    <sup>*</sup>Lane Name must not exceed 30 characters.
                                </div>
                                <div *ngIf="f.lane.hasError('whitespace')"></div>

                            </div>
                        </div>
                        <div *ngIf="deviceLocationMapping.deviceType == 'RFID'" class="col-sm-6">
                            <label for="antenna">Select Antenna </label>
                            <select name="antenna" [(ngModel)]="deviceLocationMapping.antenna" class="form-control form-select" formControlName="antenna" [ngClass]="{ 'is-invalid': submitted && f.antenna.errors }">
                                <option hidden Value='' disabled>--Choose Option--</option>

                                <option *ngFor="let dmapping of antennaDDL" [ngValue]="dmapping.value">
                                    {{dmapping.key}}</option>
                            </select>

                            <div *ngIf="submitted && f.antenna.errors" class="text-danger">
                                <div *ngIf="f.antenna.errors?.pattern">
                                    <small> Antenna is required.</small>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="deviceLocationMapping.deviceType == 'LED Display'" class="col-sm-6">
                            <label for="ledline">Select Ledline </label>
                            <select name="ledline" [(ngModel)]="deviceLocationMapping.ledline" class="form-control form-select" formControlName="ledline" [ngClass]="{ 'is-invalid': submitted && f.ledline.errors }">
                            <option hidden Value='' disabled>--Choose Option--</option>

                            <option *ngFor="let dmapping of LedLineDll" [ngValue]="dmapping.value">
                                {{dmapping.key}}</option>
                            </select>

                            <div *ngIf="submitted && f.ledline.errors" class="text-danger">
                                <div *ngIf="f.ledline.errors?.pattern">
                                    <small> LedLine is required.</small>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="deviceLocationMapping.deviceType == 'CCTV'" class="col-sm-12">
                            <label for="accessURL">Access URL </label>
                            <input type="text" class="form-control" id="accessURL" name="accessURL" placeholder=" " formControlName="accessURL"
                            [(ngModel)]="deviceLocationMapping.accessURL">
                        </div>
                            <div *ngIf="deviceLocationMapping.deviceType == 'CCTV'" class="col-sm-12">
                            <label for="snapcaptureURL">SnapCaptureURL </label>
                            <input type="text" class="form-control" id="snapcaptureURL"  placeholder=" " formControlName="snapcaptureURL"
                            [(ngModel)]="deviceLocationMapping.snapcaptureURL">
                        </div>

                        <div *ngIf="deviceLocationMapping.deviceType == 'RFID'" class="col-sm-12">
                          <label for="boomUpUrl">Boom Trigger URL </label>
                          <input type="text" class="form-control" id="boomUpUrl"  placeholder=" " formControlName="boomUpUrl"
                          [(ngModel)]="deviceLocationMapping.boomUpUrl">
                      </div>

                    </div>
                    <div class="form-group row mb-0 mt-2">
                        <div class="col-sm-12">
                            <label for="remark">Remark</label>
                            <textarea type="text" class="form-control" placeholder="" value=" " formControlName="remark" [(ngModel)]="deviceLocationMapping.remark"></textarea>

                        </div>

                    </div>


                    <!-- <div class="form-group mb-0 mt-3 select-radio" *ngIf="activebuttonShow">
              <label for="isActive" class="radio-btn mr-1">Active </label>
              <label class="form-check-inline">
                <input class="form-check-input" type="radio" name="isActive" [value]="true"
                  [(ngModel)]="deviceLocationMapping.isActive" formControlName="isActive">
              </label>
              <label for="isActive" class="radio-btn mr-1">Inactive</label>
              <label class="form-check-inline">
                <input class="form-check-input" type="radio" name="isActive" [value]="false"
                  [(ngModel)]="deviceLocationMapping.isActive" formControlName="isActive">
              </label>
            </div> -->

                    <div class="select-radio" *ngIf="activebuttonShow">
                        <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="deviceLocationMapping.isActive" formControlName="isActive">
                        <label for="active" class="radio-label">Active</label>

                        <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="deviceLocationMapping.isActive" formControlName="isActive">
                        <label for="inactive" class="radio-label">Inactive</label>
                    </div>

                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="modal-button btn btn-default" aria-label="Close" (click)="modal.dismiss('Cross click')">Close</button>
            <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow" (click)="onReset()">Reset</button>
            <button type="submit" class="modal-button btn btn-success" (click)="registerLocationDeviceMapping(deviceLocationMapping)">Save</button>
        </div>
    </div>
</ng-template>

<!-- Gpo Popup started -->
<ng-template #GPOModel let-modal class="modal">
    <div class="modal-content custom-modal">
        <div class="modal-header">
            <h4 class="modal-title mb-0">GPO - {{DeviceNameToShow}}</h4>
            <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="text-end mb-2">
                <button class="btn btn-primary go-btn" (click)="Add(GpoAdd)" matTooltip="Add Device">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
            </div>
            <div class="table-list custom-table">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover mx-auto">
                        <thead class="table-head">
                            <tr>
                                <th>Sr No.</th>
                                <th>GPO Number</th>
                                <th>Trigger Type</th>
                                <th>DelayInSec</th>
                                <th>Status</th>
                                <th>Retry Count</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let device of DeviceLocMapping | orderBy: key : reverse; let i = index">
                                <td>{{ i +1 }}</td>
                                <td>{{device.gpoNumber}}</td>
                                <td>{{device.triggerType}}</td>
                                <td>{{device.delayInSec}}</td>
                                <td>{{device.status}}</td>
                                <td>{{device.retryCount}}</td>
                                <td class="text-center">
                                    <span class="edit-btn-icon" type="button" matTooltip="Edit GPO" matTooltipClass="danger-tooltip" (click)="GpoEdit(GpoAdd,device)">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="modal-button btn btn-secondary" aria-label="Close" (click)="modal.dismiss('Cross click')">Close</button>
            <!-- <button type="submit" class="modal-button btn btn-success">Save</button> -->
        </div>
    </div>
</ng-template>
<!-- Gpo Popup end -->

<!-- Gpo Add update Popup started -->
<ng-template #GpoAdd let-modal class="modal">
    <div class="modal-content custom-modal">
        <div class="modal-header">
            <h4 class="modal-title mb-0">Add Gpo</h4>
            <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
        </div>
        <div class="modal-body">
            <form autocomplete="off" [formGroup]="GpoForm">
                <div class="form-group row mb-0 mt-2">
                    <div class="col-sm-6">
                        <label for="GPONumber">GPO Number <sup>*</sup></label>
                        <select name="GPONumber" class="form-control form-select" formControlName="GPONumber" [ngClass]="{ 'is-invalid': submitted && f2.GPONumber.errors }">
              <option  value='' [selected]=true>--Choose Option--</option>
              <option *ngFor="let gpo of GPONumber" [ngValue]="gpo.value" >
                {{gpo.key}}</option>
            </select>
                        <div *ngIf="submitted && f2.GPONumber.errors" class="text-danger">
                            <div *ngIf="f2.GPONumber.errors.required">
                                <small>GPO Number is required.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="TriggerType">Trigger Type<sup>*</sup></label>
                        <select name="TriggerType" class="form-control form-select" formControlName="TriggerType" [ngClass]="{ 'is-invalid': submitted && f2.TriggerType.errors }">
              <option value='' [selected]=true>--Choose Option--</option>
              <option *ngFor="let Trigger of TriggerType" [ngValue]="Trigger.value">
                {{Trigger.key}}</option>
            </select>
                        <div *ngIf="submitted && f2.TriggerType.errors" class="text-danger">
                            <div *ngIf="f2.TriggerType.errors.required">
                                <small>Trigger Type is required.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="Status">Status<sup>*</sup></label>
                        <select name="Status" class="form-control form-select" formControlName="Status" [ngClass]="{ 'is-invalid': submitted && f2.Status.errors }">
              <option value=''>--Choose Option--</option>
              <option value="Success">Success</option>
              <option value="Failure">Failure</option>
            </select>
                        <div *ngIf="submitted && f2.Status.errors" class="text-danger">
                            <div *ngIf="f2.Status.errors.required">
                                <small>Status is required.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="DelayInSec">Delay In Sec<sup>*</sup></label>
                        <input type="number" class="form-control" formControlName="DelayInSec" [ngClass]="{ 'is-invalid': submitted && f2.DelayInSec.errors }">
                        <div *ngIf="submitted && f2.DelayInSec.errors" class="text-danger">
                            <div *ngIf="f2.DelayInSec.errors.required">
                                <small>Retry Count is required.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                      <label for="RetryCount">Retry Count<sup>*</sup></label>
                      <input type="number" class="form-control" formControlName="RetryCount" [ngClass]="{ 'is-invalid': submitted && f2.RetryCount.errors }">
                      <div *ngIf="submitted && f2.RetryCount.errors" class="text-danger">
                          <div *ngIf="f2.RetryCount.errors.required">
                              <small>Delay In Sec is required.</small>
                          </div>
                      </div>
                  </div>

                    <div class="select-radio mt-2" *ngIf="activebuttonShow">
                        <input class="radio-input" type="radio" id="active" [value]="true" formControlName="IsActive">
                        <label for="active" class="radio-label">Active</label>

                        <input class="radio-input" id="inactive" type="radio" [value]="false" formControlName="IsActive">
                        <label for="inactive" class="radio-label">Inactive</label>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="modal-button btn btn-secondary" aria-label="Close" (click)="modal.dismiss('Cross click')">Close</button>

            <button type="submit" class="modal-button btn btn-success" (click)="SaveGPO()">Save</button>
        </div>
    </div>
</ng-template>
<!-- Gpo Add update Popup started -->
<app-footer></app-footer>
