<app-header></app-header>
<section class="my-2"  *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> User Registration </h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search" placeholder="Search" (input)="filterchange($event.target.value)" [(ngModel)]="filter">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(Registration)" matTooltip="Add User"
              matTooltipClass="danger-tooltip" *ngIf="userAccessData[0]?.canCreate == true">
              <fa-icon [icon]="faPlus"></fa-icon>
              <!-- <span class="material-icons-round">add</span> -->
            </button>
          </div>
        </div>

        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head text-dark">
                <tr>
                  <!-- <th class="sr-no">Sr. No.</th> -->
                  <th (click)="sort('name')">User Name
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">First Name
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')">Last Name
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                  </th>
                  <th (click)="sort('name')">Email
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                  </th>
                  <th (click)="sort('name')">Mobile No
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                  </th>
                  <th (click)="sort('name')">Role
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                  </th>
                  <th (click)="sort('name')">Status
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                  </th>
                  <th class="action-key text-center" (click)="sort('name')"*ngIf="userAccessData[0]?.canUpdate ==true">Action
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span>
                  </th>
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let user of userManager | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: rowCount, currentPage: p }; let i = index">
                  <input type="hidden" name="laneId" value={{user.Id}} />
                  <!-- <td>{{i +1}}</td> -->
                  <!-- <td>{{ (p - 1) * rowCount + i +1 }}</td> -->
                  <td>{{user.userName}}</td>
                  <td>{{user.firstName}}</td>
                  <td>{{user.lastName}}</td>
                  <td>{{user.email}}</td>
                  <td>{{user.mobileNumber}}</td>
                  <td>{{user.role}}</td>
                  <td> <span *ngIf="user.isActive  ==true ">Active</span>
                    <span *ngIf="user.isActive  ==false ">Inactive</span>
                  </td>
                  <td *ngIf="userAccessData[0]?.canUpdate ==true">
                    <div class="action-button text-center" >
                      <button mat-stroked-button color="primary">
                        <span class="edit-btn-icon" type="button" matTooltip="Change Password"
                          matTooltipClass="danger-tooltip" (click)="openChangePassword(changePassword,user)">
                          <!-- <span class="material-icons-sharp">vpn_key</span> -->
                          <fa-icon [icon]="faKey"></fa-icon>
                        </span>
                      </button>
                      <button mat-stroked-button color="primary">
                        <span class="edit-btn-icon" type="button" matTooltip="Edit User"
                          matTooltipClass="danger-tooltip" (click)="editUser(edit,user)">
                          <!-- <span class="material-icons-sharp">edit_note</span> -->
                          <fa-icon [icon]="faEdit"></fa-icon>
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
        </div>

      </div>
    </div>
  </div>
</section>


<ng-template #Registration let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">New User Registration</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <form autocomplete="off" [formGroup]="form" autocomplete="off">
            <div class="form-group">
              <div class="form-group ml-3">
                <input type="hidden" minlength="3" maxlength="30" class="form-control" name="userId"
                  value="{{userRegistration.userId}}" />
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="firstName">First Name<sup>*</sup></label>
                  <input type="text" formControlName="firstName" placeholder="" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" value=""
                    [(ngModel)]="userRegistration.firstName" />
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required"> <small>First Name is required</small>
                    </div>
                    <div *ngIf="f.firstName.errors.minlength">
                      <small>First Name must be at least 3 characters </small>
                    </div>
                    <div *ngIf="f.firstName.errors.maxlength">
                      <small>First name must not exceed 20 characters </small>
                    </div>
                    <div *ngIf="f.firstName.errors.pattern">
                      <small>First name should contain only a-z,A-Z.</small>
                    </div>
                    <!-- <div *ngIf="f.firstName.errors?.pattern">
                      <small> <sup>*</sup>First name should contain only a-z,A-Z.</small>
                    </div> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="lastName">Last Name<sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" " formControlName="lastName"
                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                    [(ngModel)]="userRegistration.lastName" />
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">
                      <small>Last Name is required</small>
                    </div>
                    <div *ngIf="f.lastName.errors.minlength">
                      <small>Last Name must be at least 3 characters</small>
                    </div>
                    <div *ngIf="f.lastName.errors.maxlength">
                      <small>Last name must not exceed 20 characters</small>
                    </div>
                    <div *ngIf="f.lastName.errors?.pattern">
                      <small>Last name should contain only a-z,A-Z.</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="userName">User Name<sup>*</sup></label>
                  <br>
                  <input type="text" formControlName="userName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" value=""
                    [(ngModel)]="userRegistration.userName" />
                  <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                    <div *ngIf="f.userName.errors.required">
                      <small>User Name is required</small>
                    </div>
                    <div *ngIf="f.userName.errors.minlength">
                      <small>User Name must be at least 5 characters</small>
                    </div>
                    <div *ngIf="f.userName.errors.maxlength">
                      <small>User Name must not exceed 20 characters</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">

                  <label for="email">Email<sup>*</sup></label>
                  <br>
                  <input type="text" formControlName="email" autocomplete="off" autocomplete="off" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" value=""
                    [(ngModel)]="userRegistration.email" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">
                      <small>Email is required</small>
                    </div>
                    <div *ngIf="f.email.errors.minlength">
                      <small>Email must be at least 5 characters</small>
                    </div>
                    <div *ngIf="f.email.errors.maxlength">
                      <small>Email must not exceed 20 characters</small>
                    </div>
                    <div *ngIf="f.email.errors?.email">
                      <small>Email address is invalid</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="password">Password<sup>*</sup></label>
                  <br>
                  <input type="password" formControlName="password" autocomplete="off" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" value=""
                    [(ngModel)]="userRegistration.password" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">
                      <small>Password is required</small>
                    </div>
                    <div *ngIf="f.password.errors.minlength">
                      <small>Password must be at least 5 characters</small>
                    </div>
                    <div *ngIf="f.password.errors.maxlength">
                      <small> Password must not exceed 15 characters</small>
                    </div>
                    <div *ngIf="f.password.errors?.pattern">
                      <small>Password must contain the following: upper case (A-Z), lower case (a-z),
                        number (0-9) and special character (e.g. !@#$%^&*)</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="confrmPass">Confirm Password<sup>*</sup></label>
                  <br>
                  <input type="password" formControlName="confirmPassword" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" value=""
                    [(ngModel)]="userRegistration.confirmPassword" />
                  <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">
                      <small>Confirm Password is required</small>
                    </div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">
                      <small>Passwords must match.</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <!-- <div class="col-sm-6">
                  <label for="mobileNumber">Mobile Number</label>
                  <br>
                  <input type="text" formControlName="mobileNumber" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }" value=""
                    [(ngModel)]="userRegistration.mobileNumber" required />
                  <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">

                    <div *ngIf="f.mobileNumber.errors.minlength">
                      <small>*Mobile Number must be at least 10 characters</small>
                    </div>
                    <div *ngIf="f.mobileNumber.errors.maxlength">
                      <small> *Mobile Number must not exceed 11 characters</small>
                    </div>
                    <div *ngIf="f.mobileNumber.errors?.pattern">
                      <small> <sup>*</sup>Mobile No should contain 0-9 </small>
                    </div>
                  </div>
                </div> -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">Mobile Number<sup>*</sup> </label>
                    <input required type="text" (keypress)="keyPress($event)" minlength=10 maxlength=10
                      formControlName="mobileNumber" class="form-control" placeholder=""
                      [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }"
                      [(ngModel)]="userRegistration.mobileNumber" required />
                    <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                      <div *ngIf="f.mobileNumber.errors.required">Phone number is required</div>
                      <div
                        *ngIf="f.mobileNumber.errors.pattern || f.mobileNumber.errors.maxlength || f.mobileNumber.errors.minlength">
                        Phone number must be at least 10 numbers</div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <label for="role">Select Role<sup>*</sup></label>
                  <br>
                  <select name="role" [(ngModel)]="userRegistration.roleId" class="form-control role-select form-select"
                    formControlName="role" [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                    <option value='0' hidden>--Choose Option--</option>
                    <option *ngFor="let role of roleMaster" [ngValue]="role.roleId">
                      {{role.roleName}} </option>
                  </select>
                  <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                    <div *ngIf="f.role.errors.required">
                      <small>Role is required.</small>
                    </div>
                    <div *ngIf="f.role.errors.pattern">
                      <small>Role is required.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary"
        (click)="registerUser(userRegistration)">Submit</button>
    </div>
  </div>
</ng-template>

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">Update User Data </h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <form autocomplete="off" [formGroup]="editUserForm">
            <div class="form-group">
              <div class="form-group">
                <input type="hidden" class="form-control" name="id" id="id" value="{{user.id}}" [(ngModel)]="user.id" />
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="firstName">First Name</label>
                  <input type="text" maxlength="13" class="form-control form-edit" id="firstName" name="firstName"
                    placeholder=" " [(ngModel)]="user.firstName" autocomplete="" formControlName="firstName"
                    [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.firstName.errors }">
                  <div *ngIf="editUserFormSubmitted && f1.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f1.firstName.errors.required">*First Name is required
                    </div>
                    <div *ngIf="f1.firstName.errors.minlength">
                      First Name must be at least 3 characters
                    </div>
                    <div *ngIf="f1.firstName.errors.maxlength">
                      First name must not exceed 12 characters
                    </div>
                    <div *ngIf="f1.firstName.errors?.pattern">
                    First name should contain only a-z,A-Z.
                    </div>
                  </div>
                </div>
                <div class=" form-group col-sm-6">
                  <label for="lastName">Last Name</label>
                  <input type="text" maxlength="13" class="form-control form-edit" id="lastName" name="lastName"
                    placeholder=" " [(ngModel)]="user.lastName" autocomplete="" formControlName="lastName"
                    [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.lastName.errors }">
                  <div *ngIf="editUserFormSubmitted && f1.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f1.lastName.errors.required">*Last Name is required
                    </div>
                    <div *ngIf="f1.lastName.errors.minlength">
                    Last Name must be at least 3 characters
                    </div>
                    <div *ngIf="f1.lastName.errors.maxlength">
                    Last name must not exceed 12 characters
                    </div>
                    <div *ngIf="f1.lastName.errors?.pattern">
                     Last name should contain only a-z,A-Z.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2">
                <div class="col-sm-6">
                  <label for="email">Email Adress</label>
                  <input type="text" class="form-control form-edit" id="Email" name="Email" placeholder=" "
                    [(ngModel)]="user.email" autocomplete="" formControlName="email"
                    [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.email.errors }">
                  <div *ngIf="editUserFormSubmitted && f1.email.errors" class="invalid-feedback">
                    <div *ngIf="f1.email.errors.required">*Email is required
                    </div>
                    <div *ngIf="f1.email.errors.minlength">
                      Email must be at least 3 characters
                    </div>
                    <div *ngIf="f1.email.errors.maxlength">
                      Email must not exceed 50 characters
                    </div>
                    <div *ngIf="f1.email.errors?.email">
                      <small class="text-danger">Email address is invalid</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="mobileNumber">Mobile Number </label>
                  <br>
                  <input type="text" (keypress)="keyPress($event)" minlength=10 maxlength=10
                    formControlName="mobileNumber" class="form-control" name="mobileNumber" id="mobileNumber"
                    [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.mobileNumber.errors }" value=""
                    [(ngModel)]="user.mobileNumber" />
                  <div *ngIf="editUserFormSubmitted && f1.mobileNumber.errors" class="invalid-feedback">
                    <div *ngIf="f1.mobileNumber.errors.required">
                      <small>Mobile Number is required</small>
                    </div>
                    <div *ngIf="f1.mobileNumber.errors.minlength">
                      <small>Mobile Number must be at least 10 characters</small>
                    </div>
                    <!--<div *ngIf="f1.mobileNumber.errors.maxlength">
                      <small> *Mobile Number must not exceed 11 characters</small>
                    </div>
                    <div *ngIf="f1.mobileNumber.errors?.pattern">
                      <small> <sup>*</sup>Mobile No should contain 0-9 </small>
                    </div> -->
                  </div>
                </div>
                  <div class="col-sm-6">
                    <label for="userName">User Name </label>
                    <br>
                    <input type="text" formControlName="userName" class="form-control" value=""
                      [(ngModel)]="user.userName" readonly />
                  </div>
                  <div class="col-sm-6">
                    <label for="roleId" class="form-group">Select Role</label>
                    <select name="roleId" [(ngModel)]="user.roleId" class="form-control form-select form-edit"
                      formControlName="roleId" [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.roleId.errors }">
                      <option value='0' hidden>--Choose Option--</option>
                      <option *ngFor="let role of roleMaster" [ngValue]="role.roleId">
                        {{role.roleName}}</option>
                    </select>
                    <div *ngIf="editUserFormSubmitted && f1.roleId.errors" class="invalid-feedback">
                      <div *ngIf="f1.roleId.errors.required">Role is required
                      </div>
                    </div>
                  </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-6">
                    <div class="select-radio">
                      <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="user.isActive"
                        formControlName="isActive">
                      <label for="active" class="radio-label">Active</label>
                      <input class="radio-input" id="inActive" type="radio" [value]="false" [(ngModel)]="user.isActive"
                        formControlName="isActive">
                      <label for="inActive" class="radio-label">Inactive</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="updateUser(user)">Save</button>
    </div>
  </div>

</ng-template>

<ng-template #changePassword let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">Change Password</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="changePasswordForm">
        <div class="form-group">
          <div class="form-group">
            <input type="hidden" class="form-control" name="id" id="id" value="{{model.userName}}"
              [(ngModel)]="model.userName" />
          </div>
          <!-- <div class="form-group mt-2">
                <div class="col-sm-12">
                  <label for="oldPassword">Old Password<sup>*</sup></label>
                  <br>
                  <input type="password" formControlName="oldPassword" autocomplete="off"
                  class="form-control form-edit"
                    [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.oldPassword.errors }" value=""
                    id="oldPassword" name="oldPassword"
                    [(ngModel)]="model.oldPassword" />
                  <div *ngIf="editUserFormSubmitted && f1.oldPassword.errors" class="invalid-feedback">
                    <div *ngIf="f1.oldPassword.errors.required">
                      <small>*Old Password is required</small>
                    </div>
                    <div *ngIf="f1.oldPassword.errors.minlength">
                      <small>*Old Password must be at least 5 characters</small>
                    </div>
                    <div *ngIf="f1.oldPassword.errors.maxlength">
                      <small> *Old Password must not exceed 15 characters</small>
                    </div>
                  </div>
                </div>

              </div>
                -->
          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-12">
              <label for="password">Password<sup>*</sup></label>
              <br>
              <input type="password" formControlName="password" autocomplete="off" class="form-control form-edit"
                [ngClass]="{ 'is-invalid': submitted && f2.password.errors }" value="" id="password" name="password"
                [(ngModel)]="model.password" />
              <div *ngIf="submitted && f2.password.errors" class="invalid-feedback">
                <div *ngIf="f2.password.errors.required">
                  <small>Password is required</small>
                </div>
                <div *ngIf="f2.password.errors.minlength">
                  <small>Password must be at least 5 characters</small>
                </div>
                <div *ngIf="f2.password.errors.maxlength">
                  <small>Password must not exceed 15 characters</small>
                </div>
                <div *ngIf="f2.password.errors?.pattern">
                  <small>Password must contain the following: upper case (A-Z), lower case (a-z), number
                    (0-9) and special character (e.g. !@#$%^&*)</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0 mt-2">
            <div class="col-sm-12">
              <label for="confirmPassword">Confirm Password<sup>*</sup></label>
              <br>
              <input type="password" formControlName="confirmPassword" autocomplete="off" class="form-control form-edit"
                [ngClass]="{ 'is-invalid': submitted && f2.confirmPassword.errors }" value="" id="confirmPassword"
                name="confirmPassword" [(ngModel)]="model.confirmPassword" />
              <div *ngIf="submitted && f2.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f2.confirmPassword.errors.required">
                  <small>Confirm Password is required</small>
                </div>
                <div *ngIf="f2.confirmPassword.errors.minlength">
                  <small>Confirm Password must be at least 5 characters</small>
                </div>
                <div *ngIf="f2.confirmPassword.errors.maxlength">
                  <small>Confirm Password must not exceed 15 characters</small>
                </div>
                <div *ngIf="f2.confirmPassword.errors.mustMatch">
                  <small>Passwords must match.</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="onSubmit()">Save</button>
    </div>
  </div>
</ng-template>
<app-footer></app-footer>
