<header id="header" class="header d-flex align-items-center">
  <div class="container">
      <nav class="navbar">
          <div class="container p-0">
              <a routerLink="/urgeTruck" class="logo"><img src="../../../assets/landing/img/logo.svg" alt=""></a>
              <form class="justify-content-end">
                  <a routerLink="/login" class="btn-red login"> <i
                          class="bi bi-person-fill"></i> LOGIN </a>
              </form>
          </div>
      </nav>

      <nav class="cd-vertical-nav">
          <ul>
              <li><a href="#intro" class="active"><span class="label">Intro</span></a></li>
              <li><a href="#featured"><span class="label">Featured</span></a></li>
              <li><a href="#whyUs"><span class="label">Why Us</span></a></li>
              <li><a href="#industries"><span class="label">Industries</span></a></li>
              <li><a href="#productDetails"><span class="label">Process</span></a></li>
              <li><a href="#insights"><span class="label">Insights</span></a></li>
          </ul>
      </nav>
  </div>
</header>
<!-- <button class="cd-nav-trigger cd-image-replace">Open navigation<span aria-hidden="true"></span></button> -->

<section id="intro" class="default d-flex align-items-center cd-section">
  <div class="container">
      <div class="row">
          <div class="col-md-6 align-self-center">
              <div class="">
                  <h2 class="color_2"><strong>UrgeTruck</strong> - Plant Automation Solution</h2>
              </div>
              <h1>Connecting the <br>logistic <strong>supply</strong> chain</h1>
              <h3>Increase Business Profitability! UrgeTruck automation solution is the most powerful tool for increasing business profitability by significantly lowering the existing operating costs in the Logistic Supply Chain operations. Furthermore, UrgeTruck solutions enable you to achieve Enhanced Customer Experience, Higher productivity, Reliability, Availability and Increased Performance.</h3>
              <div class="d-flex get-started">
                  <a href="#featured" class="btn-get-started">Get Started</a>
                  <!-- <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="glightbox btn-watch-video"><i class="bi bi-play-circle"></i><span>Watch Video</span></a> -->
              </div>
          </div>
          <div class="col-md-6">
              <img class="img-fluid img1" src="../../../assets/landing/img/img1.svg" alt="">
          </div>
      </div>
  </div>
</section>


<main id="main-content">
  <section id="featured" class="container cd-section">
    <div class="product-featured bg-white rounded">
        <!-- <h1 class="title1 text-center mb-5">Product Features</h1> -->
        <div class="section-header">
            <h2>Product Features</h2>
        </div>
        <div class="row">
            <div class="col-12 col-md-4 aos-init aos-animate">
                <div class="icon-box d-flex">
                    <div class="featured-icon mb-3">
                        <i class="svg-icon logistics"></i>
                    </div>
                    <div class="icon-text">
                        <h3 class="mb-1">Logistic Supply Chain Automation </h3>
                        <p>This system integrates Supply chain activities of Logistics process with various
                            stakeholders and associate vehicle/equipment allocation and movement.
                        </p>
                        <p hidden>The automated system allows improvement in Vehicle turnaround time and better
                            control over vehicle movement therby making more economic and efficient handling of
                            Logistic vehicles.
                            It also optimises resource utilization and cost saving for Transporter as well as for
                            the Business.The system gives realtime visibility of the operations to the stakeholders.
                        </p>
                        <!-- <button type="button" class="btn btn-get-details btn-sm">View Details</button> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 aos-init aos-animate">
                <div class="icon-box d-flex">
                    <div class="featured-icon mb-3">
                        <i class="svg-icon optimization"></i>
                    </div>
                    <div class="icon-text">
                        <h3 class="mb-1">TAT Optimization</h3>
                        <p>Improves truck Turn Around Time for Inbound Outbound trucks. Improves Safety at Gate and
                            Yard. Automate Weight capturing. Avoids any leakage of revenue.</p>
                        <p hidden>Improves Traceability and productivity. Mobile App helps onboard and updates
                            contractordriver, and supervisor.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 aos-init aos-animate">
                <div class="icon-box d-flex">
                    <div class="featured-icon mb-3">
                        <i class="svg-icon centralized"></i>
                    </div>
                    <div class="icon-text">
                        <h3 class="mb-1">Tower Control</h3>
                        <p>Centralized monitoring is a remote evaluation of accumulating data, performed in a timely
                            manner, supported by appropriately qualified and trained persons (e.g., data managers,
                            biostatisticians).</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 aos-init aos-animate">
                <div class="icon-box d-flex">
                    <div class="featured-icon mb-3">
                        <i class="svg-icon unmanned"></i>
                    </div>
                    <div class="icon-text">
                        <h3 class="mb-1">Unmanned/weighbridge Operation</h3>
                        <p>The Unmanned Weighbridge System is a system which allows 24x7 weighing operations without
                            the need of operator.</p>
                        <p>Main functionalities: Increase the weighment speed and large number of vehicles weighment
                            achieved. </p>
                        <p hidden> System provide Both Direction (In/Out) Weighing process facility. Automatic
                            identification of driver, truck, trailer. Driver can Confirm weighment after checking
                            the weight as displayed. Pre fix the weighbridge for vehicle weighment. No
                            Modification/Changes OR any Manual Mistakes is possible. Integrate more than one
                            weighbridge with the system. Use of RFID Smart Card and UHF RFID Tag. Cut operating cost
                            with a Fully Automated Vehicle Weighment Solution.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 aos-init aos-animate">
                <div class="icon-box d-flex">
                    <div class="featured-icon mb-3">
                        <i class="svg-icon reducing"></i>
                    </div>
                    <div class="icon-text">
                        <h3 class="mb-1">Reducing Parking & Gate Congestion</h3>
                        <p>Automated Parking & Gate automation system comprises of Parking booking by transporter,
                            Auto checking of Vehicle entry into Parking area, On-site Vehicle & Driver Registration
                            for Parking, Auto/Manual Job allocation to parked vehicles.
                             </p>
                        <p hidden>Public announcement and Job allocation Display system, etc.  This system associated with Plant Gate Entry automation system allows auto
                            verfication of Truck, Driver,cleaner and assigned job without any manual intervention
                            and minimal
                            stoppage at the Plant Gate thereby reducing the Gate Entry process execution time
                            siginificantly.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 aos-init aos-animate">
                <div class="icon-box d-flex">
                    <div class="featured-icon mb-3">
                        <i class="svg-icon equipment"></i>
                    </div>
                    <div class="icon-text">
                        <h3 class="mb-1">Vehicle / Equipment Tracking</h3>
                        <p>The System allows real time tracking of movement of Vehicles/equipment within and outside
                            the plant areas.This can be achieved by central monitoring of live movement of
                            vehicles/equipment from one milestone
                            to another milestone activated by Rfid Readers defined in the system. </p>
                        <p hidden> and also real time monitoring of vehicles/equipment outside the plant by using
                            GPS system. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="whyUs" class="why-us cd-section py-2">
  <div class="container aos-init aos-animate" data-aos="fade-up">

    <div class="section-header">
      <h2>Why UrgeTruck?</h2>
      <p hidden>UrgeTruck is a software solution which incorporates logistic process level automation, data collection and Control Centre concept. UrgeTruck solutions help in achieving the following improvements in any industrial logistic environment.</p>
    </div>

    <div class="d-flex justify-content-center align-items-center g-0 bg-white">

      <div class="col-xl-5 img-bg" style="background-image: url('../../../assets/landing/img/img6.jpg')"></div>

      <div class="col-xl-7 px-5 position-relative">

        <div id="carouselExampleDark" class="carousel carousel-dark slide whyUsCarousel" data-bs-ride="carousel" data-interval="false">
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="10000">
                    <h3 class="mb-3">Improve Truck Turn around Time</h3>
                    <p>The truck turnaround time is the average time taken up by a truck from its arrival at the facility to its departure. Low truck turnaround time is not only an indicator of efficiency in any industry but also an indicator of high-quality and cost-effective operations.</p>
                    <p>By deploying <strong>UrgeTruck</strong> automation/digitalization of the Gate entry process, weighing process, loading-unloading operations, and administrative process, we can reduce the truck turnaround time can be reduced to make the entire logistics chain more efficient.</p>
              </div>
              <div class="carousel-item" data-bs-interval="2000">
                    <h3 class="mb-3">Optimize Logistic Processes</h3>
                    <p>Optimizing logistical processes (inbound, outbound, internal movements) require companies to review each step in the supply chain process (such as sourcing, procurement, conversion) and coordination with channel partners (i.e., suppliers, intermediaries, third-party services, and customers) and discover opportunities for automation and heightened efficiency.</p>
                    <p><strong>UrgeTruck</strong> solution helps manage the logistic operations efficiently, and you have a higher chance of optimizing your system for storing, shipping, tracking, and delivering goods or services to other businesses or consumers. This process can, in turn, improve your revenue and bottom line.</p>
              </div>
              <div class="carousel-item">
                    <h3 class="mb-3">Improves Safety and Efficiency</h3>
                    <p>Companies looking to grow their business and increase throughput often turn to automation solutions. By incorporating UrgeTruck digital process automation into the supply chain, a business reduces the manual work required to perform certain business functions. UrgeTruck solution thereby speeds up workflows and makes your supply chain more efficient. Logistics process efficiency measures how effectively your business manages its logistics operations. It requires online vendors to closely track and optimize the movement of product shipments to ensure that the customers receive their orders quickly and cost-effectively.</p>
              </div>
              <div class="carousel-item">
                <h3 class="mb-3">Optimize workforce</h3>
               <p>Deployment of the UrgeTruck Logistic automation solution in repetitive work optimizes the workforce needed to perform the work. The redundant workforce can have chances for deployment in other value-added tasks, which will be more motivational and opens up new job opportunities. This automation solution will increase staff morale. One should always be working to increase productivity with the same workforce. That’s where workforce optimization comes in.</p>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
      </div>

    </div>

  </div>
</section>

<section id="industries" class="industries cd-section bg-grey">
  <div class="container-sm">
      <div class="section-header">
          <h2>Industries</h2>
      </div>
      <div class="row">
          <div class="flip col-lg-6 col-md-6 d-flex align-items-center">
              <div class="front img-steel">
                  <div class="icon-box front">
                      <!-- <img class="img-fluid" src="../assets/img/steel.jpg" alt=""> -->
                      <h2>Steel</h2>
                  </div>
              </div>
              <div class="icon-box back">
                  <!-- <div class="icon"><img class="img-fluid" src="../assets/img/icons/steel.svg" alt=""></div> -->
                  <h2>Steel</h2>
                  <p>Parking and Gate Entry/Exit automation, logistic process automation for Inbound, Outbound and
                      In plant movement.</p>
                  <a [routerLink]="['/steel']" class="btn-primary m-auto mt-2">View More.. </a>
              </div>
          </div>
          <div class="flip col-lg-6 col-md-6 d-flex align-items-stretch">
              <div class="front img-automotive">
                  <div class="icon-box front">
                      <h2>Automotive</h2>
                  </div>
              </div>
              <div class="icon-box back">
                  <h2>Automotive</h2>
                  <p>Gate Entry/Exit automation, Vehicle identification and Parking automation. </p>
                  <a routerLink="/automotive" class="btn-primary m-auto mt-2">View More.. </a>
              </div>
          </div>
          <div class="flip col-lg-6 col-md-6 d-flex align-items-stretch">
              <div class="front img-cement">
                  <div class="icon-box front">
                      <h2>CEMENT/MINING</h2>
                  </div>
              </div>
              <div class="icon-box back">
                  <h2>CEMENT/MINING</h2>
                  <p>Parking and Gate Entry/Exit automation, Loading and Dispatch, Raw material handling process
                      automation.</p>
                      <a routerLink="/cement" class="btn-primary m-auto mt-2">View More.. </a>
              </div>
          </div>
          <div class="flip col-lg-6 col-md-6 d-flex align-items-stretch">
              <div class="front img-port">
                  <div class="icon-box front">
                      <h2>Port</h2>
                  </div>
              </div>
              <div class="icon-box back">
                  <h2>Port</h2>
                  <p>RFID based Parking and Gate Entry/Exit system, OCR based container identification, Automatic
                      number plate recognition.</p>
                      <a routerLink="/port" class="btn-primary m-auto mt-2">View More.. </a>
              </div>
          </div>
      </div>
  </div>
</section>

<section id="productDetails" class="product-details bg-white cd-section">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <img src="../../../assets/landing/img/vendor-collaboration.svg" class="img2 img-fluid" alt="">
          </div>
          <div class="col-lg-6 px-5 content d-flex flex-column justify-content-center">
              <h3 class="mb-0">In-Plant Logistic Process <!-- <span>Automation System</span> --> </h3>
              <ul>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> Vendor Collaboration </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> Parking Management </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> Automated Gate</h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> Unmanned Weighbridge </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> In-Plant Movements </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> Smart Loading </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> Mobile App </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> Tower Control </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> E-Auction </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> Dashboard </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> MIS Reports </h5>
                  </li>
                  <li>
                      <i class="bi bi-arrow-right-circle-fill"></i>
                      <h5> API Integrations </h5>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</section>
<section id="insights" class="insights cd-section" style="background-color:#f3f5fa; padding: 3rem 0;">
  <div class="container">
      <div class="section-header">
          <h2>Solutions Insights</h2>
        </div>
      <div class="row">
          <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div class="accordion-list">
                  <ul>
                      <li> <a data-bs-toggle="collapse" class="collapse collapsed" data-bs-target="#accordion-list-1"
                              aria-expanded="false"><span>01</span> Identification Methods <i
                                  class="bi bi-chevron-down icon-show"></i><i
                                  class="bi bi-chevron-up icon-close"></i></a>
                          <div id="accordion-list-1" class="collapse show" data-bs-parent=".accordion-list" style="">
                              <ul class="list-unstyled inline-item">
                                  <li><i class="bi bi-check"></i> RFID </li>
                                  <li><i class="bi bi-check"></i> GPS </li>
                                  <li><i class="bi bi-check"></i> BLE </li>
                                  <li><i class="bi bi-check"></i> Barcode </li>
                                  <li><i class="bi bi-check"></i> ALPR </li>
                                  <li><i class="bi bi-check"></i> Access control </li>
                                  <li><i class="bi bi-check"></i> AI Camera </li>
                                  <li><i class="bi bi-check"></i> IOT sensors </li>
                              </ul>
                          </div>
                      </li>
                      <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class=""
                              aria-expanded="true"><span>02</span> Applicationh Features <i
                                  class="bi bi-chevron-down icon-show"></i><i
                                  class="bi bi-chevron-up icon-close"></i></a>
                          <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list" style="">
                              <ul class="list-unstyled inline-item">
                                  <li><i class="bi bi-check"></i> multi tier scalable architecture </li>
                                  <li><i class="bi bi-check"></i> Wide integration capabilities </li>
                                  <li><i class="bi bi-check"></i> Centralized and local data processing </li>
                              </ul>
                          </div>
                      </li>
                      <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" class="collapsed"
                              aria-expanded="false"><span>03</span> Action and  alert <i
                                  class="bi bi-chevron-down icon-show"></i><i
                                  class="bi bi-chevron-up icon-close"></i></a>
                          <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                              <ul class="list-unstyled inline-item">
                                  <li><i class="bi bi-check"></i> IoT Application </li>
                                  <li><i class="bi bi-check"></i> Data Analytics </li>
                                  <li><i class="bi bi-check"></i> Center Control Tower </li>
                              </ul>
                          </div>
                      </li>
                      <li> <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" class="collapsed"
                          aria-expanded="false"><span>04</span> Automation Infra <i
                              class="bi bi-chevron-down icon-show"></i><i
                              class="bi bi-chevron-up icon-close"></i></a>
                      <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                          <ul class="list-unstyled inline-item">
                              <li><i class="bi bi-check"></i> Identification device </li>
                              <li><i class="bi bi-check"></i> IoT Gateways </li>
                              <li><i class="bi bi-check"></i> Boom Gate </li>
                              <li><i class="bi bi-check"></i> Access Control </li>
                              <li><i class="bi bi-check"></i> Automation Sensors </li>
                              <li><i class="bi bi-check"></i> PA and display devices </li>
                              <li><i class="bi bi-check"></i> Network / Wi-Fi / LTE / 5G </li>
                          </ul>
                      </div>
                  </li>
                  </ul>
              </div>
          </div>
          <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img aos-init aos-animate"
              style="background-image: url(&quot;../../../assets/landing/img/img5.jpg&quot;); background-size: cover;" data-aos="zoom-in"
              data-aos-delay="150">&nbsp;</div>
              <!-- <div class="col-lg-5 align-items-center order-1">
                  <img class="img-fluid" src="../../../assets/img/img5.jpg" alt="">
              </div> -->
      </div>
  </div>
</section>
</main>


<app-lfooter></app-lfooter>

