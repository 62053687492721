import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { RFIDResponse } from 'src/app/models/rfidmodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-loadingsimulation',
  templateUrl: './loadingsimulation.component.html',
  styleUrls: ['./loadingsimulation.component.scss']
})
export class LoadingsimulationComponent implements OnInit {

  public userAccessData: UserScreenAccesData;
  deviceMaster: any;
  submitted = false;
  form: any;
  public RFIDSimulation: RFIDResponse = new RFIDResponse();

  constructor(private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder,

    private kemarClientService: KemarClientService, private modalService: NgbModal,
    private router: Router,
    private api: DataService) {
      this.form = this.formBuilder.group({

        deviceLocationId: [0],
        RFIDTagNo: [''],

        vrn: [
          '',

        ],
        weight: [''],
        weighBridgeId: [0],
        deviceId: [0],
        Status:[''],
        VRN:[''],
        Comment:[''],
        additionalComment:[''],
        milestoneTransactionCode: [''],
        milestoneCode: [''],
      });
      var route = this.router.url;
    this.userAccessData =this.api.getUserScreenAccessMenuOnRoutingURL(route);
    }

  ngOnInit(): void {
    this.getAllDevice();
  }

  getAllDevice() {
    debugger
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getYardLocationMasterData)
      .subscribe(
        response => {
          debugger
          this.deviceMaster = response.locations.slice().sort((a,b)=> a.deviceName.localeCompare(b.deviceName));
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
        });
  }

  PostRFIDTAG() {
    debugger
    this.form;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
  this.RFIDSimulation.requestId="s4526"
  if(this.RFIDSimulation?.deviceLocationId == undefined || this.RFIDSimulation?.deviceLocationId == 0){
    Swal.fire({
      icon: 'error',
      title: '',
      text: "Select Location"
    });
    return;
  }
  if(this.RFIDSimulation?.vrn == undefined || this.RFIDSimulation?.vrn == null){
    Swal.fire({
      icon: 'error',
      title: '',
      text: "Enter Vehicle Number"
    });
    return;
  }
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.postRFIDTAG, this.RFIDSimulation)
      .subscribe(
        res => {

          Swal.fire({
            icon: 'success',
            title: '',
            text: res.statusMessage
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        },
        r => {
          var errmsg = "";

          if (r.error.title != undefined && r.error.title != null){
              errmsg = r.error.title;
          }
          else
          {
            if (r.error.errorMessage != undefined && r.error.errorMessage != null)
            {
              errmsg = r.error.errorMessage;
            }
            else if (r.error.statusMessage != undefined && r.error.statusMessage != null)
            {
              errmsg = r.error.statusMessage;
            }
            else if ( r.error != null && r.error != null)
            {
              errmsg = r.error;
            }
          }

          Swal.fire({
            icon: 'error',
            title: '',
            text: errmsg
          }).then(function(){
            window.location.reload();
          })
          this.spinner.hide();
        });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
}
