import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";

import { KemarClientService } from '../service/kemar-client.service';
import { UserLogin, LoginResponse } from '../models/usermodel';
import { ServiceUrl } from '../common/service-urls';
import { DataService } from '../service/data.service';
import { TokenStorageService } from '../service/token-storage.service';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', './style.css']
})
export class LoginComponent implements OnInit {
  faUser = faUser
  faKey = faKey
  form: UntypedFormGroup;
  public userLogin: UserLogin = new UserLogin();
  public loginInvalid = false;
  private formSubmitAttempt = false;
  private returnUrl: string;
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  submitted = false;
  isPasswordVisible=false;
  rememberMe: boolean = false;

  private currentUserSubject: BehaviorSubject<UserLogin>;
  public currentUser: Observable<UserLogin>;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private kemarClientService: KemarClientService,
    private dataService: DataService,
    private tokenStorage: TokenStorageService,
    private formBuilder: UntypedFormBuilder
  ) {

    this.form = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20)
        ]
      ],

      password: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(250)
        ]
      ],
    });


    this.rememberMe = localStorage.getItem('rememberCurrentUser') == 'true' ? true : false;

    if ((this.rememberMe == true)) {
      this.currentUserSubject = new BehaviorSubject<UserLogin>(
        JSON.parse(localStorage.getItem('currentUser'))
      );
      this.userLogin.userName = this.currentUserSubject.value['userName'];
    this.userLogin.password = this.currentUserSubject.value['password'];
    }



  }

  ngOnInit() {
    window.sessionStorage.clear();
    this.rememberMe = localStorage.getItem('rememberCurrentUser') == 'true' ? true : false;
    if ((this.rememberMe == true)) {
      this.currentUserSubject = new BehaviorSubject<UserLogin>(
        JSON.parse(localStorage.getItem('currentUser')!)
      );
      this.form.controls['username'].setValue(this.currentUserSubject.value['userName']);
      this.form.controls['password'].setValue(this.currentUserSubject.value['password']);
    }
    if (this.tokenStorage.getToken(this.tokenStorage.getUserName())) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;

    }
    this.spinner.hide();

  }

  OnLoad() {
    this.userLogin.userName = '';
    this.userLogin.password = '';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  login() {
    if (this.form.invalid) {
      return;
    }
    this.submitted = true;

    this.kemarClientService.postPatch<LoginResponse>(ServiceUrl.authenticate, this.userLogin)
      .subscribe(response => {
        this.spinner.show();
        if (response.jwtToken !== null) {
          this.spinner.hide();
          this.tokenStorage.setUserName(response.userName);
          this.dataService.setUserDetail(response);
          this.dataService.setUserMenu(response.menuAccess);
          this.tokenStorage.saveToken(response.jwtToken, response.userName);
          this.tokenStorage.setRefreshToken(response.refreshToken);
          this.tokenStorage.saveUser(response);
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          let currentUserRole = this.dataService.getUserDetail().roleName;
          if (currentUserRole == "SuperAdmin" || currentUserRole == "Admin" || currentUserRole == "Management")
            this.router.navigate(['/dashboard']);
          else
            this.router.navigate(['/dashboard']);

            if(this.rememberMe){
              localStorage.setItem('currentUser', JSON.stringify(this.userLogin));
              localStorage.setItem('rememberCurrentUser', this.rememberMe ? 'true' : 'false');
            }
            else{
              localStorage.clear();
            }

        }

        this.submitted = false;

      },
        r => {

          let msg = "";
          // console.log(r);
          if ( r.error.message == undefined)
            {
              msg = "Please check connection with API. Http Error: " + r.message;
            }
          else
            {
              msg = r.error.message;
            }
          Swal.fire({
            icon: 'error',
            title: '',
            text: msg,
          })
          this.spinner.hide();
        this.submitted = false;

        });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
  }

}
