export class JobAllocationData {
    RequestId: string;
    JobAllocations: Array<jobAllocation> = new Array<jobAllocation>();
    JobAllocationForInplant:Array<JobAllocationForInplant> = new Array<JobAllocationForInplant>();
  }

  export class jobAllocation {
    ELVCode: string;
    VRN: string;
    DriverCode: string;
    BARequired: string = 'false';
    FRRequired: string = 'true';
    Priority: string = '1';
    JobAllocationCode: string; //Unique Value
    MovementType: string;
    IsActive: string = 'true';
    ShipmentNumber: string;
    Products: Array<Products> = new Array<Products>();
  }

  export class JobAllocationForInplant{
    materialtype: string
    documentnumber:string
    documentType: string
    tareweightfrequncy: string
    nooftrips:string
    vrn: string
    drivercode: string
    milestoneGroupCode: string
    isActive: string='true'
    Products: Array<Products> = new Array<Products>();
  }

  export class Products {
    MaterialType: string;
    ProductCode: string;
    BatchNo: string;
    Weight: string;
    Quantity: string;
    ShippingSequence: string;
    IsActive: string = 'true';
    ProductTransactionCode: string; //Unique Code
    MRP: number;
    ProductName: string;
    UOMValue: string;
    DocumentNumber: string;
    DocumentType: string;
    Materials: Array<Materials> = new Array<Materials>();
  }

  export class Materials {
    ItemName: string = 'YES';
    Quantity: string = '1';
    MeasurementUnit: String = '3';
  }
