export class DriverBlackListhistoryResponse {
    blackListHistoryId: number;
    id: number;
    name: string;
    type: string;
    dlNo:string;
    mobileNo:string;
    reason: string;
    description: string;
    isActive:boolean;
    incidentDate: Date;
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
}