export class MileStoneTrackingRequest {
    IsRejected:boolean;
    MilestoneActionTrackingId:number;
    Remark:string;
    vrn:string;
    locationCode:string;
    locationId:number;
    actionCode:string;
}

