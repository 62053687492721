<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter mt-3 mb-2 align-items-center">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> User Role Mapping </h4>
          </div>
          <div class="col-sm-8 text-end mx-100">
            <div class="input-append me-0">
              <span class="material-icons-sharp icon-in">
                search
              </span>
              <input class="form-control" type="search" name="search" placeholder="Search" [(ngModel)]="filter">
            </div>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="">Sr. No.</th>

                  <th (click)="sort('name')">Role Name
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')" class="col-8">Access Mapping
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')"> Screen Count
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')">Status
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th class="action-key col-1 text-center" >Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr [disabled]="true"
                  *ngFor="let accessMapping of allUserAccessMapping | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="roleName">{{accessMapping.roleName}}</td>
                  <td class="accessMapping" matTooltip="{{accessMapping.userAccess}}">
                    <span *ngIf="accessMapping.count == 0">
                      <small class="text-underline px-1">{{accessMapping.userAccess}}</small>
                    </span>
                    <span *ngIf="accessMapping.count != 0">
                      <span>
                        <small class="text-underline px-1">{{ (accessMapping.userAccess.length>100)?
                          (accessMapping.userAccess |
                          slice:0:151)+'..':(accessMapping.userAccess) }} </small>
                          <span>
                            <fa-icon [icon]="faInfo" class="faicon text-primary" style="cursor: pointer;"
                            (click)="getJsonDetail(jsonData,accessMapping)"></fa-icon>
                          </span>
                      </span>
                    </span>
                  </td>
                  <td>
                    {{accessMapping.count}}
                  </td>
                  <td>
                    <span *ngIf="accessMapping.status  == true ">Active</span>
                    <span *ngIf="accessMapping.status  == false ">Inactive</span>
                  </td>
                  <td class="text-center" >
                    <span *ngIf="accessMapping.status  == true" class="edit-btn-icon" [isDisabled]=true type="button"
                      matTooltip="Edit Role Mapping" matTooltipClass="danger-tooltip"
                      (click)="editModalPopup(edit, accessMapping.roleId,accessMapping) ">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
        </div>

      </div>
    </div>
  </div>
</section>



<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">User Role Access mapping - {{singleUserAccessMapping.roleName }}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body vrScrollbar">


      <div class="table-responsive custom-table">
        <table class="table parameter-table table-bordered mx-auto tbl-formating">
          <thead>
            <tr>
              <th class="screenName"> Menu Name</th>
              <th class="screenName"> Screen Name</th>
              <th>
                <span>Read Only </span> &nbsp; 
                <span> Select All
                  <input [(ngModel)]="ReadChecked" type="checkbox" aria-label="Select"
                    (click)="SelectReadOnly($event.target.checked)" />
                </span>
              </th>
              <th>
                <span> Create </span> &nbsp; 
                <span>Select All
                  <input [(ngModel)]="CreateChecked" type="checkbox" aria-label="Select"
                    (click)="SelectCreate($event.target.checked)" />
                </span>
              </th>
              <th>
                <span> Update </span> &nbsp;
                <span>Select All
                  <input [(ngModel)]="UpdateChecked" type="checkbox" aria-label="Select"
                    (click)="SelectUpdate($event.target.checked)" />
                </span>
              </th>
              <th>
                <span> Delete </span> &nbsp;
                <span>Select All
                  <input [(ngModel)]="DeleteChecked" type="checkbox" aria-label="Select"
                    (click)="SelectDelete($event.target.checked)" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="damage-report-table"
              *ngFor="let userAccess of singleUserAccessMapping.userAccessManagerResponse ; let i = index">
              <td class="screenName"> {{ userAccess.parentName }} </td>
              <td class="screenName"> {{ userAccess.screenName }} </td>
              <td class=" check-box mt-0 mb-0">
                <input type="checkbox" [checked]="userAccess.isActive" name="canRead" [(ngModel)]="userAccess.isActive"
                  (change)="change('read',userAccess)" />
              </td>
              <td class=" check-box mt-0 mb-0">
                <input type="checkbox" [checked]="userAccess.canCreate" name="canCreate"
                  [(ngModel)]="userAccess.canCreate" (change)="change('create')" />
              </td>
              <td class=" check-box mt-0 mb-0">
                <input type="checkbox" [checked]="userAccess.canUpdate" name="canUpdate"
                  [(ngModel)]="userAccess.canUpdate" (change)="change('update')" />
              </td>

              <td class=" check-box mt-0 mb-0">
                <input type="checkbox" [checked]="userAccess.canDeactivate" name="canDeactivate"
                  [(ngModel)]="userAccess.canDeactivate" (change)="change('remove')" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-default" aria-label="Select" (click)="ClearAll()">Clear
        All</button>
      <button type="button" class="modal-button btn btn-primary" aria-label="Select" (click)="SelectAll()">Select
        All</button>
      <button type="submit" class="modal-button btn btn-success" (click)="assignUserRoleAccessMapping()">Save</button>
    </div>
  </div>
</ng-template>
<ng-template #jsonData let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">More Details</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-list custom-table">
        <div class="table-responsive">
          <P>{{JsonDataToShow}}</P>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-footer></app-footer>