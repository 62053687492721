<app-header></app-header>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <!-- <div class="row data-filter align-items-center my-2"> -->
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Driver/Helper </h4>
          </div>

          <mat-tab-group animationDuration="10ms" ngbNav #nav="ngbNav"  mat-align-tabs="end">
            <mat-tab label="Driver" ngbNavItem="top" >
              <div class="row d-flex data-filter align-items-center my-2 m_d-flex  ">
                <div class="col-sm-12 text-end ">
                  <label class="me-2 ">
                    <select [(ngModel)]="driverSelectedColumn" (change)="DropDownChnange($event.target.value)" class="form-control dropdown-select width103">
                      <!-- <option value='undefined' selected>-- All --</option> -->
                      <option value="1" selected>Driver Name</option>
                      <option value="2"> Driver Code </option>
                      <option value="3">Driver Mobile</option>
                    </select>
                  </label>
                  <div class="input-append me-0">
                    <input class="form-control" type="search" name="search" placeholder="Search by Name"
                      style="padding-left:10px" (keydown)="EnterBtn($event)"
                      [(ngModel)]="filter">
                  </div>
                  <button class="btn btn-primary go-btn mx-2" (click)="SearchFilter()" matTooltip="Search"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faSearch"></fa-icon>
                  </button>
                  <button class="btn btn-success go-btn" (click)="getData(1 , 'true')" matTooltip="Download"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faDownload"></fa-icon>
                  </button>
                  <button class="btn btn-primary go-btn mx-2" (click)="open(edit, 'Driver')" matTooltip="Add Driver"
                    matTooltipClass="danger-tooltip"  *ngIf="userAccessData[0]?.canCreate == true">
                    <fa-icon [icon]="faPlus"></fa-icon>
                  </button>
                </div>

                <div class="table-list custom-table" *ngIf="userAccessData[0]?.canRead ==true">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover mx-auto">
                      <thead class="table-head">
                        <tr>
                          <th class="sr-no text-center">Sr No.</th>
                          <th (click)="sort('name')" class="">Name
                          </th>
                          <th (click)="sort('driverCode')" class="">Driver Code
                          </th>
                          <th (click)="sort('address')" class="">Address
                          </th>
                          <th (click)="sort('mobileNo')" class="">Mobile no.
                          </th>
                          <th (click)="sort('email')" class="">Email
                          </th>
                          <th (click)="sort('aadharNo')" class="">Aadhar No.
                          </th>
                          <th (click)="sort('dlNo')" class="">DL No.
                          </th>
                          <th (click)="sort('dlValidaty')" class="">DL Validity
                          </th>
                          <th (click)="sort('isBlackListed')" class="">BlackListed
                          </th>
                          <th (click)="sort('isActive')" class="">Status
                          </th>
                          <th (click)="sort('frStatus')" class="">FR's Status
                          </th>
                          <th class="action-key text-center" *ngIf="userAccessData[0].canUpdate ==true">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <input type="hidden" />
                        <tr
                          *ngFor="let driver of driverMaster|paginate: { id:'driverPagination', itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecords }; let i = index">
                          <td class="text-center">{{ i +1 + pageSize*(currentPage-1) }}</td>
                          <td class="left">{{driver.name}}</td>
                          <td class="left">{{driver.driverCode}}</td>
                          <td class="left">{{driver.address}}</td>
                          <td>{{driver.mobileNo | uppercase}}</td>
                          <td class="left">{{driver.email}}</td>
                          <td class="left">{{driver.aadharNo}}</td>
                          <td class="left">{{driver.dlNo}}</td>
                          <td>{{driver.dlValidaty | date: 'dd-MM-yyyy'}}</td>
                          <td>

                            <span *ngIf="driver.isBlackListed  ==true ">YES</span>
                            <span *ngIf="driver.isBlackListed  ==false ">NO</span>
                          </td>
                          <td>
                            <span *ngIf="driver.isActive  ==true ">Active</span>
                            <span *ngIf="driver.isActive  ==false ">Inactive</span>
                          </td>
                          <td class="left" class="text-center">
                            <span *ngIf="driver.frStatus  == 'Pending' || driver.frStatus  == '' || driver.frStatus  == null">
                              <mat-icon color="pending" class="material-icons">pending</mat-icon>
                            </span>
                            <span class="completed" *ngIf="driver.frStatus == 'Enrolled'">
                              <mat-icon color="completed" class="material-icons">check_circle</mat-icon>
                            </span>
                          </td>
                          <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
                            <span class="edit-btn-icon" type="button" matTooltip="Edit Driver" matTooltipClass="danger-tooltip"
                              (click)="editdriver(edit, driver, 'Driver') ">
                              <fa-icon [icon]="faEdit"></fa-icon>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    Item per page
                    <select (change)="OnPageSizeChange(pageSize = $event.target.value)" class="paginationcount">
                      <option value="15">15</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    <span> Total Records : {{totalRecords}} </span>
                  </div>
                  <div class="col-md-4 text-center">

                  </div>
                  <div class="col-md-4 text-end">
                    <div class="pagination justify-content-end">
                      <pagination-controls id="driverPagination" (pageChange)="GetDriverItemPerPage(currentPage = $event)">
                      </pagination-controls>
                    </div>
                  </div>
                </div>

              </div>
            </mat-tab>
            <!-- Helper Tab -->
            <mat-tab label="Helper" ngbNavItem="top" >
              <div class="row d-flex data-filter align-items-center my-2 m_d-flex  ">
                <div class="col-sm-12 text-end ">
                  <label class="me-2 ">
                    <select [(ngModel)]="helperSelectedColumn" (change)="DropDownChnangeForHelper($event.target.value)" class="form-control dropdown-select width103">
                      <!-- <option value='undefined' selected>-- All --</option> -->
                      <option value="1" selected>Helper Name</option>
                      <option value="2"> Helper Code </option>
                      <option value="3">Helper Mobile</option>
                    </select>
                  </label>
                  <div class="input-append me-0">
                    <input class="form-control" type="search" name="search" placeholder="Search by Name"
                      style="padding-left:10px" (keydown)="EnterBtnForHelper($event)"
                      [(ngModel)]="filterHelper">
                  </div>
                  <button class="btn btn-primary go-btn mx-2" (click)="SearchHelperFilter()" matTooltip="Search"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faSearch"></fa-icon>
                  </button>
                  <button class="btn btn-success go-btn" (click)="getHelperData(1 , 'true')" matTooltip="Download"
                    matTooltipClass="danger-tooltip">
                    <fa-icon [icon]="faDownload"></fa-icon>
                  </button>
                  <button class="btn btn-primary go-btn mx-2" (click)="open(edit, 'Helper')" matTooltip="Add Helper"
                    matTooltipClass="danger-tooltip"  *ngIf="userAccessData[0]?.canCreate == true">
                    <fa-icon [icon]="faPlus"></fa-icon>
                  </button>
                </div>

                <div class="table-list custom-table" >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover mx-auto">
                      <thead class="table-head">
                        <tr>
                          <th class="sr-no text-center">Sr No.</th>
                          <th (click)="sort('name')" class="">Name
                          </th>
                          <th (click)="sort('driverCode')" class="">Helper Code
                          </th>
                          <th (click)="sort('address')" class="">Address
                          </th>
                          <th (click)="sort('mobileNo')" class="">Mobile no.
                          </th>
                          <th (click)="sort('email')" class="">Email
                          </th>
                          <th (click)="sort('aadharNo')" class="">Aadhar No.
                          </th>
                          <!-- <th (click)="sort('dlNo')" class="">DL No.
                          </th>
                          <th (click)="sort('dlValidaty')" class="">DL Validity
                          </th> -->
                          <th (click)="sort('isBlackListed')" class="">BlackListed
                          </th>
                          <th (click)="sort('isActive')" class="">Status
                          </th>
                          <th (click)="sort('frStatus')" class="">FR
                          </th>

                          <th class="action-key text-center" *ngIf="userAccessData[0].canUpdate ==true">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <input type="hidden" />
                        <tr
                          *ngFor="let driver of helperMaster|paginate: { id:'helperPagination', itemsPerPage: pageSizeForHelper, currentPage: currentPageForHelper, totalItems: totalHelperRecords }; let i = index">
                          <td class="text-center">{{ i +1 + pageSizeForHelper*(currentPageForHelper-1) }}</td>
                          <td class="left">{{driver.name}}</td>
                          <td class="left">{{driver.driverCode}}</td>
                          <td class="left">{{driver.address}}</td>
                          <td>{{driver.mobileNo | uppercase}}</td>
                          <td class="left">{{driver.email}}</td>
                          <td class="left">{{driver.aadharNo}}</td>
                          <!-- <td class="left">{{driver.dlNo}}</td> -->
                          <!-- <td>{{driver.dlValidaty | date: 'dd-MM-yyyy'}}</td> -->
                          <td>

                            <span *ngIf="driver.isBlackListed  ==true ">YES</span>
                            <span *ngIf="driver.isBlackListed  ==false ">NO</span>
                          </td>
                          <td>
                            <span *ngIf="driver.isActive  ==true ">Active</span>
                            <span *ngIf="driver.isActive  ==false ">Inactive</span>
                          </td>
                          <td class="left" class="text-center">
                            <span *ngIf="driver.frStatus  == 'Pending' || driver.frStatus  == '' || driver.frStatus  == null">
                              <mat-icon color="pending" class="material-icons">pending</mat-icon>
                            </span>
                            <span class="completed" *ngIf="driver.frStatus == 'Enrolled'">
                              <mat-icon color="completed" class="material-icons">check_circle</mat-icon>
                            </span>
                          </td>
                          <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
                            <span class="edit-btn-icon" type="button" matTooltip="Edit Helper" matTooltipClass="danger-tooltip"
                              (click)="editdriver(edit, driver, 'Helper') ">
                              <fa-icon [icon]="faEdit"></fa-icon>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    Item per page
                    <select (change)="OnHelperPageSizeChange(pageSizeForHelper = $event.target.value)" class="paginationcount">
                      <option value="15">15</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    <span> Total Records : {{totalHelperRecords}} </span>
                  </div>
                  <div class="col-md-4 text-center">

                  </div>
                  <div class="col-md-4 text-end">
                    <div class="pagination justify-content-end">
                      <pagination-controls id="helperPagination" (pageChange)="GetHelperItemPerPage(currentPageForHelper = $event)">
                      </pagination-controls>
                    </div>
                  </div>
                </div>

              </div>
            </mat-tab>
          </mat-tab-group>


        <!-- </div> -->

      </div>
    </div>
  </div>
</section>


<app-footer></app-footer>
<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <form autocomplete="off" [formGroup]="form">
            <div class="form-group">
              <!-- <div class="form-group ml-3">
                        <input type="hidden" minlength="3" maxlength="30" class="form-control" name="mileStoneId"
                          value="{{milestone.mileStoneId}}" />
                      </div> -->

              <div class="form-group row mb-3 mt-0">
                <div class="col-sm-6">
                  <label for="code" *ngIf="editType == 'Driver'">Driver Code <sup>*</sup></label>
                  <label for="code" *ngIf="editType == 'Helper'">Helper Code <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.driverCode.errors }" formControlName="driverCode"
                    [(ngModel)]="driver.driverCode" />
                  <div *ngIf="submitted && f.driverCode.errors" class="text-danger">
                    <!-- <div *ngIf="f.driverCode.errors.required">
                      <small>Driver Code is required.</small>
                    </div> -->
                    <div *ngIf="f.driverCode.hasError('whitespace')">
                      <small>Driver Code is required.</small>
                    </div>
                    <div *ngIf="f.driverCode.errors.minlength">
                      <small>
                        Driver Code must be at least 2 characters.
                      </small>
                    </div>
                    <div *ngIf="f.driverCode.errors.maxlength">
                      <small>
                        Driver Code be at least 20 characters.
                      </small>
                    </div>

                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="rFIDTagNumber">Name <sup>*</sup></label>

                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name"
                    [(ngModel)]="driver.name" />
                  <div *ngIf="submitted && f.name.errors" class="text-danger">
                    <!-- <div *ngIf="f.name.errors.required">
                      <small>Name is required.</small>
                    </div> -->

                    <div *ngIf="f.name.hasError('whitespace')">
                      <small>Name is required.</small>
                    </div>
                   <div *ngIf="f.name.errors.minlength">
                      <small>
                       Name must be at least 4 characters.
                      </small>
                    </div>
                    <div *ngIf="f.name.errors.maxlength">
                      <small> Name must not exceed 30 characters.</small>
                    </div>
                    <div *ngIf="f.name.errors.pattern">
                      <small>Numbers Not Allowed</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="address">Address </label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address"
                    [(ngModel)]="driver.address" />
                  <div *ngIf="submitted && f.address.errors" class="text-danger">
                    <!-- <div *ngIf="f.address.errors.required">
                      <small>Address is required.</small>
                    </div> -->
                    <div *ngIf="f.name.hasError('whitespace')">
                      <small> Address is required.</small>
                    </div>


                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="mobileNo">Mobile No. </label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" " (keypress)="keyPress($event)"
                    minlength=10 maxlength=10 [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }"
                    formControlName="mobileNo" [(ngModel)]="driver.mobileNo" />
                  <div *ngIf="submitted && f.mobileNo.errors" class="text-danger">
                    <!-- <div *ngIf="f.mobileNo.errors.required">
                      <small>Mobile No. is required.</small>
                    </div> -->
                    <div *ngIf="f.name.hasError('whitespace')">
                      <small>Mobile No. is required.</small>
                    </div>

                    <!-- <div *ngIf="f.mobileNo.errors.minlength">
                      <small>
                        <sup>*</sup> Mobile number must be 10 digit.
                      </small>
                    </div> -->
                     <!-- <div *ngIf="f.mobileNo.errors.maxlength">
                      <small> <sup>*</sup>Mobile No. must not exceed 10 No.</small>
                    </div>  -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="email">Email </label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
                    [(ngModel)]="driver.email" (keyup)="emailValidationOnChange()" />
                  <div *ngIf="submitted && f.email.errors" class="text-danger">
                    <div *ngIf="f.email.errors.required">
                      <small>Email is required.</small>
                    </div>
                    <div *ngIf="f.email.errors?.email">
                      <small class="text-danger">Email address is invalid</small>
                    </div>

                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="aadharNo">Aadhar Number <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" (keypress)="keyPressnum($event)" placeholder="" value=" "
                    minlength="12" maxlength="12" [ngClass]="{ 'is-invalid': submitted && f.aadharNo.errors }"
                    formControlName="aadharNo" [(ngModel)]="driver.aadharNo" />
                  <div *ngIf="submitted && f.aadharNo.errors" class="text-danger">
                    <div *ngIf="f.aadharNo.errors.required">
                      <small>Aadhar No. is required.</small>
                    </div>
                    <div *ngIf="f.aadharNo.errors.minlength">
                      <small>
                      Aadhar No. must be 12 digit.
                      </small>
                    </div>
                    <!-- <div *ngIf="f.aadharNo.errors.maxlength">
                      <small> <sup>*</sup>Aadhar No. must not exceed 12 No.</small>
                    </div> -->
                    <div *ngIf="f.aadharNo.errors.pattern">
                      <small>Only Allowed Number</small>
                    </div>
                    <div *ngIf="f.aadharNo.hasError('whitespace')"></div>
                  </div>
                </div>
                <div class="col-sm-6" [hidden]="editType != 'Driver'">
                  <label for="dlNo">DL Number <sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.dlNo.errors }" formControlName="dlNo"
                    [(ngModel)]="driver.dlNo" />
                  <div *ngIf="submitted && f.dlNo.errors" class="text-danger">
                    <!-- <div *ngIf="f.dlNo.errors.required">
                      <small>DL number is required.</small>
                    </div> -->
                    <div *ngIf="f.dlNo.hasError('whitespace')">
                      <small>DL number is required.</small>
                    </div>
                    <div *ngIf="f.dlNo.errors.minlength">
                      <small>
                        DL number must be 15 digit.
                      </small>
                    </div>
                    <div *ngIf="f.dlNo.errors.maxlength">
                      <small>
                        DL number must be 16 digit.
                      </small>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6" [hidden]="editType != 'Driver'">
                  <label>DL Validity <sup>*</sup></label>
                  <input type="date" formControlName="dlValidaty" class="form-control" [min]="currentdate"
                    max="2060-04-30" [ngClass]="{ 'is-invalid': submitted && f.dlValidaty.errors } "
                    [(ngModel)]="driver.dlValidaty" />
                  <div *ngIf="submitted && f.dlValidaty.errors" class="invalid-feedback">
                    <div *ngIf="f.dlValidaty.errors.required">DL Validity is required.</div>
                    <div *ngIf="f.dlValidaty.errors.pattern">DL Validity must be a valid date in the format
                      YYYY-MM-DD</div>
                  </div>
                </div>

                <div class="select-radio" *ngIf="activebuttonShow">
                  <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="driver.isActive"
                    formControlName="isActive">
                  <label for="active" class="radio-label">Active</label>

                  <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="driver.isActive"
                    formControlName="isActive">
                  <label for="inactive" class="radio-label">Inactive</label>
                </div>

                <div class="col-sm-6" *ngIf="blacklistShow">
                  <label for="isBlackListed">BlackListed</label>
                  <select name="isBlackListed" (change)=" ChangedBlacklist($event.target.value)"
                    [(ngModel)]="driver.isBlackListed" class="form-control form-select" formControlName="isBlackListed"
                    [ngClass]="{ 'is-invalid': submitted && f.isBlackListed.errors }">
                    <option hidden [ngValue]='' disabled>--Choose Option--</option>
                    <option [value]="true">Yes</option>
                    <option [value]="false">No</option>
                  </select>
                </div>
              </div>
              <div class="form-group row mb-0 mt-2" [hidden]="blaclistingChange">
                <div class="col-sm-6" *ngIf="Blacklistingreason">
                  <label for="ReasonRequired">Reason<sup>*</sup></label>
                  <select name="ReasonRequired" [(ngModel)]="driver.reason" class="form-control form-select"
                    formControlName="reason" [ngClass]="{ 'is-invalid': submitted && ReasonRequired }"
                    (change)="keyPressBlackListing($event.target.value)">
                    <option hidden Value='' disabled>--Choose Option--</option>
                    <option *ngFor="let driver of BlacklistingReason" [ngValue]="driver.reason">
                      {{driver.reason}}</option>
                  </select>
                  <div *ngIf="submitted && ReasonRequired" class="text-danger">
                    <small> Reason is required.</small>
                  </div>
                </div>
                <!-- <div class="col-sm-6" *ngIf="blaclistingIncidentDate">
                  <label for="incidentDateTime">Incident Date <sup>*</sup></label>
                  <input type="date" class="form-control" placeholder="" value=" " name="incidentDateTime"
                    [ngClass]="{ 'is-invalid': submitted && IncidentRequired }" formControlName="incidentDateTime"
                    [(ngModel)]="driver.incidentDateTime" />
                  <input type="date" class="form-control" placeholder="" value=" " name="incidentDateRequired"
                      [ngClass]="{ 'is-invalid': submitted && incidentDateTime}" formControlName="incidentDateTime"
                      [(ngModel)]="incidentDateTime" />
                  <div *ngIf="submitted && IncidentRequired" class="invalid-feedback">
                    <div *ngIf="IncidentRequired">Date is required.
                    </div>
                  </div>
                </div> -->

                <div class="col-sm-6" *ngIf="blaclistingIncidentDate">
                  <label for="incidentDateTime">Incident Date <sup>*</sup></label>
                  <input type="date" class="form-control" placeholder="" (keypress)="KeyPressDate($event)"
                    (change)="keyPressIncidentDate($event.target.value)" value=" " name="incidentDateTime"
                    [ngClass]="{ 'is-invalid': submitted && DateRequired }" formControlName="incidentDateTime"
                    [(ngModel)]="driver.incidentDateTime" />
                  <div *ngIf="submitted && DateRequired" class="invalid-feedback">
                    <div *ngIf="DateRequired">Date required.
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 pb-2" *ngIf="blaclistingDescription">
                  <label for="DescriptionRequired">Description<sup>*</sup></label>
                  <textarea type="text" class="form-control" placeholder="" value=" " (keyup)="keyPressDescition($event.target.value.trim())"  name="DescriptionRequired"
                    [ngClass]="{ 'is-invalid': submitted && DescriptionRequired }" formControlName="description"
                    [(ngModel)]="driver.description" ></textarea>
                  <div *ngIf="submitted && DescriptionRequired" class="text-danger">
                    <div *ngIf="DescriptionRequired">
                      <small>Description is required.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="modal-button btn btn-default" aria-label="Close"
            (click)="modal.dismiss('Cross click')">Close</button>
          <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
            (click)="onReset()">Reset</button>
          <button type="submit" class="modal-button btn btn-success" (click)="registerDriver(driver)">Save</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
