<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="vehicleTransaction my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter mb-2 align-items-center"
          >
          <div class="col-md-3 col-12">
            <h4 class="title-one mb-0"> Support-Vehicle Trip </h4>
          </div>

          <div class="col-md-2 col-3" >
            <ng-select
              class="custom"
              [items]="vehicleList"
              [virtualScroll]="true"
              [loading]="loading"
              bindLabel="vrn"
              bindValue="vehicleTransactionId"
              placeholder="Select VRN"
              appendTo="body"
              [(ngModel)]="vtid"
              [searchFn]="customSearch"
              (change)="onSelected(vtid)"
              (scrollToEnd)="onScrollToEnd()">
              <ng-template ng-option-tmp let-vehicle="item">
                {{ vehicle.vrn }} 
              </ng-template> 
            </ng-select>
          </div>
          <div class="col-md-2 col-3">
            <input class="form-control" type="text" name="txn"
              value="{{ TranType}}" disabled>
          </div>
          <div class="col-md-2 col-3">
            <input class="form-control" type="text" name="txn"
              value="Current TXN: {{ vehicleTransactionCode }}" disabled>
          </div>
          <div class="col-md-2 col-3">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">
                person
              </span>
              <input class="form-control" type="text" name="name" value="{{ driverName }}" disabled>
            </div>
          </div>
          <div class="col-md col-3">
            <button class="btn btn-primary go-btn" (click)="getseachedvehicle()" matTooltip="Search" matTooltipClass="danger-tooltip">
              <fa-icon [icon]="faSearch"></fa-icon>
            </button>
        </div>
      </div>
       
        <div class="row justify-content-start my-4 ml-4">
          <div class="col-md-11 col-12">
            <div class="custom-table">
              <mat-accordion class="custom-accordion  vertical-line" [togglePosition]="'before'">
                <mat-expansion-panel (onclick)="selectedJobmilestone(jobmile.jobMilestoneId)"
                [disabled]="shouldDisablePanel(i)"
                  *ngFor="let jobmile of jobMilestones; let i=index; trackBy: trackByJobMilestoneId" hideToggle="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ jobmile.milestone }}
                      <span *ngIf="jobmile.milestone  != 'PHYSICAL CHECK' && jobmile.milestone  != 'INVOICING' && jobmile.milestone  != 'EXIT CLEARANCE'" >
                        - {{jobmile.milestioneEvent | titlecase}}
                      </span>
                    </mat-panel-title>
                    <mat-panel-description>
                      <div>
                        {{jobmile.locationName}}
                      </div>
                    </mat-panel-description>

                    <mat-panel-description>
                      <div>
                        {{jobmile.lane}}
                      </div>
                    </mat-panel-description>
                    <mat-panel-description>
                      <div class="align-center">
                        {{jobmile.milestoneCompletionTime | date: 'dd-MM-yyyy HH:mm'}}
                      </div>
                    </mat-panel-description>
                    <mat-panel-description>
                      <!-- <div class="align-center " *ngIf="jobmile.status == 'Completed'">
                        {{jobmile.timeTakenToCompleteMilestoneTransaction}}
                      </div> -->
                      <button
                        (click)="onCheckBoxChangeJobMilestone(jobmile, jobmile.milestoneActionsTracking, editJobMile,jobmile.locationId, jobmile.actualLane ,jobmile.status)"
                        [hidden]="disableBtn" class="btn editBTN btn-danger btn-sm align-items-center">
                          <!-- <mat-icon class="material-icons-outlined">task_alt</mat-icon> -->
                          <mat-icon class="editting">edit</mat-icon>
                      </button>
                    </mat-panel-description>
                    <div class="vt_status">
                      <span *ngIf="jobmile.status == 'Open'">
                        <mat-icon color="primary" style="transform: scaleX(-1);" class="material-icons blink">local_shipping</mat-icon>
                      </span>
                      <span *ngIf="jobmile.status == 'Pending'">
                        <mat-icon color="pending" class="material-icons">pending</mat-icon>
                      </span>
                      <span class="completed" *ngIf="jobmile.status == 'Completed'">
                        <mat-icon color="completed" class="material-icons">check_circle</mat-icon>
                      </span>
                      <span class="Cancelled" *ngIf="jobmile.status == 'Cancelled'">
                        <mat-icon color="danger">cancel</mat-icon>
                      </span>
                      <span class="completed" *ngIf="jobmile.status == 'Skip'">
                        <mat-icon color="primary" class="material-icons">fast_forward</mat-icon>
                      </span>
                      <div class="" *ngIf="jobmile.status == 'Completed'">
                        <!-- <mat-icon color="success" class="material-icons">check_circle</mat-icon> -->
                        <small class="reach-time">{{jobmile.timeTakenToCompleteMilestone}}</small>
                      </div>
                    </div>


                  </mat-expansion-panel-header>
                  <div class="running-status">
                  </div>
                  <div class="row my-3 justify-content-start align-items-center">
                    <div class="col-md-12">
                      <table class="table table-bordered sm-table text-center">
                        <thead>
                          <tr>
                            <th scope="col" class="sr-no">#</th>
                            <th scope="col">Activities</th>
                            <th scope="col">TimeStamp</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            <th scope="col" width="24%">Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let jobmileTracking of jobmile.milestoneActionsTracking   | orderBy:['sequence']  let i=index">
                            <td>{{i + 1}}</td>
                            <td> {{jobmileTracking.milestoneAction }}</td>
                            <td>
                              <span> {{jobmileTracking.completionTime | date: 'dd-MM-yyyy HH:mm:ss'}}</span>
                            </td>
                            <td>
                              <span *ngIf="jobmileTracking.status  == 'Open'">
                                <mat-icon color="success">check</mat-icon> Completed
                              </span>
                              <span *ngIf="jobmileTracking.status  == 'Completed'">
                                <strong class="text-success">Completed</strong>
                              </span>
                              <span *ngIf="jobmileTracking.status  == 'Failed'">
                                <mat-icon color="danger">cancel</mat-icon>
                              </span>
                              <span *ngIf="jobmileTracking.status  == 'ReOpen'">
                                <mat-icon color="#FFFFFF">cancel</mat-icon>
                              </span>
                            </td>
                            <td>
                                <button
                                  (click)="onChangecheckboxSupport(jobmileTracking.milestoneActionsTrackingId,jobmile.milestoneActionsTracking, edit,jobmileTracking.actionCode,jobmile.locationId, jobmile.actualLane ,jobmileTracking.status)"
                                  class="btn btn-danger btn-sm align-items-center">
                                  <mat-icon class="material-icons-outlined">task_alt</mat-icon>
                                </button>
                              <!-- <span
                                *ngIf="jobmileTracking.status  == 'Completed' && jobmile.status  == 'Completed' && jobmileTracking.actionCode == 'AX4NOTIFY' && (UserRole == 'SuperAdmin' || UserRole == 'Admin')">
                                <button
                                  (click)="retriggerAX4Notify(jobmile.jobMilestoneId,jobmile.milestoneCode )"
                                  class="btn btn-danger btn-sm align-items-center">
                                  <mat-icon class="material-icons-outlined">Retrigger</mat-icon>
                                </button>
                              </span> -->
                            </td>
                            <td style="max-width: 10vw;">
                              <p>{{jobmileTracking.remarks}}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div class="completed-timeline d-flex align-self-center justify-content-end my-2" *ngIf="milestoneCompleted">
                <mat-icon style="transform: scaleX(-1);" class="material-icons">local_shipping</mat-icon>
                  <span class="total-time">Trip TAT : {{TotalDiffTime}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="dissmiss()">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="form-group mb-2">

          <div class="form-group row mb-0 mt-2">
            <!-- <div class="col-sm-6">
              <label for="deviceLocationId"> Location <sup>*</sup></label>
              <select name="deviceLocationId" class="form-control form-select"
                formControlName="deviceLocationId" [ngClass]="{ 'is-invalid': submitted && f.deviceLocationId.errors }">
                <option hidden Value='' disabled>--Choose Option--</option>
                <option *ngFor="let device of deviceMaster" [ngValue]="device.deviceLocationId">
                  {{device.deviceName}}</option>
              </select>
              <div *ngIf="submitted && f.deviceLocationId.errors" class="invalid-feedback">
                <div *ngIf="f.deviceLocationId.errors.required">
                  <small>Location is required.</small>
                </div>
                <div *ngIf="f.deviceLocationId.errors.minlength">
                  <sup>*</sup> Location must be at least 4 characters.
                </div>
                <div *ngIf="f.deviceLocationId.errors.maxlength">
                  <sup>*</sup>Location must not exceed 30 characters.
                </div>
                <div *ngIf="f.deviceLocationId.hasError('whitespace')"></div>
              </div>
            </div> -->
            <div class="col-sm-12">
              <label for="actionValue"> Action Type <sup>*</sup></label>
              <select name="actionValue"
                class="form-control form-select" formControlName="actionValue"
                [ngClass]="{ 'is-invalid': submitted && f.actionValue.errors }">
                <option hidden value=''>--Choose Option--</option>
                <option *ngFor="let action of actionTypes" [ngValue]="action.value">
                  {{action.key}}</option>
              </select>
              <div *ngIf="submitted && f.actionValue.errors" class="invalid-feedback">
                <div *ngIf="f.actionValue.errors.required">
                  <small>Action is required.</small>
                </div>
                <div *ngIf="f.actionValue.hasError('whitespace')">
                  <small>Action is is required.</small>
                </div>
              </div>
            </div>

          </div>

          <div class="form-group row mb-0">
            <div class="col-sm-12">
              <label for="locationName">Remarks <sup>*</sup></label>
              <input type="text" class="form-control" id="locationName" name="locationName" placeholder=" "
                formControlName="Remarks"
                [ngClass]="{ 'is-invalid': submitted && f.Remarks.errors }">
              <div *ngIf="submitted && f.Remarks.errors" class="invalid-feedback">
                <div *ngIf="f.Remarks.errors.required">
                  <small>Remarks is required.</small>
                </div>
                <div *ngIf="f.Remarks.errors.minlength">
                  <sup>*</sup> Remarks Name must be at least 4 characters.
                </div>
                <div *ngIf="f.Remarks.errors.maxlength">
                  <sup>*</sup>Remarks Name must not exceed 30 characters.
                </div>
                <div *ngIf="f.Remarks.hasError('whitespace')"></div>
              </div>
            </div>
          </div>
          
        </div>

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="updateMilestoneTrackingSupport()">Save</button>
    </div>
  </div>
</ng-template>
<ng-template #editJobMile let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="dissmiss()">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="jobMileform">
        <div class="form-group mb-2">

          <div class="form-group row mb-0 mt-2">
            <!-- <div class="col-sm-6">
              <label for="deviceLocationId"> Location <sup>*</sup></label>
              <select name="deviceLocationId" class="form-control form-select"
                formControlName="deviceLocationId" [ngClass]="{ 'is-invalid': submitted && f.deviceLocationId.errors }">
                <option hidden Value='' disabled>--Choose Option--</option>
                <option *ngFor="let device of deviceMaster" [ngValue]="device.deviceLocationId">
                  {{device.deviceName}}</option>
              </select>
              <div *ngIf="submitted && f.deviceLocationId.errors" class="invalid-feedback">
                <div *ngIf="f.deviceLocationId.errors.required">
                  <small>Location is required.</small>
                </div>
                <div *ngIf="f.deviceLocationId.errors.minlength">
                  <sup>*</sup> Location must be at least 4 characters.
                </div>
                <div *ngIf="f.deviceLocationId.errors.maxlength">
                  <sup>*</sup>Location must not exceed 30 characters.
                </div>
                <div *ngIf="f.deviceLocationId.hasError('whitespace')"></div>
              </div>
            </div> -->
            <div class="col-sm-12">
              <label for="actionValue"> Action Type <sup>*</sup></label>
              <select name="actionValue"
                class="form-control form-select" formControlName="actionValue"
                [ngClass]="{ 'is-invalid': submitted && f2.actionValue.errors }">
                <option hidden value=''>--Choose Option--</option>
                <option *ngFor="let action of jobMileAction" [ngValue]="action.value">
                  {{action.key}}</option>
              </select>
              <div *ngIf="submitted && f2.actionValue.errors" class="invalid-feedback">
                <div *ngIf="f2.actionValue.errors.required">
                  <small>Action is required.</small>
                </div>
                <div *ngIf="f2.actionValue.hasError('whitespace')">
                  <small>Action is required.</small>
                </div>
              </div>
            </div>

          </div>

          <div class="form-group row mb-0">
            <div class="col-sm-12">
              <label for="locationName">Remarks <sup>*</sup></label>
              <input type="text" class="form-control" id="locationName" name="locationName" placeholder=" "
                formControlName="Remarks"
                [ngClass]="{ 'is-invalid': submitted && f2.Remarks.errors }">
              <div *ngIf="submitted && f2.Remarks.errors" class="invalid-feedback">
                <div *ngIf="f2.Remarks.errors.required">
                  <small>Remakrs is required.</small>
                </div>
                
                <div *ngIf="f2.Remarks.errors.maxlength">
                  <sup>*</sup>Remakrs Name must not exceed 30 characters.
                </div>
                <div *ngIf="f2.Remarks.hasError('whitespace')"></div>
              </div>
            </div>
          </div>
          
        </div>

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="dissmiss()">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="updateJobMilestoneSupport()">Save</button>
    </div>
  </div>
</ng-template>
<footer class="sticky-footer bg-black text-light mt-auto mb-0">
  <app-footer></app-footer>
</footer>