import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  UntypedFormControl,
} from '@angular/forms';
import { ViewChild } from '@angular/core';
import {
  faAnchor,
  faEdit,
  faMapPin,
  faObjectGroup,
  faPlus,
  faSort,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { MilestoneActionResponse } from 'src/app/models/milestoneActionModel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-milestone-action',
  templateUrl: './milestone-action.component.html',
  styleUrls: ['./milestone-action.component.scss'],
})
export class MilestoneActionComponent implements OnInit {
  milestoneaction: any;
  milestoneMaster: any[];
  milestoneActionCode: any;
  title: any;
  faEdit = faEdit;
  faTimes = faTimes;
  faAnchor = faAnchor;
  faPlus = faPlus;
  faSort = faSort;
  submitted = false;
  resetbuttonShow: boolean = true;
  form: UntypedFormGroup;
  public milestoneAction: MilestoneActionResponse =
    new MilestoneActionResponse();
    public userAccessData: UserScreenAccesData;

  //###################### Pagination and sorting section###################
  //sorting
  activebuttonHide: boolean;
  filter = '';
  pageSize1 = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  milestone: any;
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage1: number = 1;
  totalRecords1 = 0;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    private api:DataService
  ) {}

  ngOnInit(): void {
    this.Reset();
    this.getAllMilestoneAction();
    this.getAllMilestone();
  }

  Reset() {
    this.form = this.formBuilder.group({
      milestoneAction: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
          this.noWhitespaceValidator,
        ],
      ],
      actionCode: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
        ],
      ],
      isActive: [''],
    });
    this.userAccessData = this.api.getUserScreenAccessMenu('MILESTONE')
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }

  getAllMilestoneAction() {

    this.spinner.show();
    this.kemarClientService
      .get<MilestoneActionResponse>(null, ServiceUrl.getAllMilestoneAction)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.milestoneaction = response;
          this.totalRecords1 = this.milestoneaction.length;
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  getAllMilestone() {
    this.spinner.show();
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllMilestone)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.milestoneMaster = response;
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  registerMilestoneAction() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService
      .postPatch<any>(ServiceUrl.registerMilestoneAction, this.milestoneAction)
      .subscribe(
        (res) => {
          Swal.fire('', res.responseMessage, 'success');
          this.getAllMilestoneAction();
          this.closeModalPopup();
          this.spinner.hide();
        },
        (r) => {
          // console.log(r.err);
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          });
          this.spinner.hide();
        }
      );
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  open(content: any) {
    this.onReset();
    this.title = 'Add Milestone Action';
    this.milestoneAction.milestoneActionId = 0;
    this.milestoneAction.milestoneAction = '';
    this.milestoneAction.actionCode = '';
    this.activebuttonHide = false;
    this.resetbuttonShow = true;
    this.milestoneAction.isActive = true;
    this.modalService.open(content, { size: 'sm' });
    this.modalService.open(this.ngbModalOptions);
  }

  editMileStoneAction(content: any, milestoneAction) {

    this.onReset();
    this.title = 'Update Milestone Action';
    this.milestoneAction.milestoneAction = milestoneAction.milestoneAction;
    this.milestoneAction.actionCode = milestoneAction.actionCode;
    this.milestoneAction.isActive = milestoneAction.isActive;
    this.activebuttonHide = true;
    this.resetbuttonShow = false;
    this.milestoneAction.milestoneActionId = milestoneAction.milestoneActionId;
    this.modalService.open(content, { size: 'sm' });
    this.modalService.open(this.ngbModalOptions);
  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage1 = event;
  filterchange = (val: any) =>  (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage1 = 1 :  this.currentActivePage1 = this.tempcurrentActivePage

  public closeModalPopup() {
    this.modalService.dismissAll();
  }
}
