import { Component, OnInit, ViewChild } from '@angular/core';

import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { RoleMasterResponse }from 'src/app/models/rolemastermodel';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { faDoorClosed, faEdit, faPlus, faSort,faAnchor, faTimes, faTruck, faUser } from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UTStatusService } from 'src/app/service/statusdata.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
interface Movie {
    name: string;
    selected: boolean;
    disabled: boolean;
    movieCollection?: Movie[];
  }

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  public role: RoleMasterResponse = new RoleMasterResponse();
  public userAccessData: UserScreenAccesData;

  [x: string]: any;

  roleMaster: any;
  roleTobeAdded;
  faEdit = faEdit;
  faTimes = faTimes;
  faPlus = faPlus;
  faSort = faSort;
  faTruck = faTruck;
  faAnchor = faAnchor;
  form: UntypedFormGroup;
  submitted = false;
  accessArray : any;
  ShowIsActive: boolean = true;
  RoleGroups: any[];

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 15;
  key: string = 'name'; //set default
  reverse: boolean = false;
  IsEdit: boolean=false;
  headertitle : any;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  @ViewChild('closebutton') closebutton;
  //################################# END Pagination ###########################################

  constructor(private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private utStatusService: UTStatusService,   
    private api:DataService) {

      this.form = this.formBuilder.group({
        roleName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
        roleGroup: ['', [Validators.required]],
        isActive: ['', [Validators.required,]],

      });
      this.userAccessData =this.api.getUserScreenAccessMenu('ROLEMGMT');
    }

  ngOnInit(): void {
    this.getAllRoles();
    this.RoleGroups =  this.utStatusService.getRoleGroup();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
    this.role.isActive = true;
    this.form.controls['isActive'].setValue(true);
    this.form.controls['roleGroup'].setValue('');
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  getAllRoles() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllRoles)
      .subscribe(
        response => {
          this.roleMaster = response;
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  open(content: any) {
    this.headertitle = 'Add User Role'
    this.ShowIsActive = true;
    this.onReset();
    this.IsEdit=true;
    this.role.roleId = 0;
    this.role.roleName = '';
    this.role.isActive = true;
    this.role.roleGroup = '';
    this.modalService.open(content, { size: 'sm' });
    this.modalService.open(this.ngbModalOptions);
  }

  editRole(content: any, role) {
    this.headertitle = 'Update User Role'
    this.ShowIsActive = false;
    this.onReset();
    this.IsEdit=false;
    this.role.roleId = role.roleId;
    this.role.roleName = role.roleName;
    this.role.isActive = role.isActive;
    this.role.roleGroup = role.roleGroup;
    this.modalService.open(content, { size: 'sm' });
    this.modalService.open(this.ngbModalOptions);
  }

  registerRole() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerRole, this.role)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success')
          this.getAllRoles();
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }



  public closeModalPopup() {
    this.modalService.dismissAll();
  }

}
