import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faWeightHanging, faTimes, faAngleDoubleRight, faEye, faBan, faSearch, faCamera, faSort } from '@fortawesome/free-solid-svg-icons';
import { KemarClientService, QueryParams } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { Observable, Subscription, timer } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { map } from 'highcharts';
import { NumberValueAccessor } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../../service/data.service';

@Component({
  selector: 'app-ut-weighbridge-dashboard-common',
  templateUrl: './ut-weighbridge-dashboard-common.component.html',
  styleUrls: ['./ut-weighbridge-dashboard-common.component.scss']
})
export class UTWeighbridgeDashboardCommonComponent implements OnInit {
  @ViewChild('confirmationCancel') confirmationCancel: ElementRef;
  @ViewChild('pdfTable') pdfTable: ElementRef;
  AllWeightBridge: any[] = [];
  position: string[] = [];
  timer$: Observable<number> = timer(1000, 3000);
  private subscription: Subscription;
  faWeightHanging = faWeightHanging;
  faTimes = faTimes;;
  faEye = faEye;
  faCamera = faCamera;
  faSort = faSort;
  weighBridgeId: any;
  faAngleDoubleRight = faAngleDoubleRight;
  VehicleQueueList: any[] = [];
  weighmentName: any;
  tempLocationCode: void;
  tempLocationID: any;
  LocationCodeList: any[] = [];
  weighbridgeMaster: any[] = [];
  BulkSelected: any = true;
  SingleSelected: any = false;
  weighbridgeText: any;
  WeighBridgeIDList: any[] = [];
  IsDisableCheckbox: boolean = false;
  WeighBridgeDetail: any;
  singleWeighBridgeDetail: any[] = [];
  lastdraggedVehicle: any;
  lastdraggedWeighBridge: any;
  nextdraggedWeighBridge: any;
  vehicleSelectionType: any[];
  bulkDefault: boolean = true;
  faBan = faBan;
  WBName: any;
  IsShowWBVehicle: boolean = false;
  skipVehicleDiversion: boolean;
  obj: paramActionDetails = new paramActionDetails();
  faSearch = faSearch;
  searchValue: any
  submitted: boolean = false;
  CommonErrorMessage: string;
  SelectWeighbridgeID: WeighBridge[];
  AllWeighBridge: WeighBridge[];
  cameraDetailList: any;
  intervalMode: any;

  constructor(private kemarClientService: KemarClientService,
    private modalService: NgbModal, private router: Router, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer,
    private api:DataService) {
    this.vehicleSelectionType = [{ label: 'Bulk Mode', value: true }, { label: ' Single Mode', value: false }];
  }

  ngOnInit(): void {
    this.faWeightHanging = faWeightHanging;
    this.faTimes = faTimes;
    this.faEye = faEye;
    this.faBan = faBan;
    this.faSearch = faSearch;
    this.faAngleDoubleRight = faAngleDoubleRight;
    this.GetWeightCount();
    this.getAllWeighBridge();
    this.Timer();
    // this.intervalMode = setInterval(() => {
    //   this.GetWeightCount();
    // }, 500);
  }

  ngOnDestroy() {
    // clearInterval(this.intervalMode);
    this.subscription.unsubscribe();
  }

  @HostListener("show.bs.dropdown") stopTimer() {
    this.subscription.unsubscribe();
  }

  @HostListener("hide.bs.dropdown") startTimer() {
    this.Timer();
  }

  GetWeightCount(flag: number = 0) {

    let SelectedWeighBridge;
    let query;
    if (this.SelectWeighbridgeID != null || this.SelectWeighbridgeID != undefined) {
      SelectedWeighBridge = this.SelectWeighbridgeID.map(x => x.code);
      query = { "LocationID": SelectedWeighBridge.toString() }
    }
    this.kemarClientService.get<any>(null, ServiceUrl.getCommonAllweightStatus, query)
      .subscribe(
        response => {
              this.AllWeightBridge = response;

        },
        r => {

          console.log(r.error.error);
        });
  }

  OpenTransaction(value: any) {
    debugger;
    this.router.navigate(['/current-transaction', value]);
  }

  test(event: any) {
      this.GetWeightCount(1);
  }


  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };


  modelopen(content: any, LocationCode: any, WeighmentName: any) {
    this.Clearobj();
    this.weighmentName = WeighmentName;
    this.obj.LocationCode = LocationCode;
    this.obj.content = content;
    this.getVehicleQueueList(5);
  }

  dragStart(vehicle: any, weighbridge: any) {
    //
    this.lastdraggedVehicle = vehicle;
    this.lastdraggedWeighBridge = weighbridge;
    console.log(this.lastdraggedVehicle)
    this.subscription.unsubscribe();
  }

  Drop(content: any, weighbridge: any) {

    if (this.lastdraggedWeighBridge.weighbridgeId === weighbridge.weighbridgeId) {
      Swal.fire('', 'Please Select any other Weighbridge', 'info')
      return
    }
    if (weighbridge.status == "Under Maintenance") {
      Swal.fire('', 'This WeighBridge is in Under Maintenance', 'info')
      return
    }
    this.nextdraggedWeighBridge = null;
    this.nextdraggedWeighBridge = weighbridge;
    this.modalService.open(content, this.ngbModalOptions);
  }
  getVehicleQueueList(Callednumber: number) {
    debugger;
    let query: any;
    if (Callednumber === 4) {
      query = { 'locationCode': this.obj.LocationCode, 'flag': true };
    }
    else {
      query = { 'locationCode': this.obj.LocationCode };
    }
    this.kemarClientService.get<any>(null, ServiceUrl.getCommonVehicleQueue, query)
      .subscribe(
        res => {
          this.VehicleQueueList = res;
          switch (Callednumber) {
            case 1:
              this.ExcecuteAfterSaveData();
              break;
            case 2:
              this.ExcecuteAfterSaveData();
              break;
            case 3:
              if (this.VehicleQueueList.length > 0) {
                this.modalService.open(this.obj.content, this.ngbModalOptions);
                this.getAllCustomWeighBridge();
              } else {
                Swal.fire('', 'No vehicles to divert', 'info')
              }
              this.spinner.hide();
              break;
            case 4:
              if (this.VehicleQueueList.length <= 0) {
                this.weightBridgedown();
                return;
              } else {
                this.getAllCustomWeighBridge();
                this.modalService.open(this.obj.content, this.ngbModalOptions);
              }
              this.spinner.hide();
              break;
            case 5:
              this.modalService.open(this.obj.content, this.ngbModalOptions);
              break;
            default:
              break;
          }
        },
        r => {
          console.log(r.error.error);
        });
  }

  private ExcecuteAfterSaveData() {
    if (this.VehicleQueueList.length > 0) {
      Swal.fire('', this.obj.res, 'success');
    }
    else {
      if (this.skipVehicleDiversion) {
        this.weightBridgedown();
      }
      this.modalService.dismissAll();
      Swal.fire('', this.obj.res, 'success');
      this.setboolean(true);
    }
    this.submitted = false;
    this.spinner.hide();
  }

  getBackgroundColor(status: any) {
    if (status == "Weighing")
      return 'card-inprocess';
    else if (status == "Error")
      return 'card-error';
    else if (status == 'Under Maintenance') {
      return 'card-maintained';
    }
    else if (status == "Completed")
      return 'card-completed';
    else if (status == "Ready")
      return 'card-ready';
  }

  getbtnColor(status: any) {
    if (status == "Weighing")
      return 'inprocessBtn';
    else if (status == "Error")
      return 'errorBtn';
    else if (status == 'Under Maintenance')
      return 'underMaintenanceBtn';
    else if (status == "Completed")
      return 'completedBtn';
    else if (status == "Ready")
      return 'readyBtn';
    else
      return 'errorConfig';
  }

  getfontColor(status: any) {
    if (status == "Weighing")
      return 'white';
    else
      return 'White';
  }

  SetWeighBridgeData(weighBridgeStatus: any) {
    if (weighBridgeStatus === 'Under Maintenance') {
      this.weighbridgeText = 'Enable';
    } else {
      this.weighbridgeText = 'Disable';
    }
  }

  viewweighbridgetransaction(WeighBridgeID: any) {
    this.router.navigateByUrl(`/weighbridge-transaction/${WeighBridgeID}`,)
    window.open()
  }

  ViewDisabledWeightBridge(confirmation: any, currenWBDetail: any) {
    this.subscription.unsubscribe();
    this.bulkDefault = true;
    this.modalService.open(confirmation, this.ngbModalOptions);
    this.tempLocationCode = this.tempLocationID = null;
    this.tempLocationCode = currenWBDetail.locationCode;
    this.tempLocationID = currenWBDetail.locationID
    this.WBName = currenWBDetail.weightmentName;
  }

  ConfirmationYes(content: any) {
    this.spinner.show();
    if (this.weighbridgeText == "Enable") {
      this.weightBridgedown();
      return
    }
    this.IsShowWBVehicle = true
    this.Clearobj();
    this.obj.LocationCode = this.tempLocationCode;
    this.obj.content = content;
    this.getVehicleQueueList(4);
  }
  weightBridgedown() {
    let query = { 'locationId': this.tempLocationID };
    this.kemarClientService.get<any>(null, ServiceUrl.CommonweightBridgedown, query)
      .subscribe(
        response => {
          Swal.fire('Success', response.responseMessage, 'success');
          this.skipVehicleDiversion = false;
          this.modalService.dismissAll();

          this.spinner.hide();
        },
        r => {
          console.log(r.error.error);
        });
  }
  SelectAllVehicle(event: any) {
    if (event.checked && this.LocationCodeList.length > 0)
      this.LocationCodeList = [];
    this.VehicleQueueList.forEach(element => {
      if (event.checked) {
        element.isChecked = true;
        let query = { 'VehicleTrasactionID': element.vehicleTransactionId, "weighmentType": element.weighmentType, "transactionStatus": element.status }
        this.LocationCodeList.push(query);
      } else {
        element.isChecked = false;
        this.LocationCodeList = [];
      }
    });
  }

  selectSingleVehicle(event: any, vehcileDetail: any) {
    if (event.checked) {
      vehcileDetail.isDisabled = false;
      let query = { 'VehicleTrasactionID': vehcileDetail.vehicleTransactionId, "WeighmentType": vehcileDetail.weighmentType, "transactionStatus": vehcileDetail.status, "WeighBridgeDetail": null }
      this.LocationCodeList.push(query);
    } else {
      vehcileDetail.isDisabled = true;
      var index = this.LocationCodeList.findIndex(ele => ele.VehicleTrasactionID === vehcileDetail.vehicleTransactionId);
      this.LocationCodeList.splice(index, 1);
    }
  }

  getAllCustomWeighBridge() {
    let query = { 'locationId': this.tempLocationID };
    this.kemarClientService.get<any>(null, ServiceUrl.getCommonCustomWeighBridge, query)
      .subscribe(
        response => {

          this.weighbridgeMaster = response;
        },
        r => {
          alert(r.error.error);
          console.log(r.error.error);
        });
  }

  UpdateSingleData(flag: boolean = true) {
    if (this.skipVehicleDiversion) {
      this.weightBridgedown();
      return;
    }
    if (flag == true) {
      if (this.LocationCodeList == null || this.LocationCodeList == undefined || this.LocationCodeList.length === 0) {
        this.submitted = true;
        this.CommonErrorMessage = "Please Select Weighbridge."
        return;
      }
    }
    let query = { "singleWeighBridgeDetail": this.singleWeighBridgeDetail };
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.CommonupdateSingleWeighBridge, query)
      .subscribe(
        response => {
          if(response.errorMessage == 'WeighBridge queue Full, Please Divert this Vehicle to any other WeighBridge'){
            Swal.fire('Error', response.errorMessage, 'error')
          }
          this.Clearobj();
          this.obj.LocationCode = this.tempLocationCode;
          this.obj.res = response.responseMessage;
          this.spinner.hide();
          this.getVehicleQueueList(1);
        },
        r => {
          // alert(r.error.error);
          this.spinner.hide();
          console.log(r.error.error);
        });
  }

  UpdateBulkData() {
    debugger;
    if (this.skipVehicleDiversion) {
      this.weightBridgedown();
      return;
    }
    if (this.weighBridgeId === null || this.weighBridgeId === undefined || this.weighBridgeId === 0) {
      this.submitted = true;
      this.CommonErrorMessage = "Please Select Weighbridge."
      return;
    }
    if (this.LocationCodeList === null || this.LocationCodeList === undefined || this.LocationCodeList.length === 0) {
      this.submitted = true;
      this.CommonErrorMessage = "Please select atleast 1 vehicle."
      return;
    }
    this.spinner.show();
    let query = { "WeighbridgeId": this.weighBridgeId, "PreviousWeighBridgeID": this.tempLocationID, "vehicleDetail": this.LocationCodeList }
    this.kemarClientService.postPatch<any>(ServiceUrl.CommonupdateWeighBridge, query)
      .subscribe(
        response => {
          this.Clearobj();
          this.obj.LocationCode = this.tempLocationCode;
          this.obj.res = response.responseMessage
          this.getVehicleQueueList(2);
        },
        r => {
          alert(r.error.error);
          console.log(r.error.error);
        });
        this.modalService.dismissAll();
  }


  DivertedWeighBridge(Content: any, WeighBridgeDetail: any) {
    debugger;
    this.subscription.unsubscribe();
    this.spinner.show();
    this.tempLocationCode = this.tempLocationID = this.WBName = null;
    this.tempLocationCode = WeighBridgeDetail.locationCode;
    this.tempLocationID = WeighBridgeDetail.locationID;
    this.WBName = WeighBridgeDetail.weightmentName;
    this.bulkDefault = true;
    this.IsShowWBVehicle = false;
    this.Clearobj();
    this.obj.LocationCode = this.tempLocationCode;
    this.obj.flag = true;
    this.obj.content = Content;
    this.getVehicleQueueList(3);
  }

  setboolean(flag: boolean) {
    if (flag) {
      this.SingleSelected = false;
      this.IsDisableCheckbox = false;
      this.BulkSelected = true;
    }
    else {
      this.BulkSelected = false;
      this.IsDisableCheckbox = true;
      this.SingleSelected = true;
    }
    this.submitted = false;
    this.LocationCodeList = this.WeighBridgeIDList = [];
    this.VehicleQueueList.forEach(ele => ele.isChecked = false);
  }
  WeighBridgeDiversionChangeOccur(event: any, weighBridgeId: any) {
    try {
      if (!this.kemarClientService.commonValidation(event) && !this.kemarClientService.commonValidation(weighBridgeId)) {
        this.submitted = false;
        this.UpdateBulkData()
      } else {
        this.UpdateBulkData()
        this.submitted = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  SelectWeighbridge(dropWeighbridgeId: any, vehicleDetail: any) {
    if (dropWeighbridgeId == "undefined" || dropWeighbridgeId == null) {
      var locationindex = this.LocationCodeList.findIndex(ele => ele.VehicleTrasactionID == vehicleDetail.vehicleTransactionId);
      var singleindex = this.singleWeighBridgeDetail.findIndex(item => item.VehicleTrasactionID === vehicleDetail.vehicleTransactionId);
      vehicleDetail.isChecked = false;
      this.LocationCodeList.splice(locationindex, 1);
      this.singleWeighBridgeDetail.splice(singleindex, 1);
      return;
    }
    if (this.singleWeighBridgeDetail.length > 0) {
      var result = this.singleWeighBridgeDetail.find(item => item.VehicleTrasactionID === vehicleDetail.vehicleTransactionId);
      result != undefined ? result.weighbridgeId = dropWeighbridgeId : this.Pushdata(vehicleDetail, dropWeighbridgeId);
    }
    else {
      this.Pushdata(vehicleDetail, dropWeighbridgeId);
    }
  }

  Pushdata(vehicleDetail: any, dropWeighbridgeId: any) {
    let query = {
      'VehicleTrasactionID': vehicleDetail.vehicleTransactionId,
      "WeighmentType": vehicleDetail.weighmentType,
      "dropweighbridgeId": dropWeighbridgeId,
      "lastWeighBridgeID": vehicleDetail.locationId
    }
    vehicleDetail.isChecked = true;
    this.singleWeighBridgeDetail.push(query)
    let Data = { "VehicleTrasactionID": vehicleDetail.vehicleTransactionId, }
    this.LocationCodeList.push(Data)
    this.LocationCodeList[this.LocationCodeList.length - 1].singleWeighBridgeDetail = this.singleWeighBridgeDetail.filter(x => x.VehicleTrasactionID == vehicleDetail.vehicleTransactionId);
  }

  spliceData(vehicleDetail: any, tempWeighbridgeId: number) {
    var index = this.WeighBridgeIDList.findIndex(ele => ele.vehicleTransactionId === vehicleDetail.vehicleTransactionId);
    if (index != -1 || index != null) {
      this.WeighBridgeIDList.splice(index, 1);
      if (tempWeighbridgeId != null)
        this.pushWeighbridge(tempWeighbridgeId, vehicleDetail)
    }
  }

  pushWeighbridge(tempWeighbridgeId: number, vehicleDetail: any) {
    let query = { "vehicleTransactionId": vehicleDetail.vehicleTransactionId }
    this.WeighBridgeIDList.push(query);
    let indexVehicle: number = this.LocationCodeList.findIndex(ele => ele.VehicleTrasactionID == vehicleDetail.vehicleTransactionId);
    this.LocationCodeList[indexVehicle].weighbridgeId = tempWeighbridgeId;
    this.LocationCodeList[indexVehicle].WeighBridgeDetail = this.WeighBridgeIDList;
  }

  closeButton() {
    this.weighBridgeId = undefined;
    this.submitted = false;
    this.setboolean(true);
    this.modalService.dismissAll();
    this.nextdraggedWeighBridge = this.lastdraggedVehicle = this.lastdraggedWeighBridge = null;
  }

  getAllWeighBridge() {
    this.kemarClientService.get<any>(null, ServiceUrl.getCommonAllWeighBridge)
      .subscribe(
        response => {

          let tes = response;
          this.AllWeighBridge = tes.map(item => {
            return { name: item.weighBridgeName, code: item.locationId };
          });
        },
        r => {
          alert(r.error.error);
          console.log(r.error.error);
        });
  }

  createTructedURL(data: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(data.accessURL);
  }

  GetCameraDetail(locationId: any, modelContent: any) {
    let query = { locationId: locationId, deviceType: 'CCTV' };
    this.kemarClientService
      .get<any>(null, ServiceUrl.getDevicesInfoByLocationIdAnddeviceType, query)
      .subscribe(
        (response) => {
          this.cameraDetailList = response;
          console.log(response);
          this.modalService.open(modelContent, this.ngbModalOptions);
        },
        (r) => {
          console.log(r.error.error);
        }
      );
  }

  Timer = () => { this.subscription = this.timer$.subscribe(_second => this.GetWeightCount()) }
  skipDiversion = (event: any) => this.skipVehicleDiversion = event.checked;
  UpdateWeighBridge = () => this.BulkSelected ? this.UpdateBulkData() : this.UpdateSingleData();
  Clearobj = () => this.obj.LocationCode = this.obj.res = this.obj.flag = this.obj.content = null;

}

export class paramActionDetails {
  LocationCode: any;
  res: any;
  flag: boolean;
  content: any;

}

interface WeighBridge {
  code: any;
  weighBridgeName: string;
  locationId: string;
}


function changeKeyObjects(array: any, replaceKeys: { name: string; code: string; }) {
  throw new Error('Function not implemented.');
}
