<app-header></app-header>
<section class="my-2">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="row data-filter align-items-center my-2">
                    <div class="col-sm-4">
                        <h4 class="title-one mb-0"> Job Schedule Master </h4>
                    </div>
                    <div class="col-sm-8 text-end">
                        <div class="input-append">
                            <span class="material-icons-sharp icon-in">
                                search
                            </span>
                            <input class="form-control" type="text" name="search" placeholder="search"
                                [(ngModel)]="filter">
                        </div>
                        <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Jobschedule"
                            matTooltipClass="danger-tooltip">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-list custom-table">
            <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover mx-auto">
                    <thead class="table-head">
                        <tr>
                            <th class="sr-no" style="width: 5px">Sr. No.</th>

                            <th (click)="sort('name')" class="col location">Job Name
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                    [ngClass]='glyphicon-chevron-up'>
                                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                </span>
                            </th>
                            <th (click)="sort('name')" class="col location">Cron Expression
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                    [ngClass]='glyphicon-chevron-up'>
                                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                </span>
                            </th>






                            <th (click)="sort('name')" class="col">Enable Logging
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                </span>
                            </th>

                            <th (click)="sort('name')" class="col">Enable Deep Logging
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                </span>
                            </th>
                            <th (click)="sort('name')" class="col">Hourly Log Frequency
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                </span>
                            </th>
                            <th (click)="sort('name')" class="col">Is Every Day Reset
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                </span>
                            </th>

                            <th (click)="sort('name')" class="col location">Description
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                    [ngClass]='glyphicon-chevron-up'>
                                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                </span>
                            </th>
                            <th (click)="sort('name')" class="col">IsActive
                                <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                </span>
                            </th>

                            <th class="action-key text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let jobschedule of jobschedulemaster | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage:
                                    pageSize, currentPage: currentActivePage }; let i = index">
                            <td class="left">{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                            <td class="left">{{jobschedule.jobName}}</td>
                            <td class="left">{{jobschedule.cronExpression}}</td>
                            <td class="left">{{jobschedule.enableLogging}}</td>
                            <td class="left">{{jobschedule.enableDeepLogging}}</td>
                            <td class="left">{{jobschedule.hourlyLogFrequency}}</td>
                            <td class="left">{{jobschedule.isEveryDayReset}}</td>
                            <td class="left">{{jobschedule.jobDescription}}</td>

                            <td class="text-center">
                                <span *ngIf="jobschedule.isActive  ==true ">Active</span>
                                <span *ngIf="jobschedule.isActive  ==false ">Inactive</span>
                            </td>

                             <td class="text-center">  
                                <span class="edit-btn-icon" type="button" matTooltip="Edit JobSchedule"
                                    matTooltipClass="danger-tooltip" (click)="editJobSchedule(edit,jobschedule) ">
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </span>
                                <span *ngIf="jobschedule.EnableActive==true ">Active</span>
                                <span *ngIf="jobschedule.EnableActive==false ">Inactive</span>

                                <span *ngIf="jobschedule.DeepActive  ==true ">Active</span>
                                <span *ngIf="jobschedule.DeepActive  ==false ">Inactive</span>

                                <span *ngIf="jobschedule.IsEveryDayActive  ==true ">Active</span>
                                <span *ngIf="jobschedule.IsEveryDayInActive  ==false ">Inactive</span>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div class="pagination justify-content-end">
                    <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
                </div>
            </div>
            <ng-template #edit let-modal class="modal">
                <div class="modal-content custom-modal">
                    <div class="modal-header">
                        <h4 class="modal-title mb-0">{{title}}</h4>
                        <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
                            (click)="modal.dismiss('Cross click')">
                            <span class="material-icons-sharp">close</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <form autocomplete="off" [formGroup]="form">
                                    <div class="form-group">
                                        <div class="form-group row mb-0 mt-2">
                                            <div class="col-sm-6">
                                                <label for="jobName">Job Name<sup>*</sup></label>
                                                <input type="text" class="form-control" placeholder="" value=" "
                                                    name="jobName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.jobName.errors}"
                                                    formControlName="jobName" [(ngModel)]="jobschedule.jobName" />
                                                <div *ngIf="submitted && f.jobName.errors" class="text-danger">
                                                    <div *ngIf="f.jobName.errors.required">
                                                        <small>Job Name is required.</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <label for="cronExpression">Cron Expression<sup>*</sup></label>
                                                <input type="text" class="form-control" placeholder="" value=" "
                                                    name="cronExpression"
                                                    [ngClass]="{ 'is-invalid': submitted && f.cronExpression.errors}"
                                                    formControlName="cronExpression"
                                                    [(ngModel)]="jobschedule.cronExpression" />
                                                <div *ngIf="submitted && f.cronExpression.errors" class="text-danger">
                                                    <div *ngIf="f.cronExpression.errors.required">
                                                        <small>Cron Expression is required.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row mb-0 mt-2">
                                            <div class="col-sm-6">
                                                <label for="jobDescription">Description<sup>*</sup></label>
                                                <input type="text" class="form-control" placeholder="" value=" "
                                                    name="jobDescription"
                                                    [ngClass]="{ 'is-invalid': submitted && f.jobDescription.errors}"
                                                    formControlName="jobDescription"
                                                    [(ngModel)]="jobschedule.jobDescription" />
                                                <div *ngIf="submitted && f.jobDescription.errors" class="text-danger">
                                                    <div *ngIf="f.jobDescription.errors.required">
                                                        <small>Description is required.</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <label for="isActive">Is Active<sup>*</sup></label>
                                                <div class="select-radio">
                                                    <input class="radio-input" type="radio" id="active" [value]="true"
                                                        [(ngModel)]="jobschedule.isActive" formControlName="isActive">
                                                    <label for="active" class="radio-label">Active</label>

                                                    <input class="radio-input" type="radio" id="inActive"
                                                        [value]="false" [(ngModel)]="jobschedule.isActive"
                                                        formControlName="isActive">
                                                    <label for="inActive" class="radio-label">Inactive</label>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 class="modal-title mt-3 mb-0">Logging</h4>
                                        <hr class="mt-0">
                                        <div class="form-group row mb-0 mt-2">
                                            <div class="col-sm-6">
                                                <label for="hourlyLogFrequency">Hourly Log Frequency<sup>*</sup></label>
                                                    <input type="number" class="form-control" placeholder="" value=" "
                                                    name="hourlyLogFrequency"
                                                    [ngClass]="{ 'is-invalid': submitted && f.hourlyLogFrequency.errors}"
                                                    formControlName="hourlyLogFrequency"
                                                    [(ngModel)]="jobschedule.hourlyLogFrequency" />
                                                <div *ngIf="submitted && f.hourlyLogFrequency.errors"
                                                    class="text-danger">
                                                    <div *ngIf="f.hourlyLogFrequency.errors.required">
                                                        <small>Hourly Log Frequency is required.</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <label for="enableLogging">Enable Logging<sup>*</sup></label>
                                                <div class="select-radio" [hidden]="activebuttonShow">
                                                    <input class="radio-input" id="Active1" type="radio" [value]="true"
                                                        [(ngModel)]="jobschedule.EnableActive"
                                                        formControlName="EnableActive">
                                                    <label for="Active1" class="radio-label">Active</label>

                                                    <input class="radio-input" id="InActive1" type="radio"
                                                        [value]="false" [(ngModel)]="jobschedule.EnableActive"
                                                        formControlName="EnableActive">
                                                    <label for="InActive1" class="radio-label">Inactive</label>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="form-group row mb-0 mt-2">
                                            <div class="col-sm-6">
                                                <label for="enableDeepLogging">Enable Deep Logging<sup>*</sup></label>

                                                <div class="select-radio" [hidden]="activeButtonShow">
                                                    <input class="radio-input" type="radio" id="Active1" [value]="true"
                                                        [(ngModel)]="jobschedule.DeepActive"
                                                        formControlName="DeepActive">
                                                    <label for="Active1" class="radio-label">Active</label>

                                                    <input class="radio-input" id="InActive1" type="radio"
                                                        [value]="false" [(ngModel)]="jobschedule.DeepActive"
                                                        formControlName="DeepActive" />
                                                    <label for="InActive1" class="radio-label">Inactive</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <label for="isEveryDayReset">Is Every Day Reset<sup>*</sup></label>
                                                <div class="select-radio" [hidden]="activeButtonShow">
                                                    <input class="radio-input" type="radio" id="dayactive"
                                                        [value]="true" formControlName="IsEveryDayActive"
                                                        [(ngModel)]="jobschedule.IsEveryDayActive">
                                                    <label for="dayactive" class="radio-label">Active</label>

                                                    <input class="radio-input" id="dayinactive" type="radio"
                                                        [value]="false" [(ngModel)]="jobschedule.IsEveryDayActive"
                                                        formControlName="IsEveryDayActive" />
                                                    <label for="dayinactive" class="radio-label">Inactive</label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="modal-button btn btn-default" aria-label="Close"
                            (click)="modal.dismiss('Cross click')">Close</button>
                        <button type="button" class="modal-button btn btn-danger" *ngIf="resetbuttonShow"
                            aria-label="Reset" (click)="onReset()">Reset</button>
                        <button type="submit" class="modal-button btn btn-primary"
                            (click)="registerJobSchedule(jobschedule)">Save</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</section>
<app-footer></app-footer>