<app-header></app-header>
<ngx-spinner></ngx-spinner>
<section class="my-2">
    <mat-tab-group animationDuration="0ms" mat-align-tabs="end">
        <mat-tab label="Detailed Gate Report">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <div class="row data-filter my-2 align-items-center">
                            <div class="col-sm-3">
                                <h4 class="title-one mb-0">Detailed Gate Report</h4>
                            </div>
                            <div class="col-sm-9 text-end" id="input-filter">
                                <div class="input-append">

                                    <input class="form-control ps-2" type="search" name="search"
                                        placeholder="Search by keyword" [(ngModel)]="filter"
                                        (input)="filterchange($event.target.value)">
                                </div>

                                <label for="start">From:</label>
                                <input
                                  class="form-control date"
                                  name="fromdate"
                                  (keypress)="KeyPressDate($event)"
                                  [(ngModel)]="detailedGateReportModel.fromDate"
                                  type="date"
                                  id="start"
                                  value="Date"
                                  [max]="currentdate"
                                />
                                <label for="start" class="to-label">To:</label>
                                <input
                                  class="form-control date"
                                  name="todate"
                                  (keypress)="KeyPressDate($event)"
                                  [(ngModel)]="detailedGateReportModel.toDate"
                                  type="date"
                                  id="start"
                                  value="Date"
                                  [max]="currentdate"
                                />
                                <button
                                class="btn btn-primary go-btn"
                                (click)="getDetailedGateReport()"
                                matTooltip="Search"
                                matTooltipClass="primary-tooltip"
                              >
                                <span class="material-icons-round">search</span>
                              </button>

                                <button class="btn btn-success go-btn ms-2" matTooltip="Download Report"
                                    matTooltipClass="primary-tooltip" (click)="exportToExcel()">
                                    <span class="material-icons-round">file_download</span>
                                </button>
                            </div>
                        </div>

                        <div class="table-list custom-table">
                            <div class="table-responsive" style="overflow-x: auto;  white-space: nowrap;">
                                <div class="table-container" style="min-width: 1200px;">
                                    <table class="table table-striped table-bordered table-hover mx-auto">
                                        <thead class="table-head">
                                            <tr>
                                                <th class="sr-no text-center">Sr No.</th>
                                                <th (click)="sort('name')">VRN
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>


                                                </th>
                                                <th (click)="sort('name')">ELV Code
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort(' name')">Helper Name
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">Driver Name
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">Driver Mobile No
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <!-- <th (click)="sort('name')">Transporter
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th> -->
                                                <th (click)="sort('name')">Transaction Type
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">Location
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">Milestone
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">Txn Initiated by
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">Vehicle Detection
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <!-- <th (click)="sort('name')">Transaction Date & Time
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">RFID
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th> -->
                                                <th (click)="sort('name')">FRS
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">BA
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">Boom Up
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">Notify Ax4
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <th (click)="sort('name')">TAT (mm:ss)
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th>
                                                <!-- <th (click)="sort('name')">Remarks
                                                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                                                        [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                                                        <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                                                    </span>
                                                </th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                             *ngFor="
                                             let record of records
                                             | orderBy : key : !reverse
                                             | filter : filter
                                             | paginate
                                               : {
                                                    itemsPerPage: pageSize,
                                                     currentPage: currentActivePage
                                                  };

                                                 let i = index">
                                                <td class="sr-no text-center">{{ i + 1 + pageSize * (currentActivePage - 1) }}</td>
                                                <td>{{ record.vrn }}</td>
                                                <td>{{ record.tranCode }}</td>
                                                <td>{{ record.helperName }}</td>
                                                <td>{{ record.driverName }}</td>
                                                <td>{{ record.driverMobileNo }}</td>
                                                <!-- <td>{{ record.transporter }}</td> -->
                                                <td>{{ record.tranType }}</td>
                                                <td>{{ record.location }}</td>
                                                <td>{{ record.milestone }}</td>
                                                <td>{{ record. initiatedBy }}</td>
                                                <td>{{ record. vehicleDetectionCompletionTime| date: 'dd-MM-yyyy HH:mm' }}</td>
                                                <!-- <td>{{ record.transactiondateandtime | date: 'dd-MM-yyyy HH:mm '}}</td> -->

                                                <!-- <td>{{ record.rfid }}</td> -->
                                                <td>{{ record.frRemark }}</td>
                                                <td>{{ record.baRemark}}</td>
                                                <td>{{ record.boomUp}}</td>
                                                <td>{{ record.aX4NotifyRemark }}</td>
                                                <td>{{ record.tat }}</td>
                                                <!-- <td>{{ record.remarks }}</td> -->
                                            </tr>

                                            <tr *ngIf="!records || records.length === 0">
                                                <td colspan="100%" class="text-center">No data available</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                  <span style="margin-right:15px ;"> Total Records : {{ totalRecords }} </span>
                                  Items Per Page
                                  <select (change)="OnPageSizeChange(pageSize = $event.target.value)" class="paginationcount" >
                                    <option value="15">15</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                  </select>
                                </div>
                                <div class="col-md-4 text-center"></div>
                                <div class="col-md-4 text-end">
                                  <div class="pagination justify-content-end">
                                    <pagination-controls
                                      (pageChange)="pagechange($event)"
                                    ></pagination-controls>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</section>
<app-footer></app-footer>
