import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleDetail } from 'src/app/apiModels/elv-models';
import { JobAllocationData, JobAllocationForInplant, Materials, Products } from 'src/app/apiModels/job-allocation-models';
import { Milestones, VehicleMilestone, elvFromExcel, milestoneJsonData } from 'src/app/apiModels/milestone-model';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2';
import { faBan, faPlus, faSort,faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-weight-tolerance-action',
  templateUrl: './weight-tolerance-action.component.html',
  styleUrls: ['./weight-tolerance-action.component.scss']
})
export class WeightToleranceActionComponent implements OnInit {
  toleranceActionForm!: FormGroup;
  public userAccessData: UserScreenAccesData;

  submitted = false;
  AllWeighbridge : any[];
  IsThereTolerance = false;
  faSearch = faSearch;
  vehicleDetail = "";
  vehicleToleranceDetail : any;
  ToleranceReasonList:any;
  autoWBAssign=true;
  ActionValue="Accept";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private kemar: KemarClientService,
    private spinner: NgxSpinnerService,private api:DataService
  ) { }

  ngOnInit(): void {
    this.resetForm();
    this.getWeighbridgeMaster();
    this.getToleranceReasonMaster();
  }

  resetForm(){
    this.toleranceActionForm = this.fb.group({
      accept : ['Accept'],
      jobMilestoneId : [0],
      rfidTagNo:[''],
      reason:['', [Validators.required]],
      Justification:[''],
      requestId:['121'],
      vehicleTransactionId:[0],
      vrn:[''],
      weighBridgeId:[0]
    });
    this.userAccessData =this.api.getUserScreenAccessMenu('WEIGHTTOLERANCE');

  }

  getToleranceReasonMaster() {
    var query ={
      key:'TOLERANCE',
      Parameter:'TOLERANCE_REASON'
    }
    this.kemar.get<any>(null, ServiceUrl.GetCommonMasterDataOnParameter, query).subscribe(
      (response) => {
        this.ToleranceReasonList = response;
      },
      (r) => {
        console.log(r.error.error);
      }
    );
  }

  getWeighbridgeMaster() {
    this.kemar.get<any>(null, ServiceUrl.getAllWeighbridgeMobile).subscribe(
      (response) => {
        this.AllWeighbridge = response.weighBridgeList;
      },
      (r) => {
        console.log(r.error.error);
      }
    );
  }

  getVehicleToleranceDetail(){
    if(this.vehicleDetail == '' || this.vehicleDetail == null){
      Swal.fire('', 'Enter Vehicle Number', 'info');
      return;
    }
    var query = {
      RequestId : 1,
      VRN : this.vehicleDetail,
      RFIDTagNo : ''
    }
    this.kemar.get<any>(null, ServiceUrl.getVehicleToleranceCheck, query).subscribe(
      (response) => {
        this.vehicleToleranceDetail = response.weighmentDetails;
        this.IsThereTolerance = true;
        this.toleranceActionForm.controls['jobMilestoneId'].setValue(this.vehicleToleranceDetail.jobMilestoneId);
        this.toleranceActionForm.controls['vehicleTransactionId'].setValue(this.vehicleToleranceDetail.vehicleTransactionId);
        this.toleranceActionForm.controls['vrn'].setValue(this.vehicleToleranceDetail.vrn);
      },
      (r) => {
        Swal.fire('', r.error.statusMessage, 'error');
      }
    );
  }

  AcceptValueOnChange(){
    this.autoWBAssign = true;
    this.toleranceActionForm.controls['weighBridgeId'].setValue(0);
  }

  OnChangeWBAssign(){
    if(this.autoWBAssign != true){
      this.toleranceActionForm.controls['weighBridgeId'].setValidators([Validators.required]);
    }
    else{
      this.toleranceActionForm.controls['weighBridgeId'].clearValidators();
    }
    this.toleranceActionForm.controls['weighBridgeId'].updateValueAndValidity();
  }

  AcceptTolerance(){
    this.submitted = true;
    if(this.toleranceActionForm.invalid){
      return;
    }
    this.spinner.show();
    this.kemar.postPatch<any>(ServiceUrl.ActionOnToleranceWeight, this.toleranceActionForm.value)
      .subscribe(
        (res) => {
          Swal.fire('success', '', 'success');
          this.ResetAllValues();
          this.spinner.hide();
        },
        (r) => {
          Swal.fire('error', r.error.statusMessage, 'error');
          this.ResetAllValues();
          this.spinner.hide();
          // console.log(r.error.statusMessage);
        }
      );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.toleranceActionForm.controls;
  }

  ResetAllValues(){
    this.resetForm();
    this.IsThereTolerance = false;
    this.vehicleDetail = '';
    this.vehicleToleranceDetail= null;
    this.ActionValue = 'Accept';
    this.submitted = false;
  }

}
