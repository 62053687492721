import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal,NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ServiceUrl } from 'src/app/common/service-urls';
import { VehicleMasterResponse } from 'src/app/models/vehiclemastermodel';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { __values } from 'tslib';
import { MileStoneTrackingRequest } from 'src/app/models/vehicleMilestonetrackingModel';
import { DataService } from 'src/app/service/data.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-vehicle-trip-support',
  templateUrl: './vehicle-trip-support.component.html',
  styleUrls: ['./vehicle-trip-support.component.scss']
})
export class VehicleTripSupportComponent implements OnInit {
  milestoneActionTrackingId: any;
  jobMilestoneId:any;
  isChecked: boolean;
  public toggleButton: boolean = true;
  jobMilestones: any;
  public milestoneTrack: MileStoneTrackingRequest = new MileStoneTrackingRequest();
  bypassjobMileTrackingActionCode: '';
  bypassLocationCode = '';
  bypassLocationId = 0;
  bypassRemark = '';
  milestoneCompleted = false;

  protected _onDestroy = new Subject<void>();

  vehicleTransaction: any;
  milestoneActionsTracking: any;
  displayModal: boolean;
  TotalDiffTime: string;
  TranType: any;
  UserRole: string;
  vin: string ;
  vtid:number ;

  vehicleList: any = [];
  faSearch = faSearch;
  vehicleTransactionCode='';
  driverName ='';
  loading = false;
  currentPage :number =0;

  vehicleTranCode : any;
  status : string ='Completed';
  StatusOptions:any;

  displayBasic: boolean;
  DetectableByDll: any = DetectableByDll;
  locationTypeDll: any = locationTypeDll;
  actionTypes : any = ActionTypes;
  jobMileAction: any =JobMilestoneActions;
  form: FormGroup;
  jobMileform: FormGroup;
  deviceMaster:any;
  submitted = false;
  title:string ;
  locationId:number;
  deviceListOnId:any;
  disableBtn : boolean = false;
  constructor(
    private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal,
    public api: DataService,
    private formBuilder: FormBuilder
  ) {
    this.UserRole = api.getUserRole();
    this.form = this.formBuilder.group({
      actionValue: ['', Validators.required],
      Remarks: ['', Validators.required],
      //deviceLocationId: ['', Validators.required]
    });
    this.jobMileform = this.formBuilder.group({
      actionValue: ['', Validators.required],
      Remarks: ['', Validators.required],
      //deviceLocationId: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getVehicleList();
    this.getAllDevice();
  }
  getAllDevice() {
    var query = { 'requestId': "S1637" }
    this.kemarClientService.get<any>(null, ServiceUrl.getdeviceMapping,query)
      .subscribe(
        response => {
          this.deviceMaster = response.locations.slice().sort((a,b)=> a.deviceName.localeCompare(b.deviceName));
        },
        r => {
          this.spinner.hide();
        });
  }
  getVehicleList() {
    this.spinner.show();
    var query = {
       rowSize: 20,
       currentPage: this.currentPage,
       searchText : '',
       index : 0,
       tranType : 1
    }
    this.kemarClientService.get<VehicleMasterResponse>(null, ServiceUrl.getActiveVehicleList, query).subscribe(
      (response) => {
        const newVehicles = Array.isArray(response) ? response : [response];
        this.vehicleList = [...this.vehicleList, ...newVehicles];
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        console.error('Error fetching vehicle list:', error);
      }
    );
  }
  onScrollToEnd() {
    this.currentPage++;
		this.getVehicleList();
	}
  onSelected(value:any): void {
    const selectedVehicle = this.vehicleList.find(vehicle => vehicle.vehicleTransactionId === value);
    this.vehicleTranCode = selectedVehicle.vehicleTransactionCode;
		this.TranType = this.getTransactionTypeName(selectedVehicle.tranType);
    this.vehicleTransactionCode = selectedVehicle.vehicleTransactionCode;
    this.driverName = selectedVehicle.driverName;                  
	}
  
  async onChangecheckboxSupport(MilestoneTrackingId, milestoneActionsTracking, edit, jobmileTrackingActionCode, locationCode,actualLane, status) {
    this.title ="Edit Milestone Action Tracking";
    this.milestoneTrack.MilestoneActionTrackingId = MilestoneTrackingId;
    this.bypassjobMileTrackingActionCode = jobmileTrackingActionCode;
    this.bypassLocationCode = locationCode;
    this.bypassLocationId = 1;
    this.bypassRemark = jobmileTrackingActionCode.remarks;
    var reSult = await this.getAllDeviceBasedOnLocationId(locationCode, actualLane);
    // if(reSult){
    //   this.form.controls['deviceLocationId'].setValue(reSult.deviceLocationId);
    //   this.form.get('deviceLocationId').disable();
    // }
    // else{
    //   this.form.controls['deviceLocationId'].setValue('');
    //   this.form.get('deviceLocationId').enable();
    // }
      
    this.isChecked = true;  // Set true to bypass checkbox validation
    milestoneActionsTracking.forEach((val) => {
      val.isSelected = true;
      val.isShown = true;
      val.isRemark = true;


    });

    this.displayModal = true;
     this.modalService.open(edit);
  }
async onCheckBoxChangeJobMilestone(jobMile, milestoneActionsTracking, editJobMile, locationCode, actualLane, status) {
  debugger;
  this.title = "Edit Job Milestone";
  this.jobMilestoneId = jobMile.jobMilestoneId;
  this.bypassLocationCode = locationCode;
  this.bypassLocationId = 1;
//  var reSult = await this.getAllDeviceBasedOnLocationId(locationCode, actualLane);
//   if(reSult){
//     this.jobMileform.controls['deviceLocationId'].setValue(reSult.deviceLocationId);
//     this.jobMileform.get('deviceLocationId').disable();
//   }
//   else{
//     this.jobMileform.controls['deviceLocationId'].setValue('');
//     this.jobMileform.get('deviceLocationId').enable();
//   }
    
  this.isChecked = true;
  this.displayModal = true;
   this.modalService.open(editJobMile);
}
  onReset() {
    this.submitted = false;
    this.form.reset();
    this.jobMileform.reset();
    this.form.controls['actionValue'].setValue('');
    //this.form.controls['deviceLocationId'].setValue('');
    this.form.controls['Remarks'].setValue('');
    this.jobMileform.controls['actionValue'].setValue('');
    //this.jobMileform.controls['deviceLocationId'].setValue('');
    this.jobMileform.controls['Remarks'].setValue('');
  }
  updateMilestoneTrackingSupport() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    const query = {
      milestoneActionTrackingId: this.milestoneTrack.MilestoneActionTrackingId,
      LocationCode: this.bypassLocationCode,
      //LocationId: this.form.controls['deviceLocationId'].value,
      JobMilestoneId:this.jobMilestones.jobMilestoneId,
      Remarks: this.form.controls['Remarks'].value,
      Status : this.form.controls['actionValue'].value,
    };
    this.kemarClientService.get<any>(null,ServiceUrl.updatemilestonactiontracting, query)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.getseachedvehicle()
          Swal.fire('', res.responseMessage, 'success').then(() => {
            this.dissmiss();
          });
        },
        (error) => {
          Swal.fire({ icon: 'error', title: '', text: error.error.errorMessage });
        }
      );
  }
  updateJobMilestoneSupport() {
    
    this.submitted = true;
    if (this.jobMileform.invalid) {
      return;
    }
    this.spinner.show();
    const query = {

      'JobMilestoneId' : this.jobMilestoneId,
      //LocationId: this.jobMileform.deviceLocationId,
      'Remarks': this.jobMileform.controls['Remarks'].value,
      'Status' : this.jobMileform.controls['actionValue'].value,
    };
    this.kemarClientService.get<any>(null,ServiceUrl.updateJobMilestone, query)
      .subscribe(
        (res: any) => {
          this.getseachedvehicle();
          Swal.fire('', res.responseMessage, 'success').then(() => {
            this.dissmiss();
          });
        },
        (error) => {
          this.spinner.hide();
          Swal.fire({ icon: 'error', title: '', text: error.error.errorMessage });
        }
      );
  }

  getseachedvehicle() {
    if(this.vehicleTranCode ===undefined || this.vehicleTranCode ==null)
      return;
    var query = {
      vehicle: this.vehicleTranCode
    };
    this.spinner.show();
    this.kemarClientService.get<VehicleMasterResponse>(null, ServiceUrl.GetVehilceTransaction, query).subscribe(
      (response) => {
        this.spinner.hide();
        if (
          typeof response.jobMilestones !== 'undefined' &&
          response.jobMilestones !== null
        ) {
          this.jobMilestones = response.jobMilestones;
          this.TotalDiffTime = this.jobMilestones[0].totalTimeTakenToCompleteTransaction;
        }

        var pendingMilestone = this.jobMilestones.filter(x => x.status == 'Pending');
        if(this.jobMilestones[this.jobMilestones.length-1].status == 'Completed'){
          this.milestoneCompleted = true;
        }
        else if(pendingMilestone.length == 0){
          this.milestoneCompleted = true;
        }
        else{
          this.milestoneCompleted = false;
        }
      },
      (r) => {
        this.spinner.hide();
        alert(r.error.error);
      }
    );
  }

  customSearch(term: string, item: { vrn: string; }) {
    item.vrn = item.vrn.replace(',', '');
    term = term.toLocaleLowerCase();
    return item.vrn.toLocaleLowerCase().indexOf(term) > -1;
  }
  trackByJobMilestoneId(index: number, jobmile: any): number {
    return jobmile.jobMilestoneId; // Return the unique identifier for each item
  }

  shouldDisablePanel(index: number): boolean {
    this.disableBtn = false;
    const openIndex = this.jobMilestones.findIndex(jobmile => jobmile.status === 'Open');
    if (openIndex === -1) return false;
    if (index < openIndex - 1 || index > openIndex + 1) {
      this.disableBtn = true;
      return true;
    }
    this.disableBtn = false;
    return false;
  }
  retriggerAX4Notify(jobMilestoneId, MilestoneCode) {
    this.spinner.show();
    var query = {
      vrn: this.vehicleTransaction?.vrn || '',
      milestoneCode: MilestoneCode,
      JobMilestoneId: jobMilestoneId
    };
    this.kemarClientService.get<any>(null, ServiceUrl.retriggerAX4Notify, query).subscribe(
      response => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: response.responseMessage
        }).then(function () {
          window.location.reload();
        });
        this.spinner.hide();
      },
      r => {
        Swal.fire({
          icon: 'error',
          title: '',
          text: r.error.responseMessage
        }).then(function () {
          window.location.reload();
        });
        this.spinner.hide();
      });
  }
  getAllDeviceBasedOnLocationId(locationCode: number, actualLane: number): Promise<any> {
    this.spinner.show();
    const query = {
      'requestId': "S1637",
      'LocationId': locationCode,
    };
    return new Promise((resolve, reject) => {
      this.kemarClientService.get<any>(null, ServiceUrl.getLocationDataByLocationId, query)
        .subscribe(
          response => {
            this.spinner.hide();
  
            this.deviceListOnId = this.deviceMaster.find(devMaster  =>
              response.some(device => 
                device.deviceLocationMappingId == devMaster.deviceLocationId)

            )
            if (this.deviceListOnId) {
              resolve(this.deviceListOnId);
            } else {
              resolve(null);
            }
          },
          error => {
            this.spinner.hide();
            reject(error); 
          }
        );
    });
  }
  getTransactionTypeName(value: string): string {
    switch (value) {
        case '0':
            return "-- All Tran Type --";
        case '1':
            return "Outbound";
        case '2':
            return "Inbound";
        case '3':
            return "InPlant";
        default:
            return "Unknown Type";
    }
}
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  public noWhitespaceValidator(control:FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  get f2(): { [key: string]: AbstractControl } {
    return this.jobMileform.controls;
  }
  dissmiss(){
    this.onReset();
    this.modalService.dismissAll()
  }
}

// Constants
const ActionTypes: { key: string, value: string }[] = [
  { key: 'Complete', value: 'Completed' },
  { key: 'Pending', value: 'Pending' },
];
const JobMilestoneActions: { key: string, value: string }[] = [
  { key: 'Open', value: 'Open' },
  { key: 'Complete', value: 'Completed' },
  { key: 'Pending', value: 'Pending' },
  { key: 'Cancelled', value: 'Cancelled' },
];

const DetectableByDll: { key: string, value: number }[] = [
  { key: 'RFID', value: 1 },
  { key: 'GPS', value: 2 },
];
const locationTypeDll: { key: string, value: number }[] = [
  { key: 'OSPP', value: 1 },
  { key: 'Plant Gate', value: 2 },
  { key: 'Weighbridge', value: 3 },
  { key: 'Coil Yard', value: 4 },
  { key: 'TMT Yard', value: 5 },
  { key: 'Loading', value: 6 },
  { key: 'UnLoading', value: 7 },
  { key: 'ISPP', value: 8 },
  { key: 'In Plant Gate', value: 9 },
  { key: 'Packer', value: 10 },
  { key: 'Truck Loader', value: 11 },
  { key: 'Store', value: 12 },
];

