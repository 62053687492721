<app-header></app-header>
<section class="my-2" id="vehicle-parking"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <!-- <h4 class="title-one mb-0"> Vehicles In Parking </h4> -->
        <div class="form-check form-switch text-end" style="padding-left: 81.5em;">
          <mat-slide-toggle color="primary" (change)="AutoCallInToggle($event)" [checked]="IsAutoCalled"
          >Auto Call-in</mat-slide-toggle>
        </div>
        <div class="row data-filter my-2 align-items-center">
          <div class="col-sm-2">
            <h4 class="title-one mb-0"> Vehicles In Parking </h4>
          </div>

          <div class="col-sm-10 text-end" id="input-filter">
            <div class="input-append">
              <!-- <span class="material-icons-sharp icon-in">search</span> -->
              <input class="form-control ps-2" type="search" name="search" placeholder="Search by keyword"
                [(ngModel)]="filter">
            </div>
            <select name="status" class="form-control form-select" autocomplete="" id="status" [(ngModel)]="status">
              <option value="All">-- All status --</option>
              <option *ngFor="let vstatus of vehicleStatus" [ngValue]="vstatus.value">
                {{vstatus.key}}</option>
            </select>
            <select name="locationId" class="form-control form-select" autocomplete="" id="locationId" [(ngModel)]="locationId">
              <option value=0>-- All Location --</option>
              <option *ngFor="let loc of locationMaster" [ngValue]="loc.locationId">
                {{loc.locationName}}</option>
            </select>
            <select name="tranType"
            class="form-control form-select" name="tranType" id="tranType" autocomplete="" [(ngModel)]="tranType">
            <!-- <option (click)=toggleDisplayDiv() id="divshow"  value="RFID">RFID Reader</option> -->
                <option value='All'>-- All Tran Type --</option>
                <option value ='Outbound' >Outbound</option>
                <option value ='Inbound' >Inbound</option>
                <option value ='InPlant' >InPlant</option>
          </select>
            <button class="btn btn-primary go-btn"
              (click)="GetVehilceParkingOnFilter(category,status,tranType, locationId)">
              <span class="material-icons-sharp">search</span>
            </button>

            <div class="select_top align-items-center">
              <label for="start">Select Top </label>
            <input class="form-control" [disabled]="isChecked" name="select" type="number" id="select"
              [(ngModel)]="select" value="select" maxlength="99">
            <label for="start">Vehicles </label>
            <button class="btn btn-primary go-btn" (click)="GetVehilceParkingCallIn(elvList,select)">
              Call -in
            </button>
            </div>
          </div>
        </div>

        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="col-2 sr-no">Sr. No.</th>
                  <th (click)="sort('name')" class="col location">Trip No
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="col location">ELV Code
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="col-vrn">VRN
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>


                  <th (click)="sort('name')" class="col-driver">Driver Name
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="col-product">Product Name
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')" class="col doc">Doc No
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="col parking">Parking-In DateTime
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')" class="col tran">Trip Type

                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('name')" class="col status"> Status

                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')" class="col">Action
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let parkin of elvList | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">

                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}">
                    {{parkin.transactionCode}}</td>
                  <td [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}">{{parkin.elvCode}}
                  </td>
                  <td class="col-vrn" [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}">
                    {{parkin.vrn}}</td>

                  <td class="col-driver" [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}">
                    {{parkin.driverName}}</td>
                  <td class="col-product" [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}">
                    {{parkin.productName}}</td>
                  <td [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}">{{parkin.docNumber}}
                  </td>
                  <td [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}">
                    {{parkin.entryDateTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                  <!-- <td style="color: #b0b0b0"></td> -->
                  <td [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}"> {{parkin.tranType}}
                  </td>

                  <td [ngStyle]="{'color': (parkin.status=='CalledIn' || parkin.status == 'InSidecalling') ? '#07070738' : '#000'}">{{parkin.status}}
                  </td>


                  <td>
                    <span>
                      <!-- <mat-checkbox name="action" value="action"  [disabled]="parkin.status=='CalledIn'  ? true : false "  ></mat-checkbox> -->
                      <input id="action" *ngIf="parkin.transactionCode != null && (parkin.status == 'InParking' || parkin.status == 'InSideParking')"
                        type="checkbox" class="form-check-input" [checked]="parkin.action" [value]="parkin.action"
                        (change)="onChangecheckbox(parkin.elvId,elvList)">
                    </span>
                  </td>

                </tr>

              </tbody>
            </table>

          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
        </div>

      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>

<!-- <div class="row">
                <div class="col-sm-2">
                  <input class="form-control rounded-pill search" type="text" name="search" placeholder="search"
                    [(ngModel)]="filter">
                </div>

                <div class="col-sm-7 second-col">

                  <select name="status" class="form-control form-select" autocomplete="" id="status"
                    [(ngModel)]="status">
                    <option value="All">-- All status --</option>
                    <option *ngFor="let vstatus of vehicleStatus" [ngValue]="vstatus.value">
                      {{vstatus.key}}</option>
                  </select>
                  &nbsp;&nbsp; &nbsp;&nbsp;

                  <select name="tranType" class="form-control form-select" autocomplete="" id="tranType"
                    [(ngModel)]="tranType">
                    <option value="All">-- All Transactions --</option>
                    <option *ngFor="let ttype of transactionType" [ngValue]="ttype.value">
                      {{ttype.key}}</option>
                  </select>

                  &nbsp;&nbsp; &nbsp;&nbsp;
                  <select name="category" class="form-control form-select" id="category" [(ngModel)]="category">
                    <option value="All">-- All Proudcts --</option>

                    <option *ngFor="let Product of productList" [ngValue]="Product.category">
                      {{Product.category}}</option>
                  </select>
                  &nbsp;&nbsp; &nbsp;&nbsp;
                  <button class="btn btn-outline-primary go-btn"
                    (click)="GetVehilceParkingOnFilter(category,status,tranType)">Filtter</button>
                </div>

                <div class="col-sm-3 third-col">
                  <label for="start">Select Top </label> &nbsp;
                  <input class="form-control" [disabled]="isChecked" style="width:50px" name="select" type="text"
                    id="select" [(ngModel)]="select" value="select"> &nbsp;
                  <label for="start">Vehicles </label> &nbsp;&nbsp;
                  <button class="btn btn-outline-primary go-btn"
                    (click)="GetVehilceParkingCallIn(elvList,select,action)">Call In</button>

                </div>
              </div> -->

              <!-- <div [ngbNavOutlet]="nav" class="ml-4"></div> -->
