import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { AlertMasterComponent } from '../alert-master/alert-master.component';
import { NgxSpinnerService } from "ngx-spinner";
import { ExceptionMasterComponent } from '../exception-master/exception-master.component';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-alert-tabs',
  templateUrl: './alert-tabs.component.html',
  styleUrls: ['./alert-tabs.component.scss']
})
export class AlertTabsComponent implements OnInit {
  active = 'top';
  _component: any;
  componentsList = [];
  faBan = faBan;
  alertMaster: any;
  title:string="Alert";
  submitted = false;
  alertCount:number=0;
  ticketCount:number=0;
  public userAccessData: UserScreenAccesData;
  constructor(private viewContainerRef: ViewContainerRef,
    private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,   
    private api:DataService) { }

    ngOnInit(): void {
      //this.loadTabComponent("ALERTS");
     // this.getAllAlert();
     this.userAccessData =this.api.getUserScreenAccessMenu('ActiveIncident')
    }

    // loadTabComponent(_selectedTab: string) {
    //     this._component = "";
    //     if (_selectedTab == "ALERTS")
    //       this._component = AlertMasterComponent;
    //    else if (_selectedTab == "EXCEPTION")
    //       this._component = ExceptionMasterComponent;


    //     this.viewContainerRef.detach();
    //     this.viewContainerRef.clear();
    //     const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this._component);
    //     let componentRef = this.viewContainerRef.createComponent(componentFactory);
    //     this.componentsList.push(componentRef);
    //   }
      getAllAlert() {
        this.spinner.show();
        this.kemarClientService.get<any>(null, ServiceUrl.getActiveAlerts)
          .subscribe(
            response => {
              this.spinner.hide();
              this.alertMaster = response;
              this.alertCount = response.alertTicketCount.alertCount;
              this.ticketCount = response.alertTicketCount.ticketCount;
            },
            r => {
              this.spinner.hide();
              alert(r.error.error);
              // console.log(r.error.error);
            });
      }

      tab(event: any){

        this.title=event.tab.textLabel
      }

}
