import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faAnchor, faEdit, faMapPin, faObjectGroup, faPlus, faSort, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import { MyIncidentModel } from 'src/app/models/ticketmodel';
import { pairwise, startWith } from 'rxjs/operators'
import { UTStatusService } from '../../service/statusdata.service';
import { debug } from 'console';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';


@Component({
  selector: 'app-myincident',
  templateUrl: './myincident.component.html',
  styleUrls: ['./myincident.component.scss']
})

export class MyIncidentComponent implements OnInit {

  location: any;
  tickets: any;
  ticketHistoryData: any;
  ticketDetails: any;
  ticketUrgency: any;
  ticketAssignUser: any;
  utStatus: any;
  subcategory: any;
  category: any;
  title: any;
  submitted = false;
  header: string = 'Add Incident';
  Save: string = 'Save';
  resetbuttonShow: boolean = true;
  cancelform :UntypedFormGroup;
  public myIncident: MyIncidentModel = new MyIncidentModel();
  public userAccessData: UserScreenAccesData;

  // Icons and controls
  form: UntypedFormGroup;
  faEdit = faEdit;
  faTimes = faTimes
  faAnchor = faAnchor
  faPlus = faPlus
  faSort = faSort
  faInfo = faInfo;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private utStatusService: UTStatusService,
    private modalService: NgbModal,
    private api:DataService) { }

  ngOnInit(): void {
    this.cancelform = this.formBuilder.group({
      remarks: ['',[Validators.required]],
      status: ['',[Validators.required]],
    })
    this.form = this.formBuilder.group(
      {
        ticketNo: [
          '',
        ],
        raisedUserId: [
          0,
        ],
        locationName: ['', [Validators.required]],
        ticketUrgencyId: [0, [Validators.required]],
        categoryId: [0, [Validators.required]],
        subcategoryId: [0, [Validators.required]],

        ticketSubject: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(200)
            // Validators.pattern("[a-zA-Z]*")
          ]
        ],
        ticketDescription: [
          '',
          [
            Validators.required
          ]
        ],

        assignedUserId: [0]
      },
    );
    this.utStatus = this.utStatusService.getControlTowerUserIncidentStatuses();
    this.userAccessData =this.api.getUserScreenAccessMenu('My Incident');
    this.getMyIncidents();
    this.getCommonMethods();
  }

  getCommonMethods() {
    this.getAllLocation();
    this.getAllUrgency();
    this.getAllCategory();
    this.getAllUser();
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get cancelValidation(): { [key: string]: AbstractControl } {
    return this.cancelform.controls;
  }


  getAllLocation() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllLocationMapping)
      .subscribe(
        response => {
          this.spinner.hide();
          this.location = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  getAllUrgency() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getTicketUrgency)
      .subscribe(
        response => {
          this.spinner.hide();
          this.ticketUrgency = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  getAllCategory() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllCategory)
      .subscribe(
        response => {
          this.spinner.hide();
          this.category = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }

  getSubCategoryByCategory(categoryId: number) {
    if (typeof (categoryId) === 'undefined' || categoryId == 0) return;
    if (categoryId == 0) {
      this.myIncident.subCategoryId = 0;
    }

    this.spinner.show();
    this.kemarClientService.getbyId<any>(categoryId, ServiceUrl.getSubCategorybyCategoryId)
      .subscribe(
        response => {
          this.spinner.hide();
          this.subcategory = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }


  onReset() {
    this.submitted = false;
    this.form.reset();
    this.form.controls['locationName'].setValue(null);
    this.form.controls['ticketUrgencyId'].setValue(null);
    this.form.controls['categoryId'].setValue(null);
    this.form.controls['subcategoryId'].setValue(null);
  }

  getMyIncidents() {

    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getMyIncidentTicket)
      .subscribe(
        response => {

          this.tickets = response;
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }


  getTicketDetailHistory(content: any, ticketId: number) {
    this.spinner.show();
    var query = { ticketId: ticketId }
    this.kemarClientService.get<any>(null, ServiceUrl.getTicketHistory, query)
      .subscribe(
        response => {
          this.ticketHistoryData = response;
          this.modalService.open(content, this.ngbModalOptions);
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  open(content: any) {

    this.submitted = false;
    this.onReset();
    this.header = 'Add Exception';
    this.Save = 'Save';
    this.myIncident = new MyIncidentModel();
    this.myIncident.categoryId = null;
    this.myIncident.priority = null;
    this.myIncident.locationName = null;
    this.myIncident.locationId = 0;
    this.myIncident.subCategoryId = null;
    this.myIncident.ticketSubject = '';
    this.myIncident.TicketDescription = '';
    this.myIncident.ticketId = 0;
    this.myIncident.status = "Initiated";
    this.form.get('locationName').enable();
    this.form.get('ticketUrgencyId').enable();
    this.form.get('categoryId').enable();
    this.form.get('subcategoryId').enable();
    this.resetbuttonShow = true;
    this.modalService.open(content, this.ngbModalOptions);
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  editTicketDetails(content: any, columnData: any) {

    this.submitted = false;
    this.onReset();
    this.getCommonMethods();
    this.getLocationIdOnName(columnData.locationName);
    this.myIncident.categoryId = columnData.categoryId;
    this.getSubCategoryByCategory(this.myIncident.categoryId);
    this.myIncident.subCategoryId = columnData.subCategoryId;
    this.myIncident.priority = columnData.priority;
    this.myIncident.ticketSubject = columnData.ticketSubject;
    this.myIncident.TicketDescription = columnData.ticketDescription;
    this.myIncident.status = columnData.status;
    this.myIncident.ticketId = columnData.ticketId;
    this.myIncident.remarks = columnData.remarks;
    this.myIncident.locationName = columnData.locationName;
    this.myIncident.assignedUserId = columnData.assignedUserId;
    this.form.get('locationName').disable();
    this.form.get('ticketUrgencyId').disable();
    this.form.get('categoryId').disable();
    this.form.get('subcategoryId').disable();
    this.header = 'Update Exception';
    this.Save = 'Update';
    this.resetbuttonShow = false
    this.modalService.open(content, this.ngbModalOptions);
  }

  openCancelCloseTicketModal(content: any, ticket: any) {
    this.submitted = false;

    this.myIncident.categoryId = ticket.categoryId;
    this.myIncident.subCategoryId = ticket.subCategoryId;
    this.myIncident.priority = ticket.priority;
    this.myIncident.ticketId = ticket.ticketId;
    this.myIncident.ticketSubject = ticket.ticketSubject;
    this.myIncident.TicketDescription = ticket.ticketDescription;
    this.myIncident.locationName = ticket.locationName;
    this.myIncident.status = '';
    this.myIncident.remarks = '';
    this.modalService.open(content, this.ngbModalOptions);
  }

  getAllUser() {
    this.spinner.show();
    var userType = { "RoleGroup": "ControlTower" }
    this.kemarClientService.get(null, ServiceUrl.getUserbyRoll, userType)
      .subscribe(
        response => {
          this.spinner.hide();
          this.ticketAssignUser = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  registerOrUpdateIncident(content: any, myIncident: any) {
    this.submitted = true;

    if(myIncident.ticketId > 0){
      this.form.controls['ticketDescription'].setValue(myIncident.TicketDescription);
      this.form.controls['locationName'].setValue(myIncident.locationName);
      this.form.controls['ticketSubject'].setValue(myIncident.ticketSubject);
      this.form.controls['categoryId'].setValue(myIncident.categoryId);
      this.form.controls['subcategoryId'].setValue(myIncident.subCategoryId);
      this.form.controls['ticketUrgencyId'].setValue(myIncident.priority);
      if(content){
        if (this.cancelform.invalid) {
          return;
        }
      }
    }

    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.GenerateServiceTicket, this.myIncident)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success');
          this.onReset();
          this.closeModalPopup();
          this.getMyIncidents();
        },
        r => {
          this.spinner.hide();
          Swal.fire('', r.error.errorMessage, 'error');
        });

    this.spinner.hide();
  }

  getLocationNameOnId(locationId: number) {

    this.myIncident.locationName = this.location.filter(v => v.locationId == locationId)[0].locationName;
  }

  getLocationIdOnName(locationName: number) {
    this.myIncident.locationId = this.location.filter(v => v.locationName == locationName)[0].locationId;
  }

}
