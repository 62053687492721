<app-header></app-header>
<section class="my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body custom-table">
        <h4 class="title-one mb-2">Simulation</h4>
            <h3 class="mt-3">Loading Simulation</h3>   

        
            <form autocomplete="off" [formGroup]="form" class="d-flex row align-items-end justify-content-start mt-3">
                <div class="form-group ml-3">
                    <input type="hidden" minlength="3" maxlength="30" class="form-control" name="RFIDTagNo"
                    formControlName="RFIDTagNo" [(ngModel)]="RFIDSimulation.RFIDTagNo" />
                </div>
                <div class="col-md-3">
                        <label for="">Select Location</label>
                        <select name="deviceLocationId" class="form-control form-select" formControlName="deviceLocationId"
                        [ngClass]="{ 'is-invalid': submitted && f.deviceLocationId.errors }"
                        [(ngModel)]="RFIDSimulation.deviceLocationId">
                        <option hidden [ngValue]=null>--Choose Option--</option>
                        <option *ngFor="let location of deviceMaster" [ngValue]="location.deviceLocationId">
                            {{location.deviceName}}</option>
                        </select>
                </div>
                <div class="col-md-2">
                    <label for="description">Vehicle Number</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }" formControlName="vrn"
                    [(ngModel)]="RFIDSimulation.vrn" />
                </div>
                <div class="col-md-2">
                    <label for="description">Remarks</label>
                    <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.additionalComment.errors }" formControlName="additionalComment"
                    [(ngModel)]="RFIDSimulation.additionalComment" />
                </div>

                <div class="col-md-2">
                    <button class="btn btn-success px-4" (click)="PostRFIDTAG()">Send</button>
                </div>
            </form>
      
    </div>
  </div>
  </div>
</section>

  <app-footer></app-footer>