import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.html'
})
export class AppFooterComponent implements OnInit {
  version: any;
  projectCurrentVersion: any = 1.0;
  constructor(private router: Router, private kemarClientService: KemarClientService,) {
  }
  ngOnInit(): void {
    this.getVersion();
  }
  tryLogout() {
    this.router.navigateByUrl('/login');
  }
  getVersion(){
    this.kemarClientService.get<any>(null, ServiceUrl.getProjectCurrentVersion)
    .subscribe(
      response => {
        this.projectCurrentVersion = response.projectCurrentVersion;
        // console.log(this.projectCurrentVersion);
      },
      r => {
      });
  }
}

