<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="col-sm-4 mt-2 p-2 pb-0">
        <h4 class="title-one mb-0"> Vehicle Trips - Outbound/Inbound</h4>
      </div>
  <mat-tab-group animationDuration="10ms" mat-align-tabs="end" >
    <mat-tab label="Active" >
          <div class="card-body">
            <div class="d-flex data-filter align-items-center my-2">
              <!-- <div class="col-sm-8 text-end">
                <div class="input-append">
                  <span class="material-icons-sharp icon-in">search</span>
                  <input class="form-control" type="text" name="searchtext" placeholder="search" [(ngModel)]="pagination.searchtext">
                </div>
                <button mat-button matSuffix mat-stroked-button type="submit" class="modal-button btn btn-primary"
                  (click)="GetTransactionByVrn(pagination.searchtext)">
                <span class="material-icons-round">search</span>
                </button>
              </div> -->
              <div class="col-4 text-start">
                <div class="input-append me-0">
                  <span class="material-icons-sharp icon-in">search</span>
                  <input class="form-control" type="search" name="searchtext" (keydown)="EnterBtn($event)" placeholder="search by VRN" [(ngModel)]="filter"
                  (ngModelChange)="currentActivePage = 1">
                </div>
                <!-- <button class="btn btn-primary go-btn mx-2" (click)="SearchFilter()" matTooltip="Search"
                  matTooltipClass="danger-tooltip">
                  <fa-icon [icon]="faSearch"></fa-icon>
                  </button> -->
                <!-- <button class="btn btn-primary go-btn" type="submit" (click)="GetTransactionByVrn(pagination.searchtext)">
                <span class="material-icons-round">search</span>
                </button> -->
              </div>
              <div class="col-8 text-end">
                <div class="input-append ">
                <select name="tranType"
                class="form-control " name="tranType" id="tranType" (change)="GetAllVehilceTransaction(0)" [(ngModel)]="tranTypeActive"  autocomplete="">
                    <option value='0'>-- All Tran Type --</option>
                    <option value ='1' >Outbound</option>
                    <option value ='2' >Inbound</option>
                    <option value ='4' >InPlant</option>
                </select>
                </div>
                <!-- <label for="fromDate" class="mr-2">Tran Type:</label> -->
                <div class="input-append me-0">
                    <button class="btn btn-success go-btn mx-1" (click)="getActiveDataForExcel()">
                    <span class="material-icons-round">download</span>
                    </button>
                </div>
                </div>
            </div>
            <div class="table-list custom-table">
              <div class="table-responsive">
                <table class="table table-striped table-hover mx-auto tbl-formating">
                  <thead class="table-head">
                    <tr>
                      <th (click)="sort('vrn')">VRN
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('vehicleTransactionCode')">Trip No
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vehicleTransactionCode'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('tranType')"> Type
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='tranType'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('driverName')">Driver Name
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='driverName'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('entryDateTime')">Parking In
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='entryDateTime'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('exitDateTime')">Parking Out
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='exitDateTime'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('transactionStartTime')">Plant In
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='transactionStartTime'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <!-- <th (click)="sort('transactionEndTime')">Plant Out
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='transactionEndTime'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th> -->
                      <th (click)="sort('previousMilestone')">Last Milestone
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='previousMilestone'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('currentMilestone')">Open Milestone
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='currentMilestone'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('tranStatus')">Status
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='tranStatus'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr
                      *ngFor="let Dashdata of transactionResponse | paginate: { id:'transactionPagination', itemsPerPage: pageSize, currentPage: currentActivePageCompleted, totalItems: totalRecords }; let i = index">

                      <td class="containerFirst"> <a [routerLink]="['/current-transaction', Dashdata.vehicleTransactionCode]">
                          {{Dashdata.vrn}}</a>
                          <span *ngIf="Dashdata.rfidTagNumber != null && Dashdata.rfidTagNumber != ''" class="tagIcon"
                          matTooltipPosition="above" matTooltip="{{Dashdata.rfidTagNumber == '' ? 'Tag not found' : Dashdata.rfidTagNumber}}" >
                            RFID
                            </span>
                      </td>
                      <td>{{Dashdata.vehicleTransactionCode}}</td>
                      <td>
                        <span *ngIf="Dashdata.tranType ==1 ">Outbound</span>
                        <span *ngIf="Dashdata.tranType ==2">Inbound</span>
                        <span *ngIf="Dashdata.tranType ==4">In-Plant</span>
                      </td>
                      <td>{{Dashdata.driverName}}</td>
                       <td>{{Dashdata.entryDateTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                      <td>{{Dashdata.exitDateTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                      <td>{{Dashdata.transactionStartTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                      <!-- <td>{{Dashdata.transactionEndTime |date: 'dd-MM-yyyy HH:mm'}}</td> -->
                      <td>{{Dashdata.previousMilestone | titlecase }}</td>
                      <td>{{Dashdata.currentMilestone | titlecase }}</td>
                      <td>
                      <span
                          *ngIf="Dashdata.transactionEndTime==null && Dashdata.transactionStartTime== null">Pending</span>
                        <span
                          *ngIf="Dashdata.transactionStartTime !=null && Dashdata.transactionEndTime ==null">InTransist</span>
                        <span *ngIf="Dashdata.transactionStartTime !=null && Dashdata.transactionEndTime !=null">Completed</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
              <!-- <div class="pagination justify-content-end">
                <pagination-controls id="transactionPagination" (pageChange)="currentActivePage = $event"></pagination-controls>
              </div> -->
              <div class="row">
                <div class="col-md-6">
                  Item per page
                  <select (change)="OnPageChange(pageSize = $event.target.value)" class="paginationcount">
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                  <span> Total Records : {{totalRecords}} </span>
                </div>
                <div class="col-md-6 text-end">
                  <div class="pagination justify-content-end">
                    <pagination-controls (pageChange)="GetAllVehilceTransaction(currentActivePageCompleted = $event) " id="transactionPagination">
                    </pagination-controls>
                  </div>
                </div>
              </div>
           </div>
          </div>
    </mat-tab>

    <mat-tab label="Completed" >
          <div class="card-body">
            <div class="d-flex data-filter align-items-center my-2">
              <div class="col-md-4">
                <div class="input-append me-0">
                  <span class="material-icons-sharp icon-in">search</span>
                  <input class="form-control" type="search" name="searchtext" (keydown)="EnterCompletedBtn($event)" placeholder="search by VRN" [(ngModel)]="filterCompleted"
                 >
                </div>
              </div>

              <div class="col-sm-8 text-end">
                <div class="input-append ">
                <select name="tranType"
                class="form-control" name="tranType" id="tranType" [(ngModel)]="tranTypeCompleted"  autocomplete="">
                    <option value='0'>-- All Tran Type --</option>
                    <option value ='1' >Outbound</option>
                    <option value ='2' >Inbound</option>
                    <option value ='4' >InPlant</option>
                </select>
                </div>
                <div class="input-append ">
                  <!-- <label for="FromDate">From:</label> -->
                  <input class="form-control date" type="date" name="FromDate" [(ngModel)]="FromDate" id="fromDate" max="{{today | date:'yyyy-MM-dd'}}">
                </div>
                <div class="input-append  my-2">
                  <!-- <label for="ToDate">To:</label> -->
                  <input class="form-control date" type="date" name="ToDate" [(ngModel)]="ToDate" id="toDate" max="{{today | date:'yyyy-MM-dd'}}">
                </div>

                <button class="btn btn-primary go-btn mx-2" (click)="getComplatedData(1)" matTooltip="Search"
                  matTooltipClass="danger-tooltip">
                  <fa-icon [icon]="faSearch"></fa-icon>
                  </button>
                  <div class="input-append me-0">
                    <button class="btn btn-success go-btn mx-1" (click)="getComplatedDataForExcel()">
                    <span class="material-icons-round">download</span>
                </button>
                </div>
              </div>
            </div>
            <div class="table-list custom-table">
              <div class="table-responsive">
                <table class="table table-striped table-hover mx-auto tbl-formating">
                  <thead class="table-head">
                    <tr>

                      <th (click)="sort('vrn')">VRN
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('vehicleTransactionCode')">Trip No
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vehicleTransactionCode'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('tranType')"> Type
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='tranType'"
                          [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('driverName')">Driver Name
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='driverName'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('entryDateTime')">Parking In
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='entryDateTime'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('exitDateTime')">Parking Out
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='exitDateTime'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <th (click)="sort('transactionStartTime')">Plant In
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='transactionStartTime'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>
                      <th (click)="sort('transactionEndTime')">Plant Out
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='transactionEndTime'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th>

                      <!-- <th (click)="sort('tranStatus')">Status
                        <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='tranStatus'"
                          [ngClass]='glyphicon-chevron-up'>
                          <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                        </span>
                      </th> -->
                    </tr>
                  </thead>
                  <tbody>

                    <tr
                      *ngFor="let Dashdata of complatedTransactionResponse | paginate: { id:'CompletedtransactionPagination', itemsPerPage: pageSizeCompleted, currentPage: currentCompletedPage, totalItems: CompletedTotalRecord }; let i = index">

                      <td> <a [routerLink]="['/current-transaction', Dashdata.vehicleTransactionCode]">
                          {{Dashdata.vrn}}</a>
                      </td>
                      <td>{{Dashdata.vehicleTransactionCode}}</td>
                      <td>
                        <span *ngIf="Dashdata.tranType ==1 ">Outbound</span>
                        <span *ngIf="Dashdata.tranType ==2">Inbound</span>
                        <span *ngIf="Dashdata.tranType ==4">In-Plant</span>
                      </td>
                      <td>{{Dashdata.driverName}}</td>
                       <td>{{Dashdata.entryDateTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                      <td>{{Dashdata.exitDateTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                      <td>{{Dashdata.transactionStartTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                      <td>{{Dashdata.transactionEndTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                      <!-- <td>
                      <span
                          *ngIf="Dashdata.transactionEndTime==null && Dashdata.transactionStartTime== null">Pending</span>
                        <span
                          *ngIf="Dashdata.transactionStartTime !=null && Dashdata.transactionEndTime ==null">InTransist</span>
                        <span *ngIf="Dashdata.transactionStartTime !=null && Dashdata.transactionEndTime !=null">Completed</span>
                      </td> -->
                    </tr>
                  </tbody>
                </table>

              </div>
              <!-- <div class="pagination justify-content-end">
                <pagination-controls id="transactionPagination" (pageChange)="currentActivePage = $event"></pagination-controls>
              </div> -->
              <div class="row">
                <div class="col-md-6">
                  Item per page
                  <select (change)="OnPageChangeCompleted(pageSizeCompleted = $event.target.value)" class="paginationcount">
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                  </select>
                  <span> Total Records : {{CompletedTotalRecord}} </span>
                </div>
                <div class="col-md-6 text-end">
                  <div class="pagination justify-content-end">
                    <pagination-controls (pageChange)="GetAllVehilceTransactionCompleted(currentCompletedPage = $event) " id="CompletedtransactionPagination">
                    </pagination-controls>
                  </div>
                </div>
              </div>
           </div>
          </div>

    </mat-tab>

    <mat-tab label="Cancelled" >
      <div class="card-body">
        <div class="d-flex data-filter align-items-center my-2">
          <div class="col-md-4">
            <div class="input-append me-0">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="searchtext" value="" (keydown)="EnterCancelledBtn($event)" placeholder="search by VRN" [(ngModel)]="filterCancelled"
             >
            </div>
          </div>

          <div class="col-sm-8 text-end">
            <div class="input-append ">
            <select name="tranType"
            class="form-control" name="tranType" id="tranType" [(ngModel)]="tranTypeCancelled"  autocomplete="">
                <option value='0'>-- All Tran Type --</option>
                <option value ='1' >Outbound</option>
                <option value ='2' >Inbound</option>
                <option value ='4' >InPlant</option>
            </select>
            </div>
            <div class="input-append ">
              <input class="form-control date" type="date" name="FromDate" [(ngModel)]="FromDateComplete" id="fromDate" max="{{today | date:'yyyy-MM-dd'}}">
            </div>
            <div class="input-append  my-2">
              <input class="form-control date" type="date" name="ToDate" [(ngModel)]="TodayComplete" id="toDate" max="{{today | date:'yyyy-MM-dd'}}">
            </div>

            <button class="btn btn-primary go-btn mx-2" (click)="getCancelledData(1)" matTooltip="Search"
              matTooltipClass="danger-tooltip">
              <fa-icon [icon]="faSearch"></fa-icon>
              </button>
              <div class="input-append me-0">
                <button class="btn btn-success go-btn mx-1" (click)="getCancelledDataForExcel()">
                <span class="material-icons-round">download</span>
            </button>
            </div>
          </div>
        </div>
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-hover mx-auto tbl-formating">
              <thead class="table-head">
                <tr>

                  <th (click)="sort('vrn')">VRN
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vrn'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('vehicleTransactionCode')">Trip No
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='vehicleTransactionCode'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('tranType')"> Type
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='tranType'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('driverName')">Driver Name
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='driverName'"
                      [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('entryDateTime')">Parking In
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='entryDateTime'"
                      [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('exitDateTime')">Parking Out
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='exitDateTime'"
                      [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('transactionStartTime')">Plant In
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='transactionStartTime'"
                      [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('transactionEndTime')">Plant Out
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='transactionEndTime'"
                      [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let Dashdata of cancelledResponse | orderBy: key : reverse  | paginate: { id:'cancelPagination', itemsPerPage: pageSizeCancelled, currentPage: currentPageCancelled, totalItems: CancelledTotalRecord }; let i = index">

                  <td> <a [routerLink]="['/current-transaction', Dashdata.vehicleTransactionCode]">
                      {{Dashdata.vrn}}</a>
                  </td>
                  <td>{{Dashdata.vehicleTransactionCode}}</td>
                  <td>
                    <span *ngIf="Dashdata.tranType ==1 ">Outbound</span>
                    <span *ngIf="Dashdata.tranType ==2">Inbound</span>
                    <span *ngIf="Dashdata.tranType ==4">In-Plant</span>
                  </td>
                  <td>{{Dashdata.driverName}}</td>
                   <td>{{Dashdata.entryDateTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                  <td>{{Dashdata.exitDateTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                  <td>{{Dashdata.transactionStartTime |date: 'dd-MM-yyyy HH:mm'}}</td>
                  <td>{{Dashdata.transactionEndTime |date: 'dd-MM-yyyy HH:mm'}}</td>

                </tr>
              </tbody>
            </table>

          </div>

          <div class="row">
            <div class="col-md-6">
              Item per page
              <select (change)="OnPageChangeCancelled(pageSizeCancelled = $event.target.value)" class="paginationcount">
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>
              <span> Total Records : {{CancelledTotalRecord}} </span>
            </div>
            <div class="col-md-6 text-end">
              <div class="pagination justify-content-end">
                <pagination-controls (pageChange)="GetAllVehilceTransactionCancelled(currentPageCancelled = $event) " id="cancelPagination">
                </pagination-controls>
              </div>
            </div>
          </div>
       </div>
      </div>

</mat-tab>


  </mat-tab-group>
</div>
</div>
</section>
<app-footer></app-footer>
