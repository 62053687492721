<ngx-spinner> </ngx-spinner>
<section class="forgot">
    <div class="container h-100">
        <div class="d-flex align-items-center justify-content-center h-100">
            <div class="card">
                <div class="card-body">
                    <img src="" class="main-logo text-center" alt="">
                    <form *ngIf="!showPasswordSetDialog" #UserNameForm="ngForm" (ngSubmit)="getOTP()" autocomplete="off">
                      <div class="input-group form-group mt-2">
                        <div class="input-group-prepend">
                          <span class="material-icons">lock</span>
                        </div>
                        <input class="form-control form-control-user" aria-describedby="emailHelp" id="username" name="username"
                          [(ngModel)]="formModel.userName" #frmusername="ngModel" required maxlength=20
                          placeholder="Enter User Name...">
          
                        <div *ngIf="frmusername.invalid && (frmusername.dirty || frmusername.touched)"
                          class="alert input-bar text-danger">
          
                          <div *ngIf="frmusername.errors?.required">
                            User Name is required.
                          </div>
          
                          <div *ngIf="frmusername.errors?.maxlength">
                            User Name must not be more than 20 characters long.
                          </div>
                        </div>
                    </div>
                      <div class="d-flex justify-content-end">
                        <button class="btn btn-primary login-btn" [disabled]="!UserNameForm.form.valid" type="submit"> Get OTP </button>
                      </div>
                    </form>
                    <form *ngIf="showPasswordSetDialog" #SetNewPasswordForm="ngForm" (ngSubmit)="setNewPasswordFormSubmit()"
                      autocomplete="off">
                      <div class="input-group form-group mt-2 ">Please enter the OTP received on your email and then set new
                        Password</div>
                      <div class="input-group form-group mt-2 " *ngIf="enableOTPOnUI">{{otpmessage}}</div>
                      <div class="input-group form-group mt-2 ">
                        <div class="input-group-prepend">
                          <span class="material-icons">lock</span>
                        </div>
                        <input type="password" class="input-bar form-control form-control-user" aria-describedby="otp" id="otp"
                          name="otp" [(ngModel)]="formModel.otp" #OtpFrm="ngModel" required minlength=6 maxlength=6
                          placeholder="OTP...">
          
                        <div *ngIf="OtpFrm.invalid && (OtpFrm.dirty || OtpFrm.touched)">
                          <div *ngIf="OtpFrm.errors?.required" class="text-danger">
                            OTP is required.
                          </div>
                          <div *ngIf="OtpFrm.errors?.minlength" class="text-danger">
                            OTP must be at least 6 characters long.
                          </div>
                        </div>
                      </div>
                      <div class="input-group form-group mt-2 ">
                        <div class="input-group-prepend">
                          <span class="material-icons">lock</span>
                        </div>
                        <input type="password" class="form-control input-bar form-control-user" aria-describedby="newPassword"
                          id="newPassword" name="newPassword" [(ngModel)]="formModel.newPassword" #newPasswordfrm="ngModel"
                          required minlength=6 maxlength=20 placeholder="Enter New Password.">
          
                        <div *ngIf="newPasswordfrm.invalid && (newPasswordfrm.dirty || newPasswordfrm.touched)">
                          <div *ngIf="newPasswordfrm.errors?.required" class="text-danger">
                            Password is required.
                          </div>
                          <div *ngIf="newPasswordfrm.errors?.minlength" class="text-danger">
                            Password must be at least 6 characters long.
                          </div>
                        </div>
                      </div>
                      <div class="input-group form-group mt-2 ">
                        <div class="input-group-prepend">
                          <span class="material-icons">lock</span>
                        </div>
                        <input type="password" class="form-control input-bar form-control-user" aria-describedby="confirmPassword"
                          id="confirmPassword" name="confirmPassword" [(ngModel)]="formModel.confirmPassword"
                          #confirmPasswordfrm="ngModel" required minlength=6 maxlength=20 placeholder="Confirm Password...">
                        <div *ngIf="confirmPasswordfrm.invalid && (confirmPasswordfrm.dirty || confirmPasswordfrm.touched)">
          
                          <div *ngIf="confirmPasswordfrm.errors?.required" class="text-danger">
                            Confirm Password is required.
                          </div>
                          <div *ngIf="confirmPasswordfrm.errors?.minlength" class="text-danger">
                            Confirm Password must be at least 6 characters long.
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-end">
                        <button class="btn btn-primary login-btn" [disabled]="!SetNewPasswordForm.form.valid" type="submit"
                    > Reset Password </button>
                      </div>
                    </form>
                    <p class="copyright text-center"> Powered by <a href="https://www.kemarautomation.in/"> Kemar Port
                      Automation Pvt. Ltd.</a></p>
               </div>
            </div>
        </div>
    </div>
</section>
