import { Component, OnInit, ViewChild } from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { faDoorClosed, faEdit, faPlus, faSort, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, FormBuilder, FormControl, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { TransactionSLAMasterResponse } from 'src/app/models/transactionSLAmodel';
import { MatTableDataSource } from '@angular/material/table';
import { LocationMappingResponse } from 'src/app/models/locationmapping';
import { MilestoneActionMappingResponse } from 'src/app/models/milestoneActionMappingModel';
import { TransitSLAResponse } from 'src/app/models/TransitSLAResponse';
import { $ } from 'protractor';
import { Pagination } from 'src/app/models/pagination';
import { MatPaginator } from '@angular/material/paginator';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-service-ticket-sla',
  templateUrl: './service-ticket-sla.component.html',
  styleUrls: ['./service-ticket-sla.component.scss']
})
export class ServiceTicketSLAComponent implements OnInit {
  transactionSLAmaster: any[];

  filter: '';
  title: any;
  form: FormGroup;
  public ticketmaster: TransactionSLAMasterResponse = new TransactionSLAMasterResponse();
  public userAccessData: UserScreenAccesData;



  faEdit = faEdit; faTimes = faTimes; faPlus = faPlus; faSort = faSort; faTruck = faTruck; faDoorClosed = faDoorClosed
  ServiceTicketSLAResponse: ServiceTicketSLAComponent;
  pageSize = 15;
  reverse: boolean = false;
  currentdate: string;
  submitted = false;
  key: any;
  ActiveButtonShow: boolean = false;
  EventList: any;
  milestoneMaster: any[];
  ticketUrgency: any[];
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  currentActivePage: number = 1;
  @ViewChild('closebutton') closebutton;
  resetbuttonShow: boolean = true;


  constructor(private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,

    private formBuilder: FormBuilder,
    private modalService: NgbModal, private api:DataService) {
    this.form = this.formBuilder.group
      ({
        isActive: [''],
        transactionSLAmasterId: [''],
        eventCode: ['', [Validators.required, this.noWhitespaceValidator]],
        eventName: ['', [Validators.required]],
        alertTimeInMinutes: [0, [Validators.required, Validators.pattern('[1-9][0-9]*')]],
        exceptionTimeInMinutes: [0, [Validators.required, Validators.pattern('[1-9][0-9]*')]],
        ticketSLAInMinutes: [0, [Validators.required, Validators.pattern('[1-9][0-9]*')]],
        subject: ['', [Validators.required]],
        description: ['', [Validators.required]],
        ticketPriority: ['', [Validators.required]],
      });
      this.userAccessData =this.api.getUserScreenAccessMenu('SLA Master');
  }
  ngOnInit(): void {
    this.getAllUrgency();
    this.getAllSLATransactionTicket();
    this.getAllMilestone();

  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }
  onReset() {
    this.submitted = false;
    this.form.reset();
    this.ticketmaster.isActive=true;
    this.form.controls['eventCode'].setValue('');
    this.form.controls['ticketPriority'].setValue('');
  }



  onChange(eventCode: any) {

    // console.log(eventCode)

  }

  acceptnumber(event: any, flag: boolean) {
    if (flag) {
      this.kemarClientService.keyPressNumbers(event);
    }

  }

  getAllSLATransactionTicket() {

    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllSLATransactionTicket)
      .subscribe(
        response => {
          this.transactionSLAmaster = response;
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }
  registerTransactionSLA() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerTransactionSLA, this.ticketmaster)
      .subscribe(
        (res) => {

          Swal.fire('', res.responseMessage, 'success');
          this.getAllSLATransactionTicket();
          this.closeModalPopup();
          this.spinner.hide();
        },
        (r) => {

          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          })
          this.spinner.hide();
          // console.log(r.error.error);
        }
      );
  }
  getAllMilestone() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllMilestone)
      .subscribe(
        response => {

          this.spinner.hide();
          this.milestoneMaster = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  getAllUrgency() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getTicketUrgency)
      .subscribe(
        response => {
          this.spinner.hide();
          this.ticketUrgency = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

   open(content: any,) {

    this.onReset();
    this.title = "Add SLA";
    this.ticketmaster.transactionSLAMasterId = 0;
    this.ticketmaster.eventCode = '';
    this.ticketmaster.eventName = '';
    this.ticketmaster.alertTimeInMinutes = null;
    this.ticketmaster.exceptionTimeInMinutes = null;
    this.ticketmaster.ticketSLAInMinutes = null;
    this.ticketmaster.subject = '';
    this.ticketmaster.description = '';
    this.ticketmaster.ticketPriority = '';
    this.ticketmaster.isActive = true;
    this.ActiveButtonShow = true;
    this.resetbuttonShow = true;
    this.form.get('eventCode').enable();
    this.modalService.open(content, this.ngbModalOptions);


  }
  ngbModalOptions: NgbModalOptions =
    {
      backdrop: 'static',
      keyboard: false
    };
  editSLA(content: any, ticketmaster) {
    this.onReset();
    this.title = "Update SLA";
    this.ticketmaster.transactionSLAMasterId = ticketmaster.transactionSLAMasterId;
    this.ticketmaster.eventCode = ticketmaster.eventCode;
    this.ticketmaster.eventName = ticketmaster.eventName;
    this.ticketmaster.alertTimeInMinutes = ticketmaster.alertTimeInMinutes;
    this.ticketmaster.exceptionTimeInMinutes = ticketmaster.exceptionTimeInMinutes;
    this.ticketmaster.ticketSLAInMinutes = ticketmaster.ticketSLAInMinutes;
    this.ticketmaster.ticketPriority = ticketmaster.ticketPriority;
    this.ticketmaster.subject = ticketmaster.subject;
    this.ticketmaster.description = ticketmaster.description;
    this.ticketmaster.isActive = ticketmaster.isActive;
    this.form.get('eventName').setValue(ticketmaster.eventName);
    this.form.get('eventCode').disable();

    this.ActiveButtonShow = false,
      this.resetbuttonShow = false,
      this.modalService.open(content, this.ngbModalOptions);

  }
  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  ChangeMilestoneName() {

    var milestoneCode = this.form.get('eventCode').value;
    var SelectedMilestone = this.milestoneMaster.filter(milestone => milestone.milestoneCode == milestoneCode)
    this.form.get('eventName').setValue(SelectedMilestone[0].milestoneName + '-' + SelectedMilestone[0].milestoneEvent);
    this.ticketmaster.eventName = SelectedMilestone[0].milestoneName + '-' + SelectedMilestone[0].milestoneEvent;



  }

}







