<ngx-spinner> </ngx-spinner>
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-sm-4">
    <div class="input-append">
      <span class="material-icons-sharp icon-in">search</span>
      <input class="form-control" type="search" name="search" placeholder="Search" [(ngModel)]="filter"
      (keyup)="currentActivePage=1">
    </div>
  </div>
  
</div>
<div class="table-list custom-table">
  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover mx-auto">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>
          <th (click)="sort('name')" class="">Driver Name
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          <th (click)="sort('name')" class="">DL No.
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Blacklisted
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Mobile No.
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Reason
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Incident Date
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Created By
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="col w-25">Description
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <input type="hidden" />
        <tr
          *ngFor="let driverBlacklisting of BlackList | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage, id: 'driverBlack' }; let i = index">
          <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
          <td>{{driverBlacklisting.name}}</td>
          <td>{{driverBlacklisting.dlNo }}</td>
          <td>
            <span *ngIf="driverBlacklisting.status.toString().toUpperCase() == 'TRUE'">YES</span>
            <span *ngIf="driverBlacklisting.status.toString().toUpperCase() == 'FALSE'">NO</span>
          </td>
          <td>{{driverBlacklisting.mobileNo }}</td>
          <td>{{driverBlacklisting.reason}}</td>
          <td>{{driverBlacklisting.incidentDate| date: 'dd-MM-yyyy'}}</td>
          <td>{{driverBlacklisting.created}}</td>
          <td>{{driverBlacklisting.description}}</td>
          <!-- <td>{{driverBlacklisting.dlValidaty | date: 'dd-MM-yyyy'}}</td> -->
          </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination justify-content-end">
  <pagination-controls id="driverBlack" (pageChange)="currentActivePage = $event"></pagination-controls>
</div>

<!-- 
<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="row">
          <div class="col-sm-6">
            <label for="locationName">Driver Name</label>
            <input type="text" class="form-control" placeholder="" value=" "
              [ngClass]="{ 'is-invalid': submitted && f.driverName.errors }" formControlName="driverName"
              [(ngModel)]="driverBlacklisting.driverName" />
            <div *ngIf="submitted && f.driverName.errors" class="invalid-feedback">
              <div *ngIf="f.driverName.errors.required">Location name is required.
              </div>
              <div *ngIf="f.driverName.errors.minlength">
                Location name must be at least 3 characters.
              </div>
              <div *ngIf="f.driverName.errors.maxlength">
                Location name must not exceed 30 characters.
              </div>
              <div *ngIf="f.driverName.errors?.pattern">
                <sup>*</sup>Location name should contain only a-z,A-Z,0-9,-.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="locationName">DL No.</label>
            <input type="text" class="form-control" placeholder="" value=" "
              [ngClass]="{ 'is-invalid': submitted && f.driverLiceneseNo.errors }" formControlName="driverLiceneseNo"
              [(ngModel)]="driverBlacklisting.driverLiceneseNo" />
            <div *ngIf="submitted && f.driverLiceneseNo.errors" class="invalid-feedback">
              <div *ngIf="f.driverLiceneseNo.errors.required">Location name is required.
              </div>
              <div *ngIf="f.driverLiceneseNo.errors.minlength">
                Location name must be at least 3 characters.
              </div>
              <div *ngIf="f.driverLiceneseNo.errors.maxlength">
                Location name must not exceed 30 characters.
              </div>
              <div *ngIf="f.driverLiceneseNo.errors?.pattern">
                <sup>*</sup>Location name should contain only a-z,A-Z,0-9,-.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="locationName">Reason</label>
            <select name="isBlackListed" [(ngModel)]="driverBlacklisting.isBlackListed" class="form-control form-select"
              formControlName="isBlackListed" [ngClass]="{ 'is-invalid': submitted && f.isBlackListed.errors }">
              <option hidden Value='' disabled>--blacklisted status--</option>
              <option *ngFor="let blacklisted of BlacklistingReason" [ngValue]="blacklisted.value">
                {{blacklisted.reason}}</option>
            </select>
          </div>
          <div class="col-sm-6">
            <label for="dateAndTime">Incident Date</label>
            <input type="datetime-local" class="form-control" placeholder="" value=" "
              [ngClass]="{ 'is-invalid': submitted && f.dateAndTime.errors }" formControlName="dateAndTime"
              [(ngModel)]="driverBlacklisting.dateAndTime" />
            <div *ngIf="submitted && f.dateAndTime.errors" class="invalid-feedback">
              <div *ngIf="f.dateAndTime.errors.required">Location name is required.
              </div>
              <div *ngIf="f.dateAndTime.errors.minlength">
                Location name must be at least 3 characters.
              </div>
              <div *ngIf="f.dateAndTime.errors.maxlength">
                Location name must not exceed 30 characters.
              </div>
              <div *ngIf="f.dateAndTime.errors?.pattern">
                <sup>*</sup>Location name should contain only a-z,A-Z,0-9,-.
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <label for="description">Description</label>
            <textarea type="text" class="form-control" placeholder="" value=" "
              [ngClass]="{ 'is-invalid': submitted && f.description.errors }" formControlName="description"
              [(ngModel)]="driverBlacklisting.description"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">Description is required.
              </div>
              <div *ngIf="f.description.errors.minlength">
                Description must be at least 3 characters.
              </div>
              <div *ngIf="f.description.errors.maxlength">
                Description must not exceed 30 characters.
              </div>
              <div *ngIf="f.description.errors?.pattern">
                <sup>*</sup>Description should contain only a-z,A-Z,0-9,-.
              </div>
            </div>
          </div>

 <div class="select-radio">
            <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="driverBlacklisting.isActive"
              formControlName="isActive">
            <label for="active" class="radio-label">Active</label>

            <input class="radio-input" id="inactive" type="radio" [value]="false"
              [(ngModel)]="driverBlacklisting.isActive" formControlName="isActive">
            <label for="inactive" class="radio-label">Inactive</label>
          </div>

        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-success"
        (click)="registervehicleBlacklisting(location)">Save</button>
    </div>
  </div>
</ng-template> -->