import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { CarouselModule } from 'primeng/carousel';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MileStoneTrackingRequest } from 'src/app/models/vehicleMilestonetrackingModel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-gate-transaction',
  templateUrl: './gate-transaction.component.html',
  styleUrls: ['./gate-transaction.component.scss']
})
export class GateTransactionComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  intervalMode: any;

  private httpClient: HttpClient
  bChart: { labels: string[]; datasets: { label: string; backgroundColor: string; data: number[]; }[]; };
  userIP: any;
  graph: any;
  Rejected: boolean = true;
  public milestoneTrack: MileStoneTrackingRequest = new MileStoneTrackingRequest();
  public userAccessData: UserScreenAccesData;
  basicOptions: any
  currentVehicleDetails: any;
  upcomings: any[] = [];
  remarks: any = '';
  time3s$: Observable<number> = timer(1000, 3000);
  time5s$: Observable<number> = timer(1000, 5000);
  time7s$: Observable<number> = timer(1000, 8000);
  private subscription: Subscription;
  basicData: { labels: string[]; datasets: { label: string; backgroundColor: string; data: any[]; }[]; };
  milestoneList: any[] = [];
  responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number; }[];
  flag: boolean;

  IsShowflag: boolean = true;

  constructor(
    private kemarClientService: KemarClientService,
    private spinner: NgxSpinnerService,
    private api:DataService
  ) {


    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ]
    this.basicOptions = {
      plugins: {
        legend: {
          position: 'top'
        },
      },
      scales: {
        x: {
          ticks: {
            min: 0,
          }
        },
        y: {
          ticks: {
            min: 0,
          }
        }
      }

    };

  }

  ngOnInit(): void {
    this.GetCurrentDetail();

      this.GetCurrentDetail();
      this.GetUpComingDetail();
      this.GetGraphDetail();
    this.intervalMode = setInterval(() => {
      this.GetCurrentDetail();
      this.GetUpComingDetail();
      this.GetGraphDetail();
    }, 3000);

  //  this.subscription = this.time3s$.subscribe(_second => this.GetCurrentDetail())
  //  this.subscription = this.time5s$.subscribe(_second => this.GetUpComingDetail())
  //  this.subscription = this.time7s$.subscribe(_second => this.GetGraphDetail())
   this.userAccessData =this.api.getUserScreenAccessMenu('Gate Transaction')

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    clearInterval(this.intervalMode);
  }

  PopulatedGraph() {

    let MaxLength = (Math.max(this.graph.internal, this.graph.inbound, this.graph.outbound) + 10);
    if (MaxLength % 2 == 0){MaxLength =(MaxLength+10)}
    else {MaxLength =(MaxLength+8)}
    this.basicOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: MaxLength,
              min: 0
            }
          }
        ],
      }
    };
    this.basicData = {
      labels: ['InBound', 'OutBound','Internal','Other'],
      datasets: [
        {
          label: 'Vehicle',
          backgroundColor: '#4B9BFF',
          data: [this.graph.inbound, this.graph.outbound,this.graph.internal,,this.graph.other]
          //data: [34, 99, 70]e
        },
      ]
    };
  }
  GetCurrentDetail() {
    this.kemarClientService.get<any>(null, ServiceUrl.getCurrentgateTransactionDetail)
      .subscribe(
        response => {
          switch (response.error) {
            case "Device Location Error Found":
              this.IsShowflag = true;
              break;
            case "JobMilestone Error Found":
              this.IsShowflag = false;
              this.CommonMethod(response);
              break;
            default:
              this.CommonMethod(response);
              break;
          }
        },
        r => {
var error = r;
          // console.log(r.error.error);
        });
  }


  CommonMethod(res: any) {
    this.IsShowflag = false;
    // this.GetGraphDetail();
    this.GetUpComingDetail();
    this.currentVehicleDetails = res;
    this.milestoneList = this.currentVehicleDetails.milestoneActions;
  }

  GetGraphDetail() {

    this.kemarClientService.get<any>(null, ServiceUrl.getGraph)
      .subscribe(
        response => {
          this.graph = response;
          this.PopulatedGraph()
        },
        r => {
          // console.log(r.error.error);
        });
  }
  onChangecheckbox(flag: any) {
    if (flag) { this.milestoneTrack.IsRejected = this.Rejected }
    this.milestoneTrack.Remark = this.remarks;
    this.milestoneTrack.MilestoneActionTrackingId = this.currentVehicleDetails.currentMileStoneId;
    this.milestoneTrack.locationCode = this.currentVehicleDetails.locationCode;
    this.milestoneTrack.locationId = this.currentVehicleDetails.locationId;
    this.milestoneTrack.vrn = this.currentVehicleDetails.vehicleNo;
    this.milestoneTrack.actionCode = this.currentVehicleDetails.currentMilestone;

    this.spinner.show();
    this.kemarClientService
      .postPatch<any>(ServiceUrl.updateMilestoneTracking, this.milestoneTrack)
      .subscribe(
        (res) => {
          //
          Swal.fire('', res.responseMessage, 'success')
          //Swal.fire('Success', res.responseMessage, 'success')
          this.spinner.hide();

        },
        (r) => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage,
          });
          this.spinner.hide();
          // console.log(r.error.error);
        }

      );
    this.GetCurrentDetail();
  }
  GetUpComingDetail() {
    this.kemarClientService.get<any>(null, ServiceUrl.getupcominigVehicleList)
      .subscribe(
        response => {
          this.upcomings = response;

        },
        r => {
          // console.log(r.error.error);
        });
  }
}
