<ngx-spinner> </ngx-spinner>
<div class="main">
  <app-header></app-header>
  <div class="container">
    <div>
      <video #video autoplay playsinline></video>
    </div>
    <!-- <button (click)="captureImage()">captureImage</button> -->
    <div class="col-md-5 main-section-box">
      <div id="login-card" class="card">
        <div class="card-body">
          <div class="top-title">
            <h2 class="text-center">FRS Enrollment</h2>
          </div>
          <br />
          <form class="form-section" [formGroup]="driverForm">
            <div class="form-group mb-2">
              <label class="form-label" for="exampleFormControlSelect"
                >Select Type</label
              >
              <select
                class="form-control form-select"
                id="exampleFormControlSelect"
                formControlName="DeviceIP"
                [ngClass]="{ 'is-invalid': submitted && f1.DeviceIP.errors }"
              >
                <option value="" disabled>--Choose Option--</option>
                <option
                  *ngFor="let FR of FRDRegister"
                  [ngValue]="FR.frDeviceIP"
                >
                  {{ FR.frDeviceName }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="form-label" for="exampleFormControlSelect1"
                >Person Code</label
              >
              <br />
              <ng-select
                [items]="driverMaster"
                bindLabel="driverCode"
                bindValue="driverCode"
                formControlName="DriverCode"
                [virtualScroll]="true"
                [clearable]="true"
                placeholder="Select Person"
                notFoundText="No Person Found"
                [markFirst]="true"
                appendTo="body"
                (scrollToEnd)="loadDriver()"
                (search)="onSearch($event)"
                (change)="onDriverCodeChange()"
              #select>
              <ng-template let-driver pTemplate="item">
                  <span class="d-flex">{{driver[i].name}}</span>
              </ng-template>
              </ng-select>


              <!-- <select name="roleId" formControlName="DriverCode" (change)="onDriverCodeChange()"
                                class="form-control role-select form-select"
                                [ngClass]="{ 'is-invalid': submitted && f1.DriverCode.errors }" >
                                <option value="" disabled>--Choose Option--</option>
                                <option *ngFor="let role of driverMaster" [ngValue]="role.driverCode"> {{
                                    role.driverCode }}</option>
                            </select> -->
            </div>
            <div class="form-group person-name-input">
              <label class="form-label" for="exampleInputPassword1"
                >Person Name</label
              >
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Name will be shown here"
                [(ngModel)]="selectedDriver"
                [ngModelOptions]="{ standalone: true }"
                disabled="true"
              />
            </div>
            <div class="btn-sec mb-5">
              <div class="float-start" *ngIf="Sync">
                <button
                  type="submit"
                  class="btn btn-primary btn-primary-new"
                  (click)="SyncDetails()"
                >
                  Sync Detailes
                </button>
                <button
                  type="submit"
                  class="btn btn-light btn-light-delete mx-2"
                  (click)="Delete()"
                >
                  Delete
                </button>
              </div>
              <div class="enroll-section">
                <p-button
                  class="p-btn float-end"
                  (click)="showDialog()"
                  label="Enroll"
                  *ngIf="enroll"
                ></p-button>
                <button
                  class="btn no-border float-end"
                  type="button"
                  disabled
                  *ngIf="Please"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Please Wait...
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="p-3" *ngIf="progressBar">
              <p>Please wait...</p>
              <p-progressBar
                mode="indeterminate"
                [style]="{ height: '6px' }"
              ></p-progressBar>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>

    <p-dialog
      header="Please Note!"
      [(visible)]="visible"
      [modal]="true"
      [style]="{ width: '30vw' }"
    >
      <p>
        Please Ensure that <b>Person</b> are stand properly in front of the
        camera
      </p>
      <div class="text-end">
        <button class="btn btn-primary" (click)="Enroll()">ok</button>
      </div>
    </p-dialog>
  </div>
  <app-footer></app-footer>
</div>
