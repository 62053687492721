export const ConfigParms = {
  TestingMood: true,
  IsProd: true,
  APIUrl: '',
  DevAPIUrl: 'http://localhost:21639/api',
  //ProdAPIUrl: 'http://<URL>//service/api',
//  ProdAPIUrl: 'http://192.168.1.205:7100/service/api', // Office 205 Server URL
//  ProdAPIUrl: 'http://98.70.76.40:9025/service/api', // Cloud Server Server URL
  // ProdAPIUrl: 'https://urgetruckvjnr.jsw.in/Service/api', // Vijaynagar
  // ProdAPIUrl: 'https://urgetrucktestvjnr.jsw.in/Service/api', // Vijaynagar Test
  // ProdAPIUrl: 'https://urgetruckdolvi.jsw.in/Service/api', // Dolvi
  // ProdAPIUrl: 'https://urgetruckdolvitest.jsw.in/Service/api', // Dolvi Test
 ProdAPIUrl: 'https://urgetruck.com/service/api',
//  ProdAPIUrl: 'http://103.240.90.141:7000/service/api', //UrgeTruck.com alternative IP
  // ProdAPIUrl: 'http://sguivl.starcement.co.in/service/api', // SGU
//  ProdAPIUrl: 'http://122.186.230.166/service/api', // SGU Public IP
//  ProdAPIUrl: 'http://10.60.46.100/service/api', // GGU
// ProdAPIUrl: 'http://gguivl.starcement.co.in/service/api', // GGU Public IP
  EnableOTPOnUI: false,
  // PlantName:'Guwahati Grinding Unit',
  // PlantName: 'Siliguri Grinding Unit',
  // PlantName: 'Vijaynagar Works QA',
  // PlantName: 'Vijaynagar Works',
  // PlantName: 'Dolvi Works',
  // PlantName: 'Dolvi Works QA',
  PlantName: 'My Plant',
  headerWithName:'true',
  // plant:'STAR',
  plant:'MYCOMPANY',
  // plant:'JSW',
};
