export const ServiceUrl = {

  // User registration
  registerUser: 'UserManagement/RegisterUser',
  authenticate: 'UserManagement/authenticate',
  forgotPassword: 'UserManagement/forgot-password',
  resetPassword: 'UserManagement/reset-password',
  changePassword: 'UserManagement/change-password',
  resetPasswordByAdmin: 'UserManagement/reset-password-by-admin',
  updateUserLogoutTime: 'UserManagement/UpdateUserLogoutTime',


  // Location registration
  registerLocation: 'Location/RegisterLocation',
  getAllLocations: 'Location/GetAllLocations',
  getLocation: 'Location/GetLocation',
  getAllLocationMapping: 'LocationMapping/GetAllLocationMapping',
  // Gate Master
  registerGate: 'Gate/RegisterGate',
  getAllGAtes: 'Gate/GetAllGates',
  getGate: 'Gate/GetGate',

  // Lane registration
  registerLane: 'Lane/RegisterLane',
  getAllLanes: 'Lane/GetAllLanes',
  getLane: 'Lane/GetLane',

  // Device registration
  registerDevice: 'Device/RegisterDevice',
  getAllDevices: 'Device/GetAllDevices',
  getDevice: 'Device/GetDevice',

  // Device Allocation Mapping
  registerDeviceLocationMapping: 'DeviceLocationMapping/RegisterDeviceLocationMapping',
  getAllDeviceLocationMappings: 'DeviceLocationMapping/GetAllLocationDeviceMapping',
  getDeviceLocationMapping: 'DeviceLocationMapping/GetLocationDeviceMapping',
  getAllDeviceStatus: 'DeviceLocationMapping/GetAllDeviceStatus',
  getDeviceStatusHistory: 'DeviceLocationMapping/GetDeviceStatusHistory',
  getAllBlankDeviceStatus: 'DeviceLocationMapping/GetAllBlankDeviceStatus',
  getDeviceStatus: 'DeviceLocationMapping/GetDeviceStatus',
  getDevicesInfoByLocationIdAnddeviceType:
    'DeviceLocationMapping/GetDevicesInfoByLocationIdAnddeviceType',
    getAllActiveDeviceLocationMappings: 'DeviceLocationMapping/GetAllActiveLocationDeviceMapping',

  // DeviceLane registration
  getAllLaneOcrMapping: 'LaneOCRMapping/GetAllLanesOcrMapping',
  registerLaneOcrMapping: 'LaneOCRMapping/RegisterLaneOcrMapping',

  // USER registration
  registerUserManager: 'UserManagement/RegisterUser',
  getUsersData: 'UserManagement/GetAllUsers',
  updateUserDeatils: 'UserManagement/UpdateUser',
  ressetPassword: 'UserManagement/reset-password',
  refreshToken: "UserManagement/refreshtoken",
  /// GATE In
  getTransactionDetail: 'GateTransaction/GetGateTransaction',
  approveGateTransaction: 'GateTransaction/ApproveGateTransaction',


  /// Gate Tansaction

  getCurrentgateTransactionDetail:'GateTransaction/CurrentGateTransaction',
  getGraph:'GateTransaction/VehicleInPlantGraph',
  getupcominigVehicleList:'GateTransaction/UpcomingVehicleList',


  ///DAMAGE type registration
  registerDamage: 'DamageType/RegisterDamageType',
  getAllDamageType: 'DamageType/GetAllDamageType',

   // role registration
   registerRole: 'RoleManager/registerrole',
   getAllRoles: 'RoleManager/GetAllRoles',
   getRole: 'RoleManager/GetRole',
   getAllActiveRoles : 'RoleManager/GetAllActiveRoles',

  // Driver registration
  registerUserRoleMapping: 'UserRoleMapping/RegisterUserRoleMapping',
  getAllUserRoleMapping: 'UserRoleMapping/GetAllUserRoleMappings',
  getUserRoleMapping: 'UserRoleMapping/GetUserRoleMapping',

  //Report URL
  getGateReport: 'Report/getGateReport',
  getTaTReport: 'Report/getTaTReport',
  GetLanebasedOnGateId: 'Lane/GetLanebasedOnGateId',
  getApiTrackingReport: 'ApiTrackingReport/getApiTrackingReport',
  getApiTrackingReportWithDate: 'ApiTrackingReport/getApiTrackingReportData',


  // Damage container registration
  registerContainerDamage: 'Damage/RegisterDamage',

  // Vehicle registration
  registerVehicle: 'Vehicle/RegisterVehicle',
  getAllVehicle: 'Vehicle/GetAllVehicle',
  getvehicle: 'Vehicle/GetVehicle',
  bulkUploadVehicleRegistry: 'Vehicle/BulkUploadVehicleRegistry',

  // Driver registration
  registerDriver: 'Driver/RegisterDriver',
  getAllDrivers: 'Driver/GetAllDrivers',
  getDriver: 'Driver/GetDriver',
  getDriverItemPerPage:'Driver/GetDriverItemsPerPage',
  getHelperItemPerPage:'Driver/GetHelperItemsPerPage',
  GetDriverWithPagination:'Driver/GetDriverWithPagination',

  // Transporter registration
  registerTransporter: 'Transporter/RegisterTransporter',
  getAllTransporters: 'Transporter/GetAllTransporter',
  getTransporter: 'Transporter/GetTransporter',
  getAllTransportersWithPagination: 'Transporter/GetAllTransporterpagination',

  // WeighBridge registration
  registerWeigbridge: 'WeighBridge/RegisterWeighBridge',
  getAllWeighBridge: 'WeighBridge/GetAllWeighBridge',
  getWeighBridge: 'Weigh-Bridge/GetWeighBridge',

  // WeighBridge Preference registration
  registerWeigbridgePreference: 'WeighbridgePreference/RegisterWeighbridgePreferences',
  getAllWeighBridgePreference: 'WeighbridgePreference/GetAllWeighBridgePreferences',
  getWeighBridgePreference: 'WeighbridgePreference/GetWeighBridgePreference',

  // Groupmaster registration
  registerGroup: 'Group/RegisterGroup',
  getAllGroup: 'Group/GetAllGroups',
  getGroup: 'Group/GetGroup',

  // Milestonemaster registration
  registerMilestone: 'MileStone/RegisterMileStone',
  getAllMilestone: 'MileStone/GetAllMileStone',
  getMilestone: 'MileStone/GetMileStone',

  // VEHICLE Transaction

  // getAllMilestone: 'MileStone/GetAllMileStone',
  GetVehilceTransaction: 'VehicleTransaction/GetVehilceTransaction',
  getAllWeighBridgeStatus: 'WeighBridge/WeighbridgeCount',
  getAllWeighBridgeCapacity: 'WeighBridge/CapacityOfWeighBridgeAllocated',
  assignWeigbridge: 'WeighBridge/ReAllocatedWeighbridge',

  // getAllMilestone: 'MileStone/GetAllMileStone',
  GetTransactionByVrn: 'VehicleTransaction/GetTransactionByVrn',
  GetVehicleBlacklistingReason: 'Vehicle/GetVehicleBlacklistingReason',
  GetTransporterBlacklistingReason:'Transporter/GetTransporterBlacklistingReason',
  GetDriverBlacklistingReason: 'Driver/GetDriverBlacklistingReason',



  // Milestonemaster Action registration
  registerMilestoneAction: 'MilestoneAction/RegisterMilestoneAction',
  getAllMilestoneAction: 'MilestoneAction/GetAllMilestoneActions',
  getMilestoneAction: 'MileStoneAction/GetMileStoneAction',

  // Milestonemaster Action Mapping registration
  registerMilestoneActionMapping: 'MilestoneActionMapping/RegisterMilestoneActionMapping',
  getAllMilestoneActionMapping: 'MilestoneActionMapping/GetAllMilestoneMappingActions',
  getMilestoneActionMapping: 'MileStoneAction/GetMileStoneActionMapping',

  // LocationType registration
  registerLocationType: 'LocationType/RegisterLocationType',
  getAllLocationType: 'LocationType/GetAllLocationTypes',
  getLocationType: 'LocationType/GetLocationType',

  // ThirdPartyVehicle registration
  registerNonLogisticVehicle: 'NonLogisticVehicle/RegisterNonLogisticVehicle',
  getAllNonLogisticVehicle: 'NonLogisticVehicle/GetAllNonLogisticVehicle',
  getNonLogisticVehicle: 'NonLogisticVehicle/GetLocationType',

  // vehicleBlacklisting registration
  registervehicleBlacklisting: 'Vehicle-Blacklisting/RegisterVehicleBlacklisting',
  getAllvehicleBlacklisting: 'Vehicle-Blacklisting/GetAllVehicleBlacklisting',
  getvehicleBlacklisting: 'Vehicle-Blacklisting/GetVehicleBlacklisting',

  registerTransporterBlacklisting: 'Transporter-Blacklisting/RegisterVehicleBlacklisting',
  getAllTransporterBlacklisting: 'Transporter-Blacklisting/GetAllVehicleBlacklisting',
  getTransporterBlacklisting: 'Transporter-Blacklisting/GetVehicleBlacklisting',

  // driverBlacklisting registration
  registerdriverBlacklisting: 'Driver-Blacklisting/RegisterDriverBlacklisting',
  getAlldriverBlacklisting: 'Driver-Blacklisting/GetAllDriverBlacklisting',
  getdriverBlacklisting: 'Driver-Blacklisting/GetDriverBlacklisting',

  getdeviceMapping: 'MobileApp/GetLocationMasterData',
  postRFIDTAG: 'MobileApp/PostRFIDTag',
  getYardLocationMasterData: 'MobileApp/GetYardLocationMasterData',

  postWeighbridgeTransaction: 'KControl/PostweighbridgeTransactionDetail',
  PhysicalCheckModel: 'MobileApp/PostPhysicalCheck',
  InvoicingModel: 'AX4Integration/PostMilestoneCompletion',
  LodingModel: 'AX4Integration/PostMilestoneCompletion',

  // CrossChecK
  registerCrossCheck: 'crossCheckManager/RegisterCrossCheckEvent',
  getAllCrossCheckEvents: 'crossCheckManager/GetAllCrossCheckEvents',

  // elv list
  getAllElvlist: 'ELV/GetAllElvs',
  deleteTransactionOnElvId: 'ELV/DeleteAllTransactionWithElvId',
  completeAllTransactionOnEldId : 'ELV/CompleteAllTransactionWithElvId',

  getAllweightStatus : 'WeighBridge/GetVehicleDetailByIPCIPAddress',
  getVehicleQueue : 'WeighBridge/getVehicleQueue',
  weightBridgedown : 'WeighBridge/weightBridgedown',
  getCustomWeighBridge : 'WeighBridge/getCustomWeighBridge',
  updateSingleWeighBridge : 'WeighBridge/updateSingleWeighBridge',
  updateWeighBridge : 'WeighBridge/updateWeighBridge',

  // Main dashboard URL
  getMainDashboard: 'DashBoard/getMainDashboardData',

  //current transaction
  updateMilestoneTracking: 'VehicleTransaction/CompleteMilestoneActionTracking',

  getAllVehilceTransaction: 'VehicleTransaction/GetAllVehilceTransaction',
  getAllActiveVehilceTransactiontoDownload: 'VehicleTransaction/GetAllActiveVehilceTransaction',
  getallVehicleParkinlist: 'elv/GetAllCallInMilestone',
  getAutocalled:'elv/GetAutoCalledInChecked',

  getallProductCategorylist: 'Product/GetAllProductList',
  getallProductMaster: 'Product/GetAllProductMaster',


  updateAutoCalledIn:'elv/UpdateAutoCallIn',
  postParkingCallIn: 'elv/UpdateCallInMileStone',
  getDashboardCardData: 'DashboardCard/getDashboardCardData',

  // User Role Access manager
  getSingleUserRoleAccessMapping: 'UserAccessManager/getUserAccessbyRole',
  getAllUserRoleAccessMapping: 'UserAccessManager/getAllUserRoleAccess',
  assignUserRoleAccessMapping: 'UserAccessManager/assignUserAccessRole',
  getGateReportData:'gateReport/getGateReport',
  getTatReport: 'tatReport/getTatReport',
  getDailyTatReport: 'DashboardCard/DayWiseTATTransactionReportData',
  getVehicleEntryExit: 'getVehicleEntryExit',
  getNonLogisticGateReport: 'gateReport/NonLogisticGateReport',
  GetDetailReport:'DetailGateReport/GetDetailReport',


//location mapping
registerLocationMapping: 'LocationMapping/RegisterLocationMapping',
getAlllocationmapping: 'LocationMapping/GetAllLocationMapping',


getInplantVehilceTransaction: 'VehicleTransaction/GetAllInplantVehilceTransaction',
getInplantTransactionHistory: 'VehicleTransaction/GetInplantTransactionHistory',
getweighbridgeVehilceTransaction: 'WeighBridgeTransaction/GetAllWeighbrideTransaction',
getweighbridgeImage: 'WeighBridgeTransaction/getweighbridgeImage',

getProjectCurrentVersion: 'ProjectVersion/GetProjectCurrentVersion',

getActiveAlerts: 'ControlTowerDashboard/GetActiveAlerts',
getActiveTickets: 'ControlTowerDashboard/GetActiveTicketsAsync',
getAlertHistory: 'ControlTowerDashboard/getAlertHistory',
getTicketHistory: 'ControlTowerDashboard/getTicketHistory',
getAllCategory: 'CatSubCatCT/GetAllTicketCategory',
getAllSubCategory: 'CatSubCatCT/GetAllTicketSubCategory',
getSubCategorybyCategoryId: 'CatSubCatCT/GetTicketSubCategoryByCategoryId',
getTicketUrgency:'CTTicketUrgency/GetAllActiveTicketUrgency',
getUserbyRoll:'UserManagement/GetAllUsersByRoll',
GenerateServiceTicket : 'ControlTower/GenerateServiceTicket',
GetControlTowerDashboard: 'ControlTowerDashboard/GetDashboardData',
GetCTUasers: 'CTUserAvailabilityStatus/GetAllCTUsers',
registerCTUser: 'CTUserAvailabilityStatus/AddUpdateUserAvailability',
getMyIncidentTicket: 'ControlTowerDashboard/GetMyIncidents',
GetCTUserDetails :'ControlTowerDashboard/GetCTUserDetails',
getIncidentTicket: 'ControlTowerDashboard/GetLoginUserIncidents',

getAllNonLogisticsVehicleAsync: 'Vehicle/GetAllVehicleByTypeAsync',
getAllLogisticsVehicleAsync: 'Vehicle/GetAllVehicleByTypeAsync',
getInPlantVehicleAsync: 'Vehicle/GetAllVehicleByTypeAsync',
getVehiclebyVRN: 'Vehicle/GetVehiclebyVRNAsync',
getVehicleOnCategoryAsync : 'Vehicle/GetVehicleOnCategoryAsync',

//SLA Master
getAllSLATransactionTicket:'TransactionSLA/GetAllSLATransactionTicket',
registerTransactionSLA:'TransactionSLA/RegisterTransactionSLA',
getAllTransitSLATickets:'TransitSLAMaster/GetAllTransitSLATickets',
saveTransitSLA:'TransitSLAMaster/SaveTransitSLA',
getAllInboundSLA:'TransitSLAMaster/GetAllInboundSLA',
getAllOutboundSLA:'TransitSLAMaster/GetAllOutboundSLA',
getAllInPlantSLA:'TransitSLAMaster/GetAllInPlantSLA',



//Common Master Data
getCommonMasterDataAsync: 'CommonMasterData/GetCommonMasterData',
getAllCommonMasterData: 'CommonMasterData/GetAllCommonMasterData',
addCommonMasterData: 'CommonMasterData/AddCommonMasterData',
getTypeList: 'CommonMasterData/GetTypeList',
getParameterList: 'CommonMasterData/GetParameterList',
getParticularCommonMasterData: 'CommonMasterData/GetParticularCommonMasterData',

//Location Status History
getChildLocationList: 'LocationMapping/GetChildLocationList',
getLocationDeactivationReasonList: 'LocationMapping/GetLocationDeactivationReasonList',
getLocationActivationReasonList: 'LocationMapping/GetLocationActivationReasonList',
activateDeactivateLocation: 'LocationMapping/ChangeLocationStatus',
locationList: 'LocationMapping/GetAllLocationListShow',

// Application Configuration
getAllApplicationConfiguration: 'ApplicationConfiguration/GetAllApplicationConfiguration',

//Dashboard
getdashboardchartData : 'DashboardCard/dashboardchartData',
getDashBoardMonthlyTransactionData : 'DashboardCard/MonthlyTransactionData',
getDashboardTATTransactionData : 'DashboardCard/TATTransactionData',

//jobschedule
getAllJobSchedules:'JobSchedule/GetAllJobSchedules',
registerJobSchedule:'JobSchedule/RegisterJobSchedule',

//GateDashBoard

getgateDashBoardData : 'GateTransaction/gateDashboarddata',
getgateLocationData : 'GateTransaction/getGateLocation',

//blacklisthistory
getAllDriverBlackListShow:'BlackListHistries/GetAllDriverBlackListShow',
getAllVehicleBlackListShow:'BlackListHistries/GetAllVehicleBlackListShow',
getAllTransporterBlackListShow:'BlackListHistries/GetAllTransporterBlackListShow',

  // Work Flow
  getAllWorkFlowmaster:'WorkFlow/GetAllWorkFlowMaster',
  getAllActiveWorkFlowMaster:'WorkFlow/GetAllActiveWorkFlowMaster',
  updateWorkFlowMaster:'WorkFlow/RegisterWorkFlowMaster',
  getAllWorkFlowDetail:'WorkFlow/GetAllWorkFlowDetails',
  getAllWorkFlowDetailByGroup:'WorkFlow/GetWorkFlowDetailsByGroup',
  updateWorkFlowDetail:'WorkFlow/RegisterWorkFlowDetail',

  GetAllLogisticsVehicleWithoutpaginationAsync:'Vehicle/GetAllLogisticsVehicleWithoutpaginationAsync',
  sendELVApi: 'AX4Integration/PostExpectedVehicleListUI',
  sendJobAllocation: 'AX4Integration/PostJobAllocationDataUI',
  sendMilestone: 'BALIntegration/PostMilestoneData',
  updateMilestoneWithGroupName: 'AX4Integration/PostVehicleMilestonesWithGroupName',
  postVehicleMilestonesFromExcelAsync: 'AX4Integration/PostVehicleMilestonesFromExcelAsync',
  postInPlantTransactionWithGroupName: 'AX4Integration/PostInPlantTransactionWithGroupName',
  GetAllLogisticsVehicleWithpaginationAsync:'Vehicle/GetAllLogisticsVehicleWithpaginationAsync',
  //GPO
  RegisterGPOManager :'GPOManager/RegisterGPOManager',
  GetAllGPOOnDeviceMapping:'GPOManager/GetAllGPOOnDeviceMapping',

  //FRS
  GetMappedFRDeviceWithRegisterSystem:'FaceRecognition/GetMappedFRDeviceWithRegisterSystem',
  EnrollDriverImageInFRDevice:'FaceRecognition/EnrollDriverImageInFRDevice',
  SyncUserPhotoToAllDevice:'FaceRecognition/SyncUserPhotoToAllDevice',
  DeleteEnrollUserFromFR : 'FaceRecognition/DeleteEnrollUserFromFR',
  SavePersonImage : 'FaceRecognition/SavePersonImage',
  addfrs:'FaceRecognition/SyncAllDriverDataToNewDevice',

  //Packer Master
  getAllPackerData: 'PackerMaster/getAllPackerData',
  SavePackerData: 'PackerMaster/SavePackerData',
  getAllPackerLocation: 'LocationMapping/getAllPackerLocation',

  // Mobile Request
  getAllWeighbridgeMobile : 'MobileApp/GetAllWeighBridgeList',
  getVehicleToleranceCheck : 'MobileApp/GetWeighmentDetails',
  ActionOnToleranceWeight : 'MobileApp/PostSecurityCheckForWeighmentFromWeb',
  GetCommonMasterDataOnParameter : 'CommonMasterData/GetParticularCommonMasterData',
  retriggerAX4Notify: 'MobileApp/RetriggerAX4Notify',

  // Weighment Approval
  getWeighbridgeVehicleMilestoneDetails: 'WeighBridgeTransaction/GetWeighbridgeVehicleMilestoneDetails',
  getUpcommingvehicleOnWeighbridge: 'WeighBridgeTransaction/GetAllCommingVehicleForWB',
  UpdateWeighApprovalAction: 'WeighBridgeTransaction/UpdateWeighApprovalAction',

  // API Communication Error
  getAllAPICommunicationList: 'APICommunicationErrors/GetAllAPICommunicationList',

  getUpcomingVehicleCount:'UpComingVehicleCount/GetUpcomingVehicleCount',
  getVehicleWiseAllTransactions:'UpComingVehicleCount/GetVehicleWiseAllTransactions',

  // Feedback Email
  sendFeedbackEmail: 'UserManagement/sendFeedBackMail',

  //Skip FRS
  skipFRS : 'MobileApp/ByPassFRS',

  //Invoice Details
  GetInvoices: 'MobileApp/GetInvoiceDetailOnVehicleDetail',
  UpdateInvoiceDetailOnVehicle: 'MobileApp/UpdateInvoiceDetailOnVehicle',
  //getVehicleWiseAllTransactions:'UpComingVehicleCount/GetVehicleWiseAllTransactions',
  getAllPendingVehiclesList :'GateChange/GetAllPendingVehicleList',
  getPlantGateInList:'GateChange/GetAllPlantGateInList',
  getPlantGateOutList:'GateChange/GetPlantGateOutList',
  saveUpdatedLocations:'GateChange/SaveUpdatedLocation',

  // UT Common Dashboard
  getCommonMainDashboard: 'UTCommondashboard/getMainDashboardData',
  getCommonDashboardCardData: 'UTCommondashboard/getDashboardCardData',
  getCommonDashBoardMonthlyTransactionData : 'UTCommondashboard/MonthlyTransactionData',
  getCommonDashboardTATTransactionData : 'UTCommondashboard/TATTransactionData',
  getCommondashboardchartData : 'UTCommondashboard/dashboardchartData',
  getCommonDailyTatReport: 'UTCommondashboard/DayWiseTATTransactionReportData',
  getCommonAlllocationmapping: 'UTCommondashboard/GetAllLocationMapping',

  // UT WeighBridge Common Dashboard
  getCommonAllweightStatus : 'UTCommondashboard/GetVehicleDetailByIPCIPAddress',
  getCommonVehicleQueue : 'UTCommondashboard/getVehicleQueue',
  CommonweightBridgedown : 'UTCommondashboard/weightBridgedown',
  getCommonCustomWeighBridge : 'UTCommondashboard/getCustomWeighBridge',
  CommonupdateSingleWeighBridge : 'UTCommondashboard/updateSingleWeighBridge',
  CommonupdateWeighBridge : 'UTCommondashboard/updateWeighBridge',
  getCommonAllWeighBridge: 'UTCommondashboard/GetAllWeighBridge',
  getCommonDevicesInfoByLocationIdAnddeviceType:
    'UTCommondashboard/GetDevicesInfoByLocationIdAnddeviceType',

  // UT Control Tower Dashboard
  GetCommonControlTowerDashboard: 'UTCommondashboard/GetDashboardData',
  GetCommonCTUserDetails :'UTCommondashboard/GetCTUserDetails',
  getCommonActiveTickets: 'UTCommondashboard/GetActiveTicketsAsync',
  getCommonDeviceStatus: 'UTCommondashboard/GetDeviceStatus',
  getCommonAllDeviceStatus: 'UTCommondashboard/GetAllDeviceStatus',
  getCommonDeviceStatusHistory: 'UTCommondashboard/GetDeviceStatusHistory',
  getCommonAllBlankDeviceStatus: 'UTCommondashboard/GetAllBlankDeviceStatus',

  // Support Page Transactions
  getSearchedVehicle:'VehicleTransaction/GetSearchedVehicle',
  getVehicleList:'VehicleTransaction/getVehicleList',
  updatemilestonactiontracting:'VehicleTransaction/UpdateMilestonActionTracking',
  updateJobMilestone:'VehicleTransaction/UpdateJobMilestone',
  getActiveVehicleList:'VehicleTransaction/GetActiveVehicleList',
  getLocationDataByLocationId:'MobileApp/GetLocationMasterDataByLocationId',
  updateELVsupport:'elv/EditELVSupport'
};
