export class WeighBridgeMasterResponse {

    WeighBridgeId:number;
    WeighBridgeName:string;
    locationName:string;
    capacity:string;
    isActive:boolean;
    deactivationReason: string;
    maxQueueSize: string;
    locationId:number;
    desciption:string;
    editFlag : boolean;
    weighbridgeBoomType: string;
    isTokenVerificationReq: boolean;

}

