<app-header></app-header>

<div class="container">
  <h4 class="mt-3 ml-2">Weigh-Bridge Control</h4>
  <div class="card col-12 mt-4">
  <h5 class="text-center mt-3">Activation and Deactivation of Weigh-Bridge</h5>
  <div class="row">
    <div class="col-3">
      <div class="select-lane">
      <label for="">Select Weigh-Bridge</label>
      <select class="form-control form-select" name="" id="">
        <option value='' hidden selected>Select Weigh-Bridge</option>
        <option value="">Weigh-Bridge-1</option>
        <option value="">Weigh-Bridge-2</option>
        <option value="">Weigh-Bridge-3</option>
        <option value="">Weigh-Bridge-4</option>
        <option value="">Weigh-Bridge-5</option>
      </select>
    </div>

    </div>
    <div class="col-3">
      <div class="select-reason">
        <label for="">Select Reason</label>
        <select class="form-control form-select" name="" id="">
          <option value='' class="default-selection" hidden selected>Select Reason</option>
          <option value="">Maintenance</option>
          <option value="">Misfunctioning</option>
          <option value="">Breakdown of Vehicle</option>
          <option value="">Temporary Disabled</option>
          <option value="">other</option>
        </select>
      </div>
    </div>

    <div class="col-5 mt-2">
      <div class="specify-reason">
        <label for="">Specify Reason</label>
        <input type="text" class="form-control mt-0">
      </div>
    </div>
  </div>

  <div class="row text-center button">
  <div class="col-3">
  <button class="btn btn-success mr-4">Activate</button>
</div>
<div class="col-3">
  <button class="btn btn-danger">De-Activate</button>
</div>
</div>

</div>
</div>

<footer class="sticky-footer bg-black text-light mt-auto mb-0">
    <app-footer></app-footer>
  </footer>
  
