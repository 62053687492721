import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { TransporterMasterResponse } from 'src/app/models/transportermastermodel';
import { UserScreenAccesData } from 'src/app/models/usermodel'

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  faDoorClosed,
  faEdit,
  faPlus,
  faSort,
  faTimes,
  faTruck,
  faUser,
  faUserAlt,
  faSearch,
  faSleigh,
} from '@fortawesome/free-solid-svg-icons';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { getLocaleDateFormat } from '@angular/common';
import { dateFormat } from 'highcharts';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-transporter-master',
  templateUrl: './transporter-master.component.html',
  styleUrls: ['./transporter-master.component.scss'],
})
export class TransporterMasterComponent implements OnInit {
  
  BlacklistingReason: any;
  vehicleBlacklisting: [];
  Blacklisting: any;
  public transporter: TransporterMasterResponse =new TransporterMasterResponse();
  public userAccessData: UserScreenAccesData;

  [x: string]: any;
  transporterMaster: any;
  title: any;
  faEdit = faEdit;
  faTimes = faTimes;
  faPlus = faPlus;
  faSort = faSort;
  faTruck = faTruck;
  faUserAlt = faUserAlt;
  faSearch = faSearch;
  form: FormGroup;
  blacklistedDll: any = blacklistedDll;
  blacklistShow: boolean = false;
  resetbuttonShow: boolean = true;
  activebuttonShow: boolean = false;
  blaclistingDescription: boolean;
  blaclistingIncidentDate: boolean = false;
  Blacklistingreason: boolean = false;
  PreviewsBlackListed: boolean;
  lastActivePage: number = 0;
  IsEnableSearchBox: boolean = true;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 15;
  currentPage = 1;
  totalRecords = 10;
  key: string = 'vendorCode'; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  blaclistingChange = true;
  ReasonRequired = true;
  DescriptionRequired = true;
  IncidentRequired = true;
  selectedColumn: any;

  @ViewChild('closebutton') closebutton;

  //################################# END Pagination ###########################################

  constructor(
    private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,

    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private api:DataService
  ) {
    this.form = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
          this.noWhitespaceValidator,
        ],
      ],
      isActive: [''],
      vendorCode: ['', [Validators.required, this.noWhitespaceValidator]],
      plantCode: ['', [Validators.required, this.noWhitespaceValidator]],
      address: ['', [Validators.required]],
      mobileNo: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(11),
        ],
      ],
      telNo: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(11),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      contactPersonName: [
        '',
        [Validators.required, Validators.pattern('^[ a-zA-Z]*$')],
      ],
      contactPersonNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(11),
        ],
      ],
      isBlackListed: [''],
      reason: [''],
      description: [''],
      incidentDateTime: [''],
    });
   
    this.userAccessData =this.api.getUserScreenAccessMenu('TRANSPORTER');
  }

  ngOnInit(): void {
    // this.getAllTransporters();
    this.getVehiclesBlacklistingReason();
    this.getAllTransportersWithPagination(this.currentPage);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  getAllTransporters() {

    this.spinner.show();
    this.kemarClientService
      .get<TransporterMasterResponse>(null, ServiceUrl.getAllTransporters)
      .subscribe(
        (response) => {
          this.transporterMaster = response;
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  // EnterBtn(event: any) {
  //   if (event.keyCode === 13) {
  //     this.getAllTransportersWithPagination(this.currentPage);
  //   }
  // }

  // driverchange(val: any) {
  //   this.currentPage = 1;
  //   this.getAllTransportersWithPagination(this.currentPage);
  // }

  EnterBtn(event: any) {
    if (event.keyCode === 13) {
      this.currentActivePage = 1;
      this.getAllTransportersWithPagination(this.currentActivePage);
    }
  }


  open(content: any) {
    this.onReset();
    this.title = 'Add Transporter';
    this.transporter.name = '';
    this.transporter.address = '';
    this.transporter.transporterId = 0;
    this.transporter.isActive = true;
    this.transporter.telNo = null;
    this.transporter.mobileNo = null;
    this.transporter.email = '';
    this.transporter.vendorCode = '';
    this.transporter.plantCode = '';
    this.transporter.contactPersonName = '';
    this.transporter.contactPersonNumber = '';
    this.transporter.isBlackListed = false;
    this.activebuttonShow = true;
    this.blacklistShow = false;
    this.blaclistingChange = true;
    this.resetbuttonShow = true;
    this.modalService.open(content, this.ngbModalOptions);
  }

  editTransporter(content: any, transporter) {

    this.title = 'Update Transporter';
    this.PreviewsBlackListed = transporter.isBlackListed;
    this.transporter.name = transporter.name;
    this.transporter.address = transporter.address;
    this.transporter.transporterId = transporter.transporterId;
    this.transporter.isActive = transporter.isActive;
    this.transporter.telNo = transporter.telNo;
    this.transporter.mobileNo = transporter.mobileNo;
    this.transporter.email = transporter.email;
    this.transporter.vendorCode = transporter.vendorCode;
    this.transporter.plantCode = transporter.plantCode;
    this.transporter.contactPersonName = transporter.contactPersonName;
    this.transporter.contactPersonNumber = transporter.contactPersonNumber;
    this.transporter.isBlackListed = transporter.isBlackListed;
    this.resetbuttonShow = false;
    this.activebuttonShow = false;
    this.blaclistingChange = true;
    this.transporter.reason = '';
    this.transporter.description = '';
    this.transporter.incidentDateTime = null;
    this.blacklistShow = true;
    this.blaclistingChange = this.blaclistingIncidentDate = this.blaclistingDescription = this.Blacklistingreason = false;
    this.modalService.open(content, this.ngbModalOptions);
  }



  registerTransporter() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if (this.blaclistingChange == false) {
      //his.Reasonrequired();
      // if (
      //   this.ReasonRequired ||
      //   this.DescriptionRequired ||
      //   this.IncidentRequired
      // ) {
      //   return;
      // }
      if (this.Blacklistingreason && this.blaclistingIncidentDate && this.blaclistingDescription) {
        this.ReasonRequired = this.transporter.reason == '' ? true : false;
        this.ReasonRequired = this.kemarClientService.commonValidation(this.transporter.reason);
        this.IncidentRequired = this.kemarClientService.commonValidation(this.transporter.incidentDateTime);
        this.DescriptionRequired = this.kemarClientService.commonValidation(this.transporter.description);
        if (this.ReasonRequired || this.IncidentRequired || this.DescriptionRequired)
          return
      }
      else if (this.blaclistingDescription) {
        this.DescriptionRequired = this.kemarClientService.commonValidation(this.transporter.description);
        if (this.DescriptionRequired)
          return
      }
    }
    this.transporter.editflag = this.activebuttonShow;
    this.spinner.show();
    this.kemarClientService
      .postPatch<any>(ServiceUrl.registerTransporter, this.transporter)
      .subscribe(
        (res) => {
          Swal.fire('', res.responseMessage, 'success');
          this.getAllTransporters();
          this.closeModalPopup();
          this.spinner.hide();
          this.ReasonRequired = this.IncidentRequired = this.DescriptionRequired = false;
        },
        (r) => {
          if (r.error.status === 409) {
            Swal.fire({
              icon: 'info',
              title: '',
              text: "Can't register duplicate Transporter Code ."
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: '',
              text: r.error.errorMessage
            })
          }
          this.ReasonRequired = this.IncidentRequired = this.DescriptionRequired = false;
          this.spinner.hide();
        }
      );
  }
  // ChangedBlacklist() {
  //   this.blaclistingChange = !this.blaclistingChange;
  // }

  ChangedBlacklist(value: any) {

    if (value === "true") {
      if (this.PreviewsBlackListed === true)
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = false;
      else
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = true;
    }
    else {
      if (this.PreviewsBlackListed === true) {
        this.blaclistingIncidentDate = this.Blacklistingreason = false
        this.blaclistingDescription = true;
      }
      else {
        this.Blacklistingreason = this.blaclistingIncidentDate = this.blaclistingDescription = false;
      }
    }
  }


  public closeModalPopup() {
    this.modalService.dismissAll();
  }
  Reasonrequired() {

    this.submitted = true;
    if (this.transporter.reason == '') {
      this.ReasonRequired = true;
    } else {
      this.ReasonRequired = false;
    }
    if (this.transporter.incidentDateTime == null) {
      this.IncidentRequired = true;
    } else {
      this.IncidentRequired = false;
    }
    if (this.transporter.description == '') {
      this.DescriptionRequired = true;
    } else {
      this.DescriptionRequired = false;
    }
  }

  getVehiclesBlacklistingReason() {
    this.spinner.show();
    this.kemarClientService
      .get<TransporterMasterResponse>(null, ServiceUrl.GetDriverBlacklistingReason)
      .subscribe(
        (response) => {

          this.BlacklistingReason = response;
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  SearchFilter() {
    this.currentPage = 1
    this.getAllTransportersWithPagination(this.currentPage);
  }

  OnPageSizeChange(pageSize: any) {
    this.pageSize = pageSize;
    this.getAllTransportersWithPagination(this.currentPage);
  }

  getAllTransportersWithPagination(currentPage: any) {

    if (!this.kemarClientService.commonValidation(this.transporterMaster) && this.transporterMaster.length < this.pageSize) {
      this.currentPage = 1
      this.getData(1)
    }
    else
      this.getData(currentPage)
  }

  getData(currentPage: any) {
    this.spinner.show();
    var query = {
      currentPage: currentPage,
      rowSize: this.pageSize,
      searchtext: this.filter.trim(),
      selectedColumn: this.selectedColumn
    };
    this.kemarClientService
      .get<any>(null, ServiceUrl.getAllTransportersWithPagination, query)
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.transporterMaster = response;
          response.length > 0 ? this.totalRecords = response[0].totalRecord : this.totalRecords = 0;
          this.currentPage = currentPage;
        },
        (r) => {
          this.spinner.hide();
        }
      );
  }

  keyPresstelephone(event: any) {
    this.kemarClientService.keytelephoneNumbers(event)
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  closePopUp() {
    this.modalService.dismissAll();
    this.ReasonRequired = this.IncidentRequired = this.DescriptionRequired = false;
  }
  DropDownChnange(val: any) {

    if (val === "undefined") {
      this.IsEnableSearchBox = true;
      this.filter = '';
      this.getAllTransportersWithPagination(1);
      return;
    }
    else if (!this.kemarClientService.commonValidation(val)) {
      this.IsEnableSearchBox = false
    }
    else {
      this.IsEnableSearchBox = true
    }
  }
  keyPressBlackListing = (val: any) => this.ReasonRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  keyPressIncidentDate = (val: any) => this.IncidentRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;
  keyPressDescition = (val: any) => this.DescriptionRequired = val.length <= 0 || this.kemarClientService.commonValidation(val) ? true : false;

}

const blacklistedDll: { key: string; value: string }[] = [
  { key: 'Yes', value: 'true' },
  { key: 'No', value: 'false' },
];
