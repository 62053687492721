<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2" *ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Role Management </h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search" placeholder="Search" [(ngModel)]="filter">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Role"
              matTooltipClass="danger-tooltip" *ngIf="userAccessData[0]?.canCreate == true">
              <fa-icon [icon]="faPlus"></fa-icon>
              <!-- <span class="material-icons-round">add</span> -->
            </button>
          </div>
        </div>

        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr. No.</th>
                  <th (click)="sort('name')">Role Name

                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')">Role Group
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>

                  <th (click)="sort('name')">Status
                    <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>


                  <th class="action-key text-center" *ngIf="userAccessData[0]?.canUpdate ==true">Action </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let role of roleMaster | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td>{{role.roleName}}</td>
                  <td>{{role.roleGroup}}</td>
                  <td>
                    <span *ngIf="role.isActive  ==true ">Active</span>
                    <span *ngIf="role.isActive  ==false ">Inactive</span>
                  </td>
                  <td class="text-center" *ngIf="userAccessData[0]?.canUpdate ==true">
                    <span class="edit-btn-icon" type="button" matTooltip="Edit Role" matTooltipClass="danger-tooltip"
                      (click)="editRole(edit, role) ">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>

        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
        </div>

      </div>
    </div>
  </div>
</section>
<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0"> {{headertitle}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="form-group">
          <input type="hidden" class="form-control" name="roleId" value="{{role.roleId}}" />
        </div>
        <div class="form-group mb-2">
          <label for="roleName" class="top-input">Role Name <sup>*</sup></label>
          <input type="text" class="form-control" formControlName="roleName" id="roleName" name="roleName"
            placeholder=" " [(ngModel)]="role.roleName" autocomplete=""
            [ngClass]="{ 'is-invalid': submitted && f.roleName.errors }">
          <div *ngIf="submitted && f.roleName.errors" class="text-danger">
            <div *ngIf="f.roleName.errors.required">
              <small>Role Name is required.</small>
            </div>
            <div *ngIf="f.roleName.errors.minlength">
              <small>
                Role Name must be at least 4 characters.
              </small>
            </div>
            <div *ngIf="f.roleName.errors.maxlength">
              Role Name must not exceed 30 characters.
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <label for="roleGroup">Role Group <sup>*</sup></label>
          <select type="text" class="form-control form-select my-1" placeholder="" value=" " formControlName="roleGroup"
            [ngClass]="{ 'is-invalid': submitted && f.roleGroup.errors }" [(ngModel)]="role.roleGroup">
            <option value='' hidden>--Choose Option--</option>
            <option *ngFor="let role of RoleGroups" [ngValue]="role.Key">
              {{role.status}}</option>
          </select>
          <div *ngIf="submitted && f.roleGroup.errors" class="invalid-feedback">
            <div *ngIf="f.roleGroup.errors.required">
              <small>Role is required.</small>
            </div>
          </div>
        </div>
        <!-- <div class="form-group mb-2 mt-3 select-radio" [hidden]="ShowIsActive">
          <label for="isActive" class="radio-btn mr-1">Active</label>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" [value]="true" [(ngModel)]="role.isActive"
              formControlName="isActive">
          </label>
          <label for="isActive" class="radio-btn mr-1">Inactive</label>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" [value]="false" [(ngModel)]="role.isActive"
              formControlName="isActive">
          </label>
        </div> -->

        <div class="select-radio" [hidden]="ShowIsActive">
          <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="role.isActive"
            formControlName="isActive">
          <label for="active" class="radio-label">Active</label>

          <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="role.isActive"
            formControlName="isActive">
          <label for="inactive" class="radio-label">Inactive</label>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" *ngIf="IsEdit" aria-label="Close" (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary" (click)="registerRole(role)">Save</button>
    </div>
  </div>
</ng-template>

<app-footer></app-footer>