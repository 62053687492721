<ngx-spinner> </ngx-spinner>
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-md-6">
    <div class="input-append">
      <span class="material-icons-sharp icon-in">
        search
      </span>
      <input class="form-control" type="search" name="search" placeholder="Search" [(ngModel)]="filter"
      (ngModelChange)="currentActivePage1 = 1">
    </div>
  </div>
  <div class="col-md-6 text-end">
    <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Milestone Action"
      matTooltipClass="danger-tooltip"  *ngIf="userAccessData[0]?.canCreate == true">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
  </div>
</div>
<div class="table-list custom-table">
  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover mx-auto">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>
          <th (click)="sort('name')">Action Code
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')">Milestone Action
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>

          <th (click)="sort('name')">Status
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th class="action-key text-center"*ngIf="userAccessData[0].canUpdate ==true">Action </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let milestoneAction of milestoneaction | orderBy: key : reverse | filter:filter | paginate: { id : 'pagination2', itemsPerPage: pageSize1, currentPage: currentActivePage1 }; let i = index">
          <td>{{ i +1 + pageSize1*(currentActivePage1-1) }}</td>

          <td style="text-align: left;">{{milestoneAction.actionCode}}</td>
          <td style="text-align: left;">{{milestoneAction.milestoneAction}}</td>

          <td>
            <span *ngIf="milestoneAction.isActive  ==true ">Active</span>
            <span *ngIf="milestoneAction.isActive  ==false ">Inactive</span>
          </td>
          <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
            <span class="edit-btn-icon" type="button" matTooltip="Edit Milestone Action" matTooltipClass="danger-tooltip"
              (click)="editMileStoneAction(edit, milestoneAction) ">
              <fa-icon [icon]="faEdit"></fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination justify-content-end">
  <pagination-controls id="pagination2" (pageChange)="pagechange($event)"></pagination-controls>
</div>

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="form">
        <div class="form-group ml-3">
          <input type="hidden" minlength="3" maxlength="30" class="form-control" name="milestoneActionId"
            value="{{milestoneAction.milestoneActionId}}" />
        </div>
        <div class="form-group mb-2">
          <label for="milestoneAction">Milestone Action <sup>*</sup></label>
          <input type="text" class="form-control" placeholder="" value=" "
            [ngClass]="{ 'is-invalid': submitted && f.milestoneAction.errors }" formControlName="milestoneAction"
            [(ngModel)]="milestoneAction.milestoneAction" />
          <div *ngIf="submitted && f.milestoneAction.errors" class="invalid-feedback">
            <div *ngIf="f.milestoneAction.errors.required">Milestone Action is required.
            </div>
            <div *ngIf="f.milestoneAction.errors.minlength">
              Milestone Action must be at least 3 characters.
            </div>
            <div *ngIf="f.milestoneAction.errors.maxlength">
              Milestone Action must not exceed 30 characters.
            </div>
            <div *ngIf="f.milestoneAction.hasError('whitespace')"></div>
          </div>
        </div>
        <div class="form-group mb-2">
          <label for="actionCode">Action Code <sup>*</sup></label>
          <input type="text" class="form-control" placeholder="" value=" "
            [ngClass]="{ 'is-invalid': submitted && f.actionCode.errors }" formControlName="actionCode"
            [(ngModel)]="milestoneAction.actionCode" />
          <div *ngIf="submitted && f.actionCode.errors" class="invalid-feedback">
            <div *ngIf="f.actionCode.errors.required">Milestone Action Code is required.
            </div>
            <div *ngIf="f.actionCode.errors.minlength">
              Milestone Action Code must be at least 2 characters.
            </div>
            <div *ngIf="f.actionCode.errors.maxlength">
              Milestone Action Code must not exceed 30 characters.
            </div>
          </div>
        </div>

        <div class="select-radio" *ngIf="activebuttonHide">
          <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="milestoneAction.isActive"
            formControlName="isActive">
          <label for="active" class="radio-label">Active</label>

          <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="milestoneAction.isActive"
            formControlName="isActive">
          <label for="inactive" class="radio-label">Inactive</label>
        </div>



        <!-- <div class="form-group mb-2 select-radio" *ngIf="activebuttonShow">
            <label for="isActive" class="radio-btn">Active</label>
            <label class="form-check-inline">
              <input class="form-check-input" type="radio" name="isActive" [value]="true" formControlName="isActive"
                [(ngModel)]="milestoneAction.isActive">
            </label>
            <label for="isActive" class="radio-btn ">Inactive</label>
            <label class="form-check-inline">
              <input class="form-check-input" type="radio" name="isActive" [value]="false" formControlName="isActive"
                [(ngModel)]="milestoneAction.isActive">
            </label>
          </div> -->

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary"
        (click)="registerMilestoneAction(milestoneAction)">Save</button>
    </div>
  </div>
</ng-template>