<ngx-spinner></ngx-spinner>
<!-- <mat-tab-group animationDuration="0ms" mat-align-tabs="end"> -->
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-md-12 text-end">
    <div class="input-append">
      <span class="material-icons-sharp icon-in">
        search
      </span>
      <input class="form-control" type="search" name="search" placeholder="search" [(ngModel)]="filter"
      (ngModelChange)="currentActivePage = 1">
    </div>
    <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add SLA" matTooltipClass="danger-tooltip"
    *ngIf="userAccessData[0].canCreate ==true">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
  </div>
</div>


<div class="table-list custom-table">
  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover mx-auto tbl-formating">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>

          <th (click)="sort('name')" class="">Event Name
            <span class="glyphicon glyphicon-chevron-up" 
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span> 

          <th (click)="sort('name')" class=" ">Alert Time(mins)
            <span class="glyphicon glyphicon-chevron-up"  
            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>

          <th (click)="sort('name')" class=" ">Exception Time(mins)
            <span class="glyphicon glyphicon-chevron-up" 
            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>

          <th click="sort('name')" class=" ">Ticket SLA(mins)
            <span class="glyphicon glyphicon-chevron-up"  
            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>

          <th (click)="sort('name')" class=" ">Ticket Priority
            <span class="glyphicon glyphicon-chevron-up" 
            [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Subject
            <span class="glyphicon glyphicon-chevron-up" 
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>

          <th (click)="sort('name')" class="">Description
            <span class="glyphicon glyphicon-chevron-up" 
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>

          <th (click)="sort('name')" class="">Status
            <span class="glyphicon glyphicon-chevron-up" 
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th class="col action-key text-center"*ngIf="userAccessData[0].canUpdate ==true">Action </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ticketmaster of transactionSLAmaster | orderBy: key : reverse | filter:filter | paginate: {itemsPerPage: pageSize, currentPage: currentActivePage}; let i = index">
          <td class="left">{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
          <td class="left">{{ticketmaster.eventName}}</td>
          <td class="left">{{ticketmaster.alertTimeInMinutes}}</td>
          <td class="left">{{ticketmaster.exceptionTimeInMinutes}}</td>
          <td class="left">{{ticketmaster.ticketSLAInMinutes}}</td>
          <td class="left">{{ticketmaster.ticketPriority}}</td>
          <td class="left">{{ticketmaster.subject}}</td>
          <td class="left">{{ticketmaster.description}}</td>

          <td class="text-center">
            <span *ngIf="ticketmaster.isActive  ==true ">Active</span>
            <span *ngIf="ticketmaster.isActive  ==false ">Inactive</span>
          </td>
          <td class="text-center"*ngIf="userAccessData[0].canUpdate ==true">
            <span class="edit-btn-icon" type="button" matTooltip="Edit SLA" matTooltipClass="danger-tooltip"
              (click)="editSLA(edit, ticketmaster) ">
              <fa-icon [icon]="faEdit"></fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination justify-content-end">
  <pagination-controls  (pageChange)="currentActivePage = $event"></pagination-controls>
</div>

  

<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <form autocomplete="off" [formGroup]="form">
              <div class="row">

                <div class="col-md-6 mb-2">
                  <label for="eventCode">Event Name <sup>*</sup></label>
                  <select type="text" class="form-control form-select" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.eventCode.errors }" formControlName="eventCode"
                    (change)="ChangeMilestoneName()" [(ngModel)]="ticketmaster.eventCode">
                    <option value='' selected hidden>--Choose Option--</option>
                    <option *ngFor="let eventname of milestoneMaster" [ngValue]="eventname.milestoneCode">
                      {{eventname.milestoneName}}- {{eventname.milestoneEvent}}
                    </option>
                  </select>
                  <div *ngIf="submitted && f.eventCode.errors" class="text-danger">
                    <div *ngIf="f.eventCode.errors.required">
                      <small>Event Name is required.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-2">
                  <label for="alertTimeInMinutes">Alert Time(mins)<sup>*</sup></label>
                  <input type="text" (keypress)="acceptnumber($event, true)" minlength="1" maxlength="4"
                    class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.alertTimeInMinutes.errors }"
                    formControlName="alertTimeInMinutes" [(ngModel)]="ticketmaster.alertTimeInMinutes" />
                  <div *ngIf="submitted && f.alertTimeInMinutes.errors" class="text-danger">
                    <div *ngIf="f.alertTimeInMinutes.errors.required">
                      <small>Alert Time is required.</small>
                    </div>
                    <div *ngIf="f.alertTimeInMinutes.errors.pattern">
                      <small>Alert Time can not start with Zero.</small>
                    </div>
                  </div>
                </div>



                <div class="col-md-6 mb-2">
                  <label for="exceptionTimeInMinutes">Exception Time(mins)<sup>*</sup></label>
                  <input type="text" (keypress)="acceptnumber($event, true)" minlength="1" maxlength="4"
                    class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.exceptionTimeInMinutes.errors }"
                    formControlName="exceptionTimeInMinutes" [(ngModel)]="ticketmaster.exceptionTimeInMinutes" />
                  <div *ngIf="submitted && f.exceptionTimeInMinutes.errors" class="text-danger">
                    <div *ngIf="f.exceptionTimeInMinutes.errors.required">
                      <small>Exception Time is required.</small>
                    </div>
                    <div *ngIf="f.exceptionTimeInMinutes.errors.pattern">
                      <small>Exception Time can not start with Zero.</small>
                    </div>

                  </div>
                </div>

                <div class="col-md-6 mb-2">
                  <label for="ticketSLAInMinutes">Ticket SLA(mins)<sup>*</sup></label>
                  <br>
                  <input type="text" (keypress)="acceptnumber($event, true)" minlength="1" maxlength="4"
                    class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.ticketSLAInMinutes.errors }"
                    formControlName="ticketSLAInMinutes" [(ngModel)]="ticketmaster.ticketSLAInMinutes" />
                  <div *ngIf="submitted && f.ticketSLAInMinutes.errors" class="text-danger">
                    <div *ngIf="f.ticketSLAInMinutes.errors.required">
                      <small>Ticket SLA is required.</small>
                    </div>
                    <div *ngIf="f.ticketSLAInMinutes.errors.pattern">
                      <small>Ticket SLA can not start with Zero.</small>
                    </div>

                  </div>
                </div>

                <div class="col-md-6 mb-2">
                  <label for="ticketPriority">Ticket Priority <sup>*</sup></label>
                  <select type="text" class="form-control form-select" placeholder="" value=" "
                    [ngClass]="{'is-invalid': submitted && f.ticketPriority.errors }" formControlName="ticketPriority"
                    [(ngModel)]="ticketmaster.ticketPriority">
                    <option value='' hidden selected>--Choose Option--</option>
                    <option *ngFor="let priority of ticketUrgency" [ngValue]="priority.urgencyId">
                      {{priority.urgency}}
                    </option>
                  </select>
                  <div *ngIf="submitted && f.ticketPriority.errors" class="text-danger">
                    <div *ngIf="f.ticketPriority.errors.required">
                      <small> Ticket Priority is required.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-2">
                  <label for="subject">Subject<sup>*</sup></label>
                  <br>
                  <input type="text" class="form-control" placeholder="" value=" "
                    [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" formControlName="subject"
                    [(ngModel)]="ticketmaster.subject" />
                  <div *ngIf="submitted && f.subject.errors" class="text-danger">
                    <div *ngIf="f.subject.errors.required">
                      <small>Subject is required.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <label for="description">Description<sup>*</sup></label>
                  <textarea type="text" class="form-control" placeholder="" value=" " name="description"
                    [ngClass]="{ 'is-invalid': submitted && f.description.errors}" formControlName="description"
                    [(ngModel)]="ticketmaster.description"></textarea>
                  <div *ngIf="submitted && f.description.errors" class="text-danger">
                    <div *ngIf="f.description.errors.required">
                      <small>Description is required.</small>
                    </div>
                  </div>
                </div>

                <div class="select-radio" [hidden]="ActiveButtonShow">
                  <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="ticketmaster.isActive"
                    formControlName="isActive">
                  <label for="active" class="radio-label">Active</label>

                  <input class="radio-input" id="inactive" type="radio" [value]="false"
                    [(ngModel)]="ticketmaster.isActive" formControlName="isActive">
                  <label for="inactive" class="radio-label">Inactive</label>
                </div>

              </div>


          </form>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" *ngIf="resetbuttonShow" aria-label="Reset"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-primary"
        (click)="registerTransactionSLA(editSLA,ticketmaster)">Save</button>
    </div>
  </div>
</ng-template>