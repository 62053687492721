import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { DriverBlacklistingComponent } from '../driver-blacklisting/driver-blacklisting.component';
import { TransporterBlacklistingComponent } from '../transporter-blacklisting/transporter-blacklisting.component';
import { VehicleBlacklistingComponent } from '../vehicle-blacklisting/vehicle-blacklisting.component';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-blacklisting',
  templateUrl: './blacklisting.component.html',
  styleUrls: ['./blacklisting.component.scss']
})
export class BlacklistingComponent implements OnInit {
  // active = 'top';
  // _component: any;
  // componentsList = [];
  // faBan = faBan
  public userAccessData: UserScreenAccesData;
  constructor(private viewContainerRef: ViewContainerRef,
    
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,private api:DataService) { }

  ngOnInit(): void {
    // this.loadTabComponent("VEHICLE");
    this.userAccessData =this.api.getUserScreenAccessMenu('BLACKLIST');
  }

  // loadTabComponent(_selectedTab: string) {
  //
  //     this._component = "";
  //     if (_selectedTab == "DRIVER")
  //       this._component = DriverBlacklistingComponent;
  //    else if (_selectedTab == "TRANSPORTER")
  //       this._component = TransporterBlacklistingComponent;
  //     else if (_selectedTab == "VEHICLE")
  //       this._component = VehicleBlacklistingComponent;

  //     this.viewContainerRef.detach();
  //     this.viewContainerRef.clear();
  //     const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this._component);
  //     let componentRef = this.viewContainerRef.createComponent(componentFactory);
  //     this.componentsList.push(componentRef);
  //   }

}
