import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";

import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faEdit, faMapPin, faPlus, faSort, faTimes, faWeight } from '@fortawesome/free-solid-svg-icons';
import { WeighBridgeMasterResponse } from 'src/app/models/weighbridgemastermodel';
import { WeighBridgePreferenceResponse } from 'src/app/models/weighbridgePreferencemodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
// import { LocationMasterResponse } from 'src/app/models/locationmastermodel';
// import { LocationGroupMasterResponse } from 'src/app/models/locationgroupmodel';
@Component({
  selector: 'app-weighbridge-preference',
  templateUrl: './weighbridge-preference.component.html',
  styleUrls: ['./weighbridge-preference.component.scss']
})
export class WeighbridgePreferenceComponent implements OnInit {

  public WeighBridgePreference: WeighBridgePreferenceResponse = new WeighBridgePreferenceResponse();
  public userAccessData: UserScreenAccesData;

  weighbridgePreference: any;
  locationMaster:any;
  locationWBMaster:any;
  title: any;
  groupMaster:any;
  locationGroupName:any;
  faMapPin = faMapPin
  faPlus = faPlus
  faEdit = faEdit
  faSort = faSort
  faTimes = faTimes
  faWeight = faWeight
  submitted = false;
  transactionTypeDDL: any = transactionTypeDll;
  weighmentTypeDll: any = weighmentTypeDll;
  priorityDll: any = priorityDll;
  resetbuttonShow:  boolean = true;
  activebuttonShow:  boolean = false;
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  form: FormGroup;
  ngbModalOptions: NgbModalOptions;

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  currentActivePage: number = 1;

  @ViewChild('closebutton') closebutton;

  constructor(private spinner: NgxSpinnerService,

    private formBuilder: FormBuilder,

    private kemarClientService: KemarClientService, private modalService: NgbModal,private api:DataService)  {

    this.form = this.formBuilder.group({

      locationId:[0, [Validators.required,Validators.pattern(/^[1-9]\d*$/)]],
      nearByLocationId: [0, [Validators.required,Validators.pattern(/^[1-9]\d*$/)]],

      transactionType: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ]
      ],
      weighmentType: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
          this.noWhitespaceValidator
        ]
      ],
      priority: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(30),
        ]
      ],
      isActive: ['']

    });
    
    this.userAccessData =this.api.getUserScreenAccessMenu('WBPREF');
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
  ngOnInit(): void {
    this.getAllWeighBridgePreference();
     this.getAllLocationMapping();
    // this.getAllGroup();

  }

  getAllWeighBridgePreference() {
    this.spinner.show();

    this.kemarClientService.get<WeighBridgeMasterResponse>(null, ServiceUrl.getAllWeighBridgePreference)
      .subscribe(
        response => {

          this.spinner.hide();
          this.weighbridgePreference = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  registerWeigbridgePreference() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.spinner.show();
    this.kemarClientService.postPatch<any>(ServiceUrl.registerWeigbridgePreference, this.WeighBridgePreference)
      .subscribe(
        res => {

          Swal.fire('', res.responseMessage, 'success')
          this.getAllWeighBridgePreference();
          this.closeModalPopup();
          this.spinner.hide();
        },
        r => {

          Swal.fire({
            icon: 'error',
            title: '',
            text: r.error.errorMessage
          })
          this.spinner.hide();
          // console.log(r.error.error);
          // console.log(r);
        });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }


  getAllLocationMapping() {

    this.kemarClientService.get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        response => {

          this.locationGroupName = response.filter(x=>x.locationType !='Weighbridge');
          this.locationWBMaster = response.filter(x=>x.locationType=='Weighbridge');

          // this.locationGroupName = response;
          // this.locationGroupName = this.locationMaster.filter(x=>x.ParentLocationCode==null);


          //console.log(this.locationWBMaster);
        },
        r => {
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }




  open(content: any) {
    this.onReset();
    this.title = "Add Weighbridge Preference";


    this.WeighBridgePreference.weighBridgePerferencesId = 0;
    this.WeighBridgePreference.transactionType = null;
    this.WeighBridgePreference.weighmentType = null;
    this.WeighBridgePreference.priority = null;
    this.WeighBridgePreference.isActive = true;
    this.WeighBridgePreference.nearByLocationId = 0;
    this.WeighBridgePreference.locationId = 0;
    this.activebuttonShow = false;
     this.resetbuttonShow = true;

    this.modalService.open(content, this.ngbModalOptions);
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
    this.form.controls['nearByLocationId'].setValue(0);
    this.form.controls['locationId'].setValue(0);
  }


  editWeighBridge(content: any, WeighBridgePreference) {

    this.onReset();
    this.title = "Update Weighbridge Preference";


   this.WeighBridgePreference.weighBridgePerferencesId = WeighBridgePreference.weighBridgePerferencesId;
   this.WeighBridgePreference.nearByLocationId = WeighBridgePreference.nearbyLocationId;
   this.WeighBridgePreference.transactionType = WeighBridgePreference.transactionType;
    this.WeighBridgePreference.locationId= WeighBridgePreference.locationId;

    this.WeighBridgePreference.weighmentType = WeighBridgePreference.weighmentType;

    this.WeighBridgePreference.priority= WeighBridgePreference.priority;
    this.WeighBridgePreference.isActive= WeighBridgePreference.isActive;
    this.activebuttonShow = true;
    this.resetbuttonShow = false;
   // this.WeighBridgePreference.isActive = WeighBridgePreference.isActive;
    // console.log(WeighBridgePreference.isActive);
    this.modalService.open(content, this.ngbModalOptions);
  }

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

}
const transactionTypeDll: { key: string, value: string }[] = [
  { key: 'Inbound', value: 'Inbound' },
  { key: 'Outbound', value: 'Outbound' },
  { key: 'Inplant', value: 'Inplant' }
];
const weighmentTypeDll: { key: string, value: string }[] = [
  { key: 'Tare', value: 'Tare' },
  { key: 'Gross', value: 'Gross' }
];
const priorityDll: { key: number, value: number }[] = [
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
];

