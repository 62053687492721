<ngx-spinner> </ngx-spinner>
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-md-4">

  </div>
  <div class="col-md-8 text-end">
    <label class="me-2">
      <select [(ngModel)]="selectedColumn" class="form-control dropdown-select" (change)="DropDownChnange($event.target.value)">
        <!-- <option selected value="undefined">--All--</option> -->
        <option value="1">VRN</option>
        <option value="2">RFID Tag</option>
      </select>
    </label>
    <div class="input-append me-0">
      <input class="form-control ps-2" type="text" name="Search" placeholder="Search"
        [(ngModel)]="filter" (keydown)="EnterBtn($event)" >
    </div>
    <button class="btn btn-primary go-btn mx-2" (click)="SearchFilter()" matTooltip="Search"
      matTooltipClass="danger-tooltip" >
      <fa-icon [icon]="faSearch"></fa-icon>
    </button>
    <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add Non-Logistic Vehicles"
      matTooltipClass="danger-tooltip" *ngIf="userAccessData[0]?.canCreate == true">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
  </div>
</div>
<div class="table-list custom-table" >
  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover mx-auto tbl-formating">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>
          <th (click)="sort('vrn')">Reg No.
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('rfidTagNumber')">RFID Tag
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('vrDate')">Reg Date
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('permitTill')">Permit Validity
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('numberOfWheels')">Wheels
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('engineCapacityCC')">Engine Capacity
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('capacityInTons')">Load Capacity (Ton)
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('tareweightKg')">Tare weight (Kgs)
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('isBlackListed')">Blacklisted
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('isActive')">Status
            <span class="glyphicon glyphicon-chevron-up"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th class="action-key text-center" *ngIf="userAccessData[0]?.canUpdate ==true">Action </th>
        </tr>
      </thead>
      <tbody>
        <input type="hidden" />
        <tr *ngFor="let vehicle of nonLogisticVehicle | paginate: { id: 'thirdPartyVehiclePagination', itemsPerPage: pageSize,
           currentPage: currentActivePage, totalItems: totalRecords }; let i = index">
          <td class="left">{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
          <td class="left">{{vehicle.vrn}}</td>
          <td class="left">{{vehicle.rfidTagNumber}}</td>
          <td class="left">{{vehicle.vrDate | date: 'dd-MM-yyyy'}}</td>
          <td class="left">{{vehicle.permitTill |date: 'dd-MM-yyyy'}}</td>
          <td class="left">{{vehicle.numberOfWheels}}</td>
          <td class="left">{{vehicle.engineCapacityCC}}CC</td>
          <td class="left">{{vehicle.capacityInTons}}</td>
          <td class="left">{{vehicle.tareweightKg}}kg</td>
          <td class="left">
            <span *ngIf="vehicle.isBlackListed  ==true ">YES</span>
            <span *ngIf="vehicle.isBlackListed  ==false ">NO</span>
          </td>
          <td class="left">
            <span *ngIf="vehicle.isActive  ==true ">Active</span>
            <span *ngIf="vehicle.isActive  ==false ">Inactive</span>
          </td>
          <td class="text-center">
            <span class="edit-btn-icon" type="button" matTooltip="Edit Non-Logistic Vehicles"
              matTooltipClass="danger-tooltip" (click)="editNonLogisticVehicle(edit, vehicle)" 
              *ngIf="userAccessData[0]?.canUpdate ==true">
              <fa-icon [icon]="faEdit"></fa-icon>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <div class="pagination justify-content-end">
    <pagination-controls id="thirdPartyVehiclePagination" (pageChange)="pagechange($event)">
    </pagination-controls>
  </div> -->

  <div class="d-flex">
    <div class="col-md-6">
      <span *ngIf="totalRecords >=15">
        Item per page
        <select (change)="OnPageSizeChange(pageSize = $event.target.value)" class="paginationcount">
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
        <span> Total Records : {{totalRecords}} </span>
      </span>
    </div>
    <div class="col-md-6 text-end">
      <div class="pagination justify-content-end">
        <pagination-controls id="thirdPartyVehiclePagination"
          (pageChange)="getAllNonLogisticVehicle(currentActivePage = $event)">
        </pagination-controls>
      </div>
    </div>
  </div>




  <ng-template #edit let-modal class="modal">
    <div class="modal-content custom-modal">
      <div class="modal-header">
        <h4 class="modal-title mb-0">{{title}}</h4>
        <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
          (click)="closeModal()">
          <span class="material-icons-sharp">close</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <form autocomplete="off" [formGroup]="form">
              <div class="form-group">
                <div class="form-group row mb-0">
                  <div class="col-sm-6">
                    <label for="rFIDTagNumber">RFID Tag </label>
                    <br>
                    <input type="text" class="form-control" placeholder="" value=" " minlength="20" maxlength="28"
                      [ngClass]="{ 'is-invalid': submitted && f.rfidTagNumber.errors }" style="text-transform:uppercase"
                      formControlName="rfidTagNumber" [(ngModel)]="vehicle.rfidTagNumber" (keyup)="rfidValidationOnChange()" />
                    <div *ngIf="submitted && f.rfidTagNumber.errors" class="text-danger">
                      <div *ngIf="f.rfidTagNumber.errors.required">
                        <small>RFID Tag number is required.</small>
                      </div>
                      <div *ngIf="f.rfidTagNumber.errors.minlength">
                        <small>
                          <sup>*</sup> RFID Tag number is too short.
                        </small>
                      </div>
                      <div *ngIf="f.rfidTagNumber.errors?.pattern">
                        <small> <sup>*</sup>RFID Tag field should contain 'A-F,0-9' only.</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label for="vrn">Registration No. <sup>*</sup></label>
                    <br>
                    <input type="text" class="form-control" placeholder="" value=" " minlength="6" maxlength="11"
                      [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }" style="text-transform:uppercase"
                      formControlName="vrn" [(ngModel)]="vehicle.vrn" (keypress)="keyPressAlphaNumeric($event)" />
                    <div *ngIf="submitted && f.vrn.errors" class="text-danger">
                      <div *ngIf="f.vrn.errors.required">
                        <small>Registration No is required.</small>
                      </div>
                      <div *ngIf="f.vrn.errors.minlength">
                        <small>
                          <sup>*</sup>Registration No is invalid.
                        </small>
                      </div>
                      <div *ngIf="f.vrn.hasError('whitespace')"></div>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-6">
                    <label>Vehicle Registration Date <sup>*</sup></label>
                    <input type="date" formControlName="vrDate" (keypress)="keyonDate($event)"
                      class="form-control datetime-local" [max]="currentdate" min="2000-04-30"
                      [ngClass]="{ 'is-invalid': submitted && f.vrDate.errors }" [(ngModel)]="vehicle.vrDate" />
                    <div *ngIf="submitted && f.vrDate.errors" class="invalid-feedback">
                      <div *ngIf="f.vrDate.errors.required">Date is required.</div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label>Permit Validity <sup>*</sup></label>
                    <input type="date" formControlName="permitTill" (keypress)="keyonDate($event)"
                      class="form-control datetime-local" [min]="currentdate" max="2060-04-30"
                      [ngClass]="{ 'is-invalid': submitted && f.permitTill.errors }" [(ngModel)]="vehicle.permitTill" />
                    <div *ngIf="submitted && f.permitTill.errors" class="invalid-feedback">
                      <div *ngIf="f.permitTill.errors.required">Permit Validity is required.</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-6">
                    <label for="numberOfWheels">Number Of Wheels <sup>*</sup></label>

                    <input type="text" class="form-control" placeholder="" value=" "
                      (keypress)="acceptnumber($event, true)" minlength="1" maxlength="2"
                      [ngClass]="{ 'is-invalid': submitted && f.numberOfWheels.errors }"
                      formControlName="numberOfWheels" [(ngModel)]="vehicle.numberOfWheels" />
                    <div *ngIf="submitted && f.numberOfWheels.errors" class="text-danger">
                      <div *ngIf="f.numberOfWheels.errors.required">
                        <small>No of Wheels is required.</small>
                      </div>
                      <div *ngIf="f.numberOfWheels.errors.min">
                        <small>
                          <sup>*</sup>invalid input.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label for="tareweightKg">Tare Weight </label>
                    <input type="text" class="form-control" (focusout)="tareWeightValidation($event)"
                      (keypress)="KeyPressMaxLength($event, false)" placeholder="" value=" " name="tareweightKg"
                      (keypress)="acceptnumber($event, false)" minlength="3" maxlength="7"
                      [ngClass]="{ 'is-invalid': submitted && f.tareweightKg.errors }" formControlName="tareweightKg"
                      [(ngModel)]="vehicle.tareweightKg" />
                    <div *ngIf="submitted && f.tareweightKg.errors" class="text-danger">
                      <div *ngIf="f.tareweightKg.errors.required">
                        <small>Tare Weight is required.</small>
                      </div>
                      <div *ngIf="f.tareweightKg.errors.maxlength">
                        <small>
                          <sup>*</sup> Tare Weight must be at least 7 digit.
                        </small>
                      </div>
                      <div *ngIf="f.tareweightKg.errors?.pattern">
                        <small> Capacity should contain only Numbers</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-6">
                    <label for="engineCapacityCC">Capacity (in CC) </label>
                    <input type="text" class="form-control" placeholder="" value=" " name="engineCapacityCC"
                      [ngClass]="{ 'is-invalid': submitted && f.engineCapacityCC.errors }"
                      (keypress)="acceptnumber($event, true)" minlength="3" maxlength="7"
                      formControlName="engineCapacityCC" [(ngModel)]="vehicle.engineCapacityCC" />
                    <div *ngIf="submitted && f.engineCapacityCC.errors" class="text-danger">
                      <div *ngIf="f.engineCapacityCC.errors.required">
                        <small>Capacity(in CC) is required.</small>
                      </div>
                      <div *ngIf="f.engineCapacityCC.errors.minlength">
                        <small>
                          Capacity(in CC) should be betwween 3 to 5 characters.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label for="capacityInTons">Load Carrying Capacity (In Tons) </label>
                    <input type="text" class="form-control" (focusout)="tonValidation($event)"
                      (keypress)="KeyPressMaxLength($event, true)" placeholder="" value=" " name="capacityInTons"
                      [ngClass]="{ 'is-invalid': submitted && f.capacityInTons.errors }"
                      formControlName="capacityInTons" [(ngModel)]="vehicle.capacityInTons" minlength="3"
                      maxlength="7" />
                    <div *ngIf="submitted && f.capacityInTons.errors" class="text-danger">
                      <div *ngIf="f.capacityInTons.errors.required">
                        <small>Capacity(In Tons) is required.</small>
                      </div>
                      <div *ngIf="f.capacityInTons.errors.maxlength">
                        <small>
                          <sup>*</sup> capacityInTons must be at least 7 digit.
                        </small>
                      </div>
                      <div *ngIf="f.capacityInTons.errors?.pattern">
                        <small> Capacity should contain only Numbers.</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0 mt-2">
                  <!-- <div class="col-sm-6">
                  <label for="vehicleType">Vehicle Type </label>
                  <select name="vehicleType" [(ngModel)]="vehicle.vehicleType" class="form-control form-select"
                    formControlName="vehicleType" [ngClass]="{ 'is-invalid': submitted && f.vehicleType.errors }">
                    <option hidden Value='' disabled>--Choose Option--</option>
                    <option *ngFor="let vehicleType of vehicleTypeDll" [ngValue]="vehicleType.value">
                      {{vehicleType.key}}</option>
                  </select>
                </div> -->
                  <div class="col-sm-6" [hidden]="IsactiveShow">
                    <label for="isBlackListed">BlackListed </label>
                    <select name="isBlackListed" (change)="ChangedBlacklist($event.target.value)"
                      [(ngModel)]="vehicle.isBlackListed" class="form-control form-select"
                      formControlName="isBlackListed" [ngClass]="{ 'is-invalid': submitted && f.isBlackListed.errors }">
                      <option hidden Value='' disabled>--Choose Option--</option>
                      <option [value]="true">Yes</option>
                      <option [value]="false">No</option>
                    </select>
                  </div>
                </div>
                <div class="select-radio" [hidden]="IsactiveShow">
                  <input class="radio-input" type="radio" id="active" [value]="true" [(ngModel)]="vehicle.isActive"
                    formControlName="isActive">
                  <label for="active" class="radio-label">Active</label>
                  <input class="radio-input" id="inactive" type="radio" [value]="false" [(ngModel)]="vehicle.isActive"
                    formControlName="isActive">
                  <label for="inactive" class="radio-label">Inactive</label>
                </div>
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-6" *ngIf="Blacklistingreason">
                    <label for="locationName">Reason<sup>*</sup></label>
                    <select name="ReasonRequired" (change)="keyPressBlackListing($event.target.value)"
                      [(ngModel)]="vehicle.reason" class="form-control form-select" formControlName="reason"
                      [ngClass]="{ 'is-invalid': submitted && ReasonRequired }">
                      <option hidden Value='' disabled>--Choose Option--</option>
                      <option *ngFor="let vehicle of BlacklistingReason" [ngValue]="vehicle.reason">
                        {{vehicle.reason}}</option>
                    </select>
                    <div *ngIf="submitted && ReasonRequired" class="text-danger">
                      <small> Reason is required.</small>
                    </div>
                  </div>
                  <div class="col-sm-6" *ngIf="blaclistingIncidentDate">
                    <label for="incidentDateTime">Incident Date <sup>*</sup></label>
                    <input type="date" class="form-control" placeholder="" value=" " name="incidentDateTime"
                      [ngClass]="{ 'is-invalid': submitted &&  DateRequired }"  (keypress)="KeyPressDate($event)"
                      (change)="keyPressIncidentDate($event.target.value)" formControlName="incidentDateTime"
                      [(ngModel)]="vehicle.incidentDateTime" />
                    <div *ngIf="submitted &&  DateRequired" class="invalid-feedback">
                      <div *ngIf=" DateRequired">Date is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" *ngIf="blaclistingDescription">
                    <label for="DescriptionRequired">Description<sup>*</sup></label>
                    <textarea (keyup)="keyPressDescition($event.target.value.trim())" type="text" class="form-control"
                      placeholder="" value=" " name="DescriptionRequired"
                      [ngClass]="{ 'is-invalid': submitted && DescriptionRequired }" formControlName="description"
                      [(ngModel)]="vehicle.description"></textarea>
                    <div *ngIf="submitted && DescriptionRequired" class="text-danger">
                      <div *ngIf="DescriptionRequired">
                        <small>Description is required.</small>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="modal-button btn btn-default" aria-label="Close"
          (click)="closeModal()">Close</button>
        <button type="button" class="modal-button btn btn-danger" *ngIf="resetbuttonShow" aria-label="Close"
          (click)="onReset()">Reset</button>
        <button type="submit" class="modal-button btn btn-primary" (click)="registerVehicle(vehicle)">Save</button>
      </div>
    </div>
  </ng-template>
