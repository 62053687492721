export class TransporterMasterResponse {
    transporterId:number;
    name:string;
    vendorCode:string;
    plantCode:string;
    address:string;
    mobileNo:number;
    telNo:number;
    email:string;
    contactPersonName:string;
    contactPersonNumber:string;
    isBlackListed:boolean;
    isActive:boolean;
    transporterName:string;
    reason: string;
    description: string;
    incidentDateTime: Date;
    editflag : boolean
}
