import { Component, OnInit } from '@angular/core';
import { Legend } from 'chart.js';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';

@Component({
  selector: 'app-bchart',
  templateUrl: './bchart.component.html',
  styleUrls: ['./bchart.component.scss']
})
export class BchartComponent implements OnInit {

  basicData: any;
  bchartLegend: any;
  data: any;
  basicOptions: any
  parkingEntryCount: number = 0;
  plantInCount: number = 0;
  parkingOutCount: number = 0;
  plantOutCount: number = 0;
  maxYaxis: number = 0
  constructor(private kemarClientService: KemarClientService) {
  }

  ngOnInit(): void {
    this.getdashboardChartdata();
  }

  getdashboardChartdata() {
    this.kemarClientService.get<any>(null, ServiceUrl.getCommondashboardchartData)
      .subscribe(
        response => {

          this.parkingEntryCount = response.parkingEntryCount;
          this.plantInCount = response.plantInCount;
          this.parkingOutCount = response.parkingOutCount;
          this.plantOutCount = response.plantOutCount;

          this.maxYaxis = (Math.max(this.parkingEntryCount, this.parkingOutCount, this.plantInCount, this.plantOutCount));
          this.maxYaxis = this.kemarClientService.yAxisBarchartMaxValue(this.maxYaxis + 100);
          this.dataBinding();
        },
        r => {
          // console.log(r.error.error);
        });
  }

  dataBinding() {
    this.basicData = {
      labels: ["Parking", "Plant"],
      datasets: [
        {
          label: "Entry",
          backgroundColor: "#3AD89A",
          data: [this.parkingEntryCount, this.plantInCount,]
        },
        {
          label: "Exit",
          backgroundColor: "#FECA45",
          data: [this.parkingOutCount, this.plantOutCount,]
        }
      ]
    };

    this.basicOptions = {
      scales: {
        y: {
          suggestedMin: 50,
          suggestedMax: this.maxYaxis
      }
      },
      plugins: {
        legend: {
            labels: {
                color: "#black"
            }
        }
    }
    };
  }

}
