export class Pagination{
  searchtext?:any;
page:number;
count:number;
pageSize:number;
filterRecordCount:number;
totalTransactionCount:number
pageSizes:number[];

constructor(page?:number,count?:number,pageSize?:number,filterRecordCount?:number,pagesSizes?:number[]) {
  this.page=page;
  this.count=count;
  this.pageSize=pageSize;
  this.filterRecordCount=filterRecordCount;
  this.pageSizes=pagesSizes;
}

}