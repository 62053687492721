<app-header></app-header>
<section class="my-2"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0">
             {{title}}</h4>
          </div>
          <mat-tab-group animationDuration="10ms" mat-align-tabs="end" >
            <mat-tab label="Transaction">
              <app-service-ticket-sla></app-service-ticket-sla>
            </mat-tab>
            <mat-tab label="Transit">
              <app-transit-sla></app-transit-sla>
                    </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
