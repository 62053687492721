export class JobScheduleMasterResponse{
    jobScheduleId:number;
    jobName:string;
    cronExpression:string;
    jobDescription:string;
    isActive:boolean;
    enableLogging:boolean;
    enableDeepLogging:boolean;
    hourlyLogFrequency:number;
    isEveryDayReset:boolean;

}