export class WbSerachTransactionFunctionality {
    status:string[];
    wbtype:string;
    vrn:string;
    weighbridgeId:number[];
    crossCheckEvent:boolean;
    regularEvent:boolean;
    fromDateFilter:any;
    toDateFilter:any;
    page:number;
    pagesize:number;
    isExcelDownload: boolean = false;
    tranType: string[];

constructor(status:string[]=[],wbtype?:string,vrn?:string,weighbridgeId:number[]=[],crossCheckEvent?:boolean,regularEvent?:boolean,fromDateFilter?:any,toDateFilter?:any,
  tranType:string[]=[]) {
    this.status=status;
    this.wbtype=wbtype;
    this.vrn=vrn;
    this.weighbridgeId=weighbridgeId;
    this.crossCheckEvent=crossCheckEvent;
    this.regularEvent=regularEvent;
    this.fromDateFilter=fromDateFilter;
    this.toDateFilter=toDateFilter;
    this.tranType = tranType;
  }
}
