<app-ut-header-common></app-ut-header-common>
<main id="main" class="page-content content-wrap mb-4">
  <div class="page-inner color">
    <div id="main-wrapper" class="card-alert">
      <h3 class="panel-title mb-2">Control Tower Dashboard</h3>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="panelCardBody panel panel-one">
            <div class="panel-body">
              <div class="row d-flex align-items-center">
                <div class="col-6 info-box-stats text-center" >
                  <p class="counter">{{timeLapse}}</p>
                  <span class="info-box-title">Delayed</span>
                </div>
                <div class="col-6 info-box">
                  <ul class="list-unstyled sm-list justify-content-end">
                    <li class="list-item">
                      Gate In : <span class="badge bg-primary rounded-pill">{{timeLapseAtGateIn}}</span>
                    </li>
                    <li class="list-item">
                      Weighment : <span class="badge bg-primary rounded-pill">{{timeLapseAtWeighment}}</span>
                    </li>
                    <li class="list-item">
                      Loading : <span class="badge bg-primary rounded-pill">{{timeLapseAtLoading}}</span>
                    </li>
                    <li class="list-item">
                      Unloading : <span class="badge bg-primary rounded-pill">{{timeLapseAtUnloading}}</span>
                    </li>
                    <li class="list-item">
                      Gate Out : <span class="badge bg-primary rounded-pill">{{timeLapseAtGateOut}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="info-box-icon">
                <!-- <mat-icon>today</mat-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panelCardBody panel panel-two">
            <div class="panel-body">
              <div class="row d-flex align-items-center">
                <div class="col-6 info-box-stats text-center">
                  <p class="counter">{{transactionTime}}</p>
                  <span class="info-box-title">Operation Timeout</span>
                </div>
                <div class="col-6 info-box">
                  <ul class="list-unstyled sm-list justify-content-end">
                    <li class="list-item">
                      Gate In : <span class="badge bg-primary rounded-pill">{{transactionAtGateIn}}</span>
                    </li>
                    <li class="list-item">
                      Weighment : <span class="badge bg-primary rounded-pill">{{transactionAtWeighment}}</span>
                    </li>
                    <li class="list-item">
                      Loading : <span class="badge bg-primary rounded-pill">{{transactionAtLoading}}</span>
                    </li>
                    <li class="list-item">
                      Unloading : <span class="badge bg-primary rounded-pill">{{transactionAtUnloading}}</span>
                    </li>
                    <li class="list-item">
                      Gate Out : <span class="badge bg-primary rounded-pill">{{transactionAtGateOut}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panelCardBody panel panel-three">
            <div class="panel-body">
              <div class="row d-flex align-items-center">
                <div class="col-6 info-box-stats text-center">
                  <p class="counter">{{deviceError}}</p>
                  <span class="info-box-title">Device Error</span>
                </div>
                <div class="col-6 info-box">
                  <ul class="list-unstyled sm-list justify-content-end">
                    <li class="list-item">
                      CCTV : <span class="badge bg-primary rounded-pill">{{cctvDeviceNotAvilable}}</span>
                    </li>
                    <li class="list-item">
                      RFID : <span class="badge bg-primary rounded-pill">{{rfidDeviceNotAvilable}}</span>
                    </li>
                    <li class="list-item">
                      LED : <span class="badge bg-primary rounded-pill">{{ledDeviceNotAvilable}}</span>
                    </li>
                    <li class="list-item">
                      Other Devices : <span class="badge bg-primary rounded-pill">{{othersDeviceNotAvilable}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panelCardBody panel panel-four">
            <div class="panel-body">
              <div class="row d-flex align-items-center">
                <div class="col-6 info-box-stats text-center">
                  <p class="counter">{{userQueries}}</p>
                  <span class="info-box-title">User Query</span>
                </div>
                <div class="col-6 info-box">
                  <ul class="list-unstyled sm-list justify-content-end">
                    <li class="list-item">
                      Plant Gate : <span class="badge bg-primary rounded-pill">{{userQueriesAtOnPlantGate}}</span>
                    </li>
                    <li class="list-item">
                      Weighment : <span class="badge bg-primary rounded-pill">{{userQueriesAtWeighment}}</span>
                    </li>
                    <li class="list-item">
                      Loading : <span class="badge bg-primary rounded-pill">{{userQueriesAtLoading}}</span>
                    </li>
                    <li class="list-item">
                      Unloading : <span class="badge bg-primary rounded-pill">{{userQueriesAtUnloading}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panelCardBody panel panel-five">
            <div class="panel-body">
              <div class="row d-flex align-items-center">
                <div class="col-6 info-box-stats text-center">
                  <p class="counter">{{plantGateTicketTickets}}</p>
                  <span class="info-box-title">Plant Gate</span>
                </div>
                <div class="col-6 info-box">
                  <ul class="list-unstyled sm-list justify-content-end">
                    <li class="list-item">
                      Delayed : <span class="badge bg-primary rounded-pill">{{plantGateOfTimeLapse}}</span>
                    </li>
                    <li class="list-item">
                      Opr Timeout : <span class="badge bg-primary rounded-pill">{{plantGateOfTransaction}}</span>
                    </li>
                    <li class="list-item">
                      User Query : <span class="badge bg-primary rounded-pill">{{plantGateOfUserQuesries}}</span>
                    </li>
                    <li class="list-item">
                      Device Error : <span class="badge bg-primary rounded-pill">{{deviceErrorOnPlantgate}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panelCardBody panel panel-six">
            <div class="panel-body">
              <div class="row d-flex align-items-center">
                <div class="col-6 info-box-stats text-center">
                  <p class="counter">{{weighbridgeTickets}}</p>
                  <span class="info-box-title">Weighbridge</span>
                </div>
                <div class="col-6 info-box">
                  <ul class="list-unstyled sm-list justify-content-end">
                    <li class="list-item">
                      Delayed : <span class="badge bg-primary rounded-pill">{{weighbridgeOfTimeLapse}}</span>
                    </li>
                    <li class="list-item">
                        Opr Timeout : <span class="badge bg-primary rounded-pill">{{weighbridgeOfTransaction}}</span>
                    </li>
                    <li class="list-item">
                      User Query : <span class="badge bg-primary rounded-pill">{{weighbridgeOfUserQuesries}}</span>
                    </li>
                    <li class="list-item">
                      Device Error : <span class="badge bg-primary rounded-pill">{{deviceErrorOnWeighbridge}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panelCardBody panel panel-seven">
            <div class="panel-body">
              <div class="row d-flex align-items-center">
                <div class="col-6 info-box-stats text-center">
                  <p class="counter">{{loadingStationTickets}}</p>
                  <span class="info-box-title">Loading</span>
                </div>
                <div class="col-6 info-box">
                  <ul class="list-unstyled sm-list justify-content-end">
                    <li class="list-item">
                      Delayed : <span class="badge bg-primary rounded-pill">{{loadingOfTimeLapse}}</span>
                    </li>
                    <li class="list-item">
                        Opr Timeout : <span class="badge bg-primary rounded-pill">{{loadingOfTransaction}}</span>
                    </li>
                    <li class="list-item">
                      User Query : <span class="badge bg-primary rounded-pill">{{loadingOfUserQuesries}}</span>
                    </li>
                    <li class="list-item">
                      Device Error : <span class="badge bg-primary rounded-pill">{{deviceErrorOnLoading}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panelCardBody panel panel-eight">
            <div class="panel-body">
              <div class="row d-flex align-items-center">
                <div class="col-6 info-box-stats text-center">
                  <p class="counter">{{unloadingStationTickets}}</p>
                  <span class="info-box-title">Unloading</span>
                </div>
                <div class="col-6 info-box">
                  <ul class="list-unstyled sm-list justify-content-end">
                    <li class="list-item">
                      Delayed : <span class="badge bg-primary rounded-pill">{{unloadingOfTimeLapse}}</span>
                    </li>
                    <li class="list-item">
                      Opr Timeout : <span class="badge bg-primary rounded-pill">{{unloadingOfTransaction}}</span>
                    </li>
                    <li class="list-item">
                      User Query : <span class="badge bg-primary rounded-pill">{{unloadingOfUserQuesries}}</span>
                    </li>
                    <li class="list-item">
                      Device Error : <span class="badge bg-primary rounded-pill">{{deviceErrorOnUnLoading}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div class="row mb-4" *ngIf="userRole == 'CT Supervisor'">
        <div class="col-lg-8">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex data-filter align-items-center mb-2">
                <div class="flex-grow-1">
                  <h4 class="panel-title mb-0">High Priority</h4>
                </div>
                <div class="flex-shrink-0 justify-content-center">
                  <div class="input-append me-0">
                    <span class="material-icons-sharp icon-in">search</span>
                    <input type="text" class="form-control" placeholder="Search by keyword" [(ngModel)]="filter">
                  </div>
                </div>
              </div>

              <div class="table-responsive sm-table">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Ticket No</th>
                      <th>VRN</th>
                      <th>Driver Name</th>
                      <th>Category</th>
                      <th>Sub Category</th>
                      <th>Assigned User</th>
                      <th>Location Name</th>
                      <th>Ticket Descirption</th>
                      <th>SLA </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr *ngFor="let ticket of allActiveTickets | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: 10, currentPage: currentActiveAlertPage }; let i = index">
                      <td class="text-primary"><a >{{ticket.ticketNo}}</a></td>
                      <td>{{ticket.vrn}}</td>
                      <td>{{ticket.driverName}}</td>
                      <td>{{ticket.category}}</td>
                      <td>{{ticket.subCategory}}</td>
                      <td>{{ticket.assignedUser}}</td>
                      <td>{{ticket.locationName}}</td>
                      <td>{{ticket.ticketDescription}}</td>
                      <td>{{ticket.serviceSLATime}}</td>

                    </tr>
                  </tbody>
                </table>

                <div class="pagination justify-content-end mt-2">
                  <pagination-controls (pageChange)="currentActiveAlertPage = $event"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card h-100 agents-tickets">
            <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                    <div class="flex-grow-1">
                        <h4 class="panel-title mb-0">Agents <small>2/22</small></h4>
                    </div>
                    <div class="flex-shrink-0">
                        <ul class="list-unstyled list-group legend">
                            <li>SLA Crossed <span></span></li>
                            <li>Tickets Pending <span></span></li>
                            <li>Tickets completed <span></span></li>
                        </ul>
                    </div>
                </div>

              <ul class="list-unstyled list-group list-with-icon vrScrollbar">
                <li class="d-flex align-items-center" *ngFor="let ctUserDetails of getCTUserDetails">
                  <div class="avatar avatar-2xl status-offline" [ngClass]="{'status-offline': ctUserDetails.isLogIn == 0, 'status-online': ctUserDetails.isLogIn == 1 }">
                    <span class="material-icons-round"> account_circle </span>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h3 class="user-name">{{ctUserDetails.firstName +' '+ ctUserDetails.lastName }} </h3>
                    <p>Login today at {{ctUserDetails.logInTime}}</p>
                  </div>
                  <div class="flex-shrink-0">
                    <span class="badge bg-sla rounded-pill" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Tickets SLA Crossed">{{ctUserDetails.completedTicket}}</span>
                    <span class="badge bg-pending rounded-pill" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Tickets Pending">{{ctUserDetails.inProgressTicket}}</span>
                    <span class="badge bg-completed rounded-pill" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Tickets completed">{{ctUserDetails.onHoldTicket}}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

        <section class="my-4">
          <div class="row mb-4" *ngIf="userRole != 'CT Operator' && userRole != 'CT Supervisor'">
            <div class="col-lg-8">
              <div class="card">
                <div class="card-body">
                  <h4 class="panel-title">7 Days Report </h4>
                  <p-chart type="bar" [data]="bChart" width="auto" height="370px" [options]="bchartLegend"></p-chart>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card h-100">
                <div class="card-body">
                  <div class="panel-heading">
                    <h4 class="panel-title">High Priority Tickets</h4>
                    <div class="panel-control">
                      <a data-toggle="tooltip" data-placement="top" title="Reload" class="panel-reload"><i
                          class="icon-reload"></i></a>
                    </div>
                  </div>
                  <div class="ticket-widget vrScrollbar">
                    <a *ngFor="let alert of highPriorityTicketData">
                      <div class="ticket-item">
                        <p class="inbox-item-author"><strong class="head">Ticket No.:
                          </strong><span>{{alert.ticketNo}}</span>
                        </p>
                        <p class="ticket-item-text"><strong class="head">Location:
                          </strong><span>{{alert.locationName}}</span></p>
                        <p class="ticket-item-status text-danger">{{alert.status}}</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

    </div><!-- Main Wrapper -->
  </div><!-- Page Inner -->
</main><!-- Page Content -->














