import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ServiceUrl } from 'src/app/common/service-urls';
import { ELVResponseModel } from 'src/app/models/elvmodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { faBan, faPlus, faSort,faSearch, faTrash, faForward, faEdit } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, Form, FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-elv-support',
  templateUrl: './elv-support.component.html',
  styleUrls: ['./elv-support.component.scss']
})
export class ElvSupportComponent implements OnInit {

  public userAccessData: UserScreenAccesData;
  fromDate: any;
  toDate: any;
  today:Date;
  tranType:number =0;
  activeElvTransactionActive: any;
  validtil: any;
  tempcurrentActivePage: number = 1;
  currentActivePage: number = 1;
  pageSize = 15;
  filter = '';
  reverse: boolean = false;
  key: string = 'name'; //set default
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  tranTypeActive = 0;
  status='';
  faPlus = faPlus
  faSearch = faSearch
  faSort = faSort
  faDelete = faTrash;
  faForward = faForward;
  faEdit = faEdit;
  title: any;
  resetbuttonShow: boolean = true;
  form: FormGroup;
  elvId:number;
  submitted = false;
  constructor(private kemarClientService: KemarClientService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private modalService: NgbModal,
              private datePipe: DatePipe) {
                this.today = new Date();
                this.form = this.formBuilder.group({
                  status: ['',[Validators.required],
                  ],
                  remarks: ['',
                    [
                      Validators.required,
                      Validators.minLength(6)
                    ],
                  ],
                });
               }

  ngOnInit(): void {
    const today = new Date();
    const formattedDate = this.datePipe.transform(today,'yyyy-MM-dd');
    this.fromDate = formattedDate;
    this.toDate = formattedDate;
    this.getallELVlist();
  }
  getallELVlist() {
    debugger;
    this.spinner.show();
    var fromDateValue = this.fromDate;
    var toDateValue = this.toDate;
    
    var query ={
      status:this.status,
      fromDate : fromDateValue,
      toDate : toDateValue,
      tranType: this.tranType
    }
    this.kemarClientService.get<ELVResponseModel>(null, ServiceUrl.getAllElvlist,query)
      .subscribe(
        response => {
          this.spinner.hide();
          
            this.activeElvTransactionActive = response;
          
        },
        r => {
          this.spinner.hide();
        });
  }
  editELV(content : any, elv){
    debugger
    this.title = 'Update Vehicle';
    this.elvId = elv.elvId;
    (this.resetbuttonShow = false),
      this.modalService.open(content, this.ngbModalOptions);

  }
  updateELV(){
    debugger
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    var body =this.form.value;
    this.spinner.show();
    var query = {
      elvId: this.elvId,          
      status: body.status,       
      remarks: body.remarks       
    };
    this.kemarClientService.get<any>(null,ServiceUrl.updateELVsupport, query)
    .subscribe(
      res => {
        if(res.statusCode==404){
          Swal.fire('',res.errorMessage,'error').then(() => {
            this.dissmiss();
          });
        }
        else{
          Swal.fire('',res.responseMessage,'success').then(() => {
            this.dissmiss();
          });
        }
        this.spinner.hide();
        this.getallELVlist();
        
      },
      r => {
        debugger;
        Swal.fire('',r.error.errorMessage,'error');
        this.modalService.dismissAll();
      }
    )
  }
  dissmiss(){
    this.onReset();
    this.modalService.dismissAll()
  }
  pagechange = (event: number) => {  this.tempcurrentActivePage = this.currentActivePage = event; }
  
  KeyPressDate = (event: any) => this.kemarClientService.keyonDate(event);
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  public closeModalPopup() {
    this.modalService.dismissAll();
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  onReset() {
    this.submitted = false;
    this.form.reset();
    this.form.controls['status'].setValue('');
    //this.form.controls['deviceLocationId'].setValue('');
    this.form.controls['remarks'].setValue('');
  }
}
