<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- <section class="my-2" *ngIf="userAccessData[0]?.canRead ==true"> -->
    <section class="my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <!-- <div class="position-absolute mt-2">
            <h4 class="title-one mb-0">
              Expected List of Vehicles
            </h4>
          </div> -->
          <!-- <mat-tab-group animationDuration="10ms" ngbNav #nav="ngbNav" [(activeId)]="active"  mat-align-tabs="end"> -->
            <!-- <mat-tab label="Active" ngbNavItem="top" > -->
              <div class="d-flex data-filter align-items-center my-2 m_d-flex">
                <div class="col-md-2 col-10">
                  <div class="input-append">
                    <span class="material-icons-sharp icon-in">search</span>
                    <input class="form-control " type="search" name="search" placeholder="search" (ngModelChange)="currentActivePage = 1"
                  [(ngModel)]="filter">
                  </div>
                </div>
                <div class="col-md-10 text-end" id="input-filter">
                  <label for="fromDate" class="mr-2">Status:</label>
                    <select name="tranType"
                    class="form-control form-select" name="tranType" id="tranType" autocomplete="" [(ngModel)]="status">
                        <option value=''>-- Select Status --</option>
                        <option value ='Completed' >Completed</option>
                        <option value ='Pending' >Pending</option>
                        <option value ='Cancelled' >Cancelled</option>
                        <option value ='Expired' >Expired</option>
                  </select>

                    <label for="fromDate" class="mr-2">Tran Type:</label>
                    <select name="tranType"
                    class="form-control form-select" name="tranType" id="tranType" autocomplete="" [(ngModel)]="tranType">
                        <option value='0'>-- All Tran Type --</option>
                        <option value ='1' >Outbound</option>
                        <option value ='2' >Inbound</option>
                        <option value ='4' >InPlant</option>
                  </select>
                    <label for="fromDate" class="mr-2">From:</label>
                    <input class="form-control date mr-2" name="fromDate" (keypress)="KeyPressDate($event)"
                       type="date" [(ngModel)]="fromDate" id="fromDate" max="{{ today | date:'yyyy-MM-dd' }}">
                    <label for="toDate" class="mr-2">To:</label>
                    <input class="form-control date mr-2" name="toDate" (keypress)="KeyPressDate($event)"
                      [(ngModel)]="toDate" type="date" id="toDate" max="{{ today | date:'yyyy-MM-dd' }}">
                    <button class="btn btn-primary go-btn" (click)="getallELVlist()" matTooltip="Search"
                      matTooltipClass="danger-tooltip">
                      <fa-icon [icon]="faSearch"></fa-icon>
                    </button>
                  </div>
              </div>
              <div class="table-list custom-table" >
                <div class="table-responsive">
                  <table class="table table-striped MX-auto table-bordered table-hover">
                    <thead class="table-head">
                      <tr>
                        <th class="col-2 sr-no">Sr. No.</th>

                        <th (click)="sort('vrn')" class="col location">VRN
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('elvCode')" class="col location">ELV Code
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('elvCode')" class="col location">Transaction Id
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th (click)="sort('tranType')" class="col location">Trip Type
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('driverName')" class="col location">Driver Name
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        

                        <!-- <th (click)="sort('shipmentNo')" class="col location">Shipment No
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <!-- <th (click)="sort('entryDateTime')" class="col location">Parking InTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <!-- <th (click)="sort('exitDateTime')" class="col location">Parking OutTime
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th> -->
                        <th (click)="sort('validTill')" class="col location">Valid till
                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>

                        <th (click)="sort('status')" class="col location">Trip Status

                          <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                            [ngClass]='glyphicon-chevron-up'>
                            <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                          </span>
                        </th>
                        <th class="col location">Action

                            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
                              [ngClass]='glyphicon-chevron-up'>
                              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                            </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let activeElv of activeElvTransactionActive | orderBy: key : reverse | filter:filter | paginate: { id: 'ActivePagination' ,itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                        <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                        <td>{{activeElv.vrn}}</td>
                        <td>{{activeElv.elvCode}}</td>
                        <td>{{activeElv.vehicleTransactionId}}</td>
                        <td>
                          <span *ngIf="activeElv.tranType  ==1 ">Outbound</span>
                          <span *ngIf="activeElv.tranType  ==2 ">Inbound</span>
                          <span *ngIf="activeElv.tranType  ==4 ">InPlant</span>
                        </td>
                        <td>{{activeElv.driverName}}</td>
                        
                        <!-- <td>{{activeElv.shipmentNo}}</td> -->
                        <!-- <td>{{activeElv.docNumber}}</td> -->
                        <!-- <td>{{activeElv.entryDateTime |date: 'dd-MM-yyyy HH:mm' }}</td> -->
                        <!-- <td>{{activeElv.exitDateTime |date: 'dd-MM-yyyy HH:mm' }}</td> -->
                        <td>{{activeElv.validTill |date: 'dd-MM-yyyy HH:mm' }}</td>
                        <td>{{activeElv.status}}</td>
                        <!-- <td>
                          <span *ngIf="activeElv.isActive  ==true ">Active</span>
                          <span *ngIf="activeElv.isActive  ==false ">Inactive</span>
                        </td> -->
                        <!-- <td class="text-center">
                          <span *ngIf="activeElv.status == 'Pending'" >
                            <fa-icon [icon]="faDelete" class="sorticon"></fa-icon>
                          </span>
                        </td> -->
                        <td *ngIf="plantInfo == 'STAR'">
                          <button class="btn go-btn" (click)="openConfirmationModel(Confirmation, activeElv.elvId)" matTooltip="Delete Transaction"
                            matTooltipClass="danger-tooltip">
                            <fa-icon [icon]="faDelete"></fa-icon>
                          </button>
                        </td>
                        <td *ngIf="plantInfo == 'STAR'">
                          <button class="btn go-btn" (click)="openConfirmationToCompleteModel(ConfirmationToComplete, activeElv.elvId)" matTooltip="Skip Transaction"
                            matTooltipClass="danger-tooltip">
                            <fa-icon [icon]="faForward"></fa-icon>
                          </button>
                        </td>
                        <td class="text-center">
                            <span class="edit-btn-icon" type="button" matTooltip="Edit ELV" matTooltipClass="danger-tooltip"
                              (click)="editELV(edit, activeElv)"> 
                              <!-- (click)="editELV(edit, vehicle)" -->
                              <fa-icon [icon]="faEdit"></fa-icon>
                            </span>
                          </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="pagination justify-content-end">
                <pagination-controls id="ActivePagination" (pageChange)="pagechange($event)">
                </pagination-controls>
              </div>
            <!-- </mat-tab> -->
            
          <!-- </mat-tab-group> -->
        </div>
        <!-- <div [ngbNavOutlet]="nav" class="ml-4"></div> -->
      </div>
    </div>
  </div>

</section>
<app-footer></app-footer>

<ng-template #edit let-modal class="modal">
    <div class="modal-content custom-modal">
      <div class="modal-header">
        <h4 class="modal-title mb-0">{{title}}</h4>
        <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
          (click)="dissmiss()">
          <span class="material-icons-sharp">close</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <form autocomplete="off" [formGroup]="form">
              <div class="form-group">
                <div class="form-group row mb-0 mt-2">
                  <div class="col-sm-12">
                    <label for="vrn">Status<sup>*</sup></label>
                    <br>
                      <select name="Status" class="form-control form-select" formControlName="status"
                              [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                          <option value ='' hidden>--Choose Option--</option>
                          <option value='Pending'>Pending</option>
                          <option value='Cancelled'>Cancelled</option>
                        </select>
                    <div *ngIf="submitted && f.status.errors" class="text-danger">
                      <div *ngIf="f.status.errors.required">
                        <small>Status is required.</small>
                    </div>
                      
                    </div>
                  </div>
                  <!-- <div class="col-sm-6">
                    <label for="rFIDTagNumber">R </label>
                    <br>
                    <input type="text" class="form-control" placeholder="" value=" " minlength="20" maxlength="28"
                      style="text-transform:uppercase" [ngClass]="{ 'is-invalid': submitted && f.rfidTagNumber.errors }"
                      formControlName="rfidTagNumber" [(ngModel)]="vehicle.rfidTagNumber" (onkeyup)="rfidValidationOnChange()" />
                    <div *ngIf="submitted && f.rfidTagNumber.errors" class="text-danger">
                      <div *ngIf="f.rfidTagNumber.errors.required">
                        <small>RFID Tag number is required.</small>
                      </div>
                      <div *ngIf="f.rfidTagNumber.errors.minlength">
                        <small>
                          <sup>*</sup> RFID Tag number is too short.
                        </small>
                      </div>
                      <div *ngIf="f.rfidTagNumber.errors?.pattern">
                        <small> <sup>*</sup>RFID Tag field should contain 'A-F,0-9' only.</small>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="form-group row mb-0 mt-2">
                    <div class="col-sm-12">
                      <label for="remark">Remarks</label>
                      <textarea type="text" class="form-control" placeholder="" value=" " formControlName="remarks"
                      [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }"></textarea>
                      <div *ngIf="submitted && f.remarks.errors" class="text-danger">
                        <div *ngIf="f.remarks.errors.required">
                          <small>Remarks is required.</small>
                        </div>
                        <div *ngIf="f.remarks.errors.minlength">
                          <small>
                            <sup>*</sup>Remarks number is too short.
                          </small>
                        </div>
                      </div>
                      </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="modal-button btn btn-default" aria-label="Close"
          (click)="dissmiss()">Close</button>
        <button type="button" class="modal-button btn btn-danger" *ngIf="resetbuttonShow" aria-label="Close"
          (click)="onReset()">Reset</button>
        <button type="submit" class="modal-button btn btn-primary" (click)="updateELV()">Save</button>
      </div>
    </div>
  </ng-template>
