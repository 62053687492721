import { Injectable } from '@angular/core';
import { TokenStorageService } from '../service/token-storage.service';
import { KemarClientService } from '../service/kemar-client.service';
import { DataService } from '../service/data.service';
import { TokenModel, LoginResponse } from '../models/usermodel';
import { ServiceUrl } from '../common/service-urls';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {
  public refreshToken: TokenModel = new TokenModel();

  constructor(private tokenStorage: TokenStorageService,
    private kemarClientService: KemarClientService,
    private dataService: DataService,
    private router: Router
  ) { }

  gettoken() {
    return !!this.tokenStorage.getToken(this.tokenStorage.getUserName());
  }

  logout() {
    this.router.navigateByUrl('/login');
  }

  getRefreshToken() : any  {
    this.refreshToken.token = this.tokenStorage.getRefreshToken();
    this.kemarClientService.postPatch<LoginResponse>(ServiceUrl.refreshToken, this.refreshToken.token)
      .subscribe(response => {
        if (response.jwtToken !== null) {

          this.dataService.setUserDetail(response);
          this.tokenStorage.saveToken(response.jwtToken, this.tokenStorage.getUserName());
          this.tokenStorage.setRefreshToken(response.refreshToken);
          this.tokenStorage.saveUser(response);
          return response.jwtToken;
        }
      },
        r => {
          // console.log(r);
        });
        return null;
  }
}

