import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ELVResponseModel } from 'src/app/models/elvmodel';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { faBan, faPlus, faSort } from '@fortawesome/free-solid-svg-icons';
import { VehicleParkingCallInRequest } from 'src/app/models/VehicleParkingCallInmodel';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';


@Component({
  selector: 'app-vehicle-parking',
  templateUrl: './vehicle-parking.component.html',
  styleUrls: ['./vehicle-parking.component.scss']
})
export class VehicleParkingComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  active = 'top';
  _component: any;
  componentsList = [];
  faBan = faBan
  faPlus = faPlus
  faSort = faSort
  elvList: any;
  productList: any;
  isChecked: boolean = false;
  isShown: boolean = true;
  transactionType: any = TransactionType;
  vehicleStatus: any = VehicleStatus;
  vehicleParkIn: any;
  activeElvTransaction: any;
  activeElvTransactionExpired: any;
  activeElvTransactionCompleted: any;
  activeElvTransactionActive: any;
  request: any;
  action: any;
  updatereq = [];
  category: string = "All";
  status: string = "All";
  tranType: string = "All";
  reqesst: VehicleParkingCallInRequest = new VehicleParkingCallInRequest();
  public userAccessData: UserScreenAccesData;
  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 50;
  key: string = 'name'; //set default
  reverse: boolean = false;
  mat: any = null;
  select: any = undefined;
  IsAutoCalled: any = false;
  autoCalledUpdate: any;
  locationMaster: any;
  locationId = 0;



  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;

  constructor(private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,private api:DataService)    { }
  ngOnInit(): void {

    this.GetAutoCalled();
    this.getallVehicleParkinlist("All", "All", "All", 0);
    this.getAllLocationMapping();
    // this.GetProductList();
    var route = this.router.url;
    this.userAccessData =this.api.getUserScreenAccessMenuOnRoutingURL(route)
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  open(content: any) {

    this.modalService.open(content, this.ngbModalOptions);
  }

  getAllLocationMapping() {
    this.kemarClientService.get<any>(null, ServiceUrl.getAlllocationmapping)
      .subscribe(
        response => {
          var allLocation = response;

          this.locationMaster = allLocation.filter(x => x.locationType == 'OSPP' || x.locationType == 'ISPP');
        },
        r => {
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }

  getallVehicleParkinlist(category, status, typetran, locationId) {
    var query = { 'material': category, 'status': status, 'typetran': typetran, parkingLocationId: locationId }
    // var query = { 'material': category, 'status': status, 'typetran': typetran }
    this.kemarClientService.get<any>(null, ServiceUrl.getallVehicleParkinlist, query)
      .subscribe(
        response => {
          this.elvList = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  onChangecheckbox(elvId, elvList) {

    elvList.forEach(val => {

      if (val.elvId == elvId) {
        val.action = !val.action;
      }
    });

    const count = elvList.filter(function (item) {
      if (item.action === true && item.status != "CalledIn") {
        return true;
      } else {
        return false;
      }
    }).length;

    if (count > 0) {
      this.isChecked = true;
      this.select = undefined;
    }
    else {
      this.isChecked = false;
    }
  }

  GetVehilceParkingOnFilter(category, status, typetran, locationId, tranTypeSelection) {

    this.elvList = null;
    var query = { 'material': category, 'status': status, 'typetran': typetran, parkingLocationId: locationId }
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getallVehicleParkinlist, query)
      .subscribe(
        Response => {

          this.elvList = Response;

        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });

  }

  GetAutoCalled() {


    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAutocalled)
      .subscribe(
        Response => {
          this.IsAutoCalled = Response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });

  }

  AutoCallInToggle(val: any) {
    let flag: any = val.checked ? "TRUE" : "FALSE";
    var query = {
      "AutoCalledIn": flag,
    }
    this.kemarClientService.get<any>(null, ServiceUrl.updateAutoCalledIn, query)
      .subscribe(
        response => {

          this.spinner.show();
          this.autoCalledUpdate = response;
          Swal.fire('', response.responseMessage, 'success').then(function () {
            window.location.reload();
          })
        },
        r => {

          this.spinner.hide();
          Swal.fire('', r.error.errorMessage, 'error').then(function () {
            window.location.reload();
          })
          // alert(r.error.error);
          // console.log(r.error.error);
        });


  }

  GetProductList() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getallProductCategorylist)
      .subscribe(
        res => {
          this.productList = res;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });

  }

  GetVehilceParkingCallIn(request, event) {
    var callinlist: any;
    this.spinner.show();
    const count = request.filter(function (item) {
      if (item.action === true && item.status != "CalledIn" && item.status != "InSidecalling") {
        return true;
      } else {
        return false;
      }
    }).length;

    const count1 = request.filter(function (item) {
      if (item.transactionCode != null && (item.status == "InParking" || item.status == "InSideParking")) {
        return true;
      } else {
        return false;
      }
    }).length;


    if (count1 == 0 && event != undefined) {
      Swal.fire('', 'No vehicles availbale to Call IN.', 'warning');
      return;
    }
    else if (count == 0 && event == undefined) {
      Swal.fire('', 'Select atleast 1 vehicle to Call IN.', 'warning');
      return;
    }
    else if (count > 0 && event != undefined) {
      Swal.fire('', 'both functionalty not working.', 'warning');
      return;
    }


    if (typeof event != 'undefined' || event != undefined) {
      callinlist = request.filter(x => x.transactionCode != null && x.transactionCode != 'undefined' && x.status != "CalledIn" && x.status != "InSidecalling");

    }
    else {
      callinlist = request.filter(x => x.transactionCode != null && x.transactionCode != 'undefined' && x.status != "CalledIn" && x.status != "InSidecalling" && x.action == true);
    }

    var updatelist = callinlist.slice(0, event)

    updatelist.forEach(element => {
      this.spinner.hide();
      this.updatereq.push(
        {
          ELVId: element.elvId,
          TransactionCode: element.transactionCode,
          ELVCode: element.elvCode,
          vrn: element.vrn,
        }

      );

    });

    this.kemarClientService.postPatch<any>(ServiceUrl.postParkingCallIn, this.updatereq)
      .subscribe(
        response => {

          this.spinner.show();
          this.vehicleParkIn = response;
          Swal.fire('', response.responseMessage, 'success').then(function () {
            window.location.reload();
          })
        },
        r => {

          this.spinner.hide();
          Swal.fire('', r.error.errorMessage, 'error').then(function () {
            window.location.reload();
          })
          // alert(r.error.error);
          // console.log(r.error.error);
        });

  }


  public closeModalPopup() {
    this.modalService.dismissAll();
  }

};

const TransactionType: { key: string, value: string }[] = [
  { key: 'Inbound', value: 'Inbound' },
  { key: 'Outbound', value: 'Outbound' }
];

const VehicleStatus: { key: string, value: string }[] = [
  { key: 'CalledIn', value: 'CalledIn' },
  { key: 'InParking', value: 'InParking' },
  { key: 'InSideParking', value: 'InSideParking' },
  { key: 'InSideCalling', value: 'InSideCalling' }
];

