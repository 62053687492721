<!-- <app-header></app-header> -->
<section class="card-section">
  <div class="container-fluid">
    <h4 class="title-one mb-2">Dashboard</h4>
    <div class="row">
      <div class="col-sm-3">
        <div class="card card-one">
          <div class="card-body">
            <div class="bg-white progress" *ngFor="let dashboardData of dashboardCardResponse">
              <div class="progress-bar card-one" role="progressbar" [style]="vehicleInParkingStyle"
                aria-valuenow="{{dashboardData?.vehicleInParking}}" aria-valuemin="0"
                aria-valuemax="{{ParkingCapacity}}"></div>
              <!-- <div>{{dashboardData.vehicleInParking }} {{vehicleInParkingStyle}}</div> -->
            </div>
            <small class="text-white">Parking Capacity</small>
            <div class="row" *ngFor="let dashboardData of dashboardCardResponse">
              <div class="d-flex align-items-center mb-2">
                <div class="col-6">
                  <h1 class="card-count text-center">{{dashboardData.vehicleInParking}}</h1>
                </div>
                <div class="col-6">
                  <ul class="w-75 float-end list-item list-unstyled text-center mb-0">
                    <li><a >Unallocated: {{dashboardData.vehicleUnallocateCount}}</a></li>
                    <li><a >Allocated: {{dashboardData.vehicleAllocateCount}}</a></li>
                    <li><a >Called In: {{dashboardData.vehicleCalledInCount}}</a></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-end">
                <div class="flex-grow-2">
                  <img title="" width="36" class="" src="../../assets/images/icons/parking.png">
                </div>
                <div class="flex-shrink-1">
                  <a  class="card-title">Vehicles in Parking</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="card card-two">
          <div class="card-body" *ngFor="let dashboardData of dashboardCardResponse">
            <div class="bg-white progress">

                <div class="progress-bar card-two" role="progressbar" [style]="vehicleInPlantStyle"
                aria-valuenow="{{dashboardData.vehicleInPlant}}" aria-valuemin="0" aria-valuemax="{{PlantCapacity}}"></div>

            </div>
            <small class="text-white">Plant Capacity</small>
            <div class="row">
              <div class="d-flex align-items-center mb-2">
                <div class="col-6">
                  <h1 class="card-count text-center">{{dashboardData.vehicleInPlant}}</h1>
                </div>
                <div class="col-6">
                  <ul class="w-75 float-end list-item list-unstyled text-center mb-0">
                    <li><a>Inbound: {{dashboardData.vehicleInboundCount}}</a></li>
                    <li><a>Outbound: {{dashboardData.vehicleOutboundCount}}</a></li>
                    <li><a>Inplant: {{dashboardData.vehicleInternalCount}}</a></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-end">
                <div class="flex-grow-2">
                  <img title="" width="36" class="" src="../../assets/images/icons/plant.png">
                </div>
                <div class="flex-shrink-1">
                  <a class="card-title">Vehicles in Plant</a>
                </div>
              </div>
              <!-- <div class="col-7">
                            <div class="d-flex">
                                <div class="flex-grow-1 align-self-center">
                                <img title="" width="50" class="" src="../../assets/images/icons/plant.png">
                                </div>
                                <div class="flex-shrink-0">
                                <h1 class="card-count">500</h1>
                                <h2 class="card-title">Vehicles in <br> Plant</h2>
                                </div>
                        </div>
                        </div>
                        <div class="col-5">
                            <ul class="list-item list-unstyled text-center">
                                <li>Inbound: 4</li>
                                <li>Outbound: 6</li>
                                <li>Internal: 4</li>
                            </ul>
                        </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="card card-three">
          <div class="card-body">
            <div class="card-filter">
              <label class="custom-select my-0">
                <select (change)="TATSelectionChangeEventHandler($event)">
                  <option selected value="1">Today</option>
                  <option value="2">Last 7 Days</option>
                  <option value="3">Last 30 Days</option>
                </select>
              </label>
            </div>
            <div class="row">
              <div class="d-flex align-items-center mt-2 mb-2">
                <div class="col-6">
                  <h1 class="card-count text-center">{{vehicleMonthlyTatStr}}</h1>
                </div>
                <div class="col-6">
                  <ul class="w-75 float-end list-item list-unstyled text-center mb-0">
                    <li><a >Min TAT: {{vehicleMonthlyMinTatStr}}</a></li>
                    <li><a >Max TAT: {{vehicleMonthlyMaxTatStr}}</a></li>
                    <li class="invisible">&nbsp;</li>
                  </ul>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-end">
                <div class="flex-grow-2">
                  <img title="" width="36" class="" src="../../assets/images/icons/tat.png">
                </div>
                <div class="flex-shrink-1">
                  <a  class="card-title">TAT Summary</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="card card-four">
          <div class="card-body">
            <div class="card-filter">
              <label class="custom-select mt-0">
                <select (change)="MonthlyTransactionSelectionChange($event)">
                  <option selected value="1">Today</option>
                  <option value="2">Last 7 Days</option>
                  <option value="3">Last 30 Days</option>
                </select>
              </label>

            </div>
            <div class="row">
              <div class="d-flex align-items-center mt-2 mb-2">
                <div class="col-6">
                  <h1 class="card-count text-center">{{vehicleMonthlyTran}}</h1>
                </div>
                <div class="col-6">
                  <ul class="w-75 float-end list-item list-unstyled text-center mb-0">
                    <li><a >Inbound: {{vehicleInboundMonthlyTran}}</a></li>
                    <li><a >Outbound: {{vehicleOutboundMonthlyTran}}</a></li>
                    <li><a >Inplant: {{vehicleInternalMonthlyTran}}</a></li>
                  </ul>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-end">
                <div class="flex-grow-2">
                  <img title="" width="32" class="" src="../../assets/images/icons/transaction.svg">
                </div>
                <div class="flex-shrink-1">
                  <a class="card-title">{{cardTransactionTimeLine}} Completed Transaction</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="data-table">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-8">
        <div class="card h-100">
          <div class="card-body">
            <div class="d-flex data-filter align-items-center mb-2">
              <div class="flex-grow-1">
                <h4 class="title-one mb-0">Recent Movements &nbsp; <a style="cursor: pointer;"
                    (click)="getAllLocations()"><img title="" width="22" class=""
                      src="../../assets/images/icons/refresh.svg"></a></h4>
              </div>
              <div class="flex-grow-2 mx-2">
                <div class="input-append w-100">
                  <span class="material-icons-sharp icon-in">search </span>
                  <input type="text" class="form-control" placeholder="Search" [(ngModel)]="filter">
                </div>
              </div>
              <div class="flex-shrink-0">
                <label class="custom-select w-100">
                  <select class="form-control dropdown-select" name="locationCode" #t
                    (change)="onLocationCodeClick(t.value)">
                    <option Value="All" [disabled]="false">-- All Location --</option>
                    <option *ngFor="let location of locationMasterDATA" [value]="location.locationCode">
                      {{location.locationName}} </option>
                  </select>
                </label>
              </div>
            </div>

            <!-- <h4 class="title-one">Live Transaction</h4>
                        <div class="d-flex">
                            <div class="col-6">
                                <h4 class="title-one">Live Transaction</h4>
                            </div>
                            <div class="col-6">

                            </div>
                        </div> -->
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>VRN</th>
                    <th>Milestone</th>
                    <th>Location</th>
                    <th>Trip No</th>
                    <th>Trip Type</th>
                    <th>Trip Date & Time</th>
                    <th width="5%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let Dashdata of dashboardResponse | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                    <th scope="row">{{ (currentActivePage - 1) * pageSize + i +1 }}</th>
                    <td><a class="text-primary">{{Dashdata.vrn}}</a>
                    </td>
                    <td>{{Dashdata.milestone}} - {{Dashdata.milestioneEvent}}</td>
                    <td>{{Dashdata.locationName}} </td>
                    <td>{{Dashdata.vehicleTransactionCode }}</td>
                    <td>
                      <span *ngIf="Dashdata.tranType  ==1 ">Outbound</span>
                      <span *ngIf="Dashdata.tranType ==2">Inbound</span>
                      <span *ngIf="Dashdata.tranType ==4">Inplant</span>
                    </td>
                    <!-- <td>{{Dashdata.transactionDate |date: 'dd-MM-yyyy hh:mm'}}</td> -->
                    <td>
                      <span *ngIf="Dashdata.milestoneCompletionTime == null">{{Dashdata.milestoneBeginTime |date: 'dd-MM-yyyy HH:mm'}}</span>
                      <span *ngIf="Dashdata.milestoneCompletionTime != null">{{Dashdata.milestoneCompletionTime |date: 'dd-MM-yyyy HH:mm'}}</span>
                    </td>
                    <td>
                      <img *ngIf="Dashdata.status == 'Completed' " title="" width="18" class=""
                        src="../../assets/images/icons/true.svg">
                      <img *ngIf="Dashdata.status == 'Failed'" title="" width="18" class=""
                        src="../../assets/images/icons/cancel.svg">
                      <img *ngIf="Dashdata.status == 'Open' " title="" width="18" class=""
                        src="../../assets/images/icons/pending.svg">
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <app-bchart></app-bchart>
      </div>

      <!-- <div class="col-xl-4 col-lg-5 mt-4">
        <div class="card shadow mb-4"> -->
      <!-- Card Header - Dropdown -->

      <!-- Card Body -->
      <!-- <div class="card-body" *ngFor=" let chartData of dashboardCardResponse ">
            <div class="chart-pie pt-4 pb-2">
              <div class="pie">
                <ul class="legend">

                  <li>Parking In:-{{chartData.vehicleInParkingtodays}}</li>
                  <li>Parking Out:-{{chartData.vehicleParkingOutTodays}} </li>
                  <li>Plant in:-{{chartData.vehicleInPlantTodays}}</li>
                  <li>Plant Out:-{{chartData.vehiclePlantOutTodays}}</li>
                </ul> -->

      <!-- data-start will have the value of starting percentage of the slice and data-value is the actual % of the slice. data-start will have the value 0 (for the first slice) and value of the slice of previous slice (for others)-->
      <!-- <div class="slice" data-start="0" attr.data-value="{{ successfulInParkingPercent }}">
                  <div class="slice-fill"></div>
                  <div class="bg-fill"></div>
                </div>
                <div class="slice" attr.data-start="{{ successfulInParkingPercent }}"
                  attr.data-value="{{ OutParkingPercent }}">
                  <div class="slice-fill"></div>
                  <div class="bg-fill"></div>
                </div>
                <div class="slice" attr.data-start="{{ successfulInParkingPercent + OutParkingPercent}}"
                  attr.data-value="{{successfulInPlantPercent }}">
                  <div class="slice-fill"></div>
                  <div class="bg-fill"></div>
                </div>
                <div class="slice"
                  attr.data-start="{{successfulInParkingPercent + OutParkingPercent +successfulInPlantPercent}}"
                  attr.data-value="{{outPlantPercent}}">
                  <div class="slice-fill"></div>
                  <div class="bg-fill"></div>
                </div>

              </div>
              <div class="mt-4 text-center small">
                <span class="mr-2">
                  <i class="fas fa-circle text-primary"></i> Vechicle
                </span>
                <span class="mr-2">
                  <i class="fas fa-circle text-success"></i> Count
                </span>

              </div>
              <div class="mt-4 text-center small"></div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-md-4">
            <app-bchart></app-bchart>
        </div> -->

    </div>
  </div>
</section>
<app-footer></app-footer>
