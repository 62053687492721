<ngx-spinner> </ngx-spinner>
<app-header></app-header>
<section class="mt-3"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container">
    <div class="card">
      <div class="card-body">
        <div class="row my-2">
          <div class="col-sm-4">
            <h3 class="title-one mb-0">Weight Tolerance Action</h3>
          </div>
          <hr />
          <div class="searchVehicleDetail" *ngIf="!IsThereTolerance">
            <br>
            <div class="vehicleDetail">
              <label class="fw-bold vehicleText">Vehicle Number</label>
              <input type="text" class="form-control mx-2" placeholder="Enter Vehicle Number"
              [(ngModel)]="vehicleDetail" (keyup.enter)="getVehicleToleranceDetail()" autofocus
              oninput="this.value = this.value.toUpperCase()" />
              <a class="btn btn-primary" (click)="getVehicleToleranceDetail()" >
                <fa-icon [icon]="faSearch"></fa-icon>
              </a>
            </div>
          </div>

          <div class="searchVehicleDetail " *ngIf="IsThereTolerance">
            <form autocomplete="off" [formGroup]="toleranceActionForm">
              <div class="text-center fw-bold">
                <h1>
                  {{vehicleToleranceDetail.vrn}}
                </h1>
              </div>

              <div class="row my-2 fw-bold">
                <div class="col-2">Expected Tare Weight</div>
                <div class="col-10">{{vehicleToleranceDetail.expectedWeight}} KG</div>
              </div>
              <div class="row my-2 fw-bold">
                <div class="col-2">New Tare Weight</div>
                <div class="col-10">{{vehicleToleranceDetail.actualWeight}} KG</div>
              </div>

              <div class="row m-2">
                <div class="form-check">
                  <input class="form-check-input" type="radio" (change)="AcceptValueOnChange()"
                  [(ngModel)]="ActionValue" id="AcceptBtn" value="Accept"
                  [ngModelOptions]="{standalone: true}" formControlName="accept" checked>
                  <label class="form-check-label" for="AcceptBtn">
                    Accept New Weight
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" (change)="AcceptValueOnChange()"
                  [(ngModel)]="ActionValue" id="AcceptNResggingBtn" formControlName="accept"
                  value="AcceptWithReWeighment" [ngModelOptions]="{standalone: true}">
                  <label class="form-check-label" for="AcceptNResggingBtn">
                    Reweighment & Accept
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" (change)="AcceptValueOnChange()" formControlName="accept"
                  [(ngModel)]="ActionValue" id="rejectBtn" value="Reject" [ngModelOptions]="{standalone: true}">
                  <label class="form-check-label" for="rejectBtn">
                    Reject
                  </label>
                </div>
              </div>

              <div class="row my-2" *ngIf="ActionValue == 'AcceptWithReWeighment'">
                <div class="col-1">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="autoWB" [(ngModel)]="autoWBAssign"
                    [ngModelOptions]="{standalone: true}" (change)="OnChangeWBAssign()" >
                    <label class="form-check-label" for="autoWB">Auto</label>
                  </div>
                </div>
                <div class="col-11" *ngIf="autoWBAssign == false">
                  <label class="fw-bold" for="reason">Weighbridge  <span class="text-danger">*</span></label><br>
                  <p-dropdown [options]="AllWeighbridge" optionLabel="locationName" optionValue="weighBridgeId"
                  placeholder="Select Weighbridge" formControlName="weighBridgeId"
                  [ngClass]="{ 'is-invalid': submitted && f['weighBridgeId'].errors }">
                  <ng-template let-vehicle pTemplate="item">
                    <div class="vehicle-item">
                      <div>{{vehicle.locationName}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <div *ngIf="submitted && f.weighBridgeId.errors" class="text-danger invalid-feedback">
                  <div *ngIf="f.weighBridgeId.errors.required">
                    <small>Weighbridge is required.</small>
                  </div>
                </div>
                </div>
              </div>

              <div class="row my-2">
                <label class="fw-bold " for="reason">Reason  <span class="text-danger">*</span></label>
                <p-dropdown [options]="ToleranceReasonList" optionLabel="value" optionValue="value"
                    placeholder="Select Reason" formControlName="reason" [ngClass]="{ 'is-invalid': submitted && f['reason'].errors }">
                    <ng-template let-vehicle pTemplate="item">
                      <div class="vehicle-item">
                        <div>{{vehicle.value}}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <div *ngIf="submitted && f.reason.errors" class="text-danger invalid-feedback">
                    <div *ngIf="f.reason.errors.required">
                      <small>Weighbridge is required.</small>
                    </div>
                  </div>
                  <div class="form-group mt-1">
                    <label for="comment">Additional Comments</label>

                    <!-- <input type="text" class="textarea form-control mx-2" placeholder="Additional Comment" /> -->

                    <textarea class=" textarea form-control" formControlName="Justification" id="comment" rows="1"></textarea>
                  </div>
              </div>

              <div class="row my-2">
                  <a  class="btn btn-primary sumitBtn" (click)="AcceptTolerance()">Submit</a>
                  <a  class="btn btn-danger sumitBtn mx-2" (click)="ResetAllValues()">Cancel</a>
              </div>
            </form>
          </div>

          <!-- <form autocomplete="off" [formGroup]="elvForm">

          </form> -->
        </div>
      </div>
    </div>
  </div>
</section>
