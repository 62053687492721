import { Component, OnInit } from '@angular/core';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatLabel } from '@angular/material/form-field';
import { AxisDateTimeLabelFormatsOptions, dateFormat } from 'highcharts';
import { Console } from 'console';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-gate-dashboard',
  templateUrl: './gate-dashboard.component.html',
  styleUrls: ['./gate-dashboard.component.scss']
})
export class GateDashboardComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  intervalMode: any;

  dailyThroughput: any;
  weekThroughput: any;
  chartOptions: any
  basicOptions: any;
  WeekybasicOptions: any;
  gateDashboardList: any[] = [];
  gateDashboardDetail: any[] = [];
  gateDailyChartData: any[] = [];
  gateDashboardSPdto: any[] = [];
  location: any[] = [];
  currentActivePage: number = 1;
  pageSize = 10;
  CurrentDate: Date;
  gatelastsevenData: any[] = [];
  barchartDailyMapping: { labels: any[]; datasets: { label: string; backgroundColor: string; data: any[]; }[]; };
  piechartDataList: any[] = [];
  days: number=8;
  public userAccessData: UserScreenAccesData;
  selectedLocation= 'undefined';

  constructor(private kemarClientService: KemarClientService, private spinner: NgxSpinnerService,private api:DataService) {
    this.basicOptions = {
      plugins: {
        legend: {
          position: 'top',
          display: true,
                 labels: {
                    color: 'red'
                }
        },
      },
      scales: {
        x: {
          ticks: {
            min: 0,
          }
        },
        y: {
          ticks: {
            min: 0,
          }
        }
      }

    };
  }

  ngOnInit(): void {
    this.CurrentDate = new Date();
    this.getGateLocation();

    this.getGateDashboard();
    this.intervalMode = setInterval(() => {
      this.getGateDashboard(this.selectedLocation, this.days);
    }, 5000);

    this.userAccessData =this.api.getUserScreenAccessMenu('Gate Dashboard')
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    clearInterval(this.intervalMode);
  }

  getGateLocation() {
    this.spinner.show();
    let query = { 'SelectLocationCode': 'undefined' }
    this.kemarClientService.get<any>(null, ServiceUrl.getgateLocationData).subscribe(
      (response) => {

        this.spinner.hide();
        this.location = response;
      },
      (r) => {
        this.spinner.hide();
        // console.log(r.error.error);
      }
    );
  }
  ChangeMethodTYpe(val: any) {

  }
  ChangeMethod(value: any) {
this.selectedLocation = value;
    this.getGateDashboard(value)
  }

  changeDrop(val: any) {

    if(val=='16'){this.days=16}
    else{this.days=8}

    this.getGateDashboard(this.selectedLocation, this.days);
  }


  getGateDashboard(queryData: any = 'undefined', selectedDays: any = 8) {
    // this.spinner.show();
    let query = { 'SelectLocationCode': queryData, 'selectedDays': selectedDays }
    this.kemarClientService.get<any>(null, ServiceUrl.getgateDashBoardData, query).subscribe(
      (response) => {

        this.spinner.hide();
        this.gateDashboardList = response;
        this.gateDashboardSPdto = response.gateDashboardSPdto;
        if (queryData == 'undefined') {
          this.gateDailyChartData = response.gatechartData;
          this.gateDashboardDetail = response.gateDashboardDetail.filter(x => x.lastSevenDay === false);
          this.gatelastsevenData = response.gateDashboardDetail.filter(x => x.lastSevenDay === true);
          this.lastSevenChartMapping();
          this.DailyChartDataMapping();
          this.BarchartDailyMapping();
        }
      },
      (r) => {
        // this.spinner.hide();
        // console.log(r.error.error);
      }
    );
  }

  BarchartDailyMapping() {

    const ids = this.gateDashboardDetail.map(object => object.gateCount);
    let MaxCount = this.kemarClientService.yAxisBarchartMaxValue(Math.max(...ids));
    let maxYxis: number;
    this.basicOptions = {
      // plugins: {
      //   legend: {
      //       display: true,
      //       labels: {
      //           color: 'red'
      //       }
      //   },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: MaxCount,
                min: 0
              }
            }
          ],
        // }
    }


    };
    if (this.gateDashboardDetail.length > 0) {

      //let GateName: any[] = this.gateDashboardDetail.map(x => x.gateNameUnModified);
      let GateName: any[] = [...new Set(this.gateDashboardDetail.map(item => item.gateNameUnModified))];
      let EntryData: any[], ExitData: any[] = []
      EntryData = this.gateDashboardDetail.filter(x => x.lastSevenDay === false && x.gateType == 'IN').map(x => x.gateCount);
      ExitData = this.gateDashboardDetail.filter(x => x.lastSevenDay === false && x.gateType == 'OUT').map(x => x.gateCount);
      this.barchartDailyMapping = {
        labels: GateName,
        datasets:
          [
            {
              label: 'Entry',
              backgroundColor: '#A5E348',
              data: EntryData
            },
            {
              label: 'Exit',
              backgroundColor: '#239DCC',
              data: ExitData
            },
          ]
      }
    }
  }

  lastSevenChartMapping() {
    const ids = this.gatelastsevenData.map(object => object.gateCount);
    let MaxCount = this.kemarClientService.yAxisBarchartMaxValue(Math.max(...ids));
    let maxYxis: number;
    this.WeekybasicOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: MaxCount,
              min: 0
            }
          }
        ],
      }
    };

    let Data: any = [] = []
    const GateName = [...new Set(this.gatelastsevenData.map(item => item.gateName))];
    const date = [...new Set(this.gatelastsevenData.map(item => item.minusday))];
    GateName.forEach((element, index) => {
      let Gate: any[] = this.gatelastsevenData.filter(x => x.gateName == element).map(x => x.gateCount);
      let color = this.setcolor(index)
      let query = {
        label: element,
        data: Gate,
        fill: false,
        borderColor: color,
        tension: .4
      }
      Data.push(query);
    });
    this.weekThroughput = {
      labels: date,
      datasets: Data
    };
  }
  setcolor(index: any) {
    if (index == 0) {
      return '#A5E348';
    }
    else if (index == 1) {
      return '#63C08C';
    }
    else if (index == 2) {
      return '#37A7B3';
    }
    else if (index == 3) {
      return '#239DCC';
    }
  }

  DailyChartDataMapping() {

    this.dailyThroughput = [] = this.piechartDataList = [];
    //const tripType = [...new Set(this.gateDailyChartData.map(item => item.gateInboundCount))];
    const gateName = [...new Set(this.gateDailyChartData.map(item => item.gateName))];
    gateName.forEach((element, index) => {
      const gateInboundCount = [...new Set(this.gateDailyChartData.filter(x => x.dayType == 'TodayData' && x.gateName == element).map(item => item.gateInboundCount))];
      const gateOutboundCount = [...new Set(this.gateDailyChartData.filter(x => x.dayType == 'TodayData' && x.gateName == element).map(item => item.gateOutboundCount))];
      const gateInplantCount = [...new Set(this.gateDailyChartData.filter(x => x.dayType == 'TodayData' && x.gateName == element).map(item => item.gateInplantCount))];
      const gateOtherCount = [...new Set(this.gateDailyChartData.filter(x => x.dayType == 'TodayData' && x.gateName == element).map(item => item.gateOtherCount))];
      let BackGroundColorFor: any[];
      let hoverBackgroundColorFor: any[];
      const lastWord = element.split(' ').pop();
      if (lastWord === "IN") {
        BackGroundColorFor = ["#A5E348", "#63C08C", "#37A7B3", "#239DCC"]
        hoverBackgroundColorFor = ["#bffa66", "#85e9b1", "#5ad5e2", "#3ebff1"]
      }
      else if (lastWord === "OUT") {
        BackGroundColorFor = ["#A5E348", "#63C08C", "#37A7B3", "#239DCC"]
        hoverBackgroundColorFor = ["#bffa66", "#85e9b1", "#5ad5e2", "#3ebff1"]
      }
      this.dailyThroughput = {
        labels: ['Inbound', 'Outbound', 'Inplant', 'Other'],
        datasets: [
          {
            data: [gateInboundCount, gateOutboundCount, gateInplantCount, gateOtherCount],
            backgroundColor: BackGroundColorFor,
            hoverBackgroundColor: hoverBackgroundColorFor
          },
        ]
      }

      this.piechartDataList.push(this.dailyThroughput);
      this.piechartDataList[index].GateName = element
    });

  }

  getimgColor(type: string) {
    // if (indexval <= 3) {
    //   return 'gate' + (indexval + 1);
    // }
    // if (indexval > 3 && indexval < 8) {
    //   return 'gate' + (indexval - 3)
    // }
    // if (indexval > 7 && indexval < 12) {
    //   return 'gate' + (indexval - 7)
    // }

    if (type == "IN") {
      return 'gate1'
    } else {
      if (type == "OUT") {
        return 'gate2'
      }
    }
  }
}
