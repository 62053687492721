<header id="header" class="banner steel-img position-relative">
  <div class="overlay"></div>
  <div class="container">
      <nav class="navbar">
          <div class="container p-0">
              <a routerLink="/" class="logo"><img src="assets/landing/img/logo.svg" alt=""></a>
              <form class="justify-content-end">
                  <a href="http://www.urgetruck.com/" target="_blank" class="btn-red login"> <i
                          class="bi bi-person-fill"></i> LOGIN </a>
              </form>
          </div>
      </nav>

      <nav class="cd-vertical-nav">
          <ul>
              <li><a routerLink="/" class="active"><span class="label">Home</span></a></li>
              <li><a routerLink="/steel"><span class="label">Steel</span></a></li>
              <li><a routerLink="/automotive"><span class="label">Automotive</span></a></li>
              <li><a routerLink="/cement"><span class="label">Cement</span></a></li>
              <li><a routerLink="/port"><span class="label">Port</span></a></li>
          </ul>
      </nav>
  </div>
</header>

  <section class="bg-white">
      <div class="container">
          <div class="row gx-4 gx-lg-5 align-items-center">
              <div class="col-md-4"><img class="card-img-top mb-5 mb-md-0"
                      src="../../../assets/landing/img/steel-img.jpg" alt="..."></div>
              <div class="col-md-8 text-start">
                  <h2>STEEL SUPPLY CHAIN Management</h2>
                  <h3>Steel Supply Chain involves two parts:</h3>
                      <ul class="mb-2">
                          <li>Upstream Steel Supply Chain - Mining to producing Steel</li>
                          <li>Downstream Steel Supply Chain - Storage of Steel processing plants, Distribution, and processing to Finished Products.</li>
                      </ul>
                      <p>The Urgetruck solution when deployed with the associated Automation system in Steel Industries’ Logistic processes, covers:</p>

                      <ol>
                          <li>The Outbound Processes [mostly Finished Products, Sales and Dispatch data], </li>
                          <li>The Inbound processes covering the inbound movement of Purchased materials [ including Raw materials] and</li>
                          <li>The In-plant Logistics covers the intra-unit movement of goods (raw/semi-finished/finished). All three types of movements involve Vendor collaboration [ Online Transporter registration, Online and On-site Vehicle/Driver/Helper registration, Online assignment/job allocation to Transporters / Vendors, etc.]</li>
                      </ol>
                  <p><strong>Urgetruck</strong> also takes care of (i) Vehicle Parking & Management before Plant Gate Entry (ii) RFID-based Gate Entry processes along with Facial recognition of Drivers and helpers. Apart from these functions, other automated functions like Unmanned weighbridge operations, Finished Goods and Raw materials loading, Yard management, Online Security Checklist data capturing, automated Gate Exit, GPS-based vehicle tracking, etc., are managed by UrgeTruck. The UrgeTruck software can have interface with any ERP for Master data, Sales Order and also Inventory update. Invoice generation in any ERP thru <strong>UrgeTruck</strong> can happen.</p>

              </div>
          </div>
      </div>
  </section>


  <app-lfooter></app-lfooter>
