import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
// import { PageScrollService } from 'ngx-page-scroll-core';
import { ServiceUrl } from 'src/app/common/service-urls';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NavigationEnd, Router } from '@angular/router';
// import { landing }  from 'src/assets/landing/js/landing.js'


// declare var landing: any;
declare var $: any;
export class feedBackEmail {
  subject: string;
  body: string;
}

@Component({
  selector: 'app-landing-home',
  templateUrl: './landing-home.component.html',
  styleUrls: ['./landing-home.component.scss']
})
export class LandingHomeComponent implements OnInit {
  FormData!: FormGroup;
  active = 1;
  feedbackMailData: feedBackEmail = new feedBackEmail();
  submitted=false;

  constructor(
    // private pageScrollServ: PageScrollService,
    private kemarClientService: KemarClientService,
    @Inject(DOCUMENT) private document: any,
    private builder: FormBuilder,
    public datepipe: DatePipe,
    private router: Router
  ) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = true;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    // window.location.reload();
    //  new landing();
    this.FormGroup();

    $(document).ready(function ($) {
      // alert("hello");
      var scrolling = false;
      var contentSections = $('.cd-section'),
        verticalNavigation = $('.cd-vertical-nav, .get-started'),
        navigationItems = verticalNavigation.find('a'),
        navTrigger = $('.cd-nav-trigger'),
        scrollArrow = $('.cd-scroll-down');

      $(window).on('scroll', checkScroll);

      //smooth scroll to the selected section
      verticalNavigation.on('click', 'a', function (event) {
        event.preventDefault();
        smoothScroll($(this.hash));
        verticalNavigation.removeClass('open');
      });

      //smooth scroll to the second section
      scrollArrow.on('click', function (event) {
        event.preventDefault();
        smoothScroll($(this.hash));
      });

      // open navigation if user clicks the .cd-nav-trigger - small devices only
      navTrigger.on('click', function (event) {
        event.preventDefault();
        verticalNavigation.toggleClass('open');
      });

      function checkScroll() {
        if (!scrolling) {
          scrolling = true;
          !window.requestAnimationFrame
            ? setTimeout(updateSections, 300)
            : window.requestAnimationFrame(updateSections);
        }
      }

      function updateSections() {
        var halfWindowHeight = $(window).height() / 2,
          scrollTop = $(window).scrollTop();
        contentSections.each(function () {
          var section = $(this),
            sectionId = section.attr('id'),
            navigationItem = navigationItems.filter(
              '[href^="#' + sectionId + '"]'
            );
          section.offset().top - halfWindowHeight < scrollTop &&
          section.offset().top + section.height() - halfWindowHeight > scrollTop
            ? navigationItem.addClass('active')
            : navigationItem.removeClass('active');
        });
        scrolling = false;
      }

      function smoothScroll(target) {
        $('body,html').animate({ scrollTop: target.offset().top }, 300);
      }
    });

    $(document).ready(function () {
      // let's Hide Items
      $('.ut-contactpop form').hide();
      $('.ut-contactpop i').hide();
      $('.ut-contactpop p').hide();
      // Show on elements ".slideDown"
      $('.ut-contactpop').click(function () {
        $('.ut-contactpop form').slideDown(500);
        $('.ut-contactpop p').slideDown(500);
        $('.ut-contactpop i').slideDown(500);
      });
      // Close Hidden Part
      $('.ut-contactpop i').click(function () {
        $(this).slideUp(500);
        $('.ut-contactpop p').slideUp(500);
        $('.ut-contactpop form').slideUp(500);
        return false;
      });
    });
    $('.carousel').carousel({
      interval: false,
    });
  }

  FormGroup() {
    this.FormData = this.builder.group({
      fullname: new FormControl('', [Validators.required]),
      mail: new FormControl('', [Validators.required, Validators.email]),
      contact: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.FormData.controls;
  }

  // onScroll(event: HTMLElement, i: any) {
  //   this.pageScrollServ.scroll({
  //     scrollTarget: event,
  //     document: this.document,
  //   });
  //   this.active = i;
  // }

  sendEmail() {
    this.submitted = true;
    if (this.FormData.invalid) {
      return;
    }
    var name = this.FormData.get('fullname').value;
    var mail = this.FormData.get('mail').value;
    var contact = this.FormData.get('contact').value;
    var comment = this.FormData.get('comment').value;
    var dateTime = this.datepipe.transform(new Date(), 'dd/MM/yyyy h:mm:ss');
    this.feedbackMailData.subject = 'UrgeTruck website inquiry from : ' + name;
    this.feedbackMailData.body =
      'Following inquiry has been received from UrgeTruck website on ' +
      dateTime +
      '<br /> <br /> Name: ' +
      name +
      '<br /> Email ID: ' +
      mail +
      '<br /> Phone: ' +
      contact +
      '<br /> Comments: ' +
      comment +
      '<br /><br /><br /> Regards, <br /> Team UrgeTruck <br /><br />  --  this is auto generated mail, pls do not reply to this mail -- <br/><br/>';
    this.kemarClientService
      .postPatch<any>(ServiceUrl.sendFeedbackEmail, this.feedbackMailData)
      .subscribe(
        (response) => {
          Swal.fire({
            icon: 'success',
            title: 'Urgetruck Team',
            text: 'We will get you soon.',
          });
          this.FormData.reset();
        },
        (r) => {}
    );
    this.submitted = false;
  }

  // function refreshPage(){
  //   window.location.reload();
  // }
}

