import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { faAnchor, faEdit, faPlus, faSort, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-alert-master',
  templateUrl: './alert-master.component.html',
  styleUrls: ['./alert-master.component.scss']
})


export class AlertMasterComponent implements OnInit {
  alerts: any;
  alertDetail: any;
  title: any;
  faEdit = faEdit;
  faTimes = faTimes;
  faAnchor = faAnchor
  faPlus = faPlus
  faSort = faSort
  submitted = false;
  limitTo: any;
  alertHistory: any;
  form: UntypedFormGroup;
  resetbuttonShow: boolean = true;

  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  tempcurrentActivePage: number = 1;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActiveAlertPage: number = 1;
  currentActiveTicketPage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private modalService: NgbModal, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        milestoneName: [
          '',
          [
            Validators.required,
            Validators.pattern("[a-zA-Z]*"),
            Validators.minLength(4),
            Validators.maxLength(30),
            this.noWhitespaceValidator,
          ]
        ],
        milestoneCode: [
          '',
          [
            Validators.required,
            this.noWhitespaceValidator,
            Validators.pattern("^[ A-Za-z0-9.-]*$")
          ]
        ],
        isActive: [
          '',
          [
            Validators.required,
          ]
        ],

        description: [
          '',

        ],
        milestoneEvent: [
          '',
          [
            Validators.required,
            Validators.maxLength(30),
            Validators.pattern("[a-zA-Z]*")
          ]
        ],
      },
    );
    this.getAllAlert();
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getAllAlert() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getActiveAlerts)
      .subscribe(
        response => {
          this.spinner.hide();
          this.alerts = response.alert;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  getAlertDetail(content: any, tranId: number) {

    var history = this.alerts.filter(function (alert) {
      return alert.transactionId == tranId;
    });

    if (history.length > 0)
      this.alertDetail = history[0].jsonListData;
    this.modalService.open(content, this.ngbModalOptions);
  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActiveAlertPage = event;
  filterchange = (val: any) =>  (!this.kemarClientService.commonValidation(val)) ? this.currentActiveAlertPage = 1 :  this.currentActiveAlertPage = this.tempcurrentActivePage

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  public closeModalPopup() {
    this.modalService.dismissAll();
  }
}
