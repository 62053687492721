import { Component, OnInit } from '@angular/core';
import { ServiceUrl } from 'src/app/common/service-urls';
import { MilestoneMasterResponse } from 'src/app/models/milestonemastermodel';
import { KemarClientService } from 'src/app/service/kemar-client.service';

@Component({
  selector: 'app-up-coming-vehicle-report',
  templateUrl: './up-coming-vehicle-report.component.html',
  styleUrls: ['./up-coming-vehicle-report.component.scss']
})
export class UpComingVehicleReportComponent implements OnInit {
  milestones: any;
  alltx: any;
  pageSize = 10;
  currentPage = 1;
  tempcurrentActivePage: number = 1;
  currentActivePage = 1;
  milestoneMaster: MilestoneMasterResponse;
  filter='';

  constructor(
    private kemarClientService: KemarClientService,
  ) { }

  ngOnInit(): void {
    this.getUpcomingVehicleCount();
    this.getVehicleWiseAllTransactions('0');
    this.getAllMilestone();
  }

  getUpcomingVehicleCount() {
    this.kemarClientService.get<any>(null, ServiceUrl.getUpcomingVehicleCount)
      .subscribe(
        response => {
          this.milestones = response;
        },
        error => {
          console.error(error);
        });
  }

  onChangeMilestone(selectedMilestoneCode: any) {
    if (selectedMilestoneCode == '0') {
      this.getVehicleWiseAllTransactions('0');
    } 
    else {
      this.getVehicleWiseAllTransactions(selectedMilestoneCode);
    }
  }
  
  getVehicleWiseAllTransactions(milestoneCode: any) {
    this.currentActivePage=1;
    debugger
    let query = {
      milestonecode: milestoneCode
    };
    this.kemarClientService.get<any>(null, ServiceUrl.getVehicleWiseAllTransactions, query)
      .subscribe(
        response => {
          this.alltx = response;
        },
        error => {
          console.error(error);
        }
      );
  }
  getAllMilestone() {
    this.kemarClientService.get<MilestoneMasterResponse>(null, ServiceUrl.getAllMilestone)
      .subscribe(
        response => {
          this.milestoneMaster = response;
        },
        error => {
          console.error(error);
        });
  }
  tab(tab: number) {
    if (tab == 0){
      this.getUpcomingVehicleCount();
    }
    else if (tab == 1){
      this.getVehicleWiseAllTransactions('0');
    }
  }
  pagechange = (event) => {
    this.tempcurrentActivePage = this.currentActivePage = event;
  };
}
