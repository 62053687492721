<app-header></app-header>
<ngx-spinner> </ngx-spinner>
<section class="my-2"*ngIf="userAccessData[0].canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="d-flex data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> Control Tower Users </h4>
          </div>
          <div class="col-sm-8 text-end">
            <div class="input-append">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search" placeholder="Search by keyword"
                [(ngModel)]="filter" (ngModelChange)="currentActivePage = 1">
            </div>
            <button class="btn btn-primary go-btn" (click)="open(edit)" matTooltip="Add User" 
            *ngIf="userAccessData[0].canCreate ==true " matTooltipClass="danger-tooltip">
            <fa-icon [icon]="faPlus"></fa-icon>
            <!-- <span class="material-icons-round">add</span> -->
            </button>
          </div>
        </div>
        <div class="table-list custom-table" *ngIf="userAccessData[0].canRead ==true">
          <div class="table-responsive">
            <table class="table table-striped mx-auto table-bordered table-hover mx-auto tbl-formating">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr. No.</th>
                  <th (click)="sort('name')" class="col action-key">User Name <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th (click)="sort('name')" class="col action-key">Shift Start Time <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th (click)="sort('name')" class="col action-key">Shift End Time <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th (click)="sort('name')" class="col action-key">Auto Allocate <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th (click)="sort('name')" class="col action-key">Active Tickets <span class="glyphicon sort-icon" *ngIf="key =='name'"
                    [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                    <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                  </span></th>
                  <th  class="col action-key text-center"*ngIf="userAccessData[0].canUpdate ==true" >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let user of allCTUserList | orderBy: key : reverse | filter:filter | paginate: { itemsPerPage: 10, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="left"> {{user.userName}} </td>
                  <td class="left"> {{user.shiftStartTime}} </td>
                  <td class="left"> {{user.shiftEndTime}} </td>
                  <td class="left">
                    <span *ngIf="user.isAutoAllocationEnabled" >Enabled</span>
                    <span *ngIf="!user.isAutoAllocationEnabled" >Disabled</span>
                   </td>
                  <td class="left">
                    <span *ngIf="user.activeTickets == null || user.activeTickets == ''">0</span>
                    <span *ngIf="user.activeTickets != null || user.activeTickets != ''">{{user.activeTickets}} </span>
                  </td>
                  <td class="text-center" *ngIf="userAccessData[0].canUpdate ==true">
                    <fa-icon [icon]="faEdit" class="faicon text-primary" matTooltip="Edit" matTooltipClass="danger-tooltip" style="cursor: pointer;"
                      (click)="editUserDetail(edit,user)"></fa-icon>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
        <div class="pagination justify-content-end">
          <pagination-controls (pageChange)="currentActivePage = $event"></pagination-controls>
        </div>
        </div>
        </div>
        </div>
</section>
<app-footer></app-footer>






<ng-template #edit let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <form autocomplete="off" [formGroup]="ctUserForm">
        <div class="form-group">
          <div class="form-group row mb-0">
            <div class="col-sm-12">
              <label for="userName">User Name <sup>*</sup></label>
              <select type="text" class="form-control form-select my-1"
                formControlName="userId" [ngClass]="{ 'is-invalid': submitted && f.userId.errors }">
                <option hidden selected value="0">--Choose Option--</option>
                <option *ngFor="let user of AllUsers" [ngValue]="user.id">
                  {{user.userName}}</option>
              </select>
              <div *ngIf="submitted && f.userId.errors"  class="invalid-feedback">
                <div *ngIf="f.userId.errors.required">User Name is required.
                </div>
                <div *ngIf="f.userId.errors?.pattern">
                 User Name is required.
                </div>
              </div>

            </div>

            <div class="col-sm-6">
              <div class="form-group  mb-0 mt-2">
                <label for="ticketSubject">Shift Start Time<sup>*</sup></label>
                <input type="time" class="form-control my-1"
                  formControlName="shiftStartTime" [ngClass]="{ 'is-invalid': submitted && f.shiftStartTime.errors }" />
                  <div *ngIf="submitted && f.shiftStartTime.errors"  class="invalid-feedback">
                    <div *ngIf="f.shiftStartTime.errors.required">Shift Start Time is required.
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group  mb-0 mt-2">
                <label for="remark">Shift End Time<sup>*</sup></label>
                <input type="time" class="form-control my-1"
                  formControlName="ShiftEndTime" [ngClass]="{ 'is-invalid': submitted && f.ShiftEndTime.errors }" />
                  <div *ngIf="submitted && f.ShiftEndTime.errors"  class="invalid-feedback">
                    <div *ngIf="f.ShiftEndTime.errors.required">Shift End Time is required.
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group  mb-0 mt-2">
                <mat-checkbox  formControlName="isAutoAllocationEnabled" ></mat-checkbox> &nbsp;
                <label for="isAutoAllocationEnabled">Auto Allocation Enabled</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-success" (click)="registeruser()">{{Save}}</button>
    </div>
  </div>
</ng-template>
