<app-header></app-header>
<section class="my-2"*ngIf="userAccessData[0]?.canRead ==true">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <!-- <div class="row data-filter align-items-center my-2">
          <div class="col-sm-4">
            <h4 class="title-one mb-0"> API Communication Log </h4>
          </div>
          <div class="col-sm-8 text-end mx-100">
            <div class="input-append me-0">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search" placeholder="Search"
                [(ngModel)]="filter" (input)="filterchange($event.target.value)">
            </div>
          </div>
        </div> -->
<!-- Header Start -->
        <div class="d-flex data-filter align-items-center my-2">
          <div class="col-md-6">
            <div class="input-append me-0">
              <span class="material-icons-sharp icon-in">search</span>
              <input class="form-control" type="search" name="search" placeholder="Search"
                [(ngModel)]="filter" (input)="filterchange($event.target.value)">
            </div>
          </div>

          <div class="col-md-6 text-end">
            <!-- <div class="input-append "> -->
              <label for="fromDate">From:</label>
              <input class="form-control date" type="date" name="fromDate" [(ngModel)]="fromDate" id="fromDate" max="{{today | date:'yyyy-MM-dd'}}">
            <!-- </div> -->
            <!-- <div class="input-append  my-2"> -->
              <label for="toDate">To:</label>
              <input class="form-control date" type="date" name="toDate" [(ngModel)]="toDate" id="toDate" max="{{today | date:'yyyy-MM-dd'}}">
            <!-- </div> -->

            <button class="btn btn-primary go-btn mx-2" (click)="getApiTrackingReport()" matTooltip="Search"
              matTooltipClass="danger-tooltip">
              <fa-icon [icon]="faSearch"></fa-icon>
              </button>
          </div>
        </div>
<!-- Header End -->
        <div class="table-list custom-table">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mx-auto">
              <thead class="table-head">
                <tr>
                  <th class="sr-no">Sr.#</th>
                  <th (click)="sort('name')" >Request Id
                  </th>

                  <!-- <th (click)="sort('name')" >API Type
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th> -->
                  <th>Trx. Code
                  </th>
                  <th>VRN
                  </th>
                  <th>Request Json
                  </th>
                  <th>Response Message
                  </th>
                  <th (click)="sort('initiatedTime')">Initiated Time
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('initiatedTime')">Completion Time
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('transactionStatus')">API Status
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>
                  <th (click)="sort('apiType')">API Type
                    <span class="glyphicon sort-icon" *ngIf="key =='name'"
                      [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
                      <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
                    </span>
                  </th>


                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let gate of apiTrackingReport | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                  <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                  <td class="left">{{gate.requestId}}</td>
                  <td class="left">{{gate.elvCode}}</td>
                  <td class="left">{{gate.vrn}}</td>
                  <!-- <td class="left">{{gate.aPIType}}</td> -->

                  <td class="left " matTooltip="{{gate.requestJson}}" matTooltipPosition="above">
                    <span class="desc"> {{ (gate.requestJson != null && gate.requestJson.length>30)? (gate.requestJson |
                      slice:0:30)+'...':(gate.requestJson) }}</span>

                      <span class="material-icons text-primary" (click)="getJsonDetail(jsonData,gate)"> info </span>
                  </td>

                  <td class="left " matTooltip="{{gate.responseMessage}}" matTooltipPosition="above">
                    <span class="desc"> {{ (gate.responseMessage != null && gate.responseMessage.length>20)? (gate.responseMessage |
                      slice:0:20)+'...':(gate.responseMessage) }}</span>
                  </td>

                  <td>{{gate.initiatedTime | date: 'dd-MM-yyyy HH:mm:ss'}}</td>
                  <td>{{gate.completedTime | date: 'dd-MM-yyyy HH:mm:ss'}}</td>
                  <td>  {{ gate.transactionStatus  }} </td>
                  <td>  {{ gate.apiType  }} </td>

                </tr>
              </tbody>
            </table>
          </div>
          </div>
          <div class="pagination justify-content-end">
            <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
          </div>




<ng-template #jsonData let-modal class="modal">
  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">More Details</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body vrScrollbar">
      <div class="table-list custom-table">
        <div class="table-responsive">
          <h4>API Status: {{JsonDataStatusToShow}}</h4>
          <h5>API Type: {{JsonTypeToShow}}</h5>
          <P>{{JsonDataToShow}}</P>
          <hr>
          <P>API Response: {{JsonErrorDataToShow}}</P>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-footer></app-footer>
