<app-header></app-header>
<section class="my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body custom-table">
      <h4 class="title-one mb-2">Simulation</h4>
        <h3>Plant Gate Simulation</h3>   

        <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
            <div class="form-group ml-3">
                <input type="hidden" minlength="3" maxlength="30" class="form-control" name="RFIDTagNo"
                formControlName="RFIDTagNo" [(ngModel)]="RFIDSimulation.RFIDTagNo" />
            </div>
            <div class="col-md-3">
                    <label for="">Select Location</label>
                    <select name="deviceLocationId" class="form-control form-select" [ngModelOptions]="{standalone: true}"
                    [ngClass]="{ 'is-invalid': submitted && f.deviceLocationId.errors }"
                    [(ngModel)]="RFIDSimulation.deviceLocationId" >
                      <option value="" disabled selected>--Choose Option--</option>
                      <option *ngFor="let location of deviceMaster" [ngValue]="location.deviceLocationId">
                          {{location.deviceName}}
                      </option>
                    </select>
            </div>
            <div class="col-md-2">
                <label for="description">Vehicle Number</label>
                <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="RFIDSimulation.vrn" />
            </div>
            <div class="col-md-2">
                <label for="description">Remarks</label>
                <input type="text" class="form-control" placeholder="" value=" "
                [ngClass]="{ 'is-invalid': submitted && f.additionalComment.errors }" formControlName="additionalComment"
                [(ngModel)]="RFIDSimulation.additionalComment"/>
            </div>

            <div class="col-md-2">
                <button class="btn btn-success px-4" (click)="PostRFIDTAG()">Send</button>
            </div>
        </form>

        <hr>
        <h3>FRS Simulation</h3>
          <form autocomplete="off" #form="ngForm" class="d-flex align-items-end justify-content-start mt-3" (ngSubmit)="postFRSDetails()">
              <div class="col-md-2">
                  <label for="description">Vehicle Number</label>
                  <input type="text" class="form-control" placeholder="" value=" " name="vrn"
                  [ngClass]="{ 'is-invalid': submitted && f.vrn.errors }"
                  [(ngModel)]="FRSSimulation.vrn" />
              </div>
              <div class="col-md-3">
                <label for="description">Driver Name</label><br>
                <p-dropdown [options]="driverMasterData" optionLabel="name" optionValue="driverCode" [showClear]="true" [filter]="true" filterBy="name"
                  placeholder="Select a Driver" [ngClass]="{ 'is-invalid': submitted && f['DriverCode'].errors }" name="driverCode" [(ngModel)]="FRSSimulation.driverCode" style="width: 300px;">
                <ng-template let-vehicle pTemplate="item">
                  <div class="vehicle-item">
                    <div>{{vehicle.name}}</div>
                    <div><small>{{vehicle.driverCode}}</small></div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
              <div class="col-md-2">
                  <label for="description">Remarks</label>
                  <input type="text" class="form-control" placeholder="" value=" " name="remarks"
                  [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }"
                  [(ngModel)]="FRSSimulation.remarks" />
              </div>

              <div class="col-md-2">
                  <button class="btn btn-success px-4" type="submit">Send</button>
              </div>
          </form>
      </div>
    </div>
  </div>
</section>

  <app-footer></app-footer>