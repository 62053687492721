<app-header></app-header>
<section class="my-2">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="customize-mattab">
          <div class="position-absolute mt-2">
            <h4 class="title-one mb-0"> UpComing Vehicle Report </h4>
          </div>
        </div>
        <mat-tab-group class="mb-2" animationDuration="0ms" mat-align-tabs="end" (selectedTabChange)="tab($event.index)" >
          <mat-tab label="Summary">
            <div class="row">
              <div class="col-md-4 table-list custom-table">
                <h5 class="title-one mt-3">MilestoneWise Queue</h5>
                <div class="table-responsive tblscroll">
                  <table class="table table-striped table-bordered table-hover mx-auto tbl-formating ">
                    <thead class="table-head">
                      <tr>
                        <th class="text-center"> Sr No.</th>
                        <th class="text-center">Mileston Name</th>
                        <th class="text-center">Upcoming Vehicle Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let list of milestones.milestone; let i = index">
                        <td class="text-left">{{ i + 1 }}</td>
                        <td class="text-center">{{ list.milestoneName }} - {{list.milestoneEvent}}</td>
                        <td class="text-center">{{list.upCommingVehicle}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-8 table-list custom-table">
                <h5 class="title-one mt-3">YardWise Queue</h5>
                <div class="table-responsive tblscrollloc">
                  <table class="table table-striped table-bordered table-hover mx-auto tbl-formating">
                    <thead class="table-head">
                      <tr>
                        <th class="text-center"> Sr No.</th>
                        <th class="text-center">Location Name</th>
                        <th class="text-center">Loading Start</th>
                        <th class="text-center">Loading Complete</th>
                        <th class="text-center">Unloading Start</th>
                        <th class="text-center">Unloading Complete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let location of milestones.location; let i = index">
                        <td class="text-center">{{ i + 1 }}</td>
                        <td class="text-lift">{{ location.locationName }}</td>
                        <td class="text-center">{{ location.loadingStart }}</td>
                        <td class="text-center">{{ location.loadingEnd }}</td>
                        <td class="text-center">{{ location.unloadingStart }}</td>
                        <td class="text-center">{{ location.unloadingEnd }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="All Transactions ">
            <div class="row g-0 data-filter col-md-7">
              <div class="col-md-3 mt-2">
                <div class="input-append">
                  <span class="material-icons-sharp icon-in">search</span>
                  <input class="form-control" type="search"placeholder="search"
                    [(ngModel)]="filter" (ngModelChange)="currentActivePage = 1">
                </div>
              </div>
            <div class="col-md-4 ">
              <select type="text" class="form-control form-select me-2 mt-2 abc"
              (change)="onChangeMilestone($event.target.value)">
              <option Value = '0'>All</option>
                  <option *ngFor="let milestone of milestoneMaster" [value]="milestone.milestoneCode">
                      {{milestone.milestoneName}} - {{milestone.milestoneEvent}}
                  </option>
              </select>
            </div>
          </div>
            <div class="table-list custom-table mt-3">
              <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover mx-auto tbl-formating ">
                  <thead class="table-head">
                    <tr>
                      <th class="text-center"> Sr No.</th>
                      <th class="text-center">VRN</th>
                      <th class="text-center">Mileston Name</th>
                      <th class="text-center">Driver Name</th>
                      <th class="text-center">Helper Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of alltx | filter:filter| paginate: { itemsPerPage: pageSize, currentPage: currentActivePage }; let i = index">
                      <td class="text-center">{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
                      <td class="text-center">
                        <a [routerLink]="['/current-transaction', list.vehicleTransactionCode]">{{list.vrn}}</a>
                      </td>
                      <td class="text-center">{{list.milestoneName }} - {{list.milestioneEvent}}</td>
                      <td class="text-center">{{list.driverName}}</td>
                      <td class="text-center">{{list.halperName}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="pagination justify-content-end">
                  <pagination-controls (pageChange)="pagechange($event)"></pagination-controls>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
