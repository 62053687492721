<ngx-spinner> </ngx-spinner>
<div class="d-flex data-filter align-items-center my-2">
  <div class="col-sm-4">
    <div class="input-append">
      <span class="material-icons-sharp icon-in">search</span>
      <input class="form-control" type="search" name="search" placeholder="Search by keyword" [(ngModel)]="filter"
      (keyup)="currentActivePage=1">
    </div>
  </div>

</div>
<div class="table-list custom-table">
  <div class="table-responsive">
    <table class="table table-striped table-bordered table-hover mx-auto">
      <thead class="table-head">
        <tr>
          <th class="sr-no">Sr. No.</th>
          <th (click)="sort('name')" class="">Transporter Name
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Plant Code
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Blacklisted
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Mobile No
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>

          <th (click)="sort('name')" class="">Reason
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Incident Date
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="">Created By
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'" [ngClass]='glyphicon-chevron-up'>
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
          <th (click)="sort('name')" class="col w-25">Description
            <span class="glyphicon glyphicon-chevron-up" *ngIf="key =='name'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}">
              <fa-icon [icon]="faSort" class="sorticon"></fa-icon>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <input type="hidden" />
        <tr
          *ngFor="let transporterBlacklisting of TransporterBlackList  | filter:filter | paginate: { itemsPerPage: pageSize, currentPage: currentActivePage, id: 'transporterBlack' }; let i = index">
          <td>{{ (currentActivePage - 1) * pageSize + i +1 }}</td>
          <td>{{transporterBlacklisting.name}}</td>
          <td>{{transporterBlacklisting.plantCode }}</td>
          <td>
            <span *ngIf="transporterBlacklisting.status.toString().toUpperCase() == 'TRUE'">YES</span>
            <span *ngIf="transporterBlacklisting.status.toString().toUpperCase() == 'FALSE'">NO</span>
          </td>

          <td>{{transporterBlacklisting.mobileNo}}</td>
          <td>{{transporterBlacklisting.reason}}</td>
          <td>{{transporterBlacklisting.incidentDate| date: 'dd-MM-yyyy'}}</td>
          <td>{{transporterBlacklisting.created}}</td>
          <td>{{transporterBlacklisting.description}}</td>

          <!-- <td>{{transporterBlacklisting.createdDate | date: 'dd-MM-yyyy'}}</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="pagination justify-content-end">
  <pagination-controls id="transporterBlack" (pageChange)="currentActivePage = $event"></pagination-controls>
</div>
<!-- <ng-template #edit let-modal class="modal">

  <div class="modal-content custom-modal">
    <div class="modal-header">
      <h4 class="modal-title mb-0">{{title}}</h4>
      <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="material-icons-sharp">close</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <form autocomplete="off" [formGroup]="form">
            <div class="row">
              <div class="form-group ml-3">
                <input type="hidden" minlength="3" maxlength="30" class="form-control" name="vehicleBlacklistingId"
                  value="{{vehicleBlacklist.vehicleBlacklistingId}}" />
              </div>
              <div class="col-sm-12">
                <label for="transpoterName">Transporter Name</label>
                <input type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.transpoterName.errors }" formControlName="transpoterName"
                  [(ngModel)]="vehicleBlacklist.transpoterName" />
                <div *ngIf="submitted && f.transpoterName.errors" class="invalid-feedback">
                  <div *ngIf="f.transpoterName.errors.required">Transporter name is required.
                  </div>
                  <div *ngIf="f.transpoterName.errors.minlength">
                    Transporter name must be at least 3 characters.
                  </div>
                  <div *ngIf="f.transpoterName.errors.maxlength">
                    Transporter name must not exceed 30 characters.
                  </div>
                  <div *ngIf="f.transpoterName.errors?.pattern">
                    <sup>*</sup>Transporter name should contain only a-z,A-Z,0-9,-.
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <label for="reason">Reason</label>
                <select name="isBlackListed" [(ngModel)]="vehicleBlacklist.isBlackListed"
                  class="form-control form-select" formControlName="isBlackListed"
                  [ngClass]="{ 'is-invalid': submitted && f.isBlackListed.errors }">
                  <option hidden Value='' disabled>--blacklisted status--</option>
                  <option *ngFor="let vehicleBlacklist of vehicleBlacklisting" [ngValue]="vehicleBlacklist.value">
                    {{vehicleBlacklist.key}}</option>
                </select>
              </div>
              <div class="col-sm-6">
                <label for="dateAndTime">Incident Date</label>
                <input type="datetime-local" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.dateAndTime.errors }" formControlName="dateAndTime"
                  [(ngModel)]="vehicleBlacklist.dateAndTime" />
                <div *ngIf="submitted && f.dateAndTime.errors" class="invalid-feedback">
                  <div *ngIf="f.dateAndTime.errors.required">Location name is required.
                  </div>
                  <div *ngIf="f.dateAndTime.errors.minlength">
                    Location name must be at least 3 characters.
                  </div>
                  <div *ngIf="f.dateAndTime.errors.maxlength">
                    Location name must not exceed 30 characters.
                  </div>
                  <div *ngIf="f.dateAndTime.errors?.pattern">
                    <sup>*</sup>Location name should contain only a-z,A-Z,0-9,-.
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <label for="description">Description</label>
                <textarea type="text" class="form-control" placeholder="" value=" "
                  [ngClass]="{ 'is-invalid': submitted && f.description.errors }" formControlName="description"
                  [(ngModel)]="milestone.description"></textarea>
                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required">Description is required.
                  </div>
                  <div *ngIf="f.description.errors.minlength">
                    Description must be at least 3 characters.
                  </div>
                  <div *ngIf="f.description.errors.maxlength">
                    Description must not exceed 30 characters.
                  </div>
                  <div *ngIf="f.description.errors?.pattern">
                    <sup>*</sup>Description should contain only a-z,A-Z,0-9,-.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group mb-0 mt-3 select-radio" *ngIf="activebuttonShow">
              <label for="isActive" class="radio-btn">Active</label>
              <label class="form-check-inline">
                <input class="form-check-input" type="radio" name="isActive" [value]="true" formControlName="isActive"
                  [(ngModel)]="vehicleBlacklist.isActive">
              </label>
              <label for="isActive" class="radio-btn ">Inactive</label>
              <label class="form-check-inline">
                <input class="form-check-input" type="radio" name="isActive" [value]="false" formControlName="isActive"
                  [(ngModel)]="vehicleBlacklist.isActive">
              </label>
            </div>

          </form>
        </div> -->
<!-- <hr class="hr mb-0">
<div class="modal-footer">
  <button type="button" class="modal-button btn btn-secondary" aria-label="Close"
    (click)="modal.dismiss('Cross click')">Close</button>
  <button type="submit" class="modal-button btn btn-primary" (click)="registerMilestone(milestone)">Save</button>
</div> -->
<!-- </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="modal-button btn btn-default" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="modal-button btn btn-danger" aria-label="Close" *ngIf="resetbuttonShow"
        (click)="onReset()">Reset</button>
      <button type="submit" class="modal-button btn btn-success"
        (click)="registervehicleBlacklisting(vehicleBlacklist)">Save</button>
    </div>
  </div> 


</ng-template> -->