import { Component, OnInit } from '@angular/core';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-slamaster',
  templateUrl: './slamaster-tabs.component.html',
  styleUrls: ['./slamaster-tabs.component.scss']
})
  export class SLAMasterTabsComponent implements OnInit {
    public userAccessData: UserScreenAccesData;
  title: string = 'SLA Master';

  constructor(
    private api:DataService
  ) { }

  ngOnInit(): void {
    // this.loadTabComponent(0);
    this.userAccessData =this.api.getUserScreenAccessMenu('SLA Master');
  }

  // loadTabComponent(tab: number) {
  //   if (tab == 0)
  //     this.title = 'Transaction';
  //   else
  //     this.title = 'Transit';
  // }

}
