<app-header></app-header>

<section class="profile-page mt-4">
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="col-md-4 col-sm-4 col-12">
      <div class="card">
        <div class="card-body">
          <img class="img-fluid d-block mx-auto" width="280"
            src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
            alt="">
            <!-- <span  width="280" class="material-icons">account_circle</span> -->
          <div class="row">
            <div class="col-sm-6">
              <strong> Name: </strong>
              <span> {{userDetails.firstName}} </span>
              <span> {{userDetails.lastName}} </span>
            </div>
            <div class="col-sm-6">
              <strong>Email:</strong>
              <span> {{userDetails.email}} </span>
            </div>
            <div class="col-sm-6">
              <strong> Role: </strong>
              <span> {{userDetails.roleName}} </span>
            </div>
            <div class="col-md-12 mt-4">
              <button (click)="open(edit)" class="btn btn-primary btn-password" href="#open" rel="nofollow"
                type="button">Change Password</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>

 <ng-template #edit let-modal class="modal">
    <div class="modal-content custom-modal">
      <div class="modal-header">
        <h4 class="modal-title mb-0">Change Password</h4>
        <button class="rounded-circle justify-content-end" type="button" mat-button aria-label="Close"
          (click)="modal.dismiss('Cross click')">
          <span class="material-icons-sharp">close</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="off" [formGroup]="changePasswordForm">
          <div class="form-group">
            <div class="form-group">
              <input type="hidden" class="form-control" name="id" id="id" value="{{model.userName}}"
                [(ngModel)]="model.userName" />
            </div>
            <div class="form-group mt-2">
              <div class="col-sm-12">
                <label for="oldPassword">Old Password<sup>*</sup></label>
                <br>
                <input type="password" formControlName="oldPassword" autocomplete="off" class="form-control form-edit"
                  [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.oldPassword.errors }" value="" id="oldPassword"
                  name="oldPassword" [(ngModel)]="model.oldPassword" />
                <div *ngIf="editUserFormSubmitted && f1.oldPassword.errors" class="invalid-feedback">
                  <div *ngIf="f1.oldPassword.errors.required">
                    <small>Old Password is required</small>
                  </div>
                  <div *ngIf="f1.oldPassword.errors.minlength">
                    <small>Old Password must be at least 5 characters</small>
                  </div>
                  
                </div>
              </div>

            </div>
            <div class="form-group row mb-0 mt-2">
              <div class="col-sm-12">
                <label for="password">Password<sup>*</sup></label>
                <br>
                <input type="password" formControlName="password" autocomplete="off" class="form-control form-edit"
                  [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.password.errors }" value="" id="password"
                  name="password" [(ngModel)]="model.password" />
                <div *ngIf="editUserFormSubmitted && f1.password.errors" class="invalid-feedback">
                  <div *ngIf="f1.password.errors.required">
                    <small>Password is required</small>
                  </div>
                  <div *ngIf="f1.password.errors.minlength">
                    <small>Password must be at least 5 characters</small>
                  </div>
                  
                  <div *ngIf="f1.password.errors?.pattern">
                    <small>Password must contain the following: upper case (A-Z), lower case (a-z), number
                      (0-9) and special character (e.g. !@#$%^&*)</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0 mt-2">
              <div class="col-sm-12">
                <label for="confirmPassword">Confirm Password<sup>*</sup></label>
                <br>
                <input type="password" formControlName="confirmPassword" autocomplete="off"
                  class="form-control form-edit"
                  [ngClass]="{ 'is-invalid': editUserFormSubmitted && f1.confirmPassword.errors }" value=""
                  id="confirmPassword" name="confirmPassword" [(ngModel)]="model.confirmPassword" />
                <div *ngIf="editUserFormSubmitted && f1.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f1.confirmPassword.errors.required">
                    <small>Confirm Password is required</small>
                  </div>
                  <div *ngIf="f1.confirmPassword.errors.minlength">
                    <small>Confirm Password must be at least 5 characters</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="modal-button btn btn-default" aria-label="Close"
          (click)="modal.dismiss('Cross click')">Close</button>
        <button type="submit" class="modal-button btn btn-success" (click)="onSubmit()">Save</button>
      </div>
    </div>
  </ng-template>