export class GateReportModel {


    fromDate:string;
    toDate:string;
    defaultAll: string;
    TransType: string;
}
export class TatReportModel {


    fromDate:string;
    toDate:string;
    defaultAll: string;
    tranType : string;

}
export class ApiTrackingReportModel {


    fromDate:string;
    toDate:string;
    defaultAll: string;
}


export class DetailedGateReportModel{
    fromDate:string;
    toDate:string;
}