import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder,FormControl, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { faDoorClosed, faEdit, faPlus, faSort, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceUrl } from 'src/app/common/service-urls';
import { TransitSLAResponse } from 'src/app/models/TransitSLAResponse';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';
import { KemarClientService } from 'src/app/service/kemar-client.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-transit-sla',
  templateUrl: './transit-sla.component.html',
  styleUrls: ['./transit-sla.component.scss']
})
export class TransitSLAComponent implements OnInit {
  transitSLAmaster: any[];
  inboundSLAmaster:any;
  outboundSLAmaster:any;
  inplantSLAmaster: any;
  filter: '';
  public transitMasterAdd: TransitSLAResponse = new TransitSLAResponse();
  public userAccessData: UserScreenAccesData;

  faEdit = faEdit; faTimes = faTimes; faPlus = faPlus; faSort = faSort; faTruck = faTruck; faDoorClosed = faDoorClosed
  transitType: any;
  form: FormGroup;
  submitted: boolean;
  title: any;
  resetbuttonShow: boolean = true;
  ActiveButtonShow: boolean = false;
  key: any;
  reverse: boolean;
  pageSize = 10;
  currentActivePage: number = 1;
  @ViewChild('closebutton') closebutton;
  milestoneMaster: any;
  ticketUrgency: any;
  ShowingTab = 'Inbound';

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
constructor(private spinner: NgxSpinnerService,
    private kemarClientService: KemarClientService,

    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private api:DataService) {
      this.form = this.formBuilder.group({
      isActive: [''],
      transitSLAMasterId: [''],
      eventName: ['', [Validators.required]],
      fromEventCode: ['', [Validators.required]],
      toEventCode: ['', [Validators.required]],
      alertTimeInMinutes: [0, [Validators.required, Validators.pattern('[1-9][0-9]*')]],
      exceptionTimeInMinutes: [0, [Validators.required, Validators.pattern('[1-9][0-9]*')]],
      ticketSLAInMinutes: [0, [Validators.required, Validators.pattern('[1-9][0-9]*')]],
      ticketPriority: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      description: ['', [Validators.required]],

    });
    this.userAccessData =this.api.getUserScreenAccessMenu('SLA Master');
  }

  ngOnInit(){
    this.getAllMilestone();
    this.getAllUrgency();
    this.getAllInboundSLA();
    this.getAllOutboundSLA();
    this.getAllInPlantSLA();

  }


  

  public noWhitespaceValidator(control:FormControl) {

    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }
  onReset() {
    this.submitted = false;
    this.form.reset();
    this.transitMasterAdd.isActive = true;
    this.form.controls['fromEventCode'].setValue('');
    this.form.controls['toEventCode'].setValue('');
    this.form.controls['ticketPriority'].setValue('');
  }

  acceptnumber(event: any, flag: boolean) {
    if (flag) {
      this.kemarClientService.keyPressNumbers(event);
    }

  }


  dropdownselection(value:any)
   {

    //  console.log(value)
    this.ShowingTab = value;
    }

  // getAllTransitSLATickets() {
  //
  //   this.spinner.show();
  //  this.kemarClientService.get<any>(null, ServiceUrl.getAllTransitSLATickets)
  //     .subscribe(
  //       response => {
  //         this.transitSLAmaster = response;
  //        this.spinner.hide();
  //       },
  //       (r) => {
  //         this.spinner.hide();
  //         alert(r.error.error);
  //         console.log(r.error.error);
  //       }
  //     );
  // }
  getAllMilestone() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllMilestone)
      .subscribe(
        response => {

          this.spinner.hide();
          this.milestoneMaster = response;
        },
        r => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        });
  }
  getAllUrgency() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getTicketUrgency)
      .subscribe(
        response => {
          this.spinner.hide();
          this.ticketUrgency = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }
  getAllInboundSLA() {

    this.spinner.show();
    this.kemarClientService.get<any>(null,ServiceUrl.getAllInboundSLA)
      .subscribe(
        response => {
          this.inboundSLAmaster = response;
          // console.log(this.inboundSLAmaster)
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  getAllOutboundSLA() {

    this.spinner.show();
   this.kemarClientService.get<any>(null, ServiceUrl.getAllOutboundSLA)
      .subscribe(
        response => {
          this.outboundSLAmaster = response;
          // console.log(this.outboundSLAmaster)
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }

  getAllInPlantSLA() {

    this.spinner.show();
   this.kemarClientService.get<any>(null, ServiceUrl.getAllInPlantSLA)
      .subscribe(
        response => {
          this.inplantSLAmaster = response;
          // console.log(this.inplantSLAmaster)
          this.spinner.hide();
        },
        (r) => {
          this.spinner.hide();
          alert(r.error.error);
          // console.log(r.error.error);
        }
      );
  }
  saveTransitSLA() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    var milestoneCode = this.form.get('fromEventCode').value;
    var milestoneCode1 = this.form.get('toEventCode').value;
    if (milestoneCode != undefined && milestoneCode != '' && milestoneCode1 != undefined && milestoneCode1 != '') {
      if (milestoneCode == milestoneCode1) {
        var errorMessage = "From Event Name and To Event Name can not be same";
        Swal.fire({
          icon: 'error',
          title: '',
          text: errorMessage,
        })
        return;
      }
    }
     this.spinner.show();
     this.kemarClientService.postPatch<any>(ServiceUrl.saveTransitSLA, this.transitMasterAdd)
      .subscribe(
        (res) => {
          Swal.fire('', res.responseMessage, 'success');
          this.getAllInboundSLA();
          this.getAllOutboundSLA();
          this.getAllInPlantSLA();
          this.closeModalPopup();
          this.spinner.hide();
        },
        (r) => {

           let msg = "";
           if (r.error.message != undefined) {
         msg = r.error.message;
          }
        else {
            msg = r.error.errorMessage;
          }

          Swal.fire({
            icon: 'error',
            title: '',
            text:msg
          });
          this.spinner.hide();

          }
      );
  }
  ngbModalOptions: NgbModalOptions =
    {
      backdrop: 'static',
      keyboard: false
    }
  open(content: any, transitType: string) {

    this.onReset();
    this.title = "Add Transit SLA ";
    this.transitMasterAdd.transitSLAMasterId = 0;
    this.transitMasterAdd.eventName = '';
    this.transitMasterAdd.fromEventCode = '';
    this.transitMasterAdd.toEventCode = '';
    this.transitMasterAdd.alertTimeInMinutes = null;
    this.transitMasterAdd.exceptionTimeInMinutes = null;
    this.transitMasterAdd.ticketSLAInMinutes = null;
    this.transitMasterAdd.ticketPriority = '';
    this.transitMasterAdd.transitType = transitType;
    this.transitMasterAdd.subject = '';
    this.transitMasterAdd.description = '';
    this.transitMasterAdd.isActive = true;
    this.ActiveButtonShow = true;
    this.resetbuttonShow = true;
    this.modalService.open(content, this.ngbModalOptions);
    this.form.get('toEventCode').enable();
    this.form.get('fromEventCode').enable();
  }

  editSLA(content: any, transitmaster) {

    this.onReset();
    this.title = "Update Transit SLA";
    this.transitMasterAdd.transitSLAMasterId = transitmaster.transitSLAMasterId;
    this.transitMasterAdd.eventName = transitmaster.eventName;
    this.transitMasterAdd.fromEventCode = transitmaster.fromEventCode;
    this.transitMasterAdd.toEventCode = transitmaster.toEventCode;
    this.transitMasterAdd.alertTimeInMinutes = transitmaster.alertTimeInMinutes;
    this.transitMasterAdd.exceptionTimeInMinutes = transitmaster.exceptionTimeInMinutes;
    this.transitMasterAdd.ticketSLAInMinutes = transitmaster.ticketSLAInMinutes;
    this.transitMasterAdd.ticketPriority = transitmaster.ticketPriority;
    this.transitMasterAdd.transitType = transitmaster.transitType;
    this.transitMasterAdd.subject = transitmaster.subject;
    this.transitMasterAdd.description = transitmaster.description;
    this.transitMasterAdd.isActive = transitmaster.isActive;
    this.ActiveButtonShow = false,
      this.resetbuttonShow = false,
      this.form.get('fromEventCode').disable();
    this.form.get('toEventCode').disable();
    this.form.get('eventName').setValue(transitmaster.eventName);
    this.modalService.open(content, this.ngbModalOptions);
  }
  public closeModalPopup() {
    this.modalService.dismissAll();
  }
  ChangeMilestoneName() {

    var milestoneCode = this.form.get('fromEventCode').value;
    var milestoneCode1 = this.form.get('toEventCode').value;
    if (milestoneCode != undefined && milestoneCode != '' && milestoneCode1 != undefined && milestoneCode1 != '') {

      var SelectedMilestone = this.milestoneMaster.filter(milestone => milestone.milestoneCode == milestoneCode);
      var SelectMilestone = this.milestoneMaster.filter(milestone => milestone.milestoneCode == milestoneCode1);
      this.form.get('eventName').setValue(SelectedMilestone[0].milestoneName + " " + SelectedMilestone[0].milestoneEvent + '-' + SelectMilestone[0].milestoneName + " " + SelectMilestone[0].milestoneEvent);
      this.transitMasterAdd.eventName = (SelectedMilestone[0].milestoneName + " " + SelectedMilestone[0].milestoneEvent + '-' + SelectMilestone[0].milestoneName + " " + SelectMilestone[0].milestoneEvent);

    }
  }



}




