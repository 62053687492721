import { AfterViewInit, Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { KemarClientService } from 'src/app/service/kemar-client.service';
import { ServiceUrl } from 'src/app/common/service-urls';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { faAnchor, faEdit, faMapPin, faObjectGroup, faPlus, faSort, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import { TicketModel, ticketTransactionHistory } from '../../models/ticketmodel';
import { pairwise, startWith } from 'rxjs/operators'
import { UTStatusService } from '../../service/statusdata.service';
import { UserScreenAccesData } from 'src/app/models/usermodel';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-exception-master',
  templateUrl: './exception-master.component.html',
  styleUrls: ['./exception-master.component.scss']
})

export class ExceptionMasterComponent implements OnInit {
  tickets: any;
  ticketHistoryData: any;
  ticketDetails: any;
  ticketUrgency: any;
  ticketAssignUser: any;
  utStatus: any;
  title: any;
  faEdit = faEdit;
  faTimes = faTimes
  faAnchor = faAnchor
  faPlus = faPlus
  faSort = faSort
  faInfo = faInfo;
  submitted = false;
  form: UntypedFormGroup;
  subcategory: any;
  category: any;
  exception: any;
  location: any;
  header: string = 'Add Exception';
  Save: string = 'Save';
  resetbuttonShow: boolean = true;
  public ticketModel: TicketModel = new TicketModel();
  public userAccessData: UserScreenAccesData;


  //###################### Pagination and sorting section###################
  //sorting
  filter = '';
  pageSize = 10;
  key: string = 'name'; //set default
  reverse: boolean = false;
  tempcurrentActivePage: number = 1;
  assign: boolean = false;
  currentAssignUser: any;
  AssignedUserOnEditcase: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing currentActivePage to one which is active page when page loads
  currentActivePage: number = 1;
  //################################# END Pagination ###########################################
  // @ViewChild('closebutton') closebutton;

  constructor(private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private kemarClientService: KemarClientService,
    private utStatusService: UTStatusService,
    private modalService: NgbModal,private api:DataService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        ticketId: [0],
        ticketNo: [
          '',
        ],
        raisedUserId: [
          0,
        ],
        locationName: [
          '',
          [
            Validators.required,
          ]
        ],

        priority: [
          '', [
            Validators.required,
          ]
        ],
        categoryId: [
          '',
          [
            Validators.required,
          ]
        ],
        subcategoryId: [
          '',
          [
            Validators.required,
          ]
        ],

        ticketSubject: [
          '',
          [
            Validators.required
          ]
        ],
        ticketDescription: [
          '',
          [
            Validators.required
          ]
        ],
        remarks: [''],
        status: [
          '',
          [
            Validators.required,
          ]
        ],
        assignedUserId: [''],
        vrn: [''],
        driverName: [''],
        driverNo: [''],
        serviceSLATime: ['']
      },
    );
    this.userAccessData =this.api.getUserScreenAccessMenu('ActiveIncident');
    this.utStatus = this.utStatusService.getControlTowerStatuses();
    this.getAllTickets();
    this.getCommonMethods();
  }

  getCommonMethods() {
    this.getAllLocation();
    this.getAllUrgency();
    this.getAllCategory();
    this.getAllUser();
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getAllLocation() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllLocationMapping)
      .subscribe(
        response => {
          this.spinner.hide();
          this.location = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }
  Assigned(value: any) {

    if (value === "7: Assigned") {
      this.assign = true;
    }
    else {
      this.assign = false;
    }

  }

  getAllUrgency() {

    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getTicketUrgency)
      .subscribe(
        response => {
          this.spinner.hide();
          this.ticketUrgency = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  getAllCategory() {
    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getAllCategory)
      .subscribe(
        response => {
          this.spinner.hide();
          this.category = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  onSubmit() {

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(JSON.stringify(this.form.value, null, 2));
  }

  getSubCategoryByCategory(categoryId: number) {
    if (typeof (categoryId) === 'undefined' || categoryId == 0) return;
    if (categoryId == 0) {
      this.ticketModel.subCategoryId = 0;
    }

    this.spinner.show();
    this.kemarClientService.getbyId<any>(categoryId, ServiceUrl.getSubCategorybyCategoryId)
      .subscribe(
        response => {
          this.spinner.hide();
          this.subcategory = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }


  onReset() {
    this.submitted = false;
    this.form.reset();
  }

  getAllTickets() {

    this.spinner.show();
    this.kemarClientService.get<any>(null, ServiceUrl.getActiveTickets)
      .subscribe(
        response => {
          this.tickets = response.ticket;
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  getTicketDetails(content: any, ticketId: number) {

    var history = this.tickets.filter(function (ticket) {
      return ticket.ticketId == ticketId;
    });
    this.title = 'Ticket Information';
    if (history.length > 0)
      this.ticketDetails = history[0].jsonListData;
    this.modalService.open(content, this.ngbModalOptions);
  }

  getTicketDetailHistory(content: any, ticketId: number) {
    this.spinner.show();
    var query = { ticketId: ticketId }
    this.kemarClientService.get<any>(null, ServiceUrl.getTicketHistory, query)
      .subscribe(
        response => {
          this.ticketHistoryData = response;
          this.modalService.open(content, this.ngbModalOptions);
          this.spinner.hide();
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  open(content: any) {

    this.onReset();
    this.resetbuttonShow = true;
    this.header = 'Add Ticket';
    this.Save = 'Save';
    this.ticketModel = new TicketModel();
    this.assign = false;
    this.ticketModel.ticketId = null;
    this.ticketModel.categoryId = null;
    this.ticketModel.priority = null;
    this.ticketModel.locationName = null;
    this.ticketModel.subCategoryId = null;
    this.ticketModel.assignedUserId = null;
    this.ticketModel.ticketSubject = null;
    this.ticketModel.ticketDescription = '';
    this.ticketModel.remarks = '';
    this.ticketModel.status = null;
    this.modalService.open(content, this.ngbModalOptions);
  }

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  public closeModalPopup() {
    this.modalService.dismissAll();
  }

  editTicketDetails(content: any, columnData: any) {

    this.onReset();
    this.getCommonMethods();
    this.resetbuttonShow = false;
    this.ticketModel.assignedUserId = columnData.assignedUserId;

    this.currentAssignUser = columnData.assignedUser;
    if (this.kemarClientService.commonValidation(this.currentAssignUser) == false)
      this.AssignedUserOnEditcase = true;
    else
      this.AssignedUserOnEditcase = false;
    this.getLocationIdOnName(columnData.locationName);
    this.ticketModel.categoryId = columnData.categoryId;
    this.getSubCategoryByCategory(this.ticketModel.categoryId);
    this.ticketModel.subCategoryId = columnData.subCategoryId;
    this.ticketModel.priority = columnData.priority;
    this.form.controls['vrn'].setValue(columnData.vrn);
    this.form.controls['driverName'].setValue(columnData.driverName);
    this.form.controls['driverNo'].setValue(columnData.driverNo);
    this.form.controls['serviceSLATime'].setValue(columnData.serviceSLATime);
    this.form.controls['ticketId'].setValue(columnData.ticketId);
    this.ticketModel.ticketDescription = columnData.ticketDescription;
    this.ticketModel.ticketSubject = columnData.ticketSubject;
    this.ticketModel.status = columnData.status;
    if(columnData.status=='Assigned'){this.assign=true;}else{this.assign=false;}
    this.ticketModel.remarks = columnData.remarks;
    this.ticketModel.locationName = columnData.locationName;
    this.ticketModel.locationId = columnData.locationId;
    this.header = 'Update Ticket';
    this.Save = 'Update';


    this.modalService.open(content, this.ngbModalOptions);
  }

  getAllUser() {

    this.spinner.show();
    var userType = { "RoleGroup": "ControlTower" }
    this.kemarClientService.get(null, ServiceUrl.getUserbyRoll, userType)
      .subscribe(
        response => {
          this.spinner.hide();
          this.ticketAssignUser = response;
        },
        r => {
          this.spinner.hide();
          // console.log(r.error.error);
        });
  }

  registerException() {

    // console.log(this.ticketModel);
    let mymodel = this.ticketModel;
    if (this.assign) {
      this.form.controls['assignedUserId'].setValidators([Validators.required]);
      if (this.ticketModel.assignedUserId == 0)
        this.form.controls['assignedUserId'].setValue(null);
    }
    else {
      this.form.controls['assignedUserId'].clearValidators();
      this.form.controls['assignedUserId'].setValue(null);
    }
    this.form.controls['assignedUserId'].updateValueAndValidity();
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.spinner.show();
    var body = this.form.value;
    this.kemarClientService.postPatch<any>(ServiceUrl.GenerateServiceTicket, body)
      .subscribe(
        res => {
          Swal.fire('', res.responseMessage, 'success');
          this.getAllTickets();
        },
        r => {
          this.spinner.hide();
          Swal.fire('', r.error.message, 'error');
        });
    this.closeModalPopup();
    this.spinner.hide();
  }

  getLocationNameOnId(locationId: number) {
    this.ticketModel.locationName = this.location.filter(v => v.locationId == locationId)[0].locationName;
  }

  getLocationIdOnName(locationName: number) {
    this.ticketModel.locationId = this.location.filter(v => v.locationName == locationName)[0].locationId;
  }

  pagechange = (event) => this.tempcurrentActivePage = this.currentActivePage = event;
  filterchange = (val: any) => (!this.kemarClientService.commonValidation(val)) ? this.currentActivePage = 1 : this.currentActivePage = this.tempcurrentActivePage

}


