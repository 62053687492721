<app-header></app-header>
<section class="my-2">
<div class="container-fluid">
    <div class="card">
      <div class="card-body custom-table">
        <h4 class="title-one mb-2">Device Configuration</h4>
        <mat-accordion id="deviceconfiguration" class="example-headers-align custom-accordion" hideToggle="false" [togglePosition]="'before'">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                     ADD FRS
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form autocomplete="off" [formGroup]="form" class="d-flex align-items-end justify-content-start mt-3">
                     <div class="form-group ml-3">
                        <input type="hidden" minlength="3" maxlength="30" class="form-control" name="deviceip"
                          formControlName="deviceIP" />
                      </div> 
                      <div class="col-auto">
                        <label for="description">FR Device IP Address</label>
                        <input type="text" class="form-control" placeholder="" value=" "
                        [ngClass]="{ 'is-invalid': submitted && f.deviceIP.errors }" formControlName="deviceIP"/>
                      </div>
                      <div class="col-auto">
                        <button class="btn btn-success px-4" (click)="AddFRS()">send</button>
                      </div>
                </form>
      
            </mat-expansion-panel>
      </mat-accordion>
    </div>
</div>
</div> 
</section>
<app-footer></app-footer>
