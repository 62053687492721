export class RoleMasterResponse {
roleId:number;
roleName:string;
roleGroup: string;
isActive:boolean;
userAccessResponse:{

    canCreate: [];
    canUpdate: [];
    canDeactivate: [];
}

}






